import React from 'react'
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

export default function CustomBreadCrum({ title, subtitle }) {
  return (
    <div
      className="d-flex"
      style={{ alignItems: "center", paddingBottom: "2.5rem" }}
    >
      <h3 style={{ margin: "0", fontSize: "25px" }}>{title}</h3>
      <span>
        <DoubleArrowIcon className="icon-arrow" />
      </span>
      <h4
        style={{
          color: "#a6b0cf",
          fontWeight: "400",
          margin: "0",
          fontSize: "15px",
          paddingTop: "7px",
        }}
      >
        {subtitle}
      </h4>
    </div>
  )
}
