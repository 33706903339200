import React, {useState, useEffect} from "react";
import {Card, Row, Col, Button, Table, Badge, Spinner} from "reactstrap";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Switch from "react-switch";
import toastr from "toastr";

import petition_patch from "../../../../../petitions/petition_patch";
export default function CallHendling({data, devices, id, module}) {
  toastr.options = {
    showDuration: 300,
  };
  const [switchOne, setSwitchOne] = useState(true);
  const [devicesList, setDevicesList] = useState([]);
  const [actualice, setActualice] = useState(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (data.length === 0) {
      const newList = devices.map((element) => {
        if (!element.enable) {
          element.timeout = 0;
        }
        data.map((elementTwo) => {
          if (elementTwo.id === element.id) {
            element.timeout = elementTwo.timeout;
          }
        });
        return element;
      });

      setDevicesList([...newList]);
    } else {
      const newListOrder = [];
      data.map((element) => {
        devices.map((elementTwo) => {
          if (elementTwo.id === element.id) {
            elementTwo.timeout = element.timeout;
            newListOrder.push(elementTwo);
          }
        });
      });
      if (newListOrder.length !== devices.length) {
        devices.map((element) => {
          const findOne = newListOrder.find(
            (elementTwo) => elementTwo.id === element.id
          );
          if (!findOne) {
            element.timeout = 0;
            element.enabled = false;
            newListOrder.push(element);
          }
        });
      }

      setDevicesList([...newListOrder]);
    }
  }, [devices, data]);

  useEffect(() => {
    if (module !== "user") {
      setSwitchOne(false);
    } else {
      setSwitchOne(true);
    }
  }, [module]);

  const onChangeUpdate = ({id, name, value}) => {
    const newList = devicesList;
    newList.map((element) => {
      if (element.id === id) {
        switch (name) {
          case "enabled":
            element.enabled = value;
            break;
          case "timeout":
            element.timeout = value;
            if (value !== "") {
              element.enabled = true;
            } else {
              element.enabled = false;
            }

            break;
          default:
            break;
        }
      }
    });
    setActualice(!actualice);
    setDevicesList(newList);
  };
  const onClickSend = (e) => {
    e.preventDefault();
    setLoading(true);
    const sendDevices = [];
    devicesList.map((element) => {
      if (element.enabled) {
        const newElement = {id: element.id, timeout: parseInt(element.timeout)};
        return sendDevices.push(newElement);
      }
    });
    petition_patch("callHandling", {
      id,
      data: {call_handling: {enabled: true, device_order: [...sendDevices]}},
    })
      .then(({data: result}) => {
        toastr.success("Action completed", "Success");
        setLoading(false);
      })
      .catch((error) => {
        toastr.error("Action failed", "Error");
        console.log(error.response);
      });
  };
  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        No
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        Yes
      </div>
    );
  };

  const onDragEnd = (e) => {
    const srcI = e.source.index;
    const destI = e.destination.index;
    const newList = devicesList;
    newList.splice(destI, 0, newList.splice(srcI, 1)[0]);
    setDevicesList(newList);
  };

  const onChangeCheck = () => {
    const sendDevices = [];
    devicesList.map((element) => {
      if (element.enabled) {
        const newElement = {id: element.id, timeout: parseInt(element.timeout)};
        return sendDevices.push(newElement);
      }
    });
    petition_patch("callHandling", {
      id,
      data: {
        call_handling: {enabled: switchOne, device_order: [...sendDevices]},
      },
    })
      .then(({data: result}) => {})
      .catch((error) => {
        console.log(error.response);
      });

    setSwitchOne(!switchOne);
  };

  return (
    <>
      <div style={{padding: "2rem 0 "}}>
        {" "}
        <Switch
          uncheckedIcon={<Offsymbol />}
          className="mr-1"
          checkedIcon={<OnSymbol />}
          onColor="#626ed4"
          onChange={() => {
            onChangeCheck();
          }}
          checked={switchOne}
        />{" "}
        <span>Ring all devices simultaneously</span>
      </div>
      {!switchOne && (
        <>
          <Table responsive>
            <thead className="thead-light">
              <div
                className="row bg-light"
                style={{margin: "0", padding: "1rem 0"}}
              >
                <div className="col-1" style={{textAlign: "center"}}></div>
                <div className="col-3" style={{textAlign: "center"}}>
                  Phone Type
                </div>
                <div className="col-3" style={{textAlign: "center"}}>
                  Ring
                </div>
                <div className="col-3" style={{textAlign: "center"}}>
                  Ring How Many Seconds ?
                </div>
              </div>
            </thead>
          </Table>
          <form action="" onSubmit={onClickSend}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="list">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {devicesList.map((element, i) => {
                      return (
                        <div key={i}>
                          {" "}
                          <Draggable key={i} index={i} draggableId={`key-${i}`}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <div className="row pt-3">
                                  <div
                                    className="col-1"
                                    style={{textAlign: "center"}}
                                  >
                                    <i
                                      {...provided.dragHandleProps}
                                      className="bx bx-move-vertical"
                                      style={{fontSize: "1.5em"}}
                                    />
                                  </div>
                                  <div
                                    className="col-3"
                                    style={{textAlign: "center"}}
                                  >
                                    {element.name}
                                  </div>
                                  <div
                                    className="col-3"
                                    style={{textAlign: "center"}}
                                  >
                                    <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      className="mr-1"
                                      checkedIcon={<OnSymbol />}
                                      onColor="#626ed4"
                                      onChange={() => {
                                        onChangeUpdate({
                                          id: element.id,
                                          name: "enabled",
                                          value: !element.enabled,
                                        });
                                      }}
                                      checked={element.enabled}
                                    />
                                  </div>
                                  <div
                                    className="col-3"
                                    style={{
                                      display: "flex",
                                      alignContent: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {" "}
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={element.timeout}
                                      onChange={(e) => {
                                        onChangeUpdate({
                                          id: element.id,
                                          name: "timeout",
                                          value: e.target.value,
                                        });
                                      }}
                                      style={{width: "6em"}}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        </div>
                      );
                    })}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>{" "}
            <Row className="">
              <Col className="col-12  text-right mt-4">
                <button
                  disabled={loading}
                  type="submit"
                  className="btn btn-primary ml-4 pl-4 pr-4 font-size-16"
                >
                  {loading && (
                    <Spinner
                      style={{marginRight: "0.5em"}}
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  Save
                </button>
              </Col>{" "}
            </Row>
          </form>
        </>
      )}
    </>
  );
}
