import React, { useEffect, useState } from "react";

import { Container, Card, Table, Badge, Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";

import petition_get from "../../../petitions/petition_get";
import petition_patch from "../../../petitions/petition_patch";
import petition_delete from "../../../petitions/petition_delete";

import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import GetAppIcon from "@material-ui/icons/GetApp";
import Tools from "./Tools/Tools";
import ButtonHover from "../../../../components/utils/ButtonHover";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import ModalUtilityEdit from "./ModalUtility/ModalUtilityEdit";
import ModalUtilityDelete from "./ModalUtility/ModalUtilityDelete";
import ModalUtilityAdd from "./ModalUtility/ModalUtilityAdd";

import toastr from "toastr";

import CustomTable from "../../../../components/utils/CustomTable/CustomTable";

import { get, set } from "lodash";
export default function Callflows() {
  toastr.options = {
    showDuration: 300,
  };

  const [modalDelete, setModalDelete] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingPetition, setLoadingPetition] = useState(false);
  const [data, setData] = useState([]);
  const [reloadData, setReloadData] = useState(true);
  const [dataTable, setDataTable] = useState(null);

  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const deleteCallFlows = async () => {
    setLoading(true);
    let saveCallFlowDetails;
    let flowDevice2;
    let filterNodesDelete = []
    await petition_get("callflowsDetails", { id, callflow: modalDelete.id })
      .then(({ data: result }) => { saveCallFlowDetails = result.result; })
      .catch((error) => { console.log(error); });

    petition_delete("callFlow", { id, callflow_id: modalDelete.id })
      .then(({ data: result }) => {
        Object.keys(saveCallFlowDetails.dataChart.nodes).map(element => {
          let node = saveCallFlowDetails.dataChart.nodes[element]

          if (node.type.split("_")[0] === "externalNumber") {
            if (node.childrenUrl === "") flowDevice2 = saveCallFlowDetails.flow
            else flowDevice2 = get(saveCallFlowDetails.flow, node.childrenUrl)
            filterNodesDelete.push(flowDevice2.data.id)
          }
        })
        let petitions2 = []
        filterNodesDelete.map(element => {
          petitions2.push(petition_delete("deleteCallflow", { id, callFlowId: element }))
        })

        Promise.all(petitions2).then(result => {
          setLoading(false);
          toastr.success("Action completed", "Success");
          setReloadData(true);
          setModalDelete(false);

        }).catch(err => {
          console.log(err);
          setLoading(false);
          toastr.error("Action failed", "Error");
        })


      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toastr.error("Action failed", "Error");
      });
  };



  const activeDeleteModal = (e) => {
    if (e.type === "mainUserCallflow" || e.type === "QueueCallflow" || e.type === "ring_group" || e.type === "TemporalRouteCallflow") {
      return toastr.warning("Cannot delete this Callflow", "Warning");
    }
    setModalDelete(e);
  };
  const activeEditModal = (e) => {

    setModalEdit(e);
  };

  useEffect(() => {
    if (reloadData) {
      petition_get("allCallflows", { id })
        .then(({ data: result }) => {

          console.log(result.result)
          let resultCopy = result.result.filter(element => (element.type !== "TemporalRouteCallflow" && element.type !== "featurecode" && element.type !== "systemFeatureCode" && element.type !== "mainUserCallflow" && element.type !== "QueueCallflow" && element.type !== "ring_group" && element.numbers[0] !== "no_match"))

          setDataTable({
            title: [
              { title: "Name", key: "name" },
              { title: "numbers", key: "numbers" },
            ],
            content: [...resultCopy],
            actions: {
              title: "Actions",
              content: [
                {
                  icon: <DeleteIcon />, //Svg icon
                  handleClick: activeDeleteModal, //Function
                },
                {
                  icon: <EditIcon />, //Svg icon
                  handleClick: activeEditModal, //Function
                },
              ],
            },
          });
          setData(resultCopy);
          setLoadingPetition(true);
          setReloadData(false);
        })
        .catch((error) => {
          console.log(error);
          setReloadData(false);
        });
    }
  }, [reloadData]);
  return (
    <>
      {modalEdit && <ModalUtilityEdit
        modal={modalEdit}
        loading={loading}
        setModal={setModalEdit}
        setReloadData={setReloadData}
      />}

      <ModalUtilityDelete
        modal={modalDelete}
        loading={loading}
        setModal={setModalDelete}
        deleteCallFlows={deleteCallFlows}
      />
      {modalAdd && <ModalUtilityAdd
        setReloadData={setReloadData}
        modal={modalAdd}
        loading={loading}
        setModal={setModalAdd}

      />}


      <div className="page-content">
        <div className="padding-page">


          <Container fluid>
            <div
              className="d-flex"
              style={{ alignItems: "center", paddingBottom: "2.5rem" }}
            >
              <h3 style={{ margin: "0" }}>Phone System</h3>
              <span>
                <DoubleArrowIcon className="icon-arrow" />
              </span>
              <h4
                style={{
                  color: "#a6b0cf",
                  fontWeight: "400",
                  margin: "0",
                  fontSize: "15px",
                  paddingTop: "7px",
                }}
              >
                Callflows
              </h4>
            </div>
          </Container>

          <Card body>
            {loadingPetition ? (
              <>
                <Tools setModalAdd={setModalAdd} />
                <div className="border-bottom"></div>
                <CustomTable responsive={true} data={dataTable} />
              </>
            ) : (
              <div style={{ textAlign: "center" }}>
                <Spinner
                  animation="grow"
                  style={{
                    margin: "10vh auto",
                    height: "10vh",
                    width: "10vh",
                  }}
                />{" "}
              </div>
            )}
          </Card>
        </div>
      </div>
    </>
  );
}
