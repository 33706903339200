import React, {useEffect, useState} from "react";

import {Container, Card, Table, Spinner} from "reactstrap";

import petition_get from "../../../petitions/petition_get";
import petition_post from "../../../petitions/petition_post";
import petition_delete from "../../../petitions/petition_delete";

import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import GetAppIcon from "@material-ui/icons/GetApp";

import ButtonHover from "../../../../components/utils/ButtonHover";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ModalUtilityEdit from "./ModalUtility/ModalUtilityEdit";
import ModalUtilityAdd from "./ModalUtility/ModalUtilityAdd";
import ModalUtilityDelete from "./ModalUtility/ModalUtilityDelete";
import Tools from "./Tools/Tools";
import toastr from "toastr";

import styled from "styled-components";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const ContainerPagination = styled.div`
  padding: 20px;
  margin: 0;

  .btn-page {
    border: none;
    background: rgba(255, 255, 255, 0.1);
    color: #a6b0cf;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    margin: 0 5px;
    padding: 0;
    svg {
      color: #a6b0cf;
    }
    &:focus {
      outline: none;
    }
  }
  .active {
    background: #536be1;
  }

  @media (max-width: 768px) {
    padding: 0;
    .container-pages {
      display: flex;
      justify-content: center;
    }
  }
`;
const Pages = ({data, setPagination, pagination, cantPagination}) => {
  let page = [];

  for (let i = 0; i < Math.trunc(data); i++) {
    page.push(i);
  }

  const onClickPage = (index) => {
    index = (index + 1) * cantPagination;
    setPagination(index);
  };
  return (
    <>
      {page.map((pag, index) => {
        if (
          (index + 1 >= pagination / cantPagination - 2 &&
            index + 1 <= pagination / cantPagination) ||
          (index + 1 <= pagination / cantPagination + 2 &&
            index + 1 >= pagination / cantPagination)
        ) {
          return (
            <button
              className={`btn-page ${
                pagination / cantPagination === index + 1 && "active"
              } `}
              onClick={() => {
                onClickPage(index);
              }}
              key={index}
            >
              {index + 1}
            </button>
          );
        }
      })}
    </>
  );
};

export default function RingGroups() {
  toastr.options = {
    showDuration: 300,
  };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const cantPagination = 10;
  const [pagination, setPagination] = useState(cantPagination);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [errorAdd, setErrorAdd] = useState(null);
  const [listDetails, setListDetails] = useState([]);
  const [reload, setReload] = useState(0);
  const [listUsers, setListUsers] = useState([]);
  const [deleteUser, setDeleteUser] = useState(false);
  const [activeCancel, setActiveCancel] = useState(false);

  const addRingGroup = (values) => {
    const newData = {
      name: values.name,
      numbers: [values.number],
      description: values.description,
    };
    petition_post("addRingGroup", {id, data: newData})
      .then(({data: result}) => {
        setModalAdd(false);
        setErrorAdd(null);
        const allData = [...data];
        allData.push(result.result.data);
        setData(allData);

        const allDetails = [...listDetails];
        allDetails.push(result.result.data);
        setListDetails(allDetails);
        toastr.success("Action completed", "Success");
        setActiveCancel(true);
        setModalEdit(result.result.data);
      })
      .catch((error) => {
        toastr.error("Action failed", "Error");
        setErrorAdd("Verify your information");
      });
  };

  const deleteRingGroup = (ring_group_id) => {
    setSpinner(true);
    petition_delete("deleteRingGroup", {id, ring_group_id})
      .then(({data: result}) => {
        toastr.success("Action completed", "Success");
        setSpinner(false);
        setModalDelete(false);
        setDeleteUser(!deleteUser);
        const allData = data.filter(
          (element) => element.id !== result.result.id
        );
        setData(allData);
      })
      .catch((error) => {
        toastr.error("Action failed", "Error");
        console.log(error);
      });
  };

  useEffect(() => {
    let info = [];
    let dataDetails = [];
    const petitions = async () => {
      await petition_get("ringGroup", {id})
        .then(({data: result}) => {
          info = result.result;
          setData(result.result);
          setDataFilter(result.result);
          setLoading(true);
        })
        .catch((error) => {
          console.log(error);
        });

      petition_get("itpvoiceUsers", {id})
        .then(({data: result}) => {
          setListUsers(result.result);
        })
        .catch((error) => {
          console.log(error);
        });
      info.map((element, i) => {
        petition_get("ringGroupDetails", {id, ring_group_id: element.id})
          .then(({data: result}) => {
            dataDetails.push(result.result);
            setListDetails(dataDetails);
            setReload(i);
          })
          .catch((error) => {
            console.log(error.response);
          });
      });
    };
    petitions();
  }, [deleteUser]);

  const onChangeSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const onClickNext = () => {
    if (pagination < data.length) {
      setPagination(pagination + cantPagination);
    }
  };

  const onClickBack = () => {
    if (pagination > cantPagination) {
      setPagination(pagination - cantPagination);
    }
  };

  useEffect(() => {
    const newData = data.filter((element) => {
      const find = element.numbers.filter((elementTwo) =>
        elementTwo.includes(searchInput)
      );
      if (find.length > 0) return element;
    });

    setDataFilter(newData);
  }, [searchInput]);
  return (
    <>
      <ModalUtilityDelete
        spinner={spinner}
        deleteRingGroup={deleteRingGroup}
        modal={modalDelete}
        setModal={setModalDelete}
      />
      <ModalUtilityEdit
        activeCancel={activeCancel}
        setActiveCancel={setActiveCancel}
        listUsers={listUsers}
        id={id}
        modal={modalEdit}
        setModal={setModalEdit}
        listDetails={listDetails}
        setListDetails={setListDetails}
        setDeleteUser={setDeleteUser}
        deleteUser={deleteUser}
      />
      <ModalUtilityAdd
        setErrorAdd={setErrorAdd}
        errorAdd={errorAdd}
        addRingGroup={addRingGroup}
        modal={modalAdd}
        setModal={setModalAdd}
      />
      <div className="page-content">
        <Container fluid>
          <div
            className="d-flex"
            style={{alignItems: "center", paddingBottom: "2.5rem"}}
          >
            <h3 style={{margin: "0"}}>Phone System</h3>
            <span>
              <DoubleArrowIcon className="icon-arrow" />
            </span>
            <h4
              style={{
                color: "#a6b0cf",
                fontWeight: "400",
                margin: "0",
                fontSize: "15px",
                paddingTop: "7px",
              }}
            >
              Ring Group
            </h4>
          </div>
        </Container>
        <Tools
          onChangeSearch={onChangeSearch}
          searchInput={searchInput}
          setModalAdd={setModalAdd}
        />
        {loading && data.length === listDetails.length ? (
          <>
            <Card body>
              {dataFilter.length === 0 ? (
                <p style={{textAlign: "center", fontSize: "1.3rem"}}>
                  you don't have ring groups
                </p>
              ) : (
                <div className="table-responsive">
                  <Table hover className="table mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th style={{textAlign: "center"}}>Name </th>
                        <th style={{textAlign: "center"}}>Numbers Assigned </th>
                        <th style={{textAlign: "center"}}>Description </th>

                        <th style={{textAlign: "center"}}>Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      {dataFilter.map((element, i) => {
                        let description = "";
                        const searchDetail = listDetails.find(
                          (elementThree) => elementThree.id === element.id
                        );

                        if (searchDetail.itpvoice_metadata) {
                          description =
                            searchDetail.itpvoice_metadata.description;
                        }
                        if (
                          i < pagination &&
                          i >= pagination - cantPagination
                        ) {
                          return (
                            <tr key={i}>
                              <th style={{textAlign: "center"}}>
                                {element.name}
                              </th>
                              <th style={{textAlign: "center"}}>
                                {element.numbers.map((elementTwo, i) => (
                                  <span key={i}>{elementTwo}</span>
                                ))}
                              </th>
                              <th
                                style={{
                                  textAlign: "center",
                                  padding: "inherit 0",
                                }}
                              >
                                {description}
                              </th>
                              <th
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <ButtonHover
                                  style={{margin: "0"}}
                                  onClick={() => {
                                    setModalDelete(element.id);
                                  }}
                                >
                                  <DeleteIcon />
                                </ButtonHover>
                                <ButtonHover
                                  style={{margin: "0"}}
                                  onClick={() => {
                                    setModalEdit(searchDetail);
                                  }}
                                >
                                  <EditIcon />
                                </ButtonHover>
                              </th>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </Table>
                  <ContainerPagination>
                    {dataFilter && dataFilter.length > cantPagination && (
                      <div className="col-12 container-pages">
                        <button className="btn-page pl-2" onClick={onClickBack}>
                          <ArrowBackIosIcon />
                        </button>
                        <Pages
                          pagination={pagination}
                          setPagination={setPagination}
                          cantPagination={cantPagination}
                          data={
                            (dataFilter.length / cantPagination) % 1 === 0
                              ? dataFilter.length / cantPagination
                              : dataFilter.length / cantPagination + 1
                          }
                        />
                        <button className="btn-page" onClick={onClickNext}>
                          <ArrowForwardIosIcon />
                        </button>
                      </div>
                    )}
                  </ContainerPagination>
                </div>
              )}
            </Card>
          </>
        ) : (
          <div style={{textAlign: "center"}}>
            <Spinner
              animation="grow"
              style={{
                margin: "10vh auto",
                height: "10vh",
                width: "10vh",
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}
