import React, { Component } from "react";

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Button,
  Spinner,
} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { loginUser, apiError } from "../../store/actions";

// import images
import itpPortal from "../../assets/images/itpFiber/itpPortal2.png";
import itpLogo from "../../assets/images/itpFiber/itp_white.png";
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import FromCrmPetition from "./FromCrmPetition";
import * as qs from "query-string";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonLogIn: false,
      token: "",
      password: "",
      rePassword: "",
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // handleValidSubmit
  async handleValidSubmit(event, values) {
    this.setState({ buttonLogIn: true });
    this.props.loginUser(values, this.props.history);
    setTimeout(this.resetLoading, 3000);
  }

  resetLoading = () => {
    this.setState({ buttonLogIn: false });
  };

  componentDidMount() {
    this.setState({ token: qs.parse(this.props.location.search).token });
    FromCrmPetition(qs.parse(this.props.location.search).token)
    .then(({ data: { result } }) => {

    })
    .catch((error) => {
      console.log(error);
    });
    this.props.apiError("");
  }

  send() {

  }

  render() {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container className="CenterBottom">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-soft-secundary">
                    <Row>
                      <Col className="col-12">
                        <div className="text-primary  p-4"></div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="logoITP">
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <img src={itpLogo} alt="" height="70" />
                          {/* </span> */}
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        // onValidSubmit={this.handleValidSubmit}
                      >
                        {this.props.error && this.props.error ? (
                          <Alert color="danger">{this.props.error}</Alert>
                        ) : null}

                        <div className="form-group">
                          <AvField
                            name="Password"
                            label="Pasword"
                            value=""
                            className="form-control"
                            placeholder="Enter Password"
                            type="password"
                            required
                            onChange={(e) =>
                              this.setState({ password: e.target.value })
                            }
                          />
                        </div>

                        <div className="form-group">
                          <AvField
                            name="Password"
                            label="Confirm Password"
                            value=""
                            type="password"
                            required
                            placeholder="Enter Password"
                            onChange={(e) =>
                              this.setState({ rePassword: e.target.value })
                            }
                          />
                        </div>

                        {/* <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customControlInline"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div> */}

                        <div className="mt-3">
                          {/* <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                          >
                            Log In
                          </button> */}
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            // type="submit"
                            disabled={
                              this.state.buttonLogIn === true || (this.state.password !== this.state.rePassword  || this.state.password === ""  || this.state.rePassword === "") ? true : false
                            }
                            onClick={() => {
                              this.send();
                            }}
                          >
                            {this.state.buttonLogIn === true ? (
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null}

                            {this.state.buttonLogIn === true
                              ? "  Loading..."
                              : "Send"}
                          </button>
                        </div>

                        {/* <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock mr-1"></i> Forgot your
                            password?
                          </Link>
                        </div> */}
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>© {new Date().getFullYear()} ITP.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStatetoProps, { loginUser, apiError })(ChangePassword)
);
