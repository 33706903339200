import React, { Component, useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Table,
  Button,
  Row,
  Col,
  Input,
  Spinner,
  Badge,
} from "reactstrap";
import SearchIcon from "@material-ui/icons/Search";
import ButtonHover from "../../../../components/utils/ButtonHover";
import CustomBreadCrum from "../../../../components/utils/CustomBreadCrum/CustomBreadCrum";
import CustomTable from "../../../../components/utils/CustomTable/CustomTable";
import petition_get from "../../../petitions/petition_get";
//Import Breadcrumb

//Modal
import ModalUtility from "./ModalUtility/ModalUtility";


import styled from "styled-components";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import Alert404 from "../../../../components/404alert/Alert404";
const ContainerPagination = styled.div`
  padding: 20px;
  margin: 0;

  .btn-page {
    border: none;
    background: rgba(255, 255, 255, 0.1);
    color: #a6b0cf;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    margin: 0 5px;
    padding: 0;
    svg {
      color: #a6b0cf;
    }
    &:focus {
      outline: none;
    }
  }
  .active {
    background: #536be1;
  }

  @media (max-width: 768px) {
    padding: 0;
    .container-pages {
      display: flex;
      justify-content: center;
    }
  }
`;

export default function ContactList() {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const [dataInstalled, setDataInstalled] = useState([]);
  const [dataInstalledCopy, setDataInstalledCopy] = useState([]);
  const [data, setData] = useState(null);

  const [allData, setAllData] = useState(null);
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [element, setElement] = useState(null);


  const handleModal = (element) => {

    setModal(true);
    setElement(element);


  }

  useEffect(() => {


    const petitions = async () => {
      setSpinner(false);
      await petition_get("installedApp", { id: id })
        .then(({ data: result }) => {
          console.log(result)
          setDataInstalled({
            title: [{ title: "App", key: "app", secondKey: "name" }],
            content: result.result.model_list,
            actions: {
              title: "Actions",
              content: [
                {
                  icon: <SearchIcon />, //Svg icon
                  handleClick: handleModal, //Function
                },
              ],
            },
          })

          setDataInstalledCopy(result.result.model_list);
        })
        .catch((error) => {
          console.log("error");
        });

      await petition_get("itpvoiceUsers", { id: id })
        .then(({ data: result }) => {

          setData(result.result);
          setAllData(result.result);
        })
        .catch((error) => {
          console.log(error);
          // setLoading(true);
        });
      setSpinner(true);
    }
    petitions()

  }, []);

  return (
    <React.Fragment>
      <ModalUtility
        setElement={setElement}
        setModal={setModal}
        modal={modal}
        message={message}
        spinner={spinner}
        users={data}
        element={element}
      />
      <div className="page-content">
        {spinner ? (
          <>
            <CustomBreadCrum title="Apps" subtitle="Premissions" />
            <CustomTable data={dataInstalled} />
          </>
        ) :
          (
            <div style={{ textAlign: "center" }}>
              <Spinner
                animation="grow"
                style={{
                  margin: "10vh auto",
                  height: "10vh",
                  width: "10vh",
                }}
              />{" "}
            </div>
          )
        }
      </div>
    </React.Fragment>
  );
}
