
import React, { useState, useEffect } from "react"

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import toastr from "toastr";

import petition_get from "../pages/petitions/petition_get"
import petition_post from "../pages/petitions/petition_post"
import petition_delete from "../pages/petitions/petition_delete"
import petition_patch from "../pages/petitions/petition_patch"

export default function useGroup({ setModalDelete, setModalEdit, setModalAdd }) {


    const [groups, setGroups] = useState(null)
    const [dataTable, setDataTable] = useState(null)
    const [loading, setLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const [userData, setUserData] = useState(null)
    const [data, setData] = useState(null)
    const [selectedItem, setSelectedItem] = useState(null)

    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    toastr.options = { showDuration: 300, };


    useEffect(() => {

        petition_get("contactGroups", { id })
            .then(({ data: result }) => {

                let newData = [...result.result.model_list.map(element => {
                    let cutNameSave = ''
                    element.name.split(" ").map(element => cutNameSave = cutNameSave + element[0])
                    element.cutName = cutNameSave
                    return element;
                })]


                setData(newData)

                if (result.result.model_list.length > 0) setSelectedItem(result.result.model_list[0])

                setDataTable({
                    title: [
                        { title: "Name", key: "name" },
                        { title: "Description", key: "description" },
                    ],
                    content: [...result.result.model_list],
                    actions: {
                        title: "Actions",
                        content: [
                            {
                                icon: <DeleteIcon />,  //Svg icon
                                handleClick: (e) => { setModalDelete(e.pk) },  //Function
                            },
                            {
                                icon: <EditIcon />,  //Svg icon
                                handleClick: (e) => { setModalEdit(e); },  //Function
                            },
                        ],
                    },
                    search: {
                        label: "Search "
                    },
                    addButton: {
                        label: "Add Group",
                        handleClick: () => { setModalAdd(true) }
                    }
                })

            })
            .catch((error) => console.log(error));

        petition_get("viewNumbersChat", { id })
            .then(({ data: result }) => setUserData(result.result))
            .catch((error) => console.log(error));
    }, [reload])



    const deleteGroup = (e) => {

        petition_delete("deleteGroup", { id: id, groupPk: e })
            .then(({ data: result }) => {
                toastr.success("Action completed", "Success");
                setReload(!reload)
                setLoading(false);
                setModalDelete(false);
            })
            .catch((error) => {
                toastr.error("Action failed", "Error");
                console.log(error);
                setLoading(false);

            });

    }

    const addGroups = (dataForm) => {

        if (dataForm.name.trim() === "") return toastr.error("Need Group Name", "Error");
        if (dataForm.description.trim() === "") return toastr.error("Need Group Description", "Error");
        if (!dataForm.contacts || dataForm.contacts.length === 0) return toastr.error("Need Contacts in the group", "Error");

        petition_post("addGroupContact", { id: id, data: { ...dataForm, user_id: userData.user_id } })
            .then(({ data: result }) => {
                toastr.success("Action completed", "Success");
                setReload(!reload)
                setLoading(false);
                setModalAdd(false);
            })
            .catch((error) => {
                toastr.error("Action failed", "Error");
                console.log(error);
                setLoading(false);

            });
    }

    const editGroups = (dataForm, deleteContacts) => {


        if (dataForm.name.trim() === "") return toastr.error("Need Group Name", "Error");
        if (dataForm.description.trim() === "") return toastr.error("Need Group Description", "Error");
        if (!dataForm.contacts || dataForm.contacts.length === 0) return toastr.error("Need Contacts in the group", "Error");

        let removed_contacts = []

        deleteContacts.contacts.map(element => {
            let findContact = dataForm.contacts.find(elementTwo => elementTwo === element.pk)
            if (!findContact) removed_contacts.push(element.pk)
        })




        petition_patch("updateContactGroups", { id, group_id: dataForm.pk, data: { ...dataForm, removed_contacts } })
            .then(({ data: result }) => {
                toastr.success("Action completed", "Success");
                setReload(!reload)
                setLoading(false);
                setModalEdit(false);
            })
            .catch((error) => {
                toastr.error("Action failed", "Error");
                setLoading(false);
            });


    }


    const onChangeSelectedItem = (item) => { setSelectedItem(item) }


    return { data, groups, deleteGroup, addGroups, editGroups, dataTable, loading, selectedItem, onChangeSelectedItem }
}
