import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  TOKEN_USER,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  CHANGE_ADMIN,
  CHANGE_INSTALLAPPS
} from "./actionTypes";

export const changeAdmin = (value, userData) => {
  return {
    type: CHANGE_ADMIN,
    payload: { value, userData },
  };
};
export const changeInstallApps = (value) => {

  return {
    type: CHANGE_INSTALLAPPS,
    payload: value,
  };
};
export const loginUser = (user, history, action) => {
  return {
    type: LOGIN_USER,
    payload: { user, history, action },
  };
};

export const tokenUser = (token, history) => {
  return {
    type: TOKEN_USER,
    payload: { token, history },
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
