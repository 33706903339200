import React from "react";

import {Container} from "reactstrap";

export default function DashboardITPVoiceLegacy() {
  return (
      <>
          <div className="page-content">

       <Container fluid> DashboarITPVoice Legacy </Container>

          </div>
    </>
  );
}
