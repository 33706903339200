import React, { useState, useEffect } from "react";

import CallIcon from "@material-ui/icons/Call";
import CallEndIcon from "@material-ui/icons/CallEnd";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import DialpadIcon from '@material-ui/icons/Dialpad';
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import ringtonePress from "../../../../../../assets/audio/press-number.mp3"
import PerfectScrollbar from "react-perfect-scrollbar";

import { Spinner } from "reactstrap"

export default function IncomingCall({
  setModal,
  answer,
  endCallonInvited,
  reject,
  step,
  setStep,
  silence,
  unsilence,
  mute,
  unMute,
  setOnTransfer,
  setCalling,
  setActivePhone,
  holdCall,
  unholdCall,
  actualSessions,
  time,
  actualSessionSelected,
  setHoldWaiting,
  setKeyboardNumber,
  users,
  timeoutReload
}) {
  const [volume, setVolume] = useState(true);
  const [mic, setMic] = useState(true);
  const [musicOnHold, setMusicOnHold] = useState(false)
  const [keyboard, setKeyboard] = useState(false)
  const [keyNumberSignal, setKeyNumberSignal] = useState("")
  const [pressPhone] = useState(new Audio(ringtonePress))
  const [timeCall, setTimeCall] = useState("")
  const [idIntervalTime, setIdIntervalTime] = useState(null)

  const Numbers = [
    { number: "1", text: "/" },
    { number: "2", text: "A B C" },
    { number: "3", text: "D E F" },
    { number: "4", text: "G H I" },
    { number: "5", text: "J K L" },
    { number: "6", text: "M N O" },
    { number: "7", text: "P Q R S" },
    { number: "8", text: "T U V" },
    { number: "9", text: "W X Y Z" },
    { number: "*", text: " " },
    { number: "0", text: " " },
    { number: "#", text: " " },
  ];


  useEffect(() => {


    if (actualSessionSelected) {

      if (idIntervalTime) clearInterval(idIntervalTime);

      let timeNow = new Date().getTime()
      let initTime = new Date(actualSessionSelected.initialTime).getTime()

      let diffTime = 0
      let intervalTimeId = setInterval(() => {
        diffTime++;
        setTimeCall(new Date(diffTime * 1000).toISOString().substr(diffTime < 3600 ? 14 : 11, diffTime < 3600 ? 5 : 8),)
      }, 1000)

      setIdIntervalTime(intervalTimeId)
    }



  }, [actualSessionSelected])




  const returnInfoOfCalls = (sessions) => {
    if (!sessions) return ""

    let _displayName;
    let uri;

    if (sessions.incomingInviteRequest) {
      _displayName = sessions.incomingInviteRequest.message.from._displayName
      uri = sessions.incomingInviteRequest.message.from.uri

    } else {

      uri = sessions.outgoingRequestMessage.to.uri
      let findDisplayName = users.find(element => element.presence_id === uri.normal.user)
      console.log(findDisplayName)
      if (findDisplayName) _displayName = `${findDisplayName.first_name} ${findDisplayName.last_name}`

    }

    return (<div className={`my-2 mx-3 ${actualSessionSelected && sessions._id !== actualSessionSelected._id ? "callHold" : "callUnHold"}`}> <p><span> {_displayName} </span> <span> {uri.normal.user}</span> </p> <p> {actualSessionSelected && sessions._id !== actualSessionSelected._id && `(on hold)`}</p> </div>)

  }
  const returnInfoOfCallAlls = (sessions) => {

    let session = sessions[sessions.length - 1]


    if (!session) return ""

    let _displayName;
    let uri;

    if (session.incomingInviteRequest) {
      _displayName = session.incomingInviteRequest.message.from._displayName
      uri = session.incomingInviteRequest.message.from.uri

    } else {

      uri = session.outgoingRequestMessage.to.uri
      let findDisplayName = users.find(element => element.presence_id === uri.normal.user)
      if (findDisplayName) _displayName = `${findDisplayName.first_name} ${findDisplayName.last_name}`

    }
    return (<p><div> {_displayName} </div> <div> {uri.normal.user}</div></p>)

  }

  return (
    <>
      {step === 1 && (
        <>
          <div
            className="phone-call_keyboard-number calling"
            style={{ height: "40%" }}
          >
            <p>
              {returnInfoOfCallAlls(actualSessions)}
            </p>
            <br />
            <p className="calling-time">Incoming Call</p>
          </div>
          <div
            className="phone-call_keyboard-number calling"
            style={{ height: "45%" }}
          ></div>

          <div className="phone-call_controller ">
            <div className="phone-call_keyboard-num col-4  buttonClose ">
              <div
                onClick={() => {
                  if (actualSessions.length === 1) {
                    setModal(false);
                    setStep(1);
                  }
                  else {
                    setStep(2)
                  }
                  reject(true);
                }}
                className="number"
              >
                <CallEndIcon />
              </div>
            </div>
            <div className="phone-call_keyboard-num col-4 buttonCall ">
              <div
                onClick={() => {
                  setStep(2);
                  answer(true);
                }}
                className="number"
              >
                <CallIcon />
              </div>
            </div>
          </div>
        </>
      )}
      {step === 2 && (
        <>
          <PerfectScrollbar
            className="phone-call_keyboard-number calling"
            style={{ height: "40%" }}
          >
            {timeCall === "" ? <p style={{ fontSize: "16px" }}>Attempting to Answer Call <Spinner
              style={{ marginRight: "0.5em" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            /> </p> : <p>{timeCall}</p>}
            {actualSessions.map(element => <div onClick={() => { setTimeCall(""); setHoldWaiting(element._id) }} className="calling-numbers">
              {returnInfoOfCalls(element)}
            </div>)}

          </PerfectScrollbar>
          <div
            className="phone-call_keyboard-keys row "
            style={{ height: "45%", position: "relative" }}
          >

            {keyboard && <div onClick={() => { setKeyboard(false) }} style={{ backgroud: "red", cursor: "pointer", position: "absolute", top: "-25px", right: "20px", zIndex: "1000" }}>  <CloseIcon /> </div>}

            {keyboard && <div className="w-100" >

              <p className="w-100" style={{ overFlow: "auto" }}>  <input
                className="default-field"
                value={keyNumberSignal}
                /* onChange={(e) => { filterNumber(e.target.value) }} */
                type="text"
                id="numberField"
              />
              </p>
            </div>}

            {keyboard ? Numbers.map((element, i) => (
              <div
                key={i}
                onClick={() => {
                  setKeyboardNumber(parseInt(element.number))
                  setKeyNumberSignal(`${keyNumberSignal}${element.number}`)
                  pressPhone.play()
                }}
                className="phone-call_keyboard-num col-4 "
                style={{ height: "25px" }}
              >
                <div className="number"> {element.number} </div>
                <div
                  className="number-text"
                  style={{ opacity: i === 0 ? "0" : "1" }}
                >
                </div>
              </div>
            ))
              : <>

                <div onClick={() => {
                  if (mic) {
                    setMic(false);
                    return mute(true);
                  }
                  setMic(true);
                  unMute(true);
                }} className="phone-call_keyboard-num col-4 " style={{ opacity: musicOnHold ? "0.5" : "1" }}>
                  <div

                    className="number"
                  >
                    {mic ? <MicIcon /> : <MicOffIcon />}
                  </div>
                  <span>Silence</span>
                </div>
                <div
                  onClick={() => {
                    if (musicOnHold) return
                    if (volume) {
                      setVolume(false);
                      return silence(true);
                    }
                    setVolume(true);
                    unsilence(true);
                  }}
                  className="phone-call_keyboard-num col-4 "
                  style={{ opacity: musicOnHold ? "0.5" : "1" }}
                >
                  <div className="number">
                    {volume ? <VolumeUpIcon /> : <VolumeOffIcon />}
                  </div>
                  <span>Volume</span>
                </div>
                <div
                  onClick={() => {
                    // setCalling(1);
                    // setOnTransfer(true);
                    if (musicOnHold) {
                      unholdCall(true);
                    } else {
                      holdCall(true);
                    }
                    setMusicOnHold(!musicOnHold);
                  }}
                  className="phone-call_keyboard-num col-4 "
                >
                  <div className="number">
                    {musicOnHold ? (
                      <i className="mdi mdi-music"></i>
                    ) : (
                      <i className="mdi mdi-music-off"></i>
                    )}
                  </div>

                  <span>{musicOnHold ? "On Hold" : "Hold"}</span>
                </div>

                <div
                  onClick={() => {
                    if (musicOnHold) return
                    setCalling(1);
                    setOnTransfer(true);
                    setModal(false);
                    setActivePhone(true);
                  }}
                  className="phone-call_keyboard-num col-4 "
                  style={{ opacity: musicOnHold ? "0.5" : "1" }}
                >
                  <div className="number">
                    <i class="bx bx-transfer-alt"></i>
                  </div>
                  <span>Transfer</span>
                </div>
                {/*                 {actualSessions.length > 1 && <div
                  onClick={() => { setStep(4) }}
                  className="phone-call_keyboard-num col-4 "
                >
                  <div className="number">
                    <i class="bx bx-stopwatch"></i>
                  </div>
                  <span>On Waiting </span>
                </div>} */}
                <div
                  onClick={() => {
                    if (musicOnHold) return
                    setKeyboard(!keyboard)
                  }}
                  className="phone-call_keyboard-num col-4 "
                  style={{ opacity: musicOnHold ? "0.5" : "1" }}
                >
                  <div className="number">
                    < DialpadIcon />
                  </div>
                  <span>Keyboard</span>
                </div>



              </>}

          </div>
          <div className="phone-call_controller ">
            <div
              onClick={() => {
                if (actualSessions.length <= 1) setStep(3);

                if (actualSessionSelected) endCallonInvited(actualSessionSelected._id);
              }}
              className="phone-call_keyboard-footer close "
            >
              <div className="number">
                <CallEndIcon />
              </div>
            </div>
          </div>
        </>
      )}
      {step === 3 && (
        <>
          <div
            className="phone-call_keyboard-number calling"
            style={{ height: "40%" }}
          >
            <p>Call Ended</p>
            <br />
            <p className="calling-time">
              {timeCall}
            </p>
          </div>

          <div
            className="phone-call_keyboard-number calling"
            style={{ height: "45%" }}
          ></div>
          <div className="phone-call_controller ">
            <div
              onClick={() => {
                setModal(false);
                setTimeout(() => {
                  setStep(1);
                  if (timeoutReload) clearInterval(timeoutReload)
                }, 200);
              }}
              className="phone-call_keyboard-footer close "
            >
              <div style={{ fontSize: "13px", color: "white", fontWeight: 500 }}>
                Close
              </div>
            </div>
          </div>
        </>
      )}
      {step === 4 && (
        <>
          <div
            className="phone-call_keyboard-number calling"
            style={{ height: "20%" }}
          >
            <p>Calls on hold</p>

          </div>

          <div
            className="phone-call_keyboard-number calling text-center px-3"
            style={{ height: "65%" }}
          >

            {actualSessions.map(element => {

              if (element._id !== actualSessionSelected._id) {
                const { _displayName, uri } = element.incomingInviteRequest.message.from
                return <p className="custom-pointer" onClick={() => { setStep(2); }}>{_displayName} {uri.normal.user} </p>
              }

            })}


          </div>
          <div className="phone-call_controller ">
            <div
              onClick={() => { setStep(2) }}
              className="phone-call_keyboard-footer "
            >
              <div style={{ fontSize: "13px", color: "white", fontWeight: 500 }}>
                Back
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
