import React, { useState, useEffect } from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  CustomInput,
  Table
} from "reactstrap";
import Switch from "react-switch";
import toastr from "toastr";
import petition_patch from "../../../../petitions/petition_patch";
import petition_post from "../../../../petitions/petition_post";
import petition_get from "../../../../petitions/petition_get";
import petition_delete from "../../../../petitions/petition_delete";
import ButtonHover from "../../../../../components/utils/ButtonHover"
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
export default function ModalUtilityEdit({
  modal,
  setModal,
  setReloadData,
  dataTimeDate,
  dataTimeDateId,
}) {
  toastr.options = {
    showDuration: 300,
  };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const [switchOne, setSwitchOne] = useState(false)
  const [listOverrideCodes, setListOverridesCodes] = useState([
    { title: "Forced Enable Code", numbers: [], input: "" },
    { title: "Forced Disable Code", numbers: [], input: "" },
    { title: "Forced Reset Code", numbers: [], input: "" },

  ])


  const [loading, setLoading] = useState(false);
  const [formTimeGroup, setFormTimeGroup] = useState({
    name: "",
  });
  const [formTimeDate, setFormTimeDate] = useState({
    name: "",
    cycle: "weekly",
    start_date: "",
    time_window_start: "",
    time_window_stop: "",
    interval: "1",
    checked: false,
    ordinal: "",
    wdays: [],
    days: [],
  });
  const handleChangeGroup = (e) => {
    setFormTimeGroup({ ...formTimeGroup, [e.target.name]: e.target.value });
  };


  const handleChange = (e) => {
    if (e.target.name == "cycle")
      return setFormTimeDate({ ...formTimeDate, [e.target.name]: e.target.value, month: (e.target.value === "monthly" || e.target.value === "yearly") ? "1" : null, interval: e.target.value === "yearly" ? "1" : "1", ordinal: (e.target.value === "yearly" || e.target.value === "monthly") ? "first" : null })
    setFormTimeDate({ ...formTimeDate, [e.target.name]: e.target.value })
  }



  const transformDate = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor(seconds / 60 - hours * 60);
    if (hours === 24) return `23:59`
    return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes
      }`;
  };

  useEffect(() => {
    if (modal) {
      if (dataTimeDate) {
        const start_date_transform = new Date(modal.start_date * 1000);
        start_date_transform.setFullYear(start_date_transform.getFullYear() - 1970)



        if (modal.itpvoice_metadata.activeOverrideCode) {
          setSwitchOne(true)
          setListOverridesCodes(modal.itpvoice_metadata.activeOverrideCode)
        } else {
          setSwitchOne(false)
          setListOverridesCodes([
            { title: "Forced Enable Code", numbers: [], input: "*" },
            { title: "Forced Disable Code", numbers: [], input: "*" },
            { title: "Forced Reset Code", numbers: [], input: "*" },

          ])
        }

        setFormTimeDate({
          name: modal.name,
          cycle: modal.cycle,
          time_window_start: transformDate(modal.time_window_start),
          time_window_stop: transformDate(modal.time_window_stop),

          start_date: `${start_date_transform.getFullYear()}-${(start_date_transform.getMonth() + 1) < 10 ? `0${start_date_transform.getMonth() + 1}` : start_date_transform.getMonth() + 1
            }-${start_date_transform.getDate() < 10 ? `0${start_date_transform.getDate()}` : start_date_transform.getDate()}`,
          interval: modal.interval.toString(),
          wdays: modal.wdays,
          days: modal.days || [],
          ordinal: modal.ordinal || ""
        });
      } else {
        setFormTimeGroup({ name: modal.name });
      }
    }
  }, [modal]);



  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        <p style={{ marginTop: "1.5em" }}>No</p>
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        <p style={{ marginTop: "1.5em" }}>Yes</p>
      </div>
    );
  };


  const wDaysOptions = [
    { label: "S", value: "sunday" },
    { label: "M", value: "monday" },
    { label: "T", value: "tuesday" },
    { label: "W", value: "wednesday" },
    { label: "T", value: "thursday" },
    { label: "F", value: "friday" },
    { label: "S", value: "saturday" },
  ]

  const handleSubmit = async () => {

    listOverrideCodes.map(element => element.input === `*${element.input}`)
    if (dataTimeDate) {
      setLoading(true);
      const calculateTimeStart = formTimeDate.time_window_start.split(":")
      const calculateTimeStop = formTimeDate.time_window_stop.split(":")
      const start_date_transform = new Date(formTimeDate.start_date)
      start_date_transform.setFullYear(start_date_transform.getFullYear() + 1970)

      const newData = { ...formTimeDate, itpvoice_metadata: { activeOverrideCode: switchOne ? listOverrideCodes : null, } };
      newData.time_window_start = formTimeDate.check
        ? 0
        : ((calculateTimeStart[0] * 60 * 60) + calculateTimeStart[1] * 60)
      newData.time_window_stop = formTimeDate.check
        ? 86400
        : ((calculateTimeStop[0] * 60 * 60) + calculateTimeStop[1] * 60)
      newData.start_date = start_date_transform.getTime() / 1000
      newData.month = parseInt(newData.month);
      newData.interval = parseInt(newData.interval);
      newData.time_group_id = dataTimeDateId;

      if (newData.cycle === "weekly") delete newData.ordinal
      if (!newData.month) delete newData.month
      if (newData.ordinal !== "every") delete newData.days



      let filterNumbers = [listOverrideCodes[0].input, listOverrideCodes[1].input, listOverrideCodes[2].input]

      let numbersResult = filterNumbers;
      let findOne = false;


      await petition_get("allCallflows", { id }).then(({ data: result }) => {
        result.result.map(element => {
          element.numbers.map(elementTwo => {
            const findTwo = numbersResult.find(elementThree => elementThree === elementTwo)
            if (findTwo) findOne = findTwo
          })
        })

      }).catch((error) => console.log(error))



      if (switchOne && (listOverrideCodes[0].input === "*" || listOverrideCodes[1].input === "*" || listOverrideCodes[2].input === "*")) {
        toastr.error(`You need one number in each field of the overrides codes`, "Error");
        return setLoading(false)
      }

      if (switchOne && findOne) {
        toastr.error(`The number ${findOne} is used in other Callflow.`, "Error");
        return setLoading(false)
      }


      if (!switchOne && modal.itpvoice_metadata && modal.itpvoice_metadata.activeOverrideCode && modal.itpvoice_metadata.callflows) {
        newData.itpvoice_metadata = {}
        console.log(modal.itpvoice_metadata)
        modal.itpvoice_metadata.callflows.map(element => petition_delete("callFlow", { id, callflow_id: element }))
      }

      const findRepeatNumbers = [... new Set(numbersResult)];


      if (switchOne && findRepeatNumbers.length !== 3) {
        toastr.error(`The numbers is repeat  in the fiels of overrides.`, "Error");
        return setLoading(false)
      }

      petition_patch("timeDate", { id, data: newData, time_id: modal.id })
        .then(({ data: result }) => {
          if (switchOne) {
            let petitions = [];
            let timeId = result.result.id
            listOverrideCodes.map((element, i) => {

              let action;

              if (i === 0) action = "enable";
              else if (i === 1) action = "disable";
              else if (i === 2) action = "reset";


              let dataTemporalRoute = {
                flow: {
                  data: {
                    action,
                    rules: [`${result.result.id}`]
                  },
                  module: "temporal_route",
                  children: {},
                },
                type: "TemporalRouteCallflow",
                numbers: [`*${element.input}`],
                patterns: [],
              }

              if (modal.itpvoice_metadata.activeOverrideCode) {
                petitions.push(petition_patch("callflowEdit", { id, data: dataTemporalRoute, callflow_id: modal.itpvoice_metadata.callflows[i] }))

              } else {
                petitions.push(petition_post("callflow", { id, data: dataTemporalRoute }))
              }


            })
            Promise.all(petitions).then(result => {


              let callflowsData = []
              result.map(element => callflowsData.push(element.data.result.id))
              newData.itpvoice_metadata.callflows = callflowsData

              petition_patch("timeDate", { id, data: newData, time_id: timeId })
                .then(({ data: result }) => {

                  setLoading(false);
                  setModal(false);
                  toastr.success("Action completed", "Success");
                  setReloadData(true);
                })
                .catch((error) => {
                  setLoading(false)
                  console.log(error)
                  toastr.error("Action failed", "Error");
                });

            }).catch((error) => console.log(error));
          } else {
            setLoading(false);
            setModal(false);
            toastr.success("Action completed", "Success");
            setReloadData(true);
          }
        })
        .catch((error) => {
          setLoading(false)
          console.log(error);
          toastr.error("Action failed", "Error");
        });
    } else {
      setLoading(true);


      petition_get("timeGroupsDetails", { id: id, time_group_id: modal.id })
        .then(({ data: result }) => {

          console.log(result.result)
          petition_patch("timeGroup", {
            id,
            data: { ...result.result, name: formTimeGroup.name },
            time_id: modal.id,
          })
            .then(({ data: result }) => {
              setLoading(false);
              setModal(false);
              toastr.success("Action completed", "Success");
              setReloadData(true);
            })
            .catch((error) => {
              console.log(error);
              toastr.error("Action failed", "Error");
            });
        })
        .catch((error) => { });



    }
  };

  const handleChangeOverride = (e, i) => {



    let newOverrideList = [...listOverrideCodes]
    newOverrideList[i].input = e;

    setListOverridesCodes(newOverrideList)
  }

  return (
    <Modal centered={true} isOpen={modal ? true : false}>
      <ModalHeader>
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary">Edit {dataTimeDate ? "Time Date" : "Time Group"}</h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>

      <ModalBody>

        {dataTimeDate ? (
          <Row>
            <Col className="col-12">
              <label htmlFor="name"  >
                Name:
              </label>
              <input
                onChange={handleChange}
                name="name"
                value={formTimeDate.name}
                className="form-control"
                type="text"
              />
            </Col>
            <Col className="col-12 pt-3">
              <label htmlFor="cycle"  >
                Repeats:
              </label>
              <select

                onChange={handleChange}
                name="cycle"
                value={formTimeDate.cycle}
                className="form-control"
              >
                <option value="weekly">Weekly</option>
                <option value="monthly"> Monthly</option>
                <option value="yearly">Yearly</option>
              </select>
            </Col>
            {formTimeDate.cycle === "weekly" &&
              <Col className="col-12 pt-3">
                <label
                  htmlFor="every"
                >
                  Every (week(s)):
                </label>
                <input
                  onChange={handleChange}
                  name="interval"
                  value={formTimeDate.interval}
                  className="form-control"
                  type="number"
                />
              </Col>}

            {formTimeDate.cycle === "monthly" &&
              <Col className="col-12 pt-3">
                <label
                  htmlFor="every"
                >
                  Every (month(s)):
                </label>
                <input
                  onChange={handleChange}
                  name="interval"
                  value={formTimeDate.interval}
                  className="form-control"
                  type="number"
                />
              </Col>}
            {formTimeDate.cycle === "yearly" &&
              <Col className="col-12 pt-3">
                <label
                  htmlFor="every"
                >
                  Every :
                </label>
                <select
                  onChange={handleChange}
                  name="interval"
                  value={formTimeDate.interval}
                  className="form-control"
                >

                  <option value="">Select One</option>
                  <option value="january">January</option>
                  <option value="febrary"> Febrary</option>
                  <option value="march">March</option>
                  <option value="april">April</option>
                  <option value="may"> May</option>
                  <option value="june">June</option>
                  <option value="july">July</option>
                  <option value="august"> August</option>
                  <option value="september">September</option>
                  <option value="october">October</option>
                  <option value="november"> November</option>
                  <option value="december">December</option>
                </select>
              </Col>}
            {(formTimeDate.cycle === "yearly" || formTimeDate.cycle === "monthly") &&
              <Col className="col-12 pt-3">
                <label
                  htmlFor="on"
                >
                  On :
                </label>
                <div className="d-flex">

                  <select
                    onChange={handleChange}
                    name="ordinal"
                    value={formTimeDate.ordinal}
                    className="form-control mr-1"
                  >
                    <option value="">Select One</option>
                    <option value="first">First</option>
                    <option value="second"> Second</option>
                    <option value="third">Third</option>
                    <option value="fourth">Fourth</option>
                    <option value="fifth"> Fifth</option>
                    <option value="last">Last</option>
                    <option value="every">Day</option>
                  </select>
                  <select
                    onChange={(e) => { setFormTimeDate({ ...formTimeDate, [formTimeDate.ordinal === "every" ? `days` : `wdays`]: [e.target.value] }) }}
                    name="ordinal"
                    value={formTimeDate.ordinal === "every" ? formTimeDate.days[0] : formTimeDate.wdays[0]}
                    className="form-control ml-1"
                  >
                    {formTimeDate.ordinal === "every" ? <>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                      <option value="20">20</option>
                      <option value="21">21</option>
                      <option value="22">22</option>
                      <option value="23">23</option>
                      <option value="24">24</option>
                      <option value="25">25</option>
                      <option value="26">26</option>
                      <option value="27">27</option>
                      <option value="28">28</option>
                      <option value="29">29</option>
                      <option value="30">30</option>
                      <option value="31">31</option>
                    </> : <>   <option value="sunday">Sunday</option>
                      <option value="monday"> Monday</option>
                      <option value="tuesday">Tuesday</option>
                      <option value="wednesday">Wednesday</option>
                      <option value="thursday"> Thursday</option>
                      <option value="friday">Friday</option>
                      <option value="saturday">Saturday</option> </>}
                  </select>
                </div>
              </Col>}
            {formTimeDate.cycle === "weekly" &&
              <Col className="col-12 pt-3">
                <label
                  htmlFor="on"
                >
                  On :
                </label>
                <div className="d-flex">

                  {wDaysOptions.map((element, i) => {

                    let flag = formTimeDate.wdays.find(elementTwo => elementTwo === element.value)
                    return (<div
                      key={i}
                      onClick={() => {

                        const findOne = formTimeDate.wdays.find(elementTwo => elementTwo === element.value)
                        const daysfilter = formTimeDate.wdays.filter(elementTwo => elementTwo !== element.value)
                        if (!findOne) return setFormTimeDate({ ...formTimeDate, wdays: [...formTimeDate.wdays, element.value] })
                        return setFormTimeDate({ ...formTimeDate, wdays: [...daysfilter] })
                      }}
                      style={{
                        background: flag ? "rgb(112 210 152)" : "#e0e0e0",
                        width: "20px",
                        color: flag ? "white" : "black",
                        cursor: "pointer",
                        borderRadius: "3px",
                        marginLeft: "0.2rem",
                        textAlign: "center"
                      }}
                    >
                      {element.label}
                    </div>)
                  })}
                </div>
              </Col>}


            <Col className="col-12 pt-3">
              <label
                htmlFor="start_date"
              >
                Start Date :
              </label>
              <input
                onChange={handleChange}
                name="start_date"
                value={formTimeDate.start_date}
                className="form-control"
                type="date"
              />
            </Col>

            <Col className="col-12 pt-3 ">
              <label
                htmlFor="time_window_start"
                className="m-0 pr-4"

              >
                Time:
              </label>
              <div className="d-flex align-items-center">
                <input
                  onChange={handleChange}
                  name="time_window_start"
                  disabled={formTimeDate.check}
                  value={
                    formTimeDate.time_window_start
                  }
                  className="form-control"
                  type="time"
                />
                <label htmlFor="time_window_stop" className="m-0 px-2">
                  To:
                </label>
                <input
                  onChange={handleChange}
                  disabled={formTimeDate.check}
                  name="time_window_stop"
                  value={
                    formTimeDate.time_window_stop
                  }
                  className="form-control"
                  type="time"
                />
                <div className="pl-2 d-flex align-items-center">
                  <CustomInput
                    onClick={(e) => {
                      setFormTimeDate({ ...formTimeDate, checked: e.target.checked, time_window_start: "00:00", time_window_stop: "23:59" });

                    }}
                    checked={formTimeDate.checked}
                    type="checkbox"
                    className="input-check"
                    id={"exampleCustomCheckbox"}
                  />
                  <label htmlFor="" className="m-0 px-2">
                    All Day
                  </label>
                </div>
              </div>

            </Col>
            <Col className="col-12 pt-5 ">

              <label
                htmlFor="time_window_start"
                className="m-0 pr-4"

              >
                Enable Override Codes
              </label>
              <Switch
                uncheckedIcon={<Offsymbol />}
                className="mr-1"
                checkedIcon={<OnSymbol />}
                onColor="#626ed4"
                onChange={() => {
                  setSwitchOne(!switchOne);
                }}
                height={24}
                width={48}
                checked={switchOne}
              />
            </Col>



            {switchOne && listOverrideCodes.map((element, i) => <Col className="col-12 pt-3">
              <label
                htmlFor="start_date"
              >
                {element.title}
              </label>
              <div className="d-flex">
                <span className="pr-2">*</span>
                <input
                  onChange={(e) => { handleChangeOverride(e.target.value, i) }}
                  value={element.input}
                  className="form-control"
                  type="number"
                />
              </div>
            </Col>
            )
            }
          </Row>
        ) : (
          <Row>
            <Col className="col-12 d-flex align-items-center">
              <label
                htmlFor="name"
                className="m-0 pr-2"
                style={{ width: "25%" }}
              >
                Name:
              </label>
              <input
                onChange={handleChangeGroup}
                name="name"
                value={formTimeGroup.name}
                className="form-control"
                type="text"
              />
            </Col>
          </Row>
        )}
      </ModalBody>

      <ModalFooter>
        <Button
          outline
          disabled={loading}
          color="secondary"
          onClick={() => {
            setModal(false);
            setFormTimeGroup({ name: "" });
            setFormTimeDate({
              name: "",
              cycle: "",
              time_window_start: "",
              time_window_stop: "",
              days: [""],
              start_date: "",
              month: "",
              ordinal: "",
              interval: "",
              time_group_id: "",
              enabled: "",
              wdays: [],
              check: false,
            });
          }}
        >
          Cancel
        </Button>
        <Button disabled={
          loading
        } color="primary" onClick={handleSubmit}>
          {loading && (
            <Spinner
              style={{ marginRight: "0.5em" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Save
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}
