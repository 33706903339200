import React from "react";
import PhoneCallStyle from "./PhoneCallStyle";
import Calls from "./MenuApps/Calls";

export default function PhoneCall({
  users,
  realm,
  activePhone,
  userAgentStart,
  userAgentEnd,
  userStatusProfile, setUserStatusProfile,

}) {

  return (
    <>
      <PhoneCallStyle>
        <div className={`phone-container ${activePhone && "phone-close"}`}>
          <Calls
            userStatusProfile={userStatusProfile}
            setUserStatusProfile={setUserStatusProfile}
            userAgentStart={userAgentStart}
            userAgentEnd={userAgentEnd}
            realm={realm}
            users={users}

          />
        </div>
      </PhoneCallStyle>
    </>
  );
}
