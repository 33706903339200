import React, { useState, useEffect } from "react";
import {
  Container,
  Table,
  Card,
  Spinner,
  Button,
  Dropdown,
  CustomInput,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import styled from "styled-components";
import petition_get from "../../../petitions/petition_get";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import ButtonHover from "../../../../components/utils/ButtonHover";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";

//Modals
import DeleteModal from "./Modals/DeleteModal";
import ConfirmModal from "./Modals/ConfirmModal";

const ButtonStyled = styled.button`
  background: transparent;
  padding: 0.3rem;
  border-radius: 10px;
  border: none;
  transition: 0.2s all;

  &:hover {
    svg {
      color: white !important;
    }
    transition: 0.2s all;
    transform: translateY(-2px);
    background: ${(props) => props && props.background};
  }
`;

const ContainerPagination = styled.div`
  padding: 20px;
  margin: 0;

  .btn-page {
    border: none;
    background: rgba(255, 255, 255, 0.1);
    color: #a6b0cf;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    margin: 0 5px;
    padding: 0;
    svg {
      color: #a6b0cf;
    }
    &:focus {
      outline: none;
    }
  }
  .active {
    background: #536be1;
  }

  @media (max-width: 768px) {
    padding: 0;
    .container-pages {
      display: flex;
      justify-content: center;
    }
  }
`;
const Pages = ({ data, setPagination, pagination, cantPagination }) => {
  let page = [];

  for (let i = 0; i < Math.trunc(data); i++) {
    page.push(i);
  }

  const onClickPage = (index) => {
    index = (index + 1) * cantPagination;
    setPagination(index);
  };
  return (
    <>
      {page.map((pag, index) => {
        if (
          (index + 1 >= pagination / cantPagination - 2 &&
            index + 1 <= pagination / cantPagination) ||
          (index + 1 <= pagination / cantPagination + 2 &&
            index + 1 >= pagination / cantPagination)
        ) {
          return (
            <button
              className={`btn-page ${
                pagination / cantPagination === index + 1 && "active"
              } `}
              onClick={() => {
                onClickPage(index);
              }}
              key={index}
            >
              {" "}
              {index + 1}{" "}
            </button>
          );
        }
      })}
    </>
  );
};
export default function AuthorizeTransfers() {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const cantPagination = 10;
  const [pagination, setPagination] = useState(cantPagination);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [data, setData] = useState([]);
  const [dataValues, setDataValues] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [selectedCheckBox, setSelectedCheckBox] = useState([]);
  useEffect(() => {
    const petitions = () => {
      petition_get("AuthorizationTrasfers", { id })
        .then(({ data: result }) => {
          const newNumbers = Object.values(result.result.numbers).map(
            (element) => {
              element.checked = false;
              return element;
            }
          );
          setData(Object.keys(result.result.numbers));

          setDataValues(newNumbers);
          console.log(result.result);
          setLoading(true);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    petitions();
  }, []);

  const onClickNext = () => {
    if (pagination < data.length) {
      setPagination(pagination + cantPagination);
    }
  };

  const onClickBack = () => {
    if (pagination > cantPagination) {
      setPagination(pagination - cantPagination);
    }
  };

  const handleDelete = (data) => {};

  const handleConfirm = (data) => {};

  

  const handleCheck = (value) => {
    if (value.number) {
      const findNumber = selectedCheckBox.find(
        (element) => element === value.number
      );
      if (!findNumber) {
        const copySelectedCheckbox = selectedCheckBox;
        copySelectedCheckbox.push(value.number);

        const copyDataValues = dataValues;
        data.map((element, i) => {
          if (element === value.number) {
            copyDataValues[i].checked = true;
          }
        });
        setDataValues(copyDataValues);
        return setSelectedCheckBox([...copySelectedCheckbox]);
      }
      const copyDataValues = dataValues;
      data.map((element, i) => {
        if (element === value.number) {
          copyDataValues[i].checked = false;
        }
      });
      setDataValues(copyDataValues);
      const newSelectedCheckBox = selectedCheckBox.filter(
        (element) => element !== value.number
      );
      setSelectedCheckBox(newSelectedCheckBox);
    } else {
      if (value) {
        const newValues = dataValues.map((element) => {
          element.checked = true;
          return element;
        });
        setDataValues(newValues);

        return setSelectedCheckBox(data);
      }
      setSelectedCheckBox([]);
      const newValues = dataValues.map((element) => {
        element.checked = false;
        return element;
      });
      setDataValues(newValues);
    }
  };

  useEffect(() => {
    console.log(selectedCheckBox);
  }, [selectedCheckBox]);

  return (
    <>
      <DeleteModal
      dataValues={dataValues}
      data={data}
        modal={modalDelete}
        loading={loadingDelete}
        handleDelete={handleDelete}
        setModal={setModalDelete}
        
      />
      <ConfirmModal
      dataValues={dataValues}

        data={data}
        modal={modalConfirm}
        loading={loadingConfirm}
        handleDelete={handleConfirm}
        setModal={setModalConfirm}
      />
      <div className="page-content">
        <Container fluid>
          <div
            className="d-flex"
            style={{ alignItems: "center", paddingBottom: "2.5rem" }}
          >
            <h3 style={{ margin: "0" }}>Numbers</h3>
            <span>
              <DoubleArrowIcon className="icon-arrow" />
            </span>
            <h4
              style={{
                color: "#a6b0cf",
                fontWeight: "400",
                margin: "0",
                fontSize: "15px",
                paddingTop: "7px",
              }}
            >
              Authorize Transfers
            </h4>
          </div>
        </Container>
        {loading ? (
          <>
            <Card body>
              <div className="table-responsive">
                {data.length === 0 ? (
                  <p style={{ textAlign: "center", fontSize: "2rem" }}>
                    You have no requests
                  </p>
                ) : (
                  <>
                    <Dropdown
                      style={{ marginBottom: "1.3rem" }}
                      isOpen={dropdownOpen}
                      toggle={() => {
                        setDropdownOpen(!dropdownOpen);
                      }}
                    >
                      <DropdownToggle caret>Bulk Actions</DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setModalConfirm({selectedCheckBox, bulkActions: true});
                          }}
                        >
                          Confirm
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setModalDelete({selectedCheckBox, bulkActions: true});
                          }}
                        >
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>{" "}
                    <Table hover className="table mb-0">
                      <thead className="thead-light">
                        <tr>
                          <th>
                            <CustomInput
                              onClick={(e) => {
                                handleCheck(e.target.checked);
                              }}
                              type="checkbox"
                              className="input-check"
                              name=""
                              id="exampleCustomCheckbox"
                            />
                          </th>
                          <th style={{ textAlign: "center" }}>Number </th>
                          <th style={{ textAlign: "center" }}>
                            Request Account Name{" "}
                          </th>
                          <th style={{ textAlign: "center" }}>Request Date </th>

                          <th style={{ textAlign: "center" }}>Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        {data.map((element, i) => {
                          if (
                            i < pagination &&
                            i >= pagination - cantPagination
                          ) {
                            return (
                              <tr key={i}>
                                <th>
                                  <CustomInput
                                    onClick={(e) => {
                                      handleCheck({ number: element });
                                    }}
                                    checked={dataValues[i].checked}
                                    type="checkbox"
                                    className="input-check"
                                    name=""
                                    id={`exampleCustomCheckbox-${element}`}
                                  />
                                </th>
                                <th style={{ textAlign: "center" }}>
                                  {element}
                                </th>
                                <th style={{ textAlign: "center" }}>
                                  {dataValues[i].request_account_name}{" "}
                                </th>
                                <th style={{ textAlign: "center" }}>
                                  {dataValues[i].request_date
                                    .slice(0, 16)
                                    .replace("T", " ")}
                                </th>

                                <th style={{ textAlign: "center" }}>
                                  <ButtonStyled
                                    background="#007bff"
                                    onClick={() => {
                                      setModalConfirm(
                                        dataValues[i].service_addon_id
                                      );
                                    }}
                                    style={{ marginRight: "10px" }}
                                  >
                                    <CheckIcon style={{ color: "#007bff" }} />
                                  </ButtonStyled>
                                  <ButtonStyled
                                    background="#dc3545"
                                    onClick={() => {
                                      setModalDelete(
                                        dataValues[i].service_addon_id
                                      );
                                    }}
                                  >
                                    <ClearIcon style={{ color: "#dc3545" }} />
                                  </ButtonStyled>
                                </th>
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                    </Table>{" "}
                    <ContainerPagination>
                      {data && data.length > cantPagination && (
                        <div className="col-12 container-pages">
                          <button
                            className="btn-page pl-2"
                            onClick={onClickBack}
                          >
                            {" "}
                            <ArrowBackIosIcon />{" "}
                          </button>
                          <Pages
                            pagination={pagination}
                            setPagination={setPagination}
                            cantPagination={cantPagination}
                            data={
                              (data.length / cantPagination) % 1 === 0
                                ? data.length / cantPagination
                                : data.length / cantPagination + 1
                            }
                          />
                          <button className="btn-page" onClick={onClickNext}>
                            {" "}
                            <ArrowForwardIosIcon />
                          </button>
                        </div>
                      )}
                    </ContainerPagination>{" "}
                  </>
                )}
              </div>
            </Card>
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Spinner
              animation="grow"
              style={{
                margin: "10vh auto",
                height: "10vh",
                width: "10vh",
              }}
            />{" "}
          </div>
        )}{" "}
      </div>{" "}
    </>
  );
}
