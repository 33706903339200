import React, { useState } from "react";

import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';

import ModalUtilityContact from "../ModalUtility/ModalUtilityContact"
import ModalUtilityAddContact from "../ModalUtility/ModalAddContact"

import SidebarChat from "./SidebarChat"
import SidebarCampaign from "./SidebarCampaign"

import json from "../../../../config.json"

//Import Images

export default function Sidebar(props) {


  const [contactAddModal, setContactAddModal] = useState(false)
  const [contactModal, setContactModal] = useState(false)
  const [reloadData, setReloadData] = useState(false)

  return (
    <div >

      <ModalUtilityContact setReloadData={setReloadData} modal={contactModal} setModal={setContactModal} />
      <ModalUtilityAddContact setReloadData={setReloadData} modal={contactAddModal} setModal={setContactAddModal} />


      <div className="chat-leftsidebar-nav">
        <Nav pills justified>
          <NavItem>
            <NavLink
              className={classnames({ active: props.tabToggle === '1' })}
              onClick={() => { props.setTabToggle('1'); }}
            >
              <i className="bx bx-chat font-size-19 d-sm-none"></i>
              <span className="d-none d-sm-block">Chat</span>
            </NavLink>
          </NavItem>
          {!json.prodChat && <> <NavItem>
            <NavLink
              className={classnames({ active: props.tabToggle === '2' })}
              onClick={() => { props.setTabToggle('2'); }}
            >
              <i className="bx bx-group font-size-19 d-sm-none"></i>
              <span className="d-none d-sm-block">Campaign</span>
            </NavLink>
          </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: props.tabToggle === '3' })}
                onClick={() => { props.setTabToggle('3'); }}
              >
                <i className="bx bx-book-content font-size-19 d-sm-none"></i>
                <span className="d-none d-sm-block">AutoResponders</span>
              </NavLink>
            </NavItem> </>}

        </Nav>
        <TabContent activeTab={props.tabToggle} className="py-4">
          <TabPane tabId="1">
            <SidebarChat {...props} setReloadData={setReloadData} reloadData={reloadData} setContactAddModal={setContactAddModal} setContactModal={setContactModal} />
          </TabPane>

          <TabPane tabId="2">  <SidebarCampaign /> </TabPane>

          <TabPane tabId="3">
            <h5 className="font-size-14 mb-3">Auto Responders</h5>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
}
