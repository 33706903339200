import React, { useEffect, useState } from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";
import petition_get from "../../../../petitions/petition_get";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Table,
  CustomInput
} from "reactstrap";

import { v4 as uuidv4 } from 'uuid';
import toastr from "toastr";
import FlowChart from "../../../../../components/FlowChart/FlowChart";
export default function ModalUtilityEdit({
  modal,
  setModal,
  loading,
  editCallFlows,
  setReloadData,
}) {
  toastr.options = {
    showDuration: 300,
  };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const [data, setData] = useState(null);
  const [dataSave, setDataSave] = useState(null);
  const [dataUsers, setDataUsers] = useState(null);
  const [getData, setGetData] = useState(null);
  const [activeSave, setActiveSave] = useState(false);
  const [chartSimpleData, setChartSimpleData] = useState(null);

  const [firstModal, setFirstModal] = useState(true)

  const [selectedUsers, setSelectedUsers] = useState([]);

  const [activeInputNumber, setActiveInputNumber] = useState(false)
  const [reload, setReload] = useState(false)


  const [form, setForm] = useState({
    nameCallflow: "",
    inputNumber: "",
    addNumbers: [],
    selectedNumbers: [],
  })

  const dataChart = {
    menuOptions: ["Callflow"],
  };

  const handleCheck = (element) => {
    const findOne = selectedUsers.find(
      (elementTwo) => elementTwo.number === element.number
    );

    let newSelectedUsers;
    if (findOne) {
      newSelectedUsers = selectedUsers.filter(
        (elementTwo) => elementTwo.number !== element.number
      );
    } else {
      newSelectedUsers = selectedUsers;
      newSelectedUsers.push(element);
    }

    setSelectedUsers(newSelectedUsers);
    setReload(!reload)
  };

  const handleCheckAll = () => {
    if (selectedUsers.length === 0) return setSelectedUsers(dataUsers)
    setSelectedUsers([])
  }


  useEffect(() => {
    if (modal) {
      setFirstModal(true)
      setData(null);
      setDataSave(null);
      setChartSimpleData(null);
      setDataUsers(null)


      let petitions = async () => {
        let users;
        let numbersCopy = []

        await petition_get("view", { id: id })
          .then(({ data: result }) => {
            Object.keys(result.result.numbers).map(element => {

              if (!result.result.numbers[element].used_by) numbersCopy.push({ number: element })
            })

          })
          .catch((error) => {
            console.log(error);
            // setLoading(true);
          });

        petition_get("callflowsDetails", { id, callflow: modal.id })
          .then(({ data: result }) => {
            let selectedUsersCopy = []
            let dataUsersCopy = []

            console.log("callflows", result)

            result.result.numbers.map(element => {
              let findOne = numbersCopy.find(elementTwo => elementTwo.number === element)
              if (findOne) selectedUsersCopy.push(findOne)
              else {
                let generateId = uuidv4()
                selectedUsersCopy.push({ delete: true, number: element, id: generateId })
                dataUsersCopy.push({ delete: true, number: element, id: generateId })
              }
            })


            setSelectedUsers(selectedUsersCopy)

            console.log('callflows', [...numbersCopy, ...dataUsersCopy])
            setDataUsers([...numbersCopy, ...dataUsersCopy])



            setForm({ ...form, nameCallflow: result.result.name })
            setData(result.result);
            setDataSave(result.result);
            if (result.result.dataChart) {
              setChartSimpleData(result.result.dataChart);

            } else {
              setChartSimpleData({
                offset: {
                  x: 0,
                  y: 0,
                },
                scale: 1,
                nodes: {
                  node1: {
                    id: "node1",
                    type: `Callflow_1`,
                    position: {
                      x: 300,
                      y: 20,
                    },
                    ports: {
                      portAdd_1: {
                        id: "portAdd_1",
                        type: "bottom",
                        properties: {
                          value: "yes",
                          linkColor: 'white',

                        },
                      },
                    },
                  },
                },
                links: {},
                selected: {},
                hovered: {},
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      petitions()
    }


  }, [modal]);

  const onChangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }



  const handleAddNumber = () => {

    //If the Number is repeated
    if (dataUsers.find(element => element.number === form.inputNumber)) {
      toastr.error("This Number is Repeated", "Error");
      setActiveInputNumber(false)
      return setForm({ ...form, inputNumber: "" })
    }

    if (form.inputNumber.trim() !== "") {

      let generateId = uuidv4()

      //push number in data
      setDataUsers([...dataUsers, { delete: true, number: form.inputNumber, id: generateId }])

      //push number in selectnumbers
      setSelectedUsers([...selectedUsers, { delete: true, number: form.inputNumber, id: generateId }])

      //reset
      setForm({ ...form, inputNumber: "" })
      setActiveInputNumber(false)
    }

  }


  const saveFirstModal = () => {

    if (form.nameCallflow.trim() === "") return toastr.error("Need Call flow Name", "Error");
    if (selectedUsers.length === 0) return toastr.error("Need One Number", "Error");

    setForm({ ...form, addNumbers: selectedUsers })
    setFirstModal(false)
  }


  return (
    <Modal size="xl" centered={true} isOpen={modal ? true : false}>
      <ModalBody>

        {dataUsers ? <>
          {firstModal && <>
            <h3 className="text-center pb-3" style={{ opacity: "0.7" }}> CallFlow </h3>
            <p className="text-left mb-2"> Please set a callflow name. </p>

            <input value={form.nameCallflow} onChange={onChangeForm} type="text" name="nameCallflow" id="" className="form-control col-12 col-md-3 mb-5" />

            <p className="text-left"> Please select phone number or unique extension number in the system that triggers this callflow. </p>

            <Table className="mt-4" hover size={"md"}>
              <thead className="thead-light">
                <tr>
                  <th className="text-center ">
                    <CustomInput
                      onClick={(e) => {
                        handleCheckAll();
                      }}
                      type="checkbox"
                      className="input-check"
                      name=""
                      id={`check-th`}
                    />

                  </th>
                  <th className="text-center "> Number </th>
                </tr>
              </thead>

              <tbody>


                {dataUsers.map((element, i) => {
                  let flag = false;
                  const findOne = selectedUsers.find(
                    (elementTwo) =>
                      elementTwo.number === element.number
                  );
                  if (findOne) flag = true;
                  return (
                    <>
                      <tr>
                        <th className=" py-3 text-center  pl-2" key={i}>
                          <CustomInput
                            onClick={(e) => {
                              handleCheck(element);
                            }}

                            checked={flag}
                            type="checkbox"
                            className="input-check"
                            name=""
                            id={`check-${i}`}
                          />

                        </th>

                        <th className="text-center "> {element.number} </th>
                      </tr>
                    </>
                  );
                })}

              </tbody>
            </Table>

            {activeInputNumber ?
              <div className="d-flex text-left">
                <input value={form.inputNumber} onChange={onChangeForm} type="number" name="inputNumber" id="" className="form-control col-12 col-md-3 mr-2" />
                <Button

                  disabled={loading}
                  color="primary"
                  onClick={handleAddNumber}
                >
                  Save
                </Button>
              </div> : <Button
                outline
                disabled={loading}
                color="secondary"
                onClick={() => {
                  setActiveInputNumber(true)
                }}
              >
                Add Number
              </Button>}
          </>

          }
          <div style={{ display: firstModal ? "none" : "inherit" }}>
            {chartSimpleData && (
              <FlowChart
                form={form}
                setFirstModal={setFirstModal}
                data={data}
                dataSave={dataSave}
                setReloadData={setReloadData}
                setModal={setModal}
                activeSave={activeSave}
                setActiveSave={setActiveSave}
                dataChart={dataChart}
                setGetData={setGetData}
                chartSimpleData={chartSimpleData}
              />
            )}
          </div></> : <div style={{ textAlign: "center" }}>
          <Spinner
            animation="grow"
            style={{
              margin: "10vh auto",
              height: "10vh",
              width: "10vh",
            }}
          />{" "}
        </div>}


      </ModalBody>

      <ModalFooter>
        {" "}
        <Button
          outline
          disabled={loading || activeSave}
          color="secondary"
          onClick={() => {
            setModal(false);
            setSelectedUsers([])
            setForm({
              nameCallflow: "",
              inputNumber: "",
              addNumbers: [],
              selectedNumbers: [],
            })
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={loading || activeSave}
          color="primary"
          onClick={() => {
            if (firstModal) return saveFirstModal()
            setActiveSave(true);

          }}
        >
          {(loading || activeSave) && (
            <Spinner
              style={{ marginRight: "0.5em" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {firstModal ? "Next" : "Save"}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}
