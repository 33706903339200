import React, { useState, useEffect } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Table } from "reactstrap"
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DeleteIcon from '@material-ui/icons/Delete';
export default function GroupContact({ contacts, form, setForm }) {


    const [open, setOpen] = useState(false)
    const [selectOption, setSelectOption] = useState(0)
    const [selectedContacts, setSelectedContacts] = useState([])

    const [inputContact, setInputContact] = useState("")

    const handleChangeSelect = (e) => { setInputContact(e.target.value) }


    const handleAddContact = () => {

        let value = inputContact

        if (parseInt(inputContact)) value = parseInt(value)


        const findContact = contacts.find(element => (element.pk) === value)

        const findDuplicateContact = selectedContacts.find(element => (element.pk) === value)

        if (findContact && !findDuplicateContact && inputContact.trim() !== "") {
            let newListContact = selectedContacts;
            newListContact.push(findContact)

            let getContactPk = []
            newListContact.map(element => getContactPk.push(element.pk))

            setForm({ ...form, contacts: getContactPk })

            setSelectedContacts(newListContact)

        }

        setInputContact("")
        setSelectOption(0)

    }


    const handleDeleteContact = (pk) => {
        let newListContact = selectedContacts.filter(element => element.pk !== pk);
        let getContactPk = []
        newListContact.map(element => getContactPk.push(element.pk))

        setForm({ ...form, contacts: getContactPk })
        setSelectedContacts(newListContact)

    }


    useEffect(() => { setSelectedContacts(form.contacts || []) }, [])


    return (
        <div className="my-3 ">
            <div className="row justify-content-end ">

                <Dropdown isOpen={open} className="col-12 col-md-5 p-0" toggle={() => { setOpen(!open) }}>
                    <DropdownToggle className="" tag="i">
                        <Button color="primary" className="align-items-center w-100"> <GroupAddIcon />  Add Contact <KeyboardArrowDownIcon /> </Button>

                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={() => { setSelectOption(1) }}>Add</DropdownItem>
                        <DropdownItem onClick={() => { setSelectOption(2) }}>Import</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>

            {selectOption === 0 && selectedContacts.length > 0 && <div className="pt-3">  <Table>
                <thead>
                    <tr>
                        <th> Name</th>

                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {selectedContacts.map((element, i) => (
                        <tr key={i}>
                            <td>  {element.name}   </td>
                            <td>  <DeleteIcon onClick={() => { handleDeleteContact(element.pk) }} />    </td>
                        </tr>
                    ))}
                </tbody>
            </Table> </div>}


            {selectOption === 1 &&
                <div className="pt-5">

                    <select onChange={handleChangeSelect} value={inputContact} className="form-control">

                        <option value="" > Select One </option>
                        {contacts.map(element => element.pk && <option value={element.pk} > {element.name} </option>)}

                    </select>
                    <Button onClick={handleAddContact} color="primary" className="align-items-center w-100 mt-2">  Save Contact  </Button>
                </div>

            }
            {selectOption === 2 && <div> import </div>}

        </div>
    )
}
