import React, {useState, useEffect} from "react";
import phoneImg from "../../assets/images/phone-image.svg";
import classnames from "classnames";
import petition_post from "../petitions/petition_post";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CustomInput,
} from "reactstrap";
import toastr from "toastr";
export default function ModalUtility({modal, setModal}) {
  toastr.options = {
    showDuration: 300,
  };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    subject: "",
    descriptipn: "",
  });

  const onChangeForm = (e) => {
    setForm({...form, [e.target.name]: e.target.value});
  };

  const handleSubmit = async () => {
    setModal(false);
    setLoading(true);

    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));

      let data = {
        subject: form.subject,
        description: form.description,
        assigned_text_ud: null,
        status: "Open",
        priority: "Normal",
        account_id: obj.account_id,
        contact_id: obj.pk,
      };

      await petition_post("addTicket", {data})
        .then(({data: result}) => {
          toastr.success("Action completed", "Success");

          setLoading(false);
          setModal(false);
        })
        .catch((error) => {
          toastr.error("Action failed", "Error");
          setLoading(false);
        });
    }
  };

  return (
    <Modal centered={true} isOpen={modal} className="info ">
      <ModalHeader className="bg-soft-primary">
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary">Add Ticket</h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Row>
          {/*  <Col
            className="d-flex col-12 pt-4"
            style={{display: "flex", flexDirection: "column"}}
          >
            <Label
              htmlFor="name"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              Name:
            </Label>
            <input
              className="form-control"
              id="name"
              name="name"
              type="text"
              onChange={onChangeForm}
              value={form.name}
              placeholder="Name"
            />
          </Col>

          <Col
            className="d-flex col-12 pt-4"
            style={{display: "flex", flexDirection: "column"}}
          >
            <Label
              htmlFor="email"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              Email
            </Label>
            <input
              className="form-control"
              id="email"
              name="email"
              type="number"
              onChange={onChangeForm}
              value={form.email}
              placeholder="Email"
            />
          </Col> */}
          <Col
            className="d-flex col-12 pt-4"
            style={{display: "flex", flexDirection: "column"}}
          >
            <Label
              htmlFor="subject"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              Subject
            </Label>
            <input
              className="form-control"
              id="subject"
              name="subject"
              type="text"
              onChange={onChangeForm}
              value={form.subject}
              placeholder="Subject"
            />
          </Col>
          <Col
            className="d-flex col-12 pt-4"
            style={{display: "flex", flexDirection: "column"}}
          >
            <Label
              htmlFor="descriptipn"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              Message
            </Label>
            <textarea
              className="form-control"
              id="message"
              name="descriptipn"
              type="text"
              onChange={onChangeForm}
              value={form.descriptipn}
              placeholder="Message"
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          outline
          color="secondary"
          onClick={() => {
            setModal(false);
          }}
        >
          Cancel
        </Button>

        <Button disabled={loading} color="primary" onClick={handleSubmit}>
          {loading && (
            <Spinner
              style={{marginRight: "0.5em"}}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
