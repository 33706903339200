import React, { useEffect, useState, useRef } from "react";
import { Container, Card, Table, Spinner, CustomInput, Button } from "reactstrap";

import CardTools from "./CardTools/CardTools";

//Icons
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

import CustomBreadCrum from "../../../../components/utils/CustomBreadCrum/CustomBreadCrum"
import CustomTable from "../../../../components/utils/CustomTable/CustomTable"
import NavTabCustom from "../../../../components/utils/NavTabCustom";


import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import { addDays } from "date-fns";

import styled from "styled-components";
// import {DateRangePicker} from "react-date-range";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import petition_get from "../../../petitions/petition_get";
import petition_post from "../../../petitions/petition_post";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import SelectCallReports from "./SelectCallReports"
import UsersReports from "./NavReports/UsersReports"
import NumberReports from "./NavReports/NumberReports"
import CompleteReport from "./NavReports/CompleteReport"



export default function CallHistory() {



  const [users, setUsers] = useState(null);
  const [timezone, setTimezone] = useState(null)
  const [dataReport, setDataReport] = useState(null)

  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const [modal, setModal] = useState(false);
  const [element, setElement] = useState({});
  const [data, setData] = useState([]);

  const [step, setStep] = useState(0);

  useEffect(() => {

    petition_get("itpvoiceUsers", { id: id })
      .then(({ data: res }) => {
        res.result.forEach((element) => { element["check"] = true; });

        setUsers(res.result);
      })
      .catch((error) => console.log(error));

    petition_get("accountDetails", { id: id })
      .then(({ data: result }) => setTimezone(result.result.data.timezone))
      .catch((error) => console.log(error));



  }, []);



  return (

    <div className="page-content">
      <Container fluid>
        <CustomBreadCrum title="Reports" subtitle="Call Reports" />

        {/* {step === 1 && <CardTools
          reloadFilters={reloadFilters}
          setPagination={setPagination}
          dataPickerFilter={dataPickerFilter}
          setDataPickerFilter={setDataPickerFilter}
          filter={filter}
          setFilter={setFilter}
          setDataFilter={setDataFilter}
          runReport={runReport}
          setDirection={setDirection}
          data={data}
          direction={direction}
          resetAll={resetAll}
        />} */}

        {timezone && <NavTabCustom options={[{ title: "User Reports", component: <UsersReports timezone={timezone} dataReport={dataReport}> <SelectCallReports users={users} setStep={setStep} setDataReport={setDataReport} /> </UsersReports> }, { title: "Number Reports", component: <NumberReports timezone={timezone} dataReport={dataReport} /> }, { title: "Call Detailed Records", component: <CompleteReport timezone={timezone} /> }]} />}




      </Container>
    </div>
  );
}
