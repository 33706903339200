import React, {useState, useEffect} from "react";
import CustomTable from "../../components/utils/CustomTable/CustomTable";
import petition_get from "../petitions/petition_get";
import {Table, Badge} from "reactstrap";
export default function OrderItems({modal}) {
  const [dataTable, setDataTable] = useState(null);

  const Text = () => {
    return <h3> hii </h3>;
  };

  useEffect(() => {
    if (modal) {
      petition_get("ordersItems", modal.pk)
        .then(({data: {result}}) => {
          console.log(result);

          setDataTable(result);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [modal]);

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Product</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {dataTable?.map((orderItem) => (
            <tr>
              <th> {orderItem.product.name} </th>
              <th>
                <Badge color={orderItem.completed ? "success" : "primary"}>
                  {orderItem.completed
                    ? "Complete"
                    : orderItem.provision_status}
                </Badge>
              </th>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* <CustomTable bordered border data={dataTable} /> */}
    </div>
  );
}
