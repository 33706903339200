import React, {useState} from "react";
import {Col, Row, Label, Input} from "reactstrap";
import Switch from "react-switch";
export default function BasicSettings({onChange, form, setForm}) {
  const [switchOne, setSwitchOne] = useState(form.ignore_forward);
  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };
  return (
    <Row>
      <Col className="d-flex col-12 col-lg-9 pt-4 row">
        {" "}

           <Col
          className="col-12 col-lg-4"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        > <Label
          htmlFor="name"
          style={{
            cursor: "pointer",
            margin: "0",
          }}
        >
          Ring Group Name
        </Label>{" "}
        </Col>
        <Col className="col-12 col-lg-5 mt-2 mt-lg-0">  <Input
          value={form.name}
          onChange={onChange}
          id="name"
          type="text"
          name="name"
        />{" "}
        </Col>
       
      
      </Col>
      <Col className="d-flex col-12 col-lg-9 pt-4 row">

            <Col
          className="col-12 col-lg-4"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >    <Label
          htmlFor="strategy"
          style={{
            cursor: "pointer",
            margin: "0",
          }}
        >
          Ring Strategy
        </Label>{" "}
        </Col>
        <Col className="col-12 col-lg-5 mt-2 mt-lg-0">     <select
          value={form.strategy}
          onChange={onChange}
          className="form-control"
          name="strategy"
          id="strategy"
        >
          <option value="">---Select One---</option>
          <option value="single">Single</option>
          <option value="simultaneous">Simultaneous</option>
        </select>
        </Col>
    
   
      </Col>
      <Col className="d-flex col-12 col-lg-9 pt-4 row"> 

          <Col
          className="col-12 col-lg-4"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >     <Label
          htmlFor="seconds"
          style={{
            cursor: "pointer",
            margin: "0",
          }}
        >
          Timeout (Seconds)
        </Label>{" "}
        </Col>
        <Col className="col-8 col-md-4 col-lg-2 mt-2 mt-lg-0">
          <Input
          name="timeout"
          value={form.timeout}
          onChange={onChange}
          id="timeout"
          type="text"
        />{" "}
        </Col>
   
      
      </Col>
      <Col className="d-flex col-12 col-lg-9 pt-4 row">

           <Col
          className="col-12 col-lg-4"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >        <Label
          style={{
            margin: "0",
          }}
        >
          Ignore Call Forwarding
        </Label>{" "}
        </Col>
        <Col className="col-8 col-md-4 col-lg-2 mt-2 mt-lg-0">
         <Switch
          uncheckedIcon={<Offsymbol />}
          className="mr-1"
          checkedIcon={<OnSymbol />}
          onColor="#626ed4"
          onChange={() => {
            setForm({...form, ignore_forward: !switchOne});
            setSwitchOne(!switchOne);
          }}
          checked={switchOne}
        />
        </Col>

      
      </Col>
    </Row>
  );
}
