import React, { useState } from "react";
import verificationImg from "../../../../../../assets/images/verification-img.png";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Badge,
} from "reactstrap";

export default function ModalUtility({
  modal,
  setModal,
  loading,
  deleteGroup
}) {

  return (
    <Modal centered isOpen={modal ? true : false} className="info">
      <ModalHeader className="bg-soft-primary">
        <Row>
          <Col xs="9">
            <div className="text-primary p-3">
              <h3 className="text-primary"> Delete Group</h3>
            </div>
          </Col>
          <Col xs="3" className="align-self-end">
            <img src={verificationImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <div className="text-center" style={{ fontSize: "20px" }}>
          Are you sure you want to delete this Group?
        </div>
      </ModalBody>{" "}
      <ModalFooter>
        <Button
          outline
          color="secondary"
          onClick={() => { setModal(false); }} >
          Cancel
        </Button>
        <Button
          disabled={loading}
          color="primary"
          onClick={() => {
            deleteGroup(modal);
          }}
        >
          {loading && (
            <Spinner
              style={{ marginRight: "0.5em" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
}
