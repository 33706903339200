import React, { useEffect, useState } from 'react'
import { Row, Col, CustomInput, Button, Table } from "reactstrap"
import petition_post from "../../../petitions/petition_post";
import petition_patch from "../../../petitions/petition_patch";
import petition_get from "../../../petitions/petition_get";
import toastr from "toastr";
import Switch from "react-switch";

export default function TimeDate({ activeSubmitTimeDate, setLoading, dataTimeDateId, setModal, setReloadData }) {

    toastr.options = { showDuration: 300, };
    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    const [switchOne, setSwitchOne] = useState(false)
    const [listOverrideCodes, setListOverridesCodes] = useState([
        { title: "Forced Enable Code", numbers: [], input: "" },
        { title: "Forced Disable Code", numbers: [], input: "" },
        { title: "Forced Reset Code", numbers: [], input: "" },

    ])

    useEffect(() => {
        if (activeSubmitTimeDate.activeSubmitTimeDate) {
            activeSubmitTimeDate.setActiveSubmitTimeDate(false)
            if (form.name.trim() === "") toastr.error("Name is Required", "Error");
            else if (form.start_date.trim() === "") toastr.error("Start Date is Required", "Error");
            else if (form.time_window_start.trim() === "") toastr.error("Start Time is Required", "Error");
            else if (form.time_window_stop.trim() === "") toastr.error("Stop Time is Required", "Error");
            else if (form.wdays.length === 0 && form.ordinal !== "every") toastr.error("Wdays is Required", "Error");
            else if (form.cicly === "monthly" && form.ordinal === "") toastr.error("On is Required", "Error");
            else {

                handleSubmit()
            }
        }

    }, [activeSubmitTimeDate.activeSubmitTimeDate])

    const [form, setForm] = useState({
        name: "",
        cycle: "weekly",
        start_date: "",
        time_window_start: "",
        time_window_stop: "",
        interval: "1",
        checked: false,
        ordinal: "",
        wdays: [],
        days: []
    })

    const handleChange = (e) => {
        if (e.target.name == "cycle")
            return setForm({ ...form, [e.target.name]: e.target.value, month: (e.target.value === "monthly" || e.target.value === "yearly") ? "1" : null, interval: e.target.value === "yearly" ? "1" : "1", ordinal: (e.target.value === "yearly" || e.target.value === "monthly") ? "first" : null })
        setForm({ ...form, [e.target.name]: e.target.value })
    }


    const handleSubmit = async () => {


        listOverrideCodes.map(element => element.input === `*${element.input}`)
        setLoading(true);
        const calculateTimeStart = form.time_window_start.split(":")
        const calculateTimeStop = form.time_window_stop.split(":")
        const start_date_transform = new Date(form.start_date)
        start_date_transform.setFullYear(start_date_transform.getFullYear() + 1970)

        const newData = { ...form, itpvoice_metadata: { activeOverrideCode: switchOne ? listOverrideCodes : null, } };
        newData.time_window_start = form.checked
            ? 0
            : ((calculateTimeStart[0] * 60 * 60) + calculateTimeStart[1] * 60)
        newData.time_window_stop = form.checked
            ? 86400
            : ((calculateTimeStop[0] * 60 * 60) + calculateTimeStop[1] * 60)
        newData.start_date = start_date_transform.getTime() / 1000 || ""
        newData.time_group_id = dataTimeDateId;


        if (newData.cycle === "weekly") delete newData.ordinal
        if (!newData.month) delete newData.month
        if (newData.ordinal !== "every") delete newData.days

        let numbersResult = [listOverrideCodes[0].input, listOverrideCodes[1].input, listOverrideCodes[2].input]
        let findOne = false;
        await petition_get("allCallflows", { id }).then(({ data: result }) => {
            result.result.map(element => {
                element.numbers.map(elementTwo => {
                    const findTwo = numbersResult.find(elementThree => elementThree === elementTwo)
                    if (findTwo) findOne = findTwo
                })
            })
        }).catch((error) => console.log(error))

        if (switchOne && (listOverrideCodes[0].input === "*" || listOverrideCodes[1].input === "*" || listOverrideCodes[2].input === "*")) {
            toastr.error(`You need one number in each field of the overrides codes`, "Error");
            return setLoading(false)
        }

        if (switchOne && findOne) {
            toastr.error(`The number ${findOne} is used in other Callflow.`, "Error");
            return setLoading(false)
        }

        const findRepeatNumbers = [... new Set(numbersResult)];


        console.log(findRepeatNumbers)
        if (switchOne && findRepeatNumbers.length !== 3) {
            toastr.error(`The numbers is repeat  in the fiels of overrides.`, "Error");
            return setLoading(false)
        }

        petition_post("timeDate", { id, data: newData })
            .then(({ data: result }) => {
                if (switchOne) {
                    let petitions = [];
                    let timeId = result.result.id
                    listOverrideCodes.map((element, i) => {

                        let action;

                        if (i === 0) action = "enable";
                        else if (i === 1) action = "disable";
                        else if (i === 2) action = "reset";


                        let dataTemporalRoute = {
                            flow: {
                                data: {
                                    action,
                                    rules: [`${result.result.id}`]
                                },
                                module: "temporal_route",
                                children: {},
                            },
                            type: "TemporalRouteCallflow",
                            numbers: [`*${element.input}`],
                            patterns: [],
                        }

                        petitions.push(petition_post("callflow", { id, data: dataTemporalRoute }))
                    })
                    Promise.all(petitions).then(result => {

                        let callflowsData = []
                        result.map(element => callflowsData.push(element.data.result.id))
                        newData.itpvoice_metadata.callflows = callflowsData

                        petition_patch("timeDate", { id, data: newData, time_id: timeId })
                            .then(({ data: result }) => {
                                console.log(result)
                                setLoading(false);
                                setModal(false);
                                toastr.success("Action completed", "Success");
                                setReloadData(true);
                            })
                            .catch((error) => {
                                setLoading(false)
                                console.log(error)
                                toastr.error("Action failed", "Error");
                            });

                    }).catch((error) => console.log(error));
                }
                else {
                    setLoading(false);
                    setModal(false);
                    toastr.success("Action completed", "Success");
                    setReloadData(true);
                }
            })
            .catch((error) => console.log(error));
    }


    const wDaysOptions = [
        { label: "S", value: "sunday" },
        { label: "M", value: "monday" },
        { label: "T", value: "tuesday" },
        { label: "W", value: "wednesday" },
        { label: "T", value: "thursday" },
        { label: "F", value: "friday" },
        { label: "S", value: "saturday" },
    ]

    const Offsymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 10,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                <p style={{ marginTop: "1.5em" }}>No</p>
            </div>
        );
    };
    const OnSymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 10,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                <p style={{ marginTop: "1.5em" }}>Yes</p>
            </div>
        );
    };


    const handleChangeOverride = (e, i) => {



        let newOverrideList = [...listOverrideCodes]
        newOverrideList[i].input = e;

        setListOverridesCodes(newOverrideList)
    }

    return (
        <Row>
            <Col className="col-12">
                <label htmlFor="name"  >
                    Name:
                </label>
                <input
                    onChange={handleChange}
                    name="name"
                    value={form.name}
                    className="form-control"
                    type="text"
                />
            </Col>
            <Col className="col-12 pt-3">
                <label htmlFor="cycle"  >
                    Repeats:
                </label>
                <select

                    onChange={handleChange}
                    name="cycle"
                    value={form.cycle}
                    className="form-control"
                >
                    <option value="weekly">Weekly</option>
                    <option value="monthly"> Monthly</option>
                    <option value="yearly">Yearly</option>
                </select>
            </Col>
            {form.cycle === "weekly" &&
                <Col className="col-12 pt-3">
                    <label
                        htmlFor="interval"
                    >
                        interval (week(s)):
                    </label>
                    <input
                        onChange={handleChange}
                        name="interval"
                        value={form.interval}
                        className="form-control"
                        type="number"
                    />
                </Col>}

            {form.cycle === "monthly" &&
                <Col className="col-12 pt-3">
                    <label
                        htmlFor="interval"
                    >
                        Every (month(s)):
                    </label>
                    <input
                        onChange={handleChange}
                        name="month"
                        value={form.month}
                        className="form-control"
                        type="number"
                    />
                </Col>}
            {form.cycle === "yearly" &&
                <Col className="col-12 pt-3">
                    <label
                        htmlFor="interval"
                    >
                        Every :
                    </label>
                    <select
                        onChange={handleChange}
                        name="month"
                        value={form.month}
                        className="form-control"
                    >
                        <option value="1">January</option>
                        <option value="2"> Febrary</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5"> May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8"> August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11"> November</option>
                        <option value="12">December</option>
                    </select>
                </Col>}
            {(form.cycle === "yearly" || form.cycle === "monthly") &&
                <Col className="col-12 pt-3">
                    <label
                        htmlFor="on"
                    >
                        On :
                    </label>
                    <div className="d-flex">

                        <select
                            onChange={handleChange}
                            name="ordinal"
                            value={form.ordinal}
                            className="form-control mr-1"
                        >
                            <option value="">Select One</option>
                            <option value="first">First</option>
                            <option value="second"> Second</option>
                            <option value="third">Third</option>
                            <option value="fourth">Fourth</option>
                            <option value="fifth"> Fifth</option>
                            <option value="last">Last</option>
                            <option value="every">Day</option>
                        </select>


                        <select
                            onChange={(e) => { setForm({ ...form, [form.ordinal === "every" ? `days` : `wdays`]: [e.target.value] }) }}
                            name="ordinal"
                            value={form.ordinal === "every" ? form.days[0] : form.wdays[0]}
                            className="form-control ml-1"
                        >
                            <option value="">Select One</option>
                            {form.ordinal === "every" ? <>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                            </> : <>   <option value="sunday">Sunday</option>
                                <option value="monday"> Monday</option>
                                <option value="tuesday">Tuesday</option>
                                <option value="wednesday">Wednesday</option>
                                <option value="thursday"> Thursday</option>
                                <option value="friday">Friday</option>
                                <option value="saturday">Saturday</option> </>}

                        </select>
                    </div>
                </Col>}
            {form.cycle === "weekly" &&
                <Col className="col-12 pt-3">
                    <label
                        htmlFor="on"
                    >
                        On :
                    </label>
                    <div className="d-flex">

                        {wDaysOptions.map(element => {

                            let flag = form.wdays.find(elementTwo => elementTwo === element.value)
                            return (<div
                                onClick={() => {
                                    console.log("active")
                                    const findOne = form.wdays.find(elementTwo => elementTwo === element.value)
                                    const daysfilter = form.wdays.filter(elementTwo => elementTwo !== element.value)
                                    if (!findOne) return setForm({ ...form, wdays: [...form.wdays, element.value] })
                                    return setForm({ ...form, wdays: [...daysfilter] })
                                }}
                                style={{
                                    background: flag ? "rgb(112 210 152)" : "#e0e0e0",
                                    width: "20px",
                                    color: flag ? "white" : "black",
                                    cursor: "pointer",
                                    borderRadius: "3px",
                                    marginLeft: "0.2rem",
                                    textAlign: "center"
                                }}
                            >
                                {element.label}
                            </div>)
                        })}
                    </div>
                </Col>}


            <Col className="col-12 pt-3">
                <label
                    htmlFor="start_date"
                >
                    Start Date :
                </label>
                <input
                    onChange={handleChange}
                    name="start_date"
                    value={form.start_date}
                    className="form-control"
                    type="date"
                />
            </Col>

            <Col className="col-12 pt-3 ">
                <label
                    htmlFor="time_window_start"
                    className="m-0 pr-4"

                >
                    Time:
                </label>
                <div className="d-flex align-items-center">
                    <input
                        onChange={handleChange}
                        name="time_window_start"
                        disabled={form.check}
                        value={
                            form.time_window_start
                        }
                        className="form-control"
                        type="time"
                    />
                    <label htmlFor="time_window_stop" className="m-0 px-2">
                        To:
                    </label>
                    <input
                        onChange={handleChange}
                        disabled={form.check}
                        name="time_window_stop"
                        value={
                            form.time_window_stop
                        }
                        className="form-control"
                        type="time"
                    />
                    <div className="pl-2 d-flex align-items-center">
                        <CustomInput
                            onClick={(e) => {
                                setForm({ ...form, checked: e.target.checked, time_window_start: "00:00", time_window_stop: "23:59" });

                            }}
                            checked={form.checked}
                            type="checkbox"
                            className="input-check"
                            id={"exampleCustomCheckbox"}
                        />
                        <label htmlFor="" className="m-0 px-2">
                            All Day
                        </label>
                    </div>
                </div>

            </Col>

            <Col className="col-12 pt-5 ">

                <label
                    htmlFor="time_window_start"
                    className="m-0 pr-4"

                >
                    Enable Override Codes
                </label>
                <Switch
                    uncheckedIcon={<Offsymbol />}
                    className="mr-1"
                    checkedIcon={<OnSymbol />}
                    onColor="#626ed4"
                    onChange={() => {
                        setSwitchOne(!switchOne);
                    }}
                    height={24}
                    width={48}
                    checked={switchOne}
                />
            </Col>



            {switchOne && listOverrideCodes.map((element, i) => <Col className="col-12 pt-3">
                <label
                    htmlFor="start_date"
                >
                    {element.title}
                </label>
                <div className="d-flex">
                    <span className="pr-2">*</span>
                    <input
                        onChange={(e) => { handleChangeOverride(e.target.value, i) }}
                        value={element.input}
                        className="form-control"
                        type="number"
                    />
                </div>

            </Col>
            )
            }
        </Row>
    )
}
