import React from "react";
import {Row, Col} from "reactstrap";
import Switch from "react-switch";
export default function StepOne({
  form,
  setForm,
  formMetadata,
  setFormMetadata,
}) {
  const handleChange = (e) => {
    setForm(e.target.value);
  };

  return (
    <Row className="pt-3 pl-3  ">
      <Col className="col-12 col-md-10 col-lg-6  m-auto m-lg-0 ">
        <label htmlFor="text">
          Enter List of Numbers separated by a new line:
        </label>{" "}
        <textarea
          onChange={handleChange}
          value={form}
          name=""
          className="form-control w-30"
          placeholder="Example:&#10;3052993030&#10;7863563793&#10;9546727372"
          id="text"
          cols="30"
          rows="10"
        ></textarea>
      </Col>
    </Row>
  );
}
