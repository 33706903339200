import React, { useState, useEffect } from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";
import petition_get from "../../../../petitions/petition_get";
import classnames from "classnames";

import AssignedUserVoice from "./AssignedUser/AssignedUserVoice"
import AssignedUserTextMessage from "./AssignedUser/AssignedUserTextMessage"
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Spinner, Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";


export default function ModalUtility({
  modal,
  setModal,
  elementNumber,
  allUsers,
  setReloadDataEdit,
}) {

  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const [loading, setLoading] = useState(false);

  const [customActiveTab, setCustomActiveTab] = useState("1");

  const [activeSubmit, setActiveSubmit] = useState(false)

  const [numberDetails, setNumberDetails] = useState(null)

  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  }

  useEffect(() => {
    setNumberDetails(null)
    petition_get("viewDetails", { id, phoneNumber: elementNumber.num })
      .then(({ data: result }) => setNumberDetails(result.result))
      .catch((error) => console.log(error));

  }, [elementNumber])

  return (
    <>
      <Modal isOpen={modal === false ? false : true} className="info modal-md">
        <ModalHeader className="bg-soft-primary">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h3 className="text-primary">Edit Number</h3>
                <h6>{elementNumber ? elementNumber.num : null}</h6>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={phoneImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody>


          <Nav
            tabs
            className="nav-tabs-custom  flex-column flex-md-row  text-center  "
          >
            <NavItem className="pt-3 pt-md-0">
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "1",
                })}
                onClick={() => {
                  toggleCustom("1");
                }}
              >
                <span>Voice</span>
              </NavLink>
            </NavItem>
            <NavItem className="pt-3 pt-md-0">
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "2",
                })}
                onClick={() => {
                  toggleCustom("2");
                }}
              >
                <span>Text Messaging</span>
              </NavLink>
            </NavItem>


          </Nav>

          <TabContent activeTab={customActiveTab}>
            <TabPane tabId="1" className="p-3">
              <AssignedUserVoice numberDetails={numberDetails} allUsers={allUsers} setReloadDataEdit={setReloadDataEdit} setLoading={setLoading} setModal={setModal} elementNumber={elementNumber} activeSubmit={activeSubmit} setActiveSubmit={setActiveSubmit} />
            </TabPane>
            <TabPane tabId="2" className="p-3">
              <AssignedUserTextMessage numberDetails={numberDetails} elementNumber={elementNumber} activeSubmit={activeSubmit} setActiveSubmit={setActiveSubmit} allUsers={allUsers} />
            </TabPane>
          </TabContent>


        </ModalBody>
        <ModalFooter>
          <Button
            disabled={loading}
            outline
            color="secondary"
            onClick={() => { setModal(false) }}
          >
            Cancel
          </Button>

          <Button
            disabled={loading}
            color="primary"
            className="pl-3 pr-3"
            onClick={() => { setActiveSubmit(true) }}
          >
            {loading && (
              <Spinner
                style={{ marginRight: "0.5em" }}
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
