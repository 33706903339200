import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  Table,
  Badge,
  Spinner,
  CustomInput,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import Tools from "./Tools/Tools";
import ButtonHover from "../../../../components/utils/ButtonHover";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ModalUtilityEdit from "./ModalUtility/ModalUtilityEdit";
import ModalUtilityDelete from "./ModalUtility/ModalUtilityDelete";
import ModalUtilityDisconnect from "./ModalUtility/ModalUtilityDisconnect";

import petition_get from "../../../petitions/petition_get";
import petition_patch from "../../../petitions/petition_patch";
import petition_delete from "../../../petitions/petition_delete";
import toastr from "toastr";
import styled from "styled-components";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const ContainerPagination = styled.div`
  padding: 20px;
  margin: 0;

  .btn-page {
    border: none;
    background: rgba(255, 255, 255, 0.1);
    color: #a6b0cf;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    margin: 0 5px;
    padding: 0;
    svg {
      color: #a6b0cf;
    }
    &:focus {
      outline: none;
    }
  }
  .active {
    background: #536be1;
  }

  @media (max-width: 768px) {
    padding: 0;
    .container-pages {
      display: flex;
      justify-content: center;
    }
  }
`;
const Pages = ({ data, setPagination, pagination, cantPagination }) => {
  let page = [];

  for (let i = 0; i < Math.trunc(data); i++) {
    page.push(i);
  }

  const onClickPage = (index) => {
    index = (index + 1) * cantPagination;
    setPagination(index);
  };
  return (
    <>
      {page.map((pag, index) => {
        if (
          (index + 1 >= pagination / cantPagination - 2 &&
            index + 1 <= pagination / cantPagination) ||
          (index + 1 <= pagination / cantPagination + 2 &&
            index + 1 >= pagination / cantPagination)
        ) {
          return (
            <button
              className={`btn-page ${
                pagination / cantPagination === index + 1 && "active"
              } `}
              onClick={() => {
                onClickPage(index);
              }}
              key={index}
            >
              {" "}
              {index + 1}{" "}
            </button>
          );
        }
      })}
    </>
  );
};

export default function ListAllNumbersPBX() {
  toastr.options = {
    showDuration: 300,
  };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const cantPagination = 10;
  const [pagination, setPagination] = useState(cantPagination);
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingPetition, setLoadingPetition] = useState(false);

  const [inputSearch, setInputSearch] = useState("");
  const [selectCheckbox, setSelectCheckbox] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalDisconnect, setModalDisconnect] = useState(false);
  const [reloadData, setReloadData] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [keyNumbers, setKeyNumbers] = useState(null);
  const [numbers, setNumbers] = useState(null);


  const [loadingDisconnect, setLoadingDisconnect] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    const newData = data.filter((element) =>
      element.metadata.did.includes(inputSearch)
    );

    setDataFilter(newData);
  }, [inputSearch]);

  function deleteNumber() {
    setLoadingDelete(true);
    console.log(modalDelete);
    petition_delete("deleteNumberLegacy", { id: id, phone: modalDelete })
      .then(({ data: result }) => {
        setReloadData(true)
        setLoadingDelete(false);
        setModalDelete(false);
        toastr.success("Action completed", "Success");
      })
      .catch((error) => {
        setReloadData(true)
        setLoadingDelete(false);
        setModalDelete(false);
        toastr.error("Action failed", "Error");
      });
  }

  const editNumber = () => {
    setLoading(true);
    /*   petition_patch("Queues", {id})
      .then(({data: result}) => {
        setLoading(false)
        toastr.success("Action completed", "Success");

      })
      .catch((error) => {
        console.log(error);
        toastr.error("Action failed", "Error");

      });  */
  };

  useEffect(() => { 
    if (reloadData) {
      setLoadingPetition(false)
      setReloadData(false);
      petition_get("listNumbersPbx", { service_id: id })
        .then(({ data: result }) => {
          result.result.map((element) => {
            element.checked = false;
            return element;
          });
          setData(result.result);
          setDataFilter(result.result);
          setLoadingPetition(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [reloadData]);

  const onClickNext = () => {
    if (pagination < data.length) {
      setPagination(pagination + cantPagination);
    }
  };

  const onClickBack = () => {
    if (pagination > cantPagination) {
      setPagination(pagination - cantPagination);
    }
  };

  const handleCheck = (value) => {
    console.log(value);

    if (value.number) {
      //Chanche Checkbox of numbers
      let allNumbers = data.map((element, i) => {
        if (element.metadata.did === value.index) {
          element.checked = !element.checked;
        }
        return element;
      });
      setData(allNumbers);
      if (value.evento) {
        //Add Numbers
        let allSelectedCheckBox = selectCheckbox;
        allSelectedCheckBox.push(value.number);
        setSelectCheckbox(allSelectedCheckBox);
      } else {
        let allSelectedCheckBox = selectCheckbox.filter(
          (element) => element.metadata.did !== value.number.metadata.did
        );
        setSelectCheckbox(allSelectedCheckBox);
      }
    } else {
      let allNumbers = data.map((element, i) => {
        element.checked = value.checked;
        return element;
      });
      setData(allNumbers);
      if (value.checked) {
        return setSelectCheckbox(allNumbers);
      }
      setSelectCheckbox([]);
    }
  };

  function disconnectAll() {
    let data = [];
    console.log(selectCheckbox);
    if (selectCheckbox.length) {
      setLoadingDisconnect(true);
      selectCheckbox.forEach((element) => {
        console.log(element);
        data.push(
          petition_delete("deleteNumberLegacy", {
            id: id,
            phone: element.metadata.did
          })
        );
      });
      Promise.all(data)
        .then((values) => {
          console.log(values);
          console.log("prueba");

          setTimeout(() => {
            setLoadingDisconnect(false);
            setModalDisconnect(false);
            toastr.success("Action completed", "Success");
            setReloadData(true);
            setSelectCheckbox([]);
            // console.log(values);
          }, 20000);
        })
        .catch((reason) => {
          setTimeout(() => {
            setSelectCheckbox([]);
            setReloadData(true);
            setLoadingDisconnect(false);
            setModalDisconnect(false);
            console.log(reason);
            toastr.error("Action failed", "Error");
          }, 20000);
        });
    }
  }
  return (
    <>
      <ModalUtilityEdit
        modal={modalEdit}
        loading={loading}
        setModal={setModalEdit}
        editNumber={editNumber}
      />
      <ModalUtilityDelete
        modal={modalDelete}
        loading={loadingDelete}
        setModal={setModalDelete}
        deleteNumber={deleteNumber}
      />
      <ModalUtilityDisconnect
        modal={modalDisconnect}
        setModal={setModalDisconnect}
        disconnectNumbers={disconnectAll}
        loading={loadingDisconnect}
      />
      <div className="page-content">
        <Container fluid>
          {" "}
          <div
            className="d-flex"
            style={{ alignItems: "center", paddingBottom: "2.5rem" }}
          >
            <h3 style={{ margin: "0" }}>All Numbers</h3>
            <span>
              <DoubleArrowIcon className="icon-arrow" />
            </span>
            <h4
              style={{
                color: "#a6b0cf",
                fontWeight: "400",
                margin: "0",
                fontSize: "15px",
                paddingTop: "7px",
              }}
            >
              List All Numbers
            </h4>
          </div>{" "}
        </Container>
        <Tools inputSearch={inputSearch} setInputSearch={setInputSearch} />
        <Card body>
          <div className="table-responsive">
            {!loadingPetition ? (
              <div style={{ textAlign: "center" }}>
                <Spinner
                  animation="grow"
                  style={{
                    margin: "10vh auto",
                    height: "10vh",
                    width: "10vh",
                  }}
                />{" "}
              </div>
            ) : (
              <>
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle caret>Bulk Actions</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => setModalDisconnect(true)}>
                      Disconnect
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <br />
                <Table hover className="table mb-0">
                  <thead className="thead-light">
                    <tr>
                      <th>
                        <CustomInput
                          onClick={(e) => {
                            handleCheck({ checked: e.target.checked });
                          }}
                          type="checkbox"
                          className="input-check"
                          name=""
                          id="exampleCustomCheckbox"
                        />
                      </th>
                      <th>Did </th>
                      <th>Description </th>
                      <th>Status </th>

                      <th style={{ textAlign: "center" }}>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {dataFilter.map((element, i) => {
                      if (i < pagination && i >= pagination - cantPagination) {
                        return (
                          <tr key={i}>
                            <th>
                              {" "}
                              <CustomInput
                                type="checkbox"
                                onChange={(e) => {
                                  handleCheck({
                                    index: element.metadata.did,
                                    number: element,
                                    evento: e.target.checked,
                                  });
                                }}
                                checked={element.checked}
                                className="input-check"
                                name=""
                                id={"exampleCustomCheckbox" + i}
                              />
                            </th>

                            <th>{element.metadata.did} </th>
                            <th>{element.prod_description} </th>
                            <th>{element.status}</th>
                            <th
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <ButtonHover
                                style={{ margin: "0" }}
                                onClick={() => {
                                  setModalDelete(element.metadata.did);
                                }}
                              >
                                <DeleteIcon />
                              </ButtonHover>
                              <ButtonHover
                                style={{ margin: "0" }}
                                onClick={() => {
                                  setModalEdit(element.metadata);
                                }}
                              >
                                 <i className="bx bx-detail"></i>
                              </ButtonHover>
                            </th>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </Table>
                <ContainerPagination>
                  {dataFilter && dataFilter.length > cantPagination && (
                    <div className="col-12 container-pages">
                      <button className="btn-page pl-2" onClick={onClickBack}>
                        {" "}
                        <ArrowBackIosIcon />{" "}
                      </button>
                      <Pages
                        pagination={pagination}
                        setPagination={setPagination}
                        cantPagination={cantPagination}
                        data={
                          (dataFilter.length / cantPagination) % 1 === 0
                            ? dataFilter.length / cantPagination
                            : dataFilter.length / cantPagination + 1
                        }
                      />
                      <button className="btn-page" onClick={onClickNext}>
                        {" "}
                        <ArrowForwardIosIcon />
                      </button>
                    </div>
                  )}
                </ContainerPagination>
              </>
            )}
          </div>
        </Card>
      </div>
    </>
  );
}
