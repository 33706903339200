import React, { Component } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Spinner } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import {
  loginUser, tokenUser, apiError, changeSidebarTheme,
  changeTopbarTheme,
} from "../../store/actions";

// import images
//import itpPortal from "../../assets/images/itpFiber/itpPortal2.png";
// import itpLogo from "../../assets/images/itpFiber/itp_white.png";

import logoLight from "../../assets/images/itpFiber/itp_white.png";
// import logoLight from "../../assets/images/itpFiber/itp-logo-black.png";
import logoDark from "../../assets/images/itpFiber/itp-logo-black.png";
//import profile from "../../assets/images/profile-img.png";
//import logo from "../../assets/images/logo.svg";
import AlertModal from "../../components/utils/Alert";

class Login extends Component {
  constructor(props) {
    super(props);
    this.theme = localStorage.getItem("theme");
    this.state = {
      buttonLogIn: false,
      success: false,
      success_dlg: false,
      error_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // handleValidSubmit
  action = () => {
    this.setState({
      success: false,
      success_dlg: false,
      error_dlg: true,
      dynamic_title: "Error",
      dynamic_description:
        "Your username or password is incorrect, please try again.",
    });
  };
  async handleValidSubmit(event, values) {
    this.setState({ buttonLogIn: true });
    this.props.loginUser(values, this.props.history, this.action);
    setTimeout(this.resetLoading, 3000);
  }

  resetLoading = () => {
    this.setState({ buttonLogIn: false });
    this.setState({
      success: false,
      success_dlg: false,
      error_dlg: false,
      dynamic_title: "Error",
      dynamic_description:
        "Your username or password is incorrect, please try again.",
    });
  };

  getTokenUrl = () => {
    const params = new URL(document.location).searchParams;
    const token = params.get("token");
    if (token) {
      this.props.tokenUser(token, this.props.history);
    }
  };

  componentDidMount() {
    this.props.apiError("");
    this.getTokenUrl();
  }

  resetValues() { }

  render() {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container className="CenterBottom">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-soft-secundary">
                    <Row>
                      <Col className="col-12">
                        <div className="text-primary  p-4"></div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="logoITP">
                      {this.theme === "light" ? <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <img src={logoDark} alt="" height="70" />
                        </div>
                      </Link> : <Link to="/" >
                        <div className="avatar-md profile-user-wid mb-4">
                          <img src={logoLight} alt="" height="70" />
                        </div>
                      </Link>}


                    </div>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        {this.props.error && this.props.error ? (
                          <Alert color="danger">{this.props.error}</Alert>
                        ) : null}

                        <div className="form-group">
                          <AvField
                            name="email"
                            label="Email"
                            // value="xavier@pointtrackllc.com"
                            value=""
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        </div>

                        <div className="form-group">
                          <AvField
                            name="password"
                            label="Password"
                            // value="123456789"
                            value=""
                            type="password"
                            required
                            placeholder="Enter Password"
                          />
                        </div>

                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customControlInline"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>

                        <div className="mt-3">
                          {/* <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                          >
                            Log In
                          </button> */}
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                            disabled={
                              this.state.buttonLogIn === true ? true : false
                            }
                          >
                            {this.state.buttonLogIn === true ? (
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null}

                            {this.state.buttonLogIn === true
                              ? "  Loading..."
                              : "Log In"}
                          </button>
                        </div>

                        <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock mr-1"></i> Forgot your
                            password?
                          </Link>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>© {new Date().getFullYear()} ITP.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <AlertModal
          success_dlg={this.state.success_dlg}
          error_dlg={this.state.error_dlg}
          dynamic_title={this.state.dynamic_title}
          dynamic_description={this.state.dynamic_description}
          resetValues={this.resetValues}
        />
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStatetoProps, {
    loginUser, tokenUser, apiError, changeTopbarTheme,
    changeSidebarTheme,
  })(Login)
);
