import React, { useState, useEffect } from 'react'

import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import * as qs from "query-string";
import { connect } from "react-redux";
import TutorialSteps from "./TutorialSteps"

import prod from "../../config.json"
import petition_get from "../../pages/petitions/petition_get";
import { webPhoneChangeOption, appPK, changeInstallApps } from "../../store/actions";

import initMenu from "../utils/MetisMenuConfig"

function SidebarContent(props) {

  let params = new URL(document.location).searchParams;
  let id = params.get("id");
  let menu = params.get("menu");

  const [privLevel, setPrivLevel] = useState(null)
  const [data, setData] = useState({
    id: "",
    priv_level: null,
    data: [],
    permissionsData: [],
    loading: false,
    activeSteps: false,
  })


  useEffect(() => {
    if (props.layout.installApps) {
      setData({ ...data, ...props.layout.installApps });
    } else {

      petition_get("installedApp", { id: id })
        .then(({ data: result }) => {

          let resultData = result.result.model_list
          petition_get("permissionsOneUser", { id }).then(result => { setData({ ...data, data: resultData, ...result.data.result, }); props.changeInstallApps({ data: resultData, ...result.data.result, }) })

        })
        .catch((error) => console.log("error"));

    }
  }, [props.layout.admin])

  useEffect(() => { setPrivLevel(props.layout.admin); console.log("privLevel", props.layout.admin) }, [props.layout.admin])
  useEffect(() => { if (privLevel !== null) initMenu(props) }, [props.type, privLevel])


  const exitStep = () => { setData({ ...data, activeSteps: false }); }



  const [menuOptions] = useState({

    userOptions: [

      {
        category: "option",
        title: "Home",
        route: "/DashboardUsers",
        icon: "store-alt"
      },
      {
        category: "option",
        title: "Contacts",
        route: "/ContactList",
        icon: "group"
      },
      {
        category: "option",
        title: "Call History",
        route: "/CallHistory",
        icon: "phone-call"
      },
      /*  {
         category: "option",
         title: "Fax (No disponible)",
         route: "/DashboardUsers",
       }, */
      {
        category: "option",
        title: "Messaging",
        route: "/Chat",
        icon: "message-dots"
      },
      {
        category: "option",
        title: "Voicemail",
        route: "/Voicemail",
        icon: "voicemail",
      },
      {
        category: "dropdown",
        icon: "grid-alt",
        options: [
          {
            category: "option",
            title: "Operator Panel",
            route: "/OperatorPanel"
          },
          {
            category: "option",
            title: "Call Center App",
            route: "/CallCenterApp"
          }

        ],
        title: "Apps",
        type: "apps"
      },
      {
        category: "option",
        title: "Settings",
        route: "/SettingsAndDevices",
        icon: "cog"
      }

    ],

    adminOptions: [

      {
        category: "dropdown",
        icon: "grid-alt",
        options: [
          {
            category: "option",
            title: "API",
            route: "/keys"
          },
          {
            category: "option",
            title: "Apps",
            route: "/AppDirectory"
          },
          {
            category: "option",
            title: "Call Reports",
            route: "/CallReports"
          },
          {
            category: "option",
            title: "Call Flows",
            route: "/Callflows"
          },
          {
            category: "option",
            title: "General Settings",
            route: "/GeneralSettings"
          },
          {
            category: "option",
            title: "Media / Audio Files",
            route: "/MediaFiles"
          },
          {
            category: "option",
            title: "Menus",
            route: "/Menus"
          },
          {
            category: "option",
            title: "Phone Numbers",
            route: "/all-numbers"
          },
          {
            category: "option",
            title: "Queues",
            route: "/Queues"
          },

          {
            category: "option",
            title: "Time Groups",
            route: "/TimeSchedules"
          },
          {
            category: "option",
            title: "Troubleshooting",
            route: "/advancedHelp"
          },
          {
            category: "option",
            title: "Users",
            route: "/ListAllUsers"
          },

        ],
        title: "Your Phone System",
      },

    ]
  })

  return (
    <>
      <TutorialSteps exitStep={exitStep} priv_level={privLevel} activeSteps={data.activeSteps} />
      {privLevel !== null && <div id="sidebar-menu">

        <ul className={`metismenu list-unstyled `} id="side-menu" >
          <li className="menu-title">ITP Voice</li>
          {menuOptions.userOptions.map((element, i) => element.category !== "dropdown"
            ? <li key={i}  > <Link to={{ pathname: element.route, search: "?id=" + id, }} >  <i className={`bx bx-${element.icon}`}></i> <span>{element.title} </span>  </Link></li>
            : <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className={`bx bx-${element.icon}`}></i>
                <span>{element.title}</span>
              </Link>
              <ul
                className={`sub-menu ${props.menuOpen === "condensed" && "border"
                  }`}
                style={{ borderSize: "2px" }}
                aria-expanded="false"
              >
                {element.options.map((elementTwo, i) => <li key={i} > <Link to={{ pathname: elementTwo.route, search: "?id=" + id, }} > {elementTwo.title} </Link></li>)}
                {element.type === "apps" && data.permissionsData.map((element, i) => <li key={i}>
                  <Link
                    to={{
                      pathname: "/InstalledApps",
                      search: "?id=" + id + "&pk=" + element.app.pk
                    }}
                  >{this.props.t(element.app.name)}</Link>

                </li>)}
              </ul>
            </li>
          )}

          {privLevel && <li className="menu-title">Admin</li>}

          {privLevel && menuOptions.adminOptions.map((element, i) => element.category !== "dropdown"
            ? <li key={i} > <Link to={{ pathname: element.route, search: "?id=" + id, }} >  <i className={`bx bx-${element.icon}`}></i> {element.title} </Link></li>
            : <li>
              <Link to="/#" className=" has-arrow waves-effect">
                <i className={`bx bx-${element.icon}`}></i>
                <span>{element.title}</span>
              </Link>
              <ul
                className={`sub-menu ${props.menuOpen === "condensed" && "border"
                  }`}
                style={{ borderSize: "2px" }}
                aria-expanded="false"
              >
                {element.options.map((elementTwo, i) => <li key={i} > <Link to={{ pathname: elementTwo.route, search: "?id=" + id, }} > {elementTwo.title} </Link></li>)}
              </ul>
            </li>
          )}
        </ul>
      </div>}

    </>
  )
}

const mapStateToProps = (state) => {
  return {
    layout: state.Login,
    menuOpen: state.Layout.leftSideBarType,
    activeSidebarInDashboard: state.Layout.activeSidebarInDashboard,
  };
};


export default connect(mapStateToProps, { webPhoneChangeOption, appPK, changeInstallApps })(
  withRouter(withNamespaces()(SidebarContent))
);