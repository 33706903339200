import React, {useState, useEffect} from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";
import petition_get from "../../../../petitions/petition_get";
import toastr from "toastr";
import petition_patch from "../../../../petitions/petition_patch";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Badge,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";
import classnames from "classnames";

import BasicSettings from "./NavEdit/BasicSettings";
import Members from "./NavEdit/Members";
import AdvancedSettings from "./NavEdit/AdvancedSettings";

export default function ModalUtility({
  modal,
  setModal,
  id,
  listUsers,
  listDetails,
  setListDetails,
  setDeleteUser,
  deleteUser,
  activeCancel,
  setActiveCancel,
}) {
  toastr.options = {
    showDuration: 300,
  };
  const [loading, setLoading] = useState(false);
  const [add, setAdd] = useState(false);
  const [customActiveTab, setCustomActiveTab] = useState("1");
  const [customActiveTabAdd, setCustomActiveTabAdd] = useState("1");

  const [formUser, setFormUser] = useState("");
  const [listMembers, setMembers] = useState([]);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [form, setForm] = useState({
    name: "",
    strategy: "single",
    timeout: "",
    ignore_forward: true,
    fail_on_single_reject: false,
    description: "",
  });

  const onSubmitSelectUser = () => {
    const allMembers = listMembers;

    const infoUser = listUsers.find(
      (element) => element.presence_id === formUser
    );
    const findMember = allMembers.find(
      (element) => element.presence_id === infoUser.presence_id
    );

    if (!findMember) {
      allMembers.push({
        id: infoUser.id,
        presence_id: infoUser.presence_id,
        name: `${infoUser.first_name} ${infoUser.last_name}`,
        timeout: 10,
      });
    }

    setMembers(allMembers);
    setFormUser("");
  };

  //Form Basic settions
  const onChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value});
  };

  const onSubmitForm = (e) => {
    if (listMembers.length !== 0) {
      setLoading(true);
      const userOrders = listMembers.map((element) => {
        const elementTwo = {id: element.id, timeout: element.timeout};
        return elementTwo;
      });
      const dataPetition = {user_order: [...userOrders], ...form};

      petition_patch("ringGroup", {
        id,
        ring_group_id: modal.id,
        data: dataPetition,
      })
        .then(({data: result}) => {
          const allListDetails = listDetails.map((element) => {
            if (element.id === result.result.id) {
              element = result.result;
            }
            return element;
          });
          toastr.success("Action completed", "Success");
          setListDetails(allListDetails);
          setLoading(false);
          setForm({
            name: "",
            strategy: "single",
            timeout: "",
            ignore_forward: true,
            fail_on_single_reject: false,
            description: "",
          });
          setMembers([]);
          setCustomActiveTab("1");
          setDeleteUser(!deleteUser);
          setModal(false);
          setActiveCancel(false);
        })
        .catch((error) => {
          setLoading(false);
          toastr.error("Action failed", "Error");
          console.log(error);
        });
    } else {
      setCustomActiveTab("2");
      setError(true);
    }
  };

  //Custom Navigation
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };
  const toggleCustomAdd = (tab) => {
    if (customActiveTabAdd !== tab) {
      setCustomActiveTabAdd(tab);
    }
  };

  //Petitions alls
  useEffect(() => {
    if (modal) {
      if (modal.flow) {
        setForm({
          ...form,
          name: modal.name,
          timeout: modal.flow.data.timeout,
          strategy: modal.flow.data.strategy,
          ignore_forward: modal.flow.data.ignore_forward,
          fail_on_single_reject: modal.flow.data.fail_on_single_reject,
          description: modal.itpvoice_metadata.description,
        });
        setMembers(modal.flow.data.endpoints);
      } else {
        setForm({
          ...form,
          name: modal.name,
          description: modal.itpvoice_metadata.description,
        });
      }
      setData(modal);
    }
  }, [modal]);

  const onChangeAutocomplete = (e) => {
    const values = e.target.value.split(" ");
    if (values.length === 3) {
      setFormUser(values[2]);
    }
  };

  return (
    <Modal
      size="lg"
      centered={true}
      isOpen={modal ? true : false}
      className="info "
    >
      <ModalHeader className="bg-soft-primary">
        {" "}
        <Row>
          <Col xs="10">
            <div className="text-primary p-3">
              <h3 className="text-primary">Edit Ring Group</h3>
              {error && (
                <Badge color="danger" style={{padding: "0.5rem 0.2rem"}}>
                  {" "}
                  Add Members
                </Badge>
              )}
            </div>
          </Col>
          <Col xs="2" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        {add ? (
          <>
            {" "}
            <Nav
              tabs
              className="nav-tabs-custom  flex-column flex-md-row  text-center  "
            >
              <NavItem className="pt-3 pt-md-0">
                <NavLink
                  style={{cursor: "pointer"}}
                  className={classnames({
                    active: customActiveTabAdd === "1",
                  })}
                  onClick={() => {
                    toggleCustomAdd("1");
                  }}
                >
                  <span className="d-none d-sm-block">Add User</span>
                </NavLink>
              </NavItem>
              <NavItem className="pt-3 pt-md-0">
                <NavLink
                  style={{cursor: "pointer"}}
                  className={classnames({
                    active: customActiveTabAdd === "2",
                  })}
                  onClick={() => {
                    toggleCustomAdd("2");
                  }}
                >
                  <span>Add External Number</span>
                </NavLink>
              </NavItem>
            </Nav>{" "}
            <TabContent activeTab={customActiveTabAdd}>
              <TabPane tabId="1" className="p-3">
                <Col className="d-flex col-9 pt-4">
                  {" "}
                  <Label
                    htmlFor="user"
                    style={{
                      cursor: "pointer",
                      width: "40%",
                      display: "flex",
                      alignItems: "center",
                      margin: "0",
                    }}
                  >
                    {" "}
                    User
                  </Label>{" "}
                  <input
                    onChange={onChangeAutocomplete}
                    class="form-control"
                    list="datalistOptions"
                    id="exampleDataList"
                    placeholder="Type to search..."
                  />
                  <datalist id="datalistOptions">
                    {listUsers.map((element, i) => (
                      <option
                        key={i}
                        value={`${element.first_name.trim()} ${element.last_name.trim()} ${element.presence_id.trim()}`}
                      />
                    ))}
                  </datalist>
                </Col>
              </TabPane>
              <TabPane tabId="2" className="p-3">
                <Col className="d-flex col-9 pt-4">
                  {" "}
                  <Label
                    htmlFor="number"
                    style={{
                      cursor: "pointer",
                      width: "40%",
                      display: "flex",
                      alignItems: "center",
                      margin: "0",
                    }}
                  >
                    Enter Number
                  </Label>{" "}
                  <Input
                    id="number"
                    type="text"
                    name="number"
                    placeholder="3052990233"
                  />{" "}
                </Col>
              </TabPane>
            </TabContent>
          </>
        ) : (
          <>
            {" "}
            <Nav
              tabs
              className="nav-tabs-custom  flex-column flex-md-row  text-center  "
            >
              <NavItem className="pt-3 pt-md-0">
                <NavLink
                  style={{cursor: "pointer"}}
                  className={classnames({
                    active: customActiveTab === "1",
                  })}
                  onClick={() => {
                    toggleCustom("1");
                  }}
                >
                  <span className="d-none d-sm-block">Basic Settings</span>
                </NavLink>
              </NavItem>
              <NavItem className="pt-3 pt-md-0">
                <NavLink
                  style={{cursor: "pointer"}}
                  className={classnames({
                    active: customActiveTab === "2",
                  })}
                  onClick={() => {
                    toggleCustom("2");
                  }}
                >
                  <span>Members</span>
                </NavLink>
              </NavItem>
              <NavItem className="pt-3 pt-md-0">
                <NavLink
                  style={{cursor: "pointer"}}
                  className={classnames({
                    active: customActiveTab === "3",
                  })}
                  onClick={() => {
                    toggleCustom("3");
                  }}
                >
                  <span>Advanced Settings</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={customActiveTab}>
              <TabPane tabId="1" className="p-3">
                <BasicSettings
                  setForm={setForm}
                  onChange={onChange}
                  form={form}
                />
              </TabPane>
              <TabPane tabId="2" className="p-3">
                <Members
                  listUsers={listUsers}
                  list={listMembers}
                  setList={setMembers}
                  setAdd={setAdd}
                />
              </TabPane>
              <TabPane tabId="3" className="p-3">
                <AdvancedSettings form={form} setForm={setForm} />
              </TabPane>
            </TabContent>
          </>
        )}
      </ModalBody>{" "}
      <ModalFooter>
        <Button
          outline
          color="secondary"
          disabled={loading}
          onClick={() => {
            if (add) {
              return setAdd(false);
            }
            if (listMembers.length === 0 && activeCancel) {
              setCustomActiveTab("2");
              return setError(true);
            }

            setModal(false);
            setError(false);
            setCustomActiveTab("1");
            setForm({
              name: "",
              strategy: "single",
              timeout: "",
              ignore_forward: true,
              fail_on_single_reject: false,
              description: "",
            });
            setMembers([]);
          }}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={loading}
          onClick={() => {
            if (add) {
              setError(false);
              onSubmitSelectUser();

              return setAdd(false);
            }
            onSubmitForm();
          }}
        >
          {loading && (
            <Spinner
              style={{marginRight: "0.5em"}}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
