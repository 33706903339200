import React from "react";
import { Table } from "reactstrap";
import DeleteIcon from "@material-ui/icons/Delete";

import ButtonHover from "../../../../../components/utils/ButtonHover";
export default function StepTwo({ form, setForm, allData, setAllData }) {

  const handleDelete = (index) => {
    const allForm = form.filter((element, i) => i !== index);
    setForm(allForm);

    const newAllData = allData.filter((element, i) => i !== index);
    setAllData(newAllData);
  };


  return (
    <div>
      <span style={{ fontSize: "1rem", margin: "1rem 0" }}>List all Numbers</span>
      <Table className="mt-3">
        <thead>
          <tr>
            <th>Location</th>
            <th>Number Type </th>
            <th>Number </th>
            <th style={{ textAlign: "center" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {allData.map((element, i) => {
            return (
              <tr key={i}>
                <th>Domestic - US/CANADA</th>
                <th>{element.type}</th>
                <th>{element.did}</th>
                <th>
                  <ButtonHover
                    onClick={() => {
                      handleDelete(i);
                    }}
                  >
                    {" "}
                    <DeleteIcon />{" "}
                  </ButtonHover>
                </th>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
