import React from 'react'
import { Col, Table } from "reactstrap"
export default function StepViewCarriers({ supportedLosingCarriers }) {
    return (
        <div className="row justify-content-center" >
            {supportedLosingCarriers.map((element, i) => (<Col className="col-12 col-md-10 col-lg-6  ">
                <Table bordered hover>
                    <thead className="thead-light">
                        <tr>
                            <th className="text-center">
                                {element.LosingCarrierName}
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {typeof element.TnList.Tn === "string" ? (
                            <tr>
                                <td className="text-center">{element.TnList.Tn}</td>
                            </tr>
                        ) : (
                            element.TnList.Tn.map((element, index) => (
                                <tr key={index}>
                                    <td className="text-center">{element}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </Table>
            </Col>))}
        </div>
    )
}
