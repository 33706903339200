import axios from "axios";
export default function post_user(key, value) {
  const json = require("../../config.json");
  const urlBase = json.urlBase;
  const urlCrmBase = json.urlBase;
  const token = JSON.parse(localStorage.getItem("authUser"));
  var url;

  if (token && token.access_token) {
    switch (key) {
      case "billing_payments":
        url = urlBase + "/billing/account/" + value + "/payments";
        break;
      case "viewStaments":
        url = urlBase + "/billing/account/" + value + "/statements";
        break;
      case "getStaments":
        url = urlBase + "/crm/accounts/statements/" + value;

        break;
      case "getStamentsDetails":
        url = urlBase + "/billing/account/" + value + "/statements";
        break;
      case "account":
        url = urlBase + "/billing/account/" + value;
        break;
      case "getStamentsAll":
        url =
          urlBase + "/billing/account/" + value[0] + "/statements/" + value[1];
        break;

      case "refresh":
        url = urlBase + "/crm/auth/refresh";
        break;
      case "services":
        url = urlBase + "/crm/services";
        break;
      case "orders":
        url = urlBase + "/crm/orders";
        break;
      case "ordersItems":
        url = urlBase + `/crm/orderitems?order_id=${value}`;
        break;

      case "prefix":
        let newValue = value.code.substr(0, 2);

        newValue = newValue + "*";

        url =
          urlBase +
          `/itpvoice/itp_voice/bandwidth/search?toll_free_wildcard=${newValue}&quantity=100`;

        break;
      case "numbers":
        if (value.type === "NPA") {
          url =
            urlBase +
            `/itpvoice/${value.id}/bandwidth/search?npa=${value.code}&quantity=100`;
        } else if (value.type === "City/State") {
          url =
            urlBase +
            `/itpvoice/${value.id}/bandwidth/search?state=${value.code.state}&rate_center=${value.code.city}&quantity=100`;
        } else if (value.type === "NPANXX") {
          url =
            urlBase +
            `/itpvoice/${value.id}/bandwidth/search?npanxx=${
              value.code.npa + value.code.nxx
            }&quantity=100`;
        }

        break;

      case "cities2":
        url = urlBase + `/crm/misc/locations/cities?us_state_id=${value}`;
        break;

      case "cities":
        url =
          urlBase +
          `/itpvoice/${value.id}/bandwidth/rate-centers?state=${value.stateCode}`;
        break;
      case "allCities":
        url = urlBase + `/crm/misc/locations/cities`;
        break;
      case "estates":
        url = urlBase + `/crm/misc/locations/states`;
        break;
      case "view":
        url = urlBase + `/itpvoice/${value.id}/numbers?pagination=false`;
        break;
      case "viewDetails":
        url = urlBase + `/itpvoice/${value.id}/numbers/${value.phoneNumber}`;
        break;
      case "itpvoiceUsers":
        url = urlBase + `/itpvoice/${value.id}/users`;
        break;
      case "products":
        url = urlBase + "/crm/products" + value;
        break;
      case "itpvoiceUsers":
        url = urlBase + `/itpvoice/${value.id}/users`;
        break;
      case "itpvoiceAll":
        url = urlBase + `/itpvoice/${value.id}`;
        break;
      case "voicemailMessages":
        url = urlBase + `/itpvoice/${value.id}/my-extension/voicemail-messages`;
        break;
      case "voicemailAudio":
        url =
          urlBase +
          `/itpvoice/${value.id}/my-extension/voicemail-messages/${value.media_id}/audio`;
        break;
      case "MyDevices":
        url = urlBase + `/itpvoice/${value.id}/my-extension/devices`;
        break;
      case "getDevices":
        url = urlBase + `/itpvoice/${value.id}/devices`;
        break;
      case "getDevicesDetails":
        url = urlBase + `/itpvoice/${value.id}/devices/${value.deviceId}`;
        break;
      case "device":
        url =
          urlBase +
          `/itpvoice/${value.id}/my-extension/devices/${value.deviceId}`;
        break;
      case "deviceUsers":
        url = urlBase + `/itpvoice/${value.id}/devices/${value.deviceId}`;
        break;
      case "callHistory":
        url =
          urlBase + `/itpvoice/${value.id}/my-extension/cdrs?paginate=false`;
        break;
      case "callHistoryQuery":
        url =
          urlBase +
          `/itpvoice/${value.id}/my-extension/cdrs?caller_id_number=${value.query}`;
        break;
      case "callLegs":
        url =
          urlBase +
          `/itpvoice/${value.id}/my-extension/cdrs/legs/${value.interaction_id}`;

        break;
      case "contacts":
        url = urlBase + `/itpvoice/${value.id}/my-extension/contacts`;

        break;
      case "contactsFilter":
        url =
          urlBase +
          `/itpvoice/${value.id}/my-extension/contacts?${value.query}=${value.number}`;

        break;
      case "accountDetails":
        url = urlBase + `/itpvoice/${value.id}`;
        break;

      case "operatorPanel":
        url =
          urlBase + `/itpvoice/${value.id}/my-extension/operator-panel/users`;
        break;
      case "myExtensions":
        url = urlBase + `/itpvoice/${value.id}/my-extension/dashboard`;
        break;
      case "fax":
        url = urlBase + `/itpvoice/${value.id}/my-extension/faxes/inbox`;
        break;
      case "userStatus":
        url =
          urlBase +
          `/itpvoice/${value.id}/my-extension/operator-panel/my-status`;
        break;
      case "operationPanel":
        url = urlBase + `/itpvoice/${value.id}/my-extension/operator-panel`;
        break;
      case "operationPanelUsers":
        url = urlBase + `/itpvoice/${value.id}/operator-panel/users`;
        break;
      case "devicesUsers":
        url = urlBase + `/itpvoice/${value.id}/devices`;
        break;
      case "devicesUsersEdit":
        url =
          urlBase + `/itpvoice/${value.id}/devices?owner_id=${value.owner_id}`;
        break;
      case "viewNumbers":
        url = urlBase + `/itpvoice/${value.id}/my-extension/numbers`;
        break;
      case "viewNumbersChat":
        url = urlBase + `/itpvoice/${value.id}/my-extension`;
        break;
      case "numbersUsers":
        url =
          urlBase + `/itpvoice/${value.id}/numbers?owner_id=${value.owner_id}`;
        break;
      case "viewNumberDetails":
        url =
          urlBase +
          `/itpvoice/${value.id}/my-extension/numbers/${value.number}`;
        break;
      case "faxSettings":
        url = urlBase + `/itpvoice/${value.id}/my-extension/faxbox`;
        break;
      case "listSms":
        url =
          urlBase +
          `/itpvoice/${value.id}/my-extension/chat/sms/${value.number}`;
        break;
      case "getMessageThread":
        url =
          urlBase +
          `/itpvoice/${value.id}/my-extension/chat/sms/${value.number}/${value.thread}`;
        break;
      case "viewCallSettings":
        url = urlBase + `/itpvoice/${value.id}/my-extension`;
        break;
      case "deviceOrder":
        url =
          urlBase +
          `/itpvoice/${value.id}/my-extension/callflows/${value.callflow}`;
        break;
      case "callflowsDetails":
        url = urlBase + `/itpvoice/${value.id}/callflows/${value.callflow}`;
        break;
      case "callForward":
        url = urlBase + `/itpvoice/${value.id}/my-extension/users`;
        break;
      case "callHandling":
        url = urlBase + `/itpvoice/${value.id}/my-extension/callflows`;
        break;
      case "allCallflows":
        url = urlBase + `/itpvoice/${value.id}/callflows?paginate=false`;
        break;
      case "ringGroup":
        url = urlBase + `/itpvoice/${value.id}/ring-groups`;
        break;
      case "ringGroupDetails":
        url =
          urlBase + `/itpvoice/${value.id}/ring-groups/${value.ring_group_id}`;
        break;
      case "Queues":
        url = urlBase + `/itpvoice/${value.id}/queues`;
        break;
      case "QueuesDetails":
        url = urlBase + `/itpvoice/${value.id}/queues/${value.idQueque}`;
        break;
      case "QueuesPanel":
        url = urlBase + `/itpvoice/${value.id}/my-extension/queues`;
        break;
      case "musicOnHoldMedia":
        url = urlBase + `/itpvoice/${value.id}/media?pagination=false`;
        break;
      case "musicOnHoldMediaAll":
        url = urlBase + `/itpvoice/${value.id}/media?${value.search}`;
        break;
      case "musicOnHoldMediaDetails":
        url = urlBase + `/itpvoice/${value.id}/media/${value.mediaId}`;
        break;
      case "timeZome":
        url = urlBase + `/crm/misc/timezones`;
        break;
      case "userDetails":
        url = urlBase + `/itpvoice/${value.id}/my-extension/users`;
        break;
      case "viewCallSettingsUsers":
        url = urlBase + `/itpvoice/${value.id}/users/${value.user_id}`;
        break;
      case "viewCallSettingsUsersAll":
        url = urlBase + `/itpvoice/${value.id}/users?pagination=false`;
        break;
      // case "listNumbersPbx":
      //   url =
      //     "https://api.itpscorp.com/dev/crmui" +
      //     `/service-addons?service_id=${value.service_id}`;
      //   break;
      case "listNumbersPbx":
        url = urlBase + `/itpvoice-legacy/${value.service_id}/numbers`;
        break;
      case "voiceApp":
        url = urlBase + `/itpvoice/${value.id}/app-store`;
        break;
      case "installedApp":
        url = urlBase + `/itpvoice/${value.id}/app-store/installed-apps`;
        break;
      case "installedAppDetails":
        url =
          urlBase +
          `/itpvoice/${value.id}/app-store/installed-apps/${value.pk}`;
        break;
      case "voiceAppDetails":
        url = urlBase + `/itpvoice/${value.id}/app-store/${value.pk}`;
        break;
      case "transferProgress":
        url = urlBase + `/itpvoice/${value.id}/numbers/lnp`;
        break;
      case "transferProgressLegacy":
        url = urlBase + `/itpvoice-legacy/${value.id}/numbers/lnp`;
        break;
      case "callReport":
        url = urlBase + `/itpvoice/${value.id}/cdrs`;
        break;
      case "callReportQuery":
        url = urlBase + `/itpvoice/${value.id}/cdrs${value.query}`;
        break;
      case "filterSelect":
        url =
          urlBase +
          `/itpvoice/${value.id}/app-store?name=${value.name}&monthly_price=${value.price}`;
        break;
      case "AuthorizationTrasfers":
        url = urlBase + `/itpvoice/${value.id}/numbers/lnp-pending-auth`;
        break;
      case "defaultCallerID":
        url = urlBase + `/itpvoice/${value.id}`;
        break;
      case "sipRegistration":
        url = urlBase + `/itpvoice/${value.id}/troubleshooting/registrations`;
        break;
      case "defaultCallerID":
        url = urlBase + `/itpvoice/${value.id}`;
        break;
      case "apiKeys":
        url =
          urlBase +
          `/itpvoice/${value.id}/apikeys?user_null=${value.user_null}`;
        break;
      case "timeGroups":
        url = urlBase + `/itpvoice/${value.id}/time-groups`;
        break;
      case "timeGroupsDetails":
        url =
          urlBase + `/itpvoice/${value.id}/time-groups/${value.time_group_id}`;
        break;
      case "voiceAppPages":
        url = urlBase + `/itpvoice/${value.id}/apps/${value.voice_id}/pages`;
        break;
      case "voicemail-box":
        url = urlBase + `/itpvoice/${value.id}/voicemail-boxes`;
        break;
      case "permissions":
        url =
          urlBase +
          `/itpvoice/${value.id}/app-store/installed-apps/${value.app_id}/permissions`;
        break;
      case "permissionsOneUser":
        url =
          urlBase +
          `/itpvoice/${value.id}/my-extension/assigned-apps/permissions`;
        break;
      case "getMenu":
        url = urlBase + `/itpvoice/${value.id}/menus`;
        break;
      case "getMenuDetails":
        url = urlBase + `/itpvoice/${value.id}/menus/${value.menuId}`;
        break;
      case "agent-status":
        url = urlBase + `/itpvoice/${value.id}/my-extension/agent-status`;
        break;
      case "voicemail-box-details":
        url =
          urlBase + `/itpvoice/${value.id}/voicemail-box/${value.idVoicemail}`;
        break;
      case "downloadRecording":
        url =
          urlBase +
          `/itpvoice/${value.id}/recordings/${value.media_id}?accept=audio/mpeg`;
        break;
      case "notes":
        url =
          urlBase +
          `/itpvoice/${value.id}/callnotes${
            value.query ? `?call_id=${value.query}` : ""
          }`;
        break;
      case "callInteractionCaller":
        url =
          urlBase +
          `/itpvoice/${value.id}/my-extension/cdrs/interaction?caller_id_number=${value.number}`;
        break;
      case "callHistoryInteraction":
        url =
          urlBase +
          `/itpvoice/${value.id}/my-extension/cdrs/interaction?${
            value.query || ""
          }`;
        break;
      case "callInteractionCallee":
        url =
          urlBase +
          `/itpvoice/${value.id}/my-extension/cdrs/interaction?callee_id_number=${value.number}`;
        break;
      case "callUsingDeskPhone":
        url =
          urlBase +
          `/itpvoice/${value.id}/my-extension/call?destination_phone_number=${value.number}`;
        break;
      case "contactGroups":
        url = urlBase + `/itpvoice/${value.id}/my-extension/contact-groups`;
        break;
      case "directory":
        url = urlBase + `/itpvoice/${value.id}/directories`;
        break;
      case "completeCallReports":
        url =
          urlBase + `/itpvoice/${value.id}/complete-call-reports${value.query}`;
        break;
      case "getTickets":
        url = urlBase + `/crm/tickets?contact_id=${value.id}`;
        break;

      default:
        return "error";
    }
    var config = {
      headers: {
        Authorization: "Bearer " + token.access_token.replace(/['"]+/g, ""),
      },
    };
    return axios.get(url, config);
  } else {
    window.location.href = "./login";
  }
}
