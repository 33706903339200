import React, {useState, useEffect} from "react";
import {
  Container,
  Card,
  CardBody,
  Table,
  Button,
  Row,
  Col,
  Input,
  Badge,
} from "reactstrap";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import {useHistory} from "react-router-dom";
export default function Tools({inputSearch, setInputSearch}) {
  const history = useHistory();

  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col sm="12" lg="4">
              <label
                htmlFor="search"
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "7px",
                  left: "20px",
                }}
              >
                <SearchIcon />
              </label>
              <Input
                id="search"
                placeholder="Search Number"
                type="text"
                style={{paddingLeft: "40px", borderRadius: "3rem"}}
                value={inputSearch}
                onChange={(e) => {
                  setInputSearch(e.target.value);
                }}
              />
            </Col>

            <Col
              className="text-center  mt-4 mt-lg-0 text-lg-right  "
              sm="12"
              lg="8"
            >
              <Button
                onClick={() => {
                  history.push(`/AddNumbersPBX?id=${id}`);
                }}
                color="primary"
              >
                <AddIcon style={{marginRight: "0.5rem", fontSize: "18px"}} />
                Order New Numbers
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
