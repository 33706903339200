import React, { useEffect, useState } from "react";
import CustomTable from "../../../../components/utils/CustomTable/CustomTable";
import CustomBreadCrum from "../../../../components/utils/CustomBreadCrum/CustomBreadCrum"
import petition_get from "../../../petitions/petition_get"
import petition_post from "../../../petitions/petition_post"
import petition_patch from "../../../petitions/petition_patch"
import petition_delete from "../../../petitions/petition_delete"

import toastr from "toastr";

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import ModalUtilityEdit from "./ModalUtility/ModalUtilityEdit";
import ModalUtilityAdd from "./ModalUtility/ModalUtilityAdd";
import ModalUtilityDelete from "./ModalUtility/ModalUtilityDelete";

export default function Menu() {

    toastr.options = { showDuration: 300, };
    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    const [modalAdd, setModalAdd] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)

    const [dataTable, setDataTable] = useState(null)
    const [dataMedia, setDataMedia] = useState([])

    const [reloadData, setReloadData] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [loadingAdd, setLoadingAdd] = useState(false)
    const [loadingUpdate, setLoadingUpdate] = useState(false)



    //CRUD OPTIONS
    const addMenu = async (values) => {
        setLoadingAdd(true)
        petition_post("createMenu", { id, data: values })
            .then(({ data: result }) => {
                console.log(result)
                setLoadingAdd(false)
                setReloadData(!reloadData)
                setModalAdd(false)
                toastr.success("Action completed", "Success");

            })
            .catch((error) => {
                console.log(error);
                setLoadingAdd(false)
                return toastr.error("Action Error", "Error");

            });

    };

    const deleteMenu = () => {

        setLoadingDelete(true)
        petition_delete("deleteMenu", { id, menuId: modalDelete.id })
            .then(({ data: result }) => {
                console.log(result)
                setLoadingDelete(false)
                setReloadData(!reloadData)
                setModalDelete(false)
                toastr.success("Action completed", "Success");

            })
            .catch((error) => {
                console.log(error);
                setLoadingDelete(false)
                return toastr.error("Action Error", "Error");

            });
    };

    const editMenu = (values) => {
        console.log(values)
        setLoadingUpdate(true)
        petition_patch("updateMenu", { id, data: values, menuId: modalEdit.id })
            .then(({ data: result }) => {
                console.log(result)
                setLoadingUpdate(false)
                setReloadData(!reloadData)
                setModalEdit(false)
                toastr.success("Action completed", "Success");

            })
            .catch((error) => {
                console.log(error);
                setLoadingUpdate(false)
                return toastr.error("Action Error", "Error");

            });

    }

    //GET DATA OF TABLE
    useEffect(() => {
        setDataTable(null)
        petition_get("getMenu", { id })
            .then(({ data: result }) => {
                let dataTableCopy = {
                    title: [
                        { title: "Name", key: "name" },
                    ],
                    content: [...result.result],
                    actions: {
                        title: "Actions",
                        content: [
                            {
                                icon: <DeleteIcon />,  //Svg icon
                                handleClick: (e) => { setModalDelete(e) },  //Function 
                            },
                            {
                                icon: <EditIcon />,  //Svg icon
                                handleClick: (e) => { setModalEdit(e) },  //Function 
                            },
                        ],
                    },
                    search: {
                        label: "Search Menu"
                    },
                    addButton: {
                        label: "Add Menu",
                        handleClick: () => { setModalAdd(true) }
                    }
                }
                setDataTable(dataTableCopy)
            })
            .catch((error) => {
                console.log(error);
            });

    }, [reloadData]);

    //GET MEDIA FILES FOR OPTIONS IN MODAL OF ADD MENUS
    useEffect(() => {
        petition_get("musicOnHoldMedia", {
            id: id,
        })
            .then(({ data: result }) => {
                setDataMedia(result.result);
            })
            .catch((error) => {
                console.log(error);
            });

    }, [])

    return (
        <>
            <ModalUtilityDelete
                spinner={loadingDelete}
                deleteMenu={deleteMenu}
                modal={modalDelete}
                setModal={setModalDelete}
            />
            <ModalUtilityEdit
                editMenu={editMenu}
                modal={modalEdit}
                setModal={setModalEdit}
                loadingUpdate={loadingUpdate}
                reloadData={reloadData}
                dataMedia={dataMedia}
            />
            <ModalUtilityAdd
                addMenu={addMenu}
                modal={modalAdd}
                setModal={setModalAdd}
                loadingAdd={loadingAdd}
                dataMedia={dataMedia}
            />
            <div className="page-content">
                <CustomBreadCrum title="Your Phone System" subtitle="Menus" />
                <CustomTable data={dataTable} />
            </div>
        </>
    );
}
