import React, { useState, useEffect, useRef } from 'react'
import MessageIcon from '@material-ui/icons/Message';
import AccountCircleIcon from '@material-ui/icons/Group';
import AddCircleOutlineIcon from '@material-ui/icons/GroupAdd';
import GetAppIcon from '@material-ui/icons/GetApp';
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from '@material-ui/icons/Pause';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import { Card, Row, Col, Button } from "reactstrap"

import ButtonHover from '../../../../components/utils/ButtonHover';
import useCallHistorySelectedItem from '../../../../hooks/useCallHistorySelectedItem';

export default function CallHistorySelectedItem({ item, setReloadData, userData, downloadCall }) {


    const { contactInfo, refAudio, history, note, getUrlVoiceAudio, onClickSubmitNotes, onClickContacts, onClickButtonPlay, onChangeNotes, onChangeTimem, getUrlMessage, onClickSendMessage, onClickOpenModalDownload, getModalsControllers, findRecording, time, playActive, onChangeTime } = useCallHistorySelectedItem({ item, downloadCall, userData, setReloadData })
    const [colorInfoCall, setColorInfoCall] = useState({ backgroundColor: "", textColor: "", text: "" })

    const getTypeOfCall = () => {
        let colorFilter = { backgroundColor: "", textColor: "", text: "" }
        switch (item.result[0].hangup_cause) {

            case "NORMAL_CLEARING": if (item.result[0].disposition === "") colorFilter = { backgroundColor: "#f8d7da", textColor: "#721c24", text: "CALLER ABANDONED" }; else if (item.result[0].disposition === "NO_ANSWER") colorFilter = { hidden: true, backgroundColor: "#f8d7da", textColor: "#721c24", text: "NO ANSWERED" }; else colorFilter = { hidden: true, backgroundColor: "#d4edda", textColor: "#155724", text: "ANSWERED" }; break;
            case "ORIGINATOR_CANCEL": colorFilter = { backgroundColor: "#f8d7da", textColor: "#721c24", text: "CALLER ABANDONED" }; break;
            case "LOSE_RACE": colorFilter = { backgroundColor: "#fff3cd", textColor: "#856404", text: "ANSWERED ELSEWHERE" }; break;
            case "NO_ANSWER": colorFilter = { backgroundColor: "#f8d7da", textColor: "#721c24", text: "MISSED CALL" }; break;
            case "USER_BUSY": colorFilter = { backgroundColor: "#f8d7da", textColor: "#721c24", text: "REJECT CALL" }; break;
            case "NO_USER_RESPONSE": colorFilter = { backgroundColor: "#f8d7da", textColor: "#721c24", text: "REJECT CALL" }; break;

            default: break;
        }
        setColorInfoCall(colorFilter)

    }


    useEffect(() => { getTypeOfCall() }, [item])


    const InfoCall = () => {


        if (colorInfoCall.hidden) return <></>
        return (<div className="border rounded p-3 mt-5 text-center" style={{ background: colorInfoCall.backgroundColor, color: colorInfoCall.textColor }} >  {colorInfoCall.text}  </div>)
    }


    return (
        <Card body>

            <div className="px-3">
                {getModalsControllers()}

                <div className="w-100 d-flex justify-content-between pt-3">

                    <h3>{item.userCallName || item.userCallNumber}</h3>
                    <div className="d-flex">
                        <ButtonHover onClick={onClickSendMessage} className="pr-3 custom-pointer" ><MessageIcon /></ButtonHover>
                        <ButtonHover onClick={onClickContacts} className="pr-3 custom-pointer" > {item.userCallName ? <AccountCircleIcon /> : <AddCircleOutlineIcon />} </ButtonHover>
                        {findRecording.length > 0 && <ButtonHover onClick={onClickOpenModalDownload} className="pr-3 custom-pointer" ><GetAppIcon />  </ButtonHover>}
                    </div>
                </div>


                <InfoCall />


                <div className="border rounded p-3 mt-3 d-flex flex-wrap" style={{ lineHeight: "30px" }}>
                    <div className="bg-primary text-uppercase px-2 py-1 rounded text-white">  <ArrowUpwardIcon className={`arrowCall ${item.direction === "Inbound" ? "rotateInbound" : "rotateOutbound"}`} /> {item.direction}  </div>
                    <div className=" d-flex align-items-center"> <span className="px-3">|</span>  <AccessTimeIcon /> <span className="px-1">{item.duration_seconds}</span>   </div>
                    <div className=" d-flex align-items-center"> <span className="px-3">|</span> From :  <span className="px-1">{item.from}</span>   </div>
                    <div className=" d-flex align-items-center"> <span className="px-3">|</span>  <span className="px-1">{item.unix_timestamp}</span>   </div>
                </div>



                {findRecording.length > 0 && <div className="border border-2 containerAudioTag  p-3  mt-4">
                    <audio
                        className="d-none"
                        src={getUrlVoiceAudio(findRecording[0].media_recordings[0])}
                        controls
                        type="audio/mpeg"
                        preload="true"
                        ref={refAudio}
                        onTimeUpdate={onChangeTime}
                    />
                    <div style={{ width: "10%" }}> <div onClick={onClickButtonPlay} className="bg-primary button-play mr-2  "> {playActive ? <PauseIcon style={{ color: "white" }} /> : <PlayArrowIcon style={{ color: "white" }} />}   </div></div>
                    <div className="barRange">  <div style={{ width: `${time.timeProgress}%` }} className="barRangePositionBackground">. </div>  </div>
                    <div className="containerTime"> {time.timeSecond || "00:00"} / {item.durationReplayPlay}  </div>
                </div>}

                {item.contactData &&
                    <div className="pt-4">
                        <h5>Contact Information</h5>
                        {contactInfo.map((element, i) => (<div key={i} className="row" > <p className="col-3">{element.title}</p> <p className="col-9">{element.value}  </p> </div>))}
                    </div>}



                <Row>
                    <Col sm="12" className="mb-sm-2 mb-lg-0 d-flex align-items-center"      >
                        <label htmlFor="note m-0 pb-2"   >
                            Call Notes:
                        </label>
                    </Col>
                    <Col sm="12">
                        <textarea
                            onChange={onChangeNotes}
                            value={note.notes}
                            name="note"
                            id="note"
                            type="text"
                            className="form-control"
                        />
                    </Col>
                    <Col sm="12" className="mt-3 d-flex justify-content-end">
                        <Button color="primary" onClick={onClickSubmitNotes}>
                            Save
                        </Button>
                    </Col>
                </Row>


            </div>
        </Card>
    )
}
