import React, {useState, useEffect} from "react";
import {
  Row,
  Col,
  Button,
  Media,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Spinner
} from "reactstrap";

export default function FilterThree({step, setStep, setData, data, products}) {

    const IMAGES = [
    {description: "Imagen one", id: "1"},
    {description: "Imagen one", id: "2"},
    {description: "Imagen one", id: "3"},
    {description: "Imagen one", id: "4"},
    {description: "Imagen one", id: "5"},
  ];

  //Product Select
  const [form, setForm] = useState({
    option: "selected",
    pk: "",
  });



  const onChangeSelected = (e) => {
    setForm({...form, pk: "", option: e.target.value});
  };


  const onClickCard = (product) => {
    if (form.option === "yes") {
      if (product.pk === form.pk) {
        return setForm({...form, pk: "", product: {}});
      }
      setForm({...form, pk: product.pk, product: product});
    }
  };

  const next = () => {
    setStep(step + 1);
    setData({...data, phoneId: {pk: form.pk, product: form.product}});
  };

  return (
    <>
      <br />
      <div style={{margin: "0 2em"}}>
        <Row>
          <Col>
            You may use the free web phone found on the portal or you may order
            a pysical device at an additional cost. Would you like to order a
            device?
          </Col>
        </Row>
        <Row>
          <Col className="pt-4 " sm="4">
            {" "}
            <label htmlFor="country">Your Option</label>
            <select
              onChange={onChangeSelected}
              className="form-control"
              name=""
              id="country"
            >
              <option value="selected">Select one...</option>
              <option value="no">
                No, I will use a web phone or application
              </option>
              <option value="yes">Yes</option>
            </select>
          </Col>
        </Row>
        <Row>
          {form.option === "yes" && <> {products.length === 0 ? <Col style={{ textAlign: "center", width: "100%" }}>
            <Spinner
              animation="grow"
              style={{
                margin: "10vh auto",
                height: "10vh",
                width: "10vh",
              }}
            />{" "}
          </Col> : <> {  products.map((element, i) => {
              return (
                <Col
                  style={{padding: "3rem 3rem 0 3rem"}}
                  sm="6"
                  md="6"
                  lg="3"
                  xl="4"
                  key={i}
                >
                  <Card
                    className={`${
                      element.data.result.pk === form.pk
                        ? "bg-soft-primary"
                        : "bg-soft-secondary"
                    }`}
                    onClick={() => {
                      onClickCard(element.data.result);
                    }}
                    style={{
                      height: "auto",
                      cursor: "pointer",
                      transform: `${
                        element.data.result.pk === form.pk
                          ? "scale(1.05)"
                          : "scale(1)"
                      }`,

                      paddingTop: "2rem",
                    }}
                  >
                    <CardImg
                      style={{width: "70%", margin: "0 auto"}}
                      top
                      src={element.data.result.product_image}
                      alt="Card image cap"
                    />
                    <CardBody>
                      <CardTitle tag="h5">{element.data.result.name}</CardTitle>
                      <CardText>{element.data.result.description}</CardText>
                    </CardBody>
                  </Card>
                </Col>
              );
            })} </>}  </>
          }
        </Row>
      </div>
      <br /> <br />
      <Row style={{marginRight: "1em"}}>
        <Col sm="12" style={{textAlign: "right"}}>
          <Button
            style={{width: "100px"}}
            color="primary"
            disabled={form.option === "no" || form.pk !== "" ? false : true}
            onClick={() => next()}
          >
            {" "}
            Next
          </Button>
        </Col>
      </Row>
    </>
  );
}
