import React, { useState } from 'react'
import phoneImg from "../../../../assets/images/phone-image.svg"
import {
    Button,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
} from "reactstrap";

import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EditIcon from '@material-ui/icons/Edit';
import ImageUploading from 'react-images-uploading';
import Tilt from 'react-tilt'
export default function ModalAddImages({ modal, setModal, imgMessages, setImgMessages }) {

    const [loading, setLoading] = useState(false);

    const onChangeOneImage = (e) => {
        setImgMessages(e)
    }


    return (
        <Modal isOpen={modal === false ? false : true} className="info">
            <ModalHeader className="bg-soft-primary">
                <Row>
                    <Col xs="9">
                        <div className="text-primary p-3">
                            <h3 className="text-primary">Add Images</h3>
                        </div>
                    </Col>
                    <Col xs="3" className="align-self-end">
                        <img src={phoneImg} alt="" className="img-fluid" />
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody>

                <ImageUploading
                    multiple
                    value={imgMessages}
                    onChange={(e) => { onChangeOneImage(e) }}
                    maxNumber={10}
                    dataURLKey="data_url"
                    maxFileSize={3145728}
                >
                    {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                    }) => (
                        <>

                            <div className="row">
                                {imageList.map((image, index) => (
                                    <div key={index} className={`upload__image-wrapper col-4`} >
                                        <div key={index} className="image-item">
                                            <img src={image['data_url']} alt="" width="100" />
                                            <div className="image-item__btn-remove">
                                                <button onClick={() => onImageRemove(index)}>  <HighlightOffIcon /></button>
                                            </div>
                                            <div className="image-item__btn-edit">
                                                <button onClick={() => onImageUpdate(index)}><EditIcon /></button>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <div className={`upload__image-wrapper col-4 ${isDragging && "upload__image-wrapper-hover"}`} {...dragProps} >

                                    <Tilt className="Tilt" style={{ height: 140, width: 140 }} >


                                        <button
                                            className="upload__image-button"
                                            onClick={onImageUpload}

                                        >
                                            <div className="perspective3D">
                                                <AddIcon />
                                                <span>

                                                    Add Image
                                                </span>
                                            </div>

                                        </button>

                                    </Tilt>
                                </div>
                            </div>
                        </>
                    )}
                </ImageUploading>

            </ModalBody>
            <ModalFooter>
                <Button
                    disabled={loading}
                    outline
                    color="secondary"
                    onClick={() => {
                        setModal(false);
                    }}
                >
                    Cerrar
                </Button>


            </ModalFooter>
        </Modal>
    )
}
