import React, { useState, useEffect } from "react";
import SipComponent from "../../components/SipComponent/SipComponentConference";
import "intro.js/introjs.css";
import "./index.css";

import ringbacktone from "../../assets/audio/ringbacktone.mp3";
import ringtoneSound from "../../assets/audio/ringtone.mp3";

export default function Prueba() {
  const [registerSipExtention, setRegisterSipExtention] = useState(false);
  const [initiCall, setInitCall] = useState(false);
  const [endCall, setEndCall] = useState(false);
  const [numberToCall, seTNumberToCall] = useState("");
  const [audioInputSelected, setAudioInputSeleced] = useState("default");
  const [audioOutputSelected, setAudioOutputSelected] = useState("default");
  const [initAnswer, setInitAnswer] = useState(false);
  const [rejectedCall, setRejectedCall] = useState(false);
  const [endInvited, setEndInvited] = useState(false);
  const [hold, setHold] = useState(false);
  const [unhold, setUnhold] = useState(false);
  const [mute, setMute] = useState(false);
  const [unmute, setUnmute] = useState(false);
  const [silence, setSilence] = useState(false);
  const [unsilence, setUnsilence] = useState(false);
  const [transferCall, setTransferCall] = useState(false);


  ///////////test

  const [callAudio] = useState(new Audio());
  const [ringtone] = useState(new Audio(ringtoneSound));
  const [rinding] = useState(new Audio(ringbacktone));
  const [allReceivedMediaStreams] = useState(new MediaStream());
  const [allAudios] = useState([]);

  rinding.loop = true;
  ringtone.loop = true;

  function startAudio(track) {
    // rinding.play();
    // console.log(ringtone.audioTracks);
    console.log(track === 1 ? ringtone.src : rinding.src);
    allAudios.push(track === 1 ? ringtone.src : rinding.src)
    console.log(allAudios);
    allAudios.forEach(element => {
      allReceivedMediaStreams.addTrack(element)
    });
    callAudio.srcObject = allReceivedMediaStreams;
    callAudio.play();
  }

  /////////////end test

  ///////////////// nuevo Dia de Hoy viernes 25 de junio
  const [keyboardNumber, setKeyboardNumber] = useState(null);
  /////////////////

  const [state, setState] = useState({
    audioInputSelect: null,
    audioOutputSelect: null,
    videoElement: null,
  });

  useEffect(() => {

    setState({
      videoElement: document.querySelector('video'),
      audioInputSelect: document.querySelector('select#audioSource'),
      audioOutputSelect: document.querySelector('select#audioOutput'),
      selectors: [document.querySelector('select#audioSource'), document.querySelector('select#audioOutput')],
    })

  }, [])

  useEffect(() => {

    if (state.selectors) {
      navigator.mediaDevices.enumerateDevices().then(gotDevices).catch(handleError);
    }

  }, [state])


  function callNumberButton(number) {
    setInitCall(true);
    seTNumberToCall(number)
  }

  function endCallButton() {
    setEndCall(true);
  }

  function gotDevices(deviceInfos) {
    const values = state.selectors.map(select => select.value);
    state.selectors.forEach(select => {
      while (select.firstChild) {
        select.removeChild(select.firstChild);
      }
    });
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      const option = document.createElement('option');
      option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === 'audioinput') {
        option.text = deviceInfo.label || `microphone ${state.audioInputSelect.length + 1}`;
        state.audioInputSelect.appendChild(option);
      } else if (deviceInfo.kind === 'audiooutput') {
        option.text = deviceInfo.label || `speaker ${state.audioOutputSelect.length + 1}`;
        state.audioOutputSelect.appendChild(option);
      } else {
        console.log('Some other kind of source/device: ', deviceInfo);
      }
    }
    state.selectors.forEach((select, selectorIndex) => {
      if (Array.prototype.slice.call(select.childNodes).some(n => n.value === values[selectorIndex])) {
        select.value = values[selectorIndex];
      }
    });
  }

  function changeAudioDestination() {
    const audioDestination = state.audioOutputSelect.value;
    attachSinkId(state.videoElement, audioDestination);
  }


  function attachSinkId(element, sinkId) {
    if (typeof element.sinkId !== 'undefined') {
      element.setSinkId(sinkId)
        .then(() => {
          console.log(`Success, audio output device attached: ${sinkId}`);
        })
        .catch(error => {
          let errorMessage = error;
          if (error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
          console.error(errorMessage);
          state.audioOutputSelect.selectedIndex = 0;
        });
    } else {
      console.warn('Browser does not support output device selection.');
    }
  }

  function handleError(error) {
    console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
  }

  function getRegistered(registered) {
    console.log(registered);
  }

  const status = {
    getRegistered: getRegistered
  }



  return (
    <div className="page-content">
      <SipComponent
        configuration={{
          username: "ypzJOQ0KkagP",
          password: "KXqrcUG05wtlrQF3XGPuw8Ku",
          realm: "09c2768c.ucs.itpvoice.net",
        }}
        registerSipExtention={registerSipExtention}
        status={status}
        callNumber={{ initiCall: initiCall, numberToCall: numberToCall, setInitCall: setInitCall }}
        answerCall={{ initAnswer: initAnswer, setInitAnswer: setInitAnswer }}
        endCall={{ endCall: endCall, setEndCall: setEndCall }}
        rejectedCall={{ rejectedCall: rejectedCall, setRejectedCall: setRejectedCall }}
        endInvited={{ endInvited: endInvited, setEndInvited: setEndInvited }}
        audioInputSelected={audioInputSelected}
        audioOutputSelected={audioOutputSelected}
        hold={{ hold: hold, setHold: setHold }}
        unhold={{ unhold: unhold, setUnhold: setUnhold }}
        mute={{ mute: mute, setMute: setMute }}
        unmute={{ unmute: unmute, setUnmute: setUnmute }}
        silence={{ silence: silence, setSilence: setSilence }}
        unsilence={{ unsilence: unsilence, setUnsilence: setUnsilence }}
        keyboardNumber={{ keyboardNumber: keyboardNumber, setKeyboardNumber: setKeyboardNumber }}
        transferCall={{transferCall:transferCall,setTransferCall:setTransferCall}}

      />
      <div id="container">
        <div className="select">
          <label htmlFor="audioSource">Audio input source: </label><select onChange={(e) => { setAudioInputSeleced(e.target.value) }} id="audioSource"></select>
        </div>

        <div className="select">
          <label htmlFor="audioOutput">Audio output destination: </label><select onChange={(e) => { setAudioOutputSelected(e.target.value) }} id="audioOutput"></select>
        </div>

        <video id="video" ></video>

        <br></br>
        <br></br>

        <button onClick={() => { setRegisterSipExtention(true) }}>Register</button>

        <button onClick={() => { setRegisterSipExtention(false) }}>unregister</button>
        <button onClick={() => { setInitAnswer(true) }}>Answer</button>

        <br></br>
        <br></br>

        <button onClick={() => { setHold(true) }}>Hold Call</button>
        <button onClick={() => { setUnhold(true); }}>Unhold Call</button>

        <br></br>
        <br></br>
        <button onClick={() => { setMute(true) }}>Mute</button>
        <button onClick={() => { setUnmute(true); }}>Unmute</button>

        <br></br>
        <br></br>
        <button onClick={() => { setSilence(true) }}>Silence</button>
        <button onClick={() => { setUnsilence(true); }}>UnSilence</button>

        <br></br>
        <br></br>

        {/* <button onClick={() => { callNumberButton('8002') }}>Call</button> */}
        <button onClick={() => { callNumberButton('3059143701') }}>Call</button>
        <button onClick={() => { endCallButton(); }}>End Call</button>
        <button onClick={() => { setRejectedCall(true); }}>Rejected</button>
        <button onClick={() => { setEndInvited(true); }}>End Invited</button>

        {/* ///////////////// nuevo Dia de Hoy viernes 25 de junio */}

        <br></br>
        <br></br>

        {/* <button onClick={() => {setKeyboardNumber(2) }}>Button 2</button>
        <button onClick={() => {setKeyboardNumber(3) }}>Button 3</button> */}

        <button onClick={() => { callNumberButton("8002") }}>Button 8002</button>
        <button onClick={() => { callNumberButton("8003") }}>Button 8003</button>
        <button onClick={() => { callNumberButton("12345") }}>Button 12345</button>

        <br></br>
        <br></br>
        <button onClick={() => { startAudio(1) }}>Audio 1</button>
        <button onClick={() => { startAudio(2) }}>Audio 2</button>
        <button onClick={() => { callAudio.pause() }}>Pause </button>
        
        <button onClick={() => { setTransferCall("12345") }}>transfer 12345</button>

        <br></br>
        <br></br>
        {/*//////////////////////  */}

      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    layout: state.sip,
  };
};

