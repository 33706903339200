import React, { Component } from "react";
import { Container, Table, Button } from "reactstrap";

//Import Breadcrumb
import petition_get from "../../petitions/petition_get";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import ModalUtil from "../../../components/utils/modal";

export default class ViewStaments extends Component {
  constructor(props) {
    super(props);
    this.userData = JSON.parse(window.localStorage.getItem("authUser"));
    this.state = {
      data: [],
      modal: false,
      modalbody: <h3>uno dos tres cuatro</h3>,
      title: "titulo",
      spinner: true,
    };
  }
  toggle = () => this.setState({ modal: !this.state.modal });
  childFunction = () => {
    this.setState({ spinner: !this.state.spinner });
  };

  modal() {
    if (this.state.modal) {
      return (
        <ModalUtil
          modal={this.state.modal}
          toggle={this.toggle}
          modalbody={this.state.modalbody}
          childFunction={this.childFunction}
          title={this.state.title}
          spinner={this.state.spinner}
        />
      );
    }
  }
  componentDidMount() {
    petition_get("viewStaments", this.userData.account.billing_api_id)
      .then(({ data: { result } }) => {
        this.setState({
          data: result,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  Download(val, name) {
    petition_get("getStaments", val)
      .then(({ data: { result } }) => {
        let link = document.createElement("a");
        link.download = `${name}.pdf`;
        link.href = `data:application/pdf;base64,${result}`;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();

        this.setState({ modal: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ modal: false });
      });
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Utility" breadcrumbItem="Starter Page" />
            <br />
            {this.modal()}
            <Table className="table mb-0">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>DATE</th>
                  <th style={{ textAlign: "center" }}>DOWNLOAD PDF</th>
                </tr>
              </thead>

              <tbody>
                {this.state.data.map((element, i) => (
                  <tr key={i} style={{ display: i < 5 ? null : "none" }}>
                    <th style={{ textAlign: "center" }}>
                      {element.created.substr(0, 10)}
                    </th>
                    <th style={{ textAlign: "center" }}>
                      <Button
                        onClick={() => {
                          this.toggle();
                          this.Download(element._id, element.created.substr(0, 10));
                        }}
                        color="primary"
                      >
                        Download PDF
                      </Button>
                    </th>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
