import React, { useState, useEffect } from "react";
import petition_get from "../../../../../pages/petitions/petition_get";
import { Spinner, Row, Col, Button, Badge } from "reactstrap";
import states from "../../State/config";
import { Element } from "react-scroll";
export default function Filter({
  filterNumbers,
  setCityState,
  setNpaNxx,
  setNpa,
  npa,
  cityState,
  npaNxx,
  onSubmitNumbers,
  errorNumbers,
  loading,
  setLoading,
  resetActive,
  resetAll,
  prefix,
  setPrefix,
  setErrorNumbers,
}) {
  const [cities, setCities] = useState(null);
  const [loadingUbication, setLoadingUbication] = useState(false);

  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  useEffect(() => {
    if (loading === "submit") {
      setLoadingUbication(true);
    } else {
      setLoadingUbication(false);
    }
  }, [loading]);

  useEffect(() => {
    setCityState({
      state: "",
      city: "",
    });

    setNpa({
      code: "",
    });

    setNpaNxx({
      npa: "",
      nxx: "",
    });
  }, [filterNumbers.type]);

  const onChangeCityState = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setErrorNumbers(null);
    if (name === "state") {
      setCities(null);
      setLoading("cities");
      const stateInfo = states.find(
        (state) => state.shortcode === e.target.value
      );

      await petition_get("cities", { id, stateCode: stateInfo.shortcode })
        .then(({ data: result }) => {
          if (result.result.RateCenterResponse.ResultCount === "1")
            setCities([{ ...result.result.RateCenterResponse.RateCenters.RateCenter }])

          else
            setCities(result.result.RateCenterResponse.RateCenters.RateCenter);

        })
        .catch((error) => {
          console.log(error.response);
        });
    }

    setCityState({ ...cityState, [name]: value });
    setLoading(false);
  };
  const onChangeNpa = (e) => {
    setErrorNumbers(null);
    if (e.target.value.length <= 3) {
      return setNpa({ ...npa, [e.target.name]: e.target.value });
    }
  };

  const onChangeNpaNxx = (e) => {
    setErrorNumbers(null);
    if (e.target.value.length <= 3) {
      return setNpaNxx({ ...npaNxx, [e.target.name]: e.target.value });
    }
  };

  const onChangePrefix = (e) => {
    setErrorNumbers(null);
    if (e.target.value.length <= 3) {
      return setPrefix({ code: e.target.value });
    }
  };

  if (filterNumbers.category === "tool")
    return (
      <>
        <div style={{ margin: "0 2em" }}>
          <form
            id="form5"
            onSubmit={(e) => {
              e.preventDefault();
              onSubmitNumbers();
            }}
          >
            <Row>
              <Col sm="12" lg="4">
                <label className="form-title" htmlFor="">
                  Select Prefix
                </label>
                <select
                  disabled={resetActive ? true : false}
                  onChange={onChangePrefix}
                  name="code"
                  className="form-control"
                >
                  <option value="select">Select one...</option>
                  <option value="888">888</option>
                  <option value="877">877</option>
                  <option value="866">866</option>
                  <option value="855">855</option>
                  <option value="844">844</option>
                  <option value="833">833</option>
                  <option value="800">800</option>
                  <option value="8**">8**</option>
                </select>
              </Col>
            </Row>

            <Row>
              <Col style={{ margin: "2rem 0" }} sm="12" lg="4">
                <Element name="numbers" className="btn-numbers ">
                  <Button
                    color="primary"
                    onClick={() => {
                      if (resetActive) {
                        resetAll();
                        document.getElementById("form1").reset();
                        switch (filterNumbers.type) {
                          case "NPA":
                            document.getElementById("form2").reset();
                            break;
                          case "NPANXX":
                            document.getElementById("form3").reset();
                            break;
                          case "City/State":
                            document.getElementById("form4").reset();
                            break;

                          default:
                            break;
                        }
                      } else {
                        onSubmitNumbers();
                      }
                    }}
                    disabled={loading === "submit" ? true : false}
                  >
                    {loadingUbication && (
                      <Spinner
                        style={{ marginRight: "1rem" }}
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    {resetActive ? "Reset Form" : "Search Numbers"}
                  </Button>
                  <Badge
                    color="danger"
                    style={{
                      marginLeft: "2em",
                      display: errorNumbers ? "initial" : "none",
                    }}
                  >
                    {errorNumbers}
                  </Badge>
                </Element>
              </Col>
            </Row>
          </form>
        </div>
      </>
    );

  if (filterNumbers.type === "") return null;

  return (
    <>
      <div style={{ margin: "2rem 2rem 0 2rem" }}>
        {filterNumbers.type === "NPA" && (
          <Row>
            <Col sm="12" lg="4">
              <form
                id="form2"
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmitNumbers();
                }}
              >
                <label htmlFor="">Select Area Code</label>
                <input
                  disabled={resetActive ? true : false}
                  name="code"
                  onChange={onChangeNpa}
                  value={npa.code}
                  type="number"
                  placeholder="Example: 305"
                  className="form-control"
                />
              </form>
            </Col>
          </Row>
        )}
        {filterNumbers.type === "NPANXX" && (
          <form
            id="form3"
            onSubmit={(e) => {
              e.preventDefault();
              onSubmitNumbers();
            }}
          >
            <Row>
              <Col sm="12" lg="4">
                <label htmlFor="npa">Select Area Code</label>
                <input
                  disabled={resetActive ? true : false}
                  id="npa"
                  name="npa"
                  onChange={onChangeNpaNxx}
                  value={npaNxx.npa}
                  type="number"
                  placeholder="Example: 305"
                  className="form-control"
                />
              </Col>

              <Col sm="12" lg="4">
                <label htmlFor="nxx">Select NXX Code</label>
                <input
                  disabled={resetActive ? true : false}
                  id="nxx"
                  name="nxx"
                  onChange={onChangeNpaNxx}
                  value={npaNxx.nxx}
                  type="number"
                  placeholder="Example: 299"
                  className="form-control"
                />
              </Col>
            </Row>{" "}
          </form>
        )}
        {filterNumbers.type === "City/State" && (
          <form
            id="form4"
            onSubmit={(e) => {
              e.preventDefault();
              onSubmitNumbers();
            }}
          >
            <Row>
              <Col sm="12" lg="4">
                <label htmlFor="">Select State</label>
                <select
                  disabled={resetActive ? true : false}
                  onChange={onChangeCityState}
                  name="state"
                  id=""
                  className="form-control"
                >
                  <option value="">Select one...</option>


                  {states.sort(function (a, b) {
                    if (a.name > b.name) {
                      return 1;
                    }
                    if (a.name < b.name) {
                      return -1;
                    }
                    // a must be equal to b
                    return 0;
                  }).map((state) => {
                    return (
                      <option key={state.id} value={state.shortcode}>
                        {state.name.toUpperCase()}
                      </option>
                    );
                  })}
                </select>
              </Col>
              <Col sm="12" lg="4">
                {loading === "cities" && (
                  <Spinner
                    style={{ marginTop: "2rem" }}
                    className="spinner-btn"
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {cityState.state !== "" && cities && (
                  <>
                    <label htmlFor="">Select City</label>

                    <select
                      disabled={resetActive ? true : false}
                      onChange={onChangeCityState}
                      name="city"
                      id=""
                      className="form-control"
                    >
                      <option value="">Select one...</option>
                      {cities.map((city, i) => {
                        return (
                          <option key={i} value={city.Abbreviation}>
                            {city.Name}
                          </option>
                        );
                      })}
                    </select>
                  </>
                )}
              </Col>
            </Row>
          </form>
        )}
        <Row>
          {" "}
          <Col style={{ margin: "2rem 0" }}>
            <Element name="numbers" className="btn-numbers ">
              <Button
                color="primary"
                onClick={() => {
                  onSubmitNumbers();
                }}
                disabled={loading === "submit" ? true : false}
              >
                {loadingUbication && (
                  <Spinner
                    style={{ marginRight: "0.5em" }}
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Search Numbers
              </Button>
              <Badge
                color="danger"
                style={{
                  marginLeft: "2em",
                  display: errorNumbers ? "initial" : "none",
                }}
              >
                {errorNumbers}
              </Badge>
            </Element>
          </Col>{" "}
        </Row>
      </div>
    </>
  );
}
