//import React from "react";
import axios from "axios";
//import { Redirect } from "react-router-dom";

export default function ForgetPassword(val) {
  const json = require("../../config.json");
  const urlBase = json.urlBase;
  var url;

  url = urlBase + "/crm/auth/reset-pw?username=" + val;
  return axios.get(url);
}
