import React from 'react'
import { Multiselect } from "multiselect-react-dropdown";

export default function Input({ formik, type, value, name, onBlur, placeholder, label, onChangeInput, firstOptions, dropdownOptions, checkOptions }) {

    const handleChangeCheck = (e) => {

        onChangeInput({ target: { value: e, name } })
        formik.touched[name] = false
        delete formik.errors[name]
    }

    return (
        <div className={`col-12 p-0 mb-3  ${formik.errors[name] && formik.errors[name] && formik.touched[name] && formik.touched[name] && "input-error-form"}`}>
            <label className="labelInput" htmlFor={name} style={{ width: "100%", textAlign: "left" }}>
                {label}:
            </label>

            {(type === "text" || type === "email" || type === "number") && <input type={type} id={name} name={name} onBlur={onBlur} value={value} onChange={onChangeInput} placeholder={placeholder} className={`form-control ${formik.errors[name] && formik.errors[name] && formik.touched[name] && formik.touched[name] && "form-control-error"}`} />}

            {/* 
            {(type === "check") && <> {checkOptions.map((element, i) => <div className="d-flex" key={i}> <CustomInput type="checkbox" id={element.name} name={name} onChange={(e) => { handleChangeCheck(e, element) }} /> <label htmlFor={element.name}> {element.name} </label>   </div>)}  </>}
        */}
            {(type === "check") &&

                <Multiselect
                    options={[...checkOptions]}
                    onSelect={handleChangeCheck}
                    onRemove={handleChangeCheck}
                    selectedValues={firstOptions || []}
                    displayValue="name"
                    placeholder="Start Typing..."


                    style={{
                        searchBox: { border: `1px solid ${(formik.errors[name] && formik.errors[name] && formik.touched[name] && formik.touched[name]) ? "#f46a6a" : "white"}` },
                        inputField: {},
                    }}
                />

            }


            {type === "dropdown" && <select id={name} name={name} onBlur={onBlur} value={value} onChange={onChangeInput} placeholder={placeholder} className={`form-control ${formik.errors[name] && formik.errors[name] && formik.touched[name] && formik.touched[name] && "form-control-error"}`}>
                {dropdownOptions.map((element, i) => <option key={i} value={element.value}> {element.label} </option>)}
            </select>}



        </div>
    )
}
