import React, { useState, useEffect } from "react";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import Steps from "./Steps/Steps";
import StepOne from "./Steps/StepOne";
import StepTwo from "./Steps/StepTwo";
import StepThree from "./Steps/StepThree";
import StepRepeat from "./Steps/StepRepeat";
import StepViewCarriers from "./Steps/StepViewCarriers";
import toastr from "toastr";
import petition_get from "../../../petitions/petition_get"
import {
  Container,
  Table,
  CustomInput,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
} from "reactstrap";

import { connect } from "react-redux";

import petition_post from "../../../petitions/petition_post";

import IgnoreNumbersModal from "./Modals/IgnoreNumbersModal";
import { invalid } from "moment";
import { clearFields } from "redux-form";

function TabTransferNumbers({ pricePerDid }) {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  toastr.options = {
    showDuration: 300,
  };
  const [step, setStep] = useState(1);
  const [stepTwo, setStepTwo] = useState(0);
  const [form, setForm] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingIgnoreNumbers, setLoadingIgnoreNumbers] = useState(false);
  const [invalidModal, setInvalidModal] = useState(false);
  const [invalidNumbers, setInvalidNumbers] = useState(false);
  const [supportedLosingCarriers, setSupportedLosingCarriers] = useState(null);
  const [allData, setAllData] = useState([]);
  const [states, setStates] = useState("");

  const [inputError, setInputError] = useState(null)
  // const [cities,setCities] = useState("");

  const [formMetadata, setFormMetadata] = useState({
    porting_number: false,
    allow_none: false,
    porting_business_type: "",
    porting_first_name: "",
    porting_last_name: "",
    porting_business_name: "",
    porting_house_number: "",
    porting_street_name: "",
    porting_city_name: "",
    porting_state_code: "",
    porting_zip_code: "",
    porting_btn: "",
    porting_pin: "",
    porting_account_number: "",
    porting_carrier: "",
    pin: ""
  });

  useEffect(() => {
    petition_get("estates")
      .then(({ data: result }) => {
        setStates(result.result);
      })
      .catch((error) => {
        console.log(error);
      });

  }, []);

  const filterValidNumbers = () => {
    const newForm = form.split("\n").filter((element) => {
      if (
        element.trim() !== "" &&
        parseInt(element.trim()).toString().length === element.trim().length
      ) {
        return element.trim();
      }
    });
    const formTrim = newForm.map((element) => {
      return element.trim();
    });

    const filterNumbers = formTrim.filter((element) => {
      let findNumber = false;
      if (typeof invalidNumbers === "string") {
        if (invalidNumbers === element) findNumber = true;
      } else {
        findNumber = invalidNumbers.find(
          (elementTwo) => elementTwo === element
        );
      }

      if (!findNumber) return element;
    });


    setLoadingIgnoreNumbers(true);
    if (filterNumbers.length !== 0) {
      petition_post("verifyTransferNumbers", {
        id,
        data: { numbers: filterNumbers },
      })
        .then(({ data: result }) => {
          console.log(result)
          let temNumbers = [];
          if (result.result.NumberPortabilityResponse.SupportedTollFreeNumbers) {
            if (result.result.NumberPortabilityResponse.SupportedTollFreeNumbers.TollFreeNumberGroup.TnList[0]) {
              console.log("1111111111111111111111111");
              temNumbers.push({ LosingCarrierName: "Toll Free Number", porting_number_type: "toll_free", TnList: result.result.NumberPortabilityResponse.SupportedTollFreeNumbers.TollFreeNumberGroup.TnList[0] });

            } else {
              console.log("2222222222222222222222222");
              temNumbers.push({ LosingCarrierName: "Toll Free Number", porting_number_type: "toll_free", TnList: result.result.NumberPortabilityResponse.SupportedTollFreeNumbers.TollFreeNumberGroup.TnList });

            }
          }
          if (result.result.NumberPortabilityResponse.SupportedLosingCarriers) {
            let temLosing = result.result.NumberPortabilityResponse.SupportedLosingCarriers.LosingCarrierTnList.flat();
            temLosing[0].porting_number_type = temLosing[0].LosingCarrierIsWireless === "false" ? "local" : "wireless"
            console.log(temLosing)
            // temNumbers.push(...result.result.NumberPortabilityResponse.SupportedLosingCarriers.LosingCarrierTnList.flat());
            temNumbers.push(...temLosing);
          }
          console.log(temNumbers)
          setSupportedLosingCarriers(temNumbers);
          // setSupportedLosingCarriers(
          //   result.result.NumberPortabilityResponse.SupportedTollFreeNumbers ?
          //     [...result.result.NumberPortabilityResponse.SupportedLosingCarriers
          //       .LosingCarrierTnList, { LosingCarrierName: "Toll Free Number", TnList: result.result.NumberPortabilityResponse.SupportedTollFreeNumbers.TollFreeNumberGroup.TnList }]
          //     : result.result.NumberPortabilityResponse.SupportedLosingCarriers
          //       .LosingCarrierTnList
          // );

          setInvalidModal(false);
          setLoadingIgnoreNumbers(false);
          setForm(filterNumbers);
          setStep(step + 1);
          setInvalidNumbers(false);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          console.log("prueba");
        });
    } else {
      setInvalidModal(false);
      setLoadingIgnoreNumbers(false);
      toastr.error("No valid number has been entered", "Error");
    }
  };

  const reloadForm = () => {
    setFormMetadata({
      porting_number: false,
      allow_none: false,
      porting_business_type: "",
      porting_first_name: "",
      porting_last_name: "",
      porting_business_name: "",
      porting_house_number: "",
      porting_street_name: "",
      porting_city_name: "",
      porting_state_code: "",
      porting_zip_code: "",
      porting_btn: "",
      porting_pin: "",
      porting_account_number: "",
      porting_carrier: "",
      pin: ""
    });
  };



  const validateForm = () => {

    let error = null;


    if (formMetadata.porting_business_type.trim() === "") error = "porting_business_type"
    else if (formMetadata.porting_first_name.trim() === "") error = "porting_first_name"
    else if (formMetadata.porting_last_name.trim() === "") error = "porting_last_name"
    else if (formMetadata.porting_house_number.trim() === "") error = "porting_house_number"
    else if (formMetadata.porting_street_name.trim() === "") error = "porting_street_name"
    else if (formMetadata.porting_city_name.trim() === "") error = "porting_city_name"
    else if (formMetadata.porting_state_code.trim() === "") error = "porting_state_code"
    else if (formMetadata.porting_zip_code.trim() === "") error = "porting_zip_code"
    else if (formMetadata.porting_btn.trim() === "") error = "porting_btn"
    else if (formMetadata.porting_account_number.trim() === "") error = "porting_account_number"
    else if (formMetadata.porting_carrier.trim() === "") error = "porting_carrier"


    if (error) {
      setInputError(error)
      toastr.error("Complete Form", "Error");
      return true
    }

    return false
  }

  const handleNext = () => {
    switch (step) {
      case 1:
        if (form.length === 0) {
          toastr.error("Complete Form", "Error");
          return;
        }
        const newForm = form.split("\n").filter((element) => {
          if (
            element.trim() !== "" &&
            parseInt(element.trim()).toString().length === element.trim().length
          ) {
            return element.trim();
          }
        });
        const formTrim = newForm.map((element) => {
          return element.trim();
        });
        /*  setForm(formTrim); */
        setLoading(true);
        petition_post("verifyTransferNumbers", { id, data: { numbers: formTrim } })
          .then(({ data: result }) => {
            let temNumbers = [];
            if (result.result.NumberPortabilityResponse.SupportedTollFreeNumbers) {
              if (result.result.NumberPortabilityResponse.SupportedTollFreeNumbers.TollFreeNumberGroup.TnList[0]) {
                temNumbers.push({ LosingCarrierName: "Toll Free Number", porting_number_type: "toll_free", TnList: result.result.NumberPortabilityResponse.SupportedTollFreeNumbers.TollFreeNumberGroup.TnList[0] });
              } else {

                temNumbers.push({ LosingCarrierName: "Toll Free Number", porting_number_type: "toll_free", TnList: result.result.NumberPortabilityResponse.SupportedTollFreeNumbers.TollFreeNumberGroup.TnList });

              }
            }
            if (result.result.NumberPortabilityResponse.SupportedLosingCarriers) {
              let temLosing = result.result.NumberPortabilityResponse.SupportedLosingCarriers.LosingCarrierTnList.flat();
              temLosing[0].porting_number_type = temLosing[0].LosingCarrierIsWireless === "false" ? "local" : "wireless"

              // temNumbers.push(...result.result.NumberPortabilityResponse.SupportedLosingCarriers.LosingCarrierTnList.flat());
              temNumbers.push(...temLosing);
            }

            setSupportedLosingCarriers(temNumbers);
            // setSupportedLosingCarriers(
            //   result.result.NumberPortabilityResponse.SupportedTollFreeNumbers ?
            //     [...result.result.NumberPortabilityResponse.SupportedLosingCarriers
            //       .LosingCarrierTnList, { LosingCarrierName: "Toll Free Number", TnList: result.result.NumberPortabilityResponse.SupportedTollFreeNumbers.TollFreeNumberGroup.TnList }]
            //     : result.result.NumberPortabilityResponse.SupportedLosingCarriers
            //       .LosingCarrierTnList
            // );
            setInvalidModal(false);
            setLoadingIgnoreNumbers(false);
            setForm(formTrim);
            setStep(step + 1);
            // setStep(5);
            setInvalidNumbers(false);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);

            if (error.response.data.result.NumberPortabilityResponse) {
              if (
                error.response.data.result.NumberPortabilityResponse.Errors
                  .Description === "Telephone number is invalid"
              ) {
                setInvalidNumbers(
                  error.response.data.result.NumberPortabilityResponse.Errors
                    .TelephoneNumbers.Tn
                );
                setInvalidModal(true);
                setLoading(false);
              }
            } else {
              toastr.error("Action failed", "Error");
            }
          });
        break;
      case 2:
        setStep(step + 1)
        break;
      case 3:
        if (stepTwo < supportedLosingCarriers.length) {


          let result = validateForm()

          if (result) return

          const newAllData = allData;
          if (typeof supportedLosingCarriers[stepTwo].TnList.Tn === "string") {
            newAllData.push({
              ...formMetadata,
              did: supportedLosingCarriers[stepTwo].TnList.Tn,
              type: supportedLosingCarriers[stepTwo].LosingCarrierName,
            });
          } else {
            supportedLosingCarriers[stepTwo].TnList.Tn.map((element) => {
              newAllData.push({
                ...formMetadata,
                did: element,
                type: supportedLosingCarriers[stepTwo].LosingCarrierName,
              });
            });
          }

          setAllData(allData);
          reloadForm();
          if (stepTwo === supportedLosingCarriers.length - 1)
            return setStep(step + 1);
          return setStepTwo(stepTwo + 1);
        }

        setStep(step + 1);

        break;
      case 4:
        if (form.length > 0 && form.length) setStep(step + 1);

        break;
      case 5:
        //Petition
        setLoading(true);

        let types = {};
        let porting_number_type = {}

        supportedLosingCarriers.map((element) => {
          types[element.LosingCarrierName] = [];
          porting_number_type[element.LosingCarrierName] = element.porting_number_type;
        });

        allData.map((element) => {
          console.log(element);
          types[element.type] = [
            ...types[element.type],
            {
              did: element.did.trim(),
              business_type: element.porting_business_type,
              first_name: element.porting_first_name,
              last_name: element.porting_last_name,
              business_name: element.porting_business_name,
              house_number: element.porting_house_number,
              street_name: element.porting_street_name,
              city_name: element.porting_city_name,
              state_code: element.porting_state_code,
              zip_code: element.porting_zip_code,
              btn: element.porting_btn,
              account_number: element.porting_account_number,
              porting_carrier: element.porting_carrier,
              pin: element.pin,
              porting_number_type: porting_number_type[element.type]
            },
          ];
        });
        // console.log(supportedLosingCarriers);
        // console.log(types);
        const allPetitions = Object.keys(types);
        const promiseAll = [];
        allPetitions.map((element) => {
          promiseAll.push(
            petition_post("transferNumbers", {
              id,
              data: {
                numbers: [...types[element]],
                internal_port: element === "ITP" ? true : false,
              },
            })
          );
        });

        Promise.all(promiseAll)
          .then((values) => {
            toastr.success("Action completed", "Success");
            setStep(1);
            setStepTwo(0);
            setForm("");
            setLoading(false);
            setAllData([]);
          })
          .catch((reason) => {
            toastr.error("Action failed", "Error");

            console.log(reason);
          });

        break;

      default:
        break;
    }
  };

  return (
    <>
      <IgnoreNumbersModal
        modal={invalidModal}
        setModal={setInvalidModal}
        invalidNumbers={invalidNumbers}
        loading={loadingIgnoreNumbers}
        filterValidNumbers={filterValidNumbers}
      />
      <div >


        <Card body className="mx-2">
          <Steps step={step} />

          {step === 1 && (
            <StepOne
              formMetadata={formMetadata}
              setFormMetadata={setFormMetadata}
              setForm={setForm}
              form={form}
            />
          )}
          {step === 2 && (
            <StepViewCarriers
              supportedLosingCarriers={supportedLosingCarriers}
            />
          )}
          {step === 3 && (
            <StepRepeat
              inputError={inputError}
              step={stepTwo}
              formMetadata={formMetadata}
              setFormMetadata={setFormMetadata}
              setForm={setForm}
              form={form}
              supportedLosingCarriers={supportedLosingCarriers}
              states={states}
            // cities={cities}
            />
          )}
          {step === 4 && (
            <StepTwo
              setAllData={setAllData}
              allData={allData}
              setForm={setForm}
              form={form}
            />
          )}
          {step === 5 && (
            <StepThree
              pricePerDid={pricePerDid}
              setForm={setForm}
              form={form}
            />
          )}

          <div className="text-right  mt-3">
            {step > 1 && (
              <Button
                disabled={loading}
                color="outline-secondary"
                style={{ padding: "0.60rem 2rem", marginRight: "0.5rem" }}
                onClick={() => {
                  setStep(step - 1);
                  if (step === 2) {
                    setForm("");
                    setFormMetadata("");
                  }
                }}
              >
                Back
              </Button>
            )}

            <Button
              disabled={loading}
              color="primary"
              style={{ padding: "0.60rem 2.5rem" }}
              onClick={handleNext}
            >
              {loading && (
                <Spinner
                  className="spinner-submit mr-2"
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Next
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    pricePerDid: state.ITPVoice.didPrice,
  };
};

export default connect(mapStateToProps)(TabTransferNumbers);
