import React from "react";
import {Row, Col} from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer
        style={{
          position: "absolute",
          left: "0",
          bottom: "0",
        }}
        className="footer"
      >
        <div
          className="container-fluid"
          fluid={"true"}
          style={{display: "flex", justifyContent: "center"}}
        >
          <Row>
            <Col md={12}>{new Date().getFullYear()} © ITP.</Col>
          </Row>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
