import React from "react";
import {Row, Col, Table, Button, CustomInput, Badge} from "reactstrap";
export default function PeriodCard({
  errorChecked,
  setErrorChecked,
  setCheckBox,
  activeSelected,
  pricePerDid
}) {
  const onChangeCheck = (e) => {
    setCheckBox(e.target.checked);
    setErrorChecked(null);
  };

  return (
    <>
      <>
        <br />
        <div style={{margin: "0 2em", color: "red !important"}}>
          <Table responsive>
            <thead className="thead-light">
              <tr>
                <th>Period</th>
                <th>Charges And Credits</th>
                <th style={{textAlign: "center"}}>Unit Price</th>
                <th style={{textAlign: "center"}}>Quantity</th>
                <th style={{textAlign: "center"}}>Amount</th>
              </tr>
            </thead>
            <tbody>
              {activeSelected.map((number, i) => {
                return (
                  <tr key={i}>
                    <td>11/03/2020 - 12/02/2020</td>
                    <td>
                      Additional Local Number(new purchase) - {number.number}{" "}
                    </td>
                    <td style={{ textAlign: "center" }}>${ pricePerDid}</td>
                    <td style={{textAlign: "center"}}> 1</td>
                    <td style={{ textAlign: "center" }}>${ pricePerDid}</td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <th>Estimated Total :</th>
                <th style={{textAlign: "center"}}> ${pricePerDid * activeSelected.length}</th>
              </tr>
              <tr>
                <td colSpan="5" style={{ paddingTop: "4rem", border: "0" }}>
   All charges will appear as "Innovative Tech" on your credit card statement and will be charged to the credit card on file for this account if you have one on file.
                </td>
              </tr>
            
              <tr>
                <td colSpan="5" style={{border: "0"}}>
                  <div style={{padding: "0"}} className="form-check d-flex">
                    <CustomInput
                      onChange={onChangeCheck}
                      type="checkbox"
                      // onChange={onChangeCheck}
                      className="input-check"
                      name=""
                      id="exampleCheck1"
                    />
                    <label className="form-check-label" htmlFor="exampleCheck1">
                      <p>
                       I acknowledge that I have read this information and understand the itemized charges listed above. I authorize the total amount of ${ pricePerDid * activeSelected.length} to be charged to my credit card on file. I further authorize the monthly recurring charges of ${ pricePerDid * activeSelected.length} until canceled, which is in addition to my regular charges.
                      </p>
                      * Prices do not include taxes, fees or discounts <br />
                      <Badge
                        color="danger"
                        style={{
                          marginTop: "2em",
                          padding: "0.3rem 1rem",
                          opacity: errorChecked ? "1" : "0",
                        }}
                      >
                        Accept Terms...
                      </Badge>
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </>
    </>
  );
}
