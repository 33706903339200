import React, { useEffect, useState } from "react";

import petition_get from "../../../petitions/petition_get";
import CustomTable from "../../../../components/utils/CustomTable/CustomTable"
import CustomBreadCrum from "../../../../components/utils/CustomBreadCrum/CustomBreadCrum"

import toastr from "toastr";

import GetAppIcon from "@material-ui/icons/GetApp";

import ModalUtililyAdd from "./ModalUtility/ModalUtilityAdd";
import ModalUtilityDownload from "./ModalUtility/ModalUtilityDownload";

export default function MusicOnHold() {

  toastr.options = { showDuration: 300, };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const [modalAdd, setModalAdd] = useState(false);
  const [modalDownload, setModalDownload] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [reload, setReload] = useState(false)
  const [dataTable, setDataTable] = useState(null)

  useEffect(() => {
    const petitions = () => {
      setReload(true)
      setDataTable(null)
      petition_get("musicOnHoldMedia", {
        id: id,
      })
        .then(({ data: result }) => {
          let dataTableCopy = {
            title: [
              { title: "Name", key: "name" },
              { title: "LANGUAGE", key: "language" },
            ],
            content: [...result.result],
            actions: {
              title: "DOWNLOAD",
              content: [
                {
                  icon: <GetAppIcon />,  //Svg icon
                  handleClick: (e) => { setModalDownload(e) },  //Function
                },
              ],
            },
            search: {
              label: "Search Menu"
            },
            addButton: {
              label: "Add Media",
              handleClick: () => { setModalAdd(true) }
            }
          }
          setDataTable(dataTableCopy)
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (!reload) petitions();
  }, [reload]);

  const download = (element) => {
    setSpinner(true);
    petition_get("musicOnHoldMediaDetails", {
      id: id,
      mediaId: element.id,
    })
      .then(({ data: result }) => {
        setSpinner(false);
        setModalDownload(false);
        if (result.result.audio) {
          let link = document.createElement("a");
          link.download = "voicemail.mp3";
          link.href = `${result.result.audio}`;
          link.style.display = "none";
          document.body.appendChild(link);
          toastr.success("Action completed", "Success");
          return link.click();
        }
        toastr.error("Action failed", "Error");
      })
      .catch((error) => {
        console.log(error);
        setSpinner(false);
        setModalDownload(false);
      });
  };

  return (
    <>
      <ModalUtilityDownload
        modal={modalDownload}
        setModal={setModalDownload}
        download={download}
        loading={spinner}
      />
      <ModalUtililyAdd setReloadMusic={setReload} modal={modalAdd} setModal={setModalAdd} />
      <div className="page-content">
        <CustomBreadCrum title="Your Phone System" subtitle="Media / Audio File" />
        <CustomTable data={dataTable} />
      </div>
    </>
  );
}
