import React, { Component } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  CardTitle,
  CardText,
  Button,
  Table,
  Badge,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import petition_get from "../petitions/petition_get";

export default class Billing extends Component {
  constructor(props) {
    super(props);
    this.userData = JSON.parse(window.localStorage.getItem("authUser"));
    this.state = {
      data: [],
      paids: [],
      dataAccount: [],
    };
  }
  componentDidMount() {
    let paids = [];
    petition_get("billing_payments", this.userData.account.billing_api_id)
      .then(({ data: { result } }) => {
        this.setState({
          data: result,
        });
        result.forEach((element) => {
          if (element.payment_status === "Paid") {
            paids.push(element);
          }
        });
        this.setState({ paids: paids });
      })
      .catch((error) => {
        console.log(error);
      });

    petition_get("account", this.userData.account.billing_api_id)
      .then(({ data: { result } }) => {
        this.setState({
          dataAccount: result,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Billing" breadcrumbItem="Billing" />
            <Row>
              <Col md={7}>
                <Card body>
                  <CardTitle className="mt-0">Billing Sumary</CardTitle>
                  <CardText>
                    {this.state.dataAccount.balance === "0.00"
                      ? "You currently do not have a balance on your account."
                      : <strong>Current Balance Due: ${Number(this.state.dataAccount.balance).toLocaleString()} </strong>}
                  </CardText>

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    {/*      <Button
                      style={{marginRight: "1em"}}
                      color="light"
                      onClick={() => {
                        this.props.history.push("./BillDetails");
                      }}
                    >
                      View Billing Details
                    </Button> */}
                    <Button
                      style={{ marginLeft: "1em" }}
                      color="primary"
                      onClick={() => {
                        this.props.history.push("./MakePayments");
                      }}
                    >
                      Make Payment
                    </Button>
                  </div>
                </Card>
              </Col>

              <Col md={5}>
                <Card body>
                  <CardTitle className="mt-0">Common Solution</CardTitle>

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      color="light"
                      style={{ marginBottom: "1em" }}
                      onClick={() => {
                        this.props.history.push("./ViewStaments");
                      }}
                    >
                      View Staments
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => {
                        this.props.history.push("./AutomaticPayments");
                      }}
                    >
                      Automatic Payments Setting
                    </Button>
                  </div>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Card body>
                  <CardTitle className="mt-0">
                    Recent Payments And Credits
                  </CardTitle>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }}>DATE</th>
                          <th style={{ textAlign: "center" }}>PAYMENT STATUS</th>
                          {/* <th style={{maxWidth:"5em"}}>PAYMENT STATUS</th> */}
                          <th style={{ textAlign: "center" }}>AMOUNT</th>
                          <th style={{ textAlign: "center" }}>CREDIT CARD</th>
                        </tr>
                      </thead>

                      <tbody>
                        {this.state.paids
                          .sort(
                            (a, b) =>
                              new Date(a.created).getTime() / 1000 -
                              new Date(b.created).getTime() / 1000
                          )
                          .reverse()
                          .map((element, i) => (
                            <tr
                              key={i}
                              style={{ display: i < 5 ? null : "none" }}
                            >
                              <th style={{ textAlign: "center" }}>
                                {element.created.substr(0, 10)}
                              </th>
                              <th style={{ textAlign: "center" }}>
                                <h5>
                                  <Badge color="primary">
                                    {element.payment_status}
                                  </Badge>
                                </h5>
                              </th>
                              <th style={{ textAlign: "center" }}>
                                {element.amount}
                              </th>
                              <th style={{ textAlign: "center" }}>
                                {element.last_4_of_credit_card === null
                                  ? "***********"
                                  : "***********" +
                                  element.last_4_of_credit_card}
                              </th>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
