import React, {Component} from "react";
//import { Row, Col, Card, CardBody, Button, Container } from "reactstrap";
//import logolight from "../../assets/images/logo-light.png";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

//Import Breadcrumb
//import Breadcrumbs from "../../components/Common/Breadcrumb";

export default class YesOrNo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basic: false,
      with_title: false,
      success_msg: false,
      confirm_msg: false,
      success_dlg: false,
      error_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      confirm_both: false,
      img_header: false,
      img_html: false,
      custom_div: false,
      emailtxt: "",
      emailerr: "",
      step1: false,
      step1_txt: "",
      step2: false,
      step2_txt: "",
      step3: false,
      step3_txt: "",
      step_queue: false,
      final_queue: false,
      current_ip: "219.91.239.22",
      close_timer: false,
      timeralert: null,
      title: "",
    };
    this.handleStep1Change = this.handleStep1Change.bind(this);
    this.handleStep2Change = this.handleStep2Change.bind(this);
    this.handleStep3Change = this.handleStep3Change.bind(this);
  }

  handleStep1Change(e) {
    this.setState({step1_txt: e.target.value});
  }

  handleStep2Change(e) {
    this.setState({step2_txt: e.target.value});
  }

  handleStep3Change(e) {
    this.setState({step3_txt: e.target.value});
  }

  stimer() {
    const getTimer = () => (
      <SweetAlert
        title={
          <span>
            HTML <small>AutoClose Timer</small>!
          </span>
        }
        onConfirm={() => this.hideTimeAlert()}
      >
        {" "}
        <span>Timer Will Expire after 3 Seconds.</span>
      </SweetAlert>
    );
    this.setState({timeralert: getTimer()});
    setTimeout(
      function () {
        this.setState({timeralert: null});
      }.bind(this),
      3000
    );
  }
  hideTimeAlert() {
    this.setState({timeralert: null});
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      this.setState({
        // ajax_div: false,
        success_msg: this.props.success_msg,
        title: this.props.title,
      });
    }
  }
  onCancel() {
    // this.setState({ success_msg: false });
    this.props.onCancel();
  }
  onConfirm() {
    this.props.onConfirm();
    // this.setState({ success_msg: false })
  }

  render() {
    return (
      <React.Fragment>
        {this.state.timeralert}

        {/* <Row className="text-center">
                  <Col xl="3" lg="4" sm="6" className="m-b-30">
                    <p>A success message!</p>
                    <Button
                      color="primary"
                      onClick={() => this.setState({ success_msg: true })}
                      id="sa-success"
                    >
                      Click me
                    </Button> */}
        {this.state.success_msg ? (
          <SweetAlert
            title=""
            // success
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => this.onConfirm()}
            onCancel={() => this.onCancel()}
          >
            <h4 style={{color: "black"}}>{this.state.title}</h4>
          </SweetAlert>
        ) : null}
        {/* </Col>
                </Row> */}
      </React.Fragment>
    );
  }
}
