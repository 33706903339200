import React, { useState } from 'react'

import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,

} from "reactstrap";


export default function ModalAddImages({ modal, setModal, }) {



    return (
        <Modal centered size={"xl"} isOpen={modal === false ? false : true} className="info">

            <ModalBody className="text-center">

                <img src={modal} alt="message img" className="img-fluid" />

            </ModalBody>
            <ModalFooter>
                <Button
                    outline
                    color="secondary"
                    onClick={() => {
                        setModal(false);
                    }}
                >
                    Cerrar
                </Button>


            </ModalFooter>
        </Modal>
    )
}
