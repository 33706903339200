import React, {Component} from "react";
import {Collapse} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import classname from "classnames";

//i18n
import {withNamespaces} from "react-i18next";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  };

  dinamicNavbar() {
    if (
      window.location.pathname.toLowerCase() === "/myitp" ||
      window.location.pathname.toLowerCase() === "/orders" ||
      window.location.pathname.toLowerCase() === "/services" ||
      window.location.pathname.toLowerCase() === "/viewstaments" ||
      window.location.pathname.toLowerCase() === "/automaticpayments" ||
      window.location.pathname.toLowerCase() === "/billdetails" ||
      window.location.pathname.toLowerCase() === "/makepayments" ||
      window.location.pathname.toLowerCase() === "/newcard" ||
      window.location.pathname.toLowerCase() === "/billing"
    ) {
      return (
        <>
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                className="nav-link dropdown-toggle arrow-none"
                to="/Billing"
              >
                <i className="bx bx-home-circle mr-2"></i>
                {this.props.t("Billing")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link dropdown-toggle arrow-none"
                to="/Orders"
              >
                <i
                  className="bx bx-home-circle mr-2"
                  style={{fontFamily: "Poppins"}}
                ></i>
                {this.props.t("Orders")}
              </Link>
            </li>
          </ul>
        </>
      );
    } else if (
      window.location.pathname.toLowerCase() === "/itpvoice" ||
      window.location.pathname.toLowerCase() === "/voicemail" ||
      window.location.pathname.toLowerCase() === "/callhistory" ||
      window.location.pathname.toLowerCase() === "/fax" ||
      window.location.pathname.toLowerCase() === "/contactlist" ||
      window.location.pathname.toLowerCase() === "/settingsanddevices" ||
      window.location.pathname.toLowerCase() === "/listallusers" ||
      window.location.pathname.toLowerCase() === "/addusers" ||
      window.location.pathname.toLowerCase() === "/timeschedules" ||
      window.location.pathname.toLowerCase() === "/generalsettings" ||
      window.location.pathname.toLowerCase() === "/callflows" ||
      window.location.pathname.toLowerCase() === "/musiconhold" ||
      window.location.pathname.toLowerCase() === "/queues" ||
      window.location.pathname.toLowerCase() === "/ringgroups" ||
      window.location.pathname.toLowerCase() === "/dashboardusers" 
    ) {
      return (
        <>
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                className="nav-link dropdown-toggle arrow-none"
                to="/Voicemail"
              >
                <i className="bx bx-home-circle mr-2"></i>
                {this.props.t("Voicemail")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link dropdown-toggle arrow-none"
                to="/CallHistory"
              >
                <i
                  className="bx bx-home-circle mr-2"
                  style={{fontFamily: "Poppins"}}
                ></i>
                {this.props.t("Call History")}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link dropdown-toggle arrow-none" to="/Fax">
                <i
                  className="bx bx-home-circle mr-2"
                  style={{fontFamily: "Poppins"}}
                ></i>
                {this.props.t("Fax")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link dropdown-toggle arrow-none"
                to="/ContactList"
              >
                <i
                  className="bx bx-home-circle mr-2"
                  style={{fontFamily: "Poppins"}}
                ></i>
                {this.props.t("Contact List")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link dropdown-toggle arrow-none"
                to="/SettingsAndDevices"
              >
                <i
                  className="bx bx-home-circle mr-2"
                  style={{fontFamily: "Poppins"}}
                ></i>
                {this.props.t("Settings And Devices")}
              </Link>
            </li>
          </ul>
        </>
      );
    } else {
      return (
        <>
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle arrow-none"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    isDashboard: !this.state.isDashboard,
                  });
                }}
                to="dashboard"
              >
                <i className="bx bx-home-circle mr-2"></i>
                {this.props.t("Dashboard")} {this.props.menuOpen}
                <div className="arrow-down"></div>
              </Link>
              <div
                className={classname("dropdown-menu", {
                  show: this.state.isDashboard,
                })}
              >
                <Link to="index" className="dropdown-item">
                  {this.props.t("Default")}
                </Link>
                <Link to="dashboard-saas" className="dropdown-item">
                  {this.props.t("Saas")}
                </Link>
                <Link to="dashboard-crypto" className="dropdown-item">
                  {this.props.t("Crypto")}
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link dropdown-toggle arrow-none"
                to="invoices-detail"
              >
                <i className="bx bx-home-circle mr-2"></i>
                {this.props.t("Payments")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link dropdown-toggle arrow-none"
                to="pages-faqs"
              >
                <i
                  className="bx bx-home-circle mr-2"
                  style={{fontFamily: "Poppins"}}
                ></i>
                {this.props.t("Support")}
              </Link>
            </li>
          </ul>
        </>
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <div
          className="topnav"
          style={{
            display:
              window.location.pathname.toLowerCase() === "/profile" || window.location.pathname.toLowerCase() === "/home"  ? "none" : "initial",
          }}
        >
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                {this.dinamicNavbar()}
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withNamespaces()(Navbar));
