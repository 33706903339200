import React, { useState, useEffect } from "react";
import petition_get from "../../../../../pages/petitions/petition_get";
import { Row, Col, Spinner, Button, Badge } from "reactstrap";
import states from "./State/config";
import { Element } from "react-scroll";
export default function Filter({
  filterNumbers,
  setCityState,
  setNpaNxx,
  setNpa,
  npa,
  cityState,
  npaNxx,
  onSubmitNumbers,
  errorNumbers,
  loading,
  setLoading,
  resetActive,
  resetAll,
  setErrorNumbers
}) {
  const [cities, setCities] = useState(null);
  const [loadingUbication, setLoadingUbication] = useState(false);


  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  useEffect(() => {
    if (loading === "submit") {
      setLoadingUbication(true);
    } else {
      setLoadingUbication(false);
    }
  }, [loading]);

  useEffect(() => {
    setCityState({
      state: "",
      city: "",
    });

    setNpa({
      code: "",
    });

    setNpaNxx({
      npa: "",
      nxx: "",
    });
  }, [filterNumbers.type]);

  const onChangeCityState = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setErrorNumbers(null)
    if (name === "state") {
      setCities(null);
      setLoading("cities");
      const stateInfo = states.find(
        (state) => state.shortcode === e.target.value
      );

      await petition_get("cities", { id, stateCode: stateInfo.shortcode })
        .then(({ data: result }) => {
          if (result.result.RateCenterResponse.ResultCount === "1")
            setCities([{ ...result.result.RateCenterResponse.RateCenters.RateCenter }])

          else
            setCities(result.result.RateCenterResponse.RateCenters.RateCenter);

        })
        .catch((error) => {
          console.log(error.response);
        });
    }

    setCityState({ ...cityState, [name]: value });
    setLoading(false);
  };
  const onChangeNpa = (e) => {
    setErrorNumbers(null)
    if (e.target.value.length <= 3) {
      return setNpa({ ...npa, [e.target.name]: e.target.value });
    }
  };

  const onChangeNpaNxx = (e) => {
    setErrorNumbers(null)
    if (e.target.value.length <= 3) {
      return setNpaNxx({ ...npaNxx, [e.target.name]: e.target.value });
    }
  };

  if (filterNumbers.type === "") return null;

  return (
    <>
      <br /> <br />
      <div style={{ margin: "0 2em" }}>
        {filterNumbers.type === "NPA" && (
          <Row>
            <Col sm="4">
              <label className="form-title" htmlFor="">
                Select Area Code
              </label>
              <input
                disabled={resetActive ? true : false}
                name="code"
                onChange={onChangeNpa}
                value={npa.code}
                type="number"
                placeholder="Example: 305"
                className="form-control"
              />
            </Col>
          </Row>
        )}
        {filterNumbers.type === "NPANXX" && (
          <Row>
            <Col sm="4">
              <label className="form-title" htmlFor="npa">
                Select Area Code
              </label>
              <input
                disabled={resetActive ? true : false}
                id="npa"
                name="npa"
                onChange={onChangeNpaNxx}
                value={npaNxx.npa}
                type="number"
                placeholder="Example: 305"
                className="form-control"
              />
            </Col>
            <Col sm="4">
              <label className="form-title" htmlFor="nxx">
                Select NXX Code
              </label>
              <input
                disabled={resetActive ? true : false}
                id="nxx"
                name="nxx"
                onChange={onChangeNpaNxx}
                value={npaNxx.nxx}
                type="number"
                placeholder="Example: 299"
                className="form-control"
              />
            </Col>
          </Row>
        )}
        {filterNumbers.type === "City/State" && (
          <Row>
            <Col sm="4">
              <label className="form-title" htmlFor="">
                Select State
              </label>
              <select
                disabled={resetActive ? true : false}
                onChange={onChangeCityState}
                name="state"
                id=""
                className="form-control"
              >
                <option value="">Select one...</option>

                {states.sort(function (a, b) {
                  if (a.name > b.name) {
                    return 1;
                  }
                  if (a.name < b.name) {
                    return -1;
                  }
                  // a must be equal to b
                  return 0;
                }).map((state) => {
                  return (
                    <option key={state.id} value={state.shortcode}>
                      {state.name.toUpperCase()}
                    </option>
                  );
                })}
              </select>
            </Col>
            <Col sm="4">
              {loading === "cities" && (
                <Spinner
                  style={{ marginTop: "2rem" }}
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {cityState.state !== "" && cities && (
                <>
                  <label className="form-title" htmlFor="">
                    Select City
                  </label>

                  <select
                    disabled={resetActive ? true : false}
                    onChange={onChangeCityState}
                    name="city"
                    id=""
                    className="form-control"
                  >
                    <option value="">Select one...</option>
                    {cities.map((city, i) => {
                      return (
                        <option key={i} value={city.Abbreviation}>
                          {city.Name}
                        </option>
                      );
                    })}
                  </select>
                </>
              )}
            </Col>
          </Row>
        )}

        <Row>
          <Col style={{ margin: "2rem 0" }}>
            {" "}
            <Button
              color="primary"
              onClick={() => {
                onSubmitNumbers();
              }}
              disabled={loading === "submit" ? true : false}
            >
              {loadingUbication && (
                <Spinner
                  style={{ marginRight: "0.5em" }}
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Search Numbers
            </Button>
            <Badge
              color="danger"
              style={{
                marginLeft: "2em",
                display: errorNumbers ? "initial" : "none",
              }}
            >
              {errorNumbers}
            </Badge>
          </Col>
        </Row>
      </div>
    </>
  );
}
