import React, { useState, useEffect } from "react";

import 'react-phone-input-2/lib/style.css'
import petition_get from "../../../petitions/petition_get"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
    Media, Spinner, UncontrolledDropdown,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

import SidebarNewMessage from "./SidebarNewMessage";
import SidebarChatPagination from "./SidebarChatPagination"

export default function SidebarChat({
    numberFound,
    keyNumbers,
    setKeyNumbers,
    addChat,
    PerfectScrollbar,
    chats,
    messageFound,
    UserChatOpen,
    searchInput,
    modal,
    addThreeList,
    setModalDelete,
    loadingPetition,
    selectedNumber,
    setSelectedNumber,
    reloadList,
    setChatContact,
    setChats,
    setReloadData,
    reloadData,
    setContactModal,
    setContactAddModal,
    contacts,

}) {



    const [optionSelected, setOptionSelected] = useState("Recent")
    const [chatSelectedPk, setChatSelectedPk] = useState("")
    const params = new URL(document.location).searchParams;
    const id = params.get("id");


    const handleChangeSelectedNumber = (e) => {
        setChatContact(null)
        setSelectedNumber(e.target.value)
        let keyNumbersNew = keyNumbers.filter(element => element !== e.target.value);
        keyNumbersNew.splice(0, 0, e.target.value);
        setKeyNumbers(keyNumbersNew)
        reloadList(e.target.value)
        setChatSelectedPk("")
    }


    useEffect(() => {


        if (reloadData) {


            petition_get("contacts", { id })
                .then(({ data: result }) => {

                    let contactSave = result.result
                    let newChats = chats

                    newChats.map(element => {
                        let findContact = []
                        if (element.participants[0].is_self === false || element.participants.length === 1) {
                            contactSave.model_list.default_contacts.map(elementTwo => { if (parseInt(elementTwo.internal_number) === parseInt(element.participants[0].number) || parseInt(elementTwo.external_number) === parseInt(element.participants[0].number)) findContact.push(elementTwo) })
                            contactSave.model_list.user_contacts.map(elementTwo => { let findNumber = elementTwo.numbers.find(elementThree => parseInt(elementThree.number) === parseInt(element.participants[0].number)); if (findNumber) findContact.push(elementTwo) })

                        }
                        else {
                            contactSave.model_list.default_contacts.map(elementTwo => { if (parseInt(elementTwo.internal_number) === parseInt(element.participants[1].number) || parseInt(elementTwo.external_number) === parseInt(element.participants[1].number)) findContact.push(elementTwo) })
                            contactSave.model_list.user_contacts.map(elementTwo => { let findNumber = elementTwo.numbers.find(elementThree => parseInt(elementThree.number) === parseInt(element.participants[1].number)); if (findNumber) findContact.push(elementTwo) })

                        }

                        if (findContact.length >= 1) element.contact = { ...findContact[0] }
                    })

                    setChatContact(reloadData)
                    setReloadData(false)
                    setChats(newChats)

                })
                .catch((error) => console.log(error))
        }

    }, [reloadData])

    const handleContact = (chat, number) => {

        if (chat.contact) return setContactModal(chat.contact)

        if (number[0].is_self === false || number.length === 1) setContactAddModal(parseInt(number[0].number))
        else setContactAddModal(parseInt(number[1].number))

    }


    return (
        <>
            {loadingPetition
                ? <> {!numberFound ? (
                    <>
                        <div className="pb-4">
                            <select value={selectedNumber} onChange={handleChangeSelectedNumber} className="form-control" name="" id="">
                                {keyNumbers && keyNumbers.map((element, i) => (
                                    <option key={i} value={element}>
                                        {element}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div
                            className="d-flex "
                            style={{ justifyContent: "space-between" }}
                        >
                            <UncontrolledDropdown>
                                <DropdownToggle
                                    href="#"
                                    className="btn nav-btn"
                                    tag="i"
                                >
                                    <h5 className="font-size-15">
                                        {optionSelected} <KeyboardArrowDownIcon />
                                    </h5>
                                </DropdownToggle>
                                <DropdownMenu direction="right">
                                    <DropdownItem onClick={() => { setOptionSelected("Recent") }}>Recent</DropdownItem>
                                    <DropdownItem onClick={() => { setOptionSelected("Unread") }}>Unread</DropdownItem>
                                    <DropdownItem onClick={() => { setOptionSelected("Read") }}>Read</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>

                            <h5 className="font-size-20 mb-3">
                                <button
                                    onClick={() => { addChat(); }}
                                    className="btn addNewMessage"
                                >

                                    {modal
                                        ? "Close"
                                        : <> New message <AddCircleOutlineIcon style={{ fontSize: "1.8rem" }} /> </>}
                                </button>
                            </h5>
                        </div>
                        {modal ?
                            < SidebarNewMessage setChatSelectedPk={setChatSelectedPk} contacts={contacts} addThreeList={addThreeList} />
                            : <ul className="list-unstyled chat-list">
                                <PerfectScrollbar style={{ paddingBottom: "100px" }}>
                                    {chats && messageFound ? (
                                        <SidebarChatPagination chats={chats} setChatSelectedPk={setChatSelectedPk} UserChatOpen={UserChatOpen} optionSelected={optionSelected} searchInput={searchInput} chatSelectedPk={chatSelectedPk} handleContact={handleContact} setModalDelete={setModalDelete} />
                                    ) : (
                                        <div style={{ textAlign: "center" }}>
                                            {chats.length === 0 ? <p > You currently have no messages</p> : (
                                                <Spinner
                                                    animation="grow"
                                                    style={{
                                                        margin: "10vh auto",
                                                        height: "10vh",
                                                        width: "10vh",
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )}
                                </PerfectScrollbar>
                            </ul>}
                    </>
                )
                    : <div  >
                        <p style={{ fontSize: "18px", textAlign: "center" }} > You currently do not have an assigned number to send messages from </p>
                    </div>} </>
                : <div className="text-center"><Spinner
                    animation="grow"
                    style={{
                        margin: "10vh auto",
                        height: "10vh",
                        width: "10vh",
                    }}
                /> </div>

            }
        </>
    )
}
