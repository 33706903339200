import React, {Component} from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import {withRouter} from "react-router-dom";
import {Link, useHistory} from "react-router-dom";

//i18n
import {withNamespaces} from "react-i18next";
import * as qs from "query-string";


class SidebarContentLegacy extends Component {
  constructor(props) {
    super(props);
    this.urlData = qs.parse(window.location.search);
    this.state = {
      id: "",
    };
  }

  componentDidMount() {
    this.initMenu();
    const params = new URL(document.location).searchParams;
    const id = params.get("id");
    this.setState({
      id,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{this.props.t("ITP Voice (Legacy)")}</li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-mobile-vibration"></i>
                <span>{this.props.t("Numbers PBX")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to={`/AddNumbersPBX?id=${this.state.id}`}>
                    {this.props.t("New Number")}
                  </Link>
                </li>
                <li>
                  <Link to={`/ListAllNumbersPBX?id=${this.state.id}`}>
                    {this.props.t("List All Numbers")}
                  </Link>
                </li>
                   <li>
                  <Link to={`/transfer-your-number-pbx?id=${this.state.id}`}>
                    {this.props.t("Transfer Your Numbers")}
                  </Link>
                </li>
                   <li>
                  <Link to={`/transfers-in-progress-pbx?id=${this.state.id}`}>
                    {this.props.t("Transfers In Progress")}
                  </Link>
                </li>
              </ul>
            </li>

         
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withNamespaces()(SidebarContentLegacy));
