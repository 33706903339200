import React, { useEffect, useState } from "react";
import {
    Button,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Spinner
} from "reactstrap";
import toastr from "toastr";

import classnames from "classnames";
import phoneImg from "../../../../../../assets/images/verification-img.png";
import petition_patch from "../../../../../petitions/petition_patch"

import CustomTable from "../../../../../../components/utils/CustomTable/CustomTable"

export default function FavoritesModal({
    modal,
    setModal,
    userStatusProfile,
    reloadData,
    setReloadData,

    queues
}) {
    toastr.options = {
        showDuration: 300,
    };

    const params = new URL(document.location).searchParams;
    const id = params.get("id");



    const [optionSelected, setOptionSelected] = useState("")
    const [loading, setLoading] = useState(false)



    useEffect(() => { }, [])

    useEffect(() => {

        if (modal) {
            let value = ""
            switch (modal) {

                case "READY": value = "login"; break;
                case "OFFLINE": value = "logout"; break;
                case "PAUSE": value = "pause"; break;
                default: break;
            }
            setOptionSelected(value)
        }


    }, [modal])


    const handleClickStatusAgent = async (type) => {
        try {
            let data = { status: type }


            if (type !== "login" && modal !== "READY") return toastr.warning("You cannot switch between inactive states, please first activate the state with 'Ready'", "Warning");
            else if (type === "login" && modal === "PAUSE") data.status = "resume"

            setLoading(true)

            await petition_patch("updateAgentStatus", { id: id, data, user_id: userStatusProfile.id })
                .then(({ data: result }) => {


                })
                .catch((error) => { throw error });

            console.log(reloadData)
            console.log(setReloadData)
            setReloadData(!reloadData)
            setLoading(false)
            return toastr.success("Action completed", "Success");

        } catch (error) {
            console.log(error)

            setLoading(false)
            if (error.response && error.response.status === 400) {
                return toastr.error("You need one queue to active this status", "Error");
            }
            return toastr.error("Action failed", "Error");
        }
    }


    return (
        <Modal centered={true} isOpen={modal} className="info ">
            <ModalHeader className="bg-soft-primary">
                <Row>
                    <Col xs="9">
                        <div className="text-primary p-3">
                            <h3 className="text-primary">Queues Status</h3>
                        </div>
                    </Col>
                    <Col xs="3" className="align-self-end">
                        <img src={phoneImg} alt="" className="img-fluid" />
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody>

                <div className="w-100">

                    <CustomTable bordered={true} responsive={false} size="md" data={{
                        title: [{ title: "Name", key: "name" },],
                        content: queues,
                    }} />

                </div>
                <div className="text-right w-100">


                    <select className="mb-3" value={optionSelected} onChange={(e) => { setOptionSelected(e.target.value) }} className="custom-select2 btn btn-primary" >

                        <option value="login"> Log In All Queues </option>
                        <option value="logout"> Log Out All Queues </option>
                        <option value="pause"> Pause All Queues </option>
                        <option value="login"> UnPause All Queues </option>

                    </select>

                </div>




            </ModalBody>
            <ModalFooter>
                <Button
                    disabled={loading}

                    outline
                    color="secondary"
                    onClick={() => {
                        setModal(false);
                    }}
                >
                    Close
                </Button>
                <Button
                    disabled={loading}

                    color="primary"
                    onClick={() => {
                        handleClickStatusAgent(optionSelected)
                    }}
                >
                    {loading && (
                        <Spinner
                            style={{ marginRight: "0.5em" }}
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                    Save
                </Button>

            </ModalFooter>
        </Modal >
    );
}
