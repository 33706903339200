import React, {useState, useEffect} from "react";
import profileImg from "../../../../../assets/images/profile-img.png";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

import petition_get from "../../../../petitions/petition_get";
import "./ModalStyle.scss";

export default function ModalUtility({
  modal,
  setModal,
  loadingInstall,
  installApp,
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  useEffect(() => {
    if (modal) {
      setData([]);
      setLoading(false);
      petition_get("voiceAppDetails", {id: id, pk: modal})
        .then(({data: result}) => {
          console.error(result);
          setLoading(true);
          setData(result.result);
        })
        .catch((error) => {
          console.log("error");
        });
    }
  }, [modal]);

  return (
    <Modal centered={true} isOpen={modal ? true : false} className="info">
      <ModalHeader className="bg-soft-primary">
        {" "}
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary">App Details</h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={profileImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <>
            <p className="modalTitle">
              {" "}
              <span className="title">{data.name}</span>{" "}
              <p className="modalPrice">
                {`${data.monthly_price} $`}
                <span>per Month</span>
              </p>
            </p>
            <p className="modalDescription">{data.description}</p>
          </>
        ) : (
          <div style={{textAlign: "center"}}>
            <Spinner
              animation="grow"
              style={{
                margin: "10vh auto",
                height: "10vh",
                width: "10vh",
              }}
            />{" "}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={loadingInstall}
          outline
          color="secondary"
          onClick={() => {
            setModal(false);
          }}
        >
          Close
        </Button>
        <Button
          disabled={loadingInstall}
          color="primary"
          onClick={() => {
            installApp();
          }}
        >
          Install
        </Button>
      </ModalFooter>
    </Modal>
  );
}
