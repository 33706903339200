import React, { useState, useRef } from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";

import petition_post from "../../../../petitions/petition_post";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Spinner,
} from "reactstrap";
import toastr from "toastr"
export default function ModalUtility({ modal, setModal, setReloadMusic }) {
  toastr.options = {
    showDuration: 300,
  };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const inputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(1);
  const [error, setError] = useState({})
  const [form, setForm] = useState({
    media_name: "",
    language: "",
    media_type: "audio",
    tts_text: "",
  });



  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onSubmitForm = (e) => {

    setError({})

    if (form.media_name.trim() === "") {
      setError({ ...error, name: true })
      return toastr.error("Media Name is Required", "Error");
    }

    //Validation error 
    if (form.media_type.trim() === "" || (form.media_type === "audio" && (form.media_name.trim() === "" || !inputRef.current)) || (form.media_type === "tts" && (form.media_name.trim() === "" || form.tts_text.trim() === "" || form.language.trim() === "")))
      return toastr.error("Error in the form ", "Error");


    setLoading(true);
    const formData = new FormData();
    formData.append("streamable", true);
    formData.append("name", form.media_name);
    formData.append("type", form.media_type);
    formData.append("description", "media description");


    if (inputRef.current) {
      formData.append("file", inputRef.current.files[0]);
    } else {
      formData.append("tts_text", form.tts_text);
      formData.append("language", form.language);

    }
    petition_post("musicOnHoldMedia", { id: id, data: formData })
      .then(({ data: result }) => {
        setModal(false);
        setLoading(false);
        setForm({
          media_name: "",
          language: "",
          media_type: "audio",
          tts_text: "",
        });
        toastr.success("Action completed", "Success");
        setReloadMusic(false)
      })
      .catch((error) => {
        toastr.error("Action failed", "Error");
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <Modal centered={true} isOpen={modal ? true : false} className="info ">
      <ModalHeader className="bg-soft-primary">
        {" "}
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary">Add Media</h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col
            className={`d-flex col-12 pt-4 ${error.name && "input-error-form"}`}
            style={{ display: "flex", flexDirection: "column" }}
          >
            {" "}
            <Label
              htmlFor="media_name"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              Media Name
            </Label>{" "}
            <input
              className={`form-control ${error.name && "form-control-error"}`}
              id="media_name"
              name="media_name"
              type="text"
              onChange={onChange}
              value={form.media_name}
              placeholder="Media Name"
            />
          </Col>

          {/*   <Col
            className="d-flex col-12 pt-4"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {" "}
            <Label
              htmlFor="media_type"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              Media Type
            </Label>{" "}
            <select
              id="media_type"
              className="form-control"
              name="media_type"
              onChange={(e) => {
                onChange(e);
              }}
              value={form.media_type}
            >
              <option value="">Select One</option>
              <option value="tts">TTS</option>
              <option value="audio">Audio File</option>
            </select>
          </Col> */}
          {form.media_type === "tts" && <Col
            className="d-flex col-12 pt-4"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {" "}
            <Label
              htmlFor="language"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              Language
            </Label>{" "}
            <select
              id="language"
              name="language"
              className="form-control"
              onChange={(e) => {
                onChange(e);
              }}
              value={form.language}
            >
              <option value="">Select One</option>
              <option value="en-us">English</option>
            </select>
          </Col>}

          <Col
            className="d-flex col-12 pt-4"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {form.media_type === "tts" && (
              <>
                <textarea
                  onChange={onChange}
                  value={form.tts_text}
                  className="form-control"
                  placeholder="Type Text To Speech "
                  name="tts_text"
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </>
            )}
            {form.media_type === "audio" && (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label
                    style={{
                      color: "white",
                      marginRight: "1rem",
                      marginBottom: "0",
                    }}
                    className="bg-primary btn"
                    htmlFor="input_file"
                  >
                    Browse Files
                  </label>

                  <input
                    onChange={(e) => {
                      setTimeout(() => {
                        setReload(reload + 1);
                      }, [100]);
                    }}
                    id="input_file"
                    className="d-none"
                    type="file"
                    accept="audio/mp3,audio/wav"
                    ref={inputRef}
                  />
                  <span>Only MP3 or WAV Files Are Allowed</span>
                </div>
                <span className="mt-3">
                  {inputRef.current &&
                    inputRef.current.files.length !== 0 &&
                    inputRef.current.files[0].name}
                </span>
              </>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          outline
          color="secondary"
          onClick={() => {
            setModal(false);
            setForm({
              media_name: "",
              language: "",
              media_type: "audio",
              tts_text: "",
            });
          }}
        >
          Cancel
        </Button>

        <Button
          disabled={loading}
          color="primary"
          onClick={() => {
            onSubmitForm();
          }}
        >
          {loading && (
            <Spinner
              style={{ marginRight: "0.5em" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
