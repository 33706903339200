import React, { useState, useEffect } from "react";
import { Button, CustomInput } from "reactstrap";
import ReactTooltip from 'react-tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { SidebarItem } from "../../../components/Index";
import { get } from "lodash";
export default function ExternalNumber({
    setDetails,
    details,
    updateAction,
    state,
    startPoint,
    StartItemStyle,
    timeGroups,
    selectNode,
    dataCallFlow,
    directory
}) {

    const [form, setForm] = useState({
        directory: "",
    })

    const [activeForm, setActiveForm] = useState(false);
    const [activeTimeGroups, setActiveTimeGroups] = useState(false)
    const [selectTimeGroup, setSelectTimeGroup] = useState(null)
    const [defaultValue, setDefaultValue] = useState(true)
    const [timeGroupsValues, setTimeGroupsValues] = useState([])


    const [activeMenu, setActiveMenu] = useState(false)
    const [menuOptions, setMenuOptions] = useState(false)
    const [selectMenu, setSelectMenu] = useState(null);

    const onChangeAutocompleteMenu = (e) => {
        setSelectMenu(e.target.value);
    };
    const onChangeAutocompleteTimeGroup = (e) => { setSelectTimeGroup(e.target.value); };


    useEffect(() => {
        setDetails({ timeGroups: selectTimeGroup })
    }, [selectTimeGroup])
    useEffect(() => {
        setDetails({ menuOption: selectMenu, })
        console.log(selectMenu)
    }, [selectMenu])


    useEffect(() => {
        if (directory.length > 0) setForm({ directory: directory[0].id })
    }, [directory])


    useEffect(() => {



        if (selectNode && selectNode.type.split("_")[0] === "Time") {
            setDefaultValue(true)

            let values;
            if (selectNode.childrenUrl === "") values = dataCallFlow.flow.children;
            else {

                values = get(dataCallFlow.flow, selectNode.childrenUrl).children
            }

            const timeGroupsKeys = Object.keys(values)
            const newTimeGroups = []
            timeGroups.map(element => {
                const findOne = timeGroupsKeys.find(elementTwo => elementTwo === element.id)
                if (!findOne) {
                    newTimeGroups.push(element)
                }
            })

            if (values._) {
                setDefaultValue(false)
                setSelectTimeGroup(newTimeGroups.length > 0 ? newTimeGroups[0].id : "")
            } else {
                setSelectTimeGroup("_")
            }
            setTimeGroupsValues(newTimeGroups)
            setActiveTimeGroups(false)
        }
        else if (selectNode && selectNode.type.split("_")[0] === "Menu") {
            let MenuOptions = [
                { id: "1" },
                { id: "2" },
                { id: "3" },
                { id: "4" },
                { id: "5" },
                { id: "6" },
                { id: "7" },
                { id: "8" },
                { id: "9" },
                { id: "0" },
                { id: "timeout" },
            ]
            let values;
            if (selectNode.childrenUrl === "") values = dataCallFlow.flow.children;
            else values = get(dataCallFlow.flow, selectNode.childrenUrl).children

            const MenuKeys = Object.keys(values)
            const newMenu = []
            MenuOptions.map(element => {
                const findOne = MenuKeys.find(elementTwo => elementTwo === element.id)
                if (!findOne) {
                    newMenu.push(element)
                }
            })
            setSelectMenu(newMenu[0].id)
            setMenuOptions(newMenu)
            setActiveMenu(true);
            setActiveTimeGroups(true)
        }
        else {
            setActiveTimeGroups(true)
        }

    }, [])



    const handleSubmit = () => {
        let findDirectory = directory.find(element => element.id === form.directory)
        setActiveForm(findDirectory.name)
        setDetails({ menuOption: selectMenu, ...form })
    }

    return (
        <>
            {activeTimeGroups ?
                <>
                    {activeMenu ? <>    <select
                        onChange={onChangeAutocompleteMenu}
                        className="form-control"
                        list="datalistOptions"
                        id="exampleDataList"
                        placeholder="Type to search..."
                    >


                        {menuOptions.map((element, i) => (
                            <option key={i} value={element.id}>
                                {element.id}
                            </option>
                        ))}
                    </select>
                        {selectMenu && (
                            <Button
                                onClick={async () => {
                                    setActiveMenu(false);

                                }}
                                className=" mt-3 w-100"
                                color="primary"
                            >
                                Create
                            </Button>
                        )} </>

                        : <>
                            <div >
                                <label style={{ textAlign: "left", width: "100%" }} htmlFor="directory">Directories:</label>

                                <select
                                    onChange={(e) => { setForm({ directory: e.target.value }) }}
                                    name="directory"
                                    value={form.directory}
                                    id="directory"

                                    className="form-control" >

                                    {directory.map(element => <option value={element.id} > {element.name} </option>)}
                                </select>


                                <Button
                                    onClick={handleSubmit}
                                    className=" mt-3 w-100"
                                    color="primary"
                                >
                                    Create
                                </Button>
                            </div>


                            {activeForm && <SidebarItem
                                updateAction={updateAction}
                                state={state}
                                label={`directory`}
                                type={`directory_${activeForm}`}
                                ports={startPoint(`directory_${activeForm}`)}
                                ItemStyle={StartItemStyle}
                            />}


                        </>}
                </>
                : <>
                    <select
                        onChange={onChangeAutocompleteTimeGroup}
                        className="form-control"
                        list="datalistOptions"
                        id="exampleDataList"
                        placeholder="Type to search..."
                    >
                        {defaultValue && <option value="_">All Other Times</option>}
                        {selectTimeGroup === "" && <option value="">You have no more options </option>}
                        {timeGroupsValues.map((element, i) => (
                            <option key={i} value={element.id}>
                                {element.name}
                            </option>
                        ))}

                    </select>

                    {selectTimeGroup && (
                        <Button
                            disabled={selectTimeGroup === ""}
                            onClick={async () => {
                                setActiveTimeGroups(true)
                            }}
                            className=" mt-3 w-100"
                            color="primary"
                        >
                            Create
                        </Button>
                    )}
                </>}

        </>

    );
}
