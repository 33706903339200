import React, { useState } from 'react'
import SidebarChatItem from "./SidebarChatItem"

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import styled from "styled-components";
const ContainerPagination = styled.div`
  padding: 20px;
  margin: 0;
  .btn-page {
    border: none;
    background: rgba(255, 255, 255, 0.1);
    color: #a6b0cf;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    margin: 0 5px;
    svg {
      color: #a6b0cf;
    }
    &:focus {
      outline: none;
    }
  }
  .active {
    background: #536be1;
  }
`;

const Pages = ({ keyNumbers, setPagination, pagination, cantPagination }) => {
    let page = [];

    for (let i = 0; i < Math.trunc(keyNumbers); i++) {
        page.push(i);
    }

    const onClickPage = (index) => {
        index = (index + 1) * cantPagination;
        setPagination(index);
    };

    return (
        <>
            {page.map((pag, index) => {
                if (
                    (index + 1 >= pagination / cantPagination - 2 &&
                        index + 1 <= pagination / cantPagination) ||
                    (index + 1 <= pagination / cantPagination + 2 &&
                        index + 1 >= pagination / cantPagination)
                ) {
                    return (
                        <button
                            className={`btn-page ${pagination / cantPagination === index + 1 && "active"
                                } `}
                            onClick={() => {
                                onClickPage(index);
                            }}
                            key={index}
                        >
                            {" "}
                            {index + 1}{" "}
                        </button>
                    );
                }
            })}
        </>
    );
};

export default function SidebarChatPagination({ chats, optionSelected, chatSelectedPk, handleContact, setModalDelete, searchInput, UserChatOpen, setChatSelectedPk }) {
    const cantPagination = 5;
    const [pagination, setPagination] = useState(cantPagination)


    const onClickNext = () => {
        if (pagination < chats.length) {
            setPagination(pagination + cantPagination);
        }
    };

    const onClickBack = () => {
        if (pagination > cantPagination) {
            setPagination(pagination - cantPagination);
        }
    };


    return (
        <div>
            {chats.map((chat, i) => (i < pagination && i >= pagination - cantPagination) && <SidebarChatItem setChatSelectedPk={setChatSelectedPk} UserChatOpen={UserChatOpen} optionSelected={optionSelected} key={i} searchInput={searchInput} chat={chat} chatSelectedPk={chatSelectedPk} handleContact={handleContact} setModalDelete={setModalDelete} />)}

            <ContainerPagination>
                {chats && chats.length > cantPagination && (
                    <div className="col-12">
                        <button className="btn-page pl-2" onClick={onClickBack}>

                            <ArrowBackIosIcon />
                        </button>
                        <Pages
                            pagination={pagination}
                            setPagination={setPagination}
                            cantPagination={cantPagination}
                            keyNumbers={
                                (chats.length / cantPagination) % 1 === 0
                                    ? chats.length / cantPagination
                                    : chats.length / cantPagination + 1
                            }
                        />
                        <button className="btn-page" onClick={onClickNext}>

                            <ArrowForwardIosIcon />
                        </button>
                    </div>
                )}
            </ContainerPagination>

        </div>
    )
}
