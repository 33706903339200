import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Badge } from "reactstrap";
import { MuuriComponent, useRefresh } from "muuri-react";
import PerfectScrollbar from "react-perfect-scrollbar";
import petition_patch from "../../../petitions/petition_patch";

const MenuDrop = ({ toggle, setToggle, i, type, color }) => {
  return (
    <>
      <Badge
        color={`${color}`}
        style={{
          fontSize: "0.8rem",
          fontWeight: "100",
          display: "flex",
          alignItems: "center",
          height: "24px",
        }}
      >
        {color === "danger" ? (
          "OFFLINE"
        ) : (
          <>
            {" "}
            {type === "READY" && "READY"}
            {type === "true" && "On Call"}
            {type === "false" && "Ringing"}{" "}
            {type === "conference" && "Conference"}
          </>
        )}
      </Badge>{" "}
      {/*   <Dropdown
        direction="left"
        isOpen={toggle === i}
        toggle={() => setToggle(i)}
      >
        <DropdownToggle
          style={{
            background: "transparent",
            padding: "0",
            border: "none",
            position: "absolute",
          }}
        >
          {" "}
        </DropdownToggle>
        <div
          onClick={() => {
            toggle === i ? setToggle(null) : setToggle(i);
          }}
        >
          {" "}
          <MoreHorizIcon style={{marginLeft: "0.2rem"}} />{" "}
        </div>

        <DropdownMenu>
          <DropdownItem>Action</DropdownItem>
          <DropdownItem>Another action</DropdownItem>
          <DropdownItem>Something else here</DropdownItem>
        </DropdownMenu>
      </Dropdown> */}
    </>
  );
};

const Queues = ({ element, cardSizes }) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        padding: "1rem",
      }}
    >
      {" "}
      <Card style={{ width: (320 * cardSizes) / 5 + "px" }}>
        <CardBody
          style={{
            height: (110 * cardSizes) / 5 + "px",
            alignItems: "flex-start",
            paddingBottom: "0",
          }}
          className="row "
        >
          <Col className="col-10" style={{ fontSize: "0.8rem" }}>
            {`${element.name} `}
          </Col>
          <Col className="col-10" style={{ fontSize: "0.8rem" }}>
            <p>Currently Waiting</p>
          </Col>
          <Col className="col-10" style={{ fontSize: "0.8rem" }}>
            <p>Members</p>
          </Col>
          <Col
            className="col-2 text-right"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              height: "20px",
            }}
          ></Col>
        </CardBody>
      </Card>
    </div>
  );
};

const ItemQueues = ({ element, cardSizes }) => {
  const refresh = useRefresh();

  useEffect(() => {
    refresh();
  }, [cardSizes]);
  return (
    <div
      style={{
        maxWidth: (cardSizes * 320) / 5 + "px",
        maxHeight: (cardSizes * 110) / 5 + "px",
        position: "relative",
        marginBottom: "1rem",
      }}
    >
      {" "}
      <Queues cardSizes={cardSizes} element={element} />
    </div>
  );
};

export default function CardUsers({ queues, cardSizes,id }) {
  const options = {
    dragEnabled: true,
  };

  const changePosition = (item) => {
    let data = [];
    item.forEach((element, i) => {
      data.push({ position: i, id: element._component.data.element.id });
    });
    petition_patch("editCards", {
      id: id,
      data: { panel_settings: { queues: data } },
    })
      .then(({ data: result }) => {
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const children2 = queues.map((element, i) => (
    <ItemQueues cardSizes={cardSizes} element={element} />
  ));

  return (
    <PerfectScrollbar style={{ maxHeight: "50vh" }}>
      <MuuriComponent
        propsToData={(data) => data}
        {...options}
        onDragEnd={(item) => changePosition(item.getGrid()._items)}
      >
        {children2}
      </MuuriComponent>
    </PerfectScrollbar>
  );
}
