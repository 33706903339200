import React, { Component } from "react";

import { connect } from "react-redux";
import { Row, Col } from "reactstrap";

import { Link } from "react-router-dom";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

//import megamenuImg from "../../assets/images/megamenu-img.png";
import logo from "../../assets/images/itpFiber/itp_white.png";
import logoLight from "../../assets/images/itpFiber/itp_white.png";
import logoLightSvg from "../../assets/images/itpFiber/itp_white.png";
import logoDark from "../../assets/images/itpFiber/itp_white.png";

import configJson from "../../config.json"

// import images
/* import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png"; */
import petition_get from "../../pages/petitions/petition_get";
import ModalUtil from "../../pages/Fiber/SelectFiberModalTwo";
//i18n
import { withNamespaces } from "react-i18next";
import ModalUtilityLegacy from "../../pages/Home/ModalUtility/ModalUtilityLegacy";
// Redux Store
import {
  changeLayout,
  changeSidebarTheme,
  changeTopbarTheme,
  toggleRightSidebar,
  showRightSidebarAction,
  changeLayoutWidth,
} from "../../store/actions";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      typeServices: [],
      onlyFiber: [],
      onlyVoice: <h1>test</h1>,
      ModalVoice: false,
      ModalLegacy: false,
      SelectFiberModal: false,
      ModalUtil: false,
      ModalUtilLegacy: false,
      servicesITPVoice: [],
      servicesITPVoiceLegacy: [],
      selectedService: "",
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.services !== this.props.services) {

      if (this.props.services) {

        let services = [];
        let onlyFiber = [];
        let servicesITPVoice = [];
        let servicesITPVoiceLegacy = [];

        this.props.services.forEach((element, i) => {
          if (
            services.find(
              (serv) => serv.category === element.product.category
            ) === undefined
          ) {
            if (
              element.product.category === "Cloud" ||
              element.product.category === "Phone" ||
              element.product.category === "Fiber SMB" ||
              element.product.category === "Fiber DIA" ||
              element.product.category === "Voice" ||
              element.product.category === "Voice Legacy"
            ) {
              services.push({
                category: element.product.category,
                pk: element.pk,
                api_id: element.api_id,
              });
              /*   services.push(element.product.category); */
            }
          }
          if (
            element.product.category === "Fiber SMB" ||
            element.product.category === "Fiber DIA"
          ) {
            onlyFiber.push(element);
          }
        });
        this.props.services.forEach((element, i) => {
          if (element.product.category === "Voice") {
            servicesITPVoice.push(element);
          }
          if (
            element.product.category === "Fiber SMB" ||
            element.product.category === "Fiber DIA"
          ) {
            onlyFiber.push(element);
          }
          if (element.product.category === "Voice Legacy") {
            servicesITPVoiceLegacy.push(element);
          }
        });
        this.setState({
          typeServices: services,
          onlyFiber: onlyFiber,
          servicesITPVoice: servicesITPVoice,
          servicesITPVoiceLegacy: servicesITPVoiceLegacy,
        })


      }
    }
  }


  toggleMenu() {
    this.props.toggleMenuCallback()
  }
  ModalUtilClose = () => {
    this.setState({ ModalUtil: false, selectedService: "" });
  };
  ModalUtilCloseLegacy = () => {
    this.setState({ ModalUtilLegacy: false, selectedService: "" });
  };
  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  selectedVoice() {
    this.setState({
      ModalUtil: true,
      ModalVoice: true,
      selectedService: "itp_voice",
    });
  }

  selectedLegacy() {
    this.setState({
      ModalUtilLegacy: true,
      ModalLegacy: true,
      selectedService: "itp_voice_legacy",
    });
  }
  BillingOption() {
    return (
      <button
        onClick={() => {
          window.location.href = "/Billing";
        }}
        className=" btn header-item waves-effect"
      >
        Billing
      </button>
    );
  }
  menuOptions() {
    return (
      <Dropdown
        className=" d-lg-inline-block ml-1"
        isOpen={this.state.socialDrp}
        toggle={() => {
          this.setState({ socialDrp: !this.state.socialDrp });
        }}
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          caret
          tag="button"
        >
          {this.props.t("My Services")}{" "}
          {/* <i className="mdi mdi-chevron-down"></i> */}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg" right>
          <div className="px-lg-2">
            <Row className="no-gutters">
              { }
              <Col
                style={{
                  display:
                    this.state.typeServices.find(
                      (serv) => serv.category === "Fiber SMB"
                    ) !== undefined ||
                      this.state.typeServices.find(
                        (serv) => serv.category === "Fiber Dia"
                      ) !== undefined
                      ? null
                      : "none",
                }}
              >
                <div
                  style={{ cursor: "pointer" }}
                  className="dropdown-icon-item"
                  onClick={() => {
                    this.setState({ ModalUtil: true });
                  }}
                >
                  <i
                    className="fas fa-network-wired"
                    style={{ fontSize: "1.5em" }}
                  ></i>
                  <span>Fiber Internet</span>
                </div>
              </Col>
              <Col
                style={{
                  display:
                    this.state.typeServices.find(
                      (serv) => serv.category === "Phone"
                    ) !== undefined
                      ? null
                      : "none",
                }}
              >
                <Link className="dropdown-icon-item" to="/Phone">
                  <i
                    className="fas fa-phone"
                    onClick={() => { }}
                    style={{ fontSize: "1.5em" }}
                  ></i>
                  <span>Phone</span>
                </Link>
              </Col>
              {/* <Row className="no-gutters"> */}
              <Col
                style={{
                  display:
                    this.state.typeServices.find(
                      (serv) => serv.category === "Cloud"
                    ) !== undefined
                      ? null
                      : "none",
                }}
              >
                <Link className="dropdown-icon-item" to="/Cloud">
                  <i
                    className="fab fa-mixcloud"
                    onClick={() => { }}
                    style={{ fontSize: "1.5em" }}
                  ></i>
                  <span>Cloud</span>
                </Link>
              </Col>
              {/*  <Col>
                <Link className="dropdown-icon-item" to="/Billing">
                  <img src={dropbox} alt="dropbox" /> 
                  <i
                    className="fas fa-book"
                    onClick={() => {}}
                    style={{ fontSize: "1.5em" }}
                  ></i>
                  <span>My ITP</span>
                </Link>
              </Col> */}
              {this.state.typeServices.map((serv) => {
                if (serv.category === "Voice") {
                  return (
                    <Col
                      key={serv.pk}
                      style={{
                        display:
                          this.state.typeServices.find(
                            (serv) => serv.category === "Voice"
                          ) !== undefined
                            ? null
                            : "none",
                      }}
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        className="dropdown-icon-item"
                        // to={`/ITPVoice?id=${serv.api_id}`}
                        onClick={() => {
                          if (this.state.servicesITPVoice.length === 1) {

                            if (this.state.servicesITPVoice[0].metadata?.version && this.state.servicesITPVoice[0].metadata?.version === "2.0") {

                              const token = JSON.parse(localStorage.getItem("authUser"));
                              return window.location.href = `https://app.${configJson.prod ? "" : "dev."}itpvoice.net/login?token=${token.access_token.replace(/['"]+/g, "")}&&v2=` + this.state.servicesITPVoice[0].api_id;
                            }
                            window.location.href =
                              "/DashboardUsers?id=" +
                              this.state.servicesITPVoice[0].api_id;
                          } else {
                            this.selectedVoice();
                          }
                        }}
                      >
                        {/* <img src={dropbox} alt="dropbox" /> */}
                        <i
                          className="fas fa-book"
                          onClick={() => { }}
                          style={{ fontSize: "1.5em" }}
                        ></i>
                        <span>ITP Voice </span>
                        <span>{`(${serv.pk})`}</span>
                      </div>
                    </Col>
                  );
                }
              })}
              {this.state.typeServices.map((serv) => {
                if (serv.category === "Voice Legacy") {
                  return (
                    <Col
                      key={serv.pk}
                      style={{
                        display:
                          this.state.typeServices.find(
                            (serv) => serv.category === "Voice Legacy"
                          ) !== undefined
                            ? null
                            : "none",
                      }}
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        className="dropdown-icon-item"
                        // to={`/ITPVoice?id=${serv.api_id}`}
                        onClick={() => {
                          if (this.state.servicesITPVoiceLegacy.length === 1) {
                            window.location.href =
                              "/DashboardITPVoiceLegacy?id=" +
                              this.state.servicesITPVoiceLegacy[0].pk;
                          } else {
                            this.selectedLegacy();
                          }
                        }}
                      >
                        {/* <img src={dropbox} alt="dropbox" /> */}
                        <i
                          className="fas fa-book"
                          onClick={() => { }}
                          style={{ fontSize: "1.5em" }}
                        ></i>
                        <span>ITP Voice Legacy</span>
                        <span>{`(${serv.pk})`}</span>
                      </div>
                    </Col>
                  );
                }
              })}
            </Row>
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }

  render() {
    return (
      <React.Fragment>
        <ModalUtil
          modal={this.state.ModalUtil}
          spinner={false}
          toggle={this.ModalUtilClose}
          cancelButton={"Close"}
          selectedService={this.state.selectedService}
          modalbody={
            this.state.ModalVoice === true
              ? this.state.servicesITPVoice
              : this.state.onlyFiber
          }
        />

        <ModalUtilityLegacy
          modal={this.state.ModalUtilLegacy}
          spinner={false}
          close={this.ModalUtilCloseLegacy}
          selectedService={this.state.selectedService}
          modalbody={this.state.servicesITPVoiceLegacy}
        />
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box d-none d-lg-block">
                <Link to="/Home" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="40" />
                  </span>
                </Link>

                <Link to="/Home" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="40" />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                onClick={this.toggleMenu}
                className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button>

              {/* <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input type="text" className="form-control" placeholder={this.props.t('Search') + "..."} />
                  <span className="bx bx-search-alt"></span>
                </div>
              </form> */}
            </div>
            <div className="d-flex">
              <div className="dropdown d-inline-block d-lg-none ml-2">
                {/*  <button
                  onClick={() => {
                    this.setState({isSearch: !this.state.isSearch});
                  }}
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  id="page-header-search-dropdown"
                >
                  <i className="mdi mdi-magnify"></i>
                </button> */}
                <div
                  className={
                    this.state.isSearch
                      ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                      : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                  }
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {this.BillingOption()}
              {this.menuOptions()}
              {/* 
              <LanguageDropdown />

              <NotificationDropdown /> */}
              <ProfileMenu props={this.props} />
              {/*    <div className="dropdown d-inline-block">
                <button
                  onClick={() => {
                    this.props.showRightSidebarAction(
                      !this.props.showRightSidebar
                    );
                  }}
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle waves-effect"
                >
                  <i className="bx bx-cog bx-spin"></i>
                </button>
              </div>  */}
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleRightSidebar,
  changeTopbarTheme,
  changeSidebarTheme,
})(withNamespaces()(Header));
