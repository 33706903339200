import React, {useState, useEffect} from "react";
import {Col, Row, Table} from "reactstrap";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import DeleteIcon from "@material-ui/icons/Delete";
import ButtonHover from "../../../../../../components/utils/ButtonHover";
export default function Members({setAdd, list, listUsers, setList}) {
  const [listMembers, setListMembers] = useState(list);
  const onDragEnd = (e) => {
    const srcI = e.source.index;
    const destI = e.destination.index;
    if (e.destination) {
      let ListUser = listMembers;
      ListUser.splice(destI, 0, ListUser.splice(srcI, 1)[0]);
      setListMembers(ListUser);
    }
  };

  const onChangeMembers = ({id, value}) => {
    let allMembers = listMembers;
    allMembers.map((element) => {
      if (element.id === id) {
        element.timeout = parseInt(value);
      }
    });
    setListMembers([...allMembers]);
  };

  const onClickDelete = (id) => {
    const allMembers = listMembers.filter((element) => element.id !== id);
    setListMembers(allMembers);
    setList(allMembers);
  };

  useEffect(() => {
    if (list.length !== 0 && listUsers.length !== 0) {
      const newList = list.map((element) => {
        const user = listUsers.find(
          (elementTwo) => elementTwo.id === element.id
        );

        element.name = `${user.first_name} ${user.last_name}`;
        element.presence_id = user.presence_id;
        return element;
      });
    }
    setListMembers(list);
  }, [list, listUsers]);

  return (
    <Row>
      <Col className="col-12 mb-3 text-right">
        {" "}
        <button
          onClick={() => {
            setAdd(true);
          }}
          className="btn btn-primary"
          style={{fontWeight: "500", fontSize: "16px"}}
        >
          Add Member
        </button>{" "}
      </Col>{" "}
      <Col className="d-flex col-12">
        {listMembers.length !== 0 && (
          <Table responsive>
            <thead className="thead-light">
              <div
                className="row bg-light"
                style={{margin: "0", padding: "1rem 0"}}
              >
                <div className="col-1" style={{textAlign: "center"}}></div>
                <div className="col-3" style={{textAlign: "center"}}>
                  Users
                </div>
                {/* <div style={{maxWiddiv:"5em"}}>PAYMENT STATUS</div> */}
                <div className="col-1" style={{textAlign: "center"}}></div>
                <div className="col-5" style={{textAlign: "center"}}>
                  Ring for How Many Seconds ?
                </div>
              </div>
            </thead>
          </Table>
        )}
      </Col>
      <Col className="col-12">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {listMembers.map((element, i) => {
                  return (
                    <div key={i}>
                      <Draggable key={i} index={i} draggableId={`key-${i}`}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div className="row pt-3">
                              <div
                                className="col-1"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <i
                                  {...provided.dragHandleProps}
                                  className="bx bx-move-vertical"
                                  style={{fontSize: "1.5em"}}
                                />
                              </div>
                              <div
                                className="col-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {element.name} ({element.presence_id})
                              </div>

                              <div
                                className="offset-2 col-3"
                                style={{
                                  display: "flex",
                                  alignContent: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <input
                                  type="number"
                                  className="form-control"
                                  value={element.timeout}
                                  onChange={(e) => {
                                    onChangeMembers({
                                      id: element.id,
                                      value: e.target.value,
                                    });
                                  }}
                                  style={{width: "6em"}}
                                />
                              </div>
                              <div className="offset-1 col-1">
                                <ButtonHover
                                  style={{
                                    margin: "0",
                                    marginTop: "0.5rem",
                                    display: "flex",
                                    alignContent: "center",
                                    justifyContent: "center",
                                  }}
                                  onClick={() => {
                                    onClickDelete(element.id);
                                  }}
                                >
                                  {" "}
                                  <DeleteIcon />
                                </ButtonHover>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    </div>
                  );
                })}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Col>
    </Row>
  );
}
