import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Container, Card, Table, Spinner } from "reactstrap";

import CardTools from "./CardTools/CardTools";

//Icons
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import ButtonHover from "../../../../components/utils/ButtonHover";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import { addDays } from "date-fns";

import styled from "styled-components";
// import {DateRangePicker} from "react-date-range";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import petition_get from "../../../petitions/petition_get";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import GetAppIcon from '@material-ui/icons/GetApp';

//Modal
import ModalUtility from "./ModalUtility/ModalUtility";
import ModalUtilityDownload from "./ModalUtility/ModalUtilityDownload";


import Alert404 from "../../../../components/404alert/Alert404";
const ContainerPagination = styled.div`
  padding: 20px;
  margin: 0;

  .btn-page {
    border: none;
    background: rgba(255, 255, 255, 0.1);
    color: #a6b0cf;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    margin: 0 5px;
    padding: 0;
    svg {
      color: #a6b0cf;
    }
    &:focus {
      outline: none;
    }
  }
  .active {
    background: #536be1;
  }

  @media (max-width: 768px) {
    padding: 0;
    .container-pages {
      display: flex;
      justify-content: center;
    }
  }
`;
const Pages = ({ data, setPagination, pagination, cantPagination }) => {
  let page = [];

  for (let i = 0; i < Math.trunc(data); i++) {
    page.push(i);
  }

  const onClickPage = (index) => {
    index = (index + 1) * cantPagination;
    setPagination(index);
  };

  return (
    <>
      {page.map((pag, index) => {
        if (
          (index + 1 >= pagination / cantPagination - 2 &&
            index + 1 <= pagination / cantPagination) ||
          (index + 1 <= pagination / cantPagination + 2 &&
            index + 1 >= pagination / cantPagination)
        ) {
          return (
            <button
              className={`btn-page ${pagination / cantPagination === index + 1 && "active"
                } `}
              onClick={() => {
                onClickPage(index);
              }}
              key={index}
            >
              {" "}
              {index + 1}{" "}
            </button>
          );
        }
      })}
    </>
  );
};

export default function CallHistory() {
  const history = useHistory();
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const token = JSON.parse(localStorage.getItem("authUser"));
  const json = require("../../../../config.json")
  const urlBase = json.prod ? "https://api.itpscorp.com/portal/itpvoice/" : "https://api.itpscorp.com/dev/portal/itpvoice/"

  const cantPagination = 10;
  const [modal, setModal] = useState(false);
  const [modalDownload, setModalDownload] = useState(false)
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [dataPickerFilter, setDataPickerFilter] = useState({
    startDate: "",
    endDate: "",
  });
  const [timeZone, setTimeZone] = useState("");
  const [element, setElement] = useState(null);
  const [elementDownload, setElementDownload] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error404, setError404] = useState(false);
  const [pagination, setPagination] = useState(cantPagination);
  const [filter, setFilter] = useState({
    search: "",
    select: "all",
  });

  const getInitialData = async () => {
    const dateEnd = new Date();
    const dateStart = new Date(null);
    setDataPickerFilter({
      startDate: dateStart.getTime(),
      endDate: dateEnd.getTime(),
    });

    await petition_get("callForward", { id: id })
      .then(async ({ data: result }) => {
        console.log(result.result.timezone)
        if (result.result.timezone) {
          setTimeZone(result.result.timezone);

        } else {
          await petition_get("accountDetails", { id: id })
            .then(({ data: result }) => {
              setTimeZone(result.result.data.timezone);
              console.log(result.result.data.timezone)
              // setDataFilter(result.result);
            })
            .catch((error) => {
              setLoading(false);
              console.log(error.response);
              if (error.response.status === 404) {
                setError404(true)
              }
            });
        }
        // setDataFilter(result.result);
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 404) {
          setError404(true)
        }
      });




    setLoading(true);
    petition_get("callReport", { id: id })
      .then(({ data: result }) => {
        console.log(result.result);
        // setLoading(false);
        setData(result.result);
        // setDataFilter(result.result);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.response);
        if (error.response.status === 404) {
          setError404(true);
        }
      });
  }

  useEffect(() => {
    getInitialData();
  }, []);


  const formatData = (val) => {
    let time = new Date(val * 1000);
    let timeZoneTemp = time.toLocaleString('en-US', { timeZone: timeZone })
    return timeZoneTemp
  }


  useEffect(() => {
    if (data.length > 0) {
      // let newData = [];
      data.forEach(element => {
        //  console.log(element.interaction_id);
        const result = data.filter(el => el.interaction_id === element.interaction_id);
        const compResult = newData.filter(el => el.interaction_id === element.interaction_id)
        if (compResult.length === 0) {
          newData.push({
            result,
            interaction_id: element.interaction_id,
            unix_timestamp: element.unix_timestamp,
            duration_seconds: new Date(element.duration_seconds * 1000).toISOString().substr(element.duration_seconds < 3600 ? 14 : 11, element.duration_seconds < 3600 ? 5 : 8),
            datetime: element.datetime,
            callee_id_number: element.callee_id_number,
            caller_id_number: element.caller_id_number
          })
        }

      });
      console.log(newData);
      setDataFilter(newData);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    const allDateFilter = newData.filter((element) => {
      const date = new Date(element.datetime);
      if (
        date.getTime() / 1000 < dataPickerFilter.endDate &&
        date.getTime() / 1000 > dataPickerFilter.startDate
      ) {
        return element;
      }
    });
    setDataFilter(allDateFilter);
    setFilter({ search: "", select: "all" });
  }, [dataPickerFilter]);

  const onClickNext = () => {
    if (pagination < data.length) {
      setPagination(pagination + cantPagination);
    }
  };

  const onClickBack = () => {
    if (pagination > cantPagination) {
      setPagination(pagination - cantPagination);
    }
  };

  const reloadFilters = () => {
    setFilter({ search: "", select: "all" });
    setDataFilter(data);
  };

  const downloadCall = async (value) => {
    setLoadingDownload(true)

    const filterResult = modalDownload.find(element => element.id === value)


    const downloadFile = async () =>
      fetch(`${urlBase}${id}/recordings/${filterResult.media_recordings[0]}?token=${token.access_token}&&accept=audio/mpeg`).then((res) => res.blob());
    const createURL = (blob) => URL.createObjectURL(blob);

    const blob = await downloadFile();
    const create = createURL(blob);

    let link = document.createElement("a");
    link.download = `${elementDownload.interaction_id}.mp3`
    link.href = create;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();

    setModalDownload(false);
    setLoadingDownload(false)

  }

  return (
    <React.Fragment>
      {element && <ModalUtility timeZone={timeZone} setModal={setModal} modal={modal} element={element} setElement={setElement} />}
      <ModalUtilityDownload spinner={loadingDownload} setModal={setModalDownload} modal={modalDownload} downloadCall={downloadCall} />
      {/* <ModalUtility setModal={setModal} modal={modal} element={element} /> */}
      <div className="page-content">
        {error404 ? (
          <Alert404 name="Call History" />
        ) : (
          <Container fluid>
            <div
              className="d-flex"
              style={{ alignItems: "center", paddingBottom: "2.5rem" }}
            >
              <h3 style={{ margin: "0" }}>Reports</h3>
              <span>
                <DoubleArrowIcon className="icon-arrow" />
              </span>
              <h4
                style={{
                  color: "#a6b0cf",
                  fontWeight: "400",
                  margin: "0",
                  fontSize: "15px",
                  paddingTop: "7px",
                }}
              >
                Call Logs
              </h4>
            </div>

            {!loading && (
              <CardTools
                reloadFilters={reloadFilters}
                setPagination={setPagination}
                dataPickerFilter={dataPickerFilter}
                setDataPickerFilter={setDataPickerFilter}
                filter={filter}
                setFilter={setFilter}
                setDataFilter={setDataFilter}
                data={newData}
              />
            )}

            {loading ? (
              <div style={{ textAlign: "center" }}>
                <Spinner
                  animation="grow"
                  style={{
                    margin: "10vh auto",
                    height: "10vh",
                    width: "10vh",
                  }}
                />{" "}
              </div>
            ) : (
              <Card body>
                {data.length !== 0 ? (
                  <div className="table-responsive">
                    <Table hover className="table mb-0">
                      <thead className="thead-light">
                        <tr>
                          <th style={{ textAlign: "center" }}>From</th>
                          <th style={{ textAlign: "center" }}>To</th>
                          <th style={{ textAlign: "center" }}>Duration</th>
                          <th style={{ textAlign: "center" }}>Date / Time </th>
                          <th style={{ textAlign: "center" }}>Details </th>
                        </tr>
                      </thead>

                      <tbody>
                        {dataFilter.map((element, i) => {


                          let findRecording = element.result.filter(element => element.media_recordings.length > 0)

                          if (
                            i <= pagination &&
                            i >= pagination - cantPagination
                          ) {
                            return (
                              <tr key={i}>
                                <th style={{ textAlign: "center" }}>
                                  {element.result[0].from.slice(0, element.result[0].from.lastIndexOf("@"))}
                                  <br></br>
                                  {element.result[0].caller_id_name}

                                </th>

                                {element.result.length === 1 ?
                                  <th style={{ textAlign: "center" }}>
                                    {element.result[0].dialed_number}
                                  </th>
                                  :
                                  <th style={{ textAlign: "center" }}>
                                    {element.result[0].to.slice(0, element.result[0].from.lastIndexOf("@"))}
                                    <br></br>
                                    {element.result[0].callee_id_name}
                                  </th>
                                }

                                <th style={{ textAlign: "center" }}>
                                  {element.duration_seconds}
                                </th>
                                <th style={{ textAlign: "center" }}>
                                  {formatData(element.unix_timestamp)}
                                </th>
                                <th style={{ textAlign: "center" }}>
                                  <div className="d-flex justify-content-center">

                                    <ButtonHover
                                      onClick={() => {
                                        setModal(true);
                                        setElement(element.result);
                                      }}
                                    >
                                      <DescriptionOutlinedIcon />
                                    </ButtonHover>
                                    {findRecording.length > 0 && <ButtonHover
                                      onClick={() => {
                                        setModalDownload(findRecording)
                                        setElementDownload(element)

                                      }}
                                    >
                                      <GetAppIcon />
                                    </ButtonHover>}
                                  </div>

                                </th>
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                    </Table>
                    <ContainerPagination>
                      {dataFilter && dataFilter.length > cantPagination && (
                        <div className="col-12 container-pages">
                          <button
                            className="btn-page pl-2"
                            onClick={onClickBack}
                          >
                            {" "}
                            <ArrowBackIosIcon />{" "}
                          </button>
                          <Pages
                            pagination={pagination}
                            setPagination={setPagination}
                            cantPagination={cantPagination}
                            data={
                              (dataFilter.length / cantPagination) % 1 === 0
                                ? dataFilter.length / cantPagination
                                : dataFilter.length / cantPagination + 1
                            }
                          />
                          <button className="btn-page" onClick={onClickNext}>
                            {" "}
                            <ArrowForwardIosIcon />
                          </button>
                        </div>
                      )}
                    </ContainerPagination>
                  </div>
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "2rem",
                      fontWeight: "200",
                      margin: "0",
                    }}
                  >
                    {" "}
                    You don't have Call Logs
                  </p>
                )}
              </Card>
            )}
          </Container>
        )}
      </div>
    </React.Fragment>
  );
}
