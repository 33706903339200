import React from 'react'
import { Card, Button } from "reactstrap"
import CheckIcon from '@material-ui/icons/Check';
import GroupIcon from '@material-ui/icons/Group';
export default function ContainerCampaign() {
    return (
        <Card style={{ height: "100%", padding: "30px" }}>

            <h5><strong>This is a campaign name</strong></h5>

            <div className="containerAlert">
                <div className="checkAlert"><CheckIcon /></div>
                <span className="textAlert">This campaign was sent on 10/22/2021 4:11PM </span>
            </div>

            <br />
            <br />

            <h6>Recipients</h6>

            <div>

                <Button color="secondary" style={{ borderRadius: "3px" }}>
                    <GroupIcon />  Contact Group Name
                </Button>
            </div>

            <br />
            <br />

            <h6>Message Info</h6>
            <textarea className="form-control" name="" id="" cols="30" rows="10"></textarea>


            <br />
            <br />

            <h6>Message Analytics</h6>




        </Card>
    )
}
