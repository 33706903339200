import React, {useEffect, useState} from "react";

import {SidebarItem} from "../components/Index";
import {Button, Spinner, Badge} from "reactstrap";
import styled from "styled-components";
const StartItemStyle = styled.div`
  width: 80%;
  height: 80px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0.5rem auto;
  border-radius: 5px;
  background: #e0e0e0;
  padding: 0;
  color: black;
  cursor: pointer;
`;
export const UsersOption = ({
  listUsers,
  state,
  updateState,
  getMainCallFlow,
}) => {
  const [userSearch, setUserSearch] = useState("");
  const [activeItem, setActiveItem] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onChangeAutocomplete = (e) => {
    setUserSearch(e.target.value);
    setActiveItem(false);
  };

  const startPoint = {
    port3: {
      id: "port3",
      type: "top",
    },
  };

  const updateAction = () => {
    if (Object.keys(state.nodes).length > 2) {
      delete state.nodes[Object.keys(state.nodes)[1]];
    }
    let copiaState = {...state};
    copiaState.links = {
      link1: {
        id: "link1",
        from: {nodeId: Object.keys(state.nodes)[1], portId: "port3"},
        to: {nodeId: "node1", portId: "port1"},
      },
    };
    updateState(copiaState);
  };

  return (
    <div>
      <p style={{fontSize: "1rem", textAlign: "center"}}>Select One</p>
      <input
        onChange={onChangeAutocomplete}
        className="form-control"
        list="datalistOptions"
        id="exampleDataList"
        placeholder="Type to search..."
      />
      <datalist id="datalistOptions">
        {listUsers.map((element, i) => (
          <option
            key={i}
            value={`${element.first_name.trim()} ${element.last_name.trim()} ${element.presence_id.trim()}`}
          />
        ))}
      </datalist>

      <Button
        disabled={loading}
        onClick={async () => {
          setLoading(true);
          setError(false);
          getMainCallFlow(userSearch)
            .then((params) => {
              setActiveItem(userSearch);
              setLoading(false);
            })
            .catch((params) => {
              console.log("error");
              setError(true);
              setLoading(false);
            });
        }}
        className=" mt-3 w-100"
        color="primary"
      >
        {loading && (
          <Spinner
            style={{marginRight: "0.5em"}}
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
        Create
      </Button>
      {error && (
        <div className="w-100 text-center pt-3">
          {" "}
          <Badge
            className="py-2 px-4"
            style={{fontSize: "0.7rem"}}
            color="danger"
          >
            {" "}
            User Not Found{" "}
          </Badge>{" "}
        </div>
      )}

      {activeItem && (
        <SidebarItem
          updateAction={updateAction}
          state={state}
          label={`User: ${activeItem}`}
          type={`User_${activeItem}`}
          ports={startPoint}
          ItemStyle={StartItemStyle}
        />
      )}
    </div>
  );
};
