import React, {useState, useEffect} from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";
import petition_get from "../../../../petitions/petition_get";
import petition_patch from "../../../../petitions/petition_patch";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,

} from "reactstrap";


import toastr from "toastr" 


export default function ModalUtility({
  modal,
  setModal,
  loading
}) {
const [data, setData] = useState([]);
  useEffect(() => {
    if (modal) {
      const newData = Object.keys(modal.metadata);
      setData(newData); 
    }
  }, [modal]);
   

  return (
    <Modal
      size="md"
      centered={true}
      isOpen={modal ? true : false}
      className="info "
    >
      <ModalHeader className="bg-soft-primary">
        {" "}
        <Row>
          <Col xs="10">
            <div className="text-primary p-3">
              <h4 className="text-primary">Number Details</h4>
            </div>
          </Col>
          <Col xs="2" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
         {" "}
        {data.map((element, i) => (
          <p key={i}>
            {element} : {modal.metadata[element]}
          </p>
        ))}{" "}
      
      </ModalBody>{" "}
      <ModalFooter>
        <Button

          color="primary"
          onClick={() => { setModal(false); setData([])}}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}
