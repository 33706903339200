import React, { Component } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Spinner } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { loginUser, apiError, tokenUser } from "../../store/actions";
import itpLogo from "../../assets/images/itpFiber/itp_white.png";


//import modal 
import ModalUtility from './ModalUtility/ModalUtility'

import changePassword from "./ChangePasswordPetition";
import verifyToken from "./verifyToken";
import jwt_decode from "jwt-decode";
import * as qs from "query-string";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonLogIn: false,
      token: "",
      password: "",
      rePassword: "",
      modal: false
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // handleValidSubmit
  async handleValidSubmit(event, values) {
    this.setState({ buttonLogIn: true });
    this.props.loginUser(values, this.props.history);
    setTimeout(this.resetLoading, 3000);
  }

  resetLoading = () => {
    this.setState({ buttonLogIn: false });
  };

  componentDidMount() {
    let dateTime = Date.now();
    let timestamp = Math.floor(dateTime / 1000);
    let token = qs.parse(this.props.location.search).token
    let jwt = jwt_decode(token);
    // if(jwt.exp < timestamp ) this.setState({modal:true})
    this.setState({ token: token });
    this.props.apiError("");
    verifyToken(token)
      .then(({ data: { result } }) => {
        console.log(result);
      })
      .catch((error) => {
        if (error.response.status === 401) this.setState({ modal: true })
      });
  }

  send() {
    this.setState({ buttonLogIn: true });
    changePassword(this.state.token, this.state.password)
      .then(({ data: { result } }) => {
        this.props.tokenUser(this.state.token, this.props.history)
        /* this.props.history.push({
          pathname: "login",
        }); */
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response.status)
        if (error.response.status === 401) this.setState({ modal: true })
        // this.setState({modal:true})
        this.setState({ buttonLogIn: false });
      });

  }
  changeModal() {
    this.setState({ modal: false });
  }


  redirectUser() {
    window.location.href = "/forgot-password?redirect=true"
  }

  render() {
    return (
      <React.Fragment>
        <ModalUtility modal={this.state.modal} setModal={this.changeModal} redirectUser={this.redirectUser} />
        <div className="account-pages my-5 pt-sm-5">
          <Container className="CenterBottom">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-soft-secundary">
                    <Row>
                      <Col className="col-12">
                        <div className="text-primary  p-4"></div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="logoITP">
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <img src={itpLogo} alt="" height="70" />
                          {/* </span> */}
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                      >
                        {this.props.error && this.props.error ? (
                          <Alert color="danger">{this.props.error}</Alert>
                        ) : null}

                        <div className="form-group">
                          <AvField
                            name="Password"
                            label="Pasword"
                            value=""
                            className="form-control"
                            placeholder="Enter Password"
                            type="password"
                            required
                            onChange={(e) =>
                              this.setState({ password: e.target.value })
                            }
                          />
                        </div>

                        <div className="form-group">
                          <AvField
                            name="Password"
                            label="Confirm Password"
                            value=""
                            type="password"
                            required
                            placeholder="Enter Password"
                            onChange={(e) =>
                              this.setState({ rePassword: e.target.value })
                            }
                          />
                        </div>
                        <div className="mt-3">
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            // type="submit"
                            disabled={
                              this.state.buttonLogIn === true ||
                                this.state.password !== this.state.rePassword ||
                                this.state.password === "" ||
                                this.state.rePassword === ""
                                ? true
                                : false
                            }
                            onClick={() => {
                              this.send();
                            }}
                          >
                            {this.state.buttonLogIn === true ? (
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null}

                            {this.state.buttonLogIn === true
                              ? "  Loading..."
                              : "Send"}
                          </button>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>© {new Date().getFullYear()} ITP.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStatetoProps, { loginUser, apiError, tokenUser })(ChangePassword)
);
