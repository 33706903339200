import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";

export default function verifyToken(token) {
  const json = require("../../config.json");
  const urlBase = json.urlBase;
  var url;

  if (token) {
    url = urlBase + "/crm/myprofile";
    var config = {
      headers: {
        Authorization: "Bearer " + token.replace(/['"]+/g, ""),
      },
    };

    return axios.get(url, config);
  } else {
    return <Redirect exact from="/" to="/logout" />;
  }

}