import React from "react";
import { Route, Redirect } from "react-router-dom";
import petition_get from "../pages/petitions/petition_get";
import WebPhone from "../pages/ITPVoice/OperatorPanel/WebPhone"
import jwt_decode from "jwt-decode";
import { Row, Col } from "reactstrap"
const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  webPhoneActive,
  activePhone,
  services,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {



      if (isAuthProtected && !localStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }
      if (
        window.location.pathname !== "/logout" &&
        window.location.pathname !== "/forgot-password" &&
        window.location.pathname !== "/register" &&
        window.location.pathname !== "/ChangePassword" &&
        window.location.pathname !== "/login"
      ) {



        const token = JSON.parse(localStorage.getItem("authUser"));
        let jwt = jwt_decode(token.access_token);
        let dateTime = Date.now();
        let timestamp = Math.floor(dateTime / 1000);

        if (jwt.exp < timestamp) { window.location.href = "/logout" }

      }


      return (
        <Layout services={services}>
          {webPhoneActive
            ? <Row>
              <Col style={{ transitions: "5s all" }} className={`col-12 col-lg-${activePhone ? "10" : "12"}`}>   <Component {...props} /></Col>
            </Row>
            : <Component {...props} />}



        </Layout>
      );
    }}
  />
);

export default AppRoute;
