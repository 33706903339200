import React, {useState, useEffect} from "react";
import {Card, Col, Row, Badge} from "reactstrap";
import ChatIcon from "@material-ui/icons/ChatBubbleOutline";
import CustomTable from "../../components/utils/CustomTable/CustomTable";
import petition_get from "../petitions/petition_get";
export default function ViewTickets() {
  const [dataTable, setDataTable] = useState(null);
  const getColorStatus = (status) => {
    switch (status) {
      case "Closed":
        return "danger";
      case "In Progress":
        return "primary";
      case "Pending":
        return "secondary";
      default:
        return "secondary";
    }
  };

  const TicketItem = ({title, date, description, status, message}) => {
    return (
      <Col className="col-12 col-lg-4 col-xl-4">
        <Card body style={{borderRadius: "1px"}}>
          <div className="d-flex justify-content-between">
            <h3>{title}</h3>
            <p>{date}</p>
          </div>
          <p>{description}</p>
          <div className="d-flex">
            <Badge
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              color={getColorStatus(status)}
            >
              {status}
            </Badge>
            <div className="pl-3">
              <ChatIcon /> {message}
            </div>
          </div>
        </Card>
      </Col>
    );
  };

  const [items] = useState([
    {
      title: "Cannot access the system",
      date: "2h ago",
      description:
        "Life seasons open have. Air have of. Lights fill after let third darkness replenish fruitful let. Wherein set image. Creepeth said above gathered bring",
      status: "Closed",
      message: "3",
    },
    {
      title: "Cannot access the system",
      date: "5h ago",
      description:
        "Life seasons open have. Air have of. Lights fill after let third darkness replenish fruitful let. Wherein set image. Creepeth said above gathered bring",
      status: "In Progress",
      message: "3",
    },
    {
      title: "Cannot access the system",
      date: "3 days ago",
      description:
        "Life seasons open have. Air have of. Lights fill after let third darkness replenish fruitful let. Wherein set image. Creepeth said above gathered bring",
      status: "In Progress",
      message: "4",
    },
    {
      title: "Cannot access the system",
      date: "2h ago",
      description:
        "Life seasons open have. Air have of. Lights fill after let third darkness replenish fruitful let. Wherein set image. Creepeth said above gathered bring",
      status: "Pending",
      message: "0",
    },
    {
      title: "Cannot access the system",
      date: "2h ago",
      description:
        "Life seasons open have. Air have of. Lights fill after let third darkness replenish fruitful let. Wherein set image. Creepeth said above gathered bring",
      status: "Pending",
      message: "0",
    },
  ]);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));

      petition_get("getTickets", {id: obj.pk})
        .then(({data: result}) => {
          setDataTable({
            title: [
              {title: "Subject", key: "subject"},
              {title: "Status", key: "status"},
              {title: "Ticket Number", key: "ticketNumber"},
            ],
            content: [...result.result.data],
          });
        })
        .catch((error) => console.log(error));
    }
  }, []);

  return (
    <div>
      <CustomTable bordered border data={dataTable} />
      {/*  <Row>
        {items.map((ticket) => (
          <TicketItem {...ticket} />
        ))}
      </Row> */}
    </div>
  );
}
