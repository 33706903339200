import React, { useState, useEffect } from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";
import petition_get from "../../../../petitions/petition_get"
import classnames from "classnames";

import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CustomInput

} from "reactstrap";
import toastr from "toastr";
export default function ModalUtility({
  modal,
  setModal,
  editMenu,
  loadingUpdate,
  reloadData,
  dataMedia

}) {
  toastr.options = {
    showDuration: 300,
  };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const [loading, setLoading] = useState(false)
  const [customActiveTab, setCustomActiveTab] = useState("1");
  const [form, setForm] = useState({
    name: "",
    timeout: "10000",
    interdigit_timeout: "2000",
    max_extension_length: "4",
    media: {
      greeting: "",
      invalid_media: true,
      transfer_media: true,
      exit_media: true
    },
    name: "",
    hunt: false,
    allow_record_from_offnet: false,
    suppress_media: false,
    retries: "2"

  })


  useEffect(() => {

    setForm({
      name: "",
      timeout: "10000",
      interdigit_timeout: "2000",
      max_extension_length: "4",
      media: {
        greeting: "",
        invalid_media: true,
        transfer_media: true,
        exit_media: true
      },
      name: "",
      hunt: false,
      allow_record_from_offnet: false,
      suppress_media: false,
      retries: "2"
    })

    if (modal) {

      setLoading(true)
      petition_get("getMenuDetails", {
        id: id,
        menuId: modal.id
      })
        .then(({ data: result }) => {
          console.log(result)

          result.result.timeout = parseInt(result.result.timeout) || ""
          result.result.interdigit_timeout = parseInt(result.result.interdigit_timeout) || ""
          result.result.max_extension_length = parseInt(result.result.max_extension_length) || ""
          result.result.retries = parseInt(result.result.retries) || ""


          console.log(result.result)

          setForm({ ...result.result })

          setLoading(false)
        })
        .catch((error) => {
          console.log(error);
          setLoading(false)
        });

    }


  }, [modal])


  const onChangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }


  const toggleCustom = (tab) => {

    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };

  const handleSubmit = () => {

    if (form.name.trim() === "") {
      return toastr.error("Name is Required", "Error");
    }

    if (form.record_pin && form.record_pin.length < 3) {
      return toastr.error("Prompt Record PIN is Required with 3 lengths", "Error");
    }
    if (form.media.greeting.trim() === "") {
      return toastr.error("Greating Message is Required", "Error");
    }

    form.timeout = parseInt(form.timeout) || 0
    form.interdigit_timeout = parseInt(form.interdigit_timeout) || 0
    form.max_extension_length = parseInt(form.max_extension_length) || 0
    form.retries = parseInt(form.retries) || 0
    editMenu(form)

  }

  useEffect(() => {

    setForm({
      name: "",
      record_pin: false,
      timeout: "",
      interdigit_timeout: "",
      max_extension_length: "",
      media: {
        greeting: "",
        invalid_media: true,
        transfer_media: true,
        exit_media: true
      },
      name: "",
      hunt: false,
      allow_record_from_offnet: false,
      suppress_media: false,
      retries: ""

    })


  }, [reloadData])


  return (
    <Modal centered={true} isOpen={modal} className="info ">
      <ModalHeader className="bg-soft-primary">
        {" "}
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary">Add Menu</h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>

        {loading ? <div className="text-center">

          <Spinner
            animation="grow"
            style={{
              marginTop: "10vh",
              marginBottom: "10vh",
              height: "10vh",
              width: "10vh",
            }}
          />
        </div> : <> <Nav
          tabs
          className="nav-tabs-custom  flex-column flex-md-row  text-center  "
        >
          <NavItem className="pt-3 pt-md-0">
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: customActiveTab === "1",
              })}
              onClick={() => {
                toggleCustom("1");
              }}
            >
              <span>Basic</span>
            </NavLink>
          </NavItem>
          <NavItem className="pt-3 pt-md-0">
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: customActiveTab === "2",
              })}
              onClick={() => {
                toggleCustom("2");
              }}
            >
              <span>Advanced options</span>
            </NavLink>
          </NavItem>
        </Nav>


          <TabContent activeTab={customActiveTab}>
            <TabPane tabId="1" className="p-3">
              <Row>
                <Col
                  className="d-flex col-12 pt-4"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Label
                    htmlFor="name"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Name:
                  </Label>
                  <input
                    className="form-control"
                    id="name"
                    name="name"
                    type="text"
                    onChange={onChangeForm}
                    value={form.name}
                    placeholder="Name"
                  />
                </Col>
                <Col
                  className="d-flex col-12 pt-4"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Label
                    htmlFor="record_pin"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Prompt Record PIN
                  </Label>
                  <input
                    className="form-control"
                    id="record_pin"
                    name="record_pin"
                    type="number"
                    onChange={onChangeForm}
                    value={form.record_pin}
                    placeholder="Prompt Record PIN"
                  />
                </Col>

                <Col
                  className="d-flex col-12 pt-4"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Label
                    htmlFor="media"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Greeting Name:
                  </Label>


                  <select
                    className="form-control"
                    id="media"
                    name="media"
                    onChange={(e) => { setForm({ ...form, media: { ...form.media, greeting: e.target.value } }) }}
                    value={form.media.greeting}>
                    <option value="">Select one</option>
                    {dataMedia.map((element, i) => <option key={i} value={element.id}> {element.name} </option>)}
                  </select>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2" className="p-3">
              <Row>
                <Col
                  className="d-flex col-12 pt-4"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Label
                    htmlFor="retries"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Number of Retries on misdal
                  </Label>
                  <input
                    className="form-control"
                    id="retries"
                    name="retries"
                    type="number"
                    onChange={onChangeForm}
                    value={form.retries}
                    placeholder="Retries"
                  />
                </Col>
                <Col
                  className="d-flex col-12 pt-4"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Label
                    htmlFor="timeout"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Timeout for dialing
                  </Label>
                  <input
                    className="form-control"
                    id="timeout"
                    name="timeout"
                    type="number"
                    onChange={onChangeForm}
                    value={form.timeout}
                    placeholder="timeout"
                  />
                </Col>
                <Col
                  className="d-flex col-12 pt-4"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Label
                    htmlFor="interdigit_timeout"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Interdigit timeout
                  </Label>
                  <input
                    className="form-control"
                    id="interdigit_timeout"
                    name="interdigit_timeout"
                    type="number"
                    onChange={onChangeForm}
                    value={form.interdigit_timeout}
                    placeholder="Interdigit Timeout"
                  />
                </Col>
                <Col
                  className="d-flex col-12 pt-4"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Label
                    htmlFor="max_extension_length"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Extension Number Length
                  </Label>
                  <input
                    className="form-control"
                    id="max_extension_length"
                    name="max_extension_length"
                    type="number"
                    onChange={onChangeForm}
                    value={form.max_extension_length}
                    placeholder="Extension Number Length"
                  />
                </Col>

                <Col
                  className="d-flex col-12 pt-4"

                >

                  <CustomInput
                    className="input-check"
                    id="hunt"
                    name="hunt"
                    type="checkbox"
                    onChange={(e) => {
                      setForm({ ...form, hunt: e.target.checked });
                    }}
                    value={form.hunt}

                  />
                  <Label
                    htmlFor="hunt"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Allow caller to dial extensions
                  </Label>
                </Col>
                <Col
                  className="d-flex col-12 pt-4"

                >

                  <CustomInput
                    className="input-check"
                    id="allow_record_from_offnet"
                    name="allow_record_from_offnet"
                    type="checkbox"
                    onChange={(e) => {
                      setForm({ ...form, allow_record_from_offnet: e.target.checked });
                    }}
                    value={form.allow_record_from_offnet}

                  />
                  <Label
                    htmlFor="allow_record_from_offnet"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Allow outside recording
                  </Label>
                </Col>
                <Col
                  className="d-flex col-12 pt-4"

                >

                  <CustomInput
                    className="input-check"
                    id="suppress_media"
                    name="suppress_media"
                    type="checkbox"
                    onChange={(e) => {
                      setForm({ ...form, suppress_media: e.target.checked });
                    }}
                    value={form.suppress_media}

                  />
                  <Label
                    htmlFor="suppress_media"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Suppress playing of Invalid Entry
                  </Label>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </>}




      </ModalBody>
      <ModalFooter>
        <Button
          outline
          color="secondary"
          onClick={() => {
            setModal(false);
            setForm({
              name: "",
              record_pin: false,
              timeout: "",
              interdigit_timeout: "",
              max_extension_length: "",
              media: {
                greeting: "",
                invalid_media: true,
                transfer_media: true,
                exit_media: true
              },
              name: "",
              hunt: false,
              allow_record_from_offnet: false,
              suppress_media: false,
              retries: ""

            })
          }}
        >
          Cancel
        </Button>

        <Button
          disabled={loadingUpdate}
          color="primary"
          onClick={handleSubmit}
        >
          {loadingUpdate && (
            <Spinner
              style={{ marginRight: "0.5em" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
