import React from 'react'
import offImage from "../../../../../../assets/images/off.png"
import { Button } from "reactstrap"
export default function OfflinePhone({ notDevices, setDisconnected, disconnected, setReloadRegister }) {
    return (
        <div className="border h-100 " style={{ justifyContent: "center", display: "flex", alignItems: "center", flexDirection: "column" }}>
            <p style={{ fontSize: "40px", color: "black", margin: "0" }}>Disabled</p>
            {disconnected
                ? <p className="px-2" style={{ textAlign: "center", lineHeight: "17px", fontSize: "15px", color: "rgba(0,0,0,.65)" }}>{disconnected}</p>
                : <p style={{ textAlign: "center", lineHeight: "10px", fontSize: "15px", color: "rgba(0,0,0,.65)" }}>{notDevices ? "No audio devices have been detected." : "To enable go to settings"}</p>
            }
            {disconnected
                ? <div> <Button onClick={() => { setReloadRegister(true); setDisconnected(false) }} color="primary"> Retry </Button>  <Button onClick={() => { window.location.href = "/logout" }} color="danger"> Log Out </Button></div>


                : <img style={{ width: "150px" }} className="img-fluid" src={offImage} alt="off" />
            }
        </div>
    )
}
