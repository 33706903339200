import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select'
import InfoIcon from "@material-ui/icons/Info";
import { Button } from "reactstrap"
import { Multiselect } from "multiselect-react-dropdown";

import petition_get from '../../../../../petitions/petition_get';
import petition_patch from '../../../../../petitions/petition_patch';
import { configure } from '@testing-library/dom';

export default function AssignedUserTextMessage({ numberDetails, allUsers, activeSubmit, setActiveSubmit, elementNumber }) {

    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    const [radioSwitch, setRadioSwitch] = useState("-1")
    const [listUsers, setListUsers] = useState([])
    const [userSelected, setUserSelected] = useState({ value: "", label: "Not Assigned" })
    const [activeInfo, setActiveInfo] = useState(false)

    const [usersMultiSelect, setUsersMultiSelect] = useState([])

    const [multiMembers, setMultiMembers] = useState([])
    const [multiSelectedOptions, setMultiSelectedOptions] = useState([])



    useEffect(() => {

        if (numberDetails) {


            //Get list of users for select
            let listUsersCopy = [{ value: "", label: "Not Assigned" }]
            allUsers.map(element => listUsersCopy.push({ value: element.id, label: `${element.first_name} ${element.last_name} ${element.presence_id}` }))
            setListUsers(listUsersCopy)


            //Get list of users for multiselect
            let allUsersForm = []
            allUsers.map(element => allUsersForm.push({ name: `${element.first_name} ${element.last_name}`, id: element.id }))
            setUsersMultiSelect(allUsersForm)


            console.log(numberDetails)
            //Get users in the number 
            let usersAvailable = []
            allUsers.map(element => {

                let findUser = numberDetails.itpvoice_metadata.sms_assigned_users.find(elementTwo => elementTwo === element.id)

                if (findUser) usersAvailable.push({ name: `${element.first_name} ${element.last_name}`, id: element.id })

            })

            if (usersAvailable.length === 1) {
                radioRef1.current.click()
            }
            else if (usersAvailable.length > 1) {
                radioRef2.current.click()

            }

            if (usersAvailable.length >= 1) {
                setRadioSwitch(usersAvailable.length === 1 ? "1" : "2")
                setMultiSelectedOptions(usersAvailable)
            }

            if (usersAvailable.length === 1) setUserSelected({ value: usersAvailable[0].id, label: usersAvailable[0].name })
        }

    }, [numberDetails])


    const handleChangeCheck = setMultiMembers


    const onSubmitForm = (params) => {


        //Get details of users in the multiselect
        let petitions = []
        let usersId = []
        multiMembers.map(element => { usersId.push(element.id); petitions.push(petition_get("viewCallSettingsUsers", { id, user_id: element.id })) })

        Promise.all(petitions)
            .then((result) => {

                //Add Number in the list
                let users = []
                result.map(element => users.push(element.data.result))
                users.map(element => {
                    let findOne = element.itpvoice_metadata.sms_assigned_numbers.find(elementTwo => elementTwo === elementNumber.num)
                    if (!findOne) element.itpvoice_metadata.sms_assigned_numbers.push(elementNumber.num)
                })


                //Update Users  
                let petitionsUsers = []
                users.map(elementThree => petitionsUsers.push(petition_patch("editUser", [{ id, user_id: elementThree.id }, { ...elementThree }])))
                Promise.all(petitionsUsers)
                    .then((result) => console.log(result))
                    .catch((error) => console.log(error))


                let data = [
                    { id, number: elementNumber.num },
                    {
                        itpvoice_metadata: { ...elementNumber.itpvoice_metadata, sms_assigned_users: usersId }
                    },
                ];

                petition_patch("numberEdit", data)

            })
            .catch((error) => console.log(error))


        //Delete number in one user 
        let petitionsDeleteUsers = []


        numberDetails.itpvoice_metadata.sms_assigned_users.map(elementTwo => {
            let findOne = usersId.find(elementThree => elementThree === elementTwo)
            if (!findOne) {
                console.log("se ejecuto uno")
                petitionsDeleteUsers.push(petition_get("viewCallSettingsUsers", { id, user_id: elementTwo }))
            }
        })

        Promise.all(petitionsDeleteUsers)
            .then((result2) => {
                let users2 = []


                console.log(result2)


                result2.map(element => {
                    let numbers = element.data.result.itpvoice_metadata.sms_assigned_numbers.filter(elementTwo => elementTwo !== elementNumber.num)
                    users2.push({ ...element.data.result, itpvoice_metadata: { ...element.data.result.itpvoice_metadata, sms_assigned_numbers: numbers } })
                })

                let petitionsDeleteUsers2 = []
                users2.map(elementThree => petitionsDeleteUsers2.push(petition_patch("editUser", [{ id, user_id: elementThree.id }, { ...elementThree }])))
                Promise.all(petitionsDeleteUsers2)
                    .then((result) => console.log(result))
                    .catch((error) => console.log(error))
            })
            .catch((error) => console.log(error))

    }

    useEffect(() => {

        if (activeSubmit) {
            setActiveSubmit(false)
            onSubmitForm()
        }

    }, [activeSubmit])

    let radioRef1 = useRef()
    let radioRef2 = useRef()

    return (
        <div>
            {activeInfo
                ? <>
                    <p>Multi User Messaging will be billed at a standard rate of 0.03 pre message. </p>
                    <div className="text-right">
                        <Button color="primary" onClick={() => { setActiveInfo(false) }} >  Ok </Button>
                    </div>
                </>
                : <>   <div className="custom-control custom-radio pr-3 pb-3">
                    <input
                        type="radio"
                        id="user1"
                        name="customRadio"
                        className="custom-control-input"
                        onChange={() => { setRadioSwitch("1") }}
                        value="single"
                        ref={radioRef1}

                    />
                    <label
                        className="custom-control-label"
                        htmlFor="user1"
                        style={{ paddingTop: "1px" }}
                    >
                        Single User Messaging
                    </label>
                </div>
                    {radioSwitch === "1" &&
                        <div className="col-12 pb-3">
                            <label htmlFor="">Assigned User</label>

                            <Select
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        background: "transparent"
                                    }),
                                    option: (base, state) => ({
                                        ...base,
                                        color: "rgba(0,0,0,.8)"
                                    }),
                                }}

                                options={listUsers}
                                placeholder={userSelected.label}
                                className="form-control control-select"
                                onChange={(e) => { setMultiMembers([{ id: e.value, name: e.label }]) }}
                            />

                        </div>}


                    <div className="custom-control custom-radio pr-3 pb-3 d-flex">
                        <input
                            type="radio"
                            id="user2"
                            name="customRadio"
                            className="custom-control-input"
                            onChange={() => { setRadioSwitch("2") }}
                            value="single"
                            ref={radioRef2}

                        />
                        <label
                            className="custom-control-label pr-2"
                            htmlFor="user2"
                            style={{ paddingTop: "1px" }}
                        >
                            Multi User Messaging
                        </label>
                        <div className="d-flex align-items-center">


                            <InfoIcon onClick={() => { setActiveInfo(true) }} style={{ cursor: "pointer", color: "#8b9eff", fontSize: "15px" }} />
                        </div>
                    </div>

                    {radioSwitch === "2" &&
                        <div className="col-12 py-3">
                            <label htmlFor="">Assigned User</label>


                            <input
                                type="radio"
                                id="user1"
                                name="customRadio"
                                className="custom-control-input"
                                onChange={() => { setRadioSwitch("1") }}
                                value="single"

                            />

                            <input
                                type="radio"
                                id="user1"
                                name="customRadio"
                                className="custom-control-input"
                                onChange={() => { setRadioSwitch("1") }}
                                value="single"

                            />

                            <input
                                type="radio"
                                id="user1"
                                name="customRadio"
                                className="custom-control-input"
                                onChange={() => { setRadioSwitch("1") }}
                                value="single"

                            />


                            <Multiselect
                                options={[...usersMultiSelect]}
                                onSelect={handleChangeCheck}
                                onRemove={handleChangeCheck}
                                selectedValues={multiSelectedOptions}
                                displayValue="name"
                                placeholder="Start Typing..."

                            />


                        </div>} </>}
        </div>
    )
}
