import React from "react";
import AddIcon from "@material-ui/icons/Add";
import ListIcon from "@material-ui/icons/List";
import {Row, Col, Button} from "reactstrap";
export default function NavbarTitle() {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  return (
    <>
      <Row style={{height: "4rem"}}>
        <Col
          style={{
            padding: "0 1rempx",

            display: "flex",
            alignItems: "center",
          }}
        >
          <h5 style={{paddingLeft: "1rem", margin: "0", color: "#a6b0cf"}}>
            User
          </h5>
        </Col>
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {" "}
          <Button
            style={{height: "60%"}}
            color="primary"
            onClick={() => {
              window.location.href = `/AddUsers?id=${id}`;
            }}
            className="btn-back"
          >
            <AddIcon className="icon-arrow" /> Add User
          </Button>
         {/*  <ListIcon style={{margin: "0 1rem"}} /> */}
        </Col>
      </Row>
    </>
  );
}
