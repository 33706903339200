import React, { useState, useEffect } from "react";
import phoneImg from "../../../../../../assets/images/phone-image.svg";
import petition_get from "../../../../../petitions/petition_get";
import petition_patch from "../../../../../petitions/petition_patch";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Label,
  Badge,
} from "reactstrap";
import toastr from "toastr";

export default function ModalUtility({ modal, setModal }) {
  toastr.options = {
    showDuration: 300,
  };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ password: "", confirmPassword: "" });
  const [error, setError] = useState(null);

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onSubmitForm = () => {
    setError(null);

    if (form.password.trim() !== form.confirmPassword.trim()) {
      return setError("Passwords are not the same");
    }
    setLoading(true);
    petition_patch("userDetails", { id: id, data: { ...modal, password: form.password } })
      .then(({ data: result }) => {
        setLoading(false);
        setModal(false);
        setForm({ password: "", confirmPassword: "" });
        toastr.success("Action completed", "Success");
      })
      .catch((error) => {
        setLoading(false);
        toastr.error("Action failed", "Error");
      });
  };

  return (
    <Modal centered={true} isOpen={modal ? true : false} className="info ">
      <ModalHeader className="bg-soft-primary">
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary">Change Password</h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col
            className="d-flex col-12 pt-4"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Label
              htmlFor="password"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              Password
            </Label>
            <input
              className="form-control"
              id="password"
              name="password"
              type="password"
              onChange={onChange}
              value={form.password}
              placeholder="Password"
            />
          </Col>
          <Col
            className="d-flex col-12 pt-4"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Label
              htmlFor="confirm"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              Confirm Password{" "}
              {error && (
                <Badge
                  style={{ fontSize: "12px" }}
                  className="ml-2 p-1"
                  color="danger"
                >
                  {" "}
                  {error}{" "}
                </Badge>
              )}
            </Label>
            <input
              className="form-control"
              id="confirm"
              name="confirmPassword"
              type="password"
              onChange={onChange}
              value={form.confirmPassword}
              placeholder="Confirm Password"
            />
          </Col>
        </Row>
      </ModalBody>{" "}
      <ModalFooter>
        <Button
          outline
          color="secondary"
          disabled={loading}
          onClick={() => {
            setModal(false);
            setForm({ password: "", confirmPassword: "" });
          }}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          onClick={() => {
            onSubmitForm();
          }}
          disabled={
            loading ||
            form.password.trim() === "" ||
            form.confirmPassword.trim() === ""
          }
        >
          {loading && (
            <Spinner
              style={{ marginRight: "0.5em" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
