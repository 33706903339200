import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import toastr from "toastr";
import Select from 'react-select'
import { v4 as uuidv4 } from 'uuid';
import petition_get from "../../../../../petitions/petition_get";
import petition_patch from "../../../../../petitions/petition_patch";
import Loading from "../../../../../../components/utils/Loading"

export default function AssignedUserVoice({ numberDetails, elementNumber, activeSubmit, setActiveSubmit, setModal, setLoading, setReloadDataEdit, allUsers }) {


    toastr.options = { showDuration: 300, };

    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    const [userOwner, setUserOwner] = useState(null);
    const [userOwnerSave, setUserOwnerSave] = useState(null);
    const [listUsers, setListUsers] = useState([])
    const [userSelected, setUserSelected] = useState({ value: "", label: "Not Assigned" })

    const [form, setForm] = useState({ prefix: "" });
    const [switchOne, setSwitchOne] = useState(false);
    const [loadingTwo, setLoadingTwo] = useState(false);

    const [disableAssigned, setDisableAssigned] = useState(false)
    const [callflowId, setCallflowId] = useState(null)

    const Offsymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                No
            </div>
        );
    };

    const OnSymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                Yes
            </div>
        );
    };



    useEffect(() => {
        if (numberDetails) {


            setLoadingTwo(false);
            setDisableAssigned(false)
            setUserSelected({ value: "", label: "Not Assigned" })
            setCallflowId(null)

            if (elementNumber.assigned_user === "Callflow") {
                setUserSelected({ value: "", label: "Callflow" })
                console.log(elementNumber)
                setCallflowId(elementNumber.callflowId)
            }


            setLoadingTwo(true);
            setForm({ prefix: numberDetails.prepend ? numberDetails.prepend.name : "" });
            setSwitchOne(numberDetails.prepend && numberDetails.prepend.enabled ? true : false);

            //Get list of users 
            let listUsersCopy = [{ value: "", label: "Not Assigned" }]
            allUsers.map(element => listUsersCopy.push({ value: element.id, label: `${element.first_name} ${element.last_name} ${element.presence_id}` }))
            setListUsers(listUsersCopy)

            const findOne = allUsers.find((element) => element.id === numberDetails.owner_id);

            if (findOne) {

                setUserOwnerSave(findOne.id);
                setUserOwner(findOne.id);
                setUserSelected({ value: findOne.id, label: `${findOne.first_name} ${findOne.last_name} ${findOne.presence_id}` })
                setDisableAssigned(false)
            }
        }
    }, [numberDetails]);



    const onChange = (e) => {
        if (e.target.value !== "")
            setSwitchOne(true);

        setForm({ ...form, prefix: e.target.value });
    };

    const onSubmitForm = async (e) => {
        let data = [
            { id, number: elementNumber.num },
            {
                owner_id: userOwner || null,
                prepend: {
                    name: form.prefix,
                    enabled: switchOne,
                },
            },
        ];

        setLoading(true);

        if (userOwner !== userOwnerSave) {

            await petition_get("allCallflows", { id })
                .then(async ({ data: result }) => {


                    if (callflowId) {

                        console.log("eliminar callflow del usuario")

                        await petition_get("callflowsDetails", { id, callflow: callflowId })
                            .then(({ data: result }) => {

                                let filterNumbers = result.result.numbers.filter(element => element !== elementNumber.num)

                                if (filterNumbers.length === 0) filterNumbers.push(`nonum_${uuidv4().split("-")[0]}`)

                                result.result.numbers = filterNumbers
                                petition_patch("callflowEdit", { id, callflow_id: callflowId, data: { ...result.result }, })
                                    .then((result) => console.log(result))
                                    .catch((error) => console.log(error));

                            })
                            .catch((error) => console.log(error));

                    }

                    if (userOwnerSave) {

                        const findCallFlowUserSave = result.result.find(element => element.owner_id === userOwnerSave)

                        console.log(findCallFlowUserSave)

                        await petition_get("callflowsDetails", { id, callflow: findCallFlowUserSave.id })
                            .then(({ data: result }) => {

                                let filterNumbers = result.result.numbers.filter(element => element !== elementNumber.num)

                                result.result.numbers.push(elementNumber.num)

                                result.result.numbers = filterNumbers
                                petition_patch("callflowEdit", { id, callflow_id: findCallFlowUserSave.id, data: { ...result.result }, })
                                    .then((result) => console.log(result))
                                    .catch((error) => console.log(error));

                            })
                            .catch((error) => console.log(error));
                    }

                    const findCallFlowUser = result.result.find(element => element.owner_id === userOwner)

                    if (findCallFlowUser) {
                        await petition_get("callflowsDetails", { id, callflow: findCallFlowUser.id })
                            .then(({ data: result }) => {


                                result.result.numbers.push(elementNumber.num)
                                petition_patch("callflowEdit", { id, callflow_id: findCallFlowUser.id, data: { ...result.result }, })
                                    .then((result) => console.log(result))
                                    .catch((error) => console.log(error));

                            })
                            .catch((error) => console.log(error));
                    }


                    petition_patch("numberEdit", data)
                        .then(({ data: result }) => {
                            toastr.success("Action completed", "Success");
                            setLoading(false);
                            resetForm()
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                            toastr.error("Action failed", "Error");
                        });
                })
                .catch((error) => console.log(error));
        } else {
            petition_patch("numberEdit", data)
                .then(({ data: result }) => {
                    toastr.success("Action completed", "Success");
                    setLoading(false);
                    resetForm()
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    toastr.error("Action failed", "Error");
                });
        }

    };



    useEffect(() => {

        if (activeSubmit) {

            setActiveSubmit(false)
            onSubmitForm()
        }

    }, [activeSubmit])



    const resetForm = () => {

        setModal(false);
        setReloadDataEdit(true);
        setForm({
            user: "",
            prefix: "",
            presence_id: "",
        });
        setSwitchOne(false);

    }



    return (

        <> {loadingTwo ? <div className="form-group d-flex row">
            <div className="col-12 ">
                <label htmlFor="">Assigned User</label>

                <Select
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            background: "transparent"
                        }),
                        option: (base, state) => ({
                            ...base,
                            color: "rgba(0,0,0,.8)"
                        }),
                    }}
                    isDisabled={disableAssigned}
                    options={listUsers}
                    placeholder={userSelected.label}
                    className="form-control control-select"
                    onChange={(e) => { setUserSelected(e); setUserOwner(e.value); console.log(e.value) }}
                />

            </div>

            <div className="col-12 pt-5">
                <Switch
                    uncheckedIcon={<Offsymbol />}
                    className="mr-1"
                    checkedIcon={<OnSymbol />}
                    onColor="#626ed4"
                    onChange={() => {
                        setSwitchOne(!switchOne);
                    }}
                    checked={switchOne}
                />
                <span> Add Caller ID Prefix to Incoming Calls</span>
            </div>
            {switchOne && (
                <div className="col-9 pt-2">
                    <label htmlFor="">Enter Prefix</label>
                    <input
                        value={form.prefix}
                        onChange={onChange}
                        className="form-control"
                        type="text"
                    />
                </div>
            )}
        </div> : <Loading />} </>
    )
}
