import React, {Component} from "react";

import {Row, Col, CardBody, Card, Alert, Container, Spinner} from "reactstrap";

// Redux
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";

// availity-reactstrap-validation
import {AvForm, AvField} from "availity-reactstrap-validation";

// actions
import {loginUser, apiError} from "../../store/actions";

// import images
import itpLogo from "../../assets/images/itpFiber/itp_white.png";


import ForgetPassword from "./ForgetPasswordPetition";
import AlertModal from "../../components/utils/Alert";
import * as qs from "query-string";

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonLogIn: false,
      email: "",
      success: false,
      success_dlg: false,
      error_dlg: false,
      dynamic_title: "success",
      dynamic_description: "check your email",
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // handleValidSubmit
  async handleValidSubmit(event, values) {
    this.setState({buttonLogIn: true});
    this.props.loginUser(values, this.props.history);
    setTimeout(this.resetLoading, 3000);
  }

  resetLoading = () => {
    this.setState({buttonLogIn: false});
  };

  componentDidMount() {
    this.setState({token: qs.parse(this.props.location.search).token});
    this.props.apiError("");
  }

  send() {
    this.setState({buttonLogIn: true});
    ForgetPassword(this.state.email)
      .then(({data: {result}}) => {
        this.setState({
          success: true,
          success_dlg: true,
          error_dlg: false,
          dynamic_title: "success",
          dynamic_description:
            "A link to reset your password has been sent to your email. Please check your inbox or spam folders.",
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          success: false,
          success_dlg: false,
          error_dlg: true,
          dynamic_title: "Error",
          dynamic_description: "Error",
        });
        this.setState({buttonLogIn: false});
      });
    setTimeout(this.resetLoading, 3000);
  }

  successFunction() {
    window.location.href = window.location.hostname + "/login";
  }

  errorFunction() {
    console.log("error");
  }

  render() {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container className="CenterBottom">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-soft-secundary">
                    <Row>
                      <Col className="col-12">
                        <div className="text-primary  p-4"></div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="logoITP">
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <img src={itpLogo} alt="" height="70" />
                          {/* </span> */}
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        // onValidSubmit={this.handleValidSubmit}
                      >
                        {this.props.error && this.props.error ? (
                          <Alert color="danger">{this.props.error}</Alert>
                        ) : null}

                        <div className="form-group">
                          <AvField
                            name="Email"
                            label="Email"
                            value=""
                            className="form-control"
                            placeholder="Enter Email"
                            type="email"
                            required
                            onChange={(e) =>
                              this.setState({email: e.target.value})
                            }
                          />
                        </div>

                        <div className="mt-3">
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            // type="submit"
                            disabled={
                              this.state.buttonLogIn === true ? true : false
                            }
                            onClick={() => {
                              this.send();
                            }}
                          >
                            {this.state.buttonLogIn === true ? (
                              <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null}

                            {this.state.buttonLogIn === true
                              ? "  Loading..."
                              : "Send"}
                          </button>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>© {new Date().getFullYear()} ITP.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <AlertModal
          success_dlg={this.state.success_dlg}
          error_dlg={this.state.error_dlg}
          dynamic_title={this.state.dynamic_title}
          dynamic_description={this.state.dynamic_description}
          resetValues={
            this.state.success === true
              ? this.successFunction
              : this.errorFunction
          }
        />
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const {error} = state.Login;
  return {error};
};

export default withRouter(
  connect(mapStatetoProps, {loginUser, apiError})(ForgetPasswordPage)
);
