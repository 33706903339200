import axios from "axios";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postFakeRegister = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postFakeLogin = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

// postForgetPwd
const postFakeForgetPwd = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

// Get refresh token
const getJwtUser = (url, data) => {
  axios.defaults.headers.common["Authorization"] = data;

  return axios
    .get(url)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

// Login Method
const postJwtProfile = (url, data) => {
  if (JSON.parse(localStorage.getItem("authUser")).access_token) {
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let newAuthUser = {};
    return axios
      .patch(url, data, {
        headers: {Authorization: "Bearer " + authUser.access_token},
      })
      .then((response) => {
        if (response.status === 200) {
          newAuthUser = {...authUser, prueba: "zzz"};
          newAuthUser.phone = response.data.result.phone;
          newAuthUser.fax = response.data.result.fax;
          newAuthUser.firstname = response.data.result.firstname;
          newAuthUser.lastname = response.data.result.lastname;
          newAuthUser.mobile = response.data.result.mobile;
          newAuthUser.phone = response.data.result.phone;
          newAuthUser.profile_image = response.data.result.profile_image;
          localStorage.removeItem("authUser");
          localStorage.setItem("authUser", JSON.stringify(newAuthUser));
        }
        if (response.status === 400 || response.status === 500)
          throw response.data;
        return response.data;
      })
      .catch((err) => {
        throw err.response.request;
      });
  }
};

const postFakeProfile = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data.result;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postJwtLogin = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

// postForgetPwd
const postJwtForgetPwd = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

export {
  getLoggedInUser,
  isUserAuthenticated,
  getJwtUser,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
};
