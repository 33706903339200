import React from "react";
import {
  Container,
  Card,
  CardBody,
  Table,
  Button,
  Row,
  Col,
  Input,
  Badge,
} from "reactstrap";
import DateRangeIcon from "@material-ui/icons/DateRange";
import SearchIcon from "@material-ui/icons/Search";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import SendIcon from "@material-ui/icons/Send";
import CallSplitIcon from "@material-ui/icons/CallSplit";
export default function Tools({setModalAdd, onChangeSearch, searchInput}) {
  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col sm="12" lg="4">
             
               <label
                      htmlFor="search"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "7px",
                        left: "20px",
                      }}
                    >
                      <SearchIcon />
                    </label>
              <Input
                id="search"
                name="search"
                value={searchInput}
                onChange={onChangeSearch}
                placeholder="Search Number"
                type="number"
                style={{paddingLeft: "40px", borderRadius: "3rem"}}
              />
            </Col>

            <Col
              className="text-center  mt-4 mt-lg-0 text-lg-right  "
              sm="12"
              lg="8"
            >
              <Button
                onClick={() => {
                  setModalAdd(true);
                }}
                color="primary"
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  
                <CallSplitIcon
                  style={{marginRight: "0.5rem", fontSize: "20px"}}
                  />
                New Ring Group
                  </div>
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
