import React, { useState, useEffect } from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";
import petition_get from "../../../../petitions/petition_get";
import petition_patch from "../../../../petitions/petition_patch";
import petition_post from "../../../../petitions/petition_post";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Badge,
  Spinner,
} from "reactstrap";

import Switch from "react-switch";
export default function ModalUtilityDisconnect({
  modal,
  data,
  setModal,
  disconnectNumbers,
  dataValues
}) {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const [loading, setLoading] = useState(false);

  const accept = (val) => {
    if (val.bulkActions) {
      const petitions = [];
      setLoading(true);
 
      const newData = []
       data.filter((element,i) => {
       const findOne = val.selectedCheckBox.find(elementTwo => elementTwo === element)
       if(findOne) newData.push(dataValues[i]) 
      })
      console.log(newData)
      newData.map(element => {
      
        petitions.push(petition_post("authorizeTrensfer", {
          id,
          data: { action: "accept", service_addon_id: element.service_addon_id },
        }))
      })

      Promise.all(petitions)
        .then(({ data: result }) => {
        setModal(false);
        setLoading(false);
      window.location.reload(true);
      })
        .catch((error) => {
          console.log(error);
        })
    }
    else {
      setLoading(true);
    petition_post("authorizeTrensfer", { id, data: { action: "accept",service_addon_id:val} })
      .then(({ data: result }) => {
        setModal(false);
        setLoading(false);
        window.location.reload(true);
      })
      .catch((error) => {
        console.log(error);
      });
    }
    
  };

  return (
    <Modal
      centered
      isOpen={modal === false ? false : true}
      className="info modal-md"
    >
      <ModalHeader className="bg-soft-primary">
        {" "}
        <Row>
          <Col xs="9">
            <div className="text-primary p-3">
              <h3 className="text-primary">Confirm Transfer</h3>
            </div>
          </Col>
          <Col xs="3" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <div className="p-3 text-center" style={{ fontSize: "1rem" }}>
          Confirm Message Transfer
        </div>
      </ModalBody>{" "}
      <ModalFooter>
        <Button
          outline
          disabled={loading}
          color="secondary"
          onClick={() => {
            setModal(false);
          }}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={loading}
          onClick={() => {
            accept(modal);
          }}
        >
          {loading && (
            <Spinner
              style={{ marginRight: "0.5em" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
}
