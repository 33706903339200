import React from "react";

import {Card, CardBody, Col, Row} from "reactstrap";
import styled from "styled-components";

const StepStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  margin-right: 10px;
  border-radius: 50%;
  border: 2px solid #556ee6;
  color: rgb(85, 110, 230);
  font-weight: 500;

  ${(props) =>
    props.step &&
    `
       background: #556ee6;
      border: 2px solid #556ee6;
      color: white;
   
  `}
`;

export default function Steps({step}) {
  return (
    <Card>
      <CardBody style={{display: "flex", padding: "0 10px"}}>
        {" "}
        <div
          className="bg-soft-primary"
          style={{
            display: "flex",
            flexWrap: "wrap",
            margin: "0 auto",
            justifyContent: "center",
            borderRadius: "0.5rem",
            padding: "0 0.7rem 1rem 0.7rem",
          }}
        >
          <Row
            color="primary"
            style={{
              width: "250px",
              display: "flex",
              alignItems: "center",
              color: "white",
              justifyContent: "center",
              paddingTop: "1rem",
            }}
          >
            <Col className="col-2">
              <StepStyle step={step > 0 ? true : false}>1</StepStyle>
            </Col>
            <Col className="col-10"> Add Numbers</Col>
          </Row>
          <Row
            color="primary"
            style={{
              width: "250px",
              display: "flex",
              alignItems: "center",
              color: "white",
              justifyContent: "center",
              paddingTop: "1rem",
            }}
          >
            <Col className="col-2">
              <StepStyle step={step > 1 ? true : false}>2</StepStyle>
            </Col>
            <Col className="col-10"> Add Information</Col>
          </Row>
          <Row
            color="primary"
            style={{
              width: "250px",
              display: "flex",
              alignItems: "center",
              color: "white",
              justifyContent: "center",
              paddingTop: "1rem",
            }}
          >
            <Col className="col-2">
              <StepStyle step={step > 2 ? true : false}>3</StepStyle>
            </Col>
            <Col className="col-10"> Confirm Order</Col>
          </Row>
          <Row
            color="primary"
            style={{
              width: "250px",
              display: "flex",
              alignItems: "center",
              color: "white",
              justifyContent: "center",
              paddingTop: "1rem",
            }}
          >
            <Col className="col-2">
              <StepStyle step={step > 3 ? true : false}>3</StepStyle>
            </Col>
            <Col className="col-10"> Order Confirmation</Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
}
