import React from 'react'
import CustomBreadCrum from '../../../../components/utils/CustomBreadCrum/CustomBreadCrum'
import NavTabCustom from '../../../../components/utils/NavTabCustom'
import TabAllNumbers from "./TabAllNumbers/TabAllNumbers"
import TabTransferNumbers from "./TabAllNumbers/TabTransferNumbers"
import OrderNewNumber from "../OrderNewNumbers/OrderNewNumbers"
export default function AllNumbers() {
  return (
    <div className="page-content">

      <CustomBreadCrum title="Phone Numbers" subtitle="All Numbers" />

      <NavTabCustom options={[{ title: "All Numbers", component: <TabAllNumbers /> }/* , { title: "Transfer Numbers ", component: <TabTransferNumbers /> }, { title: "Order New Number ", component: <OrderNewNumber /> } */]} />


    </div>
  )
}
