var states = [
  {id: "22", name: "new york", shortcode: "NY"},
  {id: "23", name: "puerto rico", shortcode: "PR"},
  {id: "24", name: "virgin islands", shortcode: "VI"},
  {id: "25", name: "massachusetts", shortcode: "MA"},
  {id: "26", name: "rhode island", shortcode: "RI"},
  {id: "27", name: "new hampshire", shortcode: "NH"},
  {id: "28", name: "maine", shortcode: "ME"},
  {id: "29", name: "vermont", shortcode: "VT"},
  {id: "30", name: "connecticut", shortcode: "CT"},
  {id: "31", name: "new jersey", shortcode: "NJ"},
  {id: "32", name: "us armed forces europe", shortcode: "AE"},
  {id: "33", name: "pennsylvania", shortcode: "PA"},
  {id: "34", name: "delaware", shortcode: "DE"},
  {id: "35", name: "washington, d.c.", shortcode: "DC"},
  {id: "36", name: "virginia", shortcode: "VA"},
  {id: "37", name: "maryland", shortcode: "MD"},
  {id: "38", name: "west virginia", shortcode: "WV"},
  {id: "39", name: "north carolina", shortcode: "NC"},
  {id: "40", name: "south carolina", shortcode: "SC"},
  {id: "41", name: "georgia", shortcode: "GA"},
  {id: "42", name: "florida", shortcode: "FL"},
  {id: "43", name: "alabama", shortcode: "AL"},
  {id: "44", name: "tennessee", shortcode: "TN"},
  {id: "45", name: "mississippi", shortcode: "MS"},
  {id: "46", name: "kentucky", shortcode: "KY"},
  {id: "47", name: "ohio", shortcode: "OH"},
  {id: "48", name: "indiana", shortcode: "IN"},
  {id: "49", name: "michigan", shortcode: "MI"},
  {id: "50", name: "iowa", shortcode: "IA"},
  {id: "51", name: "wisconsin", shortcode: "WI"},
  {id: "52", name: "minnesota", shortcode: "MN"},
  {id: "53", name: "south dakota", shortcode: "SD"},
  {id: "54", name: "north dakota", shortcode: "ND"},
  {id: "55", name: "montana", shortcode: "MT"},
  {id: "56", name: "illinois", shortcode: "IL"},
  {id: "57", name: "missouri", shortcode: "MO"},
  {id: "58", name: "kansas", shortcode: "KS"},
  {id: "59", name: "nebraska", shortcode: "NE"},
  {id: "60", name: "louisiana", shortcode: "LA"},
  {id: "61", name: "arkansas", shortcode: "AR"},
  {id: "62", name: "oklahoma", shortcode: "OK"},
  {id: "63", name: "texas", shortcode: "TX"},
  {id: "64", name: "colorado", shortcode: "CO"},
  {id: "65", name: "wyoming", shortcode: "WY"},
  {id: "66", name: "idaho", shortcode: "ID"},
  {id: "67", name: "utah", shortcode: "UT"},
  {id: "68", name: "arizona", shortcode: "AZ"},
  {id: "69", name: "new mexico", shortcode: "NM"},
  {id: "70", name: "nevada", shortcode: "NV"},
  {id: "71", name: "california", shortcode: "CA"},
  {id: "72", name: "us armed forces pacific", shortcode: "AP"},
  {id: "73", name: "hawaii", shortcode: "HI"},
  {id: "74", name: "american samoa", shortcode: "AS"},
  {id: "75", name: "guam", shortcode: "GU"},
  {id: "76", name: "palau", shortcode: "PW"},
  {id: "77", name: "federated states of micronesia", shortcode: "FM"},
  {id: "78", name: "northern mariana islands", shortcode: "MP"},
  {id: "79", name: "marshall islands", shortcode: "MH"},
  {id: "80", name: "oregon", shortcode: "OR"},
  {id: "81", name: "washington", shortcode: "WA"},
  {id: "82", name: "alaska", shortcode: "AK"},
];

export default states;
