const getChatDate = (value) => {

  if (!value) return `now`;
  const date = new Date(value);
  let newDate = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  );

  const time =
    Math.round(new Date().getTime() / 1000) - Math.round(newDate / 1000);

  let result = null;
  if (time < 60) {
    result = `${time} seconds ago`;
  } else if (time < 3600) {
    result = `${Math.round(time / 60)} minutes ago`;
  } else if (time < 86400) {
    result = `${Math.round(time / 3600)} hours ago`;
  } else if (time < 604800) {
    result = `${Math.round(time / 86400)} days ago`;
  } else if (time < 2592000) {
    result = `${Math.round(time / 604800)} weeks ago`;
  } else if (31104000) {
    result = `${Math.round(time / 2592000)} months ago`;
  } else {
    result = `${Math.round(time / 31104000)} years ago`;
  }
  return result;
};
const getOtherChatDate = (value) => {
  const date = new Date(value);
  let newDate = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  );

  const time =
    Math.round(new Date().getTime() / 1000) - Math.round(newDate / 1000);
  let result = null;
  if (time < 60) {
    result = `${time} seconds ago`;
  } else if (time < 3600) {
    result = `${Math.round(time / 60)} minutes ago`;
  } else if (time < 86400) {
    result = `${Math.round(time / 3600)} hours ago`;
  } else if (time < 604800) {
    result = `${Math.round(time / 86400)} days ago`;
  } else if (time < 2592000) {
    result = `${Math.round(time / 604800)} weeks ago`;
  } else if (31104000) {
    result = `${Math.round(time / 2592000)} months ago`;
  } else {
    result = `${Math.round(time / 31104000)} years ago`;
  }
  return result;
};

export { getChatDate, getOtherChatDate };
