import React, { useState, useEffect } from "react";
import verificationImg from "../../../../../assets/images/verification-img.png";


import NavTabCustom from "../../../../../components/utils/NavTabCustom";
import GroupDetails from "./ModalUtilityComponents/GroupDetails";
import GroupContact from "./ModalUtilityComponents/GroupContact";


import DeleteIcon from "@material-ui/icons/Delete";
import {
    Button,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    Card

} from "reactstrap";
import ButtonHover from "../../../../../components/utils/ButtonHover";


export default function ModalUtility({
    modal,
    loading,
    contacts,
    editGroups,
    setModalDelete

}) {

    const [form, setForm] = useState(null)
    useEffect(() => { if (modal) setForm(modal) }, [modal])

    return (
        <Card body>

            <div className="d-flex justify-content-between">
                <h3>Details</h3>
                <ButtonHover className="m-0" onClick={() => { setModalDelete(modal.pk) }}><DeleteIcon /></ButtonHover>
            </div>


            {modal && form && <NavTabCustom options={[{ title: "Group Details", component: <GroupDetails form={form} setForm={setForm} /> }, { title: "Contacts", component: <GroupContact form={form} setForm={setForm} contacts={contacts} /> }]} />}

            <div className="d-flex justify-content-end">

                <Button disabled={loading} color="primary" onClick={() => { editGroups(form, modal); }}>
                    {loading && (
                        <Spinner
                            style={{ marginRight: "0.5em" }}
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                    Save
                </Button>
            </div>
        </Card>
    );
}
