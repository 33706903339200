import React, { useState } from 'react'
import { Row, Col, Spinner, Button } from "reactstrap"
import Switch from "react-switch";
import petition_patch from "../../../../../../petitions/petition_patch"
import toastr from "toastr";
export default function DisableCallWaiting({ switchTwo, setSwitchTwo, handleSubmitCallWaiting }) {

    let [loading, setLoading] = useState(false)

    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    const Offsymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 10,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                <p style={{ marginTop: "1.5em" }}>No</p>
            </div>
        );
    };
    const OnSymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 10,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                <p style={{ marginTop: "1.5em" }}>Yes</p>
            </div>
        );
    };

    return (
        <Row>
            <Col className="col-12 pt-3">

                <label
                    htmlFor="time_window_start"
                    className="m-0 pr-4  w-100 mb-2"

                >
                    Enable Call Waiting

                </label>
                <Switch
                    uncheckedIcon={<Offsymbol />}
                    className="mr-1"
                    checkedIcon={<OnSymbol />}
                    onColor="#626ed4"
                    onChange={handleSubmitCallWaiting}
                    height={24}
                    width={48}
                    checked={switchTwo}
                />
            </Col>


        </Row>
    )
}
