import React, { useState, useEffect } from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";
import petition_get from "../../../../petitions/petition_get";
import petition_patch from "../../../../petitions/petition_patch";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Badge,
  Spinner,
} from "reactstrap";

import Switch from "react-switch";
export default function ModalUtilityDelete({
  modal,
  setModal,
  deleteNumber,
  loading,
}) {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  return (
    <Modal
      centered
      isOpen={modal === false ? false : true}
      className="info modal-md"
    >
      <ModalHeader className="bg-soft-primary">
        {" "}
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary">Delete Number</h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        {modal === "DefaultNumber"
          ? "The number you are trying to move is currently being used as the default caller ID for your system. Please go to General Settings and change the caller ID number before attempting to disconnect"
          : "Are you sure you want to delete this number?"}
      </ModalBody>{" "}
      <ModalFooter>
        <Button
          outline
          disabled={loading}
          color="secondary"
          onClick={() => {
            setModal(false);
          }}
        >
          Cancel
        </Button>
        {modal != "DefaultNumber" && (
          <Button
            color="primary"
            disabled={loading}
            onClick={() => {
              deleteNumber();
            }}
          >
            {loading && (
              <Spinner
                style={{ marginRight: "0.5em" }}
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Delete
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}
