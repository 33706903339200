import React, {Component} from "react";
//import { Row, Col, Card, CardBody, Button, Container } from "reactstrap";
//import logolight from "../../assets/images/logo-light.png";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

//Import Breadcrumb
//import Breadcrumbs from "../Common/Breadcrumb";
// import UiSweetAlert from "../../components/utils/UiSweetAlert";
import CurrencyFormat from "react-currency-format";

class UiSweetAlert extends Component {
  constructor(props) {
    super(props);
    this.onHide = this.props.onHide;
    this.childFunction = this.props.childFunction;
    this.title = this.props.title;
    this.state = {
      close_timer: false,
      timeralert: null,
      ajax_div: this.props.inputModal,
      inputValue: "",
      successRequest: null,
    };
  }

  stimer() {
    const getTimer = () => (
      <SweetAlert
        title={
          <span>
            HTML <small>AutoClose Timer</small>!
          </span>
        }
        onConfirm={() => this.hideTimeAlert()}
      >
        {" "}
        <span>Timer Will Expire after 3 Seconds.</span>
      </SweetAlert>
    );
    this.setState({timeralert: getTimer()});
    setTimeout(
      function () {
        this.setState({timeralert: null});
      }.bind(this),
      3000
    );
  }
  hideTimeAlert() {
    this.setState({timeralert: null});
  }

  edit() {
    this.childFunction(this.state.inputValue);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      this.setState({
        ajax_div: false,
        successRequest: this.props.successRequest,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.timeralert}
        {this.state.successRequest === true ? (
          <SweetAlert
            closeOnClickOutside={false}
            success
            // title={this.state.dynamic_title}
            title="Sucess"
            onConfirm={() => this.onHide()}
          >
            {/* {this.state.dynamic_description} */}
            Sucess
          </SweetAlert>
        ) : null}

        {this.state.successRequest === false ? (
          <SweetAlert
            closeOnClickOutside={false}
            error
            // title={this.state.dynamic_title}
            title="error"
            onConfirm={() => this.onHide()}
          >
            {/* {this.state.dynamic_description} */}
            Failed
          </SweetAlert>
        ) : null}

        {/* <Card>
              <CardBody>
                <Col xl="3" lg="4" sm="6" className="m-b-30"> */}

        {this.state.ajax_div ? (
          <SweetAlert
            defaultValue="uno"
            showCancel
            title={
              this.title === "profile_image" ? "Profile Image" : this.title
            }
            cancelBtnBsStyle="danger"
            confirmBtnBsStyle="success"
            closeOnClickOutside={false}
            onConfirm={() => {
              this.edit();
            }}
            // onCancel={() => this.setState({ ajax_div: false })}
            onCancel={() => this.onHide()}
          >
            {this.props.title === "Mobile" ||
            this.props.title === "Phone" ||
            this.props.title === "Fax" ? (
              <CurrencyFormat
                id="mobile"
                className="form-control"
                style={{
                  background: "#FFF",
                  borderColor: "#CCC",
                  color: "#343a40",
                }}
                onFocus={(e) => {
                  e.target.style.borderColor = "#50a5f1";
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#CCC";
                }}
                format="+1 (###) ###-####"
                mask="_"
                onValueChange={(values) => {
                  const {value} = values;
                  this.setState({inputValue: value});
                }}
                placeholder={this.props.title}
              />
            ) : this.props.title === "profile_image" ? (
              <input
                type="file"
                className="form-control-file"
                // placeholder="Enter Email"
                style={{
                  background: "#FFF",
                  borderColor: "#CCC",
                  color: "#343a40",
                }}
                onFocus={(e) => {
                  e.target.style.borderColor = "#50a5f1";
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#CCC";
                }}
                onChange={(e) => this.setState({inputValue: e.target.value})}
                placeholder={this.props.title}
              />
            ) : (
              <input
                type="text"
                className="form-control"
                // placeholder="Enter Email"
                style={{
                  background: "#FFF",
                  borderColor: "#CCC",
                  color: "#343a40",
                }}
                onFocus={(e) => {
                  e.target.style.borderColor = "#50a5f1";
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#CCC";
                }}
                onChange={(e) => this.setState({inputValue: e.target.value})}
                placeholder={this.props.title}
              />
            )}
          </SweetAlert>
        ) : null}
        {/* </Col>
              </CardBody>
            </Card> */}
      </React.Fragment>
    );
  }
}

export default UiSweetAlert;
