import React from "react";
import {ConfirmOrderStyled} from "../Style/Style";
import {Link} from "react-router-dom";
export default function ConfirmOrder({resultOrder}) {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  return (
    <ConfirmOrderStyled>
      {resultOrder ? (
        <div className="order-confirm">
          order created successfully <br />{" "}
          <div className="container-back">
            {" "}
            <Link to={`/ListAllNumbersPBX?id=${id}`} className="btn-back">
              Back to List All Numbers
            </Link>
          </div>
        </div>
      ) : (
        <div className="order-fail">the creation of the order failed</div>
      )}
    </ConfirmOrderStyled>
  );
}
