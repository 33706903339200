import React, {useState, useEffect} from "react";
import {Container, Card, Button, CardBody} from "reactstrap";
import petition_get from "../../../petitions/petition_get";
import petition_post from "../../../petitions/petition_post";
import NavbarTitle from "./NavbarTitle/NavbarTitle";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import Steps from "./Steps/Steps";
import Filter from "./Filters/Filter";

import FilterUbication from "./Filters/FilterUbication";
import ListNumbers from "./ListNumbers/ListNumbers";
import CardOrder from "./CardOrder/CardOrder";
import PeriodCard from "./PeriodCard/PeriodCard";
import {useHistory} from "react-router-dom";
import ConfirmOrder from "./ConfirOrder/ConfirmOrder";

import toastr from "toastr" 
import {Spinner} from "reactstrap"
import {connect} from "react-redux";

function AddNumbersPBX({pricePerDid}) {
    toastr.options = {
    showDuration: 300,
  };
  const history = useHistory();
  //Get Id Url
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  

  //State

  //Step
  const [step, setStep] = useState(1);

  //Result Of Order
  const [resultOrder, setResultOrder] = useState(null);

  //CheckBox Confirm and Error
  const [checkbox, setCheckBox] = useState(false);
  const [errorChecked, setErrorChecked] = useState(null);

  //Filter type of numbers
  const [filterNumbers, setFilterNumbers] = useState({
    country: "selected",
    category: "selected",
    type: "",
  });

  // Loading
  const [loading, setLoading] = useState(false);
const [loadingPetition,setLoadingPetition] = useState(false)
  //Filter City/State
  const [cityState, setCityState] = useState({
    state: "",
    city: "",
  });

  //Filter Npa
  const [npa, setNpa] = useState({
    code: "",
  });

 //Filter NPANXX
  const [npaNxx, setNpaNxx] = useState({
    npa: "",
    nxx: "",
  });

  //Filter Prefix
  const [prefix, setPrefix] = useState({
    code: "selected",
  }); 
  
  
   //List of Numbers
  const [listOfNumbers, setListOfNumbers] = useState(null);
  const [errorNumbers, setErrorNumbers] = useState(null);
 const [switchNumbers, setSwitchNumbers] = useState(false);




  //List of Numbers Selected
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [activeSelected, setActiveSelected] = useState([]);
  const [resetActive, setResetActive] = useState(false);
  /*******/

 useEffect(
    (params) => {
      if (listOfNumbers && switchNumbers) {
        const newListOfNumbers = listOfNumbers.filter((element) => {
          const findOne = activeSelected.find(
            (elementTwo) => elementTwo.number === element
          );
          if (!findOne) return element;
        });
        setListOfNumbers(newListOfNumbers);
        setSwitchNumbers(false);
      }
    },
    [listOfNumbers]
  );


  const resetAll = (value) => {
    setFilterNumbers({
      country: "selected",
      category: "",
      type: "",
    });
    setCityState({
      state: "",
      city: "",
    });
    setNpa({
      code: "",
    });
    setNpaNxx({
      npa: "",
      nxx: "",
    });
    if (!value) {
   setListOfNumbers(null);
    setSelectedNumbers([]);
    setActiveSelected([]);
    setResetActive(false);
}
   
  };
 
  // Search Numbers in BD
  const onSubmitNumbers = async () => {
    setSwitchNumbers(true)

    setLoading("submit");
    if (filterNumbers.country !== "selected") {
      if (filterNumbers.category === "local") {
        switch (filterNumbers.type) {
          case "NPA":
            if (npa.code.trim() === "") {
              setLoading(false);
              return setErrorNumbers("Codes Required");
            }

            await petition_get("numbers", {
              id: id,
              type: filterNumbers.type,
              code: npa.code,
            })
              .then(({data: result}) => {
                if (!result.result.SearchResult) {
                  setListOfNumbers(null);
                  setErrorNumbers("Numbers not available");
                } else {
                  setListOfNumbers(
                    result.result.SearchResult.TelephoneNumberList
                      .TelephoneNumber
                  );
                  setResetActive(true);
                  setErrorNumbers(null);
                  setStep(step + 1);
                }
              })
              .catch((error) => {
                setListOfNumbers(null);
                setErrorNumbers("Numbers not available");
              });

            break;

          case "City/State":
            if (cityState.city === "" || cityState.state === "") {
              setLoading(false);
              return setErrorNumbers("Options Required");
            }

            await petition_get("numbers", {
              id: id,
              type: filterNumbers.type,
              code: cityState,
            })
              .then(({data: result}) => {
                if (!result.result.SearchResult) {
                  setListOfNumbers(null);
                  setErrorNumbers("Numbers not available");
                } else {
                  setListOfNumbers(
                    result.result.SearchResult.TelephoneNumberList
                      .TelephoneNumber
                  );
                  setResetActive(true);
                  setErrorNumbers(null);
                  setStep(step + 1);
                }
              })
              .catch((error) => {
                setListOfNumbers(null);
                setErrorNumbers("Numbers not available");
              });

            break;

          case "NPANXX":
            if (npaNxx.npa === "" || npaNxx.nxx === "") {
              setLoading(false);
              return setErrorNumbers("Codes Required");
            }

            await petition_get("numbers", {
              id: id,
              type: filterNumbers.type,
              code: npaNxx,
            })
              .then(({data: result}) => {
                if (
                  !result.result.SearchResult ||
                  result.result.SearchResult.Error
                ) {
                  setListOfNumbers(null);
                  setErrorNumbers("Numbers not available");
                } else {
                  setListOfNumbers(
                    result.result.SearchResult.TelephoneNumberList
                      .TelephoneNumber
                  );
                  setResetActive(true);
                  setErrorNumbers(null);
                  setStep(step + 1);
                }
              })
              .catch((error) => {
                setListOfNumbers(null);
                setErrorNumbers("Numbers not available");
              });

            break;

          default:
            setErrorNumbers("Please Select Number Type");
        }
      } else {
        if (prefix.code === "selected") {
          setLoading(false);
          return setErrorNumbers("Codes Required");
        }
        await petition_get("prefix", {
          id: id,
          code: prefix.code,
        })
          .then(({data: result}) => {
            if (
              !result.result.SearchResult ||
              result.result.SearchResult.Error
            ) {
              setListOfNumbers(null);
              setErrorNumbers("Numbers not available");
            } else {
              setListOfNumbers(
                result.result.SearchResult.TelephoneNumberList.TelephoneNumber
              );
              setResetActive(true);
              setErrorNumbers(null);
              /* scroller.scrollTo("numbers", {
                    duration: 500,
                    smooth: true,
                  }); */
              setStep(step + 1);
            }
          })
          .catch((error) => {
            setListOfNumbers(null);
            setErrorNumbers("Numbers not available");
          });
      }

      setLoading(false);
    } else {
      setErrorNumbers("Please Select Country");
    }
  };

  //Functions of Steps
  const onClickNext = () => {
    if (step < 4) {
      if (step === 3) {
        if (!checkbox) {
          return setErrorChecked("Accept Terms");
        }
        let numbers = {numbers: []};
        activeSelected.map((num) => {
          numbers.numbers.push({did: num.number});
        });
        setLoadingPetition(true)

        petition_post("addNumberLegacy", {id: id, data: numbers})
            .then(({ data: result }) => {
             setResultOrder(true);
            setLoadingPetition(false)
        toastr.success("Action completed", "Success");
            setStep(step + 1); 
          })
          .catch((error) => {
        toastr.error("Action failed", "Error");
            setLoadingPetition(false)
            setResultOrder(false);
            setStep(step + 1);
          });
      } else {
        setStep(step + 1);
      }
    }
  };
  const onClickCancel = () => {
    if (step > 1) {
       if (step === 2) {
        setResetActive(false)
        resetAll()
      }
      setStep(step - 1);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            className="d-flex"
            style={{alignItems: "center", paddingBottom: "2.5rem"}}
          >
            <h3 style={{margin: "0"}}>All Numbers</h3>
            <span>
              <DoubleArrowIcon className="icon-arrow" />
            </span>
            <h4
              style={{
                color: "#a6b0cf",
                fontWeight: "400",
                margin: "0",
                fontSize: "15px",
                paddingTop: "7px",
              }}
            >
              New Number
            </h4>
          </div>

          <Card>
            <CardBody style={{padding: "0"}}>
              <NavbarTitle />
              <hr style={{marginTop: "0", height: "3px"}} />
              <Steps step={step} />
              {step === 1 && (
                <>
                  {" "}
                  <Filter
                    setFilterNumbers={setFilterNumbers}
                    filterNumbers={filterNumbers}
                    resetActive={resetActive}
                    setErrorNumbers={setErrorNumbers}
                  />
                  <FilterUbication
                    prefix={prefix}
                    setPrefix={setPrefix}
                    loading={loading}
                    setLoading={setLoading}
                    cityState={cityState}
                    npa={npa}
                    npaNxx={npaNxx}
                    setNpaNxx={setNpaNxx}
                    setCityState={setCityState}
                    setNpa={setNpa}
                    filterNumbers={filterNumbers}
                    onSubmitNumbers={onSubmitNumbers}
                    errorNumbers={errorNumbers}
                    resetActive={resetActive}
                    resetAll={resetAll}
                    setErrorNumbers={setErrorNumbers}
                  />
                </>
              )}
              {step === 2 && (
                <>
                  <ListNumbers
                    pricePerDid={pricePerDid}
                    selectedNumbers={selectedNumbers}
                    setSelectedNumbers={setSelectedNumbers}
                    loading={loading}
                    listOfNumbers={listOfNumbers}
                    cityState={cityState}
                    npa={npa}
                    npaNxx={npaNxx}
                    prefix={prefix}
                    filterNumbers={filterNumbers}
                    setActiveSelected={setActiveSelected}
                    activeSelected={activeSelected}
                  />
                  <CardOrder
                    pricePerDid={pricePerDid}
                    activeSelected={activeSelected}
                    setActiveSelected={setActiveSelected}
                    setSelectedNumbers={setSelectedNumbers}
                    selectedNumbers={selectedNumbers}
                  />
                  <div className="text-center mt-3 mb-3">
                    {" "}
                    <Button
                      style={{width: "40%"}}
                      color="primary"
                      onClick={() => {
                        if (resetActive) {
                          resetAll();
                          setResetActive(false)
                          setStep(1);
                        }
                      }}
                    >
                      Reset Form
                    </Button>
                  </div>

                  {activeSelected.length !== 0  &&    <div className="text-center mt-3 mb-3">
                    {" "}
                    <Button
                      style={{width: "40%"}}
                      color="primary"
                      onClick={() => {
                        if (resetActive) {
                          setStep(1);
                          setResetActive(false)
                           resetAll(true)
                        }
                      }}
                    >
                     Add More Numbers
                    </Button>
                  </div>}
                      
                </>
              )}
              {step === 3 && (
                <>
                  <PeriodCard
                    pricePerDid={pricePerDid}
                    errorChecked={errorChecked}
                    setErrorChecked={setErrorChecked}
                    setCheckBox={setCheckBox}
                    activeSelected={activeSelected}
                  />
                </>
              )}
              {step === 4 && <ConfirmOrder resultOrder={resultOrder} />}{" "}
            </CardBody>
          </Card>

          {activeSelected.length !== 0 && step < 4 && (
            <div className="text-right mb-3">
              <Button
                disabled={loadingPetition}
                outline
                color="secondary"
                onClick={onClickCancel}
                style={{marginRight: "20px"}}
              >
                Cancel
              </Button>
              <Button disabled={loadingPetition} color="primary" onClick={onClickNext}>
                    {loadingPetition && 
                  <Spinner
                
              className="spinner-submit ml-2"
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          }
                Next
              </Button>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {
    pricePerDid: state.ITPVoice.didPriceLegacy,
  };
};


export default connect(mapStateToProps) (AddNumbersPBX)