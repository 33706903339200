import {takeEvery, fork, put, all, call} from "redux-saga/effects";

// Login Redux States
import {LOGIN_USER, LOGOUT_USER, TOKEN_USER, CHANGE_ADMIN} from "./actionTypes";
import {loginSuccess, logoutUserSuccess, apiError} from "./actions";

//Include Both Helper File with needed methods
import {getFirebaseBackend} from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
  getJwtUser,
} from "../../../helpers/fakebackend_helper";

import petition_get from "../../../pages/petitions/petition_get";

const fireBaseBackend = getFirebaseBackend();
const json = require("../../../config.json");
const prod = json.prod;

const login = prod
  ? "https://api.itpscorp.com/portal/crm/auth/login"
  : "https://api.itpscorp.com/dev/portal/crm/auth/login";
const refresh = prod
  ? "https://api.itpscorp.com/portal/crm/auth/refresh"
  : "https://api.itpscorp.com/dev/portal/crm/auth/refresh";

function* loginUser({payload: {user, history, action}}) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      );
      yield put(loginSuccess(response));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, login, {
        username: user.email,
        password: user.password,
      });
      localStorage.setItem("authUser", JSON.stringify(response.result));
      yield put(loginSuccess(response.result));
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(
        postFakeLogin,
        "https://api.itpscorp.com/auth/login",
        {username: user.email, password: user.password}
      );
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history.push("/Home");
  } catch (error) {
    console.log("asas");
    action();
    yield put(apiError(error));
  }
}

function* logoutUser({payload: {history}}) {
  try {
    localStorage.removeItem("authUser");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    window.location.href = "/login";
  } catch (error) {
    yield put(apiError(error));
  }
}

function* tokenUser({payload: {token, history}}) {
  try {
    const response = yield call(getJwtUser, refresh, token);
    localStorage.setItem("authUser", JSON.stringify(response.result));
    yield put(loginSuccess(response.result));
    const params = new URL(document.location).searchParams;
    const redirect = params.get("redirect");
    if (redirect) history.push(`/${redirect}`);
    else history.push("/Home");
  } catch (error) {
    console.log(error);
  }
}
function* changeAdmin({payload}) {}

export function* watchChangeAdmin() {
  yield takeEvery(CHANGE_ADMIN, changeAdmin);
}
export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export function* watchTokenUser() {
  yield takeEvery(TOKEN_USER, tokenUser);
}

function* authSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLogout),
    fork(watchTokenUser),
    fork(watchChangeAdmin),
  ]);
}

export default authSaga;
