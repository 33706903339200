import axios from "axios";

export default function FromCrmPetition(val) {         
  const json = require("../../config.json");
  const urlBase = json.urlBase;
  var url;

    url = urlBase + "/auth/refresh";
    var config = {
      headers: {
        Authorization: "Bearer " + val.replace(/['"]+/g, ""),
      },
    };
    return axios.get(url, config);
}