import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import classnames from "classnames";
import {
  Container,
  Card,
  Row,
  Col,
  Label,
  Button,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import toastr from "toastr";
import petition_get from "../../../petitions/petition_get";
import petition_patch from "../../../petitions/petition_patch";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

import SelectSearch from 'react-select-search';

import { findAllByTestId } from "@testing-library/react";
import Switch from "react-switch";
import ReactTooltip from 'react-tooltip';
import InfoIcon from '@material-ui/icons/Info';


import FeacturesCodes from "./OptionsTab/FeacturesCodes"

// import { Autocomplete } from "@material-ui/lab/Autocomplete";

export default function GeneralSettings() {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const [customActiveTab, setCustomActiveTab] = useState("1");
  const [accountDetails, setAccountDetails] = useState(null);
  const [data, setData] = useState([]);
  const [allTimezone, setAllTimezone] = useState([]);
  const [timeZone, setTimeZone] = useState(null);
  const [music, setMusic] = useState(null);
  const [change, setChange] = useState(false);
  const [max_number_of_users, setMax_number_of_users] = useState("");
  const [current_number_of_users, setCurrent_number_of_users] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const [promise, setPromise] = useState(false);
  const [loadingOne, setLoadingOne] = useState(false);
  const [loadingTwo, setLoadingTwo] = useState(false);
  const [loadingThree, setLoadingThree] = useState(false);
  const [defaultCallerId, setDefaultCallerId] = useState(null);
  const [defaultNumber, setDefaultNumber] = useState({});
  const [defaultName, setDefaultName] = useState(null);
  const [allNumbers, setAllNumbers] = useState(null);
  const [maxConnect, setMaxConnect] = useState("3")
  const [switchOne, setSwitchOne] = useState(true)
  const [switchTwo, setSwitchTwo] = useState(false)
  const [switchThree, setSwitchThree] = useState(false)
  const [loadingMaxConnect, setLoadingMaxConnect] = useState(false)
  const [loadingRecordCall, setLoadingRecordCall] = useState(false)


  const fuzzySearch = (options) => {


    return (value) => {
      if (!value.length) {
        return options;
      }

      let newArrayOptions = options.filter(element => element.value.includes(value))

      return newArrayOptions;
    };
  }

  toastr.options = {
    showDuration: 300,
  };

  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  }

  const onChangeFormNumber = (e) => {

    setDefaultNumber(e);
  };

  const onChangeFormName = (e) => {
    setDefaultName(e.target.value);
  };

  useEffect(() => {
    let start_key = 0;
    petition_get("accountDetails", { id: id })
      .then(({ data: result }) => {
        setAccountDetails(result.result.data);
        setTimeZone(result.result.data.timezone);
        setMax_number_of_users(result.result.data.max_number_of_users);
        setCurrent_number_of_users(result.result.data.current_number_of_users);
        setMusic(result.result.data.music_on_hold.media_id);
        if (result.result.data.max_connect_failures === -1) {
          setSwitchOne(false)
          setMaxConnect("0")
        }
        else if (result.result.data.max_connect_failures) {
          setSwitchOne(true)
          setMaxConnect(result.result.data.max_connect_failures.toString())
        }

        /*   if (result.result.data.call_recording) {
            setSwitchTwo(result.result.data.call_recording.account.any.onnet.enabled)
            setSwitchThree(result.result.data.call_recording.account.any.offnet.enabled)
          } */

        setLoadingOne(true);
      })
      .catch((error) => {
        console.log(error);
      });
    petition_get("musicOnHoldMediaAll", {
      id: id,
      search: "pagination=false0",
    })
      .then(({ data: result }) => {
        setData(result.result);
        setLoadingTwo(true);
      })
      .catch((error) => {
        console.log(error);
      });
    petition_get("timeZome")
      .then(({ data: result }) => {
        setAllTimezone(result.result);
        setLoadingThree(true);
      })
      .catch((error) => {
        console.log(error);
      });
    petition_get("defaultCallerID", {
      id: id,
    })
      .then(({ data: result }) => {
        setDefaultCallerId(result.result.data.caller_id || true);
        setDefaultNumber(result.result.data.caller_id ? { name: result.result.data.caller_id.external.number, value: result.result.data.caller_id.external.number } : "");
        setDefaultName(result.result.data.caller_id ? result.result.data.caller_id.external.name : "");
      })
      .catch((error) => {
        console.log(error);
      });

    petition_get("view", { id: id })
      .then(({ data: result }) => {
        let preAllData = [];
        for (const property in result.result.numbers) {
          preAllData.push({ name: property.slice(2), value: property.slice(2) });
        }
        setAllNumbers(preAllData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [promise]);

  const save = () => {
    let promises = [];
    setDisabledButton(true);
    let dataSend = {
      timezone: timeZone,
      music_on_hold: { media_id: music },
    };
    if (change) {
      if (max_number_of_users != accountDetails.max_number_of_users) {
        let dataChange = {
          action:
            max_number_of_users > accountDetails.max_number_of_users
              ? "upgrade"
              : "downgrade",
          number_of_users: parseInt(max_number_of_users),
        };
        promises.push(
          petition_patch("changeAccount", {
            id: id,
            data: dataChange,
          })
        );
      }
    }
    promises.push(
      petition_patch("updateAccountSettings", {
        id: id,
        data: dataSend,
      })
    );
    Promise.all(promises)
      .then((values) => {
        setChange(false);
        toastr.success("Success", "Success");
        setDisabledButton(false);
      })
      .catch((reason) => {
        console.log(reason);
        toastr.error("Error", "Error");
        setDisabledButton(false);
      });
  };

  const saveCallerID = () => {
    setDisabledButton(true);
    let dataNumber = {
      caller_id: {
        external: {
          name: defaultName,
          number: defaultNumber.value,
        },
      },
    };
    petition_patch("updateAccountSettings", {
      id: id,
      data: dataNumber,
    })
      .then((values) => {
        setChange(false);
        toastr.success("Success", "Success");
        setDisabledButton(false);
      })
      .catch((reason) => {
        console.log(reason);
        toastr.error("Error", "Error");
        setDisabledButton(false);
      });
  };


  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        <p style={{ marginTop: "1.5em" }}>No</p>
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        <p style={{ marginTop: "1.5em" }}>Yes</p>
      </div>
    );
  };


  const updateMaxConnect = () => {
    setLoadingMaxConnect(true)
    petition_patch("updateAccountSettings", {
      id: id,
      data: { max_connect_failures: !switchOne ? -1 : parseInt(maxConnect) },
    })
      .then((values) => {
        setLoadingMaxConnect(false)
        toastr.success("Success", "Success");
      })
      .catch((reason) => {
        setLoadingMaxConnect(false)
        toastr.error("Error", "Error");
      });
  }


  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div
            className="d-flex"
            style={{ alignItems: "center", paddingBottom: "2.5rem" }}
          >
            <h3 style={{ margin: "0" }}>Phone System</h3>
            <span>
              <DoubleArrowIcon className="icon-arrow" />
            </span>
            <h4
              style={{
                color: "#a6b0cf",
                fontWeight: "400",
                margin: "0",
                fontSize: "15px",
                paddingTop: "7px",
              }}
            >
              General Settings
            </h4>
          </div>
        </Container>

        {loadingOne &&
          loadingTwo &&
          loadingThree &&
          defaultCallerId &&
          allNumbers ? (
          <Card body>
            <Nav
              tabs
              className="nav-tabs-custom  flex-column flex-md-row  text-center  "
            >
              <NavItem className="pt-3 pt-md-0">
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "1",
                  })}
                  onClick={() => {
                    toggleCustom("1");
                  }}
                >
                  <span>General</span>
                </NavLink>
              </NavItem>
              <NavItem className="pt-3 pt-md-0">
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "2",
                  })}
                  onClick={() => {
                    toggleCustom("2");
                  }}
                >
                  <span>Caller ID</span>
                </NavLink>
              </NavItem>
              <NavItem className="pt-3 pt-md-0">
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "3",
                  })}
                  onClick={() => {
                    toggleCustom("3");
                  }}
                >
                  <span>Call Center Settings</span>
                </NavLink>
              </NavItem>
              {/* <NavItem className="pt-3 pt-md-0">
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "4",
                  })}
                  onClick={() => {
                    toggleCustom("4");
                  }}
                >
                  <span>Call Recording</span>
                </NavLink>
              </NavItem> */}
              <NavItem className="pt-3 pt-md-0">
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "5",
                  })}
                  onClick={() => {
                    toggleCustom("5");
                  }}
                >
                  <span>Feature Codes</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={customActiveTab}>
              <TabPane tabId="1" className="p-3">
                <Row>
                  <Col
                    className="col-12 row d-flex mt-5"
                    style={{ alignItems: "center" }}
                  >
                    <Col className="col-12 col-md-3 ">
                      <Label style={{ margin: "0" }}>Time Zone</Label>
                    </Col>
                    <Col className="mt-3 mt-md-0 col-12 col-md-6  col-lg-5">
                      {" "}
                      <select
                        className="form-control"
                        name=""
                        id=""
                        value={timeZone}
                        onChange={(e) => {
                          setTimeZone(e.target.value);
                        }}
                      >
                        {" "}
                        {/* <option value="">This is a opcion 1</option>{" "} */}
                        {allTimezone.map((element, i) => (
                          <option key={i} value={element}>
                            {element}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Col>
                  <Col
                    className="  col-12 row d-flex mt-4"
                    style={{ alignItems: "center" }}
                  >
                    <Col className="col-12 col-md-3">
                      <Label style={{ margin: "0" }}>SIP Realm / Domain:</Label>{" "}
                    </Col>
                    <Col className="mt-3 mt-md-0 col-12 col-md-6  col-lg-5">
                      <p className="m-0">
                        {/* SeRMNB9qQIGF@e107a272.dev.sip.itpvoice.net */}
                        {accountDetails.realm}
                      </p>
                    </Col>
                  </Col>{" "}
                  <Col
                    className=" col-12 row  d-flex mt-4"
                    style={{ alignItems: "center" }}
                  >
                    <Col className="col-12 col-md-3">
                      {" "}
                      <Label style={{ margin: "0" }}>
                        Max Number of Users
                      </Label>{" "}
                    </Col>
                    <Col className="mt-3 mt-md-0 col-12 col-md-6 d-flex col-lg-5">
                      <input
                        style={{ width: "40px" }}
                        type="text"
                        className="form-control"
                        // placeholder="10"
                        value={max_number_of_users}
                        onChange={(e) => {
                          setMax_number_of_users(e.target.value);
                        }}
                        disabled={!change}
                      />
                      <Button
                        className="btn btn-primary ml-4"
                        onClick={() => {
                          setChange(!change);
                          if (change) {
                            setMax_number_of_users(
                              accountDetails.max_number_of_users
                            );
                          }
                        }}
                        color={!change ? "primary" : "danger"}
                      >
                        {!change ? "Change" : "Cancel"}
                      </Button>
                    </Col>
                  </Col>{" "}
                  <Col
                    className=" col-12 row d-flex mt-4"
                    style={{ alignItems: "center" }}
                  >
                    <Col className="col-12 col-md-3">
                      <Label style={{ margin: "0" }}>
                        Current Number of Users
                      </Label>{" "}
                    </Col>
                    <Col className="mt-3 mt-md-0 col-12 col-md-6 d-flex col-lg-5">
                      <input
                        style={{ width: "40px" }}
                        type="text"
                        className="form-control"
                        value={current_number_of_users}
                        onChange={(e) =>
                          setCurrent_number_of_users(e.target.value)
                        }
                        disabled={true}
                      />
                    </Col>
                  </Col>{" "}
                  <Col
                    className=" col-12 row d-flex mt-4"
                    style={{ alignItems: "center" }}
                  >
                    <Col className="col-12 col-md-3">
                      <Label style={{ margin: "0" }}>
                        Default Music on Hold:
                      </Label>{" "}
                    </Col>
                    <Col className="mt-3 mt-md-0 col-12 col-md-6 d-flex col-lg-5">
                      <select
                        className="form-control"
                        name=""
                        id=""
                        value={music}
                        onChange={(e) => setMusic(e.target.value)}
                      >
                        {/* value={accountDetails.music_on_hold.media_id} */}{" "}
                        {data.map((element, i) => (
                          <option key={i} value={element.id}>
                            {element.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Col>
                  <Col className="col-12  text-right mt-4">
                    <button
                      className="btn btn-primary ml-4 pl-4 pr-4 font-size-16"
                      onClick={() => save()}
                      disabled={disabledButton}
                    >
                      {disabledButton && (
                        <Spinner
                          style={{ marginRight: "0.5em" }}
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      Save
                    </button>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
            <TabContent activeTab={customActiveTab}>
              <TabPane tabId="2" className="p-3">
                <Row>
                  <Col
                    className="col-12 row d-flex mt-5"
                    style={{ alignItems: "center" }}
                  >
                    <Col className="col-12 col-md-3 ">
                      <Label style={{ margin: "0" }}>Default Name:</Label>
                    </Col>
                    <Col className="mt-3 mt-md-0 col-12 col-md-6  col-lg-5">
                      <input
                        type="text"
                        className="form-control"
                        value={defaultName}
                        onChange={onChangeFormName}
                      />
                    </Col>
                  </Col>
                  <Col
                    className="col-12 row d-flex mt-5"
                    style={{ alignItems: "center" }}
                  >
                    <Col className="col-12 col-md-3 ">
                      <Label style={{ margin: "0" }}>Default Number:</Label>
                    </Col>

                    <Col className="mt-3 mt-md-0 col-12 col-md-6  col-lg-5">

                      <SelectSearch placeholder="Start Typing..." search={true} onChange={(e) => { setDefaultNumber({ value: e }) }} options={allNumbers} filterOptions={fuzzySearch} value={defaultNumber !== "" ? defaultNumber.value : ""} name="language" />
                    </Col>
                  </Col>
                  <Col className="col-12  text-right mt-4">
                    <button
                      className="btn btn-primary ml-4 pl-4 pr-4 font-size-16"
                      onClick={() => saveCallerID()}
                      disabled={!defaultNumber}
                    >
                      {disabledButton && (
                        <Spinner
                          style={{ marginRight: "0.5em" }}
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      Save
                    </button>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
            <TabContent activeTab={customActiveTab}>
              <TabPane tabId="3" className="p-3">
                <Row>
                  <Col className="col-12 pt-3">

                    <label
                      htmlFor="time_window_start"
                      className="m-0 pr-4  w-100 mb-2"

                    >
                      Log Users Out of Queues Automatically
                      <InfoIcon data-tip data-for='text2' style={{ paddingLeft: "0.5rem", fontSize: "21px" }} />
                    </label>
                    <ReactTooltip id="text2" >
                      <div className="text-left" style={{ fontSize: "10px" }}>

                        Enable this option if you wish to remove Users
                        <br />
                        from a Queue when they fail to answer a phone a
                        <br />
                        certain number of times specified below.
                      </div>
                    </ReactTooltip>
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      className="mr-1"
                      checkedIcon={<OnSymbol />}
                      onColor="#626ed4"
                      onChange={() => {
                        setSwitchOne(!switchOne);
                      }}
                      height={24}
                      width={48}
                      checked={switchOne}
                    />
                  </Col>
                  {switchOne && <Col className="col-12 col-md-6 pt-3">
                    <label
                      htmlFor="start_date"
                    >
                      Number of Failed Calls To Log Out
                    </label>
                    <div className="d-flex">
                      <input
                        onChange={(e) => { console.log(e.target.value); setMaxConnect(e.target.value) }}
                        value={maxConnect}
                        className="form-control"
                        type="number"

                      />
                    </div>
                  </Col>}
                  <Col className="col-12  text-right mt-4">
                    <button
                      className="btn btn-primary ml-4 pl-4 pr-4 font-size-16"
                      onClick={() => updateMaxConnect()}
                      disabled={loadingMaxConnect}
                    >
                      {loadingMaxConnect && (
                        <Spinner
                          style={{ marginRight: "0.5em" }}
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      Save
                    </button>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
            <TabContent activeTab={customActiveTab}>
              {/*  <TabPane tabId="4" className="p-3">
                <Row>
                  <Col className="col-12 pt-3">

                    <label
                      htmlFor="time_window_start"
                      className="m-0 pr-4  w-100 mb-2"

                    >
                      Record All Internal Calls

                    </label>
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      className="mr-1"
                      checkedIcon={<OnSymbol />}
                      onColor="#626ed4"
                      onChange={() => {
                        setSwitchTwo(!switchTwo);
                      }}
                      height={24}
                      width={48}
                      checked={switchTwo}
                    />
                  </Col>
                  <Col className="col-12 pt-3">

                    <label
                      htmlFor="time_window_start"
                      className="m-0 pr-4  w-100 mb-2"

                    >
                      Record All External Calls

                    </label>
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      className="mr-1"
                      checkedIcon={<OnSymbol />}
                      onColor="#626ed4"
                      onChange={() => {
                        setSwitchThree(!switchThree);
                      }}
                      height={24}
                      width={48}
                      checked={switchThree}
                    />
                  </Col>
                  <Col className="col-12  text-right mt-4">
                    <button
                      className="btn btn-primary ml-4 pl-4 pr-4 font-size-16"
                      onClick={() => updateRecordCall()}
                      disabled={loadingRecordCall}
                    >
                      {loadingRecordCall && (
                        <Spinner
                          style={{ marginRight: "0.5em" }}
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      Save
                    </button>
                  </Col>

                </Row>
              </TabPane> */}
              <TabPane tabId="5" className="p-3">

                {accountDetails && <FeacturesCodes accountDetails={accountDetails} />}
              </TabPane>
            </TabContent>
          </Card>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Spinner
              animation="grow"
              style={{
                margin: "10vh auto",
                height: "10vh",
                width: "10vh",
              }}
            />{" "}
          </div>
        )}
      </div>
    </>
  );
}
