import React, { useEffect, useState } from "react";

import json from "../../../config.json"
import petition_get from "../../petitions/petition_get";
import CustomBreadCrum from "../../../components/utils/CustomBreadCrum/CustomBreadCrum"
import Loading from "../../../components/utils/Loading"
import CardUsers from "./CardUsers/CardUsers";
import Tools from "./Tools/Tools";
import Queues from "./Queues/Queues";
import CallParkingSlots from "./CallParkingSlot/CallParkingSlot";


import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";

function OperatorPanel({ layout }) {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const urlBase = `wss://api.itpscorp.com/${!json.prod ? "dev/" : ""}portal/websockets/itpvoice/operator-panel/ws?account_id=${id}`


  const [users, setUsers] = useState(null)
  const [activeUsers, setActiveUsers] = useState(null)
  const [listDragAndDrop, setListDragAndDrop] = useState([{ type: "cardUser" }, { type: "queues" }, { type: "callParking" }]);
  const [loading, setLoading] = useState(false)
  const [queues, setQueues] = useState([]);

  const [reload, setReload] = useState(1)

  const [cardSizes, setCardSizes] = useState(5)
  const [searchFilter, setSearchFilter] = useState("");
  const [switchOne, setSwitchOne] = useState(true);
  const [switchTwo, setSwitchTwo] = useState(true);
  const [switchThree, setSwitchThree] = useState(true);
  const [switchFour, setSwitchFour] = useState(true);

  const [position, setPosition] = useState(false);
  const [positionQueues, setPositionQueues] = useState(false);

  const [positions, setPositions] = useState(null);
  const [usersCardsInfoFavorite, setUsersCardsInfoFavorite] = useState([]);
  const [queuesCardsInfoFavorite, setQueuesCardsInfoFavorite] = useState([]);
  const [queuesCardsInfo, setQueuesCardsInfo] = useState([]);
  const [usersCardsInfo, setUsersCardsInfo] = useState([]);
  const [favorite_queues, setFavorite_queues] = useState([]);
  const [favorite_extensions, setFavorite_extensions] = useState([]);
  const [loadingUser, setLoadingUser] = useState(false);

  const [panelSettings, setPanelSettings] = useState([]);

  const [view_favorite_queues, setView_favorite_queues] = useState(false);
  const [view_favorite_extensions, setView_favorite_extensions] =
    useState(false);




  useEffect(() => {
    setLoading(false);
    setLoadingUser(false);
    let petitions = async () => {
      let panel_settings = null

      await petition_get("operationPanel", { id: id })
        .then(({ data: result }) => {
          panel_settings = result.result.itpvoice_metadata.operator_panel.panel_settings;
          setPanelSettings(result.result.itpvoice_metadata.operator_panel.panel_settings);
        })
        .catch((error) => {
          console.log(error.response);
        });
      await petition_get("operatorPanel", { id })
        .then(({ data: result }) => {
          const newUsers = [];

          result.result.map(element => {
            const newChannels = []
            element.channels.map(elementTwo => newChannels.push({ answered: elementTwo.answered, time: elementTwo.elapsed_s, withNumber: elementTwo.direction === "outbound" ? elementTwo.caller_id_number : elementTwo.destination }))
            newUsers.push({ ...element, channels: newChannels })
          })

          setUsers(newUsers);
          setLoading(true)
          setActiveUsers(true)
        })
        .catch((error) => console.log(error));
      await petition_get("QueuesPanel", { id: id })
        .then(({ data: result }) => {
          setQueues(result.result);
        })
        .catch((error) => {
          console.log(error.response);
        });
      if (panel_settings.view_favorite_queues) {
        setView_favorite_queues(true);
        setSwitchFour(true);
      } else {
        setView_favorite_queues(false);
        setSwitchFour(false);
      }
      if (panel_settings.favorite_queues) {
        setFavorite_queues(panel_settings.favorite_queues);
      } else {
        setFavorite_queues([]);
      }
      if (panel_settings.view_favorite_extensions) {
        setView_favorite_extensions(true);
        setSwitchThree(true);
      } else {
        setView_favorite_extensions(false);
        setSwitchThree(false);
      }
      if (panel_settings.favorite_extensions) {
        console.log(panel_settings);
        setFavorite_extensions(panel_settings.favorite_extensions);
      } else {
        setFavorite_extensions([]);
      }
      setPosition(false);
      setPositionQueues(false);
    }

    petitions()

  }, [])



  const refreshAll = async () => {

    setLoading(false);
    setLoadingUser(false);
    let petitions = async () => {
      let panel_settings = null
      await petition_get("operationPanel", { id: id })
        .then(({ data: result }) => {
          panel_settings = result.result.itpvoice_metadata.operator_panel.panel_settings;
          setPanelSettings(result.result.itpvoice_metadata.operator_panel.panel_settings);
        })
      await petition_get("operatorPanel", { id })
        .then(({ data: result }) => {
          const newUsers = [];

          result.result.map(element => {
            const newChannels = []
            element.channels.map(elementTwo => newChannels.push({ answered: elementTwo.answered, time: elementTwo.elapsed_s, withNumber: elementTwo.direction === "outbound" ? elementTwo.caller_id_number : elementTwo.destination }))
            newUsers.push({ ...element, channels: newChannels })
          })

          setUsers(newUsers);
          setLoading(true)
          setActiveUsers(true)
        })
        .catch((error) => console.log(error));
      await petition_get("QueuesPanel", { id: id })
        .then(({ data: result }) => {
          setQueues(result.result);
        })
        .catch((error) => {
          console.log(error.response);
        });
      if (panel_settings.view_favorite_queues) {
        setView_favorite_queues(true);
        setSwitchFour(true);
      } else {
        setView_favorite_queues(false);
        setSwitchFour(false);
      }
      if (panel_settings.favorite_queues) {
        setFavorite_queues(panel_settings.favorite_queues);
      } else {
        setFavorite_queues([]);
      }
      if (panel_settings.view_favorite_extensions) {
        setView_favorite_extensions(true);
        setSwitchThree(true);
      } else {
        setView_favorite_extensions(false);
        setSwitchThree(false);
      }
      if (panel_settings.favorite_extensions) {
        console.log(panel_settings);
        setFavorite_extensions(panel_settings.favorite_extensions);
      } else {
        setFavorite_extensions([]);
      }
      setPosition(false);
      setPositionQueues(false);
    }

    petitions()
  }

  useEffect(() => {

    if (users) {

      let saveUsers = users;

      const onmessage = (socket) => {
        socket.onmessage = (e) => {

          const socketInfo = JSON.parse(e.data);
          if (socketInfo.action === "event") {
            let usersCopy;
            let { custom_channel_vars, to, from, call_direction } = socketInfo.data

            switch (socketInfo.name) {
              case "CHANNEL_CREATE":

                usersCopy = saveUsers.map(element => {

                  if (element.id === custom_channel_vars.owner_id) {

                    let withNumber = call_direction === "outbound" ? from.split("@")[0] : to.split("@")[0];

                    if (element.channels.find(elementTwo => elementTwo.withNumber === withNumber)) return element;

                    element.devices_registered = true;
                    element.time_end = 0;
                    element.channels.push({ answered: false, time: 0, withNumber, })
                  }
                  return element
                })
                saveUsers = usersCopy
                setUsers(usersCopy)
                break;

              case "CHANNEL_ANSWER":

                usersCopy = saveUsers.map(element => {
                  if (element.id === custom_channel_vars.owner_id) {

                    let withNumber = call_direction === "outbound" ? from.split("@")[0] : to.split("@")[0];

                    let saveIdChannels;
                    element.channels.map((elementTwo, i) => {
                      if (elementTwo.withNumber === withNumber)
                        saveIdChannels = i;
                    })

                    if (element.channels[saveIdChannels]) element.channels[saveIdChannels].answered = true;
                  }

                  return element;
                })

                saveUsers = usersCopy
                setUsers(usersCopy)
                break;

              case "CHANNEL_DESTROY":

                usersCopy = saveUsers.map(element => {
                  if (element.id === custom_channel_vars.owner_id) {

                    let withNumber = call_direction === "outbound" ? from.split("@")[0] : to.split("@")[0];

                    let saveIdChannels;
                    element.channels.map((elementTwo, i) => {
                      if (elementTwo.withNumber === withNumber)
                        saveIdChannels = i;
                    })
                    if (element.channels[saveIdChannels])
                      element.channels[saveIdChannels].terminated = 1;
                  }
                  return element;
                })

                saveUsers = usersCopy
                setUsers(usersCopy)
                break;

              default: break;
            }
          }
        }
      }


      let socket = new WebSocket(urlBase);
      onmessage(socket)

      //Interval of opening socket
      const intervalIdTwo = setInterval(() => {
        if (socket.readyState === 3) {
          socket = new WebSocket(urlBase);
          onmessage(socket);
        }
      }, 10000);


      const intervalIdOne = setInterval(() => {



        saveUsers.map((element) => {
          if (element.channels.length === 0) return element


          if (!element.channels[0].terminated) {
            element.time_end = element.time_end + 1;
            element.channels[0].time = element.channels[0].time + 1;
          }
          else if (element.channels[0].terminated < 5)
            element.channels[0].terminated = element.channels[0].terminated + 1;

          else
            element.channels = element.channels.filter(elementTwo => !elementTwo.terminated)




        });



        setReload(new Date())
        setUsers(saveUsers);

      }, 1000);

      return () => {
        clearInterval(intervalIdOne);
        clearInterval(intervalIdTwo);
      }
    }
  }, [activeUsers])


  const onDragEnd = (e) => {
    const srcI = e.source.index;

    if (e.destination) {
      const destI = e.destination.index;
      let ListUser = listDragAndDrop;
      ListUser.splice(destI, 0, ListUser.splice(srcI, 1)[0]);
      const newListUser = ListUser.filter((element) => element);

      setListDragAndDrop(newListUser);
    }
  };


  useEffect(() => {
    let dataTemPosition = [];
    let favoriteUsersId = [];
    let favoriteUsers = [];
    if (!position && users && users.length !== 0) {
      setPosition(true);
      users.forEach((element) => {
        favorite_extensions.forEach((elementChild) => {
          if (element.id === elementChild) {
            favoriteUsersId.push(element);
          }
        });
      });
      petition_get("viewCallSettings", { id: id })
        .then(({ data: result }) => {
          setLoadingUser(true);
          if (
            result.result.itpvoice_metadata.operator_panel.panel_settings
              .extensions.length != 0
          ) {
            setPositions(
              result.result.itpvoice_metadata.operator_panel.panel_settings
            );
            if (users.length !== 0) {
              result.result.itpvoice_metadata.operator_panel.panel_settings.extensions.forEach(
                (element) => {
                  users.forEach((elementChild) => {
                    if (element.id === elementChild.id) {
                      dataTemPosition.push(elementChild);
                    }
                  });
                }
              );

              const getNotFoundExtension = [];
              users.map((element) => {
                const findOne =
                  result.result.itpvoice_metadata.operator_panel.panel_settings.extensions.find(
                    (elementTwo) => elementTwo.id === element.id
                  );
                if (!findOne) getNotFoundExtension.push(element);
              });

              if (dataTemPosition.length != 0) {
                setUsersCardsInfo([
                  ...dataTemPosition,
                  ...getNotFoundExtension,
                ]);
              }
            }
          } else {
            setUsersCardsInfo(users);
          }

          if (favoriteUsersId.length != 0) {
            let dataTemPosition = [];

            result.result.itpvoice_metadata.operator_panel.panel_settings.extensions.forEach(
              (element) => {
                users.forEach((elementChild) => {
                  if (element.id === elementChild.id) {
                    dataTemPosition.push(elementChild);
                  }
                });
              }
            );

            users.map((element) => {
              const findOne =
                result.result.itpvoice_metadata.operator_panel.panel_settings.extensions.find(
                  (elementTwo) => elementTwo.id === element.id
                );
              if (!findOne) dataTemPosition.push(element);
            });

            dataTemPosition.forEach((element) => {
              favoriteUsersId.forEach((elementChild) => {
                if (element.id === elementChild.id) {
                  favoriteUsers.push(elementChild);
                }
              });
            });

            if (favoriteUsers.length != 0) {
              setUsersCardsInfoFavorite(favoriteUsers);
            } else {
              setUsersCardsInfoFavorite(favoriteUsersId);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (users && users.length !== 0) {
      const newUsersCardsInfo = usersCardsInfo.map((element) => {
        const findUser = users.find(
          (elementTwo) => elementTwo.presence_id === element.presence_id
        );
        element = findUser;
        return element;
      });
      setUsersCardsInfo(newUsersCardsInfo);
    }
  }, [users, position]);

  useEffect(() => {
    let dataTemPosition = [];
    let favoritesQueuesId = [];
    let favoritesQueues = [];
    if (!positionQueues && queues.length !== 0) {
      setPositionQueues(true);
      queues.forEach((element) => {
        favorite_queues.forEach((elementChild) => {
          if (element.id === elementChild) {
            favoritesQueuesId.push(element);
          }
        });
      });

      if (positions && positions.queues && positions.queues.length != 0) {
        if (users.length !== 0) {
          positions.queues.forEach((element) => {
            queues.forEach((elementChild) => {
              if (element.id === elementChild.id) {
                dataTemPosition.push(elementChild);
              }
            });
          });
          if (dataTemPosition.length != 0) {
            setQueuesCardsInfo(dataTemPosition);
          }
        }
      } else {
        setQueuesCardsInfo(queues);
      }

      if (favoritesQueuesId.length != 0) {
        queues.forEach((element) => {
          favoritesQueuesId.forEach((elementChild) => {
            if (element.id === elementChild.id) {
              favoritesQueues.push(elementChild);
            }
          });
        });
        if (favoritesQueues.length != 0) {
          setQueuesCardsInfoFavorite(favoritesQueues);
        } else {
          setQueuesCardsInfoFavorite(favoritesQueuesId);
        }
      }
    }
  }, [queues, positionQueues]);


  return (
    <div className="page-content">
      <div className="padding-page">
        <CustomBreadCrum title="Apps" subtitle="Operator Panel" />
        <div style={{ paddingBottom: "10rem", transition: "1s all", width: "100%", }} >
          {(!loading || !loadingUser) ? <Loading /> : (
            <>
              <Row>
                <Col className="col-12 ">
                  <Tools
                    refreshAll={refreshAll}
                    users={users}
                    queues={queues}
                    searchFilter={searchFilter}
                    setSearchFilter={setSearchFilter}
                    switchOne={switchOne}
                    setSwitchOne={setSwitchOne}
                    switchTwo={switchTwo}
                    setSwitchTwo={setSwitchTwo}
                    switchThree={switchThree}
                    setSwitchThree={setSwitchThree}
                    switchFour={switchFour}
                    setSwitchFour={setSwitchFour}
                  />
                </Col>
              </Row>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="panels">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {listDragAndDrop.map((element, i) => {
                        if (element.type === "cardUser") {
                          return (
                            <Draggable
                              key={i}
                              index={i}
                              draggableId={`key-${i}`}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <div
                                    {...provided.dragHandleProps}
                                    className="d-flex"
                                    style={{
                                      alignItems: "center",
                                      paddingBottom: "1rem",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div className="mt-5">
                                        <h3 style={{ margin: "0" }}>
                                          EXTENSIONS
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                  <CardUsers
                                    cardSizes={cardSizes}
                                    searchFilter={searchFilter}
                                    users={
                                      switchThree === true
                                        ? usersCardsInfoFavorite
                                        : usersCardsInfo
                                    }
                                    switchOne={switchOne}
                                    switchTwo={switchTwo}
                                    id={id}
                                    layout={layout}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        }

                        else if (element.type === "callParking") {
                          return (
                            <Draggable
                              key={i}
                              index={i}
                              draggableId={`key-${i}`}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <div
                                    className="mt-5"
                                    {...provided.dragHandleProps}
                                  >
                                    <h3 className="m-0">  Call Parking Slot </h3>
                                  </div>
                                  <CallParkingSlots />
                                </div>
                              )}
                            </Draggable>
                          );
                        }
                        else {
                          /*  return (
                             <Draggable
                               key={i}
                               index={i}
                               draggableId={`key-${i}`}
                             >
                               {(provided, snapshot) => (
                                 <div
                                   ref={provided.innerRef}
                                   {...provided.draggableProps}
                                 >
                                   <div
                                     className="mt-5"
                                     {...provided.dragHandleProps}
                                   >
                                     <h3 className="m-0">  QUEUES  </h3>
                                   </div>
                                   <Queues
                                     queues={switchFour === true ? queuesCardsInfoFavorite : queuesCardsInfo}
                                     cardSizes={cardSizes}
                                     id={id}
                                   />
                                 </div>
                               )}
                             </Draggable>
                           ); */
                        }
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          )}

        </div>

      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
    ITPVoice: state.ITPVoice,
  };
};

export default connect(mapStateToProps)(OperatorPanel);
