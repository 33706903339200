import React, { useState, useEffect } from "react";
import { Button, CustomInput } from "reactstrap";
import { SidebarItem } from "../../../components/Index";
import { get } from "lodash";
import { formatTimeDelta } from "react-countdown";

export default function User({
    listUsers,
    mediaSounds,
    setDetails,
    updateAction,
    state,
    startPoint,
    StartItemStyle,
    timeGroups,
    selectNode,
    dataCallFlow
}) {
    const [userSearch, setUserSearch] = useState("");
    const [activeItem, setActiveItem] = useState(false);
    const [activeForm, setActiveForm] = useState(false);
    const [form, setForm] = useState({ timeout: "20", checked: false });
    const [activeTimeGroups, setActiveTimeGroups] = useState(false)
    const [selectTimeGroup, setSelectTimeGroup] = useState(null)
    const [defaultValue, setDefaultValue] = useState(true)
    const [timeGroupsValues, setTimeGroupsValues] = useState([])


    const [activeMenu, setActiveMenu] = useState(false)
    const [menuOptions, setMenuOptions] = useState(false)
    const [selectMenu, setSelectMenu] = useState(null);

    const onChangeAutocompleteMenu = (e) => {
        setSelectMenu(e.target.value);
    };

    const onChangeAutocomplete = (e) => {



        const findOne = mediaSounds.find(
            (element) => element.id === e.target.value
        );

        if (findOne) {
            setUserSearch(findOne.name);
            setActiveForm(findOne.id);
        }
    };
    const onChangeAutocompleteTimeGroup = (e) => {

        setSelectTimeGroup(e.target.value);

    };

    const changeForm = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };


    useEffect(() => {

        if (selectNode && selectNode.type.split("_")[0] === "Time") {
            setDefaultValue(true)

            let values;
            if (selectNode.childrenUrl === "") values = dataCallFlow.flow.children;
            else {

                values = get(dataCallFlow.flow, selectNode.childrenUrl).children
            }

            const timeGroupsKeys = Object.keys(values)
            const newTimeGroups = []
            timeGroups.map(element => {
                const findOne = timeGroupsKeys.find(elementTwo => elementTwo === element.id)
                if (!findOne) {
                    newTimeGroups.push(element)
                }
            })

            if (values._) {
                setDefaultValue(false)
                setSelectTimeGroup(newTimeGroups.length > 0 ? newTimeGroups[0].id : "")
            } else {
                setSelectTimeGroup("_")
            }
            setTimeGroupsValues(newTimeGroups)
            setActiveTimeGroups(false)
        }
        else if (selectNode && selectNode.type.split("_")[0] === "Menu") {
            let MenuOptions = [
                { id: "1" },
                { id: "2" },
                { id: "3" },
                { id: "4" },
                { id: "5" },
                { id: "6" },
                { id: "7" },
                { id: "8" },
                { id: "9" },
                { id: "0" },
                { id: "timeout" },
            ]
            let values;
            if (selectNode.childrenUrl === "") values = dataCallFlow.flow.children;
            else values = get(dataCallFlow.flow, selectNode.childrenUrl).children

            const MenuKeys = Object.keys(values)
            const newMenu = []
            MenuOptions.map(element => {
                const findOne = MenuKeys.find(elementTwo => elementTwo === element.id)
                if (!findOne) {
                    newMenu.push(element)
                }
            })
            setSelectMenu(newMenu[0].id)
            setMenuOptions(newMenu)
            setActiveMenu(true);
            setActiveTimeGroups(true)
        }
        else {
            setActiveTimeGroups(true)
        }

    }, [])



    return (
        <>
            {activeTimeGroups ? <>


                {activeMenu ? <>
                    <select
                        onChange={onChangeAutocompleteMenu}
                        className="form-control"
                        list="datalistOptions"
                        id="exampleDataList"
                        placeholder="Type to search..."
                    >

                        {menuOptions.map((element, i) => (
                            <option key={i} value={element.id}>
                                {element.id}
                            </option>
                        ))}
                    </select>
                    {selectMenu && (
                        <Button
                            onClick={async () => {
                                setActiveMenu(false);
                            }}
                            className=" mt-3 w-100"
                            color="primary"
                        >
                            Create
                        </Button>
                    )}
                </> : <>

                    <select
                        onChange={onChangeAutocomplete}
                        className="form-control"
                        list="datalistOptions"
                        id="exampleDataList"
                        placeholder="Type to search...">
                        {mediaSounds.map((element, i) => (
                            <option key={i} value={element.id}>
                                {element.name}
                            </option>
                        ))}
                    </select>

                    {activeForm && (
                        <Button
                            onClick={async () => {

                                const findOne = mediaSounds.find(
                                    (element) => element.id === activeForm
                                );
                                setDetails({ ...findOne, ...form, timeGroups: selectTimeGroup, menuOption: selectMenu });
                                setActiveItem(userSearch);
                            }}
                            className=" mt-3 w-100"
                            color="primary"
                        >
                            Create
                        </Button>
                    )}

                    {activeItem && (
                        <SidebarItem
                            updateAction={updateAction}
                            state={state}
                            label={`Media: ${activeItem}`}
                            type={`Media_${activeItem}`}
                            ports={startPoint(`Media_${activeItem}`)}
                            ItemStyle={StartItemStyle}
                        />
                    )}

                </>}


            </>
                : <>
                    <select
                        onChange={onChangeAutocompleteTimeGroup}
                        className="form-control"
                        list="datalistOptions"
                        id="exampleDataList"
                        placeholder="Type to search..."
                    >
                        {defaultValue && <option value="_">All Other Times</option>}
                        {selectTimeGroup === "" && <option value="">You have no more options </option>}
                        {timeGroupsValues.map((element, i) => (
                            <option key={i} value={element.id}>
                                {element.name}
                            </option>
                        ))}

                    </select>

                    {selectTimeGroup && (
                        <Button
                            disabled={selectTimeGroup === ""}
                            onClick={async () => {
                                setActiveTimeGroups(true)
                            }}
                            className=" mt-3 w-100"
                            color="primary"
                        >
                            Create
                        </Button>
                    )}
                </>}

        </>

    );
}
