import React, { useState, useEffect } from "react";



export default function Prueba() {

    // const urlBase = json.prod
    // ? `wss://api.itpscorp.com/portal/websockets/itpvoice/operator-panel/ws?account_id=${id}`
    // : `wss://api.itpscorp.com/dev/portal/websockets/itpvoice/operator-panel/ws?account_id=${id}`;

    const urlBase = "wss://websockets.api.itp247.com/sms?phone_number=3052990240"
    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    useEffect(() => {
       let  socket = new WebSocket(urlBase);
        // console.log("111111111111111111111");
        // const urlBase = `https://api.itpscorp.com/dev/portal/itpvoice/${id}/my-extension/chat/sms/sse`

        // let sse = new EventSource(urlBase);

        // sse.onmessage = (e) => {
        //     console.log(e)
        // }

        socket.addEventListener('open', function (event) {
            socket.send('Hello Server!');
        });

        // // Escucha por mensajes
        // socket.addEventListener('message', function (event) {
        //     console.log('Message from server', event.data);
        // });


    }, [])


    return (
        <div className="page-content">

            <h3>Pruebas de chat </h3>

        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        layout: state.sip,
    };
};

