import React, { useState } from 'react'
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
    Button,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Row, Col
} from "reactstrap";
export default function SidebarCampaign() {

    const [toggle, setToggle] = useState(false)


    const [data, setData] = useState([
        { name: "Long title name cut of...", description: "I,ve finished it! See you so...", date: "03/23/2021 2:42 PM" },
        { name: "Long title name cut of...", description: "I,ve finished it! See you so...", date: "03/23/2021 2:42 PM" },
        { name: "Long title name cut of...", description: "I,ve finished it! See you so...", date: "03/23/2021 2:42 PM" },
        { name: "Long title name cut of...", description: "I,ve finished it! See you so...", date: "03/23/2021 2:42 PM" },
    ])


    return (
        <>
            <div className="d-flex justify-content-between">

                <Button
                    color="danger"
                >
                    <AddCircleOutlineIcon style={{ fontSize: "1.8rem" }} /> NEW
                </Button>
                <Button
                    color="primary"
                >
                    <AddCircleOutlineIcon style={{ fontSize: "1.8rem" }} /> FILTERS
                </Button>

            </div>

            <Row>
                <Col className="col-4 mt-5">
                    <Dropdown isOpen={toggle} toggle={() => { setToggle(!toggle) }}>
                        <DropdownToggle className="btn nav-btn d-flex align-items-center" tag="i">
                            Sent   <KeyboardArrowDownIcon />
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem >
                                <div>   Sent  </div>
                            </DropdownItem>
                            <DropdownItem >
                                <div>   Scheduled </div>
                            </DropdownItem>
                            <DropdownItem >
                                <div>   Drafts </div>
                            </DropdownItem>
                            <DropdownItem >
                                <div>   Paused </div>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </Col>



            </Row>

            <div >

                {data.map((element, i) => <div  >
                    <hr style={{ margin: "7px 0" }} />
                    <div className=" m-2" style={{ cursor: "pointer" }}>

                        <div className="d-flex justify-content-between">
                            <strong>{element.name}</strong>
                            <span>{element.date}</span>
                        </div>
                        <div>
                            <span>
                                {element.description}
                            </span>
                        </div>

                    </div>

                </div>)}

            </div>

        </>

    )
}
