import React from "react";
import styled from "styled-components";
import { Row, Col } from "reactstrap";

export default function Filter({
  setFilterNumbers,
  filterNumbers,
  resetActive,
  setErrorNumbers,
}) {
  const onChangeRadio = (e) => {
    if (resetActive) return null;

    setFilterNumbers({ ...filterNumbers, type: e.target.value });
  };

  const onChangeSelect = (e) => {
    if (resetActive) return null;

    if (e.target.value === "selected") {
      return setFilterNumbers({
        ...filterNumbers,
        country: e.target.value,
        type: "",
      });
    }
    setFilterNumbers({ ...filterNumbers, country: e.target.value });
  };

  const onChangeSelectType = (e) => {
    if (resetActive) {
      return null;
    }
    setErrorNumbers(null);
    if (e.target.value === "selected") {
      return setFilterNumbers({
        ...filterNumbers,
        type: "",
        category: "",
      });
    }
    setFilterNumbers({ ...filterNumbers, type: "", category: e.target.value });
  };

  return (
    <>
      <div style={{ margin: "2rem 2rem 0 2rem" }}>
        <form action="" id="form1">
          <Row>
            <Col sm="12" lg="4">
              <label htmlFor="country">Select Country</label>
              <select
                className="form-control"
                disabled={resetActive ? true : false}
                onChange={onChangeSelect}
                name=""
                id="country"
              >
                <option value="selected">Select one...</option>
                <option value="UUEE">United States</option>
              </select>
            </Col>
            {filterNumbers.country !== "selected" && (
              <>
                {" "}
                <Col className="pt-sm-4 pt-lg-0" sm="12" lg="4">
                  {" "}
                  <label htmlFor="">Select Number Type</label>
                  <select
                    className="form-control"
                    onChange={onChangeSelectType}
                    name=""
                    id=""
                  >
                    <option value="selected">Select one ...</option>
                    <option value="tool">Toll Free</option>
                    <option value="local">Local</option>
                  </select>
                </Col>
                {filterNumbers.category === "local" && (
                  <Col className="pt-sm-4 pt-lg-0" sm="12" lg="4">
                    <label htmlFor="">Search Filter</label>
                    <div className="d-flex" style={{ marginTop: "8px" }}>
                      <div className="custom-control custom-radio pr-3">
                        <input
                          type="radio"
                          id="customRadio1"
                          name="customRadio"
                          className="custom-control-input"
                          onChange={onChangeRadio}
                          value="NPA"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadio1"
                          style={{ paddingTop: "1px" }}
                        >
                          Area Code
                        </label>
                      </div>
                      {/*  <div className="custom-control custom-radio pr-3 ">
                        <input
                          type="radio"
                          id="customRadio2"
                          name="customRadio"
                          className="custom-control-input"
                          onChange={onChangeRadio}
                          value="NPANXX"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadio2"
                          style={{ paddingTop: "1px" }}
                        >
                          NPANXX
                        </label>
                      </div> */}
                      <div className="custom-control custom-radio pr-3">
                        <input
                          type="radio"
                          id="customRadio3"
                          name="customRadio"
                          onChange={onChangeRadio}
                          className="custom-control-input"
                          value="City/State"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customRadio3"
                          style={{ paddingTop: "1px" }}
                        >
                          City/State
                        </label>
                      </div>
                    </div>
                  </Col>
                )}
              </>
            )}
          </Row>
        </form>
        <br />
        <hr />
      </div>
    </>
  );
}
