import React from "react";
import { ConfirmOrderStyled } from "../Style/Style";
import { Link } from "react-router-dom";
import { Checkmark } from "react-checkmark";
export default function ConfirmOrder({ resultOrder }) {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  return (
    <ConfirmOrderStyled>
      {resultOrder ? (
        <div className="order-confirm">
          <Checkmark size="150px" />
          <div className="container-back">

            {/*  <Link to={`/DashboardUsers?id=${id}`} className="btn-back">
              Back to ITPVoce Menu
            </Link> */}
          </div>
        </div>
      ) : (
        <div className="order-fail">the creation of the order failed</div>
      )}
    </ConfirmOrderStyled>
  );
}
