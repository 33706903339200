import React, {useState} from "react";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  CustomInput,
  Spinner,
} from "reactstrap";

import phoneImg from "../../../../assets/images/phone-image.svg";
import petition_patch from "../../../petitions/petition_patch";
export default function FavoritesModal({modal, setModal}) {
  return (
    <Modal centered={true} isOpen={modal} className="info ">
      <ModalBody className="text-center">
        <Spinner
          animation="grow"
          style={{
            margin: "10vh auto",
            height: "10vh",
            width: "10vh",
          }}
        />
      </ModalBody>
    </Modal>
  );
}
