import React, { useState, useEffect } from "react";
import verificationImg from "../../../../../assets/images/verification-img.png";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  CustomInput,
  Table
} from "reactstrap";

import petition_post from "../../../../petitions/petition_post";
import petition_patch from "../../../../petitions/petition_patch";
import petition_get from "../../../../petitions/petition_get";
import toastr from "toastr";
export default function ModalUtility({
  modal,
  setModal,
  users,
  element,
  setElement
}) {
  toastr.options = {
    showDuration: 300,
  };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false)

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUsersCopy, setSelectedUsersCopy] = useState([]);

  useEffect(() => {
    if (element) {
      petition_get("permissions", { id: id, app_id: element.pk })
        .then(({ data: result }) => {

          setSelectedUsers(result.result)
          setSelectedUsersCopy(result.result)
        })
        .catch((error) => {
          console.log(error);
          // setLoading(true);
        });
    }

  }, [element]);


  const handleCheck = (element) => {
    const findOne = selectedUsers.find(
      (elementTwo) => elementTwo.id === element.id
    );

    let newSelectedUsers;
    if (findOne) {
      newSelectedUsers = selectedUsers.filter(
        (elementTwo) => elementTwo.id !== element.id
      );
    } else {
      newSelectedUsers = selectedUsers;
      newSelectedUsers.push(element);
    }

    setSelectedUsers(newSelectedUsers);
    setReload(!reload)
  };

  const handleCheckAll = () => {
    if (selectedUsers.length === 0) return setSelectedUsers(users)
    setSelectedUsers([])
  }



  const onSubmitForm = async (e) => {
    setLoading(true)

    const selectedUsersId = []
    selectedUsers.map(element => selectedUsersId.push(element.id))

    const removeUsers = []


    selectedUsersCopy.map(element => {
      const findOne = selectedUsers.find(elementTwo => elementTwo.id === element.id)
      if (!findOne) removeUsers.push(element.id)
    })

    await petition_patch("permissions", { id: id, data: { users: selectedUsersId, remove_users: removeUsers }, app_id: element.pk })
      .then(({ data: result }) => {
        setLoading(false)
        toastr.success("Action completed", "Success");
        setModal(false)
        setSelectedUsers([])
        setSelectedUsersCopy([])
        setElement(null)
        setTimeout(() => {
          window.location.reload(true)
        }, 1500)
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)
        toastr.error("Action failed", "Error");

      });
  };



  return (
    <Modal isOpen={modal} className="info">
      <ModalHeader className="bg-soft-primary">
        {" "}
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary"> Users</h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={verificationImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Table className="mt-4" hover size={"md"}>
          <thead className="thead-light">
            <tr>
              <th className="text-center d-flex">
                <CustomInput
                  onClick={(e) => {
                    handleCheckAll();
                  }}
                  type="checkbox"
                  className="input-check"
                  name=""
                  id={`check-th`}
                />

                <label htmlFor="check-th">Users</label>


              </th>
            </tr>
          </thead>

          <tbody>
            {users &&
              users.map((element, i) => {
                let flag = false;
                const findOne = selectedUsers.find(
                  (elementTwo) =>
                    elementTwo.id === element.id
                );
                if (findOne) flag = true;
                return (
                  <tr className="border py-3 text-center d-flex pl-2" key={i}>
                    <CustomInput
                      onClick={(e) => {
                        handleCheck(element);
                      }}

                      checked={flag}
                      type="checkbox"
                      className="input-check"
                      name=""
                      id={`check-${i}`}
                    />
                    <label htmlFor={`check-${i}`}>{element.first_name} {element.last_name}{" "}
                      {element.presence_id}</label>

                  </tr>
                );
              })}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button
          outline
          disabled={loading}
          color="secondary"
          onClick={() => {
            setModal(false);
            setSelectedUsers([])
            setElement(null)
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          color="primary"
          onClick={() => {
            onSubmitForm();
          }}
        >
          {loading && (
            <Spinner
              style={{ marginRight: "0.5em" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
