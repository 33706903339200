import React, { useState, useEffect } from "react";
import { Col, Row, Table } from "reactstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import ButtonHover from "../../../../../../components/utils/ButtonHover";
import { Multiselect } from "multiselect-react-dropdown";
export default function Members({ list, listUsers, setList }) {

  const [multiSelectUsers, setMultiSelectUsers] = useState([])
  const [form, setForm] = useState([])
  const [listMembers, setListMembers] = useState(list);
  const [add, setAdd] = useState(false)

  const onClickDelete = (id) => {
    const newList = list.filter((element) => element !== id);
    setList(newList);
  };

  useEffect(() => {

    let newList = [];
    list.map((element) => {
      const user = listUsers.find((elementTwo) => elementTwo.id === (element));
      if (user) {
        newList.push({
          name: `${user.first_name} ${user.last_name}`,
          presence_id: user.presence_id,
          id: user.id,
        });
      }
    });
    const MultiSelectUsers = []
    listUsers.map(element => MultiSelectUsers.push({ name: `${element.first_name} ${element.last_name}`, id: element.id }))
    setMultiSelectUsers(MultiSelectUsers)

    return setListMembers(newList);

  }, [list, listUsers]);

  const handleSubmit = (e) => {

    const newMembers = []
    form.map(element => {
      const exitsUser = list.find(elementTwo => elementTwo === element.id)
      if (!exitsUser) newMembers.push(element.id)
    })

    setList([...list, ...newMembers])

    setForm([]);
    setAdd(false)
  }

  const handleChangeCheck = (e) => { setForm(e) }
  return (
    <Row>
      <Col className="col-12 col-md-8 offset-md-4 d-flex mb-3" style={{ alignItems: "center", justifyContent: "flex-end" }}>
        {
          add ? <>
            <Multiselect
              options={multiSelectUsers}
              onSelect={handleChangeCheck}
              onRemove={handleChangeCheck}
              displayValue="name"
              placeholder="Start Typing..."

            />
            <button
              onClick={handleSubmit}
              className="btn btn-primary ml-2"
              style={{ fontWeight: "500", fontSize: "16px" }}
            >
              Save
            </button> </> : <button
              onClick={() => {
                setAdd(true);
              }}
              className="btn btn-primary"
              style={{ fontWeight: "500", fontSize: "16px" }}
            >
            Add Member
          </button>
        }

      </Col>
      <Col className="d-flex col-12">
        {listMembers.length !== 0 && (
          <Table responsive>
            <thead className="thead-light">
              <tr>
                <th>Users</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {listMembers.map((element, i) => {
                return (
                  <tr key={i}>
                    <th>
                      {element.name} ({element.presence_id})
                    </th>
                    <th>

                      <ButtonHover
                        style={{
                          margin: "0",
                          marginTop: "0.5rem",
                          display: "flex",
                          alignContent: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          onClickDelete(element.id);
                        }}
                      >

                        <DeleteIcon />
                      </ButtonHover>
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </Col>
    </Row>
  );
}
