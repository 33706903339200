import React, { useState } from 'react'
import CustomTable from '../../../../../components/utils/CustomTable/CustomTable'
import { Button, Spinner } from "reactstrap"
import useFeatureCode from "../../../../../hooks/useFeatureCode"
import ModalUtility from "./ModalUtility"
export default function FeacturesCodes({ accountDetails }) {



    const { updateParkPickup, dataTable, loading, onHandleSave, modal, setModal, loadingModal, featuresCallflow } = useFeatureCode()




    return (
        <>

            <ModalUtility updateParkPickup={updateParkPickup} modal={modal} setModal={setModal} loading={loadingModal} featuresCallflow={featuresCallflow} />

            <CustomTable data={dataTable} />
            <div className="text-right">
                <Button disabled={loading} onClick={() => { onHandleSave() }} color="primary">
                    {loading && (
                        <Spinner
                            style={{ marginRight: "0.5em" }}
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                    Save </Button>
            </div>
        </>
    )
}
