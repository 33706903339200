import React, { useState } from 'react';
import { ResizableBox } from 'react-resizable';

import "../styles/resizable.css"
export default function CallParkingSlot() {

    const [data, setData] = useState([
        { title: "*71", descripcion: "Descripcion 1", type: "incall" },
        { title: "*72", descripcion: "Descripcion 2", type: "missing" },
        { title: "*73", descripcion: "Descripcion 3", type: "call" },
        { title: "*74", descripcion: "Descripcion 4", type: "default" },
    ])


    return <>
        <ResizableBox width={1000} height={(data.length / 4) * 150}
            minConstraints={[250, 200]} >
            <div className="containerGlass mt-3" >

                <div className="area" >
                    <ul className="circles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div >

                <div className="glass glassDisplay">

                    {data.map(element => (<div className={`glassItem`}> <div className={`glassItemType glassItem-${element.type} `}></div>  <h5>{element.title}</h5> <p>{element.descripcion}</p> </div>))}

                </div>
            </div>
        </ResizableBox>
    </>



}
