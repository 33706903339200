import React, { useState, useEffect } from "react";
import verificationImg from "../../../../../assets/images/verification-img.png";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
import petition_get from "../../../../petitions/petition_get";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";

import ButtonHover from "../../../../../components/utils/ButtonHover";

export default function ModalUtility({ modal, setModal, element, setElement,timeZone }) {
  const [tech, setTech] = useState(false);
  const [Keys, setKeys] = useState(null);
  const [KeysValues, setKeysValues] = useState(null);

  const formatData = (val) => {
    let time = new Date(val*1000);
    let timeZoneTemp = time.toLocaleString('en-US', { timeZone: timeZone })
    return timeZoneTemp
  }

  if (tech) {
    return (
      <Modal isOpen={modal} style={{ marginTop: "10rem" }} className=" info">
        <ModalHeader className=" bg-soft-primary">
          {" "}
          <Row>
            <Col className="col-10">
              <div className="text-primary p-3">
                <h3 className="text-primary"> Tech Details</h3>
              </div>
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody style={{ padding: "4rem 0 0 3rem" }}>
          <Table responsive>
            {Keys.map((elem, i) => {
              return (
                <p key={i}>
                  {" "}
                  "{elem}" : "{KeysValues[i]}"{" "}
                </p>
              );
            })}
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setTech(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  return (
    <Modal
      size="lg"
      isOpen={modal}
      style={{ marginTop: "10rem" }}
      className=" info"
    >
      <ModalHeader className="bg-soft-primary">
        {" "}
        <Row style={{ height: "15vh" }}>
          <Col className="col-10">
            <div className="text-primary p-3">
              <h3 className="text-primary"> Call Details</h3>
            </div>
          </Col>
          <Col className="col-2">
            <div>
              {" "}
              <img src={verificationImg} alt="" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Table hover responsive>
          <thead className="thead-light">
            <tr>
              <th>Direction</th>
              <th>Number</th>
              <th>Caller ID Name</th>
              <th>Duration</th>
              <th>Date/Time</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              element.map((el, i) => {
                let KeysTemp = Object.keys(el)
                let KeysValuesTemp = Object.values(el)
                return (
                  <tr key={i}>
                    <th>{el.direction}</th>
                    <th>{el.caller_id_number}</th>
                    <th>{el.caller_id_name}</th>
                    <th>{el.duration_seconds + "seg"}</th>
                    <th>{formatData(el.unix_timestamp)}</th>
                    <th>
                      <ButtonHover
                        onClick={() => {
                          setKeys(KeysTemp)
                          setKeysValues(KeysValuesTemp)
                          setTech(true);
                        }}
                      >
                        {" "}
                        <BusinessCenterOutlinedIcon />{" "}
                      </ButtonHover>
                    </th>
                  </tr>)
              })

            }
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            setModal(false);
            
            setElement(null);
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal >
  );
}
