import React, { useState } from "react";
import {
  Card,
  Row,
  Col,
  CardTitle,
  Button,
  Input,
  Label,
  CardBody,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import Switch from "react-switch";
export default function FaxSettings() {
  const [password, setPassword] = useState("");
  const [switchOne, setSwitchOne] = useState(true);
  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };
  return (
    <React.Fragment>
      <Card body>
        <CardTitle className="mt-4">User Details </CardTitle>
        <CardBody>
          <Row className="mt-4">
            <Col>
              <div>
                <Switch
                  uncheckedIcon={<Offsymbol />}
                  className="mr-1"
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  onChange={() => {
                    setSwitchOne(!switchOne);
                  }}
                  checked={switchOne}
                />
                <p style={{display:"inline-block",marginLeft:"1em"}}>Send Incoming Faxes To My Email</p>  
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={9}>
              <Label for="exampleEmail">Enter Email</Label>
              <Input
                type="email"
                name="email"
                id="exampleEmail"
                placeholder="Enter Email"
              />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={10} className="mt-1"></Col>
            <Col md={2} className="mt-5">
              <Button color="primary">Send</Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
