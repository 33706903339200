import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

export default class ModalUtil extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.props.toggle;
    this.modalbody = this.props.modalbody;
    this.childFunction = this.props.childFunction;
    this.acceptButton= this.props.acceptButton;
    this.cancelButton= this.props.cancelButton;
    this.title = this.props.title;
    this.state = {
      modal: this.props.modal,
      spinner: this.props.spinner,
      disabledAccept:true
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.spinner !== prevProps.spinner) {
      this.setState({ 
        spinner: this.props.spinner,
       });
    }
    if (this.props.disabledAccept !== prevProps.disabledAccept) {
      this.setState({ 
        disabledAccept:this.props.disabledAccept
       });
    }
  }

  spinner() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minHeight: "20em",
        }}
      >
        <Spinner animation="grow" style={{ height: "10vh", width: "10vh" }} />
      </div>
    );
  }
  render() {
    return (
      <React.Fragment>
        <div>
          <Modal
            isOpen={this.props.modal}
            toggle={this.toggle}
            size="lg"
            backdrop="static"
          >
            <ModalHeader>
              {this.state.spinner ? "Loading" : this.title}
            </ModalHeader>
            <ModalBody>
              {this.state.spinner ? this.spinner() : this.modalbody}
            </ModalBody>
            <ModalFooter>
              {this.state.spinner ? null : (
                <>
                  <Button color="danger"  onClick={this.toggle}>
                    {this.cancelButton}
                  </Button>{" "}
                  <Button color="primary" onClick={this.childFunction}  disabled={this.state.disabledAccept}>
                   {this.acceptButton}
                  </Button>
                </>
              )}
            </ModalFooter>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}