import React, {useState, useEffect} from "react";
import profileImg from "../../../../../assets/images/profile-img.png";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

import petition_get from "../../../../petitions/petition_get";

export default function ModalUtility({
  modal,
  setModal,
  deleteApp,
  loadingDelete,
}) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  useEffect(() => {
    if (modal) {
      setData(null);
      setLoading(false);
      petition_get("installedAppDetails", {id: id, pk: modal})
        .then(({data: result}) => {
          setData(result.result.app);
          setLoading(true);
        })
        .catch((error) => {
          console.log("error");
        });
    }
  }, [modal]);
  return (
    <Modal centered={true} isOpen={modal ? true : false} className="info">
      <ModalHeader className="bg-soft-primary">
        {" "}
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary">App Details</h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={profileImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <>
            {data && (
              <>
                <p>Name:{data.name}</p>
                <p>Description:{data.description}</p>
                <p>Monthly Price: {data.monthly_price}</p>
                <p
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  App Image: <span>{data.app_image}</span>
                </p>
                <p>Available: {data.status}</p>{" "}
                <div className="text-center">
                  <button
                    disabled={loadingDelete}
                    onClick={() => {
                      deleteApp(data.pk);
                    }}
                    className="btn btn-danger"
                  >
                    {loadingDelete && (
                      <Spinner
                        animation="grow"
                        style={{
                          marginRight: "1rem",
                        }}
                      />
                    )}
                    Delete App
                  </button>
                </div>
              </>
            )}
          </>
        ) : (
          <div style={{textAlign: "center"}}>
            <Spinner
              className="spinner-submit mr-2"
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          </div>
        )}

        {/*   <p>
          {" "}
          <strong>Call Center App</strong>{" "}
        </p>
        <p>Enable this App provides Call Center Features such as:</p>
        <p>
          - Automatic Caller ID Matching based on destination phone number. This
          features allows you to automatically change your caller ID to match
          the area code you are dialing. For example, If you own the phone
          number: 305-555-3030 and are dialing a customer lead nomber of:
          305-225-4444, the system will automatically change your caller ID so
          it appears to the caller as 305-555-3030
        </p>{" "}
        <p>
          -Permission Bases Numbers. Allow specific users to use specific phone
          numbers
        </p> */}
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={loadingDelete}
          outline
          color="secondary"
          onClick={() => {
            setModal(false);
          }}
        >
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
}
