import React, { useState } from "react";
import { Col, Row, Button } from "reactstrap";
import { scroller } from "react-scroll";
import FilterUbication from "./FilterUbication";
import petition_get from "../../../../petitions/petition_get";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import ListNumbers from "./ListNumbers/ListNumbers";
import CardOrder from "./CardOrder";

export default function AddNumber({
  setStep,
  step,
  setData,
  data,
  setNumberSelected,
}) {
  //Get Api_ID
  const history = useHistory();
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  //States
  //Filter Country
  const [form, setForm] = useState({
    country: "selected",
    type: "",
  });
  //Filter City/State
  const [cityState, setCityState] = useState({
    state: "",
    city: "",
  });
  //Filter NPA
  const [npa, setNpa] = useState({
    code: "",
  });
  //Filter NPANXX
  const [npaNxx, setNpaNxx] = useState({
    npa: "",
    nxx: "",
  });
  //Loading
  const [loading, setLoading] = useState(false);
  //List of Numbers Selected
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [activeSelected, setActiveSelected] = useState([]);
  const [resetActive, setResetActive] = useState(false);
  /*******/
  //List of Numbers
  const [listOfNumbers, setListOfNumbers] = useState(null);
  const [errorNumbers, setErrorNumbers] = useState(null);

  const onChangeRadio = (e) => {
    setForm({ ...form, type: e.target.value });
  };

  const onChangeSelect = (e) => {
    if (e.target.value === "selected") {
      return setForm({ ...form, country: "selected" });
    }
    setForm({ ...form, country: e.target.value });
  };

  const onSubmitNumbers = async () => {
    setLoading("submit");
    if (form.country !== "selected") {
      switch (form.type) {
        case "NPA":
          if (npa.code.trim() === "") {
            setLoading(false);
            return setErrorNumbers("Codes Required");
          }

          await petition_get("numbers", {
            id: id,
            type: form.type,
            code: npa.code,
          })
            .then(({ data: result }) => {
              if (!result.result.SearchResult) {
                setListOfNumbers(null);
                setErrorNumbers("Numbers not available");
              } else {
                setListOfNumbers(
                  result.result.SearchResult.TelephoneNumberList.TelephoneNumber
                );
                setResetActive(true);
                setErrorNumbers(null);
                scroller.scrollTo("numbers", {
                  duration: 500,
                  smooth: true,
                });
              }
            })
            .catch((error) => {
              setListOfNumbers(null);
              setErrorNumbers("Numbers not available");
            });

          break;

        case "City/State":
          if (cityState.city === "" || cityState.state === "") {
            setLoading(false);
            return setErrorNumbers("Options Required");
          }

          await petition_get("numbers", {
            id: id,
            type: form.type,
            code: cityState,
          })
            .then(({ data: result }) => {
              if (!result.result.SearchResult) {
                setListOfNumbers(null);
                setErrorNumbers("Numbers not available");
              } else {
                setListOfNumbers(
                  result.result.SearchResult.TelephoneNumberList.TelephoneNumber
                );
                setResetActive(true);
                setErrorNumbers(null);
                scroller.scrollTo("numbers", {
                  duration: 500,
                  smooth: true,
                });
              }
            })
            .catch((error) => {
              setListOfNumbers(null);
              setErrorNumbers("Numbers not available");
            });

          break;

        case "NPANXX":
          if (npaNxx.npa === "" || npaNxx.nxx === "") {
            setLoading(false);
            return setErrorNumbers("Codes Required");
          }

          await petition_get("numbers", {
            id: id,
            type: form.type,
            code: npaNxx,
          })
            .then(({ data: result }) => {
              if (
                !result.result.SearchResult ||
                result.result.SearchResult.Error
              ) {
                setListOfNumbers(null);
                setErrorNumbers("Numbers not available");
              } else {
                setListOfNumbers(
                  result.result.SearchResult.TelephoneNumberList.TelephoneNumber
                );
                setResetActive(true);
                setErrorNumbers(null);
                scroller.scrollTo("numbers", {
                  duration: 500,
                  smooth: true,
                });
              }
            })
            .catch((error) => {
              setListOfNumbers(null);
              setErrorNumbers("Numbers not available");
            });

          break;

        default:
          setErrorNumbers("Please Select Number Type");
      }

      setLoading(false);
    } else {
      setErrorNumbers("Please Select Country");
    }
  };

  const next = () => {
    setStep(step + 1);
    // cambiar "num" por el nombre de la variable en el backend
    setData({ ...data, numberInfo: { ...activeSelected[0] } });
  };

  const skip = () => {
    setStep(step + 1);
  };

  return (
    <>
      <br />
      <div style={{ margin: "0 2em" }}>
        <Row>
          <Col sm="12" lg="4">
            {" "}
            <label htmlFor="country">Select Country</label>
            <select
              className="form-control"
              onChange={onChangeSelect}
              name=""
              id="country"
            >
              <option value="selected">Select one...</option>
              <option value="UUEE">United States</option>
            </select>
          </Col>
          {form.country !== "selected" && (
            <>
              {" "}
              <Col className="pt-4 pt-lg-0" sm="12" lg="4">
                {" "}
                <label htmlFor="">Select Number Type</label>
                <select
                  className="form-control"
                  onChange={onChangeSelect}
                  name=""
                  id=""
                  disabled={true}
                >
                  <option value="local">Local</option>
                </select>
              </Col>
              <Col className="pt-4 pt-lg-0" sm="12" lg="4">
                <label htmlFor="">Search Filter</label>
                <div className="d-flex" style={{ marginTop: "8px" }}>
                  <div className="custom-control custom-radio pr-3">
                    <input
                      type="radio"
                      id="customRadio1"
                      name="customRadio"
                      className="custom-control-input"
                      onChange={onChangeRadio}
                      value="NPA"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadio1"
                      style={{ paddingTop: "1px" }}
                    >
                      Area Code
                    </label>
                  </div>
                  {/* <div className="custom-control custom-radio pr-3 ">
                    <input
                      type="radio"
                      id="customRadio2"
                      name="customRadio"
                      className="custom-control-input"
                      onChange={onChangeRadio}
                      value="NPANXX"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadio2"
                      style={{ paddingTop: "1px" }}
                    >
                      NPANXX
                    </label>
                  </div> */}
                  <div className="custom-control custom-radio pr-3">
                    <input
                      type="radio"
                      id="customRadio3"
                      name="customRadio"
                      onChange={onChangeRadio}
                      className="custom-control-input"
                      value="City/State"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadio3"
                      style={{ paddingTop: "1px" }}
                    >
                      City/State
                    </label>
                  </div>
                </div>
              </Col>
            </>
          )}
        </Row>
        <br />
        <hr />
      </div>
      <FilterUbication
        filterNumbers={form}
        cityState={cityState}
        npa={npa}
        npaNxx={npaNxx}
        setNpaNxx={setNpaNxx}
        setCityState={setCityState}
        setNpa={setNpa}
        loading={loading}
        setLoading={setLoading}
        onSubmitNumbers={onSubmitNumbers}
        resetActive={resetActive}
        errorNumbers={errorNumbers}
        setErrorNumbers={setErrorNumbers}
      />
      <ListNumbers
        selectedNumbers={selectedNumbers}
        setSelectedNumbers={setSelectedNumbers}
        loading={loading}
        listOfNumbers={listOfNumbers}
        cityState={cityState}
        npa={npa}
        npaNxx={npaNxx}
        filterNumbers={form}
        setActiveSelected={setActiveSelected}
        activeSelected={activeSelected}
        setNumberSelected={setNumberSelected}
      />
      <CardOrder
        activeSelected={activeSelected}
        setActiveSelected={setActiveSelected}
        setSelectedNumbers={setSelectedNumbers}
        selectedNumbers={selectedNumbers}
      />
      {/*   <br /> <br />
      <Row style={{marginRight: "1em"}}>
        <Col sm="12" style={{textAlign: "right"}}>
          <Button
            style={{width: "100px"}}
            color="primary"
            disabled={activeSelected.length === 0 ? true : false}
            onClick={() => next()}
          >
            {" "}
            Next
          </Button>
                  <Button
            style={{width: "100px", marginLeft: "1rem"}}
            outline
            color="secondary"
            onClick={() => skip()}
          >
            {" "}
            Skip
          </Button> 
        </Col>
      </Row> */}
    </>
  );
}
