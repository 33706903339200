const getDateLocal = (infoDate) => {
  const UTC1970 = 62167219200;
  infoDate = parseInt(infoDate) - UTC1970;
  const dataLocal = new Date(parseInt(infoDate * 1000));

  let hours = dataLocal.getHours();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;

  return `${
    dataLocal.getMonth() === 0 ? "01" : `${dataLocal.getMonth()+1}`
  }-${dataLocal.getDate()}-${dataLocal.getFullYear()} ${
    hours < 10 ? `0${hours}` : `${hours}`
  }:${
    dataLocal.getMinutes() < 10
      ? `0${dataLocal.getMinutes()}`
      : `${dataLocal.getMinutes()}`
  }:${
    dataLocal.getSeconds() < 10
      ? `0${dataLocal.getSeconds()}`
      : `${dataLocal.getSeconds()}`
  } ${ampm}`;
};

export default getDateLocal;
