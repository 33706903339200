import React, { Component } from "react";
import { Container, Col, Row, CardTitle, Card, Input, Button } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import petition_get from "../../petitions/petition_get";
import petition_patch from "../../petitions/petition_patch";
import petition_delete from "../../petitions/petition_delete";
import Switch from "react-switch";
import ModalUtil from "../../../components/utils/modal";
import NewCard from "../NewCard/NewCard";
import YesOrNo from "../../../components/utils/YesOrNo";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

export default class ViewStaments extends Component {
  constructor(props) {
    super(props);
    this.date = new Date();
    this.month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    this.userData = JSON.parse(window.localStorage.getItem("authUser"));
    this.state = {
      data: [],
      cardInfo: {},
      checked: false,
      modal: false,
      modalbody: (
        <NewCard
          diabledButtonAccept={this.diabledButtonAccept}
          enabledButtonAccept={this.enabledButtonAccept}
          saveValues={this.saveValues}
        />
      ),
      title: "Add new credit/debit card",
      spinner: false,
      success_msg: false,
      automatic: false,
      titleAlert:
        "You currently do not have a credit card on file. Would you like to add one now?",
      disabledAccept: true,
      inputs: {},
      disabledSwitch: false,
      changeTitle: false,
    };
    toastr.options = {
      showDuration: 300,
    };
  }

  diabledButtonAccept = () => {
    this.setState({ disabledAccept: true });
  };
  enabledButtonAccept = () => {
    this.setState({ disabledAccept: false });
  };
  saveValues = (val) => {
    this.setState({ inputs: val });
  };

  toggle = () => this.setState({ modal: !this.state.modal });
  toggleAlert = () => this.setState({ success_msg: false });

  automaticAlert = () =>
    this.setState({ automatic: false, checked: !this.state.checked });
  automaticOnConfirm = () => {
    this.setState({ automatic: false });
    let data = [
      this.userData.account.billing_api_id,
      { automatic_payments: this.state.checked },
    ];
    this.setState({ disabledSwitch: true });
    petition_patch("NewCreditcard", data)
      .then(({ data: { result } }) => {
        this.setState({ disabledSwitch: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  childFunction = () => {
    this.setState({ spinner: !this.state.spinner });
    this.saveCreditCard();
  };
  onConfirm = () => {
    this.toggle();
    this.setState({ success_msg: false });
  };
  modal() {
    if (this.state.modal) {
      return (
        <ModalUtil
          modal={this.state.modal}
          toggle={this.toggle}
          modalbody={this.state.modalbody}
          childFunction={this.childFunction}
          title={this.state.title}
          spinner={this.state.spinner}
          acceptButton="Save"
          cancelButton="Cancel"
          disabledAccept={this.state.disabledAccept}
        />
      );
    }
  }

  loading() {
    if (this.state.automatic) {
      return <ModalUtil modal={this.state.automatic} spinner={true} />;
    }
  }
  saveCreditCard() {
    let data = [
      this.userData.account.billing_api_id,
      { ...this.state.inputs, automatic_payments: true },
    ];
    petition_patch("NewCreditcard", data)
      .then(({ data: { result } }) => {
        window.location.reload(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    petition_get("account", this.userData.account.billing_api_id)
      .then(({ data: { result } }) => {
        this.setState({
          data: result,
          cardInfo: result.credit_card_info,
          checked: result.automatic_payments,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  checked() {
    this.setState({ checked: !this.state.checked });
    if (this.state.data.credit_card_info.last_4 === null) {
      this.setState({
        checked: false,
        success_msg: true,
      });

      // this.toggle();
    } else {
      this.setState({ automatic: true });
      let data = [
        this.userData.account.billing_api_id,
        { automatic_payments: !this.state.checked },
      ];
      let susccessText =
        !this.state.checked === true
          ? "Automatic payments have been enabled on your account"
          : "Automatic payments have been disabled on your account";
      let errorText =
        !this.state.checked === true
          ? "There was an issue enabling automatic payments on your account. Try again later or contact our support team"
          : "There was an issue disabling automatic payments on your account. Try again later or contact our support team";
      this.setState({ disabledSwitch: true });
      petition_patch("NewCreditcard", data)
        .then(({ data: { result } }) => {
          this.setState({ disabledSwitch: false });
          this.setState({ automatic: false });
          toastr.success(susccessText, "Success");
        })

        .catch((error) => {
          console.log(error);
          toastr.error(errorText, "Error");
          this.setState({ checked: !this.state.checked });
        });
    }
  }

  deleteCC() {
    this.setState({ automatic: true });
    petition_delete("deletecc", this.userData.account.billing_api_id)
      .then(({ data: { result } }) => {
        petition_patch("NewCreditcard", data)
          .then(({ data: { result } }) => {
            this.setState({ disabledSwitch: false });
            window.location.reload(true);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });

    let data = [
      this.userData.account.billing_api_id,
      { automatic_payments: false },
    ];
    this.setState({ disabledSwitch: true });
  }

  addCC() {
    this.toggle();
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Automatic Payments"
              breadcrumbItem="Automatic Payments"
            />
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "4em",
              }}
            >
              <Col md="8">
                <Card body>
                  <CardTitle>
                    <strong>
                      {"Your bill will automatically be generated on " + this.month[this.date.getMonth()] +
                        " " +
                        this.state.data.billing_cycle_month_date +
                        ", " +
                        this.date.getFullYear() + "."}
                    </strong>
                  </CardTitle>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <h6 style={{ marginRight: "1em" }}>
                      Automatic Payments Are:{" "}
                    </h6>
                    <div>
                      <Switch
                        onChange={() => this.checked()}
                        checked={this.state.checked}
                        disabled={this.state.disabledSwitch}
                      />
                    </div>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Button
                        color={
                          this.state.cardInfo.last_4 === null
                            ? "primary"
                            : "danger"
                        }
                        onClick={() =>
                          this.state.cardInfo.last_4 === null
                            ? this.addCC()
                            : this.deleteCC()
                        }
                      >
                        {this.state.cardInfo.last_4 === null
                          ? "Add CC"
                          : "Delete CC"}
                      </Button>
                    </div>
                  </div>
                  <br />
                </Card>
              </Col>

              <Col
                md="8"
                style={{
                  display: this.state.cardInfo.last_4 === null ? "none" : null,
                }}
              >
                <Card body>
                  <CardTitle>
                    <strong>{"Credit Card Info"}</strong>
                  </CardTitle>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginBottom: "0.5em",
                    }}
                  >
                    <Button
                      style={{ maxWidth: "8em" }}
                      color="primary"
                      onClick={() => {
                        this.setState({ title: "Edit Credit Card" });
                        this.addCC();
                      }}
                    >
                      Edit CC
                    </Button>
                  </div>
                  <Row>
                    <Col>
                      <div>
                        <label htmlFor="firstName">First Name</label>
                        <Input
                          id="firstName"
                          type="text"
                          value={this.state.cardInfo.first_name || ""}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <label htmlFor="lastName">Last Name</label>
                        <Input
                          id="lastName"
                          type="text"
                          value={this.state.cardInfo.last_name || ""}
                          disabled
                        />
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <div>
                        <label htmlFor="cardNumber">Card Number</label>
                        <Input
                          id="cardNumber"
                          type="text"
                          value={"**** **** **** " + this.state.cardInfo.last_4}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <div style={{ width: "30%" }}>
                          <label htmlFor="expiration">Expiration</label>
                          <Input
                            type="text"
                            name="select"
                            id="expiration"
                            style={{ width: "100%" }}
                            value={this.state.cardInfo.exp_month || ""}
                            disabled
                          ></Input>
                        </div>
                        <div style={{ width: "30%" }}>
                          <label htmlFor="year">Year</label>
                          <Input
                            type="text"
                            name="select"
                            id="year"
                            value={this.state.cardInfo.exp_year || ""}
                            disabled
                          ></Input>
                        </div>
                        <div style={{ width: "30%" }}>
                          <label htmlFor="type">Type</label>
                          <Input
                            type="text"
                            name="select"
                            id="text"
                            value={this.state.cardInfo.type || ""}
                            disabled
                          ></Input>
                        </div>
                      </div>
                    </Col>

                    {/* <Col
                      style={{
                        display:
                          this.state.cardInfo.last_4 === "null" ? null : "none",
                      }}
                    >
                      <Card body>
                        <CardTitle>
                          <strong>{"Add Credit Card"}</strong>
                        </CardTitle>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            marginBottom: "0.5em",
                          }}
                        >
                          <Button style={{ maxWidth: "8em" }} color="primary">
                            Add credit Card
                          </Button>
                        </div>
                      </Card>
                    </Col> */}
                  </Row>
                </Card>
              </Col>
            </Row>
            {this.modal()}
            <YesOrNo
              success_msg={this.state.success_msg}
              onCancel={this.toggleAlert}
              onConfirm={this.onConfirm}
              title={this.state.titleAlert}
            />

            {/* <YesOrNo
              success_msg={this.state.automatic}
              onCancel={this.automaticAlert}
              onConfirm={this.automaticOnConfirm}
              title={
                this.state.checked === true
                  ? "Automatic payments have been enabled "
                  : "Automatic payments have been disabled" + "on your account"
              }
            /> */}
            {this.loading()}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
