import React, { useState, useEffect } from "react";
import {
  Card, Button, Col, Row, Spinner, NavItem,
  NavLink,
  TabContent,
  TabPane,
  Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import CustomBreadCrum from "../../../../components/utils/CustomBreadCrum/CustomBreadCrum";
import CustomTable from "../../../../components/utils/CustomTable/CustomTable";
import AddIcon from "@material-ui/icons/Add";
import AddKeyModals from "../Keys/Modals/AddKeyModals";
import DeleteKeyModals from "../Keys/Modals/DeleteKeyModals";
import petition_get from "../../../petitions/petition_get";
import petition_post from "../../../petitions/petition_post";
import petition_delete from "../../../petitions/petition_delete";
import DeleteIcon from "@material-ui/icons/Delete";
import classnames from "classnames";

export default function Keys() {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalAddKey, setModalAddKey] = useState(false);
  const [modalDeleteKey, setModalDeleteKey] = useState(false);
  const [reloadData, setReloadData] = useState(true);
  const [customActiveTab, setCustomActiveTab] = useState("1");

  const [dataTable, setDataTable] = useState(null);
  const [dataTableUsers, setDataTableUsers] = useState(null);

  const [listUsers, setListUsers] = useState([])

  const handleOpenKeysModal = (element) => {
    setModalDeleteKey(element);
  };

  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  }

  //Get Table Data
  useEffect(() => {
    if (reloadData) {
      setReloadData(false);
      setLoading(true);
      petition_get("apiKeys", { id, user_null: true })
        .then(({ data: result }) => {
          setDataTable({
            title: [{ title: "Api Key", key: "api_key" }],
            content: [...result.result],
            actions: {
              title: "Keys",
              content: [
                {
                  icon: <DeleteIcon />,
                  handleClick: handleOpenKeysModal,
                },
              ],
            },
          });
          console.log(result.result);
          setLoading(false);
        })
        .catch((error) => { });

      petition_get("itpvoiceUsers", { id: id })
        .then(({ data: result }) => {


          //Get list of users 
          let users = result.result;
          let listUsersCopy = [{ value: "", label: "Select One" }]
          result.result.map(element => listUsersCopy.push({ value: element.id, label: `${element.first_name} ${element.last_name} ${element.presence_id}` }))
          setListUsers(listUsersCopy)

          petition_get("apiKeys", { id, user_null: false })
            .then(({ data: result }) => {

              let keys = []

              result.result.map(element => {
                let findUser = users.find(elementTwo => elementTwo.id === element.user_id)
                if (findUser)
                  keys.push({ ...element, user_name: `${findUser.first_name} ${findUser.last_name}` })

              })

              console.log(keys)
              setDataTableUsers({
                title: [{ title: "Api Key", key: "api_key" }, { title: "User", key: "user_name" }],
                content: [...keys],
                actions: {
                  title: "Keys",
                  content: [
                    {
                      icon: <DeleteIcon />,
                      handleClick: handleOpenKeysModal,
                    },
                  ],
                },
              });
              console.log(result.result);
              setLoading(false);

            })
            .catch((error) => { console.log(error) });

        })
        .catch((error) => console.log(error));


    }

  }, [reloadData]);

  const addApiKey = (user_id) => {
    petition_post("apiKeys", { id: id, data: modalAddKey === "User" ? { user_id } : {} })
      .then(({ data: result }) => {
        setModalAddKey(false);
        setReloadData(true);
      })
      .catch((error) => { });
  };

  const deleteApiKey = () => {
    petition_delete("apiKeys", { id: id, pk: modalDeleteKey.pk })
      .then(({ data: result }) => {
        setModalDeleteKey(false);
        setReloadData(true);
      })
      .catch((error) => { });
  };

  return (
    <>
      <AddKeyModals
        setModal={setModalAddKey}
        modal={modalAddKey}
        loading={loading}
        addApiKey={addApiKey}
        listUsers={listUsers}
      />
      <DeleteKeyModals
        setModal={setModalDeleteKey}
        modal={modalDeleteKey}
        loading={loading}
        deleteApiKey={deleteApiKey}
      />
      <div className="page-content">
        <CustomBreadCrum title="API" subtitle="Key" />

        {loading ? (
          <div style={{ textAlign: "center" }}>
            <Spinner
              animation="grow"
              style={{
                margin: "10vh auto",
                height: "10vh",
                width: "10vh",
              }}
            />{" "}
          </div>
        ) : (
          <>
            <Card className="p-3">
              <div className="text-right mb-3">

                <Row>
                  <Col>

                    <UncontrolledDropdown>
                      <DropdownToggle href="#" className="card-drop" tag="i">

                        <Button

                          color="primary"
                        >
                          <AddIcon /> Add Key

                        </Button>
                      </DropdownToggle>
                      <DropdownMenu right className="card-dropdown">

                        <DropdownItem
                          onClick={() => {
                            setModalAddKey("Global");
                          }}  >
                          Global API Keys</DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            setModalAddKey("User");
                          }}>
                          User API Keys</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>

                  </Col>
                </Row>
              </div>
              <Nav
                tabs
                className="nav-tabs-custom  flex-column flex-md-row  text-center  "
              >
                <NavItem className="pt-3 pt-md-0">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleCustom("1");
                    }}
                  >
                    <span>Global API Keys</span>
                  </NavLink>
                </NavItem>
                <NavItem className="pt-3 pt-md-0">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleCustom("2");
                    }}
                  >
                    <span>User API Keys</span>
                  </NavLink>
                </NavItem>


              </Nav>
              <TabContent activeTab={customActiveTab}>
                <TabPane tabId="1" className="p-3">
                  <CustomTable data={dataTable} pagination={10} />
                </TabPane>
              </TabContent>
              <TabContent activeTab={customActiveTab}>
                <TabPane tabId="2" className="p-3">
                  <CustomTable data={dataTableUsers} pagination={10} />
                </TabPane>
              </TabContent>


            </Card>

          </>
        )}
      </div>
    </>
  );
}
