import React, {useState} from "react";

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
} from "reactstrap";
import phoneImg from "../../assets/images/phone-image.svg";
import NavTabCustom from "../../components/utils/NavTabCustom";
import OrderDetails from "./OrderDetails";
import OrderItems from "./OrderItems";
export default function ModalAddImages({modal, setModal}) {
  return (
    <Modal
      centered
      size={"lg"}
      isOpen={modal === false ? false : true}
      className="info"
    >
      <ModalHeader className="bg-soft-primary">
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary">View order details</h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img
              style={{width: "100px"}}
              src={phoneImg}
              alt=""
              className="img-fluid"
            />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody className="text-center">
        <NavTabCustom
          options={[
            {title: "Details", component: <OrderDetails modal={modal} />},
            {title: "Order Items", component: <OrderItems modal={modal} />},
          ]}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          outline
          color="secondary"
          onClick={() => {
            setModal(false);
          }}
        >
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
}
