import styled from "styled-components";

const OrderNewNumbersStyled = styled.div`
  .page-content {
    padding-top: 85px;
  }
  .container {
    &-header {
      display: flex;
      align-items: flex-end;
      margin-bottom: 13px;
      .header-arrow {
        margin: 0 12px;
      }
      h3 {
        font-weight: 400;
        margin: 0;
      }
      h4 {
        font-size: 15px;
        color: #a6b0cf;
        margin: 0;
      }
    }
    &-information {
      background: #2a3042;
      box-shadow: 0 0 1.5rem rgba(18, 38, 63, 0.1);
      border: 9px;
      padding-bottom: 1rem;
      margin-top: 0.3rem;
      margin-bottom: 2rem;
    }
  }
  .next {
    text-align: right;
    margin-bottom: 2rem;
    .btn-cancel,
    .btn-next {
      background: #2a3042;
      border: 1px solid #79829c;
      font-size: 10px;
      min-width: 83px;
      min-height: 12px;
    }
    .btn-next {
      margin-left: 15px;
      background: #536be1;
      border: 1px solid #536be1;
      color: white;
      border: none;
    }
  }
  .container-reset {
    width: 100%;
    text-align: center;
  }
  .btn-reset {
    background: transparent;
    margin: 0 auto;
    min-width: 300px;
    height: 37px;
    font-size: 13px;
    text-align: center;
    color: white;
    border: none;
    background: #536be1;
    border-radius: 3px;
  }
  .container-check {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    .check {
      .check-img {
        font-size: 15px;
        position: relative;
        right: 0;
        bottom: 18px;
        background: #536be1;
        border-radius: 3px;
        color: white;
      }
      .check-input {
        z-index: 100;
        position: relative;
        appearance: none;
        height: 15px;
        width: 15px;
        border-radius: 3px;
        display: block;
        border: none;
        box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.1);
        background: #2e3548;

        &:focus {
          outline: none;
        }
        &:checked {
          background: transparent;
        }
      }
    }
    .text {
      padding-left: 10px;
      font-size: 13px;
      color: #79829c;
    }
  }
`;

const NavbarStyle = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #222736;
  padding: 0 15px;
  .title {
    font-size: 15px;
    padding: 15px;
    font-weight: 500;
  }
  .menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn-back {
      display: flex;
      align-items: center;
      color: #a6b0cf;
      background: transparent;
      border: 1px solid rgba(255, 255, 255, 0.03);
      height: 60%;
      .icon-arrow {
        transform: rotate(180deg);
        padding-left: 7px;
      }
    }
    .btn-menu {
      padding-left: 15px;
      color: #a6b0cf;
      border: none;
      background: transparent;
    }
  }
`;

const StepsStyled = styled.div`
  padding: 10px;
  .row {
    margin: 0;
    border-radius: 2px;
    color: white;
    height: 50px;
    background: #2f3652;
    .step-item {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: white;
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      margin-right: 10px;
      border-radius: 50%;
      border: 2px solid #556ee6;
      color: rgb(85, 110, 230);
      font-weight: 500;
    }
    .active-step {
      background: #556ee6;
      border: 2px solid #556ee6;
      color: white;
    }
  }
  @media (max-width: 992px) {
    .row {
      height: auto;
      padding: 10px;
      .step-item {
        padding: 10px 0px;
      }
    }
  }
`;

const FilterStyled = styled.div`
  margin: 30px;
  margin-bottom: 0;

  .form-group {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
    margin-right: 30px;
    padding: 10px 0;
    .label-input {
      font-weight: 600;
      width: 15%;
      margin: 0;
    }
    input,
    select {
      border: 1px solid red;
      width: 35%;
      // background: #2e3548;
      // color: rgba(255, 255, 255, 0.4);
      padding: 6px 8px;
      border-radius: 3px;
      border: none;
      // box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.1);
      &:focus {
        // outline: none;
      }
    }

    .input-extension {
      width: 17.5%;
    }
    select {
      -webkit-appearance: none;
    }
    select:focus,
    select:hover {
      background: #2e3548;
      color: rgba(255, 255, 255, 0.4);
      outline: none;
    }
    .arrow-type {
      position: relative;
      right: 20px;
      top: 5px;
      font-size: 10px;
      transform: rotate(90deg);
    }

   
  }
  .form{
    display: flex; 

  }
    .form-change{
    flex-direction: column;
    width: 33%;
    margin-right: 30px;
    .label-input{
      width: 100%;
      padding-bottom: 7px;
    }
    .form-control{width: 100%;} 

    .arrow-type {
      position: absolute;
      right: 10px;
      font-size: 10px;

      transform: rotate(90deg);
      top: 50px;
    }
       .container-radios {
       display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: left;
      height: 35px;
      
      width: 100%;

      input {
        position: relative;
        appearance: none;
        height: 15px;
        width: 15px;
        border-radius: 50%;

        display: block;
        border: none;
        box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.1);
        background: rgba(255, 255, 255, 0.05);

        &:focus {
          outline: none;
        }
        &:checked {
          background: #536be1;
          &::before {
            border: 1px solid transparent;
            content: "";
            display: block;
            position: relative;
            right: 3.2px;
            bottom: 2px;
            height: 7px;
            width: 7px;
            background: white;
            border-radius: 50%;
          }
        }
      }
      label {
        margin: 0;
        margin-right: 20px;
        margin-left: 10px;
      }
  }
  }
  .radios{
    width: 50%;
 
  }


  .next {
    text-align: right;
    margin: 0;
    padding: 0;
    .btn-cancel,
    .btn-next {
      background: #2a3042;
      border: 1px solid #79829c;
      font-size: 15px;
      min-width: 83px;
      font-weight: 600;
      min-height: 12px;
    }
    .btn-next {
      margin-left: 15px;
      background: #536be1;
      border: 1px solid #536be1;
      color: white;
      border: none;
    }
  }
  @media (max-width: 992px) {
    form {
      flex-direction: column;
    }
    .form-group {
      width: 100%;
    }
  }
`;

const InfoNumbersStyled = styled.div`
  margin: 30px;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  h3 {
    font-size: 16px;
    color: #a6b0cf;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 3px;
    opacity: 0.6;
  }
  span {
    font-weight: 600;
  }
`;

const FilterUbicationStyled = styled.div`
  // margin: 30px;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  form {
    display: flex;
    position: relative;
  }
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 33%;
    margin-right: 30px;
    position: relative;
    .form-title {
      font-weight: 600;
      
    }
    // select {
    //   -webkit-appearance: none;
    // }
    select,
    input {
      width:100%;
      // background: #2e3548;
      // color: rgba(255, 255, 255, 0.4);
      // padding: 6px 8px;
      // border-radius: 3px;
      // border: none;
      // box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.1);
      // &:focus {
      //   outline: none;
      // }
    }
    // input::-webkit-inner-spin-button {
    //   appearance: none;
    // }
    // select:focus,
    // select:hover {
    //   background: #2e3548;
    //   color: rgba(255, 255, 255, 0.4);
    //   outline: none;
    // }
    // .arrow-country {
    //   position: absolute;
    //   right: 8px;
    //   font-size: 10px;
    //   transform: rotate(90deg);
    //   top: 38px;
    // }
  }
  .radio {
    width: 100%;
    .container-radios {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 35px;
      input {
        border: none;
        box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.1);
        background: rgba(255, 255, 255, 0.05);
      }
      label {
        margin: 0;
        margin-right: 20px;
        margin-left: 5px;
      }
    }
  }

  .btn-numbers {
    display: flex;

    align-items: center;
    button {
      min-width: 128px;
      height: 37px;
      font-size: 13px;
      text-align: center;
      color: white;
      border: none;
      background: #536be1;
      position: relative;
      border-radius: 3px;
    }
    p {
      color: red;
      padding-left: 10px;
      font-size: 13px;
      margin-bottom: 0;
      font-weight: 300;
      text-shadow: 0px 0px 10px red;
    }
    margin-top: 15px;
    .spinner-submit {
      position: relative;
      right: 3px;
    }
  }

  .spinner-btn {
    position: relative;
    top: 45px;
  }
  @media (max-width: 992px) {
    form {
      flex-wrap: wrap;
    }
    .form-group {
      width: 80%;
    }
    input {
      width: 100%;
    }
  }
`;

const ListNumbersStyled = styled.div`
  margin: 30px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  .container {
    &-numbers {
      background: #2e3548;
      border: 1px solid #363e54;
      border-top: none;
      border-radius: 10px;
      border-top-left-radius: 0;
      .list-numbers {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        margin: 0px 10px;
        padding: 0;
        padding-top: 30px;
        .number {
          width: 25%;
          display: flex;
          margin: 0;
          .check-img {
            font-size: 15px;
            position: relative;
            right: 15px;
            background: #536be1;
            border-radius: 3px;
            color: white;
          }
          .check-number {
            z-index: 100;
            position: relative;
            appearance: none;
            height: 15px;
            width: 15px;
            border-radius: 3px;
            display: block;
            border: none;
            box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.1);
            background: #2e3548;

            &:focus {
              outline: none;
            }
            &:checked {
              background: transparent;
            }
          }
        }
      }

      .btn-pag {
        border: 1px solid #a6b0cf;
        background: transparent;
        border-radius: 5px;
        margin: 10px 20px;
        color: #a6b0cf;
        padding: 5px;
        &:hover {
          transform: scale(1.1);
        }
        &:focus {
          outline: none;
        }
      }
    }
    &-head {
      display: flex;

      .search,
      .available {
        display: flex;
        align-items: center;
        background: #2e3548;
        height: 58px;
        padding: 0 20px;
        border: 1px solid #363e54;
        border-bottom: none;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      .available {
        background: transparent;
        border: none;
      }
    }
  }

  @media (max-width: 992px) {
    .list-numbers {
      .number {
        justify-content: center;
        width: 50% !important;
      }
    }
    .container-numbers {
      .btn-pag {
        margin: 10px 5px;
      }
    }
  }
  .btn-numbers {
    display: flex;
    margin-top: 2.5rem;
    align-items: center;
    button {
      min-width: 128px;
      height: 37px;
      font-size: 13px;
      text-align: center;
      color: white;
      border: none;
      background: #536be1;
      position: relative;
      border-radius: 3px;
    }
    p {
      color: #79829c;
      padding-left: 10px;
      font-size: 11px;
      margin-bottom: 0;
      font-weight: 300;
    }
    .spinner-submit {
      position: relative;
      right: 3px;
    }
  }
`;

const CardOrderStyled = styled.div`
  margin: 30px;
  h3 {
    font-size: 16px;
    color: #a6b0cf;
    margin-bottom: 20px;
  }
  .container-card {
    background: #2e3548;
    padding: 14px;
    border-radius: 10px;
    margin-bottom: 20px;
    .total {
      padding-top: 20px;
      p {
        padding-top: 10px;
      }
      &-price {
        padding: 0 5px;
      }
    }
    .subtitle {
      .col-4 {
        padding: 0;
      }
      margin: 0;
      padding: 0;
      padding: 14px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    .title {
      .col-4 {
        padding: 0;
      }
      margin: 0;
      padding: 0;
      padding-bottom: 14px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    .content {
      padding: 0;
      position: relative;
      margin: 0;
      padding: 12px 0;
      .col-4 {
        padding: 0;
      }
      .btn-delete {
        position: absolute;
        background: transparent;
        border: none;
        right: 0;
        color: #a6b0cf;

        &:focus {
          outline: none;
        }
      }
    }
  }
`;

const PeriodCardStyled = styled.div`
  margin: 30px;

  .title {
    background: #32384b;
    min-height: 50px;
    display: flex;
    align-items: center;
  }
  .number {
    min-height: 50px;
    display: flex;
    color: #79829c;
    font-size: 12px;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    .row {
      display: flex;
      align-items: center;
    }
  }
  strong {
    color: #79829c;
    font-size: 13px;
  }
  .discounts {
    text-align: right;
    margin-top: 20px;
    padding-right: 65px;
    span {
      margin-left: 30px;
    }
  }
`;

const ConfirmOrderStyled = styled.div`
  margin: 30px;
  .order-confirm,
  .order-fail {
    text-align: center;
    font-size: 30px;
    padding-top: 20px;
    color: green;
  }
  .order-fail {
    color: red;
  }
  .container-back {
    padding-top: 3rem;
    width: 100%;
    text-align: center;
  }
  .btn-back {
    background: transparent;
    margin: 0 auto;
    min-width: 200px;
    padding: 10px;
    height: 37px;
    font-size: 13px;
    text-align: center;
    color: white;
    border: none;
    background: #536be1;
    border-radius: 3px;
  }
`;

export {
  OrderNewNumbersStyled,
  NavbarStyle,
  StepsStyled,
  FilterStyled,
  InfoNumbersStyled,
  FilterUbicationStyled,
  ListNumbersStyled,
  CardOrderStyled,
  PeriodCardStyled,
  ConfirmOrderStyled,
};
