import React, { useEffect, useState } from "react";

import petition_get from "../../petitions/petition_get";

import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { sip, userAgentStart, userAgentEnd } from "./../../../store/actions";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import PhoneCall from "./PhoneCall/PhoneCall";
import ButtonHover from "../../../components/utils/ButtonHover";
import jwt_decode from "jwt-decode";
function WebPhone({ userAgentStart, userAgentEnd, updateActivePhone }) {


  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const [activeCall, setActiveCall] = useState(null)
  const [activePhone, setActivePhone] = useState(false);
  const [userStatusProfile, setUserStatusProfile] = useState(null);
  const [realm, setRealm] = useState(null);
  const [users, setUsers] = useState(null);

  const history = useHistory()

  useEffect(() => {

    let petitions = async () => {
      let tempUsers;
      await petition_get("itpvoiceAll", { id: id })
        .then((result) => { setRealm(result.data.result.data.realm) })
        .catch((error) => console.log(error));

      await petition_get("operatorPanel", { id })
        .then(({ data: result }) => { tempUsers = result.result; setUsers(result.result) })
        .catch((error) => console.log(error));

      /*   await petition_get("MyDevices", { id: id })
          .then(({ data: result }) => {
            let filterDevices = result.result.filter(element => element.name === "web_phone")
  
  
            petition_get("device", { id: id, deviceId: filterDevices[0].id })
              .then(({ data: result }) => {
  
                let findUser = tempUsers.filter(element => element.id === result.result.owner_id)
                setUserStatusProfile({ ...result.result, ...findUser[0], web_phone: { sip: result.result.sip } })
              })
              .catch((error) => console.log(error))
  
  
          })
          .catch((error) => {
            console.log(error);
          }); */

      /* await petition_get("operationPanel", { id: id })
        .then(({ data: result }) => {
          console.log("DatosError", result.result)
          setUserStatusProfile(result.result)
        })
        .catch((error) => console.log(error.response)); */


    }
    petitions()

  }, [])


  useEffect(() => {


    var enabled2 = false;
    window.addEventListener('click', function (e) {
      if (!document.getElementById('phoneCall')) return
      if (document.getElementById('phoneCall').contains(e.target)) enabled2 = true
      else enabled2 = false
    })

    window.addEventListener('keydown', (event) => {
      if (enabled2) {
        let button = document.getElementById(event.key)
        if (button && document.activeElement.id !== "numberField") button.click()
      }
    })

  }, [])


  useEffect(() => {

    let dateTime = Date.now();
    let timestamp = Math.floor(dateTime / 1000);

    const token = JSON.parse(localStorage.getItem("authUser"));
    let jwt = jwt_decode(token.access_token);



    if (jwt.exp > timestamp) {
      petition_get("refresh", "")
        .then(({ data: { result } }) => localStorage.setItem("authUser", JSON.stringify(result)))
        .catch((error) => window.location.href = "/logout");
    }


    let idInterval = setInterval(() => {

      let dateTime = Date.now();
      let timestamp = Math.floor(dateTime / 1000);

      const token = JSON.parse(localStorage.getItem("authUser"));
      let jwt = jwt_decode(token.access_token);



      if (jwt.exp > timestamp) {
        petition_get("refresh", "")
          .then(({ data: { result } }) => localStorage.setItem("authUser", JSON.stringify(result)))
          .catch((error) => window.location.href = "/logout");
      }

      return () => { clearInterval(idInterval) }

    }, 3600000)
  }, [])


  useEffect(() => {
    let chatBox = document.querySelector(".woot--bubble-holder")
    if (chatBox) chatBox.classList.add("d-none")

    return () => { if (chatBox) chatBox.classList.remove("d-none") }

  }, [document.querySelector(".woot--bubble-holder")])

  return (
    <div style={{ position: "absolute", background: "red" }}>
      <div
        style={{ width: !activePhone ? "350px" : "0", transition: "1s all", }}
        className="d-none d-xl-block"
        id="phoneCall"
      >
        <PhoneCall
          realm={realm}
          activePhone={activePhone}
          userAgentEnd={userAgentEnd}
          userAgentStart={userAgentStart}
          userStatusProfile={userStatusProfile}
          setUserStatusProfile={setUserStatusProfile}
          users={users}
        />
      </div>
      <ButtonHover
        style={{
          position: "fixed",
          top: "80px",
          right: "20px",
          zIndex: "1000",
          transform: `rotate(${!activePhone ? "0deg" : "180deg"})`,
        }}
        onClick={() => {
          setActivePhone(!activePhone);
          updateActivePhone(activePhone)
        }}
      >
        <ArrowForwardIosIcon />
      </ButtonHover>
    </div>


  );
}
const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
    ITPVoice: state.ITPVoice,
  };
};

export default connect(mapStateToProps, { sip, userAgentStart, userAgentEnd })(
  WebPhone
);
