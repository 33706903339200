import React from "react";
import {Container} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Tickets from "./Tickets";
export default function Support() {
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Support" breadcrumbItem="Support" />
        <Tickets />
      </Container>
    </div>
  );
}
