import React from "react";
import {Table} from "reactstrap";
export default function StepThree({form,pricePerDid}) {


  return (
    <div>
      <span style={{fontSize: "1rem", margin: "1rem 0"}}>Orden Summary</span>
      <Table responsive className="mt-3">
        <thead>
          <tr>
            <th>Recurring Changes - monthly</th>
            <th>Qty </th>
            <th>SubTotal* </th>
          </tr>
        </thead>
        <tbody>
          {form.length > 0 && (
            <tr>
              <th>Additional Local Numbers - Domestic </th>
              <th>{form.length}x${pricePerDid} </th>
              <th>${form.length * pricePerDid} </th>
            </tr>
          )}

          <tr className="pt-5">
            <th className="pt-5">Estimated Total</th>
            <th className="pt-5"></th>
            <th className="pt-5">
              {" "}
              <strong>${form.length * pricePerDid}</strong>
            </th>
          </tr>
        </tbody>
      </Table>
      <div className="col-12">
        *This price does not include calculations for prorated amounts, taxes,
        fees and/or shipping.
      </div>
    </div>
  );
}
