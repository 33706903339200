import React, { useState, useEffect } from "react";
import petition_get from "../../../../petitions/petition_get"
import { Row, Col, Table } from "reactstrap";
export default function StepRepeat({
  supportedLosingCarriers,
  formMetadata,
  setFormMetadata,
  step,
  states,
  // cities
  inputError
}) {
  const [selectedState, setSelectedState] = useState(false)
  const [cities, setCities] = useState([]);
  const [enabled, setEnabled] = useState(false);
  const [porting_state_code, setPorting_state_code] = useState("");
  const hangleChangeMetadata = (e) => {
    setFormMetadata({ ...formMetadata, [e.target.name]: e.target.value });
  };

  // const selectedEstate = () =>{
  //   console.log(e.target.value);hangleChangeMetadata(e.target.value,true);

  // }

  useEffect(() => {

    if (enabled === false) {
      changeEstate(states[0].pk)
      setPorting_state_code(states[0].pk);
      setEnabled(true);
    }
  }, [states]);

  const changeEstate = (pk) => {
    setSelectedState(false)
    let selected = states.filter((el) => el.pk == pk)
    setFormMetadata({ ...formMetadata, porting_state_code: selected[0].shortcode });
    setPorting_state_code(selected[0].pk);
    petition_get("cities2", pk)
      .then(({ data: result }) => {
        setCities([{ city_alias: "", name: "Select One" }, ...result.result]);
        setSelectedState(true)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      {supportedLosingCarriers.map((element, i) => {
        if (i === step) {
          return (
            <Row key={i} className="pt-3 pl-3  ">
              <Col className="col-12 col-md-10 col-lg-6  m-auto m-lg-0 ">
                <Table bordered hover>
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center">
                        {element.LosingCarrierName} {"   "} ( {i + 1} / {supportedLosingCarriers.length} )
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {typeof element.TnList.Tn === "string" ? (
                      <tr>
                        <td className="text-center">{element.TnList.Tn}</td>
                      </tr>
                    ) : (
                      element.TnList.Tn.map((element, index) => (
                        <tr key={index}>
                          <td className="text-center">{element}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </Col>
              <Col className="col-12 col-md-10 col-lg-6  m-auto m-lg-0  ">
                <div className="form-group row p-3 p-md-0  px-md-3  ">
                  <label className="col-12  p-0">
                    Current Provider Information:
                  </label>{" "}
                  <label
                    className="col-12 mt-2 p-0"
                    style={{ cursor: "pointer" }}
                    htmlFor="porting_business_type"
                  >
                    Bussiness Type:
                  </label>
                  {console.log(inputError)}
                  <select
                    value={formMetadata.porting_business_type}
                    onChange={(e) => hangleChangeMetadata(e)}
                    className={`form-control ${inputError === "porting_business_type" && "form-control-error"}`}
                    name="porting_business_type"
                    id="porting_business_type"
                  >
                    <option value="">-- Select one --</option>
                    <option value="RESIDENTIAL">Residential</option>
                    <option value="BUSINESS">Business</option>
                  </select>
                  {formMetadata.porting_business_type !== "" && (
                    <>
                      <label
                        className="col-12 mt-2 p-0"
                        style={{ cursor: "pointer" }}
                        htmlFor="porting_first_name"
                      >
                        First Name:
                      </label>
                      <input
                        name="porting_first_name"
                        value={formMetadata.porting_first_name}
                        onChange={(e) => hangleChangeMetadata(e)}
                        className="col-12"
                        type="text"
                        id="porting_first_name"
                        className={`form-control ${inputError === "porting_first_name" && "form-control-error"}`}
                      />
                      <label
                        className="col-12 mt-2 p-0"
                        style={{ cursor: "pointer" }}
                        htmlFor="porting_last_name"
                      >
                        Last Name:
                      </label>
                      <input
                        name="porting_last_name"
                        value={formMetadata.porting_last_name}
                        onChange={(e) => hangleChangeMetadata(e)}
                        className="col-12"
                        type="text"
                        id="porting_last_name"
                        className={`form-control ${inputError === "porting_last_name" && "form-control-error"}`}
                      />
                      {formMetadata.porting_business_type === "BUSINESS" && (
                        <>
                          {" "}
                          <label
                            className="col-12 mt-2 p-0"
                            style={{ cursor: "pointer" }}
                            htmlFor="porting_business_name"
                          >
                            Business Name:
                          </label>
                          <input
                            name="porting_business_name"
                            value={formMetadata.porting_business_name}
                            onChange={(e) => hangleChangeMetadata(e)}
                            className="col-12"
                            type="text"
                            id="porting_business_name"
                            className={`form-control ${inputError === "porting_business_name" && "form-control-error"}`}
                          />
                        </>
                      )}
                      <label
                        className="col-12 mt-2 p-0"
                        style={{ cursor: "pointer" }}
                        htmlFor="porting_house_number"
                      >
                        House Number:
                      </label>
                      <input
                        name="porting_house_number"
                        value={formMetadata.porting_house_number}
                        onChange={(e) => hangleChangeMetadata(e)}
                        className="col-12"
                        type="text"
                        id="porting_house_number"
                        className={`form-control ${inputError === "porting_house_number" && "form-control-error"}`}
                      />
                      <label
                        className="col-12 mt-2 p-0"
                        style={{ cursor: "pointer" }}
                        htmlFor="porting_street_name"
                      >
                        Street Name:
                      </label>
                      <input
                        name="porting_street_name"
                        value={formMetadata.porting_street_name}
                        onChange={(e) => hangleChangeMetadata(e)}
                        className="col-12"
                        type="text"
                        id="porting_street_name"
                        className={`form-control ${inputError === "porting_street_name" && "form-control-error"}`}
                      />

                      <label
                        className="col-12 mt-2 p-0"
                        style={{ cursor: "pointer" }}
                        htmlFor="porting_state_code"
                      >
                        State Code:
                      </label>
                      <select
                        name="porting_state_code"
                        value={porting_state_code}
                        onChange={(e) => { changeEstate(e.target.value) }}
                        className="col-12"
                        type="text"
                        id="porting_state_code"
                        className={`form-control ${inputError === "porting_state_code" && "form-control-error"}`}
                      >
                        {
                          states.map((element, i) => (
                            <option key={i} value={element.pk}>{element.name}</option>
                          ))
                        }
                      </select>
                      {selectedState && (<><label
                        className="col-12 mt-2 p-0"
                        style={{ cursor: "pointer" }}
                        htmlFor="porting_city_name"
                      >
                        City Name:
                      </label>
                        <select
                          name="porting_city_name"
                          value={formMetadata.porting_city_name}
                          onChange={(e) => hangleChangeMetadata(e)}
                          className="col-12"
                          type="text"
                          id="porting_city_name"
                          className={`form-control ${inputError === "porting_city_name" && "form-control-error"}`}

                        >
                          {
                            cities.map((element, i) => (
                              <option key={i} value={element.city_alias}>{element.name}</option>
                            ))
                          }
                        </select>
                      </>)}

                      <label
                        className="col-12 mt-2 p-0"
                        style={{ cursor: "pointer" }}
                        htmlFor="porting_zip_code"
                      >
                        Zip Code:
                      </label>
                      <input
                        name="porting_zip_code"
                        value={formMetadata.porting_zip_code}
                        onChange={(e) => hangleChangeMetadata(e)}
                        className="col-12"
                        type="text"
                        id="porting_zip_code"
                        className={`form-control ${inputError === "porting_zip_code" && "form-control-error"}`}
                      />
                      <label
                        className="col-12 mt-2 p-0"
                        style={{ cursor: "pointer" }}
                        htmlFor="porting_btn"
                      >
                        Btn:
                      </label>
                      <input
                        name="porting_btn"
                        value={formMetadata.porting_btn}
                        onChange={(e) => hangleChangeMetadata(e)}
                        className="col-12"
                        type="text"
                        id="porting_btn"
                        className={`form-control ${inputError === "porting_btn" && "form-control-error"}`}
                      />
                      <label
                        className="col-12 mt-2 p-0"
                        style={{ cursor: "pointer" }}
                        htmlFor="porting_account_number"
                      >
                        Account Number:
                      </label>
                      <input
                        name="porting_account_number"
                        value={formMetadata.porting_account_number}
                        onChange={(e) => hangleChangeMetadata(e)}
                        className="col-12"
                        type="text"
                        id="porting_account_number"
                        className={`form-control ${inputError === "porting_account_number" && "form-control-error"}`}
                      />
                      <label
                        className="col-12 mt-2 p-0"
                        style={{ cursor: "pointer" }}
                        htmlFor="porting_carrier"
                      >
                        Port Carrier:
                      </label>
                      <input
                        name="porting_carrier"
                        value={formMetadata.porting_carrier}
                        onChange={(e) => hangleChangeMetadata(e)}
                        className="col-12"
                        type="text"
                        id="porting_carrier"
                        className={`form-control ${inputError === "porting_carrier" && "form-control-error"}`}
                      />
                      <label
                        className="col-12 mt-2 p-0"
                        style={{ cursor: "pointer" }}
                        htmlFor="pin"
                      >
                        Pin (opcional):
                      </label>
                      <input
                        name="pin"
                        value={formMetadata.pin}
                        onChange={(e) => hangleChangeMetadata(e)}
                        className="col-12"
                        type="text"
                        id="pin"
                        className={`form-control ${inputError === "pin" && "form-control-error"}`}
                      />
                    </>
                  )}
                </div>
              </Col>
            </Row>
          );
        }
      })}
    </>
  );
}
