import React, { useState } from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";
import petition_post from "../../../../petitions/petition_post";

import TimeDate from "../TimeDate"

import toastr from "toastr";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,

  Spinner,

} from "reactstrap";

export default function ModalUtility({
  modal,
  setModal,
  dataTimeDate,
  dataTimeDateId,
  setReloadData,
}) {

  toastr.options = { showDuration: 300, };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const [loading, setLoading] = useState(false);
  const [activeSubmitTimeDate, setActiveSubmitTimeDate] = useState(false)

  const [formTimeGroup, setFormTimeGroup] = useState({
    name: "",
  });

  const onSubmitForm = (e) => {
    if (dataTimeDate) {
      setActiveSubmitTimeDate(true)
    } else {
      setLoading(true);
      petition_post("timeGroup", { id, data: formTimeGroup })
        .then(({ data: result }) => {
          setLoading(false);
          setModal(false);
          toastr.success("Action completed", "Success");
          setReloadData(true);
          setFormTimeGroup({ name: "" })
        })
        .catch((error) => {
          setLoading(false)

          console.log(error);
          toastr.error("Action failed", "Error");
        });
    }
  };


  const handleChangeGroup = (e) => {
    setFormTimeGroup({ ...formTimeGroup, [e.target.name]: e.target.value });
  };



  return (
    <Modal centered={true} isOpen={modal} className="info ">
      <ModalHeader className="bg-soft-primary">

        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary">Add {dataTimeDate ? "Time Date" : "Time Group"}</h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        {dataTimeDate ? <TimeDate setLoading={setLoading} setModal={setModal} setReloadData={setReloadData} dataTimeDateId={dataTimeDateId} activeSubmitTimeDate={{ setActiveSubmitTimeDate, activeSubmitTimeDate }} /> : (
          <Row>
            {" "}
            <Col className="col-12 d-flex align-items-center">
              <label htmlFor="name" className="m-0 pr-2" style={{ width: "25%" }}>
                Name:
              </label>
              <input
                onChange={handleChangeGroup}
                name="name"
                value={formTimeGroup.name}
                className="form-control"
                type="text"
              />
            </Col>{" "}
          </Row>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          outline
          color="secondary"
          disabled={loading}
          onClick={() => {
            setModal(false);
            setFormTimeGroup({ name: "" })
          }}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          onClick={() => {
            onSubmitForm();
          }}
          disabled={
            loading || (!dataTimeDate && formTimeGroup.name === "")
          }
        >
          {loading && (
            <Spinner
              style={{ marginRight: "0.5em" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
