import React, { useState, useEffect } from "react";
import verificationImg from "../../../../../assets/images/verification-img.png";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PhoneInput from 'react-phone-input-2'

import petition_get from "../../../../petitions/petition_get";
import petition_patch from "../../../../petitions/petition_patch";
import toastr from "toastr";
import classnames from "classnames";
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import DescriptionIcon from '@material-ui/icons/Description';
import Loading from "../../../../../components/utils/Loading"
export default function ModalUtility({
  modal,
  setModal,
  setReloadData,
  timeZone
}) {
  toastr.options = {
    showDuration: 300,
  };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const [form, setForm] = useState({
    name: "",
    numbers: [],
    number: "",
    labelNumber: "",
    emails: [],
    email: "",
    labelEmail: "",
    notes: "",
  });


  const [addNumber, setAddNumber] = useState(false)
  const [addEmail, setAddEmail] = useState(false)

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [customActiveTab, setCustomActiveTab] = useState("1");
  const [callCaller, setCallCaller] = useState(null)
  const [callCallee, setCallCallee] = useState(null)
  const [callDetails, setCallDetails] = useState(null)


  const formatData = (val, timeZone) => {
    let time = new Date(val * 1000);
    let timeZoneTemp = time.toLocaleString('en-US', { timeZone: timeZone })
    return timeZoneTemp
  }


  useEffect(() => {
    if (modal) {
      setForm({
        ...form,
        name: modal.name,
        notes: modal.notes,
        numbers: modal.numbers || null,
        emails: modal.emails || null,

      });

      setAddNumber(false)
      setAddNumber(false)
      setCallCallee(null)
      setCallCaller(null)
      setCallDetails(null)
      petition_get("callInteractionCallee", { id, number: modal.number })
        .then(({ data: result }) => {
          setCallCallee(result.result)
        })
        .catch((error) => console.log(error));
      petition_get("callInteractionCaller", { id, number: modal.number })
        .then(({ data: result }) => {
          setCallCaller(result.result)
        })
        .catch((error) => console.log(error));

    }
  }, [modal]);

  const onChangeForm = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitForm = async (e) => {
    setLoading(true);
    setError(false);
    await petition_patch("updateContactList", {
      id: id,
      data: form,
      contact_id: modal.pk,
    })
      .then(({ data: result }) => {
        setLoading(false);
        setModal(false);
        setReloadData(true)
        toastr.success("Action completed", "Success");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setError(true);
        toastr.error("Action failed", "Error");
      });
  };

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };

  const handleAddNumber = () => {
    if (form.number.trim() === "") return toastr.error("Action failed", "Complete Number Field");
    if (form.labelNumber.trim() === "") return toastr.error("Action failed", "Complete Label Number Field");

    let findMobileType = form.numbers.find(element => element.label === "Mobile")
    if (findMobileType && form.labelNumber === "Mobile") return toastr.error("Action failed", "Only one number of type Mobile");

    let findNumber = form.numbers.find(element => element.number === form.number)
    if (!findNumber) {
      setForm({ ...form, numbers: [...form.numbers, { number: form.number, label: form.labelNumber }], number: "", labelNumber: "" })
    }
    else {
      setForm({ ...form, number: "", labelNumber: "" })
    }
    setAddNumber(false)
  }


  const handleAddEmail = () => {
    if (form.email.trim() === "") return toastr.error("Action failed", "Complete Number Field");
    if (form.labelEmail.trim() === "") return toastr.error("Action failed", "Complete Label Number Field");

    let findEmail = form.emails.find(element => element.email === form.email)
    if (!findEmail) setForm({ ...form, emails: [...form.emails, { email: form.email, label: form.labelEmail }], email: "", labelEmail: "" })
    else setForm({ ...form, email: "", labelEmail: "" })

    setAddEmail(false)
  }



  const handleDeleteNumber = (number) => {

    let numbers = form.numbers.filter(element => element.number !== number)

    setForm({ ...form, numbers })

  }

  const handleDeleteEmail = (email) => {

    let emails = form.emails.filter(element => element.email !== email)

    setForm({ ...form, emails })

  }

  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader className="bg-soft-primary">

        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary">Contact</h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={verificationImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>

        {!form.numbers ? <>
          <h2 className="text-center"> Default contact</h2>
          <Row>
            <Col
              sm="12"
              className="mb-sm-2 mb-lg-0"
              style={{ display: "flex", alignItems: "center" }}
            >
              <label
                htmlFor="name"
                style={{ paddingBottom: "0.5rem", margin: "0" }}
              >
                Name
              </label>
            </Col>
            <Col sm="12">
              <input
                onChange={onChangeForm}
                value={form.name}
                name="name"
                id="name"
                type="text"
                className="form-control"
                disabled
              />

            </Col>
          </Row>

          {console.log(modal)}

          {modal.email && <> <br /> <Row>
            <Col
              sm="12"
              className="mb-sm-2 mb-lg-0"
              style={{ display: "flex", alignItems: "center" }}
            >
              <label
                htmlFor="name"
                style={{ paddingBottom: "0.5rem", margin: "0" }}
              >
                Email
              </label>
            </Col>
            <Col sm="12">
              <input
                onChange={onChangeForm}
                value={modal.email}
                name="email"
                id="email"
                type="text"
                className="form-control"
                disabled
              />

            </Col>
          </Row> </>}
          {modal.internal_number && <> <br /> <Row>
            <Col
              sm="12"
              className="mb-sm-2 mb-lg-0"
              style={{ display: "flex", alignItems: "center" }}
            >
              <label
                htmlFor="name"
                style={{ paddingBottom: "0.5rem", margin: "0" }}
              >
                Internal Number
              </label>
            </Col>
            <Col sm="12">
              <input
                onChange={onChangeForm}
                value={modal.internal_number}
                name="internal_number"
                id="internal_number"
                type="text"
                className="form-control"
                disabled
              />

            </Col>
          </Row> </>}
          {modal.external_number && <> <br /> <Row>
            <Col
              sm="12"
              className="mb-sm-2 mb-lg-0"
              style={{ display: "flex", alignItems: "center" }}
            >
              <label
                htmlFor="name"
                style={{ paddingBottom: "0.5rem", margin: "0" }}
              >
                Internal Number
              </label>
            </Col>
            <Col sm="12">
              <input
                onChange={onChangeForm}
                value={modal.external_number}
                name="external_number"
                id="external_number"
                type="text"
                className="form-control"
                disabled
              />

            </Col>
          </Row> </>}

        </> : <>
          <Nav
            tabs
            className="nav-tabs-custom  flex-column flex-md-row  text-center  "
          >
            <NavItem className="pt-3 pt-md-0">
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "1",
                })}
                onClick={() => {
                  toggleCustom("1");
                }}
              >
                <span>Contact Details</span>
              </NavLink>
            </NavItem>
            <NavItem className="pt-3 pt-md-0">
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "2",
                })}
                onClick={() => {
                  toggleCustom("2");
                }}
              >
                <span>Call History</span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={customActiveTab}>
            <TabPane tabId="1" className="p-3">
              <Row>
                <Col
                  sm="12"
                  className="mb-sm-2 mb-lg-0"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <label
                    htmlFor="name"
                    style={{ paddingBottom: "0.5rem", margin: "0" }}
                  >
                    Name
                  </label>
                </Col>
                <Col sm="12">
                  <input
                    onChange={onChangeForm}
                    value={form.name}
                    name="name"
                    id="name"
                    type="text"
                    className="form-control"
                  />

                </Col>
              </Row>
              <br />


              <Row>
                <Col
                  sm="12"
                  className="mb-sm-2 mb-lg-0"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <label
                    htmlFor="external_number"
                    style={{ paddingBottom: "0.5rem", margin: "0" }}
                  >
                    Numbers
                  </label>
                </Col>
                <Col
                  sm="12"
                  className="mb-sm-2 mb-lg-0"

                >
                  {form.numbers.map(element =>
                    <div className="d-flex justify-content-between">
                      <p>{element.label} - {element.number}</p>
                      <div style={{ cursor: "pointer" }} onClick={() => { handleDeleteNumber(element.number) }}>

                        <HighlightOffIcon />
                      </div>
                    </div>
                  )}
                  {addNumber &&
                    <div>
                      <div className="d-flex">
                        <PhoneInput
                          country={'us'}
                          value={form.number}
                          onChange={(e) => { setForm({ ...form, number: e }) }}
                        />
                        <select onChange={onChangeForm}
                          value={form.labelNumber}
                          name="labelNumber"
                          id="labelNumber"
                          className="form-control ml-2">

                          <option value="">Select One</option>
                          <option value="Home">Home</option>
                          <option value="Mobile">Mobile</option>
                          <option value="Work">Work</option>
                          <option value="Others">Others</option>
                        </select>

                      </div>


                      <Button className="mt-2" color="primary" onClick={() => { handleAddNumber() }}   >
                        Save
                      </Button>
                    </div>

                  }

                  <p style={{ paddingTop: "10px", cursor: "pointer", color: "#556ee6" }} onClick={() => { setAddNumber(true) }}> + Add Number </p>
                </Col>




              </Row>
              <br />
              <Row>
                <Col
                  sm="12"
                  className="mb-sm-2 mb-lg-0"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <label
                    htmlFor="external_number"
                    style={{ paddingBottom: "0.5rem", margin: "0" }}
                  >
                    Emails
                  </label>
                </Col>
                <Col
                  sm="12"
                  className="mb-sm-2 mb-lg-0"

                >
                  {form.emails.map(element =>
                    <div className="d-flex justify-content-between">
                      <p><p>{element.label} - {element.email}</p></p>
                      <div style={{ cursor: "pointer" }} onClick={() => { handleDeleteEmail(element.email) }}>

                        <HighlightOffIcon />
                      </div>
                    </div>
                  )}
                  {addEmail &&
                    <div >
                      <div className="d-flex">
                        <input
                          onChange={onChangeForm}
                          value={form.email}
                          name="email"
                          id="email"
                          type="text"
                          className="form-control"
                        />

                        <select onChange={onChangeForm}
                          value={form.labelEmail}
                          name="labelEmail"
                          id="labelEmail"
                          className="form-control ml-2">

                          <option value="">Select One</option>
                          <option value="Particular">Particular</option>
                          <option value="Work">Work</option>
                          <option value="Others">Others</option>
                        </select>


                      </div>

                      <Button className="mt-2" color="primary" onClick={() => { handleAddEmail() }}   >
                        Save
                      </Button>
                    </div>

                  }

                  <p style={{ paddingTop: "10px", cursor: "pointer", color: "#556ee6" }} onClick={() => { setAddEmail(true) }}> + Add Email </p>
                </Col>


              </Row>

              <br />

              <Row>
                <Col
                  sm="12"
                  className="mb-sm-2 mb-lg-0"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <label
                    htmlFor="notes"
                    style={{ paddingBottom: "0.5rem", margin: "0" }}
                  >
                    Notes:
                  </label>
                </Col>
                <Col sm="12">
                  <textarea
                    onChange={onChangeForm}
                    value={form.notes}
                    name="notes"
                    id="notes"
                    type="text"
                    className="form-control"
                  />
                </Col>
              </Row>

            </TabPane>
            <TabPane tabId="2" className="p-3">


              {callDetails ?
                Object.keys(callDetails).map(element => <p> "{element}": {callDetails[element]} </p>)
                :
                <>
                  {callCaller && callCallee
                    ? <>  {callCaller.map(element =>

                      <Row className="mt-3">
                        <div className="col-2 d-flex justify-content-center align-items-center"><PhoneCallbackIcon style={{ color: "#556ee6" }} /></div>
                        <div className="col-8"> <p className="m-0"> <strong>{formatData(element.rfc_1036, timeZone)}</strong> </p> <span>Outgoing Call {new Date(element.duration_seconds * 1000).toISOString().substr(element.duration_seconds < 3600 ? 14 : 11, element.duration_seconds < 3600 ? 5 : 8)} </span> </div>
                        <div className="col-2 d-flex justify-content-center align-items-center"><DescriptionIcon style={{ cursor: "pointer" }} onClick={() => { setCallDetails(element) }} /></div>
                      </Row>

                    )}
                      {callCallee.map(element =>

                        <Row className="mt-3">
                          <div className="col-2 d-flex justify-content-center align-items-center"><PhoneCallbackIcon style={{ color: "#556ee6" }} /></div>
                          <div className="col-8"> <p className="m-0"> <strong>{formatData(element.rfc_1036, timeZone)}</strong> </p> <span>Incoming Call {new Date(element.duration_seconds * 1000).toISOString().substr(element.duration_seconds < 3600 ? 14 : 11, element.duration_seconds < 3600 ? 5 : 8)} </span> </div>
                          <div className="col-2 d-flex justify-content-center align-items-center"><DescriptionIcon style={{ cursor: "pointer" }} onClick={() => { setCallDetails(element) }} /></div>
                        </Row>

                      )}</>
                    : <Loading />
                  }
                </>
              }
            </TabPane>
          </TabContent></>}




      </ModalBody>
      <ModalFooter>
        {callDetails ?

          <Button
            color="primary"
            onClick={() => { setCallDetails(null) }}
          >
            Close
          </Button>

          :
          <><Button
            outline
            color="secondary"
            onClick={() => {
              setModal(false);
              setError(false);
            }}
          >

            Close
          </Button>
            {form.numbers && <Button
              disabled={loading}
              color="primary"
              onClick={() => {
                onSubmitForm();
              }}
            >
              {loading && (
                <Spinner
                  style={{ marginRight: "0.5em" }}
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Update
            </Button>}
          </>
        }

      </ModalFooter>
    </Modal>
  );
}
