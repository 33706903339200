import React from "react";
import {Redirect} from "react-router-dom";

// Pages Component
import Chat2 from "../pages/Chat/Chat";

// Pages Calendar
import Calendar from "../pages/Calendar/index";

// User profile
import UserProfile from "../pages/Authentication/UserProfile";

//Tasks
import TasksList from "../pages/Tasks/tasks-list";
import TasksKanban from "../pages/Tasks/tasks-kanban";
import TasksCreate from "../pages/Tasks/tasks-create";

//Projects
import ProjectsGrid from "../pages/Projects/projects-grid";
import ProjectsList from "../pages/Projects/projects-list";
import ProjectsOverview from "../pages/Projects/projects-overview";
import ProjectsCreate from "../pages/Projects/projects-create";

//Ecommerce Pages

import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts";
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProductDetail";
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders";
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers";
import EcommerceCart from "../pages/Ecommerce/EcommerceCart";
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout";
import EcommerceShops from "../pages/Ecommerce/EcommerceShops";
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct";

//Email
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";

//Invoices
import InvoicesList from "../pages/Invoices/invoices-list";
import InvoiceDetail from "../pages/Invoices/invoices-detail";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ChangePassword from "../pages/Authentication/ChangePassword";
import FromCrm from "../pages/Authentication/FromCrm";

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import DashboardSaas from "../pages/Dashboard-saas/index";
import DashboardCrypto from "../pages/Dashboard-crypto/index";

//Crypto
import CryptoWallet from "../pages/Crypto/crypto-wallet";
import CryptoBuySell from "../pages/Crypto/crypto-buy-sell";
import CryptoExchange from "../pages/Crypto/crypto-exchange";
import CryptoLending from "../pages/Crypto/crypto-lending";
import CryptoOrders from "../pages/Crypto/crypto-orders";
import CryptoKYCApplication from "../pages/Crypto/crypto-kyc-application";
import CryptoIcoLanding from "../pages/Crypto/CryptoIcoLanding/index";

// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartistChart from "../pages/Charts/ChartistChart";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import EChart from "../pages/Charts/EChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ToastUIChart from "../pages/Charts/ToastUIChart";
import ChartsKnob from "../pages/Charts/charts-knob";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";
import MapsLeaflet from "../pages/Maps/MapsLeaflet";

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconFontawesome from "../pages/Icons/IconFontawesome";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

// Forms
import FormElements from "../pages/Forms/FormElements";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormRepeater from "../pages/Forms/FormRepeater";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiColors from "../pages/Ui/UiColors";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";
import UiImageCropper from "../pages/Ui/ui-image-cropper";

//Pages
import PagesStarter from "../pages/Utility/pages-starter";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesTimeline from "../pages/Utility/pages-timeline";
import PagesFaqs from "../pages/Utility/pages-faqs";
import PagesPricing from "../pages/Utility/pages-pricing";
import Pages401 from "../pages/Utility/pages-401";
import Pages500 from "../pages/Utility/pages-500";

//Contacts
import ContactsGrid from "../pages/Contacts/contacts-grid";
import ContactsList from "../pages/Contacts/contacts-list";
import ContactsProfile from "../pages/Contacts/contacts-profile";

//ITP
import FiberSMB from "../pages/Fiber/SMB/FiberSMB";
import FiberDIA from "../pages/Fiber/DIA/FiberDIA";
import Phone from "../pages/Phone/Phone";
import Labor from "../pages/Labor/Labor";
import HostedService from "../pages/HostedService/HostedService";
import Cloud from "../pages/Cloud/Cloud";
import MyITP from "../pages/MyITP/MyITP";
import ITPVoice from "../pages/ITPVoice/ITPVoice";

//import ApproveQuote from "../pages/ApproveQuote/ApproveQuote";
import Billing from "../pages/Billing/Billing";
import Support from "../pages/Support/Support";
import Services from "../pages/Services/Services";
import Orders from "../pages/Orders/Orders";

/// into Billing
import ViewStaments from "../pages/Billing/ViewStaments/ViewStaments";
import AutomaticPayments from "../pages/Billing/AutomaticPayments/AutomaticPayments";
import BillDetails from "../pages/Billing/BillDetails/BillDetails";
import MakePayments from "../pages/Billing/MakePayments/MakePayments";
import NewCard from "../pages/Billing/NewCard/NewCard";

/// into ITPVoice
import Chat from "../pages/ITPVoice/Chat/Chat";
import OperatorPanel from "../pages/ITPVoice/OperatorPanel/OperatorPanel";
import WebPhone from "../pages/ITPVoice/OperatorPanel/WebPhone";
import DashboardUsers from "../pages/ITPVoice/MyExtensions/DashboardUsers/DashboardUsers";
import Voicemail from "../pages/ITPVoice/MyExtensions/Voicemail/Voicemail";
import CallHistory from "../pages/ITPVoice/MyExtensions/CallHistory/CallHistory";
import Fax from "../pages/ITPVoice/MyExtensions/Fax/Fax";
import ContactList from "../pages/ITPVoice/MyExtensions/ContactList/ContactList";
import SettingsAndDevices from "../pages/ITPVoice/MyExtensions/SettingsAndDevices/SettingsAndDevices";
import ListAllUsers from "../pages/ITPVoice/Users/ListAllUsers/ListAllUsers";
import AddUsers from "../pages/ITPVoice/Users/AddUsers/AddUsers";
import AllNumbers from "../pages/ITPVoice/Numbers/AllNumbers/AllNumbers";
import OrderNewNumbers from "../pages/ITPVoice/Numbers/OrderNewNumbers/OrderNewNumbers";
import AdminOperatorPanel from "../pages/ITPVoice/AdminOperatorPanel/AdminOperatorPanel";
import TransferredAndVanity from "../pages/ITPVoice/Numbers/TransferredAndVanity/TransferredAndVanity";
import TransferredAndVanityPBX from "../pages/ITPVoiceLegacy/NumbersPBX/TransferredAndVanity/TransferredAndVanity";
import NumberInventory from "../pages/ITPVoice/Numbers/NumberInventory/NumberInventory";
import AuthorizeTransfers from "../pages/ITPVoice/Numbers/AuthorizeTransfers/AuthorizeTransfers";
import CallLogs from "../pages/ITPVoice/Reports/CallLogs/CallLogs";
import CallReports from "../pages/ITPVoice/Reports/CallReports/CallReports";
import SipRegistration from "../pages/ITPVoice/Help/Advanced/SipRegistration/SipRegistration";
import Keys from "../pages/ITPVoice/API/Keys/Keys";
import Menus from "../pages/ITPVoice/YourPhoneSystem/Menus/Menu";
import CallCenterApp from "../pages/ITPVoice/App/CallCenterApp/CallCenterApp";
// import CallLogs from "../pages/ITPVoice/CDR/CallLogs/CallLogs";

/// ITPVoiceLegacy
import DashboardITPVoiceLegacy from "../pages/ITPVoiceLegacy/Dashboard/DashboardITPVoiceLegacy";
import AddNumbersPBX from "../pages/ITPVoiceLegacy/NumbersPBX/AddNumbersPBX/AddNumbersPBX";
import ListAllNumbersPBX from "../pages/ITPVoiceLegacy/NumbersPBX/ListAllNumbersPBX/ListAllNumbersPBX";
import NumberInventoryPBX from "../pages/ITPVoiceLegacy/NumbersPBX/NumberInventory/NumberInventory";

//prueba
import Prueba from "../pages/prueba/prueba";
import Prueba2 from "../pages/prueba/prueba2";

///// your phone system
import TimeSchedules from "../pages/ITPVoice/YourPhoneSystem/TimeSchedules/TimeSchedules";
import GeneralSettings from "../pages/ITPVoice/YourPhoneSystem/GeneralSettings/GeneralSettings";
import Callflows from "../pages/ITPVoice/YourPhoneSystem/Callflows/Callflows";
import MediaFiles from "../pages/ITPVoice/YourPhoneSystem/MediaFiles/MediaFiles";
import Queues from "../pages/ITPVoice/YourPhoneSystem/Queues/Queues";
import RingGroups from "../pages/ITPVoice/YourPhoneSystem/RingGroups/RingGroups";

///// App

import EnabledApp from "../pages/ITPVoice/App/EnabledApp/EnabledApp";
import AppDirectory from "../pages/ITPVoice/App/AppDirectory/AppDirectory";
import InstalledApps from "../pages/ITPVoice/App/InstalledApps/InstalledApps";
import Permissions from "../pages/ITPVoice/App/Permissions/Permissions";

//// home

import Home from "../pages/Home/Home";

const authProtectedRoutes = [
  {path: "/dashboard", component: Dashboard},
  {path: "/dashboard-saas", component: DashboardSaas},
  {path: "/dashboard-crypto", component: DashboardCrypto},

  //Crypto
  {path: "/crypto-wallet", component: CryptoWallet},
  {path: "/crypto-buy-sell", component: CryptoBuySell},
  {path: "/crypto-exchange", component: CryptoExchange},
  {path: "/crypto-lending", component: CryptoLending},
  {path: "/crypto-orders", component: CryptoOrders},
  {path: "/crypto-kyc-application", component: CryptoKYCApplication},

  //profile
  {path: "/profile", component: UserProfile},

  //chat
  {path: "/chat2", component: Chat2},

  //calendar
  {path: "/calendar", component: Calendar},

  //Ecommerce

  {path: "/ecommerce-products", component: EcommerceProducts},
  {path: "/ecommerce-product-detail", component: EcommerceProductDetail},
  {path: "/ecommerce-orders", component: EcommerceOrders},
  {path: "/ecommerce-customers", component: EcommerceCustomers},
  {path: "/ecommerce-cart", component: EcommerceCart},
  {path: "/ecommerce-checkout", component: EcommerceCheckout},
  {path: "/ecommerce-shops", component: EcommerceShops},
  {path: "/ecommerce-add-product", component: EcommerceAddProduct},

  //Email
  {path: "/email-inbox", component: EmailInbox},
  {path: "/email-read", component: EmailRead},

  //Invoices
  {path: "/invoices-list", component: InvoicesList},
  {path: "/invoices-detail", component: InvoiceDetail},

  // Tasks
  {path: "/tasks-list", component: TasksList},
  {path: "/tasks-kanban", component: TasksKanban},
  {path: "/tasks-create", component: TasksCreate},

  //Projects
  {path: "/projects-grid", component: ProjectsGrid},
  {path: "/projects-list", component: ProjectsList},
  {path: "/projects-overview", component: ProjectsOverview},
  {path: "/projects-create", component: ProjectsCreate},

  // Contacts
  {path: "/contacts-grid", component: ContactsGrid},
  {path: "/contacts-list", component: ContactsList},
  {path: "/contacts-profile", component: ContactsProfile},

  //Charts
  {path: "/apex-charts", component: ChartApex},
  {path: "/chartist-charts", component: ChartistChart},
  {path: "/chartjs-charts", component: ChartjsChart},
  {path: "/e-charts", component: EChart},
  {path: "/sparkline-charts", component: SparklineChart},
  {path: "/tui-charts", component: ToastUIChart},
  {path: "/charts-knob", component: ChartsKnob},

  // Icons
  {path: "/icons-boxicons", component: IconBoxicons},
  {path: "/icons-dripicons", component: IconDripicons},
  {path: "/icons-materialdesign", component: IconMaterialdesign},
  {path: "/icons-fontawesome", component: IconFontawesome},

  // Tables
  {path: "/tables-basic", component: BasicTables},
  {path: "/tables-datatable", component: DatatableTables},
  {path: "/tables-responsive", component: ResponsiveTables},
  {path: "/tables-editable", component: EditableTables},

  // Maps
  {path: "/maps-google", component: MapsGoogle},
  {path: "/maps-vector", component: MapsVector},
  {path: "/maps-leaflet", component: MapsLeaflet},

  // Forms
  {path: "/form-elements", component: FormElements},
  {path: "/form-advanced", component: FormAdvanced},
  {path: "/form-editors", component: FormEditors},
  {path: "/form-mask", component: FormMask},
  {path: "/form-repeater", component: FormRepeater},
  {path: "/form-uploads", component: FormUpload},
  {path: "/form-wizard", component: FormWizard},
  {path: "/form-validation", component: FormValidations},
  {path: "/form-xeditable", component: FormXeditable},

  // Ui
  {path: "/ui-alerts", component: UiAlert},
  {path: "/ui-buttons", component: UiButtons},
  {path: "/ui-cards", component: UiCards},
  {path: "/ui-carousel", component: UiCarousel},
  {path: "/ui-colors", component: UiColors},
  {path: "/ui-dropdowns", component: UiDropdown},
  {path: "/ui-general", component: UiGeneral},
  {path: "/ui-grid", component: UiGrid},
  {path: "/ui-images", component: UiImages},
  {path: "/ui-lightbox", component: UiLightbox},
  {path: "/ui-modals", component: UiModal},
  {path: "/ui-progressbars", component: UiProgressbar},
  {path: "/ui-sweet-alert", component: UiSweetAlert},
  {path: "/ui-tabs-accordions", component: UiTabsAccordions},
  {path: "/ui-typography", component: UiTypography},
  {path: "/ui-video", component: UiVideo},
  {path: "/ui-session-timeout", component: UiSessionTimeout},
  {path: "/ui-rating", component: UiRating},
  {path: "/ui-rangeslider", component: UiRangeSlider},
  {path: "/ui-notifications", component: UiNotifications},
  {path: "/ui-image-cropper", component: UiImageCropper},

  // ITP
  {path: "/fiberSMB", component: FiberSMB},
  {path: "/fiberDIA", component: FiberDIA},
  {path: "/Phone", component: Phone},
  {path: "/Labor", component: Labor},
  {path: "/HostedService", component: HostedService},
  {path: "/Cloud", component: Cloud},
  {path: "/MyITP", component: MyITP},
  {path: "/ITPVoice", component: ITPVoice},
  {path: "/Billing", component: Billing},
  {path: "/Support", component: Support},
  {path: "/Services", component: Services},
  {path: "/Orders", component: Orders},
  {path: "/ViewStaments", component: ViewStaments},
  {path: "/AutomaticPayments", component: AutomaticPayments},
  {path: "/BillDetails", component: BillDetails},
  {path: "/MakePayments", component: MakePayments},
  {path: "/NewCard", component: NewCard},

  /// ITPVOICE
  {path: "/Voicemail", component: Voicemail},
  {path: "/DashboardUsers", component: DashboardUsers},
  {path: "/CallHistory", component: CallHistory},
  {path: "/Fax", component: Fax},
  {path: "/ContactList", component: ContactList},
  {path: "/SettingsAndDevices", component: SettingsAndDevices},
  {path: "/ListAllUsers", component: ListAllUsers},
  {path: "/AddUsers", component: AddUsers},
  {path: "/OperatorPanel", component: OperatorPanel},
  {path: "/WebPhone", component: WebPhone},
  {path: "/Chat", component: Chat},
  {path: "/AdminOperatorPanel", component: AdminOperatorPanel},
  {path: "/new-numbers", component: OrderNewNumbers},
  {path: "/all-numbers", component: AllNumbers},
  {path: "/transfers-in-progress", component: NumberInventory},
  {path: "/transfer-your-number", component: TransferredAndVanity},
  {path: "/CallLogs", component: CallLogs},
  {path: "/CallReports", component: CallReports},
  {path: "/AuthorizeTransfers", component: AuthorizeTransfers},
  {path: "/advancedHelp", component: SipRegistration},
  {path: "/Keys", component: Keys},
  {path: "/Menus", component: Menus},
  {path: "/CallCenterApp", component: CallCenterApp},

  ///ITPVOICE LEGACY
  {path: "/DashboardITPVoiceLegacy", component: DashboardITPVoiceLegacy},
  {path: "/AddNumbersPBX", component: AddNumbersPBX},
  {path: "/ListAllNumbersPBX", component: ListAllNumbersPBX},
  {path: "/transfer-your-number-pbx", component: TransferredAndVanityPBX},
  {path: "/transfers-in-progress-pbx", component: NumberInventoryPBX},

  // Home
  {path: "/Home", component: Home},

  //prueba
  {path: "/prueba", component: Prueba},
  //prueba
  {path: "/prueba2", component: Prueba2},

  ///// My Phone Sistem
  {path: "/TimeSchedules", component: TimeSchedules},
  {path: "/GeneralSettings", component: GeneralSettings},
  {path: "/Callflows", component: Callflows},
  {path: "/MediaFiles", component: MediaFiles},
  {path: "/Queues", component: Queues},
  {path: "/RingGroups", component: RingGroups},

  ///App
  {path: "/AppDirectory", component: AppDirectory},
  {path: "/InstalledApps", component: InstalledApps},
  {path: "/EnabledApp", component: EnabledApp},
  {path: "/Permissions", component: Permissions},

  //Utility
  {path: "/pages-starter", component: PagesStarter},
  {path: "/pages-timeline", component: PagesTimeline},
  {path: "/pages-faqs", component: PagesFaqs},
  {path: "/pages-pricing", component: PagesPricing},

  // this route should be at the end of all other routes
  {path: "/", exact: true, component: () => <Redirect to="/profile" />},
];

const publicRoutes = [
  {path: "/logout", component: Logout},
  {path: "/login", component: Login},
  {path: "/forgot-password", component: ForgetPwd},
  {path: "/register", component: Register},
  {path: "/ChangePassword", component: ChangePassword},

  {path: "/pages-maintenance", component: PagesMaintenance},
  {path: "/pages-comingsoon", component: PagesComingsoon},
  {path: "/pages-401", component: Pages401},
  {path: "/pages-500", component: Pages500},
  {path: "/crypto-ico-landing", component: CryptoIcoLanding},

  // Authentication Inner
  {path: "/pages-login", component: Login1},
  {path: "/pages-register", component: Register1},
  {path: "/pages-forgot-pwd", component: ForgetPwd1},
  {path: "/auth-lock-screen", component: LockScreen},

  // { path : "/ApproveQuote", component: ApproveQuote }
];

export {authProtectedRoutes, publicRoutes};
