import React, {useState, useEffect} from "react";
import CustomTable from "../../components/utils/CustomTable/CustomTable";

export default function OrderDetails({modal}) {
  const [dataTable, setDataTable] = useState(null);

  useEffect(() => {
    if (modal) {
      let orderData = Object.keys(modal).map((orderKey) => ({
        key: orderKey.replaceAll("_", " "),
        value: modal[orderKey] || "",
      }));

      setDataTable({
        title: [
          {title: "", key: "key"},
          {title: "", key: "value"},
        ],
        content: [...orderData],
      });
    }
  }, [modal]);

  return (
    <div>
      <CustomTable bordered border data={dataTable} />
    </div>
  );
}
