import React, {useState, useEffect} from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";
import petition_get from "../../../../petitions/petition_get";
import petition_patch from "../../../../petitions/petition_patch";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Badge,
  Spinner,
} from "reactstrap";

import Switch from "react-switch";
export default function ModalUtilityDisconnect({
  modal,
  setModal,
  disconnectNumbers,
  loading,
}) {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  return (
    <Modal
      centered
      isOpen={modal === false ? false : true}
      className="info modal-md"
    >
      <ModalHeader className="bg-soft-primary">
        {" "}
        <Row>
          <Col xs="9">
            <div className="text-primary p-3">
              <h3 className="text-primary">Disconnect Numbers</h3>
            </div>
          </Col>
          <Col xs="3" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <div className="p-3 text-center" style={{fontSize: "1rem"}}>
          WARNING! disconnecting this number will remove it from your account
          entirely
        </div>
      </ModalBody>{" "}
      <ModalFooter>
        <Button
          outline
          disabled={loading}
          color="secondary"
          onClick={() => {
            setModal(false);
          }}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={loading}
          onClick={() => {
            disconnectNumbers();
          }}
        >
          {loading && (
            <Spinner
              style={{marginRight: "0.5em"}}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Disconnect
        </Button>
      </ModalFooter>
    </Modal>
  );
}
