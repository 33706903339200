import React from "react";

import {Button} from "reactstrap";

export const MenuOption = ({title, setActiveMenu}) => {
  return (
    <div className="col-6 text-center">
      <Button
        onClick={() => {
          setActiveMenu(title);
        }}
        color="primary w-100 mt-2"
      >
        {title}
      </Button>
    </div>
  );
};
