import React, {Component} from "react";
import {Container, Col, Row, CardTitle, Card} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import petition_get from "../../petitions/petition_get";

export default class BillDetails extends Component {
  constructor(props) {
    super(props);
    this.userData = JSON.parse(window.localStorage.getItem("authUser"));
    this.state = {
      data: [],
      printData: [],
    };
  }
  async componentDidMount() {
    await petition_get(
      "getStamentsDetails",
      this.userData.account.billing_api_id
    )
      .then(({data: {result}}) => {
        this.setState({
          data: result,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    if (this.state.data.length !== 0) {
      await petition_get("getStamentsAll", [
        this.userData.account.billing_api_id,
        this.state.data[this.state.data.length - 1]._id,
      ])
        .then(({data: {result}}) => {
          this.setState({
            printData: result,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  render() {
    if (this.state.data.length === 0) {
      return (
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              <Breadcrumbs title="Bill Details" breadcrumbItem="Bill Details" />
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "4em",
                }}
              >
                <Col md="6">
                  <Card body>
                    <CardTitle>
                      <strong>The status is not available </strong>
                    </CardTitle>
                    <br />
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <h6>The status is not available</h6>
                    </div>
                    <br />
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              <Breadcrumbs title="Bill Details" breadcrumbItem="Bill Details" />
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "4em",
                }}
              >
                <Col md="6">
                  <Card body>
                    <CardTitle>
                      <strong>Your statement </strong>
                    </CardTitle>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6>Balance Forward</h6>
                      <h6>${this.state.printData.past_due_balance}</h6>
                    </div>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6>Regular Monthly Charges</h6>
                      <h6>${this.state.printData.total_mrc}</h6>
                    </div>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6>One-Time Charges</h6>
                      <h6>${this.state.printData.total_nrc}</h6>
                    </div>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6>Taxes, Surcharges, Fess</h6>
                      <h6>${this.state.printData.total_taxes}</h6>
                    </div>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6>Stament Charges</h6>
                      <h6>${this.state.printData.total_invoice}</h6>
                    </div>
                    <br />
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      );
    }
  }
}
