import React, { useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  CustomInput,
} from "reactstrap";
import Switch from "react-switch";
import { Link } from "react-router-dom";

import avatar1 from "../../../../../assets/images/users/avatar-1.jpg";
import profileImg from "../../../../../assets/images/profile-img.png";

export default function SendFax() {
  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  const [numbers, setNumbers] = useState(["123456", "123456", "123456"]);
  const [files, setFiles] = useState(["aaaaaa", "bbbbbbb", "ccccccccc"]);
  const [numberValue, setNumberValue] = useState("");
  const [switchOne, setSwitchOne] = useState(true);
  const [switchTwo, setSwitchTwo] = useState(true);



  const addNumber = () => {
    setNumbers([...numbers, numberValue]);
    setNumberValue("");
  };

  const deleteNumber = (i) => {
    let tempNumbers = numbers.filter((num, index) => index !== i);
    setNumbers(tempNumbers);
  };

  const deleteFiles = (i) => {
    let tempFiles = files.filter((num, index) => index !== i);
    setFiles(tempFiles);
  };

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-soft-primary">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h3 className="text-primary">Select Cover Page</h3>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img
                style={{ visibility: "hidden" }}
                src={profileImg}
                alt=""
                className="img-fluid"
              />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row className="mt-2">
            <Col xl="12">
              <FormGroup>
                <Label for="exampleCustomSelect">
                  Select and optional cover template to be used
                </Label>
                <CustomInput
                  type="select"
                  id="exampleCustomSelect"
                  name="customSelect"
                >
                  <option value="">Select</option>
                  <option>Value 1</option>
                  <option>Value 2</option>
                  <option>Value 3</option>
                  <option>Value 4</option>
                  <option>Value 5</option>
                </CustomInput>
              </FormGroup>
            </Col>
          </Row>
          <p>Preview:</p>
          <Row>
            <Col xs="12" className="align-self-end">
              <img
                src={profileImg}
                alt=""
                className="img-fluid"
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col></Col>
            <Col>
              <Row>
                <Col style={{ paddingRight: "0px" }}>
                  <Button outline color="secondary">
                    Cancel
                  </Button>
                </Col>
                <Col style={{ paddingLeft: "0px" }}>
                  <Button color="primary">Send</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
