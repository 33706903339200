import React from "react";
import {Container, Row, Col} from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer
        style={{
          position: "absolute",
          left: "0",
          bottom: "0",
        }}
        className="footer"
      >
        <Container
          fluid={true}
          style={{display: "flex", justifyContent: "center"}}
        >
          <Row>
            <Col md={12}>{new Date().getFullYear()} © ITP.</Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
