import React, {useState, useEffect} from "react";
import profileImg from "../../../../../assets/images/profile-img.png";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Table,
  CustomInput,
} from "reactstrap";

import Switch from "react-switch";
export default function ModalUtility({modal, setModal, modalType}) {
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    mobile_num: "",
    home_num: "",
    business_num: "",
    email: "",
    group: "selected",
  });

  const [cover, setCover] = useState(false);

  const onChangeForm = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitForm = (e) => {
  };

  const resetForm = () => {
    setForm({
      first_name: "",
      last_name: "",
      mobile_num: "",
      home_num: "",
      business_num: "",
      email: "",
      group: "selected",
    });
  };

  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  const [numbers, setNumbers] = useState(["123456", "123456", "123456"]);
  const [files, setFiles] = useState(["aaaaaa", "bbbbbbb", "ccccccccc"]);
  const [numberValue, setNumberValue] = useState("");
  const [switchOne, setSwitchOne] = useState(true);
  const [switchTwo, setSwitchTwo] = useState(true);

  const addNumber = () => {
    setNumbers([...numbers, numberValue]);
    setNumberValue("");
  };

  const deleteNumber = (i) => {
    let tempNumbers = numbers.filter((num, index) => index !== i);
    setNumbers(tempNumbers);
  };

  const deleteFiles = (i) => {
    let tempFiles = files.filter((num, index) => index !== i);
    setFiles(tempFiles);
  };

  return (
    <Modal isOpen={modal} className="info">
      <ModalHeader className="bg-soft-primary">
        {" "}
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary">
                {" "}
                {!cover ? `${modalType} fax` : "Select Cover Page"}{" "}
              </h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={profileImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        {!cover ? (
          <>
            {" "}
            <Row className="mt-2">
              <Col xl="11">
                <FormGroup>
                  <Label style={{display: "flex"}} for="exampleEmail">
                    To{" "}
                    <div style={{paddingLeft: "0.5rem", marginTop: "0.1rem"}}>
                      {" "}
                      <i
                        className="bx bxs-info-circle"
                        style={{fontSize: "1.2em"}}
                      ></i>{" "}
                    </div>
                  </Label>
                  <Input
                    value={numberValue}
                    type="email"
                    name="to"
                    id="to"
                    placeholder=""
                    onChange={(e) => setNumberValue(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col
                xl="1"
                className="justify-content-center align-items-center"
                style={{
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  paddingLeft: "0",
                }}
              >
                <i
                  className="bx bx-plus-circle"
                  style={{fontSize: "2em"}}
                  onClick={() => addNumber()}
                ></i>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>
                <Table className="table mb-0">
                  <tbody>
                    {numbers.map((element, i) => (
                      <tr key={i}>
                        <td colSpan="10">{element}</td>
                        <td colSpan="2" style={{textAlign: "center"}}>
                          <i
                            className="bx bx-minus-circle"
                            style={{fontSize: "1.5em"}}
                            onClick={() => deleteNumber(i)}
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className="pt-3">
              <Col xl="2">
                <Switch
                  uncheckedIcon={<Offsymbol />}
                  className="mr-1"
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  onChange={() => {
                    setSwitchOne(!switchOne);
                  }}
                  checked={switchOne}
                />
              </Col>
              <Col xl="4" className="mt-1">
                <p>Use Cove Page</p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Button
                  onClick={() => {
                    setCover(true);
                  }}
                  color="primary"
                >
                  Select Cover Page
                </Button>
              </Col>
            </Row>
            <Row>
              <Col style={{padding: "1rem"}} className="mt-4" xl="11">
                <FormGroup>
                  <Input
                    type="textarea"
                    name="text"
                    rows="6"
                    id="exampleText"
                    placeholder="Type Cover Page Notes Here (Optional)"
                  />
                </FormGroup>
              </Col>
            </Row>
            <p style={{display: "flex"}}>
              Attach Files:{" "}
              <div style={{paddingLeft: "0.5rem", marginTop: "0.1rem"}}>
                {" "}
                <i
                  className="bx bxs-info-circle"
                  style={{fontSize: "1.2em"}}
                ></i>{" "}
              </div>
            </p>
            <Row className="mt-2 pb-3">
              <Col>
                <Button color="primary">Browse</Button>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>
                <Table className="table mb-0">
                  <tbody>
                    {files.map((element, i) => (
                      <tr key={i}>
                        <td colSpan="10">{element}</td>
                        <td colSpan="2" style={{textAlign: "center"}}>
                          <i
                            className="bx bx-minus-circle"
                            style={{fontSize: "1.5em"}}
                            onClick={() => deleteFiles(i)}
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className="pt-4 pb-2">
              <Col xl="2">
                <Switch
                  uncheckedIcon={<Offsymbol />}
                  className="mr-1"
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  onChange={() => {
                    setSwitchTwo(!switchTwo);
                  }}
                  checked={switchTwo}
                />
              </Col>
              <Col xl="4" className="mt-1">
                <p>Schedule</p>
              </Col>
            </Row>
            <Row>
              <Col xl="8">
                <FormGroup>
                  <Input
                    type="datetime-local"
                    name="datetime"
                    id="exampleDatetime"
                    placeholder="datetime placeholder"
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        ) : (
          <>
            {" "}
            <Row className="mt-2">
              <Col xl="12">
                <FormGroup>
                  <Label for="exampleCustomSelect">
                    Select and optional cover template to be used
                  </Label>
                  <CustomInput
                    type="select"
                    id="exampleCustomSelect"
                    name="customSelect"
                  >
                    <option value="">Select</option>
                    <option>Value 1</option>
                    <option>Value 2</option>
                    <option>Value 3</option>
                    <option>Value 4</option>
                    <option>Value 5</option>
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
            <p>Preview:</p>
            <Row>
              <Col xs="12" className="align-self-end">
                <img src={profileImg} alt="" className="img-fluid" />
              </Col>
            </Row>
          </>
        )}
      </ModalBody>{" "}
      <ModalFooter>
        <Button
          outline
          color="secondary"
          onClick={() => {
            if (cover) {
              return setCover(false);
            } else {
              resetForm();
              setModal(false);
            }
          }}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => {
            if (cover) {
              return setCover(false);
            }
            onSubmitForm();
          }}
        >
          {cover ? "Save" : "Send"}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
