import React, {useState} from "react";
import TicketItem from "./TicketItem";
import LocalPhoneIcon from "@material-ui/icons/LocalPhoneOutlined";
import EmailIcon from "@material-ui/icons/EmailOutlined";
import TicketIcon from "@material-ui/icons/Message";
import TicketIconPerson from "@material-ui/icons/Person";
import ModalAddTicket from "./ModalAddTicket";
import ViewTickets from "./ViewTickets";
export default function Tickets() {
  const [modal, setModal] = useState(false);
  const [viewTickets, setViewTickets] = useState(false);
  const [options] = useState([
    {
      title: "Task to us",
      description: "call to (877) 524 4-ITP",
      icon: <LocalPhoneIcon style={{fontSize: "100px"}} />,
    },
    {
      title: "Got a Question?",
      description: "Send email to support@itp247.com",
      icon: <EmailIcon style={{fontSize: "100px"}} />,
    },
    {
      title: "Create a ticket",
      description: "",
      icon: <TicketIcon style={{fontSize: "100px"}} />,
      action: () => {
        setModal(true);
      },
    },
    {
      title: "View your tickets",
      description: "",
      icon: <TicketIconPerson style={{fontSize: "100px"}} />,
      action: () => {
        setViewTickets(true);
      },
    },
  ]);

  if (viewTickets) return <ViewTickets />;

  return (
    <div className="ticket-container">
      <ModalAddTicket modal={modal} setModal={setModal} />
      {options.map((option) => (
        <TicketItem {...option} />
      ))}
    </div>
  );
}
