import React, { useState, useEffect } from 'react'
import { Button, CustomInput } from "reactstrap";
import { SidebarItem } from "../../../components/Index";
import { get } from "lodash";

export default function TimeConditionals({ timeZone, setDetails,
  updateAction,
  state,
  startPoint,
  StartItemStyle, timeGroups, selectNode, dataCallFlow }) {

  const [timeSearch, setTimeSearch] = useState("default");
  const [activeItemTime, setActiveItemTime] = useState(false);
  const [activeTimeGroups, setActiveTimeGroups] = useState(false)
  const [selectTimeGroup, setSelectTimeGroup] = useState(null)
  const [defaultValue, setDefaultValue] = useState(true)
  const [timeGroupsValues, setTimeGroupsValues] = useState([])



  const [activeMenu, setActiveMenu] = useState(false)
  const [menuOptions, setMenuOptions] = useState(false)
  const [selectMenu, setSelectMenu] = useState(null);

  const onChangeAutocompleteMenu = (e) => {
    setSelectMenu(e.target.value);
  };

  const onChangeAutocompleteTime = (e) => {
    setTimeSearch(e.target.value);
  };

  const onChangeAutocompleteTimeGroup = (e) => {

    setSelectTimeGroup(e.target.value);

  };


  useEffect(() => {

    if (selectNode && selectNode.type.split("_")[0] === "Time") {
      setDefaultValue(true)

      let values;
      if (selectNode.childrenUrl === "") values = dataCallFlow.flow.children;
      else {

        values = get(dataCallFlow.flow, selectNode.childrenUrl).children
      }
      const timeGroupsKeys = Object.keys(values)
      const newTimeGroups = []
      timeGroups.map(element => {
        const findOne = timeGroupsKeys.find(elementTwo => elementTwo === element.id)
        if (!findOne) {
          newTimeGroups.push(element)
        }
      })

      if (values._) {
        setDefaultValue(false)
        setSelectTimeGroup(newTimeGroups.length > 0 ? newTimeGroups[0].id : "")
      } else {
        setSelectTimeGroup("_")
      }
      setTimeGroupsValues(newTimeGroups)
      setActiveTimeGroups(false)
    }
    else if (selectNode && selectNode.type.split("_")[0] === "Menu") {
      let MenuOptions = [
        { id: "1" },
        { id: "2" },
        { id: "3" },
        { id: "4" },
        { id: "5" },
        { id: "6" },
        { id: "7" },
        { id: "8" },
        { id: "9" },
        { id: "0" },
        { id: "timeout" },
      ]
      let values;
      if (selectNode.childrenUrl === "") values = dataCallFlow.flow.children;
      else values = get(dataCallFlow.flow, selectNode.childrenUrl).children

      const MenuKeys = Object.keys(values)
      const newMenu = []
      MenuOptions.map(element => {
        const findOne = MenuKeys.find(elementTwo => elementTwo === element.id)
        if (!findOne) {
          newMenu.push(element)
        }
      })
      setSelectMenu(newMenu[0].id)
      setMenuOptions(newMenu)
      setActiveMenu(true);
      setActiveTimeGroups(true)
    }
    else {
      setActiveTimeGroups(true)
    }

  }, [])


  return (
    <>
      {activeTimeGroups ? <>

        {activeMenu ? <>    <select
          onChange={onChangeAutocompleteMenu}
          className="form-control"
          list="datalistOptions"
          id="exampleDataList"
          placeholder="Type to search..."
        >

          {menuOptions.map((element, i) => (
            <option key={i} value={element.id}>
              {element.id}
            </option>
          ))}
        </select>
          {selectMenu && (
            <Button
              onClick={async () => {
                setActiveMenu(false);
              }}
              className=" mt-3 w-100"
              color="primary"
            >
              Create
            </Button>
          )} </> : <>
          <select
            onChange={onChangeAutocompleteTime}
            className="form-control"
            list="datalistOptions"
            id="exampleDataList"
            placeholder="Type to search..."
          >
            <option value="default">
              Default
            </option>
            {timeZone.map((element, i) => (
              <option key={i} value={element}>
                {element}
              </option>
            ))}

          </select>
          <Button
            onClick={async () => {


              setDetails({ timezone: timeSearch, timeGroups: selectTimeGroup, menuOption: selectMenu });
              setActiveItemTime(timeSearch);
            }}
            className=" mt-3 w-100"
            color="primary"
          >
            Create
          </Button>

          {activeItemTime && (
            <SidebarItem
              updateAction={updateAction}
              state={state}
              label={`Time: ${activeItemTime}`}
              type={`Time_${activeItemTime}`}
              ports={startPoint(`Time_${activeItemTime}`)}
              ItemStyle={StartItemStyle}
            />
          )} </>}


      </> : <>
        <select
          onChange={onChangeAutocompleteTimeGroup}
          className="form-control"
          list="datalistOptions"
          id="exampleDataList"
          placeholder="Type to search..."
        >
          {defaultValue && <option value="_">All Other Times</option>}
          {selectTimeGroup === "" && <option value="">You have no more options </option>}

          {timeGroupsValues.map((element, i) => (
            <option key={i} value={element.id}>
              {element.name}
            </option>
          ))}

        </select>

        {selectTimeGroup && (
          <Button
            disabled={selectTimeGroup === ""}
            onClick={async () => {
              setActiveTimeGroups(true)
            }}
            className=" mt-3 w-100"
            color="primary"
          >
            Create
          </Button>
        )}
      </>}

    </>
  )
}
