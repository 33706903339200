import React, { useState, useEffect } from 'react'
import CustomBreadCrum from "../../../../components/utils/CustomBreadCrum/CustomBreadCrum";
import SidebarComponent from "../../../../components/SidebarComponent/SidebarComponent"

import useCallHistory from '../../../../hooks/useCallHistory';
import CallHistorySelectedItem from './CallHistorySelectedItem';
import Loading from '../../../../components/utils/Loading';

import imgProfile from "../../../../assets/images/imgProfile.png"
import arrowCall from "../../../../assets/images/arrowCall.svg"

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

export default function CallHistory() {


  const { userData, data, selectedItem, onChangeSelectedItem, setReloadData, downloadCall, filterKeys, filterData, getListCallHistory, loading } = useCallHistory()



  const DataItem = ({ item, index }) => {


    const [colorInfoCall, setColorInfoCall] = useState({ backgroundColor: "", textColor: "", text: "" })


    useEffect(() => { getTypeOfCall() }, [])


    const getTypeOfCall = () => {
      let colorFilter = { backgroundColor: "", textColor: "", text: "" }
      switch (item.result[0].hangup_cause) {

        case "NORMAL_CLEARING": if (item.result[0].disposition === "") colorFilter = { backgroundColor: "#f8d7da", textColor: "#eb7676", text: "Caller Abandoned" }; else if (item.result[0].disposition === "NO_ANSWER") colorFilter = { backgroundColor: "#f8d7da", textColor: "#eb7676", text: "Outbound Call" }; else colorFilter = { backgroundColor: "#d4edda", textColor: "#74d7b3", text: `${item.direction} Call` }; break;
        case "ORIGINATOR_CANCEL": colorFilter = { backgroundColor: "#f8d7da", textColor: "#eb7676", text: "Caller Abandoned" }; break;
        case "LOSE_RACE": colorFilter = { backgroundColor: "#fff3cd", textColor: "#74d7b3", text: "Answered Elsewhere" }; break;
        case "NO_ANSWER": colorFilter = { backgroundColor: "#f8d7da", textColor: "#eb7676", text: "Missed Call", active: true }; break;
        case "USER_BUSY": colorFilter = { backgroundColor: "#f8d7da", textColor: "#eb7676", text: "Reject Call" }; break;
        case "NO_USER_RESPONSE": colorFilter = { backgroundColor: "#f8d7da", textColor: "#eb7676", text: "Reject Call" }; break;

        default: break;
      }
      setColorInfoCall(colorFilter)

    }

    if (item.userCallNumber === "context_2") return <> </>

    return (<>  <hr className="w-100" style={{ margin: "10px 0 0 0" }} />
      <div onClick={() => { onChangeSelectedItem({ ...item, index }) }} className={`col-12 callhistory-item d-flex pt-2 ${selectedItem.result[0].id === item.result[0].id && "callhistory-item-active"}`}>
        <div className="d-flex align-items-center  mr-3 h-100">
          <img src={imgProfile} className="rounded-circle avatar-sm" alt="" />
        </div>
        <div className="w-100 d-flex flex-column justify-content-center">
          <div className="d-flex justify-content-between w-100">
            <h5 className="text-truncate font-size-14 mb-0" style={{ color: colorInfoCall.active ? colorInfoCall.textColor : "inherit" }}>
              {item.userCallName || item.userCallNumber}
            </h5>
            <p className="text-truncate mb-0">
              {item.unix_timestamp}
            </p>
          </div>
          <p className="text-truncate mb-0">
            <ArrowUpwardIcon className={`arrowCall ${item.direction === "Inbound" ? "rotateInbound" : "rotateOutbound"}`} />  {colorInfoCall.text}, {item.duration_seconds}
          </p>

        </div>
      </div>

    </>)
  }



  return (
    <div className="page-content px-5">
      <CustomBreadCrum title="My Extensions" subtitle="Call History" />
      {data ? <div className="row">

        {data.length > 0
          ? <>
            <div className="col-12 col-lg-5 "><SidebarComponent data={data} loading={loading} getListCallHistory={getListCallHistory} filterData={filterData} filterKeys={filterKeys} data={data} DataItem={DataItem} /></div>
            <div className="col-12 col-lg-7 pt-5 pt-lg-0 "> {selectedItem && <CallHistorySelectedItem downloadCall={downloadCall} userData={userData} item={selectedItem} setReloadData={setReloadData} />} </div>  </>
          : <h3 className="text-center w-100">You do not have any call history at the moment. Make some calls!</h3>
        }
      </div> : <Loading />}


    </div>
  )
}
