export const SIP = "SIP";
export const USERAGENT_START = "USERAGENT_START";
export const USERAGENT_END = "USERAGENT_END";

export const SIP_SUCCESS = "SIP_SUCCESS";
export const SIP_USER = "SIP_USER";


export const DISCONNECTED_PHONE = "DISCONNECTED_PHONE";


