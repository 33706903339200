import {all, fork, takeEvery} from "redux-saga/effects";

import {NEW_NUMBER} from "./actionTypes";

function* newNumber() {
  try {
    yield console.log("send number endpoint");
  } catch (error) {}
}

export function* watchNewNumber() {
  yield takeEvery(NEW_NUMBER, newNumber);
}

function* ITPVoiceSaga() {
  yield all([fork(watchNewNumber)]);
}

export default ITPVoiceSaga;
