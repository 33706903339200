import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  FlowChartWithState,
  INode,
  REACT_FLOW_CHART,
} from "@mrblenny/react-flow-chart";

const Outer = styled.div`
  padding: 20px 30px;
  font-size: 18px;
  background: white;
  cursor: move;
`;

export const SidebarItem = ({
  label,
  type,
  ports,
  properties,
  ItemStyle,
  state,
  updateAction,
}) => {

  const [nodes, setNodes] = useState(Object.keys(state.nodes))



  return (
    <>
      {ItemStyle ? (
        <ItemStyle
          draggable={true}
          onDragStart={(event) => {
            event.dataTransfer.setData(
              REACT_FLOW_CHART,
              JSON.stringify({ type, ports, properties })
            );
          }}
          onDragEnd={(event) => {
            if (Object.keys(state.nodes).length !== nodes.length) {
              console.log("prueba")
              updateAction(event)
            }
            setNodes(Object.keys(state.nodes))
          }}
        >
          {label || type}
        </ItemStyle>
      ) : (
        <Outer
          draggable={true}
          onDragStart={(event) => {
            event.dataTransfer.setData(
              REACT_FLOW_CHART,
              JSON.stringify({ type, ports, properties })
            );
          }}
        >
          {type}
        </Outer>
      )}
    </>
  );
};
