import React from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";

export default function ModalUtilityLegacy({modal,close,modalbody}) {
    return (
        <div>
                    <Modal
            isOpen={modal}
            size="lg"
            backdrop="static"
          >
            <ModalHeader>
               Itp Voice Legacy
            </ModalHeader>
            <ModalBody>
                <Table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>
                        pk
                      </th>
                      <th>Select</th>
                    </tr>
                  </thead>
                  <tbody>
                  {modalbody.sort().map((element, i) => (
                      <tr key={i}>
                        <td>{element.product.name}</td>
                        <td>
                          {element.pk}
                           
                        </td>
                        <td>
                        
                            <Link
                              to={{
                                pathname: "/DashboardITPVoiceLegacy",
                                search: "?id="+element.pk,
                              }}
                              // className="logo logo-dark"
                            >View</Link>
                       
                        </td>
                      </tr>
                    ))} 
                  </tbody>
                </Table>
            </ModalBody>
            <ModalFooter>
                <>
                  <Button color="danger" onClick={()=>{close()}}>
                    Cancel
                  </Button>{" "}
                </>
            
            </ModalFooter>
          </Modal>
        </div>
    )
}
