import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import PhoneForwardedIcon from "@material-ui/icons/PhoneForwarded";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import { Resizable, ResizableBox } from "react-resizable";
import { MuuriComponent, useRefresh } from "muuri-react";
import PerfectScrollbar from "react-perfect-scrollbar";
import petition_patch from "../../../petitions/petition_patch";

import "../styles/resizable.css"

import Styles from "./style";

const MenuDrop = ({ toggle, setToggle, i, type, color, setToggleActive }) => {
  return (
    <>
      <Badge
        color={`${color}`}
        style={{
          fontSize: "0.8rem",
          fontWeight: "100",
          display: "flex",
          alignItems: "center",
          height: "24px",
        }}
      >
        {color === "danger" ? (
          "OFFLINE"
        ) : (
          <>
            {" "}
            {type === "READY" && "READY"}
            {type === "true" && "On Call"}
            {type === "false" && "Ringing"}{" "}
            {type === "conference" && "Conference"}
          </>
        )}
      </Badge>
      <Dropdown
        direction="left"
        isOpen={toggle === i}
        toggle={() => setToggle(i)}
      >
        <DropdownToggle
          style={{
            padding: "0",
            border: "none",
            position: "absolute",
          }}
        >
          {" "}
        </DropdownToggle>
        <div
          onClick={() => {
            toggle === i ? setToggle(null) : setToggle(i);
            toggle === i ? setToggleActive(true) : setToggleActive(false);
          }}
        >
          {" "}
          <MoreHorizIcon style={{ marginLeft: "0.2rem" }} />{" "}
        </div>

        <DropdownMenu>
          <DropdownItem>Hangup</DropdownItem>
          <DropdownItem>Listen</DropdownItem>
          <DropdownItem>Whisper</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

const CardPhone = ({
  toggle,
  setToggle,
  i,
  type,
  element,
  cardSizes,
  queues,
  setToggleActive,
}) => {
  // useEffect(() => {

  // },[cardSizes] )

  const selectColor = () => {
    if (element.devices_registered === false) return "danger";
    else if (type === "READY") return "success";
    else if (type === "true" || type === "false" || type === "conference")
      return "primary";
  };

  const color = selectColor();

  const getData = (channels) => {

    if (channels.length === 0) return ''

    if (channels.length > 1) return "Conference";

    let time = new Date(channels[0].time * 1000).toISOString().substr(11, 8)

    if (channels[0].terminated) return `Call Terminated ${time}`

    if (!channels[0].answered) return "Calling...";
    return `${channels[0].withNumber} ${time}`

  }
  return (

    <div className="card-user" >

      <div
        className={`bg-${color}`}
        style={{
          position: "absolute",
          height: "100%",
          width: "10px",
          top: "0px",
          left: "0px",
          borderRadius: "10px",
        }}
      ></div>
      <Col className="col-12" style={{ fontSize: "0.7rem", borderRadius: "5px", }}>
        <h5 style={{ fontSize: "0.8rem" }}>
          {`${element.first_name} ${element.last_name}`}
          ({element.presence_id})
        </h5>
        <div>
          {getData(element.channels)}
        </div>
      </Col>
    </div>

  );
};

const Item = ({
  element,
  i,
  users,
  toggle,
  setToggle,
  cardSizes,
  setToggleActive,
  activeId,
  resize
}) => {
  const refresh = useRefresh();

  useEffect(() => {
    refresh();
  }, [cardSizes, toggle, resize]);

  let conferenceActive = false;
  if (element.channels.length !== 0) {
    users.map((elementTwo, i) => {
      if (elementTwo.channels.length > 1) {
        elementTwo.channels.map((elementThree, index) => {
          if (
            element.presence_id === elementThree.callee_id_number ||
            element.presence_id === elementThree.caller_id_number
          ) {
            conferenceActive = true;
          }
        });
      }
    });
  }
  return (
    <div
      key={i}
      devices_registered={element.devices_registered}
      presence_id={element.presence_id}
      channels={element.channels}
      style={{
        zIndex: toggle === i || parseInt(activeId) === i ? "10" : "1",
        height: "75px",
        width: "220px",
        position: "relative",
        marginBottom: "0.7rem",
        borderRadius: "5px",
      }}
    >
      <CardPhone
        cardSizes={cardSizes}
        key={i}
        type={`${element.channels.length === 0
          ? "READY"
          : element.channels.length > 1 || conferenceActive
            ? "conference"
            : element.channels[0].answered
          }`}
        element={element}
        toggle={toggle}
        setToggle={setToggle}
        setToggleActive={setToggleActive}
        i={i}
      />{" "}
    </div>
  );
};

export default function CardUsers({
  users,
  switchOne,
  switchTwo,
  searchFilter,
  cardSizes,
  id,
  layout,
}) {

  const [toggle, setToggle] = useState(false);
  const [toggleActive, setToggleActive] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [resize, setResize] = useState(null)



  const options = {
    dragEnabled: true,
  };


  const changePosition = (item) => {
    let data = [];
    item.forEach((element, i) => {
      data.push({ position: i, id: element._component.data.element.id });
    });
    petition_patch("editCards", {
      id: id,
      data: { panel_settings: { extensions: data } },
    })
      .then(({ data: result }) => { })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const filter = useCallback(
    (data) => {
      let value = true;
      if (!data.devices_registered && !switchTwo) {
        value = false;
      }
      if (!data.presence_id.includes(searchFilter)) {
        value = false;
      }

      if (data.channels.length === 0 && !switchOne) {
        value = false;
      }
      return value;
    },
    [
      switchTwo,
      searchFilter,
      switchOne,
      cardSizes,
      layout.leftSideBarType,
    ]
  );

  const children = users.map((element, i) => (
    <Item
      resize={resize}
      devices_registered={element.devices_registered}
      presence_id={element.presence_id}
      channels={element.channels}
      users={users}
      cardSizes={cardSizes}
      toggle={toggle}
      setToggle={setToggle}
      setToggleActive={setToggleActive}
      element={element}
      activeId={activeId}
      i={i}
      key={i}
    />
  ));
  return (
    /*  <Styles> */
    <ResizableBox onResize={(e) => { setResize(e.x + e.y) }} width={1000} height={(users.length / 4) * 150} minConstraints={[250, 200]} >

      <div className="containerGlass mt-3" >

        <div className="area" >
          <ul className="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div >

        <div className="glass">
          <MuuriComponent
            propsToData={(data) => data}
            filter={filter}
            {...options}
            onDragEnd={(item) => changePosition(item.getGrid()._items)}
            onDragStart={(item) => {
              setActiveId(item._component.key);
              if (toggle) {
                setToggle(null);
                setToggleActive(null);
              }
            }}
          >
            {children}
          </MuuriComponent>
        </div>
      </div>
    </ResizableBox >
    /*  </Styles> */
  );
}
