import React, {Component} from "react";
//import { Row, Col, Card, CardBody, Button, Container } from "reactstrap";
//import logolight from "../../assets/images/logo-light.png";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

//Import Breadcrumb
//import Breadcrumbs from "../Common/Breadcrumb";

export default class Alert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basic: false,
      with_title: false,
      success_msg: false,
      confirm_msg: false,
      success_dlg: false,
      error_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      confirm_both: false,
      img_header: false,
      img_html: false,
      custom_div: false,
      emailtxt: "",
      emailerr: "",
      step1: false,
      step1_txt: "",
      step2: false,
      step2_txt: "",
      step3: false,
      step3_txt: "",
      step_queue: false,
      final_queue: false,
      current_ip: "219.91.239.22",
      close_timer: false,
      timeralert: null,
    };
    this.handleStep1Change = this.handleStep1Change.bind(this);
    this.handleStep2Change = this.handleStep2Change.bind(this);
    this.handleStep3Change = this.handleStep3Change.bind(this);
  }

  handleStep1Change(e) {
    this.setState({step1_txt: e.target.value});
  }

  handleStep2Change(e) {
    this.setState({step2_txt: e.target.value});
  }

  handleStep3Change(e) {
    this.setState({step3_txt: e.target.value});
  }

  stimer() {
    const getTimer = () => (
      <SweetAlert
        title={
          <span>
            HTML <small>AutoClose Timer</small>!
          </span>
        }
        onConfirm={() => this.hideTimeAlert()}
      >
        {" "}
        <span>Timer Will Expire after 3 Seconds.</span>
      </SweetAlert>
    );
    this.setState({timeralert: getTimer()});
    setTimeout(
      function () {
        this.setState({timeralert: null});
      }.bind(this),
      3000
    );
  }
  hideTimeAlert() {
    this.setState({timeralert: null});
  }

  componentDidUpdate(prevProps) {
    // Uso tipico (no olvides de comparar las props):
    if (this.props !== prevProps) {
      this.setState({
        confirm_both: this.props.confirm_both,
        success_dlg: this.props.success_dlg,
        error_dlg: this.props.error_dlg,
        dynamic_title: this.props.dynamic_title,
        dynamic_description: this.props.dynamic_description,
      });
    }
  }
  // componentDidMount(){
  //   this.setState({ confirm_both: this.props.confirm_both })
  // }

  render() {
    return (
      <React.Fragment>
        {this.state.timeralert}
        {this.state.success_dlg ? (
          <SweetAlert
            success
            title={this.state.dynamic_title}
            onConfirm={() => {
              this.setState({success_dlg: false});
              this.props.resetValues();
            }}
          >
            {this.state.dynamic_description}
          </SweetAlert>
        ) : null}

        {this.state.error_dlg ? (
          <SweetAlert
            error
            title={this.state.dynamic_title}
            onConfirm={() => {
              this.setState({error_dlg: false});
              this.props.resetValues();
            }}
          >
            {this.state.dynamic_description}
          </SweetAlert>
        ) : null}
      </React.Fragment>
    );
  }
}
