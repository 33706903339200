import React, { useEffect } from 'react'
import Input from "../../../../../../components/utils/Input"
import * as yup from "yup"
import { useFormik } from "formik"
export default function GroupDetails({ form, setForm }) {

    const initialForm = [
        { name: "name", placeholder: "", label: "Group Name", type: "text" },
        { name: "description", placeholder: "", label: "Group Description", type: "text" },
    ]

    const formik = useFormik({
        initialValues: {
            name: form.name || "",
            description: form.description || "",

        },
        validationSchema: yup.object({
            name: yup.string().required("Group Name is Required"),
            description: yup.string().required("Group Name is Required"),

        }),
    });


    useEffect(() => { setForm({ ...form, ...formik.values }) }, [formik.values])
    console.log("TestForm", form)
    console.log("TestForm", formik.values)


    return (
        <>
            {initialForm.map((element, i) => <Input formik={formik} key={i} {...element} onBlur={formik.handleBlur} value={formik.values[element.name]} onChangeInput={formik.handleChange} />)}

        </>
    )
}
