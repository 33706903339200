import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { sip, userAgentStart, userAgentEnd } from "../../store/actions";
import ringbacktone from "../../assets/audio/ringbacktone.mp3";
import ringtoneSound from "../../assets/audio/ringtone.mp3";
import sdpTransform from "sdp-transform"

import sdpParser from "sdpparser"
import {
    UserAgent,
    Inviter,
    SessionState,
    Web,
    onicecandidate,
    SessionDescriptionHandlerModifier
} from "sip.js";
import { forEach } from "lodash";

function SipComponent({
    layout,
    status,
    configuration,
    registerSipExtention,
    sip,
    userAgentEnd,
    callNumber,
    audioInputSelected,
    audioOutputSelected,
    answerCall,
    endCall,
    rejectedCall,
    endInvited,
    hold,
    unhold,
    mute,
    unmute,
    silence,
    unsilence,
    keyboardNumber,
    actualSessionsInPhone,
    onInvitePhone,
    volumen,
    transferCall,
    setActualSessionSelected,
    holdWaiting,
    deviceData,
    setDisconnected,
    reloadRegister,
    setReloadRegister,
}) {



    const [closeCall, setCloseCall] = useState(null)
    const [updateCallSessions, setUpdateCallSessions] = useState(false)
    const [callAudio] = useState(new Audio());
    const [ringtone] = useState(new Audio(ringtoneSound));
    const [rinding] = useState(new Audio(ringbacktone));
    const [first, setFirst] = useState(true)
    const [makeCall, setMakeCall] = useState(false)
    const [reloadIntervalRegister, setReloadIntervalRegister] = useState(false)

    const [cleanupMediaEffect, setCleanupMediaEffect] = useState(false)
    const [eventOnInvite, setEventOnInvite] = useState(null)

    const [disconnectPhone, setDisconnectPhone] = useState(false)


    // const [sourceStreamInput, setSourceStreamInput] = useState([]);
    // const [sourceStreamOutput, setSourceStreamOutput] = useState([]);
    const [receivedTracks, setReceivedTracks] = useState([]);


    // const [context] = useState(new AudioContext());
    // const [allReceivedMediaStreams] = useState(new MediaStream());
    // const [mixedOutput] = useState(context.createMediaStreamDestination());

    rinding.loop = true;
    ringtone.loop = true;

    const [registered, setRegistered] = useState(false);
    const [actualSessions, setActualSessions] = useState([]);
    // const [remoteStream] = useState(new MediaStream());

    useEffect(() => {
        setRegistered(layout.result)
    }, [layout.active])

    useEffect(() => {
        if (registerSipExtention) {
            registerSip()
        } else {
            unRegisterSip()
        }
    }, [registerSipExtention])



    useEffect(() => {

        if (reloadRegister) {
            registerSip()
            setReloadRegister(false)
        }


    }, [reloadRegister])


    useEffect(() => {

        if (disconnectPhone) {
            setDisconnectPhone(false)
            setDisconnected("You have logged in from another browser or device, click below to re-activate this session or log out")

        }
    }, [disconnectPhone])


    useEffect(() => {
        if (callNumber.initiCall) {
            call(callNumber.numberToCall, callNumber.setInitCall);
        }
    }, [callNumber])

    useEffect(() => {
        if (answerCall.initAnswer) {
            answer();
        }
    }, [answerCall])

    useEffect(() => {

        if (endCall.endCall) {
            endCalls(endCall.endCall);
        }
    }, [endCall.endCall])

    useEffect(() => {
        if (rejectedCall.rejectedCall) {
            reject();
        }
    }, [rejectedCall])


    useEffect(() => {
        if (cleanupMediaEffect) {

            setCleanupMediaEffect(false)
            cleanupMedia(actualSessions.length)


        }

    }, [cleanupMediaEffect])


    useEffect(() => {
        changeAudioInput(audioInputSelected)
    }, [audioInputSelected])

    useEffect(() => {
        attachSinkId(callAudio, audioOutputSelected);
    }, [audioOutputSelected])

    useEffect(() => {
        if (hold.hold) {
            holdCall();
        }
    }, [hold])

    useEffect(() => {
        if (unhold.unhold) {
            unholdCall();
        }
    }, [unhold])

    useEffect(() => {
        if (mute.mute) {
            muteCall();
        }
    }, [mute])

    useEffect(() => {
        if (unmute.unmute) {
            unmuteCall();
        }
    }, [unmute])

    useEffect(() => {
        if (silence.silence) {
            silenceCall();
        }
    }, [silence])

    useEffect(() => {
        if (unsilence.unsilence) {
            unsilenceCall();
        }
    }, [unsilence])

    useEffect(() => {
        if (keyboardNumber.keyboardNumber != null) {
            sendDTMF();
        }

    }, [keyboardNumber])

    useEffect(() => {
        console.log(volumen.volume);
        if (volumen) {
            ringtone.volume = volumen.volume / 100;
        }

    }, [volumen.volume])

    useEffect(() => {
        if (first) {
            ringtone.volume = 1.0;
            setFirst(false);
        }

    }, [first])

    useEffect(() => {
        if (actualSessionsInPhone) actualSessionsInPhone.setActualSessions(actualSessions)
    }, [actualSessions])


    useEffect(() => {
        if (transferCall.transferCall) {
            transfer();
        }
    }, [transferCall])


    useEffect(() => {
        if (updateCallSessions) {
            setUpdateCallSessions(false)
            returnHold()
        }
    }, [updateCallSessions])



    useEffect(() => {

        if (closeCall) {

            let newActualSessions = actualSessions.filter(element => element._id !== closeCall.inviter._id)
            onInvitePhone(closeCall.type, newActualSessions)
            setActualSessionSelected(newActualSessions[newActualSessions.length - 1])
            setActualSessions(newActualSessions)
            returnHoldEndCall(newActualSessions)
        }


    }, [closeCall])

    useEffect(() => {

        if (makeCall) {
            setMakeCall(false)

            setActualSessionSelected(actualSessions[actualSessions.length - 1])
            setupRemoteMedia([actualSessions[actualSessions.length - 1]]);
        }

    }, [makeCall])


    useEffect(() => {
        if (holdWaiting.holdWaiting) {


            let findActualSession = actualSessions.find(element => element._id === holdWaiting.holdWaiting)

            if (findActualSession) setActualSessionSelected(findActualSession);

            const options = {
                sessionDescriptionHandlerModifiers: [Web.holdModifier],
            };
            const optionsFind = {
                sessionDescriptionHandlerModifiers: [],
            };

            actualSessions.map(async (element, index) => {
                if (element !== null && element !== undefined) {

                    await element.invite(element._id === holdWaiting.holdWaiting ? optionsFind : options);
                }
            })


            holdWaiting.setHoldWaiting(false)


        }

    }, [holdWaiting.holdWaiting])


    useEffect(() => {


        if (eventOnInvite) {

            let invitation = eventOnInvite;

            if (!deviceData.itpvoice_metadata.enableCallWaiting && actualSessions.length > 0) invitation.reject()

            else {

                if (onInvitePhone) onInvitePhone("inviteInit")
                ringtone.play();
                invitation.initialTime = new Date();
                setActualSessions(prev => { return [...prev, invitation] });
            }

        }


    }, [eventOnInvite])


    function returnHold() {



        const options = {
            sessionDescriptionHandlerModifiers: [Web.holdModifier],
        };

        actualSessions.map(async (element, index) => {
            if (index !== actualSessions.length - 1 && element !== null && element !== undefined) {
                await element.invite(options);
            }
        })

        setActualSessionSelected(actualSessions[actualSessions.length - 1])

    }

    function returnHoldEndCall(listActualSessions) {



        const options = {
            sessionDescriptionHandlerModifiers: [],
        };

        listActualSessions.map(async (element, index) => {
            if (index === listActualSessions.length - 1 && element !== null && element !== undefined) {
                await element.invite(options);
            }
        })
    }





    function transfer() {
        if (actualSessions.length > 0) {

            let target = UserAgent.makeURI(`sip:${transferCall.transferCall}@${configuration.realm}`);
            actualSessions[0].refer(target);
        }
        transferCall.setTransferCall(false);
    }



    function registerSip() {
        let register = sip({
            username: configuration.username,
            password: configuration.password,
            realm: configuration.realm,
            setDisconnectPhone,
            onInvite
        })
        setRegistered(register)
        status.getRegistered(register);

    }

    function unRegisterSip() {
        if (registered) {
            let register = !userAgentEnd()
            status.getRegistered(register);
        }
    }



    function onInvite(invitation) {


        setEventOnInvite(invitation)



        invitation.stateChange.addListener((state) => {

            switch (state) {
                case SessionState.Initial:
                    break;
                case SessionState.Establishing:
                    ringtone.pause();
                    break;
                case SessionState.Established:
                    setupRemoteMediaOnInvite(invitation);
                    ringtone.pause();
                    break;
                case SessionState.Terminating:
                // fall through
                case SessionState.Terminated:
                    ringtone.pause();
                    if (onInvitePhone) setCloseCall({ type: "inviteEnd", inviter: invitation })
                    setCleanupMediaEffect(true)
                    break;
                default:
                    throw new Error("Unknown session state.");
            }
        });

    }

    function answer() {
        if (actualSessions.length != 0) {
            actualSessions[actualSessions.length - 1].accept();
        }
        answerCall.setInitAnswer(false);

    };

    function call(number, setInitCall) {
        // const target = UserAgent.makeURI(`sip:${number}@${realm}`);
        setInitCall(false);
        if (registered) {



            const modifier: SessionDescriptionHandlerModifier = (description) => {
                let { sdp = '' } = description
                let result = sdpTransform.parse(sdp)

                result.media[0].rtp = [{ ...result.media[0].rtp[0] }, { ...result.media[0].rtp[1] }]
                description.sdp = sdpTransform.write(result)

                return Promise.resolve(description)
            }

            console.log("testing", configuration)

            const target = UserAgent.makeURI(`sip:${number}@${configuration.realm}`);
            const inviter = new Inviter(layout.userAgent, target, { sessionDescriptionHandlerModifiers: [modifier] });
            inviter.initialTime = new Date();



            inviter.stateChange.addListener((state) => {

                console.log(`Sessions Call Session state changed to ${state}`);
                switch (state) {
                    case SessionState.Initial:
                        break;
                    case SessionState.Establishing:

                        rinding.play();

                        break;
                    case SessionState.Established:
                        rinding.pause();
                        setMakeCall(true)

                        break;
                    case SessionState.Terminating:
                    // fall through
                    case SessionState.Terminated:
                        rinding.pause();
                        actualSessions.forEach((element, i) => {
                            if (element.request.callId === inviter.request.callId) {
                                actualSessions.splice(i, 1);
                            }
                        });
                        if (actualSessions.length === 0) {
                            setCleanupMediaEffect(true)
                            setCloseCall({ type: "callEnd", inviter })
                        }

                        break;
                    default:
                        throw new Error("Unknown session state.");
                }
            });
            inviter.invite()
            setActualSessions(prev => { return [...prev, inviter] });
        }

    };

    function endCalls(idSession) {
        endCall.setEndCall(false)
        actualSessions.map(element => {
            if (element.id === idSession || actualSessions.length === 1) {

                switch (element.state) {
                    case SessionState.Initial:
                        element.cancel();
                        break;
                    case SessionState.Establishing:
                        element.cancel();
                        break;
                    case SessionState.Established:
                        element.bye();
                        break;
                    case SessionState.Terminating:
                    case SessionState.Terminated:
                        break;
                    default: break;
                }
            }

        });

        if (actualSessions.length === 1) {

            callAudio.srcObject = null;
            setCleanupMediaEffect(true)
            setActualSessions([]);
        } else {
            let newActualSessions = actualSessions.filter(element => element._id !== idSession)
            setActualSessions(newActualSessions);
            setActualSessionSelected(newActualSessions[newActualSessions.length - 1])
            if (newActualSessions.length > 1) {
                returnHoldEndCall(newActualSessions);

            }
        }
    }

    function sendDTMF() {
        if (actualSessions.length != 0) {
            const options = {
                requestOptions: {
                    body: {
                        contentDisposition: "render",
                        contentType: "application/dtmf-relay",
                        content: "Signal=" + keyboardNumber.keyboardNumber + "\r\nDuration=1000"
                    }
                }
            };
            console.log("send", keyboardNumber.keyboardNumber)
            actualSessions.forEach(element => {
                element.info(options)
            });
            keyboardNumber.setKeyboardNumber(null);

        }
    }


    function reject() {

        rejectedCall.setRejectedCall(false)

        actualSessions[actualSessions.length - 1].reject()

        setCleanupMediaEffect(true)


    };

    function cleanupMedia(sessions) {

        if (sessions.length === 1) {
            console.log("Sessions cleanup media",)

            callAudio.srcObject = null;
            callAudio.pause();
        }

    }


    function setupRemoteMediaOnInvite(session) {
        const remoteStream = new MediaStream();
        session.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver) => {
            if (receiver.track) {
                remoteStream.addTrack(receiver.track);
            }
        });
        callAudio.srcObject = remoteStream;
        callAudio.play();
        setUpdateCallSessions(true)
    }

    function setupRemoteMedia(sessions) {
        let receivedTracksTemp = [];

        sessions.forEach(function (session) {
            if (session !== null && session !== undefined) {
                session.sessionDescriptionHandler.peerConnection
                    .getReceivers()
                    .forEach(function (receiver) {
                        receivedTracksTemp.push(receiver.track);
                    });
            }
        });

        let context = new AudioContext();
        let allReceivedMediaStreams = new MediaStream();
        sessions.forEach(function (session) {
            if (session !== null && session !== undefined) {
                let mixedOutput = context.createMediaStreamDestination();
                session.sessionDescriptionHandler.peerConnection
                    .getReceivers()
                    .forEach(function (receiver) {
                        receivedTracksTemp.forEach(function (track) {
                            allReceivedMediaStreams.addTrack(receiver.track);
                            if (receiver.track.id !== track.id) {

                                console.log(track)
                                let sourceStream = context.createMediaStreamSource(
                                    new MediaStream([track])
                                );
                                sourceStream.connect(mixedOutput);
                            }
                        });
                    });
                //mixing your voice with all the received audio

                session.sessionDescriptionHandler.peerConnection
                    .getSenders()
                    .forEach(function (sender) {
                        let sourceStreamTwo = context.createMediaStreamSource(
                            new MediaStream([sender.track])
                        );
                        sourceStreamTwo.connect(mixedOutput);
                    });
                session.sessionDescriptionHandler.peerConnection
                    .getSenders()[0]
                    .replaceTrack(mixedOutput.stream.getTracks()[0])
                    .then((e) => {
                        callAudio.srcObject = allReceivedMediaStreams;
                        callAudio.play();
                        setReceivedTracks(receivedTracksTemp);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        });
    }

    function holdCall() {
        const options = {
            sessionDescriptionHandlerModifiers: [Web.holdModifier],
        };
        actualSessions.forEach(element => {
            element.invite(options);
        });
        hold.setHold(false);
    }

    function unholdCall() {
        const options = {
            sessionDescriptionHandlerModifiers: [],
        };
        actualSessions.forEach(element => {
            element.invite(options);
        });
        unhold.setUnhold(false)
    }


    function silenceCall() {
        actualSessions.forEach(element => {
            if (element !== null && element !== undefined) {
                element.sessionDescriptionHandler.peerConnection
                    .getReceivers()
                    .forEach(function (receiver) {
                        receiver.track.enabled = false;
                    });
            }
        })
        silence.setSilence(false);
    }

    function unsilenceCall() {
        actualSessions.forEach(element => {
            if (element !== null && element !== undefined) {
                element.sessionDescriptionHandler.peerConnection
                    .getReceivers()
                    .forEach(function (receiver) {
                        receiver.track.enabled = true;
                    });
            }
        })

        unsilence.setUnsilence(false);
    }

    function muteCall() {
        actualSessions.forEach(element => {
            if (element !== null && element !== undefined) {
                element.sessionDescriptionHandler.peerConnection
                    .getSenders()
                    .forEach(function (receiver) {
                        if (receiver.track != null) {
                            receiver.track.enabled = false;
                        }
                    });
            }
        })
        mute.setMute(false);
    }

    function unmuteCall() {
        actualSessions.forEach(element => {
            if (element !== null && element !== undefined) {
                element.sessionDescriptionHandler.peerConnection
                    .getSenders()
                    .forEach(function (receiver) {
                        if (receiver.track != null) {
                            receiver.track.enabled = true;
                        }
                    });
            }
        })
        unmute.setUnmute(false);
    }




    ///// salida de sonido y entrada de audio

    function changeAudioInput(audioInput) {
        if (audioInput) {
            const audioSource = audioInput;
            const constraints = {
                audio: { deviceId: audioSource ? { exact: audioSource } : "undefined" },
                video: false
            };
            if (actualSessions.length != 0) {
                navigator.mediaDevices.getUserMedia(constraints).then(gotStream).catch(handleError);
            } else {
                navigator.mediaDevices.getUserMedia(constraints).catch(handleError)
            }

        }
    }

    function attachSinkId(element, sinkId) {
        if (typeof element.sinkId !== 'undefined') {
            element.setSinkId(sinkId)
                .then(() => {
                    console.log(`Success, audio output device attached: ${sinkId}`);
                })
                .catch(error => {
                    let errorMessage = error;
                    if (error.name === 'SecurityError') {
                        errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                    }
                    console.error(errorMessage);
                    // state.audioOutputSelect.selectedIndex = 0;
                });
        } else {
            console.warn('Browser does not support output device selection.');
        }
    }


    function gotStream(stream) {
        actualSessions.forEach(element => {
            element.sessionDescriptionHandler.peerConnection.getSenders()[0].replaceTrack(stream.getAudioTracks()[0]);
        });
        return navigator.mediaDevices.enumerateDevices();
    }

    function handleError(error) {
        console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
    }

    return (
        <video id="video" ></video>
    )
}



const mapStateToProps = (state) => {
    return {
        layout: state.sip,
    };
};


export default connect(mapStateToProps, { sip, userAgentStart, userAgentEnd })(SipComponent);