import React, {Component} from "react";
import {
  Container,
  Col,
  Row,
  Button,
  Card,
  CardTitle,
  Input,
  Label,
  FormGroup,
  Spinner,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import petition_get from "../../petitions/petition_get";
import petition_post from "../../petitions/petition_post";
import CurrencyFormat from "react-currency-format";
import Alert from "../../../components/utils/Alert";

export default class MakePayments extends Component {
  constructor(props) {
    super(props);
    this.date = new Date();
    this.year = this.date.getFullYear();
    this.years = [];
    this.types = ["Amex", "Visa", "MasterCard", "Discover"];
    this.userData = JSON.parse(window.localStorage.getItem("authUser"));
    for (let index = this.year; index <= this.year + 10; index++) {
      this.years.push(index);
    }
    this.state = {
      data: [],
      PayAnotherAmount: "",
      cardNumber: "",
      firstName: "",
      lastName: "",
      type: this.types[0],
      year: this.year + "",
      expiration: "1",
      last_4: null,
      newCreditCard: false,
      loading: true,
      amount: 0,
      currentBalance: "true",
      saveAmount: "",
      confirm_both: false,
      success_dlg: false,
      error_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      buttonLogIn: false,
    };
  }
  resetValues = () => {
    this.setState({
      confirm_both: false,
      success_dlg: false,
      error_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
    });
  };
  componentDidMount() {
    petition_get("account", this.userData.account.billing_api_id)
      .then(({data: {result}}) => {
        this.setState({
          data: result,
          last_4: result.credit_card_info.last_4,
          newCreditCard:
            result.credit_card_info.last_4 === null ? "true" : "false",
          loading: false,
          paymentAmount: result.balance,
          amount: result.balance,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  submitExisting() {
    this.setState({buttonLogIn: true});
    let data = [
      {
        amount: this.state.amount,
      },
      this.userData.account.billing_api_id,
    ];
    petition_post("payments_creditcard-onfile", data)
      .then(({data: {result}}) => {
        this.setState({
          success_dlg: true,
          error_dlg: false,
          dynamic_title: "success",
          dynamic_description: "success",
          buttonLogIn: false,
        });
        window.location.reload(true);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          success_dlg: false,
          error_dlg: true,
          dynamic_title: "error",
          dynamic_description: "error",
          buttonLogIn: false,
        });
      });
  }

  submitNew() {
    this.setState({buttonLogIn: true});
    let data = [
      {
        amount: this.state.amount,
        cc_first_name: this.state.firstName,
        cc_last_name: this.state.lastName,
        cc_num: this.state.cardNumber,
        cc_exp_month:
          this.state.expiration >= 10
            ? this.state.expiration
            : "0" + this.state.expiration,
        cc_exp_year: this.state.year,
        cc_type: this.state.type,
      },
      this.userData.account.billing_api_id,
    ];

    petition_post("payments_creditcard", data)
      .then(({data: {result}}) => {
        this.setState({
          success_dlg: true,
          error_dlg: false,
          dynamic_title: "success",
          dynamic_description: "success",
          buttonLogIn: false,
        });
        window.location.reload(true);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          success_dlg: false,
          error_dlg: true,
          dynamic_title: "error",
          dynamic_description: "error",
          buttonLogIn: false,
        });
      });
  }

  render() {
    if (this.state.loading === false) {
      return (
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs
                title="Make Payments"
                breadcrumbItem="Make Payments"
              />
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "4em",
                }}
              >
                <Col md="8">
                  <Card body>
                    <CardTitle>
                      <strong>Payment Amount </strong>
                    </CardTitle>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="radio2"
                            defaultChecked
                            id="currentBalance"
                            value={true}
                            onClick={() => {
                              this.setState({
                                currentBalance: document.getElementById(
                                  "currentBalance"
                                ).value,
                                amount: this.state.data.balance,
                              });
                            }}
                            disabled={
                              this.state.data.balance === "0.00" ? true : false
                            }
                          />{" "}
                          Current Balance
                        </Label>
                      </FormGroup>
                      {/* <h6>{"$" + this.state.data.balance}</h6> */}
                      <h3>
                        <CurrencyFormat
                          fixedDecimalScale={true}
                          value={this.state.data.balance}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                        />
                      </h3>
                    </div>
                    <br />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="radio2"
                            id="payAnotherAmount"
                            value={false}
                            onClick={() => {
                              this.setState({
                                currentBalance: document.getElementById(
                                  "payAnotherAmount"
                                ).value,
                                amount: this.state.saveAmount,
                              });
                            }}
                            disabled={
                              this.state.data.balance === "0.00" ? true : false
                            }
                          />
                          Pay Another Amount
                        </Label>
                      </FormGroup>
                      <CurrencyFormat
                        fixedDecimalScale={true}
                        decimalScale={2}
                        className="form-control"
                        id="price"
                        // value={this.state.price_per_unit}
                        thousandSeparator={true}
                        style={{
                          maxWidth: "20em",
                          display:
                            this.state.currentBalance === "true"
                              ? "none"
                              : "initial",
                        }}
                        prefix={"$"}
                        onValueChange={(values) => {
                          const {value} = values;
                          this.setState({amount: value, saveAmount: value});
                        }}
                        disabled={
                          this.state.currentBalance === "true" ? true : false
                        }
                      />
                      <Input
                        type="text"
                        style={{
                          maxWidth: "20em",
                          display:
                            this.state.currentBalance !== "true"
                              ? "none"
                              : "initial",
                        }}
                        disabled
                      />
                    </div>
                  </Card>
                </Col>

                <Col md="8">
                  <Card body>
                    <CardTitle>
                      <strong>Payment Method </strong>
                    </CardTitle>
                    <br />
                    <div style={{display: "flex"}}>
                      <FormGroup tag="fieldset">
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="radio"
                              name="radio1"
                              id="existingCard"
                              value={false}
                              defaultChecked={
                                this.state.last_4 !== null ? true : false
                              }
                              onClick={() => {
                                this.setState({
                                  newCreditCard: document.getElementById(
                                    "existingCard"
                                  ).value,
                                });
                              }}
                              disabled={
                                this.state.last_4 === null ||
                                this.state.data.balance === "0.00"
                                  ? true
                                  : false
                              }
                            />
                            Use Existing Card
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="radio"
                              name="radio1"
                              id="newCreditCard"
                              value={true}
                              defaultChecked={
                                this.state.last_4 === null ? true : false
                              }
                              onClick={() => {
                                this.setState({
                                  newCreditCard: document.getElementById(
                                    "newCreditCard"
                                  ).value,
                                });
                              }}
                              disabled={
                                this.state.data.balance === "0.00"
                                  ? true
                                  : false
                              }
                            />{" "}
                            Use New Credit/Debit Card
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </div>

                    <br />
                    <div
                      style={{
                        display:
                          this.state.newCreditCard !== "true"
                            ? "none"
                            : "initial",
                      }}
                    >
                      <Row>
                        <Col>
                          <div>
                            <label htmlFor="firstName">First Name</label>
                            <Input
                              id="firstName"
                              type="text"
                              onChange={(e) => {
                                this.setState({firstName: e.target.value});
                              }}
                            />
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <label htmlFor="lastName">Last Name</label>
                            <Input
                              id="lastName"
                              type="text"
                              onChange={(e) => {
                                this.setState({lastName: e.target.value});
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <div>
                            <label htmlFor="cardNumber">Card Number</label>
                            <CurrencyFormat
                              className="form-control"
                              id="cardNumber"
                              format="################"
                              mask=""
                              onValueChange={(values) => {
                                const {value} = values;
                                this.setState({cardNumber: value});
                              }}
                            />
                          </div>
                        </Col>
                        <Col>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <div style={{width: "30%"}}>
                              <label htmlFor="expiration">Exp Month</label>
                              <Input
                                type="select"
                                name="select"
                                id="expiration"
                                style={{width: "100%"}}
                                onChange={(e) => {
                                  this.setState({expiration: e.target.value});
                                }}
                              >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                              </Input>
                            </div>
                            <div style={{width: "30%"}}>
                              <label htmlFor="year">Exp Year</label>
                              <Input
                                type="select"
                                name="select"
                                id="year"
                                onChange={(e) => {
                                  this.setState({year: e.target.value});
                                }}
                              >
                                {this.years.map((element, i) => (
                                  <option value={element} key={i}>
                                    {element}
                                  </option>
                                ))}
                              </Input>
                            </div>
                            <div style={{width: "30%"}}>
                              <label htmlFor="type">Type</label>
                              <Input
                                type="select"
                                name="select"
                                id="type"
                                onChange={(e) => {
                                  this.setState({type: e.target.value});
                                }}
                              >
                                {this.types.map((element, i) => (
                                  <option value={element} key={i}>
                                    {element}
                                  </option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Col>
                <Col md="8">
                  <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <Button
                      style={{marginRight: "0.5em"}}
                      onClick={() => {
                        this.props.history.push("./Billing");
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      style={{marginLeft: "0.5em"}}
                      color="primary"
                      onClick={() => {
                        this.state.newCreditCard !== "true"
                          ? this.submitExisting()
                          : this.submitNew();
                      }}
                      disabled={
                        this.state.amount === "" ||
                        this.state.amount === "0.00" ||
                        this.state.buttonLogIn === true ||
                        (this.state.newCreditCard === "true" &&
                          (this.state.firstName === "" ||
                            this.state.lastName === "" ||
                            this.state.cardNumber === "" ||
                            this.state.expiration === "" ||
                            this.state.year === "" ||
                            this.state.type === ""))
                          ? true
                          : false
                      }
                    >
                      {this.state.buttonLogIn === true ? (
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          style={{marginRight: "0.5em"}}
                        />
                      ) : null}
                      Pay Now
                    </Button>
                  </div>
                </Col>
              </Row>
              <Alert
                confirm_both={this.state.confirm_both}
                success_dlg={this.state.success_dlg}
                error_dlg={this.state.error_dlg}
                dynamic_title={this.state.dynamic_title}
                dynamic_description={this.state.dynamic_description}
                resetValues={this.resetValues}
              />
            </Container>
          </div>
        </React.Fragment>
      );
    } else {
      return <></>;
    }
  }
}
