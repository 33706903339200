import React from 'react'
import { Modal } from "reactstrap"
import ClearIcon from '@material-ui/icons/Clear';
export default function ModalInformation({ modal, setModal }) {

    const information = [
        { title: "Menu", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." },
        { title: "Time Condition", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." },
        { title: "Queue", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." },
        { title: "Ring Group", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." },
        { title: "Voicemail", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." },
        { title: "Device", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." },
        { title: "User", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." },

    ]

    return (
        <Modal size="lg" centered={true} isOpen={modal ? true : false}>
            <div className="px-4 mb-4">
                <div onClick={() => { setModal(false) }} style={{ position: "absolute", top: "20px", right: "20px", cursor: "pointer" }}>
                    <ClearIcon />

                </div>
                <h3 className="py-4 text-center" style={{ fontSize: "25px", color: "rgba(135, 145, 173, 1)" }}>Information</h3>
                {information.map(element =>

                    <div className="row p-2">

                        <div className="col-4" style={{ display: "flex", alignItems: "center" }}>{element.title}</div>
                        <div className="col-8 text-left">{element.content}</div>

                    </div>

                )}

            </div>
        </Modal>
    )
}
