import React, {useState, useEffect} from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";
import petition_get from "../../../../petitions/petition_get";
import petition_patch from "../../../../petitions/petition_patch";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Badge,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import Switch from "react-switch";
import {setLogLevel} from "firebase";
export default function ModalUtility({
  modal,
  setModal,
  addRingGroup,
  errorAdd,
  setErrorAdd,
}) {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({name: "", number: "", description: ""});
  const [customActiveTab, setCustomActiveTab] = useState("1");
  const onChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value});
  };

  const onSubmitForm = (e) => {
    if (
      form.name.trim() !== "" &&
      form.number.trim() !== "" &&
      form.description.trim() !== ""
    ) {
      setErrorAdd(null);
      setLoading(true);
      addRingGroup(form);
    }
  };

  useEffect(() => {
    if (errorAdd) {
      setLoading(false);
    }
  }, [errorAdd]);

  useEffect(() => {
    if (!modal) {
      setLoading(false);
      setForm({name: "", number: "", description: ""});
    }
  }, [modal]);

  const toggleCustom = (tab) => {
    setForm("");
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };

  return (
    <Modal centered={true} isOpen={modal} className="info ">
      <ModalHeader className="bg-soft-primary">
        {" "}
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary">Add Ring Group</h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Nav
          tabs
          className="nav-tabs-custom  flex-column flex-md-row  text-center  "
        >
          <NavItem className="pt-3 pt-md-0">
            <NavLink
              style={{cursor: "pointer"}}
              className={classnames({
                active: customActiveTab === "1",
              })}
              onClick={() => {
                toggleCustom("1");
              }}
            >
              <span>Add Ring Group</span>
            </NavLink>
          </NavItem>
          {/*           <NavItem className="pt-3 pt-md-0">
            <NavLink
              style={{cursor: "pointer"}}
              className={classnames({
                active: customActiveTab === "2",
              })}
              onClick={() => {
                toggleCustom("2");
              }}
            >
              <span>Add External Numbers2</span>
            </NavLink>
          </NavItem> */}
        </Nav>
        <TabContent activeTab={customActiveTab}>
          <TabPane tabId="1" className="p-3">
            <Row>
              <Col className="d-lg-flex col-12 pt-4">
                {" "}
                <Label
                  htmlFor="name"
                  style={{
                    cursor: "pointer",
                    width: "40%",
                    display: "flex",
                    alignItems: "center",
                    margin: "0",
                  }}
                >
                  Name
                </Label>{" "}
                <input
                  className="form-control"
                  id="name"
                  name="name"
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={form.name}
                  type="text"
                  placeholder="Name"
                />{" "}
              </Col>
              <Col className="d-lg-flex col-12 pt-3">
                {" "}
                <Label
                  htmlFor="number"
                  style={{
                    cursor: "pointer",
                    width: "40%",
                    display: "flex",
                    alignItems: "center",
                    margin: "0",
                  }}
                >
                  Number
                </Label>{" "}
                <input
                  className="form-control"
                  id="number"
                  name="number"
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={form.number}
                  type="number"
                  placeholder="Extension Number"
                />{" "}
              </Col>
              <Col className="d-lg-flex col-12 pt-3">
                {" "}
                <Label
                  htmlFor="description"
                  style={{
                    cursor: "pointer",
                    width: "40%",
                    display: "flex",
                    alignItems: "center",
                    margin: "0",
                  }}
                >
                  Description
                </Label>{" "}
                <input
                  className="form-control"
                  id="description"
                  name="description"
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={form.description}
                  type="text"
                  placeholder="Description"
                />{" "}
              </Col>
              <Col className="d-lg-flex col-12 pt-3">
                {errorAdd && (
                  <Badge
                    style={{margin: "0 auto", padding: "0.5rem 0.2rem"}}
                    color="danger"
                  >
                    {" "}
                    {errorAdd}{" "}
                  </Badge>
                )}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2" className="p-3">
            <Row>
              <Col className="d-flex col-12">
                {" "}
                <Label
                  htmlFor="number"
                  style={{
                    cursor: "pointer",
                    width: "40%",
                    display: "flex",
                    alignItems: "center",
                    margin: "0",
                  }}
                >
                  {" "}
                  Enter Number{" "}
                </Label>{" "}
                <Input
                  id="number"
                  onChange={onChange}
                  value={form}
                  type="number"
                  placeholder="3052990233"
                />{" "}
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </ModalBody>{" "}
      <ModalFooter>
        <Button
          outline
          color="secondary"
          disabled={loading}
          onClick={() => {
            setErrorAdd(null);
            setModal(false);
          }}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          onClick={() => {
            onSubmitForm();
          }}
          disabled={
            loading ||
            form.name.trim() === "" ||
            form.number.trim() === "" ||
            form.description.trim() === ""
          }
        >
          {loading && (
            <Spinner
              style={{marginRight: "0.5em"}}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
