import React, { useEffect, useState } from "react";

import petition_get from "../../../petitions/petition_get";
import petition_post from "../../../petitions/petition_post";
import petition_delete from "../../../petitions/petition_delete";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import CustomTable from "../../../../components/utils/CustomTable/CustomTable"
import CustomBreadCrum from "../../../../components/utils/CustomBreadCrum/CustomBreadCrum"


import ModalUtilityEdit from "./ModalUtility/ModalUtilityEdit";
import ModalUtilityAdd from "./ModalUtility/ModalUtilityAdd";
import ModalUtilityDelete from "./ModalUtility/ModalUtilityDelete";

import toastr from "toastr";
export default function Queues() {
  toastr.options = { showDuration: 300 };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const [modalEdit, setModalEdit] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const [data, setData] = useState(null);
  const [dataDescription, setDataDescription] = useState([]);
  const [dataTable, setDataTable] = useState(null)
  const [listUsers, setListUsers] = useState([]);

  const [spinner, setSpinner] = useState(false);
  const [reload, setReload] = useState(0);
  const [reloadData, setReloadData] = useState(false)
  const [errorAdd, setErrorAdd] = useState(null);

  const [loading, setLoading] = useState(false)


  const addQueues = (values) => {
    const newData = {
      name: values.name,
      description: values.description,
    };
    petition_post("addQueues", { id, data: newData })
      .then(({ data: result }) => {
        petition_get("QueuesDetails", { id, idQueque: result.result.id })
          .then(({ data: result }) => {
            toastr.success("Action completed", "Success");
            setModalAdd(false);
            setModalEdit(result.result);
            setReloadData(!reloadData)
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        toastr.error("Action failed", "Error");
        setErrorAdd("Verify your information");
        console.log(error.response);
      });
  };

  const deleteQueues = (queues_id) => {
    setSpinner(true);
    petition_delete("deleteQueues", { id, queues_id })
      .then(({ data: result }) => {
        setSpinner(false);
        setModalDelete(false);
        setReloadData(!reloadData)
        toastr.success("Action completed", "Success");
      })
      .catch((error) => {
        toastr.error("Action failed", "Error");
        console.log(error);
      });
  };

  useEffect(() => {
    let idInfo = [];
    let dataDescriptionSave = []
    setDataTable(null)

    const petitions = async () => {
      setDataTable(null)
      setDataDescription([])
      setData([])
      await petition_get("Queues", { id })
        .then(({ data: result }) => {
          idInfo = result.result;
          setData(result.result);
          setLoading(true)
        })
        .catch((error) => {
          console.log(error);
        });

      idInfo.map((element, i) => {
        petition_get("QueuesDetails", { id, idQueque: element.id })
          .then(({ data: result }) => {
            dataDescriptionSave.push({ ...result.result })
            setDataDescription(dataDescriptionSave);
            setReload(i);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    };
    petitions();
  }, [reloadData]);

  useEffect(() => {
    if ((data && data.length > 0 && dataDescription.length === data.length) || (data && loading)) {
      setDataTable({
        title: [
          { title: "Name", key: "name" },
          /*   { title: "Extension", key: "extension" }, */
          { title: "Description", key: "description" },
        ],
        content: [...dataDescription],
        actions: {
          title: "Actions",
          content: [
            {
              icon: <DeleteIcon />,  //Svg icon
              handleClick: (e) => { setModalDelete(e.id) },  //Function
            },
            {
              icon: <EditIcon />,  //Svg icon
              handleClick: (e) => { setModalEdit(e) },  //Function
            },
          ],
        },
        search: {
          label: "Search Menu"
        },
        addButton: {
          label: "Add Queues",
          handleClick: () => { setModalAdd(true) }
        }
      })
    }

  }, [reload, loading, dataDescription])

  //Get List of Users for Members in queues
  useEffect(() => {
    petition_get("itpvoiceUsers", { id })
      .then(({ data: result }) => {
        setListUsers(result.result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [])

  return (
    <>
      <ModalUtilityDelete
        spinner={spinner}
        deleteQueues={deleteQueues}
        modal={modalDelete}
        setModal={setModalDelete}
      />
      <ModalUtilityEdit
        setReloadData={setReloadData}
        reloadData={reloadData}
        listUsers={listUsers}
        id={id}
        modal={modalEdit}
        setModal={setModalEdit}
      />
      <ModalUtilityAdd
        setErrorAdd={setErrorAdd}
        errorAdd={errorAdd}
        addQueues={addQueues}
        modal={modalAdd}
        setModal={setModalAdd}
      />
      <div className="page-content">
        <CustomBreadCrum title="Your Phone System" subtitle="Queues" />
        <CustomTable data={dataTable} />
      </div>
    </>
  );
}
