import React, { Component, useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Table,
  Button,
  Row,
  Col,
  Input,
  Spinner,
  Badge,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Switch from "react-switch";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SearchIcon from "@material-ui/icons/Search";
import ButtonHover from "../../../../components/utils/ButtonHover";
import CustomBreadCrum from "../../../../components/utils/CustomBreadCrum/CustomBreadCrum";
import petition_get from "../../../petitions/petition_get";
import Alert404 from "../../../../components/404alert/Alert404";
import GroupList from "./Groups/GroupList"


//Modal
import ModalUtility from "./ModalUtility/ModalUtility";


import styled from "styled-components";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import classnames from "classnames";
import useContacts from "../../../../hooks/useContacts";
import Loading from "../../../../components/utils/Loading";
import SidebarComponent from "../../../../components/SidebarComponent/SidebarComponent";


import ContactListSelected from "./ContactListSelected"
import NavTabCustom from "../../../../components/utils/NavTabCustom";


export default function ContactList({ activeCall, setActiveCall }) {

  const [loading, setLoading] = useState(false);
  const [switchOne, setSwitchOne] = useState(true);
  const [modal, setModal] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [error404, setError404] = useState(false);
  const [customActiveTab, setCustomActiveTab] = useState("1");
  const { data, selectedItem, onChangeSelectedItem, reloadData } = useContacts()

  const DataItem = (({ item, index }) => {

    return (<>  <hr className="w-100" style={{ margin: "10px 0 0 0" }} />
      <div onClick={() => { if (!item.default) onChangeSelectedItem({ ...item, index }) }} className={`col-12 callhistory-item d-flex pt-2 ${selectedItem.pk === item.pk && "callhistory-item-active"}`}>
        <div className="d-flex align-items-center  mr-3 h-100">
          <div className="rounded-circle avatar-sm bg-secondary d-flex justify-content-center align-items-center text-black" style={{ textTransform: "uppercase", color: "black", fontSize: "20px", fontWeight: "700" }} >
            {item.cutName}
          </div>
        </div>
        <div className="w-100 d-flex flex-column justify-content-center">
          <div className="d-flex justify-content-between w-100">
            <h5 className="text-truncate font-size-14 mb-0">
              {item.name} {item.default && `(default)`}
            </h5>
            <p className="text-truncate mb-0">

            </p>
          </div>
          <p className="text-truncate mb-0">
            {item.default ? item.internal_number : `${item.numbers[0] && item.numbers[0].number} ${item.emails[0] ? `- ${item.emails[0].email} ` : ""}`}
          </p>
        </div>
      </div>

    </>)
  })



  const ContactListContent = () => {
    return (<>

      <ModalUtility
        setModal={setModal}
        modal={modal}
        reloadData={reloadData}

      />
      {data ? <div className="row">

        {data.length > 0
          ? <>
            <div className="col-12 col-lg-5 ">

              <div className="text-right mb-3">
                <Button
                  onClick={() => { setModal(true); }}
                  color="primary"
                >
                  <PersonAddIcon style={{ marginRight: "0.5rem", fontSize: "18px" }} />
                  Add Contact
                </Button>
              </div>

              <SidebarComponent filterKeys={['cutName', "name", { key: "emails", subKey: "email" }, { key: "numbers", subKey: "number" }]} data={data} DataItem={DataItem} />
            </div>
            <div className="col-12 col-lg-7 pt-5 pt-lg-0 "> {selectedItem && <ContactListSelected item={selectedItem} reloadData={reloadData} />} </div>  </>
          : <h3 className="text-center w-100">You do not have any call history at the moment. Make some calls!</h3>
        }
      </div> : <Loading />}
    </>)
  }

  return (
    <div className="page-content px-5">
      <CustomBreadCrum title="My Extensions" subtitle="Call History" />
      <NavTabCustom options={[{ title: "Contacts", component: <ContactListContent /> }, { title: "Groups", component: < GroupList contacts={data} /> }]} />
    </div>
  );
}
