import React from "react";

export default function TicketItem({icon, title, description, action}) {
  const handleClickTicket = () => {
    if (action) action();
  };

  return (
    <div
      className={`ticket-item ${action && "ticket-pointer"} `}
      onClick={handleClickTicket}
    >
      <div className="ticket-icon">{icon}</div>
      <h3 className="ticket-title"> {title}</h3>
      <p className="ticket-description">{description}</p>
    </div>
  );
}
