import React, { useState, useEffect } from "react";
import { Row, Col, Button, Table, Spinner, CustomInput, Badge } from "reactstrap";
import petition_post from "../../../../petitions/petition_post";
import petition_patch from "../../../../petitions/petition_patch";
import petition_get from "../../../../petitions/petition_get";
import { useHistory } from "react-router-dom";
import toastr from "toastr"

export default function FilteFour({ step, setStep, setData, data, apiId }) {
  //Get API_ID
  const history = useHistory();
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  toastr.options = {
    showDuration: 300,
  };
  //State
  const [check, setCheck] = useState(false);
  const [checkError, setCheckError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [CheckSendOption, setCheckSendOption] = useState(true);

  const onChangeCheck = (e) => {
    setCheckError(false);
    setCheck(e.target.checked);
  };

  const onChangeCheckSendOption = (e) => {
    setCheckSendOption(e.target.checked);
  };


  const send = async () => {
    if (!check) {
      return setCheckError(true);
    }

    setLoading(true);
    let dataUser = {
      data: {
        ...data.userInfo,
        password: "inspections7",
        vm_to_email_enabled: true,
        require_password_update: false,
        language: "en-US",
        voicemail_enabled: true,
        add_to_directory: true,
        send_email_on_creation: CheckSendOption,
        user_number: data.numberInfo ? data.numberInfo.number : null,
        phone_product_id: data.phoneId.pk ? data.phoneId.pk : null,
      },
      id: id,
    };
    petition_post("users", dataUser)
      .then(async ({ data: result }) => {


        if (data.numberInfo) {

          await updateCallflow(result, data.userInfo, data.numberInfo.number);
        }



        setLoading(false);
        toastr.success("Action completed", "Success");
        history.push({
          pathname: "/ListAllUsers",
          search: "?id=" + apiId,
        });
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        if (error.response && error.response.status === 400 && error.response.data.message) return toastr.error(error.response.data.message, "Error");
        toastr.error("Action failed", "Error");
      });
  };

  function totalPrice() {
    //Change, Price for Users is 0$
    let totalPrice = 0;
    if (data.numberInfo) {
      totalPrice = totalPrice + data.prices.price_per_did;
    }
    if (data.phoneId.pk !== "") {
      totalPrice = totalPrice + data.phoneId.product.price;
    }

    return totalPrice;
  }



  const updateCallflow = (dataUser, data, number) => {


    return new Promise(async (resolve, reject) => {

      if (number) {

        await petition_get("allCallflows", { id })
          .then(async ({ data: result }) => {


            const findCallFlowUserSave = result.result.find(element => element.owner_id === dataUser.result.data.id)

            await petition_get("callflowsDetails", { id, callflow: findCallFlowUserSave.id })
              .then(({ data: result }) => {
                result.result.numbers.push(number)
                petition_patch("callflowEdit", { id, callflow_id: findCallFlowUserSave.id, data: { ...result.result }, })
                  .then((result) => console.log(result))
                  .catch((error) => reject(false));

              })
              .catch((error) => reject(false));

            let data = [{ id, number }, { owner_id: dataUser.result.data.id, },];

            await petition_patch("numberEdit", data)

              .catch((error) => reject(false));
          })
          .catch((error) => { console.log(error); reject(false) });

        resolve(true)

      } else resolve(true)






    })

  }

  return (
    <>
      <br />
      <div style={{ margin: "0 2em" }}>
        <Table responsive>
          <thead className="thead-light">
            <tr>
              <th>Period</th>
              <th>Charges And Credits</th>
              <th style={{ textAlign: "center" }}>Unit Price</th>
              <th style={{ textAlign: "center" }}>Quantity</th>
              <th style={{ textAlign: "center" }}>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>11/03/2020 - 12/02/2020</td>
              <td>New User </td>
              <td style={{ textAlign: "center" }}>
                {"$0" /* data.prices.purchased_users */}
              </td>
              <td style={{ textAlign: "center" }}> 1</td>
              <td style={{ textAlign: "center" }}>
                {"$0" /* data.prices.purchased_users */}
              </td>
            </tr>

            {data.numberInfo && (
              <tr>
                <td>11/03/2020 - 12/02/2020</td>
                <td>
                  Additional Local Number(new purchase) -
                  {data.numberInfo.number}{" "}
                </td>
                <td style={{ textAlign: "center" }}>
                  {"$" + data.prices.price_per_did}
                </td>
                <td style={{ textAlign: "center" }}> 1</td>
                <td style={{ textAlign: "center" }}>
                  {"$" + data.prices.price_per_did}
                </td>
              </tr>
            )}
            {data.phoneId.pk !== "" && (
              <tr>
                <td>11/03/2020 - 12/02/2020</td>
                <td>New Phone</td>
                <td style={{ textAlign: "center" }}>
                  ${data.phoneId.product.price}
                </td>
                <td style={{ textAlign: "center" }}> 1</td>
                <td style={{ textAlign: "center" }}>
                  ${data.phoneId.product.price}
                </td>
              </tr>
            )}

            <tr>
              <td></td>
              <td></td>
              <td></td>
              <th>Estimated Total :</th>
              <th style={{ textAlign: "center" }}> ${totalPrice()}</th>
            </tr>
            <tr>
              <td colSpan="5" style={{ paddingTop: "4rem", border: "0" }}>
                All charges will appear as "Innovative Tech" on your credit
                card statement and will be charged to the credit card on file
                for this account if you have one on file.
              </td>
            </tr>

            <tr>
              <td colSpan="5">
                <div style={{ padding: "0" }} className="form-check d-flex">
                  <CustomInput
                    onChange={onChangeCheck}
                    type="checkbox"
                    // onChange={onChangeCheck}
                    className="input-check"
                    name=""
                    id="exampleCheck1"
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    <p>
                      {" "}
                      I acknowledge that I have read this information and
                      understand the itemized charges listed above. I authorize
                      the total amount of $2.36 to be charged to my credit card
                      ending in [*0496]. I further authorize the monthly
                      recurring charges of $4.99* beginning on 13/03/2020 and
                      continuing until canceled, which is in addition to my
                      regular charges.
                    </p>
                    * Prices do not include taxes, fees or discounts <br />
                    <Badge
                      color="danger"
                      style={{
                        marginTop: "2em",
                        padding: "0.3rem 1rem",
                        opacity: checkError ? "1" : "0",
                      }}
                    >
                      Accept Terms...
                    </Badge>
                  </label>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan="5">
                <div style={{ padding: "0" }} className="form-check d-flex">
                  <CustomInput
                    onChange={onChangeCheckSendOption}
                    type="checkbox"
                    // onChange={onChangeCheck}
                    className="input-check"
                    name=""
                    id="exampleCheck2"
                    checked={CheckSendOption}
                  />
                  <label className="form-check-label" htmlFor="exampleCheck2">
                    <p> Send welcome email to user</p>
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <br /> <br />
      <Row style={{ marginRight: "3em" }}>
        <Col sm="12" style={{ textAlign: "right" }}>
          <Button
            color="primary"
            // disabled={form.option !== "no" ? true : false}
            onClick={() => send()}
            disabled={loading}
          >
            {loading && (
              <Spinner
                style={{ marginRight: "0.5em" }}
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Send
          </Button>
        </Col>
      </Row>
    </>
  );
}
