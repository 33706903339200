import React, {useState, useEffect} from "react";
import {Col, Row, Label} from "reactstrap";
import Switch from "react-switch";
export default function AdvancedSettings({form, setForm}) {
  const [switchOne, setSwitchOne] = useState(form.fail_on_single_reject);

  useEffect(() => {
    setSwitchOne(form.fail_on_single_reject);
  }, [form]);

  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };
  return (
    <Row>
      <Col className="d-flex col-12 pt-4">
        <Label
          style={{
            width: "35%",
            display: "flex",
            alignItems: "center",
            margin: "0",
          }}
        >
          Fail Entire Ring Group on Reject
        </Label>{" "}
        <Switch
          uncheckedIcon={<Offsymbol />}
          className="mr-1"
          checkedIcon={<OnSymbol />}
          onColor="#626ed4"
          onChange={() => {
            setForm({
              ...form,
              fail_on_single_reject: !form.fail_on_single_reject,
            });
            setSwitchOne(!switchOne);
          }}
          checked={switchOne}
        />
      </Col>
    </Row>
  );
}
