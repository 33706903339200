import React, {useState} from "react";

import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  
} from "reactstrap";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export default function ModalUtility({modal, setModal,redirectUser}) {
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    mobile_num: "",
    home_num: "",
    business_num: "",
    email: "",
    group: "selected",
  });

  const onChangeForm = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitForm = (e) => {
   
  };

  const resetForm = () => {
    setForm({
      first_name: "",
      last_name: "",
      mobile_num: "",
      home_num: "",
      business_num: "",
      email: "",
      group: "selected",
    });
  };

  return (
    <Modal style={{marginTop: "10rem" }} isOpen={modal} className="info">
      <ModalBody style={{textAlign: "center",padding: "2rem"}} >
       <ErrorOutlineIcon style={{fontSize: "5rem", marginBottom: "2rem"}}/>
      <p style={{fontSize: "18px", textAlign: "center", paddingBottom: "1rem"}}> The link you have clicked on has expired. Please click below to resend a
        new email containing a refreshed link. </p> 
       <Button style={{fontSize: "18px", padding: "0.7rem 3rem"}} onClick={redirectUser} color="primary" > 
            Reset Link
         </Button> 
      </ModalBody>{" "}
    </Modal>
  );
}
