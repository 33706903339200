import React from "react";
import {
  Modal,
  ModalHeader,
  Row,
  Col,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import phoneImg from "../../../../../assets/images/phone-image.svg";

export default function AddKeyModals({ modal, loading, setModal,deleteApiKey }) {
  return (
    <Modal
      centered
      isOpen={modal === false ? false : true}
      className="info modal-md"
    >
      <ModalHeader className="bg-soft-primary">
        {" "}
        <Row>
          <Col xs="9">
            <div className="text-primary p-3">
              <h3 className="text-primary">Delete Key</h3>
            </div>
          </Col>
          <Col xs="3" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <Spinner
              animation="grow"
              style={{
                margin: "10vh auto",
                height: "10vh",
                width: "10vh",
              }}
            />{" "}
          </div>
        ) : (
          <p >
            Delete this Key ?
          </p>
        )}
      </ModalBody>{" "}
      <ModalFooter>
        <Button
          disabled={loading}
          outline
          color="secondary"
          onClick={() => {
            setModal(false);
          }}
        >
          Close
        </Button>

        <Button
          disabled={loading}
          color="primary"
          onClick={() => {
            deleteApiKey();
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
