import React from "react";
import {NavbarStyle} from "../Style/Style";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import ListIcon from "@material-ui/icons/List";
import {Col, Row, Button} from "reactstrap";
export default function NavbarTitle({setStep, step, data, setData}) {
  const onClickBack = () => {
    //resetInfo();
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const resetInfo = () => {
    const newData = data;
    if (step === 2) {
      delete newData.userInfo;
      setData({...newData});
    }
    if (step === 3) {
      delete newData.numberInfo;
      setData({...newData});
    }
    if (step === 4) {
    }
  };

  return (
    <Row
      style={{
        minHeight: "50px",
        display: "flex",
        alignItems: "center",
        margin: "0 10px",
      }}
    >
      <Col
        className="col-12  mt-2 mt-sm-2 col-sm-6 text-center text-sm-left"
        style={{fontSize: "17px"}}
      >
        {" "}
        Add Numbers
      </Col>
      <Col
        style={{height: "35px"}}
        className="col-12 col-sm-6  mt-2 mt-sm-2 mb-2 mb-sm-2  text-center text-sm-right"
      >
      {/*   {" "}
        <Button
          onClick={onClickBack}
          style={{height: "100%", padding: "0 10px"}}
          color="secondary"
          outline
          className="btn-back"
        >
          {" "}
          <DoubleArrowIcon className="icon-arrow" /> Back
        </Button>{" "}
        <Button
          style={{height: "100%", padding: "0 10px", marginLeft: "10px"}}
          color="secondary"
          outline
          className="btn-menu"
        >
          {" "}
          <ListIcon />{" "}
        </Button>{" "} */}
      </Col>
    </Row>
  );
}
