import React, {useState} from "react";

import {Card, CardBody, Row, Col, Input, Button} from "reactstrap";

import DateRangePicker from "react-bootstrap-daterangepicker";
import SearchIcon from "@material-ui/icons/Search";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import moment from "moment";
import ReplayIcon from "@material-ui/icons/Replay";
import ButtonHover from "../../../../../components/utils/ButtonHover";

export default function CardTools({
  filter,
  setFilter,
  setDataFilter,
  dataFilter,
  data,
  dataPickerFilter,
  setDataPickerFilter,
  setPagination,
  reloadFilters,
  runReport,
  setDirection,
  direction,
  resetAll,
}) {
  const [customActiveTab, setCustomActiveTab] = useState("1");
  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  }

  const onChangeSearch = (e) => {
    setPagination(10);
    setFilter({...filter, select: "all", search: e.target.value});
    const newData = data.filter(
      (element) =>
        element.caller_id_number.includes(e.target.value) ||
        element.callee_id_number.includes(e.target.value)
    );
    if (newData.length !== 0) return setDataFilter(newData);
    else return setDataFilter([...data]);
  };

  const onChangeSelect = (e) => {
    // setPagination(10);
    // setFilter({...filter, select: e.target.value, search: ""});
    // if (e.target.value === "all") return setDataFilter([...data]);

    // const newData = data.filter(
    //   (element) => element.direction === e.target.value
    // );
    // return setDataFilter(newData);
    runReport(e.target.value);
    setDirection(e.target.value);
  };

  const onChangeDate = (event, picker) => {
    setPagination(10);
    if (event.type === "apply") {
      setDataPickerFilter({
        startDate: Math.round(picker.startDate._d.getTime() / 1000),
        endDate: Math.round(picker.endDate._d.getTime() / 1000),
      });
    }
  };

  return (
    <Card>
      <CardBody>
        <Row>
          <Col sm="12" xl="12">
            <Row>
              {/* <Col sm="12" lg="3">
                <SearchIcon
                  style={{
                    position: "absolute",
                    top: "7px",
                    left: "20px",
                  }}
                />
                <Input
                  value={filter.search}
                  onChange={onChangeSearch}
                  placeholder="Search Number"
                  type="text"
                  style={{paddingLeft: "40px"}}
                />
              </Col>
              <Col
                sm="12"
                lg="3"
                className=" mt-4 mb-3 mb-lg-0 mt-lg-0"
                style={{
                  display: "flex",
                  alignItems: "center",
                  // paddingLeft: "2rem",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    // marginRight: "2rem",
                    width: "100%",
                  }}
                >
                  <DateRangePicker
                    initialSettings={{
                      ranges: {
                        Today: [moment(), moment()],
                        Yesterday: [
                          moment().subtract(1, "days"),
                          moment().subtract(1, "days"),
                        ],
                        "Last 7 Days": [moment().subtract(6, "days"), moment()],
                        "Last 30 Days": [
                          moment().subtract(29, "days"),
                          moment(),
                        ],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        "Last Month": [
                          moment().subtract(1, "month").startOf("month"),
                          moment().subtract(1, "month").endOf("month"),
                        ],
                      },
                      startDate: new Date(),
                      endDate: new Date(),
                    }}
                    onEvent={onChangeDate}
                  >
                 
                    <input
                      type="text"
                      className="form-control"
                      style={{width: "100%"}}
                    />
                  </DateRangePicker>
                </div>
              </Col> */}
              <Col sm="12" lg="3" className="mt-2 mt-lg-0">
                <div style={{position: "relative"}}>
                  {" "}
                  <DescriptionOutlinedIcon
                    style={{
                      position: "absolute",
                      top: "7px",
                      left: "10px",
                    }}
                  />
                  <Input
                    placeholder="Inbound/Outbound"
                    onChange={onChangeSelect}
                    value={direction}
                    type="select"
                    style={{paddingLeft: "40px"}}
                  >
                    <option value="all">Combined</option>
                    <option value="inbound">Inbound</option>
                    <option value="outbound">Outbound</option>
                    <option value="distribution">Distribution</option>
                  </Input>
                </div>
              </Col>
              <Col sm="12" lg="3" className="mt-2 mt-lg-0 offset-6 text-right">
                <Button onClick={resetAll} color="primary">
                  Back
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
