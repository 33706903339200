import React, { useEffect, useState } from "react";
import {
  Container,
  Table,
  CustomInput,
  Row,
  Col,
  Button,
  Spinner,
  Card,
  CardBody,
} from "reactstrap";

import { useHistory } from "react-router-dom";

import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import styled from "styled-components";
import toastr from "toastr";

import NavbarTitle from "../NavbarTitle/NavbarTitle";
import petition_get from "../../../../petitions/petition_get";
import petition_delete from "../../../../petitions/petition_delete";

import ButtonHover from "../../../../../components/utils/ButtonHover";

//Modal
import ModalUtility from "../ModalUtility/ModalUtility";

import Deleted from "../../../../../components/utils/Deleted";

const ContainerPagination = styled.div`
  padding: 20px;
  margin: 0;
  .btn-page {
    border: none;
    background: rgba(255, 255, 255, 0.1);
    color: #a6b0cf;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    margin: 0 5px;
    .active {
      background: #536be1;
    }
    svg {
      color: #a6b0cf;
    }
    &:focus {
      outline: none;
    }
  }
  .active {
    background: #536be1;
  }
`;
const Pages = ({ data, setPagination, pagination, cantPagination }) => {
  let page = [];

  for (let i = 0; i < Math.trunc(data); i++) {
    page.push(i);
  }

  const onClickPage = (index) => {
    index = (index + 1) * cantPagination;
    setPagination(index);
  };

  return (
    <>
      {page.map((pag, index) => {
        if (
          (index + 1 >= pagination / cantPagination - 2 &&
            index + 1 <= pagination / cantPagination) ||
          (index + 1 <= pagination / cantPagination + 2 &&
            index + 1 >= pagination / cantPagination)
        ) {
          return (
            <button
              className={`btn-page ${pagination / cantPagination === index + 1 && "active"
                } `}
              onClick={() => {
                onClickPage(index);
              }}
              key={index}
            >
              {" "}
              {index + 1}{" "}
            </button>
          );
        }
      })}
    </>
  );
};

export default function ListAllUsers({
  data,
  setData,
  allData,
  setAllData,
  loading,
  setLoading,
  editUser,
  setEditUser,
  setInfoUser,
  setDeleteUser,
}) {
  toastr.options = {
    showDuration: 300,
  };
  const history = useHistory();
  const params = new URL(document.location).searchParams;
  const id = params.get("id");


  //   const [data, setData] = useState(null);
  //   const [allData, setAllData] = useState(null);
  const cantPagination = 10;
  const [pagination, setPagination] = useState(cantPagination);
  const [modal, setModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [message, setMessage] = useState("");
  const [searchFilter, setSearchFilter] = useState("");

  function search(val) {
    setSearchFilter(val);
  }

  const onClickNext = () => {
    if (pagination < data.length) {
      setPagination(pagination + cantPagination);
    }
  };

  const onClickBack = () => {
    if (pagination > cantPagination) {
      setPagination(pagination - cantPagination);
    }
  };

  const deleteUser = () => {
    setSpinner(true);
    petition_delete("deleteUser", { api_id: id, user_id: modal })
      .then(({ data: result }) => {
        const newData = data.filter((user) => user.id !== modal);
        setDeleteUser(true);
        setData(newData);
        setModal(false);
        toastr.success("Action completed", "Success");
        setSpinner(false);
      })
      .catch((error) => {
        console.log(error.response);
        toastr.error("Action failed", "Error");
        setMessage("error");
        setSpinner(false);
      });
  };

  return (
    <>
      <ModalUtility
        setModal={setModal}
        modal={modal}
        deleteUser={deleteUser}
        message={message}
        spinner={spinner}
      />

      {/*   <Card>
        <CardBody style={{ paddingBottom: "1rem" }}>
          {" "}
          <NavbarTitle />
          <Table className="table mb-0 mt-4" hover>
            <thead>
              <tr>
                <th style={{ borderBottom: "none" }}>
                  <Row style={{ padding: "25px 25px 15px 15px" }}>
                    <Col
                      style={{ display: "flex", alignItems: "center" }}
                      sm="6"
                      md="3"
                    >
                      Main:
                      <span
                        style={{ color: "#536be1", paddingLeft: "20px" }}
                        className="primary-color"
                      >
                        (305) 428-2515
                      </span>
                    </Col>

                    <Col
                      style={{ display: "flex", alignItems: "center" }}
                      sm="5"
                      md="3"
                    >
                      Fax:{" "}
                      <span style={{ color: "#536be1", paddingLeft: "20px" }}>
                        (305) 428-2515
                      </span>
                    </Col>

                    <Col
                      className="text-right pt-md-0 pt-5"
                      md={{ size: "4", offset: "2" }}
                      sm="12"
                    >
                      <input
                        id="search"
                        type="text"
                        className="form-control"
                        placeholder={"Search Numbers"}
                        onChange={(e) => {
                          search(e.target.value);
                        }}
                        value={searchFilter}
                      />
                      <label
                        style={{
                          position: "absolute",
                          bottom: "-2px",
                          right: "15px",
                        }}
                        htmlFor="search"
                      >
                        <SearchIcon />
                      </label>
                    </Col>
                  </Row>
                </th>
              </tr>
            </thead>
          </Table>
        </CardBody>
      </Card> */}

      {loading ? (
        <Card>
          <CardBody>

            <Row className="mt-3 mb-4">



              <Col style={{ positio: "relative" }} className="col-12   col-md-6 d-flex align-items-center">
                <input
                  id="search"
                  type="text"
                  className="form-control"
                  placeholder={"Search Numbers"}
                  onChange={(e) => {
                    search(e.target.value);
                  }}
                  value={searchFilter}
                />
                <label

                  className="icon-search"
                  style={{ bottom: "0px" }}
                  htmlFor="search"
                >

                  <SearchIcon />
                </label>
              </Col>

              {/*  <Col
                className="text-right mt-3 mt-md-0 col-12 col-md-6"
              >
                <Button
                  color="primary"
                  onClick={() => {
                    history.push(`/AddUsers?id=${id}`)
                  }}
                >
                  <AddIcon className="icon-arrow" /> Add User
                </Button>
              </Col> */}
            </Row>

            {data && data.length !== 0 ? (
              <Table className="table mb-0" hover responsive>
                <thead className="thead-light">
                  <tr style={{ background: "#2a3042" }}>
                    <th>
                      <CustomInput
                        type="checkbox"
                        className="input-check"
                        name=""
                        id="exampleCustomCheckbox"
                      />
                    </th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    {/* <th style={{maxWidth:"5em"}}>PAYMENT STATUS</th> */}
                    <th>Email</th>
                    <th>Extension Number</th>
                    <th>Type</th>
                    <th style={{ textAlign: "center" }}>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {data
                    ? data.map((element, i) => {
                      if (
                        i <= pagination &&
                        i >= pagination - cantPagination &&
                        element.presence_id.includes(searchFilter)
                      ) {
                        return (
                          <tr key={i}>
                            <td>
                              <CustomInput
                                type="checkbox"
                                className="input-check"
                                name=""
                                id={"exampleCustomCheckbox" + i}
                              />
                            </td>
                            <td>{element.first_name}</td>
                            <td>{element.last_name}</td>
                            <td>{element.email}</td>
                            <td>{element.presence_id}</td>
                            <td>{element.priv_level}</td>
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <ButtonHover
                                style={{ margin: "0" }}
                                onClick={() => {
                                  console.log(element)
                                  setModal(element.id);
                                  setMessage(
                                    "Are you sure you wish to delete this User? All user information including Voicemails will be removed."
                                  );
                                }}
                              >
                                {" "}
                                <DeleteIcon />
                              </ButtonHover>
                              <ButtonHover
                                style={{ margin: "0" }}
                                onClick={() => {
                                  setEditUser(true);
                                  setInfoUser(element);
                                }}
                              >
                                <SearchIcon />
                              </ButtonHover>
                            </td>
                          </tr>
                        );
                      }
                    })
                    : null}
                </tbody>
              </Table>
            ) : (
              <p
                style={{
                  textAlign: "center",
                  fontSize: "2rem",
                  fontWeight: "200",
                  margin: "0",
                }}
              >
                {" "}
                You don't have users
              </p>
            )}

            <ContainerPagination>
              {data && data.length > cantPagination && (
                <div className="col-6">
                  <button className="btn-page" onClick={onClickBack}>
                    {" "}
                    <ArrowBackIosIcon />{" "}
                  </button>

                  <Pages
                    pagination={pagination}
                    setPagination={setPagination}
                    cantPagination
                    data={
                      (data.length / cantPagination) % 1 === 0
                        ? data.length / cantPagination
                        : data.length / cantPagination + 1
                    }
                  />
                  <button className="btn-page" onClick={onClickNext}>
                    {" "}
                    <ArrowForwardIosIcon />
                  </button>
                </div>
              )}

              <div className="col-6"></div>
            </ContainerPagination>
          </CardBody>
        </Card>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Spinner
            animation="grow"
            style={{
              margin: "10vh auto",
              height: "10vh",
              width: "10vh",
            }}
          />{" "}
        </div>
      )}
    </>
  );
}
