import React, { useEffect, useState } from 'react'
import petition_get from '../pages/petitions/petition_get'
export default function useContacts() {


    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    const [defaultUsers, setDefaultUsers] = useState(null);
    const [userContacts, setUserContacts] = useState(null);
    const [data, setData] = useState(null);

    const [selectedItem, setSelectedItem] = useState(null)



    useEffect(() => { getContacts() }, [])


    const reloadData = () => { getContacts() }


    const getContacts = () => {
        petition_get("contacts", { id })
            .then(({ data: result }) => {

                /*    setDefaultUsers(result.result.model_list.default_contacts);
                   setUserContacts(result.result.model_list.user_contacts); */




                const newDefault = [
                    ...result.result.model_list.default_contacts.map((element) => {
                        let cutNameSave = ''
                        element.name.split(" ").map(element => {
                            if (element.trim() !== "") cutNameSave = cutNameSave + element[0]
                        })
                        element.default = true;
                        element.cutName = cutNameSave
                        return element;
                    }),
                ];

                const newUserContacts = [...result.result.model_list.user_contacts.map(element => {
                    let cutNameSave = ''
                    element.name.split(" ").map(element => {
                        if (element.trim() !== "") cutNameSave = cutNameSave + element[0]
                    })
                    element.cutName = cutNameSave
                    return element;
                })]



                const newResults = [...newUserContacts, ...newDefault];
                if (newResults.length > 0) setSelectedItem(newResults[0])
                setData(newResults);
            })
            .catch((error) => { /* if (error.response.status === 404) setError404(true);  */ });
    }


    const onChangeSelectedItem = (item) => { setSelectedItem(item) }



    return { selectedItem, defaultUsers, userContacts, data, onChangeSelectedItem, reloadData }
}
