import React, { useState } from "react";
import verificationImg from "../../../../../assets/images/verification-img.png";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Badge,
} from "reactstrap";

import petition_delete from "../../../../petitions/petition_delete";
import toastr from "toastr";
export default function ModalUtility({
  modal,
  setModal,
  reloadData,
}) {
  toastr.options = {
    showDuration: 300,
  };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const deleteContact = () => {
    setError(false);
    setLoading(true);
    petition_delete("deleteContactList", { id: id, contact_id: modal })
      .then(({ data: result }) => {
        toastr.success("Action completed", "Success");
        reloadData(true)

        setLoading(false);
        setModal(false);
      })
      .catch((error) => {
        toastr.error("Action failed", "Error");
        setLoading(false);
        setError(true);
      });
  };

  return (
    <Modal centered isOpen={modal ? true : false} className="info">
      <ModalHeader className="bg-soft-primary">
        {" "}
        <Row>
          <Col xs="9">
            <div className="text-primary p-3">
              <h3 className="text-primary"> Delete Contact</h3>{" "}
              {error && (
                <Badge color="danger">Failed to delete contact. </Badge>
              )}
            </div>
          </Col>
          <Col xs="3" className="align-self-end">
            <img src={verificationImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <div className="text-center" style={{ fontSize: "20px" }}>
          Are you sure you want to delete this contact?
        </div>
      </ModalBody>{" "}
      <ModalFooter>
        <Button
          outline
          color="secondary"
          onClick={() => {
            setError(false);
            setModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          color="primary"
          onClick={() => {
            deleteContact();
          }}
        >
          {loading && (
            <Spinner
              style={{ marginRight: "0.5em" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
}
