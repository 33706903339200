import React, {Component} from "react";
import {Col, Row, Card, Input} from "reactstrap";
//Import Breadcrumb
//import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CurrencyFormat from "react-currency-format";

export default class NewCard extends Component {
  constructor(props) {
    super(props);
    this.date = new Date();
    this.year = this.date.getFullYear();
    this.years = [];
    this.enabledButtonAccept = this.props.enabledButtonAccept;
    this.diabledButtonAccept = this.props.diabledButtonAccept;
    this.saveValues = this.props.saveValues;
    this.types = ["amex", "visa", "mastercard", "discover"];
    for (let index = this.year; index <= this.year + 10; index++) {
      this.years.push(index);
    }
    this.state = {
      cardNumber: "",
      firstName: "",
      lastName: "",
      type: this.types[0],
      year: this.year + "",
      expiration: "1",
    };
  }
  change(val, nameInput) {
    if (
      this.state.cardNumber === "" ||
      this.state.firstName === "" ||
      this.state.lastName === "" ||
      this.state.type === "" ||
      this.state.year === "" + "" ||
      this.state.expiration === "" ||
      val === ""
    ) {
      this.diabledButtonAccept();
    } else {
      this.enabledButtonAccept();
      let data = {
        credit_card_info: {
          first_name: nameInput === "first_name" ? val : this.state.firstName,
          last_name: nameInput === "last_name" ? val : this.state.lastName,
          number: nameInput === "number" ? val : this.state.cardNumber,
          exp_month: nameInput === "exp_month" ? val : this.state.expiration,
          exp_year: nameInput === "exp_year" ? val : this.state.year,
          type: nameInput === "type" ? val : this.state.type,
        },
      };
      this.saveValues(data);
    }
  }
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col md="12">
            <Card body>
              <Row>
                <Col>
                  <div>
                    <label htmlFor="firstName">First Name</label>
                    <Input
                      id="firstName"
                      type="text"
                      onChange={(e) => {
                        this.setState({firstName: e.target.value});
                        this.change(e.target.value, "first_name");
                      }}
                    />
                  </div>
                </Col>
                <Col>
                  <div>
                    <label htmlFor="lastName">Last Name</label>
                    <Input
                      id="lastName"
                      type="text"
                      onChange={(e) => {
                        this.setState({lastName: e.target.value});
                        this.change(e.target.value, "last_name");
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <div>
                    <label htmlFor="cardNumber">Card Number</label>
                    <CurrencyFormat
                      className="form-control"
                      id="cardNumber"
                      format="#### #### #### ####"
                      mask="_"
                      onValueChange={(values) => {
                        const {formattedValue, value} = values;
                        this.setState({cardNumber: value});
                        this.change(value, "number");
                      }}
                    />
                  </div>
                </Col>
                <Col>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <div style={{width: "30%"}}>
                      <label htmlFor="expiration">Expiration</label>
                      <Input
                        type="select"
                        name="select"
                        id="expiration"
                        style={{width: "100%"}}
                        onChange={(e) => {
                          this.setState({expiration: e.target.value});
                          this.change(e.target.value, "exp_month");
                        }}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </Input>
                    </div>
                    <div style={{width: "30%"}}>
                      <label htmlFor="year">Year</label>
                      <Input
                        type="select"
                        name="select"
                        id="year"
                        onChange={(e) => {
                          this.setState({year: e.target.value});
                          this.change(e.target.value, "exp_year");
                        }}
                      >
                        {this.years.map((element, i) => (
                          <option value={element} key={i}>
                            {element}
                          </option>
                        ))}
                      </Input>
                    </div>
                    <div style={{width: "30%"}}>
                      <label htmlFor="type">Type</label>
                      <Input
                        type="select"
                        name="select"
                        id="type"
                        onChange={(e) => {
                          this.setState({type: e.target.value});
                          this.change(e.target.value, "type");
                        }}
                      >
                        {this.types.map((element, i) => (
                          <option value={element} key={i}>
                            {element}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
