import React, { useRef, useState, useEffect } from 'react';
import json from "../config.json"
export default function useCallRecord(urlBase, token) {

    const [time, setTime] = useState(0)
    const [playActive, setPlayActive] = useState(null)


    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    const getUrlVoiceAudio = (media_recordings) => {
        return `${urlBase}${id}/recordings/${media_recordings}?token=${token.access_token}&&accept=audio/mpeg`
    }


    const onClickButtonPlay = async (refAudio, id, item) => {
        if (!playActive) {
            refAudio.current.src = getUrlVoiceAudio(item.media_recordings ? item.media_recordings[0] : null)
            await refAudio.current.load()
            refAudio.current.addEventListener('durationchange', (e) => {
                if (refAudio.current.duration == Infinity) {
                    refAudio.current.currentTime = 1e101;
                    refAudio.current.ontimeupdate = function () {
                        this.ontimeupdate = () => {
                            return;
                        }
                        refAudio.current.currentTime = 0;
                        return;
                    }
                }
            });
            await refAudio.current.play();



        }
        else await refAudio.current.pause();

        setPlayActive(playActive ? null : id)
    }

    const onChangeTime = ({ e, item, refAudio }) => {




        setTime({ timeProgress: (e.target.currentTime * 100) / parseInt(refAudio.current.duration), timeSecond: new Date(e.target.currentTime * 1000).toISOString().substr(e.target.currentTime < 3600 ? 14 : 11, e.target.currentTime < 3600 ? 5 : 8) });
        if (refAudio.current && e.target.currentTime === refAudio.current.duration) {
            setTime({ timeProgress: 0, timeSecond: 0 });
            setPlayActive(false)

        }
    }


    return { getUrlVoiceAudio, playActive, time, onClickButtonPlay, onChangeTime }
}
