import React, {useState, useEffect} from "react";
import {CardOrderStyled} from "../Style/Style";
import {Table, Card, CardBody, Row} from "reactstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import ButtonHover from "../../../../../components/utils/ButtonHover";
export default function CardOrder({
  activeSelected,
  setActiveSelected,
  pricePerDid,
}) {
  const [cant, setCant] = useState(0);

  useEffect(() => {
    setCant(activeSelected.length);
  }, [activeSelected]);

  if (activeSelected.length === 0) return null;

  const onClickDelete = (number) => {
    const newSelectedNumbers = activeSelected.filter(
      (numberSelected) => numberSelected.number !== number
    );
    setActiveSelected(newSelectedNumbers);
  };
  return (
    <div style={{padding: "0 2rem", paddingBottom: "1rem", marginTop: "2rem"}}>
      <h3>Card</h3>

      <Row
        style={{
          height: "auto",
          margin: "0",
        }}
        className="form-control"
      >
        <Table responsive className="table mb-0">
          <thead>
            <tr>
              <th>Location</th>
              <th>Number</th>
              <th>Qty</th>
              <th className="text-center"> Action</th>
            </tr>
          </thead>
          <tbody>
            {activeSelected.length !== 0 &&
              activeSelected.map((number, i) => (
                <tr key={i}>
                  <th>
                    {" "}
                    {number.type !== "City/State" && `${number.type}: `}
                    {number.code}{" "}
                  </th>
                  <th>{number.number}</th>
                  <th>1</th>

                  <th>
                    <ButtonHover
                      onClick={() => {
                        onClickDelete(number.number);
                      }}
                      className="text-left"
                    >
                      <DeleteIcon />
                    </ButtonHover>
                  </th>
                </tr>
              ))}
          </tbody>
        </Table>
      </Row>

      <h3 style={{marginTop: "2rem"}}>Order Summary</h3>
      <Row
        style={{
          height: "auto",
          margin: "0",
        }}
        className="form-control"
      >
        <Table responsive>
          <thead>
            <tr>
              <th>
                <strong>Recurring Charges - Monthly</strong>{" "}
              </th>
              <th>
                <strong>Qty</strong>{" "}
              </th>
              <th>
                <strong>Subtotal*</strong>{" "}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th style={{fontWeight: "500"}}>
                Additional Local Numbers - Domestic
              </th>
              <th style={{fontWeight: "500"}}>{`${cant} x $${pricePerDid}`}</th>
              <th style={{fontWeight: "500"}}>{`$${(cant * pricePerDid).toFixed(
                2
              )}`}</th>
            </tr>

            <tr>
              <th style={{paddingTop: "3rem"}}>
                <strong>Estimated Total</strong>
              </th>
              <th></th>
              <th style={{paddingTop: "3rem"}}>
                {" "}
                <strong>{`$${(cant * pricePerDid).toFixed(2)}`}</strong>
              </th>
            </tr>
            <tr>
              <th style={{fontWeight: "500", borderTop: "none"}}>
                {" "}
                *This price dose not include calculations for prorated amounts,
                taxes, fees and/or shipping.
              </th>
            </tr>
          </tbody>
        </Table>
      </Row>
    </div>
  );
}
