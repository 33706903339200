import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import ModalUtility from "./ModalUtility/ModalUtility";
import { Container, Row, Col, Input } from "reactstrap";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//Import Petitions
import petition_get from "../../../pages/petitions/petition_get";
import petition_patch from "../../../pages/petitions/petition_patch";
import petition_post from "../../petitions/petition_post";
import petition_delete from "../../petitions/petition_delete";

import ContainerChat from "./ContainerChat/ContainerChat";
import ContainerCampaign from "./ContainerChat/ContainerCampaign";
import Sidebar from "./Sidebar/Sidebar";
import InfoUser from "./InfoUser/InfoUser";

//Import ChatDate
import { getChatDate } from "../../../components/utils/GetChatDate";

import rindMessage from "../../../assets/audio/ringmessage.mp3"

export default function Chat() {
  //Get Api_id
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const messageNumber = params.get("messageNumber");

  const rindingMessage = new Audio(rindMessage);

  //States
  const [chats, setChats] = useState([]);
  const [messages, setMessages] = useState([]);
  const [notification_Menu, setNotification_Menu] = useState(false);
  const [search_Menu, setSearch_Menu] = useState(false);
  const [other_Menu, setOther_Menu] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [Chat_Box_Username, setChat_Box_Username] = useState("");
  const [Chat_Box_User_Status, setChat_Box_User_Status] = useState("online");
  const [curMessage, setCurMessage] = useState("");
  const [keyNumbers, setKeyNumbers] = useState(null);
  const [loadingSend, setLoadingSend] = useState(false);
  const [loadingChat, setLoadingChat] = useState(false);
  const [allMessages, setAllMessages] = useState([]);
  const [threadId, setThreadId] = useState(null);
  const [numberFound, setNumberFound] = useState(false);
  const [modal, setModal] = useState(false);
  const [messageFound, setMessageFound] = useState(true);
  const [error404, setError404] = useState(false);
  const [formSearch, setFormSearch] = useState("");
  const [activeSearch, setActiveSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [imgMessages, setImgMessages] = useState([])
  const [reloadData, setReloadData] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingPetition, setLoadingPetition] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [selectedNumber, setSelectedNumber] = useState(null)
  const [contact, setContacts] = useState(null)
  const [chatContact, setChatContact] = useState(null)

  const [tabToggle, setTabToggle] = useState("1")

  const divRef = useRef();

  //Toggle Chat Box Menus

  const onChangeSearch = (e) => {
    setFormSearch(e.target.value);
  };
  const onChangeInputSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const onClickActive = (e) => {
    e.preventDefault();
    setActiveSearch(formSearch);
  };

  const toggleNotification = () => {
    setNotification_Menu(!notification_Menu);
  };
  const toggleSearch = () => {
    setSearch_Menu(!search_Menu);
  };

  const toggleOther = () => {
    setOther_Menu(!other_Menu);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  //Use For Chat Box
  const UserChatOpen = async (participants, chat, pk) => {

    if (chat) setChatContact(chat.name)
    else setChatContact(null)
    // if you are loading a chat before
    if (loadingChat || loadingSend) return;

    //get the chat number

    if (participants.length === 1) {
      setChat_Box_Username(participants[0].number);
    }
    else if (participants[0].is_self === false) {
      if (participants[0].number === Chat_Box_Username) return;
      setChat_Box_Username(participants[0].number);
    } else {
      if (participants[0].number === Chat_Box_Username) return;
      setChat_Box_Username(participants[1].number);
    }

    setLoadingChat(true);
    setMessages([]);
    let activePetition = true;

    //if the chat is in the list
    setThreadId(participants[0].message_thread_id);
    allMessages.map(async (element, i) => {

      if (element.thread === participants[0].message_thread_id) {
        element.messages.map((element) => {
          element.message_timestamp = getChatDate(element.message_timestamp);
        });
        setMessages(element.messages);
        setLoadingChat(false);
        setTimeout(() => {
          divRef.current.scrollTop = divRef.current.scrollHeight;
        }, 100);
        activePetition = false;
      }
    });

    //if the chat is not found in the list
    if (activePetition) {


      petition_get("getMessageThread", {
        id: id,
        thread: participants[0].message_thread_id,
        number: keyNumbers[0]
      })
        .then(({ data: result }) => {
          result.result.messages.map((element) => {
            element.message_timestamp = getChatDate(element.message_timestamp);
          });
          setMessages(result.result.messages.reverse());

          setLoadingChat(false);
          setTimeout(() => {
            divRef.current.scrollTop = divRef.current.scrollHeight;
          }, 10);
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.status === 404) {
            setError404(true)
          }
        });

      updateChatThreadRead(participants[0].message_thread_id)


    }
  };


  const updateChatThreadRead = (pk) => {


    let findOnePkChat = chats.filter(element => element.pk.toString() === pk)
    if (findOnePkChat && !findOnePkChat.thread_read) {

      petition_patch("updateReadChat", {
        id: id,
        thread: pk,
        number: keyNumbers[0],
        data: { thread_read: true }
      })
        .then(({ data: result }) => {

          let newChats = []
          chats.map(element => {

            if (element.pk.toString() === pk) element.thread_read = true
            newChats.push(element)
          })

          setChats(newChats)


        })
        .catch((error) => {
          console.log(error.response);
        });

    }
  }

  const deleteThree = (element) => {

    if (element.pk.toString() === threadId) {
      setChat_Box_Username("")
      setThreadId(null)
      setMessages([])
    }
    setLoadingDelete(true)
    petition_delete("deleteThreadSms", { id, smsId: element.pk, number: keyNumbers[0] })
      .then(({ data: result }) => {

        petition_get("listSms", { id, number: keyNumbers[0] })
          .then(({ data: result }) => {
            if (result.result.message_threads.length === 0) {
              setMessageFound(false);
            }
            result.result.message_threads.map((element) => {
              element.last_updated = getChatDate(element.last_updated);

              let findContact = []
              if (element.participants[0].is_self === false || element.participants.length === 1) {
                contact.model_list.default_contacts.map(elementTwo => { if (parseInt(elementTwo.internal_number) === parseInt(element.participants[0].number) || parseInt(elementTwo.external_number) === parseInt(element.participants[0].number)) findContact.push(elementTwo) })
                contact.model_list.user_contacts.map(elementTwo => { let findNumber = elementTwo.numbers.find(elementThree => parseInt(elementThree.number) === parseInt(element.participants[0].number)); console.log(findNumber); if (findNumber) findContact.push(elementTwo) })

              }
              else {
                contact.model_list.default_contacts.map(elementTwo => { if (parseInt(elementTwo.internal_number) === parseInt(element.participants[1].number) || parseInt(elementTwo.external_number) === parseInt(element.participants[1].number)) findContact.push(elementTwo) })
                contact.model_list.user_contacts.map(elementTwo => { let findNumber = elementTwo.numbers.find(elementThree => parseInt(elementThree.number) === parseInt(element.participants[1].number)); console.log(findNumber); if (findNumber) findContact.push(elementTwo) })

              }

              if (findContact.length >= 1) element.contact = { ...findContact[0] }
            });
            setLoadingDelete(false)
            setModalDelete(false)

            setChats(result.result.message_threads);
          })
          .catch((error) => {
            setLoadingDelete(false)
            setModalDelete(false)
            console.log(error.response);
          });

      })
      .catch((error) => {
        setLoadingDelete(false)
        setModalDelete(false)
        console.log(error.response);

      });

  }



  const addMessage = (images, setImages) => {
    //If no chat has been loaded
    if (Chat_Box_Username === "") return;
    setLoadingSend(true);


    let petitions = []

    images.map(element => {

      const formData = new FormData()

      formData.append("file", element.file)
      formData.append("body", "")
      formData.append("from_number", keyNumbers[0])
      formData.append("to_numbers_list", JSON.stringify({ list: [`${Chat_Box_Username}`] }))
      petitions.push(petition_post("sendSms", { id, data: formData, number: keyNumbers[0] }))

    })


    Promise.all(petitions)
      .then(result => {

        if (curMessage.trim() === "") {
          setImgMessages([])
          setLoadingSend(false);
          let demoMsg = messages;
          result.map(elementThree => {
            console.log(elementThree)
            demoMsg.push({
              message_mms_media: elementThree.data.result.message_mms_media,
              message_body: curMessage,
              message_participant: keyNumbers[0],
              message_participand_id: "2",
              pk: messages.length + 1,
              message_timestamp: "now",
              pending: true,
              message_provider_id: elementThree.data.result.message_provider_id
            });
          })

          setMessages(demoMsg);
          setCurMessage("");
          const newChats = chats.map((element) => {
            if (element.participants[0].message_thread_id === threadId) {
              element.last_message = curMessage;
              element.last_update = "now";
            }
            return element;
          });
          setChats(newChats);

          divRef.current.scrollTop = divRef.current.scrollHeight;
        }
        else {
          const formData = new FormData()


          formData.append("body", curMessage)
          formData.append("from_number", keyNumbers[0])
          formData.append("to_numbers_list", JSON.stringify({ list: [`${Chat_Box_Username}`] }))


          let demoMsg = messages;

          result.map(elementThree => {

            demoMsg.push({
              message_mms_media: elementThree.data.result.message_mms_media,
              message_body: "",
              message_participant: keyNumbers[0],
              message_participand_id: "2",
              pk: messages.length + 1,
              message_timestamp: "now",
              pending: true,
              message_provider_id: elementThree.data.result.message_provider_id
            });
          })


          petition_post("sendSms", { id: id, data: formData, number: keyNumbers[0] })
            .then(({ data: result }) => {

              console.log(result)

              setImgMessages([])
              setLoadingSend(false);

              demoMsg.push({
                message_body: curMessage,
                message_participant: keyNumbers[0],
                message_participand_id: "2",
                pk: messages.length + 1,
                message_timestamp: "now",
                pending: true,
                message_provider_id: result.result.message_provider_id

              });
              setMessages(demoMsg);
              setCurMessage("");
              const newChats = chats.map((element) => {
                if (element.participants[0].message_thread_id === threadId) {
                  element.last_message = curMessage;
                  element.last_update = "now";
                }
                return element;
              });
              setChats(newChats);



              petition_get("getMessageThread", {
                id: id,
                thread: threadId,
                number: keyNumbers[0]
              })
                .then(({ data: result }) => {
                  result.result.messages.map((element) => {
                    element.message_timestamp = getChatDate(element.message_timestamp);
                  });
                  setMessages(result.result.messages.reverse());

                  setLoadingChat(false);
                  setTimeout(() => {
                    divRef.current.scrollTop = divRef.current.scrollHeight;
                  }, 10);
                })
                .catch((error) => {
                  console.log(error.response);
                  if (error.response.status === 404) {
                    setError404(true)
                  }
                });



            })
            .catch((error) => {
              console.log(error.response);
              setLoadingSend(false);
            });
        }



      })
      .catch(err => {
        console.log(err);
        setLoadingSend(false);
      })

  };



  const deleteMessage = async (element) => {
    console.log(element)
    petition_delete("deleteMessageSms", { id, smsId: element.pk })
      .then(({ data: result }) => {

        let newMessages = []

        messages.map(elementTwo => {
          if (elementTwo.pk !== element.pk) {
            newMessages.push(elementTwo)
          }
        })

        setMessages(newMessages)



        divRef.current.scrollTop = divRef.current.scrollHeight;

      })
      .catch((error) => {
        console.log(error.response);

      });

  }


  const reloadList = (number) => {
    setMessageFound(true)

    petition_get("listSms", { id, number: number })
      .then(({ data: result }) => {
        if (result.result.message_threads.length === 0) {
          setMessageFound(false);
        }
        result.result.message_threads.map((element) => {
          element.last_updated = getChatDate(element.last_updated);
          let findContact = []
          if (element.participants[0].is_self === false || element.participants.length === 1) {
            contact.model_list.default_contacts.map(elementTwo => { if (parseInt(elementTwo.internal_number) === parseInt(element.participants[0].number) || parseInt(elementTwo.external_number) === parseInt(element.participants[0].number)) findContact.push(elementTwo) })
            contact.model_list.user_contacts.map(elementTwo => { let findNumber = elementTwo.numbers.find(elementThree => parseInt(elementThree.number) === parseInt(element.participants[0].number)); if (findNumber) findContact.push(elementTwo) })

          }
          else {
            contact.model_list.default_contacts.map(elementTwo => { if (parseInt(elementTwo.internal_number) === parseInt(element.participants[1].number) || parseInt(elementTwo.external_number) === parseInt(element.participants[1].number)) findContact.push(elementTwo) })
            contact.model_list.user_contacts.map(elementTwo => { let findNumber = elementTwo.numbers.find(elementThree => parseInt(elementThree.number) === parseInt(element.participants[1].number)); if (findNumber) findContact.push(elementTwo) })

          }

          if (findContact.length >= 1) element.contact = { ...findContact[0] }
        });
        setChats(result.result.message_threads);
        setMessages([])
        setChat_Box_Username("")
        setThreadId(null)
      })
      .catch((error) => {
        console.log(error.response);
      });

  }

  //UseEffect Petitions
  useEffect(() => {
    const petitions = async () => {
      let numbersSave = [];
      let contactSave = []
      await petition_get("viewNumbersChat", { id: id })
        .then(({ data: result }) => {

          if (result.result.itpvoice_metadata.sms_assigned_numbers.length === 0) {
            setKeyNumbers(["You don't have a number"]);
            return setNumberFound(true);
          }

          setSelectedNumber(result.result.itpvoice_metadata.sms_assigned_numbers[0])
          numbersSave = result.result.itpvoice_metadata.sms_assigned_numbers
          setKeyNumbers(result.result.itpvoice_metadata.sms_assigned_numbers);
        })
        .catch((error) => {
          console.log(error.response);
        });

      await petition_get("contacts", { id })
        .then(({ data: result }) => {

          setContacts(result.result)
          contactSave = result.result

        })
        .catch((error) => console.log(error))

      if (numbersSave[0]) {
        await petition_get("listSms", { id: id, number: numbersSave[0] })
          .then(({ data: result }) => {
            if (result.result.message_threads.length === 0) {
              setMessageFound(false);
            }

            result.result.message_threads.map((element) => {
              element.last_updated = getChatDate(element.last_updated);

              let findContact = []
              if (element.participants[0].is_self === false || element.participants.length === 1) {
                contactSave.model_list.default_contacts.map(elementTwo => { if (parseInt(elementTwo.internal_number) === parseInt(element.participants[0].number) || parseInt(elementTwo.external_number) === parseInt(element.participants[0].number)) findContact.push(elementTwo) })
                contactSave.model_list.user_contacts.map(elementTwo => { let findNumber = elementTwo.numbers.find(elementThree => parseInt(elementThree.number) === parseInt(element.participants[0].number)); if (findNumber) findContact.push(elementTwo) })

              }
              else {
                contactSave.model_list.default_contacts.map(elementTwo => { if (parseInt(elementTwo.internal_number) === parseInt(element.participants[1].number) || parseInt(elementTwo.external_number) === parseInt(element.participants[1].number)) findContact.push(elementTwo) })
                contactSave.model_list.user_contacts.map(elementTwo => { let findNumber = elementTwo.numbers.find(elementThree => parseInt(elementThree.number) === parseInt(element.participants[1].number)); if (findNumber) findContact.push(elementTwo) })

              }

              if (findContact.length >= 1) element.contact = { ...findContact[0] }

            });
            setChats(result.result.message_threads);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      setLoadingPetition(true)

    };

    petitions();
  }, [reloadData]);


  useEffect(() => {
    if (threadId) {
      allMessages.map((element, i) => {
        if (element.thread === threadId) {
          element.messages.map((elementTwo) => {
            elementTwo.message_timestamp = getChatDate(
              elementTwo.message_timestamp
            );
          });
          setMessages(element.messages);
        }
      });
    }
  }, [allMessages]);

  useEffect(() => {
    if (!threadId) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [messages]);

  const addChat = () => {
    setModal(!modal);
  };



  const [addMessageSocket, setAddMessageSocket] = useState(null)

  useEffect(() => {

    if (keyNumbers) {

      const token = JSON.parse(localStorage.getItem("authUser"));
      let socket = new WebSocket("wss://websockets.api.itp247.com/sms");

      socket.onmessage = (e) => {
        let result = JSON.parse(e.data)

        if (result.message_type === "sms" || result.message_type === "sms-status")
          setAddMessageSocket(JSON.parse(result.payload))
      }

      //Error
      socket.onerror = (e) => {
        console.log("error")
      }

      //Connect 
      socket.onopen = (e) => {

        socket.send(JSON.stringify({
          action: "login",
          payload: {
            jwt_token: token.access_token.replace(/['"]+/g, ""),
            account_id: id,
            phone_number: keyNumbers[0]
          }
        }))
      }
    }

  }, [keyNumbers])


  useEffect(() => {

    if (addMessageSocket) {


      const newChats = []
      let findPk = true
      let repeatSound = false; //Repeat sound play
      chats.map(element => {

        //Find if thread exists

        if (addMessageSocket.message_thread_pk === null || element.pk === addMessageSocket.message_thread_pk) findPk = false;


        //If you recive a message
        if (element.pk === addMessageSocket.message_thread_pk) {
          element["last_message"] = addMessageSocket.message
          element["last_updated"] = getChatDate(addMessageSocket.message_timestamp)
          if (addMessageSocket.from_number === Chat_Box_Username) {
            let newMessages = messages
            try {
              newMessages.push({
                message_mms_media: addMessageSocket.media_id,
                message_body: addMessageSocket.message,
                message_participant: Chat_Box_Username,
                message_status: addMessageSocket.message_status,
                message_participand_id: "1",
                pk: messages.length + 1,
                message_timestamp: "now",
              })
            } catch (error) {
              console.log(error)
            }
            if (!repeatSound) rindingMessage.play()
            repeatSound = true

            setMessages(newMessages)
            setTimeout(() => { divRef.current.scrollTop = divRef.current.scrollHeight; }, 10)
          }
        }


        //If you send a message
        if (keyNumbers[0] === addMessageSocket.from_number && addMessageSocket.from_number !== Chat_Box_Username && addMessageSocket.message_provider_id) {
          let newMessages = []
          let findMessage = true
          console.log(newMessages)
          messages.map(element => {
            if (addMessageSocket.message_provider_id === element.message_provider_id) findMessage = false;
            newMessages.push({ ...element, message_status: addMessageSocket.message_status, pending: addMessageSocket.message_provider_id === element.message_provider_id || !element.pending ? false : true })
          })

          if (findMessage && (addMessageSocket.from_number === Chat_Box_Username || (keyNumbers[0] === addMessageSocket.from_number && addMessageSocket.to_numbers_list[0] === Chat_Box_Username))) {
            newMessages.push({
              message_mms_media: addMessageSocket.media_id,
              message_body: addMessageSocket.message,
              message_participant: keyNumbers[0],
              message_status: addMessageSocket.message_status,
              message_participand_id: "2",
              pk: newMessages.length + 1,
              message_timestamp: "now",

            })
          }
          setMessages(newMessages)
          setTimeout(() => {
            divRef.current.scrollTop = divRef.current.scrollHeight;
          }, 10)


        }
        newChats.push(element)
      })



      //New Messages 
      if (findPk) {
        petition_get("listSms", { id: id, number: keyNumbers[0] })
          .then(({ data: result }) => {

            result.result.message_threads.map((element) => {
              element.last_updated = getChatDate(element.last_updated);

              let findContact = []
              if (element.participants[0].is_self === false || element.participants.length === 1) {
                contact.model_list.default_contacts.map(elementTwo => { if (parseInt(elementTwo.internal_number) === parseInt(element.participants[0].number) || parseInt(elementTwo.external_number) === parseInt(element.participants[0].number)) findContact.push(elementTwo) })
                contact.model_list.user_contacts.map(elementTwo => { let findNumber = elementTwo.numbers.find(elementThree => parseInt(elementThree.number) === parseInt(element.participants[0].number)); if (findNumber) findContact.push(elementTwo) })

              }
              else {
                contact.model_list.default_contacts.map(elementTwo => { if (parseInt(elementTwo.internal_number) === parseInt(element.participants[1].number) || parseInt(elementTwo.external_number) === parseInt(element.participants[1].number)) findContact.push(elementTwo) })
                contact.model_list.user_contacts.map(elementTwo => { let findNumber = elementTwo.numbers.find(elementThree => parseInt(elementThree.number) === parseInt(element.participants[1].number)); if (findNumber) findContact.push(elementTwo) })

              }

              if (findContact.length >= 1) element.contact = { ...findContact[0] }
            });
            setChats(result.result.message_threads);
            setThreadId(addMessageSocket.message_thread_pk.toString())
            setMessageFound(true);
            rindingMessage.play()
          })
          .catch((error) => console.log(error.response));
      }
      else setChats(newChats) //Save messages update

    }
  }, [addMessageSocket])






  const addThreeList = (threeNumber) => {


    let parseNumber = threeNumber
    let findOneChat = false
    chats.map(element => {

      if (element.participants.length === 1) {
        if (element.participants[0].number === parseNumber)
          findOneChat = element
      }
      else if (element.participants[0].number === parseNumber && element.participants[0].is_self === false)
        findOneChat = element

      else if (element.participants[1].number === parseNumber && element.participants[1].is_self === false)
        findOneChat = element
    })

    if (findOneChat) {

      UserChatOpen(findOneChat.participants)
    } else {
      setMessages([])
      setChat_Box_Username(parseNumber)
    }
    setModal(false)




  }

  useEffect(() => {
    if (messageNumber) {
      let parseNumber = `+${messageNumber}`

      let findOneChat = false
      chats.map(element => {

        if (element.participants.length === 1) {
          if (element.participants[0].number === parseNumber)
            findOneChat = element
        }
        else if (element.participants[0].number === parseNumber && element.participants[0].is_self === false)
          findOneChat = element

        else if (element.participants[1].number === parseNumber && element.participants[1].is_self === false)
          findOneChat = element
      })

      if (findOneChat) {

        UserChatOpen(findOneChat.participants)
      } else {
        setMessages([])
        setChat_Box_Username(parseNumber)
      }
    }
  }, [])

  return (
    <div>
      <React.Fragment>
        <ModalUtility
          loadingDelete={loadingDelete}
          modal={modalDelete}
          setModal={setModalDelete}
          deleteThree={deleteThree}
        />
        <div className="page-content px-0 px-lg-5">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs disabled={true} title="Skote" breadcrumbItem="Chat" />

            <Row>
              <Col lg="12">
                <div className="d-lg-flex">
                  <div className="chat-leftsidebar mr-lg-4">
                    <div className="">
                      <div className="py-4 border-bottom">
                        <InfoUser
                          keyNumbers={keyNumbers}
                          notification_Menu={notification_Menu}
                          toggleNotification={toggleNotification}
                        />
                      </div>

                      {!numberFound && <div className="search-box chat-search-box py-4">
                        <div className="position-relative">
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            onChange={onChangeInputSearch}
                            value={searchInput}
                          />
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>}


                      <div className="chat-leftsidebar-nav">
                        <Sidebar
                          contacts={contact}
                          tabToggle={tabToggle}
                          setTabToggle={setTabToggle}
                          setChats={setChats}
                          setChatContact={setChatContact}
                          setModalDelete={setModalDelete}
                          modal={modal}
                          searchInput={searchInput}
                          activeTab={activeTab}
                          toggleTab={toggleTab}
                          numberFound={numberFound}
                          keyNumbers={keyNumbers}
                          setKeyNumbers={setKeyNumbers}
                          addChat={addChat}
                          PerfectScrollbar={PerfectScrollbar}
                          chats={chats}
                          allMessages={allMessages}
                          messageFound={messageFound}
                          UserChatOpen={UserChatOpen}
                          addThreeList={addThreeList}
                          loadingPetition={loadingPetition}
                          selectedNumber={selectedNumber}
                          setSelectedNumber={setSelectedNumber}
                          reloadList={reloadList}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-100 user-chat">
                    {tabToggle === "1" && <ContainerChat
                      setChats={setChats}
                      chats={chats}
                      setChatContact={setChatContact}
                      chatContact={chatContact}
                      deleteMessage={deleteMessage}
                      imgMessages={imgMessages}
                      setImgMessages={setImgMessages}
                      Chat_Box_Username={Chat_Box_Username}
                      Chat_Box_User_Status={Chat_Box_User_Status}
                      search_Menu={search_Menu}
                      toggleSearch={toggleSearch}
                      toggleOther={toggleOther}
                      onChangeSearch={onChangeSearch}
                      formSearch={formSearch}
                      onClickActive={onClickActive}
                      other_Menu={other_Menu}
                      divRef={divRef}
                      setFormSearch={setFormSearch}
                      setActiveSearch={setActiveSearch}
                      activeSearch={activeSearch}
                      PerfectScrollbar={PerfectScrollbar}
                      messages={messages}
                      curMessage={curMessage}
                      setCurMessage={setCurMessage}
                      addMessage={addMessage}
                      loadingSend={loadingSend}
                      loadingChat={loadingChat}
                    />}
                    {tabToggle === "2" && <ContainerCampaign />}



                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </div>
  );
}
