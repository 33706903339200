import React from 'react'
import {Container} from "reactstrap"
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

export default function Alert404({name}) {
    return (
      <Container>
         <div
            className="d-flex"
            style={{alignItems: "center", paddingBottom: "2.5rem"}}
          >
            <h3 style={{margin: "0"}}>My Extensions</h3>{" "}
            <span>
              <DoubleArrowIcon className="icon-arrow" />
            </span>{" "}
            <h4
              style={{
                color: "#a6b0cf",
                fontWeight: "400",
                margin: "0",
                fontSize: "15px",
                paddingTop: "7px",
              }}
            >
              {name}
            </h4>
          </div>


             <div style={{height:"70vh" ,display: "flex", alignItems: "center", justifyContent: "center" }}>
        
          <span style={{fontSize:"1.1rem",textAlign:"center", padding:"0 5rem"}}>

        You Currently do not have a user assigned on the phone system. You likely are logged in as a user with Administrative access
          </span>
        </div>
        </Container>
    )
}
