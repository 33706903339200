
import MetisMenu from "metismenujs";
const initMenu = (props) => {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
        if (props.location.pathname === items[i].pathname) {
            matchingMenuItem = items[i];
            break;
        }
    }
    if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
    }
}


const activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
            parent2.classList.add("mm-show");

            const parent3 = parent2.parentElement;

            if (parent3) {
                parent3.classList.add("mm-active"); // li
                parent3.childNodes[0].classList.add("mm-active"); //a
                const parent4 = parent3.parentElement;
                if (parent4) {
                    parent4.classList.add("mm-active");
                }
            }
        }
        return false;
    }
    return false;
};


export default initMenu