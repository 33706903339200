import React, { useState, useEffect } from "react";
import { Button, CustomInput } from "reactstrap";
import { SidebarItem } from "../../../components/Index";
import { get } from "lodash";
import { formatTimeDelta } from "react-countdown";

export default function User({
  listUsers,
  setDetails,
  updateAction,
  state,
  startPoint,
  StartItemStyle,
  timeGroups,
  selectNode,
  dataCallFlow
}) {
  const [userSearch, setUserSearch] = useState("");
  const [activeItem, setActiveItem] = useState(false);
  const [activeForm, setActiveForm] = useState(false);
  const [form, setForm] = useState({ timeout: "20", checked: false, flow: "voicemail" });
  const [activeTimeGroups, setActiveTimeGroups] = useState(false)
  const [selectTimeGroup, setSelectTimeGroup] = useState(null)
  const [defaultValue, setDefaultValue] = useState(true)
  const [timeGroupsValues, setTimeGroupsValues] = useState([])


  const [activeMenu, setActiveMenu] = useState(false)
  const [menuOptions, setMenuOptions] = useState(false)
  const [selectMenu, setSelectMenu] = useState(null);

  const onChangeAutocompleteMenu = (e) => {
    setSelectMenu(e.target.value);
  };

  const onChangeAutocomplete = (e) => {
    setUserSearch(e.target.value);

    const getUser = e.target.value.split(" ");
    const findOne = listUsers.find(
      (element) => element.presence_id === getUser[getUser.length - 1]
    );
    if (findOne) setActiveForm(true);
  };
  const onChangeAutocompleteTimeGroup = (e) => {

    setSelectTimeGroup(e.target.value);

  };

  const changeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };


  useEffect(() => {

    if (selectNode && selectNode.type.split("_")[0] === "Time") {
      setDefaultValue(true)

      let values;
      if (selectNode.childrenUrl === "") values = dataCallFlow.flow.children;
      else {

        values = get(dataCallFlow.flow, selectNode.childrenUrl).children
      }
      console.log(values)
      const timeGroupsKeys = Object.keys(values)
      const newTimeGroups = []
      timeGroups.map(element => {
        const findOne = timeGroupsKeys.find(elementTwo => elementTwo === element.id)
        if (!findOne) {
          newTimeGroups.push(element)
        }
      })

      if (values._) {
        setDefaultValue(false)
        setSelectTimeGroup(newTimeGroups.length > 0 ? newTimeGroups[0].id : "")
      } else {
        setSelectTimeGroup("_")
      }
      setTimeGroupsValues(newTimeGroups)
      setActiveTimeGroups(false)
    }
    else if (selectNode && selectNode.type.split("_")[0] === "Menu") {
      let MenuOptions = [
        { id: "1" },
        { id: "2" },
        { id: "3" },
        { id: "4" },
        { id: "5" },
        { id: "6" },
        { id: "7" },
        { id: "8" },
        { id: "9" },
        { id: "0" },
        { id: "timeout" },
      ]
      let values;
      if (selectNode.childrenUrl === "") values = dataCallFlow.flow.children;
      else values = get(dataCallFlow.flow, selectNode.childrenUrl).children

      const MenuKeys = Object.keys(values)
      const newMenu = []
      MenuOptions.map(element => {
        const findOne = MenuKeys.find(elementTwo => elementTwo === element.id)
        if (!findOne) {
          newMenu.push(element)
        }
      })
      setSelectMenu(newMenu[0].id)
      setMenuOptions(newMenu)
      setActiveMenu(true);
      setActiveTimeGroups(true)
    }
    else {
      setActiveTimeGroups(true)
    }

  }, [])



  return (
    <>
      {activeTimeGroups ? <>


        {activeMenu ? <>
          <select
            onChange={onChangeAutocompleteMenu}
            className="form-control"
            list="datalistOptions"
            id="exampleDataList"
            placeholder="Type to search..."
          >

            {menuOptions.map((element, i) => (
              <option key={i} value={element.id}>
                {element.id}
              </option>
            ))}
          </select>
          {selectMenu && (
            <Button
              onClick={async () => {
                setActiveMenu(false);
              }}
              className=" mt-3 w-100"
              color="primary"
            >
              Create
            </Button>
          )}
        </> : <>
          <input
            onChange={onChangeAutocomplete}
            className="form-control"
            list="datalistOptions"
            id="exampleDataList"
            placeholder="Type to search..."
          />
          <datalist id="datalistOptions">
            {listUsers.map((element, i) => (
              <option
                key={i}
                value={`${element.first_name.trim()} ${element.last_name.trim()} ${element.presence_id.trim()}`}
              />
            ))}
          </datalist>

          {activeForm && (
            <div>
              <div className=" pt-3">
                <label
                  className="m-0 w-100 text-left"
                  htmlFor="flow"
                >
                  If the number is not available:
                </label>

                <select onChange={changeForm}
                  name="flow"
                  value={form.flow}
                  id="flow"
                  type="number"
                  className="form-control" >

                  <option value="voicemail">Go to User`s Voicemail</option>
                  <option value="custom">Custom option</option>
                </select>

              </div>
              <div className=" pt-3" >
                <label
                  className="m-0 w-100 text-left"
                  htmlFor="timeout"
                >
                  Ring Time:
                </label>
                <input
                  onChange={changeForm}
                  name="timeout"
                  value={form.timeout}
                  id="timeout"
                  type="number"
                  className="form-control"
                />
              </div>
              <div className="d-flex pt-3" style={{ alignItems: "center" }}>
                <label className="m-0 pr-2" htmlFor="exampleCustomCheck">
                  Allow call themselves:
                </label>
                <CustomInput
                  onChange={(e) => {
                    setForm({ ...form, checked: e.target.checked });
                  }}
                  type="checkbox"
                  value={form.checked}
                  className="input-check"
                  name=""
                  id="exampleCheck1"
                />
              </div>

            </div>
          )}

          {activeForm && (
            <Button
              onClick={async () => {
                const value = userSearch;
                const getUser = value.split(" ");
                const findOne = listUsers.find(
                  (element) => element.presence_id === getUser[getUser.length - 1]
                );
                setDetails({ ...findOne, ...form, timeGroups: selectTimeGroup, menuOption: selectMenu });
                setActiveItem(userSearch);
              }}
              className=" mt-3 w-100"
              color="primary"
            >
              Create
            </Button>
          )}

          {activeItem && (
            <SidebarItem
              updateAction={updateAction}
              state={state}
              label={`User: ${activeItem}`}
              type={`UserCallflow_${activeItem}`}
              ports={startPoint(`UserCallflow_${activeItem}`)}
              ItemStyle={StartItemStyle}
            />
          )}

        </>}


      </>
        : <>
          <select
            onChange={onChangeAutocompleteTimeGroup}
            className="form-control"
            list="datalistOptions"
            id="exampleDataList"
            placeholder="Type to search..."
          >
            {defaultValue && <option value="_">All Other Times</option>}
            {selectTimeGroup === "" && <option value="">You have no more options </option>}
            {timeGroupsValues.map((element, i) => (
              <option key={i} value={element.id}>
                {element.name}
              </option>
            ))}

          </select>

          {selectTimeGroup && (
            <Button
              disabled={selectTimeGroup === ""}
              onClick={async () => {
                setActiveTimeGroups(true)
              }}
              className=" mt-3 w-100"
              color="primary"
            >
              Create
            </Button>
          )}
        </>}

    </>

  );
}
