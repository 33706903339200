import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import CustomTable from "../../../../utils/CustomTable/CustomTable";
import DeleteIcon from "@material-ui/icons/Delete";
export default function CallFlowNumber({ setSelectOption, callFlowNumber, setCallFlowNumber, dataCallFlow, setDataCallFlow }) {

  const [form, setForm] = useState("")

  const deleteNumberCallFlow = (number) => {

    const newCallFlowNumber = callFlowNumber.filter(element => element.number !== number.number)

    setCallFlowNumber(newCallFlowNumber)
    setData({
      title: [{ title: "Number", key: "number" }],
      content: newCallFlowNumber,
      actions: {
        title: "Actions",
        content: [
          {
            icon: <DeleteIcon />, //Svg icon
            handleClick: deleteNumberCallFlow, //Function
          },
        ],
      },
    })

    let dataCallFlowCopy = dataCallFlow;
    let numbersCopy = []
    newCallFlowNumber.map(element => numbersCopy.push(element.number))
    dataCallFlowCopy.numbers = numbersCopy;
    console.log(dataCallFlowCopy)
    setDataCallFlow(dataCallFlowCopy)
    setInputCallFlow("")
  };



  const [inputCallFlow, setInputCallFlow] = useState("");
  const [data, setData] = useState({
    title: [{ title: "Number", value: "number" }],
    content: callFlowNumber,
    actions: {
      title: "Actions",
      content: [
        {
          icon: <DeleteIcon />, //Svg icon
          handleClick: deleteNumberCallFlow, //Function
        },
      ],
    },
  });

  const changeForm = (e) => {
    setInputCallFlow(e.target.value);
  };

  useEffect(() => {

    setForm(dataCallFlow.name)
    const newCallFlowNumber = [];
    dataCallFlow.numbers.map(element => newCallFlowNumber.push({ number: element }))

    setCallFlowNumber(newCallFlowNumber)
    setData({
      title: [{ title: "Number", key: "number" }],
      content: newCallFlowNumber,
      actions: {
        title: "Actions",
        content: [
          {
            icon: <DeleteIcon />, //Svg icon
            handleClick: deleteNumberCallFlow, //Function
          },
        ],
      },
    })

  }, [])




  const handleCreate = () => {
    let dataCallFlowCopy = dataCallFlow;
    if (inputCallFlow.trim() !== "") {
      let numbersCopy = []
      const newCallFlowNumber = callFlowNumber;
      newCallFlowNumber.push({ number: inputCallFlow });
      setData({
        title: [{ title: "Number", key: "number" }],
        content: newCallFlowNumber,
        actions: {
          title: "Actions",
          content: [
            {
              icon: <DeleteIcon />, //Svg icon
              handleClick: deleteNumberCallFlow, //Function
            },
          ],
        },
      })
      newCallFlowNumber.map(element => numbersCopy.push(element.number))
      dataCallFlowCopy.numbers = numbersCopy;
      setCallFlowNumber(newCallFlowNumber);
    }
    dataCallFlowCopy.name = form
    setDataCallFlow(dataCallFlowCopy)
    setSelectOption(null)

  };

  return (
    <>
      <p style={{ fontSize: "1rem", textAlign: "center" }}>Add Number </p>

      <div className="d-flex pt-3" style={{ alignItems: "center" }}>
        <label className="m-0 pr-2" style={{ width: "50%" }} htmlFor="name">
          Name:
        </label>
        <input
          onChange={(e) => {
            setForm(e.target.value)
          }}
          name="name"
          value={form}
          id="name"
          type="text"
          className="form-control"
        />
      </div>
      <div className="d-flex pt-3" style={{ alignItems: "center" }}>
        <label className="m-0 pr-2" style={{ width: "50%" }} htmlFor="timeout">
          Number:
        </label>
        <input
          onChange={changeForm}
          name="timeout"
          value={inputCallFlow}
          id="timeout"
          type="number"
          className="form-control"
        />
      </div>
      <Button
        onClick={() => {
          handleCreate();
        }}
        className=" mt-3 w-100"
        color="primary"
      >
        Save
      </Button>
      {data.content.length > 0 && <CustomTable bordered={true} data={data} />}
    </>
  );
}
