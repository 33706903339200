import React, {Component} from "react";
import {Container} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

export default class ITPVoice extends Component {
  componentWillUnmount() {
    // this.props.history.replace("./Voicemail")
    // window.location.reload(true);
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Utility" breadcrumbItem="ITPVoice" />
          </Container>
        </div>
        {/* {this.props.history.push("./Voicemail")} */}
      </React.Fragment>
    );
  }
}
