import React, { useState, useEffect } from 'react'
import { Row, Col, Spinner } from "reactstrap"
import Switch from "react-switch";
import petition_patch from "../../../../../../petitions/petition_patch"
import toastr from "toastr";
export default function CallRecording({ data, setData, user_id }) {

    toastr.options = {
        showDuration: 300,
    };

    const [switchTwo, setSwitchTwo] = useState(false)
    const [switchThree, setSwitchThree] = useState(false)
    const [loadingRecordCall, setLoadingRecordCall] = useState(false)
    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    const Offsymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 10,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                <p style={{ marginTop: "1.5em" }}>No</p>
            </div>
        );
    };
    const OnSymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 10,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                <p style={{ marginTop: "1.5em" }}>Yes</p>
            </div>
        );
    };

    useEffect(() => {

        console.log("Record", data)

        if (data && data.call_recording) {
            setSwitchTwo(data.call_recording.inbound.onnet.enabled)
            setSwitchThree(data.call_recording.inbound.offnet.enabled)
        }


    }, [data])



    const updateRecordCall = () => {


        let dataSubmit = [{ id, user_id }, { ...data, call_recording: { inbound: { offnet: { enabled: switchThree, record_on_answer: true, }, onnet: { enabled: switchTwo, record_on_answer: true, } }, outbound: { offnet: { record_on_answer: true, enabled: switchThree }, onnet: { record_on_answer: true, enabled: switchTwo } } } }]

        setLoadingRecordCall(true)
        petition_patch("editUser", dataSubmit)
            .then(({ data: result }) => {
                setLoadingRecordCall(false);
                setData(result.result)
                toastr.success("Action completed", "Success");
            })
            .catch((error) => {
                setLoadingRecordCall(false)

                toastr.error("Action failed", "Error");
                console.log(error.response);
            });
    }



    return (
        <Row>
            <Col className="col-12 pt-3">

                <label
                    htmlFor="time_window_start"
                    className="m-0 pr-4  w-100 mb-2"

                >
                    Record All Internal Calls

                </label>
                <Switch
                    uncheckedIcon={<Offsymbol />}
                    className="mr-1"
                    checkedIcon={<OnSymbol />}
                    onColor="#626ed4"
                    onChange={() => {
                        setSwitchTwo(!switchTwo);
                    }}
                    height={24}
                    width={48}
                    checked={switchTwo}
                />
            </Col>
            <Col className="col-12 pt-3">

                <label
                    htmlFor="time_window_start"
                    className="m-0 pr-4  w-100 mb-2"

                >
                    Record All External Calls

                </label>
                <Switch
                    uncheckedIcon={<Offsymbol />}
                    className="mr-1"
                    checkedIcon={<OnSymbol />}
                    onColor="#626ed4"
                    onChange={() => {
                        setSwitchThree(!switchThree);
                    }}
                    height={24}
                    width={48}
                    checked={switchThree}
                />
            </Col>
            <Col className="col-12  text-right mt-4">
                <button
                    className="btn btn-primary ml-4 pl-4 pr-4 font-size-16"
                    onClick={() => updateRecordCall()}
                    disabled={loadingRecordCall}
                >
                    {loadingRecordCall && (
                        <Spinner
                            style={{ marginRight: "0.5em" }}
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                    Save
                </button>
            </Col>

        </Row>
    )
}
