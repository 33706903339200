import React, {useState, useEffect} from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Badge,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
export default function ModalUtilityEdit({
  modal,
  setModal,
  loading,
  editNumber,
}) {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (modal) {
      const newData = Object.keys(modal);
      setData(newData);
    }
  }, [modal]);
  return (
    <Modal centered={true} isOpen={modal ? true : false}>
      <ModalHeader>
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary">Number Metadata</h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>

      <ModalBody>
        {" "}
        {data.map((element, i) => (
          <p key={i}>
            {element} : {modal[element]}
          </p>
        ))}{" "}
      </ModalBody>

      <ModalFooter>
        {" "}
        <Button
          disabled={loading}
          color="danger"
          onClick={() => {
            setModal(false);
          }}
        >
          Close
        </Button>
       {/*  <Button
          disabled={loading}
          color="primary"
          onClick={() => {
            editNumber();
          }}
        >
          {loading && (
            <Spinner
              style={{marginRight: "0.5em"}}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Save
        </Button>{" "} */}
      </ModalFooter>
    </Modal>
  );
}
