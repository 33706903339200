import React, { useState, useEffect } from "react";
import { Badge, Row, Col, Spinner } from "reactstrap";

import petition_post from "../../../../petitions/petition_post";
import petition_get from "../../../../petitions/petition_get";
import getTimeZoneList from "../../../../../components/utils/getTimezoneList"

import Select from 'react-select'

export default function Filter(props) {


  const [listTimeZones] = useState(getTimeZoneList())
  const [loading, setLoading] = useState(false);
  //Validate Email
  const [emailExist, setEmailExist] = useState(false);

  const [emailExistValid, setEmailExistValid] = useState(false);
  //Validate Extension
  const [extensionExist, setExtensionExist] = useState(false);
  //Form data
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    presence_id: "",
    email: "",
    priv_level: "selected",

  });

  useEffect(() => {
    if (form.presence_id === "" && props.recommedExtension) {
      console.log(props.recommedExtension)
      setForm({ ...form, presence_id: props.recommedExtension })
    }

  }, [props.recommedExtension])


  const onChangeForm = (e) => {

    console.log(e)
    setForm({ ...form, [e.target.name]: e.target.value });
    if (e.target.name === "email") {
      setEmailExist(false);
    }
    if (e.target.name === "presence_id") {
      setExtensionExist(false);
    }
  };

  const next = async () => {
    setLoading(true);


    const newForm = { ...form }

    if (newForm.timezone === "") delete newForm.timezone

    newForm.email = newForm.email.trim();

    let data = {
      data: {
        email: newForm.email,
        presence_id: newForm.presence_id,
      },
      id: props.id,
    };
    await petition_post("usersValidate", data)
      .then(({ data: result }) => {
        if (result.result.contact !== null) {
          if (
            result.result.contact.firstname !== newForm.first_name ||
            result.result.contact.lastname !== newForm.last_name
          ) {
            setForm({
              ...newForm,
              ["first_name"]: result.result.contact.firstname,
              ["last_name"]: result.result.contact.lastname,
            });
            setEmailExistValid(true);
          } else {
            props.setStep(2);
            props.setData({ ...props.data, userInfo: newForm });
          }
        } else {
          props.setStep(2);
          props.setData({ ...props.data, userInfo: newForm });
        }
      })
      .catch((error) => {
        console.log(error.response);

        if (
          error.response.data.error.error_message ===
          "Duplicate Email found when adding user."
        ) {
          setEmailExist(true);
        }

        if (
          error.response.data.error.error_message ===
          "Duplicate Presence ID found when adding user."
        ) {
          setExtensionExist(true);
        }
      });
    setLoading(false);
  };



  return (
    <>
      <br />
      <div style={{ margin: "0 2em" }}>
        <Row>
          <Col
            sm="12"
            md="12"
            lg="2"
            xl="2"
            className="mb-sm-2 mb-lg-0"
            className="mb-sm-2 mb-lg-0"
            style={{ display: "flex", alignItems: "center" }}
          >
            <label htmlFor="first_name" style={{ padding: "0", margin: "0" }}>
              First Name
            </label>
          </Col>
          <Col sm="12" md="6" lg="5" xl="6">
            <input
              onChange={onChangeForm}
              value={form.first_name}
              name="first_name"
              id="first_name"
              type="text"
              placeholder="First Name"
              className="form-control"
              disabled={emailExistValid}
            />
          </Col>
          <Col
            sm="3"
            className="mt-2 ml-0 ml-md-2 mt-md-0"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Badge
              color="info"
              style={{
                marginLeft: "2em",
                display: emailExistValid ? "initial" : "none",
              }}
            >
              A User with this email was found. First name and Last name was
              updated.
            </Badge>
          </Col>
        </Row>
        <br />
        <Row>
          <Col
            sm="12"
            md="12"
            lg="2"
            xl="2"
            className="mb-sm-2 mb-lg-0"
            style={{ display: "flex", alignItems: "center" }}
          >
            <label htmlFor="last_name" style={{ padding: "0", margin: "0" }}>
              Last Name
            </label>
          </Col>
          <Col sm="12" md="6" lg="5" xl="6">
            <input
              onChange={onChangeForm}
              value={form.last_name}
              name="last_name"
              id="last_name"
              type="text"
              placeholder="Last Name"
              className="form-control"
              disabled={emailExistValid}
            />
          </Col>
          <Col
            sm="3"
            className="mt-2 ml-0 ml-md-2 mt-md-0"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Badge
              color="info"
              style={{
                marginLeft: "2em",
                display: emailExistValid ? "initial" : "none",
              }}
            >
              A User with this email was found. First name and Last name was
              updated.
            </Badge>
          </Col>
        </Row>
        <br />
        <Row>
          <Col
            sm="12"
            md="12"
            lg="2"
            xl="2"
            className="mb-sm-2 mb-lg-0"
            style={{ display: "flex", alignItems: "center" }}
          >
            <label htmlFor="presence_id" style={{ padding: "0", margin: "0" }}>
              Extension Number
            </label>
          </Col>
          <Col
            sm="12"
            md="5"
            lg="4"
            xl="2"
            className="mb-sm-2 mb-lg-0"
            style={{ display: "flex", alignItems: "center" }}
          >
            <input
              onChange={onChangeForm}
              value={form.presence_id}
              name="presence_id"
              id="presence_id"
              type="number"
              placeholder="Extension Number"
              className="form-control input-extension"
            />
          </Col>

          <Col
            sm="3"
            className="mt-2 ml-0 ml-md-2 mt-md-0"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Badge
              color="danger"
              style={{
                // marginLeft: "2em",
                display: extensionExist ? "initial" : "none",
              }}
            >
              Extension Already In Use !
            </Badge>
          </Col>
        </Row>
        <br />
        <Row>
          <Col
            sm="12"
            md="12"
            lg="2"
            xl="2"
            className="mb-sm-2 mb-lg-0"
            style={{ display: "flex", alignItems: "center" }}
          >
            <label htmlFor="email" style={{ padding: "0", margin: "0" }}>
              Email
            </label>
          </Col>
          <Col sm="12" md="6" lg="5" xl="6">
            <input
              onChange={onChangeForm}
              value={form.email}
              name="email"
              id="email"
              type="text"
              placeholder="example@gmail.com"
              className="form-control"
            />
          </Col>
          <Col
            sm="3"
            className="mt-2 ml-0 ml-md-2 mt-md-0"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Badge
              color="danger"
              style={{
                marginLeft: "2em",
                display: emailExist ? "initial" : "none",
              }}
            >
              Email Already In Use !
            </Badge>
          </Col>
        </Row>
        <br />
        <Row>
          <Col
            sm="12"
            md="12"
            lg="2"
            xl="2"
            className="mb-sm-2 mb-lg-0"
            style={{ display: "flex", alignItems: "center" }}
          >
            <label htmlFor="select" style={{ padding: "0", margin: "0" }}>
              Time Zone
            </label>
          </Col>
          <Col sm="12" md="6" lg="5" xl="6">

            <Select
              styles={{
                control: (base, state) => ({
                  ...base,
                  background: "transparent"
                }),
                option: (base, state) => ({
                  ...base,
                  color: "rgba(0,0,0,.8)"
                }),
              }}
              options={listTimeZones}
              placeholder="Default"
              className="form-control control-select"
              onChange={(e) => { onChangeForm({ target: { name: "timezone", value: e.value } }) }}
            />


          </Col>
        </Row>
        <br />
        <Row>
          <Col
            sm="12"
            md="12"
            lg="2"
            xl="2"
            className="mb-sm-2 mb-lg-0"
            style={{ display: "flex", alignItems: "center" }}
          >
            <label htmlFor="select" style={{ padding: "0", margin: "0" }}>
              Type
            </label>
          </Col>
          <Col sm="12" md="6" lg="5" xl="6">
            <select
              onChange={onChangeForm}
              value={form.priv_level}
              name="priv_level"
              id="select"
              className="form-control"
            >
              <option value="selected">Select one...</option>
              <option value="admin">Admin</option>
              <option value="user">User</option>
            </select>
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="col-12  text-right mt-4">
            <button
              disabled={
                form.first_name === "" ||
                  form.last_name === "" ||
                  form.presence_id === "" ||
                  form.email === "" ||
                  form.priv_level === "selected"
                  ? true
                  : false || loading
              }
              onClick={() => next()}
              className="btn btn-primary ml-4 pl-4 pr-4 font-size-16"
            >
              {loading && (
                <Spinner
                  style={{ marginRight: "0.5em" }}
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}{" "}
              Next
            </button>
          </Col>{" "}
        </Row>
      </div>
    </>
  );
}
