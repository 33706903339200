import React from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Badge,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
export default function ModalUtilityDelete({
  modal,
  setModal,
  loading,
  deleteCallFlows,
}) {
  return (
    <Modal centered={true} isOpen={modal ? true : false}>
      <ModalHeader>
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary">Delete CallFlows</h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>

      <ModalBody>
        {" "}
        <p className="text-center" style={{fontSize: "1.2rem"}}>
          Are you sure you want to delete this callflow?{" "}
        </p>{" "}
      </ModalBody>

      <ModalFooter>
        {" "}
        <Button
          outline
          disabled={loading}
          color="secondary"
          onClick={() => {
            setModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          color="primary"
          onClick={() => {
            deleteCallFlows();
          }}
        >
          {loading && (
            <Spinner
              style={{marginRight: "0.5em"}}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Save
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}
