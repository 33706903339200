import React, { useState } from 'react'
import useGroup from "../../../../../hooks/useGroup"
import ModalUtilityAdd from "../ModalUtility/GroupModals/ModalUtility"
import ModalUtilityDelete from "../ModalUtility/GroupModals/ModalUtilityDelete"
import ModalUtilityUpdate from "../ModalUtility/GroupModals/ModalUtilityUpdate"
import CustomTable from "../../../../../components/utils/CustomTable/CustomTable"
import SidebarComponent from '../../../../../components/SidebarComponent/SidebarComponent'
import { Button } from "reactstrap"
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Loading from '../../../../../components/utils/Loading'
import GroupListSelected from "./GroupListSelected"
export default function GroupList({ contacts }) {

    const [modalAdd, setModalAdd] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);



    const { data, dataTable, loading, addGroups, deleteGroup, editGroups, selectedItem, onChangeSelectedItem } = useGroup({ setModalEdit, setModalAdd, setModalDelete })



    const DataItem = (({ item, index }) => {

        return (<>  <hr className="w-100" style={{ margin: "10px 0 0 0" }} />
            <div onClick={() => { if (!item.default) onChangeSelectedItem({ ...item, index }) }} className={`col-12 callhistory-item d-flex pt-2 ${selectedItem.pk === item.pk && "callhistory-item-active"}`}>
                <div className="d-flex align-items-center  mr-3 h-100">
                    <div className="rounded-circle avatar-sm bg-secondary d-flex justify-content-center align-items-center text-black" style={{ textTransform: "uppercase", color: "black", fontSize: "20px", fontWeight: "700" }} >
                        {item.cutName}
                    </div>
                </div>
                <div className="w-100 d-flex flex-column justify-content-center">
                    <div className="d-flex justify-content-between w-100">
                        <h5 className="text-truncate font-size-14 mb-0">
                            {item.name}
                        </h5>
                        <p className="text-truncate mb-0">

                        </p>
                    </div>
                    <p className="text-truncate mb-0">
                        {item.description}
                    </p>
                </div>
            </div>

        </>)
    })




    const GroupListContent = () => {

        return (<>
            < ModalUtilityAdd addGroups={addGroups} contacts={contacts} modal={modalAdd} setModal={setModalAdd} loading={loading} />
            {data ? <div className="row">
                {data.length > 0
                    ? <>
                        <div className="col-12 col-lg-5 ">
                            <div className="text-right mb-3">
                                <Button onClick={() => { setModalAdd(true); }} color="primary"  >
                                    <PersonAddIcon style={{ marginRight: "0.5rem", fontSize: "18px" }} />
                                    Add Group
                                </Button>
                            </div>
                            <SidebarComponent filterKeys={["name", "description"]} data={data} DataItem={DataItem} />
                        </div>
                        <div className="col-12 col-lg-7 pt-5 pt-lg-0 "> {selectedItem && <GroupListSelected setModalDelete={setModalDelete} editGroups={editGroups} contacts={contacts} modal={selectedItem} loading={loading} />} </div>  } </>
                    : <h3 className="text-center w-100">You do not have any group at the moment. Make some Contacts!</h3>
                }
            </div> : <Loading />}

        </>)

    }



    return (
        <div>
            < ModalUtilityDelete deleteGroup={deleteGroup} modal={modalDelete} setModal={setModalDelete} loading={loading} />
            {/* <CustomTable data={dataTable} /> */}

            <GroupListContent />
        </div>
    )
}
