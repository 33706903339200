import React, {useState, useEffect, useRef} from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";
import petition_get from "../../../../petitions/petition_get";
import petition_patch from "../../../../petitions/petition_patch";
import petition_post from "../../../../petitions/petition_post";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Badge,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import Switch from "react-switch";
export default function ModalUtility({modal, setModal, download, loading}) {
  return (
    <Modal centered={true} isOpen={modal ? true : false} className="info ">
      <ModalBody>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {!loading ? (
            <h3>Do you want to download this audio?</h3>
          ) : (
            <div className="text-center">
              {" "}
              <Spinner
                animation="grow"
                style={{
                  marginTop: "10vh",
                  marginBottom: "10vh",
                  height: "10vh",
                  width: "10vh",
                }}
              />
            </div>
          )}
          <div style={{margin: "0 auto", paddingTop: "3rem"}}>
            <Button
              style={{marginRight: "1rem"}}
              outline
              disabled={loading}
              color="secondary"
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={loading}
              color="primary"
              onClick={() => {
                download(modal);
                //setModal(false)
              }}
            >
              Download
            </Button>{" "}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
