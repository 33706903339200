import React, {useEffect, useState} from "react";
import {
  Container,
  Card,
  CardBody,
  Table,
  Button,
  Row,
  Col,
  Input,
  Badge,
} from "reactstrap";
import SendFax from "./SendFax/SendFax";
import SelectCoverPage from "./SelectCoverPqge/SelectCoverPqge";
import ButtonHover from "../../../../components/utils/ButtonHover";

//Icons
import SendIcon from "@material-ui/icons/Send";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import SearchIcon from "@material-ui/icons/Search";
import GetAppIcon from "@material-ui/icons/GetApp";
import petition_get from "../../../petitions/petition_get";

//Modal
import ModalUtility from "./ModalUtility/ModalUtility";

export default function Fax() {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState(null);

  const activeModal = (type) => {
    setModal(true);
    setModalType(type);
  };

  useEffect(() => {
    petition_get("fax", {id: id})
      .then(({data: result}) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  return (
    <React.Fragment>
      <ModalUtility modalType={modalType} setModal={setModal} modal={modal} />
      <div className="page-content">
        <Container fluid>
          <div
            className="d-flex"
            style={{alignItems: "center", paddingBottom: "2.5rem"}}
          >
            <h3 style={{margin: "0"}}>My Extensions</h3>
            <span>
              <DoubleArrowIcon className="icon-arrow" />
            </span>
            <h4
              style={{
                color: "#a6b0cf",
                fontWeight: "400",
                margin: "0",
                fontSize: "15px",
                paddingTop: "7px",
              }}
            >
              Fax
            </h4>
          </div>

          <Card>
            <CardBody>
              <Row>
                <Col sm="12" lg="3">
                  <SearchIcon
                    style={{position: "absolute", top: "7px", left: "20px"}}
                  />
                  <Input
                    placeholder="Search Number"
                    type="text"
                    style={{paddingLeft: "40px"}}
                  />
                </Col>
                <Col
                  sm="6"
                  lg="6"
                  className="justify-content-center mt-4 mt-lg-0 justify-content-lg-start"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "2rem",
                  }}
                >
                  <div style={{position: "relative", marginRight: "2rem"}}>
                    {" "}
                    <DateRangeIcon
                      style={{position: "absolute", top: "7px", left: "10px"}}
                    />
                    <Input
                      placeholder="Last 7 days"
                      type="text"
                      style={{paddingLeft: "40px"}}
                    />
                  </div>
                  <div style={{position: "relative"}}>
                    {" "}
                    <DescriptionOutlinedIcon
                      style={{position: "absolute", top: "7px", left: "10px"}}
                    />
                    <Input
                      placeholder="Inbound/Outbound"
                      type="text"
                      style={{paddingLeft: "40px"}}
                    />
                  </div>
                </Col>
                <Col
                  className="text-center  mt-4 mt-lg-0 text-lg-right  "
                  sm="6"
                  lg="3"
                >
                  <Button
                    onClick={() => {
                      activeModal("Send");
                    }}
                    color="primary"
                  >
                    <SendIcon
                      style={{marginRight: "0.5rem", fontSize: "18px"}}
                    />
                    Send Fax
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card body>
            <div className="table-responsive">
              <Table hover className="table mb-0">
                <thead className="thead-light">
                  <tr>
                    <th style={{textAlign: "center"}}>Direction </th>
                    <th style={{textAlign: "center"}}>Number </th>
                    <th style={{textAlign: "center"}}>Caller ID Name </th>
                    <th style={{textAlign: "center"}}>Pages</th>
                    <th style={{textAlign: "center"}}>Date / Time </th>
                    <th style={{textAlign: "center"}}>States </th>
                    <th style={{textAlign: "center"}}>Download </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <th style={{textAlign: "center"}}>Inbound </th>
                    <th style={{textAlign: "center"}}>3052990233 </th>
                    <th style={{textAlign: "center"}}>Caesar Engroba</th>
                    <th style={{textAlign: "center"}}>3</th>
                    <th style={{textAlign: "center"}}>
                      July 5th, 2020 8:34 PM{" "}
                    </th>
                    <th style={{textAlign: "center"}}>
                      <Badge style={{padding: "0.2rem 0.4rem"}} color="success">
                        {" "}
                        Success{" "}
                      </Badge>{" "}
                    </th>
                    <th style={{textAlign: "center"}}>
                                  <ButtonHover >
                                <GetAppIcon/>
                                </ButtonHover>
                    </th>
                  </tr>
                  <tr>
                    <th style={{textAlign: "center"}}>Inbound </th>
                    <th style={{textAlign: "center"}}>3052990233 </th>
                    <th style={{textAlign: "center"}}>Caesar Engroba</th>
                    <th style={{textAlign: "center"}}>3</th>
                    <th style={{textAlign: "center"}}>
                      July 5th, 2020 8:34 PM{" "}
                    </th>
                    <th style={{textAlign: "center"}}>
                      {" "}
                      <Badge style={{padding: "0.2rem 1rem"}} color="danger">
                        {" "}
                        Error{" "}
                      </Badge>{" "}
                    </th>
                    <th style={{textAlign: "center"}}>Unavailable</th>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Card>
        </Container>
      </div>

      {/* <div className="page-content">
        <Container fluid>
         
          <Breadcrumbs title="Utility" breadcrumbItem="Fax" />
          <Row>
            <Col xl="6">
              <SendFax />
            </Col>
            <Col xl="6">
              <SelectCoverPage />
            </Col>
          </Row>
        </Container>
      </div >  */}
    </React.Fragment>
  );
}
