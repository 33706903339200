import React, { useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
} from "reactstrap";
import Switch from "react-switch";
import { Link } from "react-router-dom";

import avatar1 from "../../../../../assets/images/users/avatar-1.jpg";
import profileImg from "../../../../../assets/images/profile-img.png";

export default function SendFax() {
  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  const [numbers, setNumbers] = useState(["123456", "123456", "123456"]);
  const [files, setFiles] = useState(["aaaaaa", "bbbbbbb", "ccccccccc"]);
  const [numberValue, setNumberValue] = useState("");
  const [switchOne, setSwitchOne] = useState(true);
  const [switchTwo, setSwitchTwo] = useState(true);

  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
  }, []);

  const addNumber = () => {
    setNumbers([...numbers, numberValue]);
    setNumberValue("");
  };

  const deleteNumber = (i) => {
    let tempNumbers = numbers.filter((num, index) => index !== i);
    setNumbers(tempNumbers);
  };

  const deleteFiles = (i) => {
    let tempFiles = files.filter((num, index) => index !== i);
    setFiles(tempFiles);
  };

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-soft-primary">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h3 className="text-primary">Send Fax</h3>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row className="mt-2">
            <Col xl="11">
              <FormGroup>
                <Label for="exampleEmail">To </Label>
                <Input
                  value={numberValue}
                  type="email"
                  name="to"
                  id="to"
                  placeholder=""
                  onChange={(e) => setNumberValue(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col
              xl="1"
              className="justify-content-center align-items-center"
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                paddingLeft: "0",
              }}
            >
              <i
                className="bx bx-plus-circle"
                style={{ fontSize: "2em" }}
                onClick={() => addNumber()}
              ></i>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <Table className="table mb-0">
                <tbody>
                  {numbers.map((element, i) => (
                    <tr key={i}>
                      <td colSpan="10">{element}</td>
                      <td colSpan="2" style={{ textAlign: "center" }}>
                        <i
                          className="bx bx-minus-circle"
                          style={{ fontSize: "1.5em" }}
                          onClick={() => deleteNumber(i)}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col xl="2">
              <Switch
                uncheckedIcon={<Offsymbol />}
                className="mr-1"
                checkedIcon={<OnSymbol />}
                onColor="#626ed4"
                onChange={() => {
                  setSwitchOne(!switchOne);
                }}
                checked={switchOne}
              />
            </Col>
            <Col xl="4" className="mt-1">
              <p>Use Cove Page</p>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Button color="primary">Select Cover Page</Button>
            </Col>
          </Row>
          <Row>
            <Col className="mt-4" xl="11">
              <FormGroup>
                <Input type="textarea" name="text" rows="6" id="exampleText" />
              </FormGroup>
            </Col>
          </Row>
          <p>
            Attach Files{" "}
            <i
              style={{ marginTop: "1em" }}
              className="bx bxs-info-circle"
              style={{ fontSize: "0.8em" }}
            ></i>{" "}
          </p>
          <Row className="mt-2">
            <Col>
              <Button color="primary">Browse</Button>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <Table className="table mb-0">
                <tbody>
                  {files.map((element, i) => (
                    <tr key={i}>
                      <td colSpan="10">{element}</td>
                      <td colSpan="2" style={{ textAlign: "center" }}>
                        <i
                          className="bx bx-minus-circle"
                          style={{ fontSize: "1.5em" }}
                          onClick={() => deleteFiles(i)}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col xl="2">
              <Switch
                uncheckedIcon={<Offsymbol />}
                className="mr-1"
                checkedIcon={<OnSymbol />}
                onColor="#626ed4"
                onChange={() => {
                  setSwitchTwo(!switchTwo);
                }}
                checked={switchTwo}
              />
            </Col>
            <Col xl="4" className="mt-1">
              <p>Use Cove Page</p>
            </Col>
          </Row>
          <Row>
            <Col xl="8">
              <FormGroup>  
                <Input
                  type="datetime-local"
                  name="datetime"
                  id="exampleDatetime"
                  placeholder="datetime placeholder"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col>
              <Row>
                <Col style={{paddingRight:"0px"}}>
                  <Button outline color="secondary" >Cancel</Button>
                </Col>
                <Col style={{paddingLeft:"0px"}}>
                  <Button color="primary">Send</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
