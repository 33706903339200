import React, {useEffect, useState} from "react";
import {
  Container,
  Card,
  Row,
  Col,
  CardTitle,
  Button,
  Table,
  FormGroup,
  Input,
  Label,
  CardBody,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//Icons
import PhoneIcon from "@material-ui/icons/Phone";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";

import petition_get from "../../petitions/petition_get";

export default function AdminOperatorPanel() {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const [selector, setSelector] = useState(1);
  const [myDevices, setMyDevices] = useState([]);
  const [accountDetails, setAccountDetails] = useState([]);

  useEffect(() => {
    /* petition_get("MyDevices", {id: id})
      .then(({data: result}) => {
        setMyDevices(result.result);
      })
      .catch((error) => {
        console.log(error);
      });
    petition_get("accountDetails", {id: id})
      .then(({data: result}) => {
        setAccountDetails(result.result);
      })
      .catch((error) => {
        console.log(error);
      }); */
  }, []);

  const onClickCard = (type) => {
    setSelector(type);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Utility" breadcrumbItem="Settings And Devices" />
          <Row>
            {/* /////////////////////////// duwan */}

            <Col md={3}>
              <Card
                onClick={() => {
                  onClickCard(1);
                }}
                body
                className={`${selector === 1 && "bg-primary"}`}
                style={{cursor: "pointer"}}
              >
                {" "}
                <PersonOutlineOutlinedIcon
                  style={{
                    margin: "0 auto",
                    fontSize: "4rem",
                    color: `${selector === 1 ? "white" : "inherit"}`,
                  }}
                />
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "1rem",
                    color: `${selector === 1 ? "white" : "inherit"}`,
                  }}
                >
                  One
                </p>
              </Card>
              <Card
                style={{cursor: "pointer"}}
                body
                onClick={() => {
                  onClickCard(2);
                }}
                className={`${selector === 2 && "bg-primary"}`}
              >
                <PhoneIcon
                  style={{
                    margin: "0 auto",
                    fontSize: "4rem",
                    color: `${selector === 2 ? "white" : "inherit"}`,
                  }}
                />
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "1rem",
                    color: `${selector === 2 ? "white" : "inherit"}`,
                  }}
                >
                 Two
                </p>
              </Card>
              <Card
                style={{cursor: "pointer"}}
                body
                onClick={() => {
                  onClickCard(3);
                }}
                className={`${selector === 3 && "bg-primary"}`}
              >
                {" "}
                {/* <CheckCircleOutlineOutlinedIcon
                  style={{
                    margin: "0 auto",
                    fontSize: "4rem",
                    color: `${selector === 3 ? "white" : "inherit"}`,
                  }}
                /> */}
                <i
                  className="fas fa-fax"
                  style={{
                    margin: "0 auto",
                    fontSize: "4rem",
                    color: `${selector === 3 ? "white" : "inherit"}`,
                  }}
                ></i>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "1rem",
                    color: `${selector === 3 ? "white" : "inherit"}`,
                  }}
                >
                  Three
                </p>
              </Card>
            </Col>
            {/* /////////////////////////////////// Diego */}
            <Col md={9}>

                          <Card body>
              {selector === 1 && (
                <>
                  One
                </>
              )}
              {selector === 2 && (
                <>
                  two
                </>
              )}
              {selector === 3 && <> Three </>}       
                          </Card>
             
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
