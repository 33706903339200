import { UserAgent, Registerer } from "sip.js";
import {
  SIP,
  SIP_SUCCESS,
  USERAGENT_START,
  USERAGENT_END,
  DISCONNECTED_PHONE
} from "./actionsTypes";

const initialState = {
  userAgent: "user",
  result: false,
  active: false,
  disconnected: false,
};
const sipReducer = (state = initialState, Action) => {
  switch (Action.type) {
    case SIP:
      state = {
        ...state,
      };
      break;
    case SIP_SUCCESS:
      state = { ...state, userAgent: Action.payload.userAgent, result: Action.payload.result, disconnected: false, active: !state.active };

      break;
    case USERAGENT_START:
      state = { ...state };

      break;
    case USERAGENT_END:
      state = { ...state };

      break;
    case DISCONNECTED_PHONE:

      console.log("Sessions Reducer")
      state = { ...state, disconnected: true };

      break;

    default:
      break;
  }
  return state;
};

export default sipReducer;
