import React, { useState, useEffect, useRef } from 'react'
import CustomTable from "../../../../../components/utils/CustomTable/CustomTable"
import petition_post from "../../../../petitions/petition_post";
import petition_get from "../../../../petitions/petition_get";
import SelectCompleteReport from "../SelectCompleteReports"
import DescriptionIcon from "@material-ui/icons/Description";
import GetAppIcon from '@material-ui/icons/GetApp';
import ModalUtility from "../ModalUtility/ModalUtility";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from '@material-ui/icons/Pause';
import useCallRecord from "../../../../../hooks/useCallRecord"
import json from "../../../../../config.json"
export default function NumerReports({ timezone }) {

    const [dataTable, setDataTable] = useState(null)
    const [distribution, setDistribution] = useState("all")

    const [dataReport, setDataReport] = useState(null)


    const params = new URL(document.location).searchParams;
    const id = params.get("id");
    const [modal, setModal] = useState(false)


    const [urlBase] = useState(json.prod ? "https://api.itpscorp.com/portal/itpvoice/" : "https://api.itpscorp.com/dev/portal/itpvoice/")
    const [token] = useState(JSON.parse(localStorage.getItem("authUser")))

    const CallRecording = ({ item }) => {
        const refAudio = useRef()

        const { getUrlVoiceAudio, time, playActive, onClickButtonPlay, onChangeTime } = useCallRecord(urlBase, token, refAudio, item)


        if (item.media_recordings && item.media_recordings.length === 0) return "Not recorded"
        return (<><div className="d-flex align-items-center" style={{ width: "450px" }}>
            <audio
                className="d-none"
                src=""
                controls
                type="audio/mpeg"
                preload="true"
                ref={refAudio}
                onTimeUpdate={(e) => { onChangeTime({ e, item, refAudio }) }}
            />
            <div style={{ width: "10%" }}> <div onClick={() => { onClickButtonPlay(refAudio, item.id, item) }} className="bg-primary button-play mr-2  "> {playActive === item.id ? <PauseIcon style={{ color: "white" }} /> : <PlayArrowIcon style={{ color: "white" }} />}   </div></div>
            <div className="barRange">  <div style={{ width: `${time.timeProgress >= 100 || !time.timeProgress ? "0" : time.timeProgress}%` }} className="barRangePositionBackground">. </div>  </div>
            {refAudio.current && !isNaN(refAudio.current.duration) && <div className="containerTime"> {time.timeSecond || "00:00"} / {refAudio.current && !isNaN(refAudio.current.duration) ? new Date(refAudio.current.duration * 1000).toISOString().substr(refAudio.current.duration < 3600 ? 14 : 11, refAudio.current.duration < 3600 ? 5 : 8) : ""}  </div>}
        </div></>)
    }


    const downloadCall = async (value) => {

        return new Promise(async (resolve, reject) => {

            try {

                console.log(value)

                const token = JSON.parse(localStorage.getItem("authUser"));
                const urlBase = json.prod ? "https://api.itpscorp.com/portal/itpvoice/" : "https://api.itpscorp.com/dev/portal/itpvoice/"


                const downloadFile = async () =>
                    fetch(`${urlBase}${id}/recordings/${value.media_recordings[0]}?token=${token.access_token}&&accept=audio/mpeg`).then((res) => res.blob());
                const createURL = (blob) => URL.createObjectURL(blob);

                const blob = await downloadFile();
                const create = createURL(blob);

                let link = document.createElement("a");
                link.download = `${value.interaction_id}.mp3`
                link.href = create;
                link.style.display = "none";
                document.body.appendChild(link);
                link.click();

                resolve(true)
            } catch (error) {
                reject(error)
            }
        })
    }


    const formatData = (val) => {
        let time = new Date(val);
        let timeZoneTemp = time.toLocaleString('en-US', { timeZone: timezone })
        return timeZoneTemp
    }



    useEffect(() => {

        if (dataReport) {
            setDataTable(null)

            petition_get("completeCallReports", {
                id: id,
                data: {},
                query: `?start_datetime=${dataReport.timeframe.timeStart}&end_datetime=${dataReport.timeframe.timeEnd}`,
            })
                .then(({ data: res }) => {


                    let newDate = []


                    res.result.map(element => {
                        let fromNumber = element.caller_id_number || element.from.slice(0, element.from.lastIndexOf("@"))
                        let toNumber = element.callee_id_number || element.to.slice(0, element.to.lastIndexOf("@"))
                        let saveDurationCall = new Date((element.duration_seconds - element.billing_seconds) * 1000).toISOString().substr((element.duration_seconds - element.billing_seconds) < 3600 ? 14 : 11, (element.duration_seconds - element.billing_seconds) < 3600 ? 5 : 8)

                        let datetime = formatData(element.datetime)
                        newDate.push({ ...element, datetime, ringTime: element.duration_seconds - element.billing_seconds, fromNumber, toNumber, saveDurationCall })
                    })


                    setDataTable({

                        title: [
                            { title: "Date", key: "datetime" },
                            { title: "From", key: "fromNumber" },
                            { title: "To", key: "toNumber" },
                            { title: "Billable Time", key: "billing_seconds" },
                            { title: "Ring Time", key: "ringTime" },
                            { title: "Disposition", key: "disposition" },
                            { title: "Direction", key: "direction" },
                        ],
                        content: newDate,
                        actions: {
                            title: "Actions",
                            content: [
                                {
                                    component: (e) => { return (<CallRecording item={e} />) },
                                    type: "component",

                                },
                                {

                                    component: (e) => { if (e.media_recordings[0]) return (<div onClick={() => { downloadCall(e) }} className=" mx-2 h-100 d-flex align-items-center"><GetAppIcon /> </div>) },
                                    type: "component",
                                },
                            ],
                        },
                    })

                })
                .catch((error) => console.log(error));

        }
    }, [dataReport])

    return (
        <div>
            <ModalUtility setModal={setModal} modal={modal} />
            <SelectCompleteReport setDataReport={setDataReport} />
            {dataReport && <CustomTable data={dataTable} />}
        </div>
    )
}
