import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Button } from "reactstrap"
import CustomBreadCrum from "../../../../components/utils/CustomBreadCrum/CustomBreadCrum"
import CustomTable from "../../../../components/utils/CustomTable/CustomTable"
import ReactEcharts from 'echarts-for-react';
export default function CallCenterApp() {

    const [dataTable, setDataTable] = useState(null)
    const [dataQueue, setDataQueue] = useState(null)
    const [selectedData, setSelectedData] = useState(false)

    const [chart, setChart] = useState({

        toolbox: {
            show: false,
        },
        tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        legend: {
            orient: 'vertical',
            x: 'left',
            data: ['Laptop', 'Tablet', 'Mobile', 'Others', 'Desktop'],
            textStyle: {
                color: ['#74788d']
            }
        },
        color: ['#02a499', '#f8b425', '#ec4561', '#38a4f8', '#3c4ccf'],
        series: [
            {
                name: 'Total sales',
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    normal: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        show: true,
                        textStyle: {
                            fontSize: '30',
                            fontWeight: 'bold'
                        }
                    }
                },
                labelLine: {
                    normal: {
                        show: false
                    }
                },
                data: [
                    { value: 335, name: 'Laptop' },
                    { value: 310, name: 'Tablet' },
                    { value: 234, name: 'Mobile' },
                    { value: 135, name: 'Others' },
                    { value: 1548, name: 'Desktop' }
                ]
            }
        ]
    })



    const ViewDetails = () => <Button color="primary" >View Details</Button>

    useEffect(() => {

        setDataTable({
            title: [
                { title: "Queue Name", key: "name", },

                { title: "Calls Abandoned", key: "abandoned" },
                { title: "Calls Waiting", key: "waiting" },
                { title: "Calls Answered", key: "answered" },
            ],
            firstWidth: "30%",
            content: [{ name: "IT Support", abandoned: "15", waiting: "5", answered: "20" }, { name: "IT Sales", abandoned: "15", waiting: "5", answered: "20" }, { name: "IT Billing", abandoned: "15", waiting: "5", answered: "20" },],
            actions: {
                title: "View Queues Dashboard",
                content: [
                    {
                        icon: <ViewDetails />,   //Svg icon
                        handleClick: (e) => {
                            setSelectedData(e); setDataQueue({
                                title: [
                                    { title: "Date/Time", key: "time", },
                                    { title: "Event", key: "event" },
                                ],
                                content: [{ time: "July 12, 2021 8:34 PM", event: "Agent Caesar Engroba logged in", }, { time: "July 12, 2021 8:34 PM", event: "Agent Caesar Engroba logged in", }],
                            })
                        },  //Function
                    },

                ],
            },
        })




    }, [])

    return (
        <div className="page-content px-5">
            <CustomBreadCrum title="Apps" subtitle="Call Center App" />

            <Row >
                <Col className="col-12  col-xl-4 ">
                    <Card body className="p-3 rounded-0" style={{ height: "100%" }}>
                        <p className="card-subtitle mb-2"> <strong>Call Center Queues </strong> </p>

                        <div className="border-bottom mt-5 mb-3"></div>
                        <Row>
                            <Col className="col-12 col-md-7" >  <p className="card-subtitle"> <strong>Total Agents   </strong> </p> <span>Total Agents Assidned To Queues</span>  </Col>
                            <Col className="col-12 col-md-3" >


                            </Col>
                            <Col className="col-12 col-md-2 d-flex align-items-center justify-content-center" > 5</Col>
                        </Row>
                        <div className="border-bottom my-3"></div>
                        <Row>
                            <Col className="col-12 col-md-7" >  <p className="card-subtitle"> <strong>Logged Off   </strong> </p> <span>Agents Logged Off</span>  </Col>
                            <Col className="col-12 col-md-3" >


                            </Col>
                            <Col className="col-12 col-md-2 d-flex align-items-center justify-content-center" style={{ flexDirection: "column" }} > <span>Sales</span> <strong> 72% </strong>  </Col>
                        </Row>
                        <div className="border-bottom my-3"></div>
                        <Row>
                            <Col className="col-12 col-md-7" >  <p className="card-subtitle"> <strong>Paused  </strong> </p> <span>Agents Paused</span>  </Col>
                            <Col className="col-12 col-md-3" >


                            </Col>
                            <Col className="col-12 col-md-2 d-flex align-items-center justify-content-center" style={{ flexDirection: "column" }} > <span>Sales</span> <strong> 54% </strong>  </Col>

                        </Row>


                    </Card>
                </Col>

                <Col className="col-12  col-xl-4 " >
                    <Card body className="p-3 rounded-0" style={{ height: "100%" }} >
                        <p className="card-subtitle"> <strong>Call Dispositions </strong> </p>

                        <ReactEcharts style={{ height: "100%" }} option={chart} />

                    </Card>
                </Col>


                <Col className="col-12  col-xl-4">
                    <Card body className="p-3 rounded-0" style={{ height: "100%" }}>
                        <p className="card-subtitle"> <strong>Call Statistics </strong> </p>
                        <div className="border-bottom mt-5 mb-3"></div>
                        <Row>
                            <Col className="col-12 col-md-7" >  <p className="card-subtitle"> <strong>Average Wait  </strong> </p> <span>Average wait time callers experienced</span>  </Col>
                            <Col className="col-12 col-md-3" > Chart </Col>
                            <Col className="col-12 col-md-2 d-flex align-items-center justify-content-center" > 00:03:20</Col>
                        </Row>
                        <div className="border-bottom my-3"></div>
                        <Row>
                            <Col className="col-12 col-md-7" >  <p className="card-subtitle"> <strong>Average Duration   </strong> </p> <span>Average amount of time callers experienced</span>  </Col>
                            <Col className="col-12 col-md-3" > Chart </Col>
                            <Col className="col-12 col-md-2 d-flex align-items-center justify-content-center" > 00:04:34</Col>
                        </Row>
                        <div className="border-bottom my-3"></div>
                        <Row>
                            <Col className="col-12 col-md-7" >  <p className="card-subtitle"> <strong>Maximun Wait Time  </strong> </p> <span>Longest call that caller experienced</span>  </Col>
                            <Col className="col-12 col-md-3" > Chart </Col>
                            <Col className="col-12 col-md-2 d-flex align-items-center justify-content-center" > 00:18:32</Col>
                        </Row>

                    </Card></Col>

                <Col className="col-12 mt-5">
                    <Card body className="p-3 rounded-0">
                        <p className="card-subtitle"> <strong>Queues </strong> </p>
                        <CustomTable data={dataTable} />
                    </Card>
                </Col>

                {selectedData &&
                    <Col className="col-12 ">
                        <Card body className="rounded-0">
                            <p className="card-subtitle"> <strong> {selectedData.name} Queue </strong> </p>

                            <div className="d-flex my-3" style={{ paddingLeft: "18px" }}>
                                <Button color="secondary" style={{ borderRadius: "0" }} className="  rounded-left"  >Calls</Button>
                                <Button color="secondary " style={{ borderRadius: "0" }} className="">Agents</Button>
                                <Button color="primary" style={{ borderRadius: "0" }} className=" rounded-right">Events</Button>
                            </div>

                            <CustomTable data={dataQueue} />
                        </Card>
                    </Col>}
            </Row>

        </div>
    )
}
