import React, { useState, useEffect } from "react";
import verificationImg from "../../../../../../assets/images/verification-img.png";

import NavTabCustom from "../../../../../../components/utils/NavTabCustom";
import GroupDetails from "../../Groups/ModalUtilityComponents/GroupDetails"
import GroupContact from "../../Groups/ModalUtilityComponents/GroupContact"


import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,

} from "reactstrap";


export default function ModalUtility({
  modal,
  setModal,
  loading,
  contacts,
  editGroups

}) {

  const [form, setForm] = useState({})
  useEffect(() => { if (modal) setForm(modal) }, [modal])


  return (
    <Modal isOpen={modal} className="info">
      <ModalHeader className="bg-soft-primary">
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary"> Add Group</h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={verificationImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>

        {modal && <NavTabCustom options={[{ title: "Group Details", component: <GroupDetails form={form} setForm={setForm} /> }, { title: "Contacts", component: <GroupContact form={form} setForm={setForm} contacts={contacts} /> }]} />}


      </ModalBody>
      <ModalFooter>
        <Button outline disabled={loading} color="secondary" onClick={() => { setModal(false) }} >
          Cancel
        </Button>
        <Button disabled={loading} color="primary" onClick={() => { editGroups(form, modal); }}>
          {loading && (
            <Spinner
              style={{ marginRight: "0.5em" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
