import React from "react";
import {
  Container,
  Card,
  CardBody,
  Table,
  Button,
  Row,
  Col,
  Input,
  Badge,
} from "reactstrap";
import DateRangeIcon from "@material-ui/icons/DateRange";
import SearchIcon from "@material-ui/icons/Search";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import SendIcon from "@material-ui/icons/Send";
import CallSplitIcon from "@material-ui/icons/CallSplit";
export default function Tools({ setModalAdd }) {
  return (

    <Row className="mb-4">
      <Col sm="12" lg="4">
        <label
          htmlFor="search"
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "7px",
            left: "20px",
          }}
        >
          <SearchIcon />
        </label>
        <Input
          id="search"
          placeholder="Search Number"
          type="text"
          style={{ paddingLeft: "40px", borderRadius: "3rem" }}
        />
      </Col>

      <Col
        className="text-center  mt-4 mt-lg-0 text-lg-right  "
        sm="12"
        lg="8"
      >
        <Button
          onClick={() => {
            setModalAdd(true)
          }}
          color="primary"
        >
          <CallSplitIcon
            style={{ marginRight: "0.5rem", fontSize: "18px" }}
          />
          New CallFlows
        </Button>
      </Col>
    </Row>

  );
}
