import styled, {keyframes, css} from "styled-components";

const Styles = styled.div`
  .muuri {
    flex-wrap: wrap;
  }
  .muuri-item {
    z-index: 1;
  }
  .muuri-item-dragging {
    z-index: 3;
  }
  .muuri-item-releasing {
    z-index: 2;
  }
  .muuri-item-hidden {
    z-index: 0;
  }
  .muuri-item:hover {
    z-index: 4;
  }
  .dropdown: {
    opacity: 0.99;
  }
`;

export default Styles;
