export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const TOKEN_USER = "TOKEN_USER";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const API_ERROR = "LOGIN_API_ERROR";

export const CHANGE_ADMIN = "CHANGE_ADMIN";
export const CHANGE_INSTALLAPPS = "CHANGE_INSTALLAPPS";

