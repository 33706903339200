import React, { useState, useEffect } from 'react'
import Switch from "react-switch";
import { Row, Col, Button, Spinner } from "reactstrap";
import { Multiselect } from "multiselect-react-dropdown";
import toastr from "toastr";
import petition_patch from "../../../../../../petitions/petition_patch"
import petition_get from "../../../../../../petitions/petition_get"

export default function CallerIdSettings({ data, setData, user_id }) {
    toastr.options = {
        showDuration: 300,
    };

    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    const [form, setForm] = useState({
        switchOverride: false,
        callerIdNumber: "",
        callerIdName: ""
    })

    const [numbers, setNumbers] = useState([]);
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        if (data) {
            console.log(data)

            petition_get("view", { id: id })
                .then(({ data: result }) => { console.log(result.result); setNumbers(Object.keys(result.result.numbers)) })
                .catch((error) => console.log(error));

            setForm({
                ...form,
                callerIdName: data.caller_id.external?.name || "",
                callerIdNumber: data.caller_id.external?.number || "",
                switchOverride: data.caller_id.external ? true : false
            })
        }
    }, [data])


    const Offsymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                No
            </div>
        );
    };
    const OnSymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                Yes
            </div>
        );
    };


    const onChangeForm = (e) => {
        if (!form.switchOverride) setForm({ ...form, switchOverride: true })

        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const onChangeFormNumber = (e) => {

        if (!form.switchOverride) setForm({ ...form, switchOverride: true })
        setForm({ ...form, callerIdNumber: e[0] || "" })
    };


    const handleSubmit = () => {

        if (form.switchOverride && form.callerIdNumber === "") return toastr.error("Required Number Field", "Error");
        if (form.switchOverride && form.callerIdName === "") return toastr.error("Required Name Field", "Error");


        let newData = {}

        if (form.switchOverride) newData = { ...data, caller_id: { internal: data.caller_id.internal, external: { name: form.callerIdName, number: form.callerIdNumber } } }
        else newData = { ...data, caller_id: { internal: data.caller_id.internal } }

        delete newData.user_id


        let dataSubmit = [
            {
                id: id,
                user_id
            },
            {
                ...newData
            }
        ]

        setLoading(true)
        petition_patch("editUser", dataSubmit)
            .then(({ data: result }) => {
                setLoading(false);
                setData(result.result)
                toastr.success("Action completed", "Success");
            })
            .catch((error) => {
                setLoading(false)

                toastr.error("Action failed", "Error");
                console.log(error.response);
            });
    }

    return (
        <div>
            <p>If you  wish to use a different Caller ID for Outbound calls you may override the default Caller ID options below</p>

            <Row>
                <Col className="col-12 d-flex align-items-center">
                    <Switch
                        uncheckedIcon={<Offsymbol />}
                        className="mr-1"
                        checkedIcon={<OnSymbol />}
                        onColor="#626ed4"
                        onChange={() => { setForm({ ...form, switchOverride: !form.switchOverride }) }}
                        checked={form.switchOverride}
                    />
                    <p className="pl-2 m-0"> Override Default Caller ID Settings </p>

                </Col>

                {form.switchOverride && <>   <Col className="col-12 col-lg-3 d-lg-flex mt-3 align-items-center">
                    <label className="mr-2 w-lg-25 mb-0" htmlFor="callerName">Caller ID Name</label>
                </Col>
                    <Col className="col-12 col-lg-9 d-lg-flex mt-3 align-items-center">
                        <input
                            type="text"
                            className="form-control"
                            value={form.callerIdName}
                            onChange={onChangeForm}
                            name="callerIdName"
                        />
                    </Col>
                    <Col className="col-12 col-lg-3 d-lg-flex mt-3 align-items-center">
                        <label className="mr-2 w-lg-25 mb-0" htmlFor="callerName">Caller ID Number</label>
                    </Col>
                    <Col className="col-12 col-lg-9 d-lg-flex mt-3 align-items-center">
                        <Multiselect
                            options={numbers}
                            selectedValues={
                                form.callerIdNumber ? [form.callerIdNumber] : null
                            }
                            isObject={false}
                            onSelect={onChangeFormNumber}
                            onRemove={onChangeFormNumber}
                            selectionLimit={1}
                            placeholder="Start Typing..."
                        />
                    </Col></>}


                <Col className="col-12 text-right mt-3">

                    <Button color="primary" onClick={handleSubmit} disabled={loading} >

                        {loading && (
                            <Spinner
                                style={{ marginRight: "0.5em" }}
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}

                        Save

                    </Button>

                </Col>
            </Row>

        </div>
    )
}
