import styled from "styled-components";

export const Code = styled.pre`
  margin: 10px;
  padding: 10px;
  line-height: 1.4em;
  white-space: pre-wrap;
  font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console",
    "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono",
    "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier,
    monospace;
  background-color: #eff0f1;
  overflow: auto;
`;
