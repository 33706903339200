// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  HIDE_RIGHT_SIDEBAR,
  CHANGE_DASHBOARD_ACTIVE
} from "./actionTypes";

var layoutType =
  window.location.pathname === "/ITPVoice" ||
    window.location.pathname === "/Voicemail" ||
    window.location.pathname === "/CallHistory" ||
    window.location.pathname === "/Fax" ||
    window.location.pathname === "/ContactList" ||
    window.location.pathname === "/SettingsAndDevices" ||
    window.location.pathname === "/ListAllUsers" ||
    window.location.pathname === "/AddUsers" ||
    window.location.pathname === "/TimeSchedules" ||
    window.location.pathname === "/GeneralSettings" ||
    window.location.pathname === "/Callflows" ||
    window.location.pathname === "/MusicOnHold" ||
    window.location.pathname === "/Queues" ||
    window.location.pathname === "/RingGroups" ||
    window.location.pathname === "/DashboardUsers" ||
    window.location.pathname === "/DashboardITPVoiceLegacy"
    ? "vertical"
    : "horizontal";

const params = new URL(document.location).searchParams;
const menu = params.get("menu");

var INIT_STATE = {
  layoutType: "vertical",
  layoutWidth: "fluid",
  leftSideBarTheme: "dark",
  leftSideBarType: "default",
  topbarTheme: localStorage.getItem("theme") || "dark",
  isPreloader: true,
  showRightSidebar: false,
  isMobile: false,
  activeSidebarInDashboard: false,
};

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      };
    case CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload,
      };

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      };
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      };
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      };
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      };
    case TOGGLE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: !state.showRightSidebar,
      };
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: true,
      };
    case HIDE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: false,
      };
    case CHANGE_DASHBOARD_ACTIVE:

      return {
        ...state,
        activeSidebarInDashboard: action.payload,
      };
    default:
      return state;
  }
};

export default Layout;
