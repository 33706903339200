//ORDER NEW NUMBER

export const NEW_NUMBER = "NEW_NUMBER";

export const OLD_NUMBER = "OLD_NUMBER";

export const DID_PRICE = "DID_PRICE";
export const DID_PRICE_LEGACY = "DID_PRICE_LEGACY";

export const WEB_PHONE_OPTION = "WEB_PHONE_OPTION";
export const APP_PK = "APP_PK";
