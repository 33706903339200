import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, Row, Col, Spinner } from "reactstrap";

export default function ModalUtility({ modal, setModal, deleteMenu, spinner }) {
  return (
    <Modal
      style={{ marginTop: "10rem" }}
      isOpen={!modal ? false : true}
      className="info "
    >
      <ModalBody>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {!spinner && (
            <h3 className="text-center">
              Are you sure you wish to delete this Menu?
            </h3>
          )}
          {spinner && (
            <Spinner
              animation="grow"
              style={{
                marginTop: "10vh",
                marginBottom: "10vh",
                height: "10vh",
                width: "10vh",
              }}
            />
          )}
        </div>
        {!spinner && (
          <div style={{ textAlign: "center" }}>
            <Button
              style={{ margin: "1rem" }}
              outline
              color="secondary"
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </Button>

            <Button
              style={{ margin: "1rem" }}
              color="danger"
              onClick={() => {
                deleteMenu(modal);
              }}
            >
              Delete
            </Button>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
}
