import React from 'react'
import { Steps, Hints } from "intro.js-react";
import "intro.js/introjs.css";
export default function TutorialSteps({ priv_level, activeSteps, exitStep }) {
    let steps = [
        {
            title: "Welcome",
            intro: "Hello World! 👋",
        },
        {
            element: ".step-my-extension",
            intro: "Hello step",
        },
        {
            element: ".dashboardUsers",
            intro: "dashboardUsers",
        },
        {
            element: ".voicemail",
            intro: "voicemail",
        },
        {
            element: ".callhistory",
            intro: "callhistory",
        },
        {
            element: ".contactList",
            intro: "contactList",
        },
        {
            element: ".settingsAndDevices",
            intro: "settingsAndDevices",
        },
        {
            element: ".webPhone",
            intro: "webPhone",
        },
        {
            element: ".chat",
            intro: "Chat",
        },
        /* {
          element: ".help",
          intro: "help",
        }, */
    ];
    let stepsAdmin = [
        {
            title: "Welcome",
            intro: "Hello World! 👋",
        },
        {
            element: ".step-my-extension2",
            intro: "Hello step",
        },
        {
            element: ".dashboardUsers2",
            intro: "dashboardUsers",
        },
        {
            element: ".voicemail2",
            intro: "voicemail",
        },
        {
            element: ".callhistory2",
            intro: "callhistory",
        },
        {
            element: ".contactList2",
            intro: "contactList",
        },
        {
            element: ".settingsAndDevices2",
            intro: "settingsAndDevices",
        },
        {
            element: ".webPhone2",
            intro: "webPhone",
        },
        {
            element: ".chat2",
            intro: "Chat",
        },
        {
            element: ".help2",
            intro: "help",
        },
        {
            element: ".numbers2",
            intro: "Numbers",
        },
        {
            element: ".users2",
            intro: "Users",
        },

        {
            element: ".yourPhoneSystem2",
            intro: "Your Phone System",
        },

        {
            element: ".apps2",
            intro: "Apps",
        },

        {
            element: ".api2",
            intro: "Api",
        },

        {
            element: ".reports2",
            intro: "Reports",
        },
    ];
    return (
        <>
            {priv_level === false && priv_level !== null && (
                <Steps
                    onExit={exitStep}
                    enabled={activeSteps}
                    steps={steps}
                    initialStep={0}
                />
            )}
            {priv_level === true && (
                <Steps
                    onExit={exitStep}
                    enabled={activeSteps}
                    steps={stepsAdmin}
                    initialStep={0}
                />
            )}
        </>
    )
}
