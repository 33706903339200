import React, { useState, useEffect } from 'react';
import petition_get from '../pages/petitions/petition_get'
import petition_delete from '../pages/petitions/petition_delete'
import petition_post from '../pages/petitions/petition_post'
import petition_patch from '../pages/petitions/petition_patch'
import toastr from "toastr";
import SettingsIcon from '@material-ui/icons/Settings';
export default function useFeatureCode() {

    const [reloadData, setReloadData] = useState(true)
    const [dataTable, setDataTable] = useState(null)
    const [loading, setLoading] = useState(false)
    const [loadingModal, setLoadingModal] = useState(false)

    const [modal, setModal] = useState(false)

    const [dataFeatureCodes, setDataFeatureCodes] = useState(null)

    const [featuresCallflow, setFeaturesCallflow] = useState({ intercomCode: null, parkCode: null, parkPick: null })


    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    toastr.options = {
        showDuration: 300,
    };


    const handleSwitch = (e, dataFeatures, parkPick) => {

        let newData = []
        dataFeatures.map(element => newData.push({ ...element, switch: element.pk === e.pk ? !element.switch : element.switch }))

        setDataFeatureCodes(newData)
        setDataTable({
            title: [
                { title: "Feature Code", key: "feature_code" },
                { title: "Name", key: "name" },
                { title: "Description", key: "description" },
            ],
            content: newData,
            actions: {
                title: "Enabled",
                content: [
                    {
                        switch: true,
                        keySwitch: "switch",
                        handleClick: (e) => { handleSwitch(e, newData, parkPick) },
                    },
                    {

                        component: (e) => { if (e.icon && e.switch && parkPick?.length > 0) return (<div onClick={() => { setModal(e) }} style={{ cursor: "pointer" }} className=" mx-2 h-100 d-flex align-items-center"><SettingsIcon /> </div>) },
                        type: "component",
                    },
                ],
            },
        })
    }

    useEffect(() => {
        if (reloadData) {
            petition_get("allCallflows", { id })
                .then(({ data: result }) => {

                    let findIntercomCode = result.result.filter(element => element.featurecode?.name === "intercom");
                    let findParkCode = result.result.filter(element => element.featurecode?.name === "park_call");
                    let findParkPickCode = result.result.filter(element => element.featurecode?.name === "park_pickup");

                    const dataFeatureCodesIntern = [

                        { pk: 0, switch: findIntercomCode.length > 0 ? true : false, feature_code: "*0", name: "Intercom", description: "Allows for calling an extension and automatically answering the call" },
                        { pk: 1, icon: true, switch: findParkCode.length > 0 ? true : false, feature_code: "*70", name: "Park Call", description: "Place a call in one of the available parking slots automatically" }

                    ]

                    setDataTable({
                        title: [
                            { title: "Feature Code", key: "feature_code" },
                            { title: "Name", key: "name" },
                            { title: "Description", key: "description" },
                        ],
                        content: dataFeatureCodesIntern,
                        actions: {
                            title: "Enabled",
                            content: [
                                {
                                    switch: true,
                                    keySwitch: "switch",
                                    handleClick: (e) => { handleSwitch(e, dataFeatureCodesIntern, findParkPickCode) },
                                },
                                {

                                    component: (e) => { if (e.icon && e.switch && findParkPickCode.length > 0) return (<div onClick={() => { setModal(e) }} style={{ cursor: "pointer" }} className=" mx-2 h-100 d-flex align-items-center"><SettingsIcon /> </div>) },
                                    type: "component",
                                },
                            ],
                        },
                    })

                    setFeaturesCallflow({ intercomCode: findIntercomCode, parkCode: findParkCode, parkPick: findParkPickCode })
                    setDataFeatureCodes(dataFeatureCodesIntern)
                    setReloadData(false);
                })
                .catch((error) => setReloadData(false));
        }
    }, [reloadData]);


    const onHandleSave = async () => {

        try {

            setLoading(true)

            if (featuresCallflow.intercomCode.length > 0 && !dataFeatureCodes[0].switch) await petition_delete("callFlow", { id, callflow_id: featuresCallflow.intercomCode[0].id }).catch(err => { throw err })
            else if (!featuresCallflow.intercomCode.length > 0 && dataFeatureCodes[0].switch) {

                await petition_post("callflow", {
                    id, data: {

                        type: "featurecode",
                        flow: {
                            children: {},
                            data: { action: "compose" },
                            module: "intercom"
                        },
                        featurecode: { name: "intercom", number: "0" },
                        patterns: ["^\\*0([0-9]*)$"],
                        numbers: [],
                        metadata: { itpvoice: { name: "intercom" } }
                    }
                })
                    .catch((error) => { throw error });

            }

            if (featuresCallflow.parkCode.length > 0 && !dataFeatureCodes[1].switch) {

                await petition_delete("callFlow", { id, callflow_id: featuresCallflow.parkCode[0].id }).catch(err => { throw err })
                await petition_delete("callFlow", { id, callflow_id: featuresCallflow.parkPick[0].id }).catch(err => { throw err })
            }
            else if (!featuresCallflow.parkCode.length > 0 && dataFeatureCodes[1].switch) {


                await petition_post("callflow", {
                    id, data: {
                        flow: {
                            children: {},
                            data: {
                                action: "retrieve",
                                custom_presence_id: false,
                                slots: {}
                            },
                            module: "park"
                        },
                        patterns: ["^\\*70([0-9]*)$"],
                        numbers: [],
                        featurecode: { name: "park_call", number: "70" },
                        metadata: {}
                    }
                })
                    .catch((err) => { throw err });

                await petition_post("callflow", {
                    id, data: {
                        flow: {
                            data: {
                                default_ringback_timeout: 120000,
                                default_callback_timeout: 30000,
                                action: "park",
                                custom_presence_id: false,
                                slots: {
                                    "71": {},
                                    "72": {},
                                    "73": {},
                                    "74": {},
                                    "75": {}
                                }
                            },
                            module: "park",
                            children: {}
                        },
                        numbers: ["*70"],
                        featurecode: { name: "park_pickup", number: "70" },
                        metadata: {}
                    }
                })
                    .catch((err) => { throw err });


            }
            toastr.success("Action completed", "Success");
            setLoading(false)
            setReloadData(true)


        } catch (error) {
            toastr.error("Action Error", "Error")
        }


    }


    const updateParkPickup = (pickSlots) => {


        let slotsObject = {}
        for (let i = 1; i <= parseInt(pickSlots); i++) { slotsObject[`${70 + i}`] = {} }


        if (featuresCallflow.parkPick[0]) {
            petition_patch("callflowEdit", {
                id, data: {
                    flow: {
                        data: {
                            default_ringback_timeout: 120000,
                            default_callback_timeout: 30000,
                            action: "park",
                            custom_presence_id: false,
                            slots: slotsObject
                        },
                        module: "park",
                        children: {}
                    },
                    numbers: ["*70"],
                    featurecode: { name: "park_pickup", number: "70" },
                    metadata: {}
                }, callflow_id: featuresCallflow.parkPick[0].id
            })
                .then(result => { setModal(false); toastr.success("Action completed", "Success"); })
                .catch((err) => { toastr.error("Action Error", "Error") });
        }










    }



    return { dataTable, loading, onHandleSave, modal, setModal, loadingModal, featuresCallflow, updateParkPickup };
}
