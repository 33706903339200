import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  TOKEN_USER,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  CHANGE_ADMIN,
  CHANGE_INSTALLAPPS
} from "./actionTypes";

const initialState = {
  error: "dkjkdjfkdjfkdjfk",
  loading: false,
  admin: null,
  userData: null,
  installApps: null
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case TOKEN_USER:
      state = { ...state };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case LOGOUT_USER:
      state = { ...state };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;

    case CHANGE_ADMIN:
      state = { ...state, admin: action.payload.value, userData: action.payload.userData };
      break;
    case CHANGE_INSTALLAPPS:
      state = { ...state, installApps: action.payload };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
