import React, {useState, useEffect} from "react";
import {
  Card,
  Row,
  Col,
  CardTitle,
  Input,
  Label,
  CardBody,
  Spinner,
} from "reactstrap";
import Switch from "react-switch";
import petition_get from "../../../../petitions/petition_get";
import petition_patch from "../../../../petitions/petition_patch";
import toastr from "toastr"
export default function FaxSettings() {
    toastr.options = {
    showDuration: 300,
  };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const [switchOne, setSwitchOne] = useState(true);
  const [switchTwo, setSwitchTwo] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingTwo, setLoadingTwo] = useState(false);
  const [data, setData] = useState(null);
  const [form, setForm] = useState({
    email: "",
    email_two: "",
  });
  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  useEffect(() => {
    const petitions = async () => {
      petition_get("faxSettings", {id: id})
        .then(({data: result}) => {
          setLoadingTwo(true);
          setData(result.result);
          setForm({
            ...form,
            email: result.result.notifications.inbound.email.send_to[0],
            email_two: result.result.notifications.outbound.email.send_to[0],
          });

          setSwitchOne(
            result.result.notifications.inbound.email.send_to.length === 0
              ? false
              : true
          );
          setSwitchTwo(
            result.result.notifications.outbound.email.send_to.length === 0
              ? false
              : true
          );
        })
        .catch((error) => {
          console.log(error);
        });
    };
    petitions();
  }, []);

  const onChangeForm = (e) => {
    if (e.target.name === "email" && e.target.value !== "") {
      setSwitchOne(true);
    }
    if (e.target.name === "email_two" && e.target.value !== "") {
      setSwitchTwo(true);
    }

    setForm({...form, [e.target.name]: e.target.value});
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    setLoading(true);

    const info = {
      name: data.name,
      caller_name: data.caller_name,
      fax_header: data.fax_header,
      inbound_email_notification: switchOne ? form.email : "",
      outbound_email_notification: switchTwo ? form.email_two : "",
    };

    if (!switchOne) {
      setForm({...form, email: ""});
    }
    if (!switchTwo) {
      setForm({...form, email_two: ""});
    }

    await petition_patch("editFaxbox", {id: id, number: data.id, data: info})
      .then(({data: result}) => {
        setData(result.result);
        setForm({
          ...form,
          email: result.result.notifications.inbound.email.send_to[0],
          email_two: result.result.notifications.outbound.email.send_to[0],
        });

        setSwitchOne(
          result.result.notifications.inbound.email.send_to.length === 0
            ? false
            : true
        );
        setSwitchTwo(
          result.result.notifications.outbound.email.send_to.length === 0
            ? false
            : true
        );
        toastr.success("Action completed", "Success");
        setLoading(false);
      })
      .catch((error) => {
        toastr.error("Action failed", "Error");
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <React.Fragment>
      <Card body>
        <CardTitle className="mt-4">User Details </CardTitle>
        <CardBody>
          {loadingTwo ? (
            <form onSubmit={onSubmitForm} action="">
              <Row className="mt-4">
                <Col>
                  <div>
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      className="mr-1"
                      checkedIcon={<OnSymbol />}
                      onColor="#626ed4"
                      onChange={() => {
                        setSwitchOne(!switchOne);
                      }}
                      checked={switchOne}
                    />
                    <p
                      className="ml-0  ml-lg-4"
                      style={{display: "inline-block"}}
                    >
                      Send Incoming Faxes to my email
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={9}>
                  <Label for="exampleEmail">Enter Email</Label>
                  <Input
                    onChange={onChangeForm}
                    type="email"
                    name="email"
                    value={form.email}
                    id="exampleEmail"
                    placeholder="Enter Email"
                  />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col>
                  <div>
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      className="mr-1"
                      checkedIcon={<OnSymbol />}
                      onColor="#626ed4"
                      onChange={() => {
                        setSwitchTwo(!switchTwo);
                      }}
                      checked={switchTwo}
                    />
                    <p
                      className="ml-0  ml-lg-4"
                      style={{display: "inline-block"}}
                    >
                      Send Outgoint Fax Report to my email
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={9}>
                  <Label for="exampleEmail-two">Enter Email</Label>
                  <Input
                    onChange={onChangeForm}
                    type="email"
                    name="email_two"
                    value={form.email_two}
                    id="exampleEmail-two"
                    placeholder="Enter Email"
                  />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col className="col-12  text-right mt-4">
                  <button
                    disabled={loading}
                    className={`btn btn-primary ml-4 ${
                      !loading && "pl-4"
                    }  pr-4 font-size-16`}
                  >
                    {loading && (
                      <Spinner
                        style={{marginRight: "0.5em"}}
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}{" "}
                    Save
                  </button>
                </Col>{" "}
              </Row>
            </form>
          ) : (
            <div style={{textAlign: "center"}}>
              <Spinner
                animation="grow"
                style={{
                  margin: "10vh auto",
                  height: "10vh",
                  width: "10vh",
                }}
              />{" "}
            </div>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
