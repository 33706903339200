import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  Row,
  Col,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import phoneImg from "../../../../../assets/images/phone-image.svg";
import Select from 'react-select'

export default function AddKeyModals({ listUsers, modal, loading, setModal, addApiKey }) {


  const [selectedUser, setSelectedUser] = useState("")

  useEffect(() => {

    if (modal) setSelectedUser("")

  }, [modal])

  return (
    <Modal
      centered
      isOpen={modal === false ? false : true}
      className="info modal-md overflowActive"
    >
      <ModalHeader className="bg-soft-primary">
        {" "}
        <Row>
          <Col xs="9">
            <div className="text-primary p-3">
              <h3 className="text-primary">Add Key</h3>
            </div>
          </Col>
          <Col xs="3" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <Spinner
              animation="grow"
              style={{
                margin: "10vh auto",
                height: "10vh",
                width: "10vh",
              }}
            />{" "}
          </div>
        ) : (
          <p >
            {modal === "User"
              ? <div className="col-12 ">
                <label htmlFor="">Assigned User</label>

                <Select
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      background: "transparent"
                    }),
                    option: (base, state) => ({
                      ...base,
                      color: "rgba(0,0,0,.8)"
                    }),
                  }}
                  options={listUsers}
                  className="form-control control-select"
                  onChange={(e) => { setSelectedUser(e.value) }}
                />

              </div>
              : <> Add New {modal} Key ?</>
            }


          </p>
        )}
      </ModalBody>{" "}
      <ModalFooter>
        <Button
          disabled={loading}
          outline
          color="secondary"
          onClick={() => {
            setModal(false);
          }}
        >
          No
        </Button>

        <Button
          disabled={loading}
          color="primary"
          onClick={() => {
            addApiKey(selectedUser);
          }}
        >
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
}
