import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import petition_get from "../../../../petitions/petition_get";
import SipComponent from "../../../../../components/SipComponent/SipComponentConference"
import ModalUtilitySettings from "./ModalUtility/ModalUtilitySettings";
import ModalUtilityQueues from "./ModalUtility/ModalUtilityQueues";

import OutgoindCall from "./EventsCalls/OutgoindCall";
import IncomingCall from "./EventsCalls/IncomingCall";
import ProfileCall from "./EventsCalls/ProfileCall"
import OfflinePhone from "./EventsCalls/OfflinePhone"

function Calls({ layout, realm, userAgentStart, userAgentEnd, userStatusProfile, setUserStatusProfile, users, accountDetails }) {

  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const [holdWaiting, setHoldWaiting] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalSettings, setModalSettings] = useState(false);
  const [modalQueues, setModalQueues] = useState(false)
  const [activePhone, setActivePhone] = useState(false);
  const [step, setStep] = useState(1)
  const [calling, setCalling] = useState(1);
  const [onTransfer, setOnTransfer] = useState(false);
  const [modal, setModal] = useState(false)
  const [actualSessions, setActualSessions] = useState([])
  const [actualSessionSelected, setActualSessionSelected] = useState(null)
  const [volume, setVolume] = useState(100)
  const [reloadQueues, setReloadQueues] = useState(false)

  function TransferCall(number) { setTransferCall(number) }

  const audioSettings = () => { setModalSettings(true); };

  /// Nuevo codigo 
  const [keyboardNumber, setKeyboardNumber] = useState(null);
  const [registerSipExtention, setRegisterSipExtention] = useState(false);
  const [initiCall, setInitCall] = useState(false);
  const [endCall, setEndCall] = useState(false);
  const [numberToCall, seTNumberToCall] = useState("");
  const [audioInputSelected, setAudioInputSeleced] = useState("default");
  const [audioOutputSelected, setAudioOutputSelected] = useState("default");
  const [initAnswer, setInitAnswer] = useState(false);
  const [rejectedCall, setRejectedCall] = useState(false);
  const [endInvited, setEndInvited] = useState(false);
  const [hold, setHold] = useState(false);
  const [unhold, setUnhold] = useState(false);
  const [mute, setMute] = useState(false);
  const [unmute, setUnmute] = useState(false);
  const [silence, setSilence] = useState(false);
  const [unsilence, setUnsilence] = useState(false);
  const [transferCall, setTransferCall] = useState(false);
  const [time, setTime] = useState(0)
  const [idTime, setIdTime] = useState(null)
  const [disconnected, setDisconnected] = useState(null)
  const [reloadRegister, setReloadRegister] = useState(false)

  const [timeoutReload, setTimeoutReload] = useState(null)




  const [state, setState] = useState({
    audioInputSelect: null,
    audioOutputSelect: null,
    videoElement: null,
  });

  useEffect(() => {

    setState({
      videoElement: document.querySelector('video'),
      audioInputSelect: document.querySelector('select#audioSource'),
      audioOutputSelect: document.querySelector('select#audioOutput'),
      selectors: [document.querySelector('select#audioSource'), document.querySelector('select#audioOutput')],
    })

  }, [])

  useEffect(() => {

    if (state.selectors) {

      try {

        navigator.mediaDevices.enumerateDevices().then(gotDevices).catch(handleError);
      } catch (error) {
        console.log(error)

      }
    }

  }, [state])


  function callNumberButton(number) {
    setInitCall(true);
    seTNumberToCall(number)
  }

  function endCallButton(idSession) {
    setEndCall(idSession);
  }

  function gotDevices(deviceInfos) {
    const values = state.selectors.map(select => select.value);
    state.selectors.forEach(select => {
      while (select.firstChild) {
        select.removeChild(select.firstChild);
      }
    });
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      const option = document.createElement('option');
      option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === 'audioinput') {
        option.text = deviceInfo.label || `microphone ${state.audioInputSelect.length + 1}`;
        state.audioInputSelect.appendChild(option);
      } else if (deviceInfo.kind === 'audiooutput') {
        option.text = deviceInfo.label || `speaker ${state.audioOutputSelect.length + 1}`;
        state.audioOutputSelect.appendChild(option);
      } else {
        console.log('Some other kind of source/device: ', deviceInfo);
      }
    }
    state.selectors.forEach((select, selectorIndex) => {
      if (Array.prototype.slice.call(select.childNodes).some(n => n.value === values[selectorIndex])) {
        select.value = values[selectorIndex];
      }
    });
  }

  function handleError(error) {
    console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
  }

  function getRegistered(registered) {
    console.log(registered);
  }

  const status = {
    getRegistered: getRegistered
  }


  useEffect(() => {
    if (userStatusProfile) {
      setRegisterSipExtention(true)
    }
  }, [userStatusProfile])

  useEffect(() => {

    if (actualSessions.length > 0 && !idTime && actualSessionSelected) {
      setTime("")
      let timeCopy = 0
      let intervalId = setInterval(() => {


        timeCopy++;
        setTime(new Date(timeCopy * 1000).toISOString().substr(timeCopy < 3600 ? 14 : 11, timeCopy < 3600 ? 5 : 8),)


      }, 1000)
      setIdTime(intervalId)
    }
    else if (idTime) {

      clearInterval(idTime)
      setIdTime(null)
    }

  }, [actualSessions, calling, actualSessionSelected])

  const onInvitePhone = (value, actualSessionsSize) => {

    switch (value) {



      case "inviteInit":
        setModal(true)
        setStep(1)
        setCalling(1)
        break;
      case "inviteEnd":

        if (actualSessionsSize.length === 0) {
          setStep(3)
          let saveTimeout = setTimeout(() => { setModal(false); setStep(1); }, 3000)
          setTimeoutReload(saveTimeout)
        }
        else if (actualSessionsSize.length === 1) setStep(2)
        break;
      case "callEnd":
        if (actualSessionsSize.length === 0) {
          setStep(3)
          setCalling(3)
          let saveTimeout = setTimeout(() => { setStep(1); setCalling(1); }, 3000)
          setTimeoutReload(saveTimeout)
        }
        break;
      default: break;
    }

  }


  const [deviceId, setDeviceId] = useState(null)
  const [deviceData, setDeviceData] = useState(null)
  const [reloadData, setReloadData] = useState(false)
  const [firstPetition, setFirstPetition] = useState(false)
  const [notDevices, setNotDevices] = useState(false)
  const [queues, setQueues] = useState([])
  const [data, setData] = useState({ input: [], output: [] })



  useEffect(() => {

    const petitions = async () => {
      let saveOwnerId = ""
      let saveQueuesAvailables = []
      await petition_get("MyDevices", { id: id })
        .then(({ data: result }) => {

          let findOne = result.result.find(element => element.device_type === "web_phone")
          setDeviceId(findOne.id)
          saveOwnerId = findOne.owner_id
        })
        .catch((error) => {
          console.log(error);
        });

      /*  await petition_get("viewCallSettingsUsers", { id: id, user_id: saveOwnerId })
         .then(({ data: result }) => {
 
           saveQueuesAvailables = result.result.queues
           petition_get("Queues", { id: id })
             .then(({ data: result }) => {
 
 
               let queuesCopy = []
 
               saveQueuesAvailables.map(element => {
                 const findOne = result.result.find(elementTwo => elementTwo.id === element)
                 if (findOne) queuesCopy.push(findOne)
 
               })
               setQueues(queuesCopy)
 
             })
             .catch((error) => {
               console.log(error);
             });
         })
         .catch((error) => {
           console.log(error);
         }); */

    }

    petitions()



  }, [])

  useEffect(() => {

    if (deviceId && users) {
      setDeviceData(null)
      petition_get("device", { id: id, deviceId: deviceId })
        .then(({ data: result }) => {

          let findUser = users.filter(element => element.id === result.result.owner_id)
          setUserStatusProfile({ ...result.result, ...findUser[0], web_phone: { sip: result.result.sip } })

          if (!result.result.itpvoice_metadata) {
            result.result = { ...result.result, itpvoice_metadata: { web_phone_enabled: true } }
          }
          else if (result.result.itpvoice_metadata && result.result.itpvoice_metadata["web_phone_enabled"] === undefined) {
            result.result = { ...result.result, itpvoice_metadata: { ...result.result.itpvoice_metadata, web_phone_enabled: true } }
          }

          setDeviceData(result.result)
          if (firstPetition) {
            if (result.result.itpvoice_metadata.web_phone_enabled)
              userAgentStart();

            else
              userAgentEnd();
          }

        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [users, deviceId, reloadData])


  useEffect(() => {

    if (deviceData && layout.active && !firstPetition) {
      if (deviceData.itpvoice_metadata.web_phone_enabled)
        userAgentStart();

      else
        userAgentEnd();


      setFirstPetition(true)

    }

  }, [layout.active])

  useEffect(() => {
    const Devices = async () => {

      try {
        let devices = await navigator.mediaDevices.enumerateDevices();
        let outputsAudioDevices = devices.filter(
          (device) => device.kind === "audiooutput"
        );
        let inputsAudioDevices = devices.filter(
          (device) => device.kind === "audioinput"
        );


        if (inputsAudioDevices.length === 0 || outputsAudioDevices === 0) setNotDevices(true)
        setData({
          input: inputsAudioDevices,
          output: outputsAudioDevices
        })
      } catch (error) {
        console.log(error)
      }

    }
    Devices()
  }, []);

  return (
    <>
      <ModalUtilitySettings data={data} modal={modalSettings} setModal={setModalSettings} volume={volume} setVolume={setVolume} deviceData={deviceData} reloadData={reloadData} setReloadData={setReloadData} deviceId={deviceId} id={id} calling={calling} loading={loading} setLoading={setLoading} setUserStatusProfile={setUserStatusProfile} userStatusProfile={userStatusProfile} setUserStatusProfile={setUserStatusProfile} userAgentStart={userAgentStart} userAgentEnd={userAgentEnd} changeAudioSelected={setAudioOutputSelected} changeAudioSelectedInput={setAudioInputSeleced} />
      <ModalUtilityQueues queues={queues} modal={modalQueues} setModal={setModalQueues} reloadData={reloadQueues} setReloadData={setReloadQueues} userStatusProfile={userStatusProfile} />
      <ProfileCall queues={queues} reloadQueues={reloadQueues} setModalQueues={setModalQueues} reloadDataDevice={reloadData} setReloadDataDevice={setReloadData} deviceId={deviceId} deviceData={deviceData} userStatusProfile={userStatusProfile} audioSettings={audioSettings} />
      <div
        className="phone-call_keyboard"
      >

        {(notDevices || (disconnected && actualSessions.length === 0) || ((deviceData && !deviceData.itpvoice_metadata.web_phone_enabled) && userStatusProfile)) && <OfflinePhone setDisconnected={setDisconnected} setReloadRegister={setReloadRegister} disconnected={disconnected} notDevices={notDevices} />}

        {!notDevices && deviceData && deviceData.itpvoice_metadata.web_phone_enabled && userStatusProfile && !modal && (
          <OutgoindCall
            actualSessions={actualSessions}
            call={callNumberButton}
            endCall={endCallButton}
            calling={calling}
            setCalling={setCalling}
            silence={setSilence}
            unsilence={setUnsilence}
            switch={
              deviceData && deviceData.itpvoice_metadata && deviceData.itpvoice_metadata.web_phone_enabled ? true : false
            }
            mute={setMute}
            unMute={setUnmute}
            transferCall={TransferCall}
            setOnTransfer={setOnTransfer}
            onTransfer={onTransfer}
            holdCall={setHold}
            unholdCall={setUnhold}
            setKeyboardNumber={setKeyboardNumber}
            keyboardNumber={keyboardNumber}
            activePhone={activePhone}
            setActivePhone={setActivePhone}
            setModal={setModal}
            actualSessionSelected={actualSessionSelected}
            users={users}
            time={time}
            timeoutReload={timeoutReload}

          />
        )}
        {!notDevices && deviceData && deviceData.itpvoice_metadata.web_phone_enabled && modal && (
          <IncomingCall
            actualSessions={actualSessions}
            answer={setInitAnswer}
            reject={setRejectedCall}
            endCallonInvited={endCallButton}
            step={step}
            setStep={setStep}
            setCalling={setCalling}
            setActivePhone={setActivePhone}
            setModal={setModal}
            silence={setSilence}
            unsilence={setUnsilence}
            mute={setMute}
            unMute={setUnmute}
            setOnTransfer={setOnTransfer}
            holdCall={setHold}
            unholdCall={setUnhold}
            time={time}
            users={users}
            actualSessionSelected={actualSessionSelected}
            setHoldWaiting={setHoldWaiting}
            setKeyboardNumber={setKeyboardNumber}
            timeoutReload={timeoutReload}

          />
        )}
        {!notDevices && userStatusProfile && <SipComponent
          configuration={{
            username: userStatusProfile.web_phone.sip.username,
            password: userStatusProfile.web_phone.sip.password,
            realm: realm,
          }}
          registerSipExtention={registerSipExtention}
          status={status}
          deviceData={deviceData}
          callNumber={{ initiCall: initiCall, numberToCall: numberToCall, setInitCall: setInitCall }}
          answerCall={{ initAnswer: initAnswer, setInitAnswer: setInitAnswer }}
          endCall={{ endCall: endCall, setEndCall: setEndCall }}
          rejectedCall={{ rejectedCall: rejectedCall, setRejectedCall: setRejectedCall }}
          endInvited={{ endInvited: endInvited, setEndInvited: setEndInvited }}
          audioInputSelected={audioInputSelected}
          audioOutputSelected={audioOutputSelected}
          hold={{ hold: hold, setHold: setHold }}
          unhold={{ unhold: unhold, setUnhold: setUnhold }}
          mute={{ mute: mute, setMute: setMute }}
          unmute={{ unmute: unmute, setUnmute: setUnmute }}
          silence={{ silence: silence, setSilence: setSilence }}
          unsilence={{ unsilence: unsilence, setUnsilence: setUnsilence }}
          keyboardNumber={{ keyboardNumber: keyboardNumber, setKeyboardNumber: setKeyboardNumber }}
          setModal={setModal}
          setCalling={setCalling}
          setStep={setStep}
          onInvitePhone={onInvitePhone}
          actualSessionsInPhone={{ actualSessions, setActualSessions }}
          transferCall={{ transferCall: transferCall, setTransferCall: setTransferCall }}
          volumen={{ volume, setVolume }}
          setActualSessionSelected={setActualSessionSelected}
          holdWaiting={{ holdWaiting, setHoldWaiting }}
          setDisconnected={setDisconnected}
          reloadRegister={reloadRegister}
          setReloadRegister={setReloadRegister}

        />}

      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    layout: state.sip,
  };
};

export default connect(mapStateToProps)(Calls);
