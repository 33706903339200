import React, { useState, useEffect } from 'react'
import { Input, Button, Spinner, } from "reactstrap";
import SearchIcon from "@material-ui/icons/Search";
import Replay from "@material-ui/icons/Replay";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useInView } from 'react-intersection-observer';
import ButtonHover from "../../components/utils/ButtonHover"
export default function SidebarComponent({ data, DataItem, filterKeys, filterData, getListCallHistory, loading }) {

    const [dataFilter, setDataFilter] = useState(null)
    const [inputSearch, setInputSearch] = useState("")
    const [directionFilter, setDirectionFilter] = useState("inbound")
    const [activeInput, setActiveInput] = useState(false)


    useEffect(() => { if (data) setDataFilter(data) }, [data])

    const { ref, inView, entry } = useInView({

        threshold: 0,
    });



    const onChangeSearch = (e) => {
        setInputSearch(e.target.value)

        if (!filterData) {


            const searchList = data.filter((element) => filterKeys.find(elementTwo => {

                if (elementTwo.key) {
                    let searchInternalInput = element[elementTwo.key] && element[elementTwo.key].find(elementThree => elementThree[elementTwo.subKey].toLowerCase().includes(e.target.value.toLowerCase().trim()))
                    if (searchInternalInput) return element
                } else if (element[elementTwo].toLowerCase().includes(e.target.value.toLowerCase().trim())) return element


            }));
            setDataFilter(searchList);
        }


    }


    useEffect(() => {

        if (inView && filterData && !activeInput) getListCallHistory({ pagination: true })



    }, [inView])

    let validateInputNumber = (number) => {
        let newNumber;

        var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        let isValid = phoneNumberPattern.test(number);


        if (isValid && !number.includes("+1")) {
            newNumber = `%2b1${number}`
        } else {

            newNumber = number
            newNumber = newNumber.replace("+", "%2b")
        }

        filterData(newNumber, directionFilter)
        setActiveInput(true)
    }

    return (
        <div className="row ">
            <div className="col-12 p-0 mb-3 d-flex">

                <div className="d-flex form-control p-0 pl-2" style={{ borderRadius: "3rem", height: "35px", overflow: "hidden" }}>

                    {filterData ? <select className="form-control p-0 rounded-0 w-auto" name="" id="" value={directionFilter} onChange={(e) => { setDirectionFilter(e.target.value) }}>

                        <option value="inbound">Inbound</option>
                        <option value="outbound">Outbound</option>
                    </select> : <label htmlFor="search" className="sidebar-label"  ><SearchIcon /> </label>}



                    <input
                        id="search"
                        name="search"
                        value={inputSearch}
                        onChange={onChangeSearch}
                        placeholder={"Search"}
                        type="text"
                        className="w-100 form-control p-0 rounded-0 pl-2"

                    />
                </div>
                {filterData && <div className="d-flex align-items-center">  <Button onClick={() => { validateInputNumber(inputSearch) }} color="primary" className="ml-3 mr-2">  {loading && (
                    <Spinner
                        style={{ marginRight: "0.5em" }}
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                )} Search</Button> <ButtonHover onClick={() => { getListCallHistory({}); setActiveInput(false); setInputSearch("") }}><Replay /></ButtonHover> </div>}

            </div>
            <PerfectScrollbar className="sidebarSize">
                {dataFilter && dataFilter.map((element, i) => <DataItem key={i} item={element} index={i} />)}
                <div ref={ref} >. </div>
            </PerfectScrollbar>
        </div>
    )
}
