import React, { useState, useEffect } from "react";
import petition_patch from "../../../../petitions/petition_patch";
import petition_get from "../../../../petitions/petition_get";
import petition_post from "../../../../petitions/petition_post";
import getTimeZoneList from "../../../../../components/utils/getTimezoneList"
import Select from 'react-select'

import {
  Button,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
  Spinner,
} from "reactstrap";

import { Checkmark } from "react-checkmark";
import styled from "styled-components";
import AddNumber from "../AddNumber/AddNumber";

import { useHistory } from "react-router-dom";

const StepStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  margin-right: 10px;
  border-radius: 50%;
  border: 2px solid #556ee6;
  color: rgb(85, 110, 230);
  font-weight: 500;

  ${(props) =>
    props.step &&
    `
       background: #556ee6;
      border: 2px solid #556ee6;
      color: white;
   
  `}
`;

export default function ModalUtility({
  modal,
  setModal,
  id,
  userData
}) {



  const history = useHistory();
  const [step, setStep] = useState(1);
  const [allTimeZone, setAllTimeZone] = useState([""]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingTwo, setLoadingTwo] = useState(true);
  const [numberSelected, setNumberSelected] = useState(null);
  const [listTimeZones] = useState(getTimeZoneList())

  const [form, setForm] = useState({
    extension: "100",
  });

  const onChangeFormExtension = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (userData) {
      setForm({
        extension: "100",
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
        timeZone: allTimeZone[0],
      });
    }
  }, [userData]);



  const changeStep = () => {
    switch (step) {
      case 1:
        setStep(step + 1);
        break;
      case 2:
        setStep(step + 1);
        break;
      case 3:
        setStep(step + 1);
        break;
      case 4:
        const user = JSON.parse(localStorage.getItem("authUser"));
        setLoading(true);
        let data = [
          {
            id: id,
            user_id: userData.id,
          },
          {
            ...userData,
            presence_id: form.extension,
            first_name: form.first_name,
            last_name: form.last_name,
            caller_id: {
              internal: {
                number: form.extension,
                name: `${form.first_name} ${form.last_name}`
              }
            },
          },
        ];

        petition_patch("editUser", data)
          .then(({ data: result }) => {




            petition_get("callHandling", { id })
              .then(({ data: result }) => {
                petition_patch("updateCallflowDisturb", { id, data: { ...result.result, numbers: [`${form.extension}`, `${numberSelected[0].number}`], name: `${form.extension} Callflow` } })
                  .then(({ data: result }) => { })
                  .catch((error) => { console.log(error) });
              })
              .catch((error) => {
                console.log(error);
              });

            petition_get("voicemail-box-details", { id, idVoicemail: result.result.voicemail_box_id })
              .then(({ data: result }) => {
                petition_patch("updateVoicemailBox", {
                  id: id,
                  voicemail_box_id: result.result.id,
                  data: { ...result.result, name: `${form.first_name} ${form.last_name}`, mailbox: form.extension, },
                }).catch((error) => {
                  console.log(error);
                });

              })
              .catch((error) => {
                console.log(error);
              });
            setLoading(false);
            setStep(step + 1);
          })
          .catch((error) => {
            console.log(error);
          });

        let tempData = { ...userData, timezone: form.timeZone }

        if (tempData.timezone.trim() === "") delete tempData.timezone

        petition_patch("updateAccountSettings", {
          id: id,
          data: tempData,
        })
          .then(({ data: result }) => {
            setLoading(false);
            setStep(step + 1);
          })
          .catch((error) => {
            console.log(error);
          });

        petition_post("orders", {
          id: id,
          data: { numbers: [{ did: numberSelected[0].number }] },
        })
          .then(({ data: result }) => {
            const accountName = JSON.parse(localStorage.getItem("authUser"))
              .account.name;
            petition_patch("updateAccontNumber", {
              id: id,
              data: {
                caller_id: {
                  internal: {
                    number: form.extension,
                    name: `${form.first_name} ${form.last_name}`
                  },
                  external: {
                    name: accountName,
                    number: numberSelected[0].number,
                  },
                },
              },
            })
              .then(({ data: result }) => {
                petition_patch("numberEdit", dataEditNumber).then(() => {
                  setLoading(false);
                  setStep(step + 1);
                })

              })
              .catch((error) => {
                console.log(error);
              });


            let dataEditNumber = [
              { id, number: numberSelected[0].number },
              {
                owner_id: userData.id,

              },
            ];




          })
          .catch((error) => {
            console.log(error);
          });

        break;
      case 5:
        break;
      default:
        break;
    }
  };

  return (
    <Modal size="xl" isOpen={modal} centered={true}>
      <ModalBody>
        <div
          className="bg-soft-primary"
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "center",
            borderRadius: "0.5rem",
            padding: "0rem 0.7rem 1rem 0.7rem",
          }}
        >
          <Row
            color="primary"
            style={{
              width: "200px",
              display: "flex",
              alignItems: "center",
              color: "white",
              justifyContent: "center",
              paddingTop: "1rem",
            }}
          >
            <Col className="col-2">
              <StepStyle step={step > 0 ? true : false}>1</StepStyle>
            </Col>
            <Col className="col-9 ml-2">Welcome </Col>
          </Row>
          <Row
            color="primary"
            style={{
              width: "200px",
              display: "flex",
              alignItems: "center",
              color: "white",
              justifyContent: "center",
              paddingTop: "1rem",
            }}
          >
            <Col className="col-2">
              <StepStyle step={step > 1 ? true : false}>2</StepStyle>
            </Col>
            <Col className="col-9 ml-2">Change Info</Col>
          </Row>
          <Row
            color="primary"
            style={{
              width: "200px",
              display: "flex",
              alignItems: "center",
              color: "white",
              justifyContent: "center",
              paddingTop: "1rem",
            }}
          >
            <Col className="col-2">
              <StepStyle step={step > 2 ? true : false}>3</StepStyle>
            </Col>
            <Col className="col-9 ml-3">Add Number</Col>
          </Row>
          <Row
            color="primary"
            style={{
              width: "200px",
              display: "flex",
              alignItems: "center",
              color: "white",
              justifyContent: "center",
              paddingTop: "1rem",
            }}
          >
            <Col className="col-2">
              <StepStyle step={step > 3 ? true : false}>4</StepStyle>
            </Col>
            <Col className="col-9 ml-3">Info</Col>
          </Row>
          <Row
            color="primary"
            style={{
              width: "200px",
              display: "flex",
              alignItems: "center",
              color: "white",
              justifyContent: "center",
              paddingTop: "1rem",
            }}
          >
            <Col className="col-2">
              <StepStyle step={step > 4 ? true : false}>5</StepStyle>
            </Col>
            <Col className="col-9 ml-2"> Confirm</Col>
          </Row>
        </div>
        <hr />

        {step === 1 && (
          <>
            {" "}
            <p className="text-center p-5 " style={{ fontSize: "1.2rem " }}>
              Welcome to ITP Voice! First, let's give yourself an extension
              number. Please click next to configure your user.
            </p>
          </>
        )}
        {step === 2 && (
          <>
            {/* <label htmlFor="extension">
              Please enter the extension you want to use (for default its 100 )
            </label>
            <InputGroup>
              <Input
                id="extension"
                type="number"
                value={form.extension}
                onChange={onChangeFormExtension}
                name="extension"
                className="w-50"
                disabled={true}
              />
            </InputGroup>
            <br /> */}
            <label htmlFor="timeZone">
              Please select the system default time zone.
            </label>
            <InputGroup>
              <Select
                styles={{
                  control: (base, state) => ({
                    ...base,
                    background: "transparent"
                  }),
                  option: (base, state) => ({
                    ...base,
                    color: "rgba(0,0,0,.8)"
                  }),
                }}
                options={listTimeZones}
                placeholder="Default"
                className="form-control control-select"
                onChange={(e) => { onChangeFormExtension({ target: { name: "timeZone", value: e.value } }) }}
              />
            </InputGroup>
          </>
        )}

        {step === 3 && loadingTwo && (
          <AddNumber
            data={data}
            setData={setData}
            setNumberSelected={setNumberSelected}
          />
        )}

        {step === 4 && (
          <>
            <label className="pt-3" htmlFor="first_name">
              First Name
            </label>
            <InputGroup>
              <Input
                id="first_name"
                type="text"
                value={form.first_name}
                onChange={onChangeFormExtension}
                name="first_name"
                className="w-50"
              />
            </InputGroup>
            <label className="pt-3" htmlFor="last_name">
              Last Name
            </label>
            <InputGroup>
              <Input
                id="last_name"
                type="text"
                value={form.last_name}
                onChange={onChangeFormExtension}
                name="last_name"
                className="w-50"
              />
            </InputGroup>
            <label className="pt-3" htmlFor="email">
              Email
            </label>
            <InputGroup>
              <Input
                id="email"
                type="text"
                value={form.email}
                onChange={onChangeFormExtension}
                name="email"
                className="w-50"
                disabled
              />
            </InputGroup>
          </>
        )}
        {step === 5 && (
          <>
            <Checkmark size="150px" />
            <p className="text-center pt-3" style={{ fontSize: "1.9rem " }}>
              Success
            </p>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        {step < 5 && (
          <>
            {step > 1 && (
              <Button
                outline
                disabled={loading}
                color="secondary"
                onClick={() => {
                  if (step === 3) setNumberSelected(null);
                  setStep(step - 1);
                }}
              >
                Back
              </Button>
            )}

            <Button
              disabled={
                (step === 2 && form.extension.trim() === "") ||
                (step === 3 && !numberSelected) ||
                (step === 4 &&
                  (form.last_name.trim() === "" ||
                    form.first_name.trim() === "")) ||
                loading
              }
              color="primary"
              onClick={() => {
                changeStep();
              }}
            >
              {loading && (
                <Spinner
                  style={{ marginRight: "0.5em" }}
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Next
            </Button>
          </>
        )}

        {step === 5 && (
          <Button
            color="primary"
            onClick={() => {
              setModal(false);
              history.push(`/all-numbers?id=${id}`);
            }}
          >
            Close
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}
