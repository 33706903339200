import React, { useRef, useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";

import petition_patch from '../pages/petitions/petition_patch';
import petition_post from '../pages/petitions/petition_post';
import petition_get from '../pages/petitions/petition_get';

import ModalUtilityContact from '../pages/ITPVoice/MyExtensions/CallHistory/ModalUtility/ModalUtilityContact';
import ModalUtilityAddContact from '../pages/ITPVoice/MyExtensions/CallHistory/ModalUtility/ModalAddContact';
import ModalUtilityDownload from '../pages/ITPVoice/MyExtensions/CallHistory/ModalUtility/ModalUtilityDownload';
import toastr from "toastr";
import json from "../config.json"
export default function useCallHistorySelectedItem({ getListOfNotes, item, downloadCall, setReloadData, userData }) {
    toastr.options = {
        showDuration: 300,
    };
    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    let refAudio = useRef()
    const history = useHistory()

    //STATES
    const [note, setNote] = useState({ notes: "" })
    const [modalContact, setModalContact] = useState(null)
    const [modalNewContact, setModalNewContact] = useState(false)
    const [modalDownload, setModalDownload] = useState(false)
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [findRecording, setFindRecording] = useState([])
    const [urlBase] = useState(json.prod ? "https://api.itpscorp.com/portal/itpvoice/" : "https://api.itpscorp.com/dev/portal/itpvoice/")
    const [token] = useState(JSON.parse(localStorage.getItem("authUser")))




    const [contactInfo, setContactInfo] = useState([])
    const [playActive, setPlayActive] = useState(false)
    const [time, setTime] = useState(0)

    const getItemData = () => {

        if (item.result) {
            let findRecord = item.result.filter(element => element.media_recordings.length > 0)
            setFindRecording(findRecord)
        }

        if (item.contactData) {
            let contactInfoSave = [{ title: "Contact Name:", value: item.contactData.name },]

            if (item.contactData.numbers) item.contactData.numbers.map((element, i) => { contactInfoSave.push({ title: i === 0 ? "Numbers:" : "", value: `${element.number} - ${element.label}` }) })
            if (item.contactData.emails) item.contactData.emails.map((element, i) => { contactInfoSave.push({ title: i === 0 ? "Emails:" : "", value: `${element.email} - ${element.label}`, }) })
            if (item.contactData.internal_number) contactInfoSave.push({ title: "Internal Number", value: item.contactData.internal_number })

            setContactInfo(contactInfoSave)
        }
    }

    const getNoteOfCall = () => {
        setNote({ notes: "" })
        petition_get("notes", { id, query: item.result[0].interaction_id })
            .then(({ data: result }) => setNote(result.result.model_list[0] || { notes: "" }))
            .catch((error) => console.log(error))
    }

    const getUrlVoiceAudio = (media_recordings) => {
        return `${urlBase}${id}/recordings/${media_recordings}?token=${token.access_token}&&accept=audio/mpeg`
    }

    const getModalsControllers = () => {
        return (<>
            <ModalUtilityContact timeZone={userData.timezone} setReloadData={setReloadData} modal={modalContact} setModal={setModalContact} />
            <ModalUtilityAddContact setReloadData={setReloadData} modal={modalNewContact} setModal={setModalNewContact} />
            <ModalUtilityDownload spinner={loadingDownload} setModal={setModalDownload} modal={modalDownload} downloadCall={onClickDownloadCall} />
        </>)
    }



    useEffect(() => {
        getItemData();
        getNoteOfCall();
    }, [item])

    //OnClick Functions

    const onClickSendMessage = () => { history.push(`/Chat?id=${id}&&messageNumber=${item.userCallNumber}`) }

    const onClickOpenModalDownload = () => { setModalDownload(findRecording) }

    const onClickContacts = () => {
        if (item.userCallName) setModalContact({ ...item.contactData, number: item.userCallNumber })
        else setModalNewContact(item.userCallNumber)
    }

    const onClickButtonPlay = async () => {
        if (!playActive) await refAudio.current.play();
        else await refAudio.current.pause();

        setPlayActive(!playActive)
    }

    const onClickDownloadCall = async () => {
        setLoadingDownload(true)
        await downloadCall(item)
        setModalDownload(false)
        setLoadingDownload(false)
    }

    const onClickSubmitNotes = () => {

        if (note.call_id) {

            petition_patch("updateNote", { id, callnote_id: note.pk, data: { call_id: note.call_id, notes: note.notes } })
                .then(result => toastr.success("Action completed", "Success"))
                .catch(err => toastr.error("Action failed", "Error"))
        }
        else {
            petition_post("updateNote", { id, data: { call_id: item.result[0].interaction_id, notes: note.notes } })
                .then(result => toastr.success("Action completed", "Success"))
                .catch(err => toastr.error("Action failed", "Error"))
        }

    }




    const onChangeTime = (e) => {

        setTime({ timeProgress: (e.target.currentTime * 100) / parseInt(item.durationReplay), timeSecond: new Date(e.target.currentTime * 1000).toISOString().substr(e.target.currentTime < 3600 ? 14 : 11, e.target.currentTime < 3600 ? 5 : 8) });
        if (refAudio.current && e.target.currentTime === refAudio.current.duration) {
            setTime({ timeProgress: 0, timeSecond: 0 });
            setPlayActive(false)
        }
    }

    const onChangeNotes = (e) => { setNote({ ...note, notes: e.target.value }) }






    return { onClickSubmitNotes, onChangeNotes, note, findRecording, contactInfo, time, playActive, onChangeTime, refAudio, history, getModalsControllers, onClickSendMessage, onClickOpenModalDownload, getUrlVoiceAudio, onClickContacts, onClickButtonPlay, onChangeTime }
}
