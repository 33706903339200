import React, { useEffect, useState } from "react";

import { Container, Card, Table, Spinner } from "reactstrap";

import petition_get from "../../../petitions/petition_get";
import petition_post from "../../../petitions/petition_post";
import petition_delete from "../../../petitions/petition_delete";

import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import GetAppIcon from "@material-ui/icons/GetApp";

import ButtonHover from "../../../../components/utils/ButtonHover";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import Tools from "./Tools/Tools";
import toastr from "toastr";

import styled from "styled-components";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import ModalUtilityEdit from "./ModalUtility/ModalUtilityEdit";

const ContainerPagination = styled.div`
  padding: 20px;
  margin: 0;

  .btn-page {
    border: none;
    background: rgba(255, 255, 255, 0.1);
    color: #a6b0cf;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    margin: 0 5px;
    padding: 0;
    svg {
      color: #a6b0cf;
    }
    &:focus {
      outline: none;
    }
  }
  .active {
    background: #536be1;
  }

  @media (max-width: 768px) {
    padding: 0;
    .container-pages {
      display: flex;
      justify-content: center;
    }
  }
`;
const Pages = ({ data, setPagination, pagination, cantPagination }) => {
  let page = [];

  for (let i = 0; i < Math.trunc(data); i++) {
    page.push(i);
  }

  const onClickPage = (index) => {
    index = (index + 1) * cantPagination;
    setPagination(index);
  };
  return (
    <>
      {page.map((pag, index) => {
        if (
          (index + 1 >= pagination / cantPagination - 2 &&
            index + 1 <= pagination / cantPagination) ||
          (index + 1 <= pagination / cantPagination + 2 &&
            index + 1 >= pagination / cantPagination)
        ) {
          return (
            <button
              className={`btn-page ${pagination / cantPagination === index + 1 && "active"
                } `}
              onClick={() => {
                onClickPage(index);
              }}
              key={index}
            >
              {" "}
              {index + 1}{" "}
            </button>
          );
        }
      })}
    </>
  );
};

export default function Queues() {
  toastr.options = {
    showDuration: 300,
  };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const cantPagination = 10;
  const [pagination, setPagination] = useState(cantPagination);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [dataDescription, setDataDescription] = useState([]);
  const [listUsers, setListUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [reload, setReload] = useState(0);
  const [errorAdd, setErrorAdd] = useState(null);

  useEffect(() => {
    const petitions = () => {
      petition_get("transferProgress", { id })
        .then(({ data: result }) => {
          console.log(result);
          setData(result.result);
          setDataFilter(result.result);
          setLoading(true);
        })
        .catch((error) => {
          console.log(error.response);
        });
    };

    petitions();
  }, []);

  useEffect(() => {
    const newData = data.filter((element) => {
      if (element.metadata.did.includes(searchInput)) return element;
    });

    setDataFilter(newData);
  }, [searchInput]);

  const onChangeSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const onClickNext = () => {
    if (pagination < data.length) {
      setPagination(pagination + cantPagination);
    }
  };

  const onClickBack = () => {
    if (pagination > cantPagination) {
      setPagination(pagination - cantPagination);
    }
  };

  return (
    <>
      <ModalUtilityEdit modal={modalEdit} setModal={setModalEdit} />
      <div className="page-content">
        <Container fluid>
          <div
            className="d-flex"
            style={{ alignItems: "center", paddingBottom: "2.5rem" }}
          >
            <h3 style={{ margin: "0" }}>Phone Numbers</h3>
            <span>
              <DoubleArrowIcon className="icon-arrow" />
            </span>
            <h4
              style={{
                color: "#a6b0cf",
                fontWeight: "400",
                margin: "0",
                fontSize: "15px",
                paddingTop: "7px",
              }}
            >
              Transfers In Progress
            </h4>
          </div>
        </Container>
        <Tools
          onChangeSearch={onChangeSearch}
          searchInput={searchInput}
          setModalAdd={setModalAdd}
        />
        {loading ? (
          <>
            <Card body>
              <div className="table-responsive">
                <Table hover className="table mb-0">
                  <thead className="thead-light">
                    <tr>

                      <th style={{ textAlign: "center" }}>Did </th>
                      <th style={{ textAlign: "center" }}>status </th>
                      <th style={{ textAlign: "center" }}>FOC Date </th>

                      <th style={{ textAlign: "center" }}>Details</th>
                    </tr>
                  </thead>

                  <tbody>
                    {dataFilter.map((element, i) => {
                      if (i < pagination && i >= pagination - cantPagination) {
                        return (
                          <tr key={i}>

                            <th style={{ textAlign: "center" }}>
                              {element.metadata.did}
                            </th>

                            <th style={{ textAlign: "center" }}>
                              {element.provision_result}
                            </th>
                            <th style={{ textAlign: "center" }}>
                              {element.metadata.foc_date || ""}
                            </th>
                            <th
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <ButtonHover
                                style={{ margin: "0" }}
                                onClick={() => {
                                  setModalEdit(element);
                                }}
                              >
                                <i className="bx bx-detail"></i>

                              </ButtonHover>
                            </th>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </Table>
                <ContainerPagination>
                  {dataFilter && dataFilter.length > cantPagination && (
                    <div className="col-12 container-pages">
                      <button className="btn-page pl-2" onClick={onClickBack}>
                        {" "}
                        <ArrowBackIosIcon />{" "}
                      </button>
                      <Pages
                        pagination={pagination}
                        setPagination={setPagination}
                        cantPagination={cantPagination}
                        data={
                          (dataFilter.length / cantPagination) % 1 === 0
                            ? dataFilter.length / cantPagination
                            : dataFilter.length / cantPagination + 1
                        }
                      />
                      <button className="btn-page" onClick={onClickNext}>
                        {" "}
                        <ArrowForwardIosIcon />
                      </button>
                    </div>
                  )}
                </ContainerPagination>
              </div>
            </Card>
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Spinner
              animation="grow"
              style={{
                margin: "10vh auto",
                height: "10vh",
                width: "10vh",
              }}
            />{" "}
          </div>
        )}{" "}
      </div>{" "}
    </>
  );
}
