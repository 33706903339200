import React, { useState, useEffect } from "react";
import CallIcon from "@material-ui/icons/Call";
import BackspaceIcon from "@material-ui/icons/Backspace";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import CallEndIcon from "@material-ui/icons/CallEnd";
import DialpadIcon from '@material-ui/icons/Dialpad';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import AddIcon from '@material-ui/icons/Add';
import ringtonePress from "../../../../../../assets/audio/press-number.mp3"
export default function OutgoindCall({
  call,
  endCall,
  calling,
  setCalling,
  silence,
  unsilence,
  mute,
  unMute,
  transferCall,
  onTransfer,
  setOnTransfer,
  holdCall,
  unholdCall,
  switch: switchOne,
  setKeyboardNumber,
  keyboardNumber,
  activePhone,
  setActivePhone,
  setModal,
  actualSessions,
  actualSessionSelected,
  users,
  time,
  timeoutReload
}) {

  const Numbers = [
    { number: "1", text: "/" },
    { number: "2", text: "A B C" },
    { number: "3", text: "D E F" },
    { number: "4", text: "G H I" },
    { number: "5", text: "J K L" },
    { number: "6", text: "M N O" },
    { number: "7", text: "P Q R S" },
    { number: "8", text: "T U V" },
    { number: "9", text: "W X Y Z" },
    { number: "*", text: " " },
    { number: "0", text: " " },
    { number: "#", text: " " },
  ];

  const [number, setNumber] = useState("");
  const [volume, setVolume] = useState(true);
  const [mic, setMic] = useState(true);
  const [musicOnHold, setMusicOnHold] = useState(false)
  const [keyboard, setKeyboard] = useState(false)
  const [conference, setConference] = useState(false)
  const [pressPhone] = useState(new Audio(ringtonePress))

  const [keyNumberSignal, setKeyNumberSignal] = useState("")
  const [oldNumber, setOldNumber] = useState("")




  const onClickPanel = (e) => {
    if (!switchOne) return
    if (e !== " ") return setNumber(number + e);
  };

  const onClickDelete = () => {
    if (!switchOne) return
    setNumber(number.substring(0, number.length - 1));
  };

  useEffect(() => {
    if (calling === 1)
      setKeyboard(false)
  }, [calling])


  useEffect(() => {
    //Get Param number 
    const params = new URL(document.location).searchParams;
    const numberCall = params.get("number");
    if (numberCall) setNumber(parseInt(numberCall))
  }, [])


  const handleButtonCall = () => {
    if (!switchOne || (number === "" && oldNumber === "")) return
    if (onTransfer) {
      setOnTransfer(false);
      transferCall(number === "" ? oldNumber : number);
      setCalling(3)
      setNumber("")
    }
    else {
      setConference(false)
      call(number === "" ? oldNumber : number);
      setOldNumber(number === "" ? oldNumber : number)
      setCalling(2);
    }

    if (number === "") setNumber(oldNumber)
  }


  const returnInfoOfCalls = (sessions) => {

    console.log(sessions)

    if (sessions.length === 0) return ""
    if (sessions.length > 1) return "Conference"

    const { uri } = sessions[0].outgoingRequestMessage.to
    const _displayName = users && users.find(element => element.presence_id === uri.normal.user)

    if (_displayName) return `${_displayName.first_name} ${_displayName.last_name} (${uri.normal.user})`

    return uri.normal.user

  }


  const filterNumber = (value) => {

    let newString = ''
    value.split('').map(element => {
      if (parseInt(element) || element === "0" || element === "+" || element === "*" || element === "#") newString = newString + element
    })


    setNumber(newString)

  }

  console.log("Sessions", actualSessionSelected)

  return (

    <>
      {calling === 1 && (
        <>
          <div className="phone-call_keyboard-number "  >

            <p className="w-100" style={{ overFlow: "auto" }}>  <input
              className="default-field"
              value={number}
              onChange={(e) => { filterNumber(e.target.value) }}
              type="text"
              id="numberField"
            /></p>



            <div onClick={onClickDelete} className="absolute-icon" style={{ right: "2px" }} id={"Backspace"}>
              <BackspaceIcon />
            </div>
          </div>

          <div className="phone-call_keyboard-keys row" >
            {Numbers.map((element, i) => (
              <button
                key={i}
                disabled={!switchOne}
                onClick={() => { if (!switchOne) return; pressPhone.play(); onClickPanel(element.number); }}
                className="phone-call_keyboard-num col-4 p-0 "
                style={{ background: "transparent", border: "none" }}
                id={element.number}
              >
                <div className="number"> {element.number} </div>
                <div
                  className="number-text"
                  style={{ opacity: i === 0 ? "0" : "1" }}
                >
                  {element.text}
                </div>
              </button>
            ))}

            {(onTransfer || conference) && (
              <div style={{ cursor: "pointer", position: "absolute", bottom: "10px", right: "20px" }} onClick={() => {
                if (activePhone) {
                  setModal(true)
                  return setActivePhone(false)
                }
                if (conference) {
                  setConference(false)
                }
                setKeyNumberSignal("")
                setCalling(2)
              }} className="absolute-icon-back">
                <ArrowForwardIcon />
              </div>
            )}

          </div>



          <div className="phone-call_controller " style={{ height: "16%" }}>
            <div
              onClick={handleButtonCall}
              className="phone-call_keyboard-footer call "
              style={{ opacity: switchOne ? "1" : "0.4" }}
            >
              <div className="number">
                <CallIcon />
              </div>
            </div>
          </div>


        </>
      )}
      {calling === 2 && (
        <>
          <div
            className="phone-call_keyboard-number calling"
            style={{ height: "40%" }}
          >
            <p>
              {returnInfoOfCalls(actualSessions)}
            </p>

            <br />
            <p className="calling-time">
              {!actualSessionSelected || time === 0 ? "Calling..." : time}
            </p>
          </div>
          <div
            className="phone-call_keyboard-keys row "
            style={{ height: "45%", position: "relative" }}


          >

            {keyboard && <div onClick={() => { setKeyboard(false) }} style={{ backgroud: "red", cursor: "pointer", position: "absolute", top: "-25px", right: "20px", zIndex: "1000" }}>  <CloseIcon /> </div>}

            {keyboard && <div className="w-100" >

              <p className="w-100" style={{ overFlow: "auto" }}>  <input
                className="default-field"
                value={keyNumberSignal}
                /* onChange={(e) => { filterNumber(e.target.value) }} */
                type="text"
                id="numberField"
              />
              </p>
            </div>}

            {keyboard ?


              Numbers.map((element, i) => (
                <div
                  key={i}
                  onClick={() => {
                    setKeyboardNumber(parseInt(element.number))
                    setKeyNumberSignal(`${keyNumberSignal}${element.number}`)
                    pressPhone.play()
                  }}
                  className="phone-call_keyboard-num col-4 "
                  style={{ height: "25px" }}
                >
                  <div className="number"> {element.number} </div>
                  <div
                    className="number-text"
                    style={{ opacity: i === 0 ? "0" : "1" }}
                  >
                  </div>
                </div>
              ))

              : <>
                <div onClick={() => {
                  if (musicOnHold) return
                  if (mic) {
                    setMic(false);
                    return mute(true);
                  }
                  setMic(true);
                  unMute(true);
                }}
                  className="phone-call_keyboard-num col-4 " style={{ opacity: musicOnHold ? "0.5" : "1" }}>
                  <div

                    className="number"
                  >
                    {mic ? <MicIcon /> : <MicOffIcon />}
                  </div>
                  <span>Silence</span>
                </div>
                {actualSessions.length <= 1 && <div
                  onClick={() => {
                    if (musicOnHold) return
                    if (volume) {
                      setVolume(false);
                      return silence(true);
                    }
                    setVolume(true);
                    unsilence(true);
                  }}
                  className="phone-call_keyboard-num col-4 "
                  style={{ opacity: musicOnHold ? "0.5" : "1" }}
                >
                  <div className="number">
                    {volume ? <VolumeUpIcon /> : <VolumeOffIcon />}
                  </div>
                  <span>Volume</span>
                </div>}

                {actualSessions.length <= 1 && <div
                  onClick={() => {

                    if (musicOnHold) {
                      unholdCall(true);
                    } else {
                      holdCall(true);
                    }
                    setMusicOnHold(!musicOnHold);
                  }}
                  className="phone-call_keyboard-num col-4 "
                >
                  <div className="number">
                    {musicOnHold ? (
                      <i className="mdi mdi-music"></i>
                    ) : (
                      <i className="mdi mdi-music-off"></i>
                    )}
                  </div>
                  <span>{musicOnHold ? "On Hold" : "Hold"}</span>
                </div>}

                {actualSessions.length <= 1 && <div
                  onClick={() => {
                    if (musicOnHold) return
                    setKeyboard(!keyboard)
                  }}
                  className="phone-call_keyboard-num col-4 "
                  style={{ opacity: musicOnHold ? "0.5" : "1" }}
                >
                  <div className="number">
                    < DialpadIcon />
                  </div>
                  <span>Keyboard</span>
                </div>}


                <div
                  onClick={() => {
                    if (musicOnHold) return
                    setCalling(1);
                    setNumber("");
                    setConference(true)
                  }}
                  className="phone-call_keyboard-num col-4 "
                  style={{ opacity: musicOnHold ? "0.5" : "1" }}
                >
                  <div className="number">
                    <AddIcon />
                  </div>
                  <span>Add</span>
                </div>

                {actualSessions.length <= 1 && <div
                  onClick={() => {
                    if (musicOnHold) return
                    setCalling(1);
                    setNumber("");
                    setOnTransfer(true);
                  }}
                  className="phone-call_keyboard-num col-4 "
                  style={{ opacity: musicOnHold ? "0.5" : "1" }}
                >
                  <div className="number">
                    <i className="bx bx-transfer-alt"></i>
                  </div>
                  <span>Transfer</span>
                </div>}


              </>}

          </div>

          <div className="phone-call_controller ">
            <div
              onClick={() => {
                setCalling(3);
                endCall(actualSessionSelected ? actualSessionSelected._id : actualSessions[0]._id);
              }}
              className="phone-call_keyboard-footer close "
            >
              <div className="number">
                <CallEndIcon />
              </div>
            </div>
          </div>
        </>
      )}
      {calling === 3 && (
        <>
          <div
            className="phone-call_keyboard-number calling"
            style={{ height: "40%" }}
          >
            <p>Call Ended</p>
            <br />
            <p className="calling-time">
              {time}
            </p>
          </div>
          <div
            className="phone-call_keyboard-number calling"
            style={{ height: "45%" }}
          ></div>

          <div className="phone-call_controller ">
            <div
              onClick={() => {
                setCalling(1);
                setNumber("");
                if (timeoutReload) clearInterval(timeoutReload)
              }}
              className="phone-call_keyboard-footer close "
            >
              <div
                style={{ fontSize: "13px", color: "white", fontWeight: 500 }}
              >
                Close
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
