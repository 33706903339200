import React, { useState, useEffect } from "react";
import profileImg from "../../../../../assets/images/profile-img.png";
import { Button, Modal, ModalBody, Row, Col, Spinner } from "reactstrap";

export default function ModalUtility({
  modal,
  setModal,
  deleteVoicemail,
  message,

}) {



  const [spinner, setSpinner] = useState(false);


  const onClickDelete = async () => {
    setSpinner(true)
    await deleteVoicemail(modal);
    setSpinner(false)
    setModal(false)

  }

  return (
    <Modal centered isOpen={!modal ? false : true} className="info ">
      <ModalBody>
        <div
          style={{
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          {!spinner && <h3>{message}</h3>}
          {spinner && (
            <Spinner
              animation="grow"
              style={{
                marginTop: "10vh",
                marginBottom: "10vh",
                height: "10vh",
                width: "10vh",
              }}
            />
          )}
        </div>
        {!spinner && (
          <div style={{ textAlign: "center" }}>
            <Button
              style={{ margin: "1rem" }}
              outline
              color="secondary"
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </Button>
            {message !== "error" && (
              <Button
                style={{ margin: "1rem" }}
                color="danger"
                onClick={onClickDelete}
              >
                Delete
              </Button>
            )}
          </div>
        )}
      </ModalBody>
    </Modal>
  );
}
