import styled, { keyframes } from "styled-components";

const animationKey = keyframes`
  
   from{
    
    backdrop-filter: blur(4px);
    p{
        margin-top: 0 
    }
     
   }
   to{
 p{
        margin-top: 15rem
    }
    backdrop-filter: blur(0);
     
   }

`;

const ServiceStyled = styled.div`
  position: relative;
  width: "auto";
  margin: 2rem;
  .glass-image {
    position: absolute;
    left: 0;
    right: 0;
    filter: blur(10px);
    img {
      top: 0;
      left: 0;
      border-radius: 1.5rem;
      width: 170px;
      height: 170px;
    }
  }
  .data {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 170px;
    width: 170px;
    border-radius: 1.5rem;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    transition: 1s all;
    overflow: hidden;
    @media (max-width: 568px) {
      box-shadow: none;
    }
    .get-out {
      border: 1px solid red;
      position: absolute;
      top: 1rem;
      left: -1.5rem;
      width: 100px;
      line-height: 20px;
      transform: rotate(-45deg);
      background: #dc3545;
      color: white;
      &:hover {
        color: white;
      }
    }
    p {
      transition: 1s all;
      margin-top: 0;
      margin: 0;
      color: white;
      span {
        font-size: 1rem;
      }
    }
    &:hover {
      transition: 1s all;
      backdrop-filter: none;
      p {
        transition: 1s all;
        margin-top: 9.6rem;
        color: white;
      }
    }
  }
`;

export { ServiceStyled };
