import React, {useEffect, useState} from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CustomInput,
  Spinner,
  Button,
} from "reactstrap";
import Switch from "react-switch";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import ModalUtility from "./ModalUtility/ModalUtility";
import petition_get from "../../../petitions/petition_get";
import petition_post from "../../../petitions/petition_post";
import ReplayIcon from "@material-ui/icons/Replay";
import toastr from "toastr";
import ButtonHover from "../../../../components/utils/ButtonHover";
export default function MusicOnHold({pkPhone}) {
  toastr.options = {
    showDuration: 300,
  };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const pk = pkPhone || params.get("pk");
  const json = require("../../../../config.json");
  const installedPk = params.get("installedPk");
  const urlBasePage = json.prod
    ? "https://apps.itpvoice.net/prod/"
    : "https://apps.itpvoice.net/dev/";
  const token = JSON.parse(localStorage.getItem("authUser")).access_token;
  const [loading, setLoading] = useState(false);
  const [basePageUrl, setBasePageUrl] = useState(null);
  const [data, setData] = useState(null);
  const [dataCopy, setDataCopy] = useState(null);

  useEffect(() => {
    petition_get("installedApp", {id: id})
      .then(({data: result}) => {
        setLoading(false);
        setData(result.result.model_list);
        setDataCopy(result.result.model_list);

        const filterApp = result.result.model_list.find(
          (element) => element.app.pk === parseInt(pk)
        );

        petition_get("voiceAppPages", {id: id, voice_id: pk})
          .then(({data: result}) => {
            setBasePageUrl(
              urlBasePage +
                result.result.app_pages[0].pk +
                `?api_key=${filterApp.api_key.api_key}`
            );
            setLoading(true);
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
      })
      .catch((error) => {
        setLoading(false);
        console.log("error");
      });
  }, [pk]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div
            className="d-flex"
            style={{alignItems: "center", paddingBottom: "2.5rem"}}
          >
            <h3 style={{margin: "0"}}>App</h3>
            <span>
              <DoubleArrowIcon className="icon-arrow" />
            </span>
            <h4
              style={{
                color: "#a6b0cf",
                fontWeight: "400",
                margin: "0",
                fontSize: "15px",
                paddingTop: "7px",
              }}
            >
              App Store
            </h4>
          </div>
        </Container>

        {loading ? (
          <iframe
            src={basePageUrl + `&id=${id}` + "&token=" + token + "&installedPk=" + installedPk}
            style={{width: "100%", height: "100vh", border: "none"}}
          ></iframe>
        ) : (
          <div style={{textAlign: "center"}}>
            <Spinner
              animation="grow"
              style={{
                margin: "10vh auto",
                height: "10vh",
                width: "10vh",
              }}
            />{" "}
          </div>
        )}
      </div>
    </>
  );
}
