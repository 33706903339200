import React, { useState, useEffect } from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  FormGroup,
  Label,
  Badge,
  CustomInput,
  Collapse,
  Spinner,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import toastr from "toastr";
import Switch from "react-switch";
import petition_get from "../../../../petitions/petition_get";
import petition_patch from "../../../../petitions/petition_patch";
import { Multiselect } from "multiselect-react-dropdown";
import ContentCopyIcon from '@material-ui/icons/FileCopy';

export default function ModalUtility({
  modal,
  setModal,
  modalType,
  deviceElement,
  id,
  setDeviceElement,
  accountDetails,
  allNumbers,
}) {
  toastr.options = {
    showDuration: 300,
  };

  console.log("numbers", allNumbers)
  const [form, setForm] = useState({
    make: "",
    model: "",
    ignore_completed_elsewhere: false,
    suppress_unregister_notifications: false,
    encrypt: true,
    number: "",
    name: "",
    switchNumber: "",
  });

  const [cover, setCover] = useState(false);
  const [data, setData] = useState({});
  const [collapse, setCollapse] = useState(false);
  const [loading, setloading] = useState(false);
  const [loadingSave, setloadingSave] = useState(false);
  const [customActiveTab, setCustomActiveTab] = useState("1");
  const [defaultCallerId, setDefaultCallerId] = useState(null);



  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  }

  const [codecs, setCodecs] = useState([
    "G7221@32000h",
    "G7221@16000h",
    "G722",
    "PCMU",
    "PCMA",
    "G729",
    "GSM",
    "G722_16",
    "G722_32",
  ]);
  const [codecsVideo, setCodecsVideo] = useState([
    "H261",
    "H263",
    "H264",
    "VP8",
  ]);

  useEffect(() => { }, []);

  const onChangeForm = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeFormNumber = (e) => {
    setForm({
      ...form,
      ["number"]: e[0] ? e[0] : "",
    });
  };

  const onSubmitForm = (e) => {
    let data;
    if (customActiveTab === "1") {
      data = {
        make: form.make,
        model: form.model,
        ignore_completed_elsewhere: false,
        suppress_unregister_notifications: false,
        encrypt: true,
      };
    } else {
      if (!form.switchNumber) {
        data = {
          caller_id: {},
        };
      } else {
        data = {
          caller_id: {
            external: {
              name: !form.switchNumber
                ? defaultCallerId.external.name
                : form.name,
              number: !form.switchNumber
                ? defaultCallerId.external.number
                : form.number,
            },
          },
        };
      }
    }

    setloadingSave(true);
    petition_patch("deviceEdit", {
      id: id,
      device_id: deviceElement.id,
      data,
    })
      .then(({ data: result }) => {
        toastr.success("Action completed", "Success");

        setloadingSave(false);
        /* setModal(false);
        resetForm(); 
        setDeviceElement(false);*/
      })
      .catch((error) => {
        console.log(error);
        setloadingSave(false);
        toastr.success("Action completed", "Success");
      });
  };

  const resetForm = () => {
    setForm({
      make: "",
      model: "",
      address: "",
      video: "",
      group: "",
      codecs: "",
      mac: "",
    });
  };

  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  const petitions = async () => {
    if (deviceElement.id) {
      let defaultCallerIdTem = null;
      setloading(false);
      setCustomActiveTab("1");

      await petition_get("defaultCallerID", {
        id: id,
        deviceId: deviceElement.id,
      })
        .then(({ data: result }) => {
          setDefaultCallerId(result.result.data.caller_id);
          defaultCallerIdTem = result.result.data.caller_id;
        })
        .catch((error) => {
          console.log(error);
        });
      await petition_get("device", { id: id, deviceId: deviceElement.id })
        .then(({ data: result }) => {
          const accountName = JSON.parse(localStorage.getItem("authUser"))
            .account.name;
          setloading(true);
          setData(result.result);
          setForm({
            make: result.result.make,
            model: result.result.model,
            ignore_completed_elsewhere:
              result.result.ignore_completed_elsewhere,
            suppress_unregister_notifications:
              result.result.suppress_unregister_notifications,
            encrypt: result.result.media.encryption.enforce_security,
            number:
              result.result.caller_id && result.result.caller_id.external
                ? result.result.caller_id.external.number
                : "",
            name:
              result.result.caller_id && result.result.caller_id.external
                ? result.result.caller_id.external.name
                : "",
            switchNumber:
              result.result.caller_id && result.result.caller_id.external
                ? true
                : false,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    petitions();
  }, [deviceElement]);



  const handleCopyClip = (value) => {

    toastr.success("Text Copy", "Success");
    let aux = document.createElement("input");
    aux.setAttribute("value", value);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux)
  }

  return (
    <Modal isOpen={modal} className="info">
      <ModalHeader className="bg-soft-primary">
        {" "}
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary">Edit Device</h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        {!cover ? (
          <>
            {loading ? (
              <>
                <Nav
                  tabs
                  className="nav-tabs-custom  flex-column flex-md-row  text-center  "
                >
                  <NavItem className="pt-3 pt-md-0">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleCustom("1");
                      }}
                    >
                      <span>Basic Information</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="pt-3 pt-md-0">
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleCustom("2");
                      }}
                    >
                      <span>Caller ID Settings</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={customActiveTab}>
                  <TabPane tabId="1" className="p-3">
                    <>
                      {" "}
                      <br />
                      <Row>
                        <Col
                          sm="12"
                          className="mb-sm-2 mb-lg-0"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label
                            htmlFor="make"
                            style={{ paddingBottom: "0.5rem", margin: "0" }}
                          >
                            Make
                          </label>
                        </Col>
                        <Col sm="12">
                          <input
                            onChange={onChangeForm}
                            value={form.make}
                            name="make"
                            id="make"
                            type="text"
                            className="form-control"
                          />
                        </Col>
                      </Row>
                      <br />{" "}
                      <Row>
                        <Col
                          sm="12"
                          className="mb-sm-2 mb-lg-0"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label
                            htmlFor="model"
                            style={{ paddingBottom: "0.5rem", margin: "0" }}
                          >
                            Model
                          </label>
                        </Col>
                        <Col sm="12">
                          <input
                            onChange={onChangeForm}
                            value={form.model}
                            name="model"
                            id="model"
                            type="text"
                            className="form-control"
                          />
                        </Col>
                      </Row>
                      <br />{" "}
                      <Row>
                        <Col
                          sm="12"
                          className="mb-sm-2 mb-lg-0"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <label
                            htmlFor="address"
                            style={{ paddingBottom: "0.5rem", margin: "0" }}
                          >
                            MAC Address
                          </label>
                        </Col>
                        <Col sm="12">
                          <input
                            onChange={onChangeForm}
                            value={form.address}
                            name="address"
                            id="address"
                            type="text"
                            className="form-control"
                          />
                        </Col>
                      </Row>
                      <br />
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          paddingTop: "1rem",
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setCollapse(!collapse);
                        }}
                      >
                        <p>
                          Advanced Settings{" "}
                          <Badge
                            style={{
                              padding: "0.1rem 0.3rem",
                              marginLeft: "1rem",
                            }}
                            color="danger"
                          >
                            !
                          </Badge>{" "}
                        </p>{" "}
                        <ArrowDropDownIcon
                          style={{ position: "absolute", right: "0" }}
                        />
                      </div>
                      <br />
                      <Collapse isOpen={collapse}>
                        <Row className="mt-2">
                          <Col
                            sm="12"
                            className="mb-sm-2 mb-lg-0"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label
                              htmlFor="video"
                              style={{ paddingBottom: "0.5rem", margin: "0" }}
                            >
                              Audio Codecs Allowed
                            </label>
                          </Col>
                          <Col xl="12">
                            <Multiselect
                              placeholder="Start Typing..."

                              options={codecs}
                              selectedValues={[
                                "G7221@32000h",
                                "G7221@16000h",
                                "G722",
                                "PCMU",
                              ]}
                              isObject={false}
                            />
                          </Col>
                        </Row>
                        <br />{" "}
                        <Row>
                          <Col
                            sm="12"
                            className="mb-sm-2 mb-lg-0"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <label
                              htmlFor="video"
                              style={{ paddingBottom: "0.5rem", margin: "0" }}
                            >
                              Video Codecs Allowed
                            </label>
                          </Col>
                          <Col sm="12">
                            <Multiselect
                              placeholder="Start Typing..."

                              options={codecsVideo}
                              isObject={false}
                            />
                          </Col>
                        </Row>
                        <br />
                        <Row className="mt-2">
                          <Col xl="12">
                            <FormGroup>
                              <Label for="group">Group</Label>
                              <CustomInput
                                onChange={onChangeForm}
                                type="select"
                                id="group"
                                name="group"
                              >
                                <option value="">Select</option>
                                <option>Value 1</option>
                                <option>Value 2</option>
                                <option>Value 3</option>
                                <option>Value 4</option>
                                <option>Value 5</option>
                              </CustomInput>
                            </FormGroup>
                          </Col>
                        </Row>
                        <br />{" "}
                        <Row className="pt-4 pl-3 pb-2">
                          <Col xl="2">
                            <Switch
                              uncheckedIcon={<Offsymbol />}
                              className="mr-1"
                              checkedIcon={<OnSymbol />}
                              onColor="#626ed4"
                              name="ignore_completed_elsewhere"
                              onChange={() => {
                                setForm({
                                  ...form,
                                  ignore_completed_elsewhere: !form.ignore_completed_elsewhere,
                                });
                              }}
                              checked={form.ignore_completed_elsewhere}
                            />
                          </Col>
                          <Col xl="8" className="mt-1">
                            <p>Ignore Completed Elsewhere</p>
                          </Col>
                        </Row>{" "}
                        <Row className="pl-3 pb-2">
                          <Col xl="2">
                            <Switch
                              uncheckedIcon={<Offsymbol />}
                              className="mr-1"
                              checkedIcon={<OnSymbol />}
                              onColor="#626ed4"
                              name="suppress_unregister_notifications"
                              onChange={() => {
                                setForm({
                                  ...form,
                                  suppress_unregister_notifications: !form.suppress_unregister_notifications,
                                });
                              }}
                              checked={form.suppress_unregister_notifications}
                            />
                          </Col>
                          <Col xl="8" className="mt-1">
                            <p>Notify when unregistered</p>
                          </Col>
                        </Row>{" "}
                        <Row className="pl-3 pb-2">
                          <Col xl="2">
                            <Switch
                              uncheckedIcon={<Offsymbol />}
                              className="mr-1"
                              checkedIcon={<OnSymbol />}
                              onColor="#626ed4"
                              name="encrypt"
                              onChange={() => {
                                setForm({
                                  ...form,
                                  encrypt: !form.encrypt,
                                });
                              }}
                              checked={form.encrypt}
                            />
                          </Col>
                          <Col xl="8" className="mt-1">
                            <p>Encrypt Audio</p>
                          </Col>
                        </Row>
                        <Row className="pt-3 pb-2">
                          <Col>
                            <Button color="primary"> Reboot Phone </Button>
                          </Col>
                        </Row>
                        <Row className="pt-1 pb-2">
                          <Col>
                            <Button
                              onClick={() => {
                                setCover(true);
                              }}
                              color="primary"
                            >
                              {" "}
                              View SIP Credentials
                            </Button>
                          </Col>
                        </Row>
                      </Collapse>{" "}
                    </>
                  </TabPane>
                  <TabPane tabId="2" className="p-3">
                    <Row className="pl-3 pb-2">
                      <Col xl="2">
                        <Switch
                          uncheckedIcon={<Offsymbol />}
                          className="mr-1"
                          checkedIcon={<OnSymbol />}
                          onColor="#626ed4"
                          name="encrypt"
                          checked={form.switchNumber}
                          onChange={() => {
                            setForm({
                              ...form,
                              switchNumber: !form.switchNumber,
                            });
                          }}
                        />
                      </Col>
                      <Col xl="8" className="mt-1">
                        <p>Override Caller ID </p>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col
                        sm="12"
                        className="mb-sm-2 mb-lg-0"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <label
                          htmlFor="number"
                          style={{ paddingBottom: "0.5rem", margin: "0" }}
                        >
                          Name
                        </label>
                      </Col>
                      <Col
                        sm="12"
                        className="mb-sm-2 mb-lg-0"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <InputGroup>
                          <input
                            disabled={!form.switchNumber}
                            id="name"
                            type="text"
                            value={
                              !form.switchNumber
                                ? defaultCallerId.external.name
                                : form.name
                            }
                            // value={form.name}
                            onChange={onChangeForm}
                            name="name"
                            className="form-control"
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col
                        sm="12"
                        className="mb-sm-2 mb-lg-0"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <label
                          htmlFor="number"
                          style={{ paddingBottom: "0.5rem", margin: "0" }}
                        >
                          Numbers
                        </label>
                      </Col>
                      {/* <Col
                        sm="12"
                        className="mb-sm-2 mb-lg-0"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <InputGroup>
                          <input
                            disabled={!form.switchNumber}
                            id="number"
                            type="select"
                            value={
                              !form.switchNumber
                                ? defaultCallerId.external.number
                                : form.number
                            }
                            // value={form.number}
                            onChange={onChangeForm}
                            name="number"
                            className="form-control"
                            list="datalistOptions"
                          />
                          <datalist id="datalistOptions">
                            {allNumbers.length === 0 ? (
                              <option  value={null}>
                                {"No Numbers Available"}
                              </option>
                            ) : null}
                            {allNumbers.map((element, i) => (
                              <option key={i} value={element}>
                                {element}
                              </option>
                            ))}
                          </datalist>
                        </InputGroup>
                      </Col> */}

                      <Col xl="12">
                        {!form.switchNumber ? (
                          <Multiselect
                            // singleSelect={true}
                            options={[defaultCallerId.external.number]}
                            selectedValues={[defaultCallerId.external.number]}
                            isObject={false}
                            // onSelect={onChangeFormNumber}
                            placeholder="Start Typing..."

                          />
                        ) : (
                          <Multiselect
                            // singleSelect={true}
                            options={allNumbers}
                            selectedValues={
                              form.number !== "" ? [form.number] : null
                            }
                            isObject={false}
                            onSelect={onChangeFormNumber}
                            onRemove={onChangeFormNumber}
                            selectionLimit={1}
                            placeholder="Start Typing..."

                          />
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </>
            ) : (
              <div style={{ textAlign: "center" }}>
                <Spinner
                  animation="grow"
                  style={{
                    margin: "10vh auto",
                    height: "10vh",
                    width: "10vh",
                  }}
                />{" "}
              </div>
            )}
          </>
        ) : (
          <>
            {" "}
            <Row className="mt-2">
              <Col xl="12">
                <p>For Advanced use only. Use with caution</p>
                <br />
                <br />
                <p >SIP Username: {data.sip.username} <ContentCopyIcon style={{ fontSize: "15px", cursor: "pointer" }} onClick={() => { handleCopyClip(data.sip.username) }} /> </p>
                <p >SIP Password: {data.sip.password} <ContentCopyIcon style={{ fontSize: "15px", cursor: "pointer" }} onClick={() => { handleCopyClip(data.sip.password) }} /></p>
                <p >SIP Domain: {accountDetails.data.realm} <ContentCopyIcon style={{ fontSize: "15px", cursor: "pointer" }} onClick={() => { handleCopyClip(accountDetails.data.realm) }} /></p>
              </Col>
            </Row>
          </>
        )}
      </ModalBody>{" "}
      <ModalFooter>
        {!cover && (
          <Button
            outline
            disabled={loadingSave}
            color="secondary"
            onClick={() => {
              if (cover) {
                return setCover(false);
              } else {
                resetForm();
                setDeviceElement({});
                setModal(false);
              }
            }}
          >
            Cancel
          </Button>
        )}

        <Button
          color="primary"
          disabled={
            loadingSave ||
            (customActiveTab === "2" && (!form.name || !form.number))
          }
          onClick={() => {
            if (cover) {
              return setCover(false);
            }
            onSubmitForm();
          }}
        >
          {loadingSave && (
            <Spinner
              style={{ marginRight: "0.5em" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {cover ? "Ok" : "Save"}
        </Button>
      </ModalFooter>
    </Modal>
  );
}