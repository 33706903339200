import React, {useState, useEffect} from "react";
import phoneImg from "../../../../../../assets/images/phone-image.svg";
import {
  Modal,
  ModalHeader,
  Row,
  Col,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import DescriptionIcon from "@material-ui/icons/Description";

import CustomTable from "../../../../../../components/utils/CustomTable/CustomTable";
import {data} from "jquery";
export default function DevicesModal({modal, setModal, loading}) {
  const [devicesDetails, setDevicesDetails] = useState(null);
  const [dataDevices, setDataDevices] = useState(null);
  const [dataDetails, setDataDetails] = useState(null);

  const handleClickDetails = (element) => {
    let allTitles = [];
    Object.keys(element).map((element) =>
      allTitles.push({title: element, key: element})
    );

    setDevicesDetails(true);
    setDataDetails({
      title: allTitles,
      content: [{...element}],
    });
  };

  useEffect(() => {
    if (modal) {
      console.log(modal);
      setDataDevices({
        title: [
          {title: "Source Ip", key: "source_ip"},
          {title: "Source Port", key: "source_port"},
          {title: "Contact Ip", key: "contact_ip"},
        ],
        content: [...modal.registered_devices],
        actions: {
          title: "Details",
          content: [
            {
              icon: <DescriptionIcon />,
              handleClick: handleClickDetails,
            },
          ],
        },
      });
    }
  }, [modal]);

  return (
    <Modal
      centered
      isOpen={modal === false ? false : true}
      className="info modal-md"
    >
      <ModalHeader className="bg-soft-primary">
        {" "}
        <Row>
          <Col xs="9">
            <div className="text-primary p-3">
              <h3 className="text-primary">
                Devices {devicesDetails && "Details"}{" "}
              </h3>
            </div>
          </Col>
          <Col xs="3" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        {devicesDetails ? (
          <>
            <div className="p-3">
              {console.log(dataDetails)}
              {dataDetails &&
                dataDetails.title.map((element, i) => {
                  return (
                    <p
                      style={{
                        width: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      key={i}
                    >
                      {element.title} : {dataDetails.content[0][element.key]}{" "}
                    </p>
                  );
                })}
            </div>
          </>
        ) : (
          <>
            {" "}
            {dataDevices && (
              <>
                {dataDevices.content.length === 0 ? (
                  <p style={{fontSize: "1rem", textAlign: "center"}}>
                    You do not have an active device
                  </p>
                ) : (
                  <CustomTable bordered={true} data={dataDevices} />
                )}
              </>
            )}
          </>
        )}
      </ModalBody>{" "}
      <ModalFooter>
        {devicesDetails && (
          <Button
            disabled={loading}
            outline
            color="secondary"
            onClick={() => {
              setDevicesDetails(false);
            }}
          >
            Back
          </Button>
        )}

        <Button
          disabled={loading}
          color="primary"
          onClick={() => {
            setModal(false);
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
