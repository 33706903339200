import React, { useEffect, useState } from "react";

import { Button } from "reactstrap";
import CustomBreadCrum from "../../../../components/utils/CustomBreadCrum/CustomBreadCrum";
import CustomTable from "../../../../components/utils/CustomTable/CustomTable";

import DateRange from "@material-ui/icons/DateRange";
import petition_get from "../../../petitions/petition_get";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import ModalUtilityAdd from "./ModalUtility/ModalUtilityAdd";
import ModalUtilityEdit from "./ModalUtility/ModalUtilityEdit";
import ModalUtilityDelete from "./ModalUtility/ModalUtilityDelete";

export default function TimeSchedules() {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const [loading, setLoading] = useState(false);
  const [reloadData, setReloadData] = useState(true);

  const [modalAdd, setModalAdd] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);

  const [dataTable, setDataTable] = useState(null);

  const [dataTimeDateId, setDataTimeDateId] = useState(null);
  const [dataTimeDate, setDataTimeDate] = useState(null);

  const viewTimeDate = (element) => {
    setLoading(true);
    petition_get("timeGroupsDetails", { id: id, time_group_id: element.id })
      .then(({ data: result }) => {
        setDataTimeDateId(element.id);
        setDataTimeDate({
          title: [
            { title: "Id", key: "id" },
            { title: "name", key: "name" },
          ],
          content: [...result.result.time_dates],
          actions: {
            title: "Actions",
            content: [
              {
                icon: <DeleteIcon />,
                handleClick: modalDeleteButton,
              },
              {
                icon: <EditIcon />,
                handleClick: modalEditButton,
              },
            ],
          },
          search: {
            label: "Search Time Date"
          },
          addButton: {
            label: "Add Time Date",
            handleClick: () => { setModalAdd(true) }
          }
        });
        setLoading(false);
      })
      .catch((error) => { });
  };

  const modalDeleteButton = (element) => {
    setModalDelete(element);
  };

  const modalEditButton = (element) => {
    setModalEdit(element);
  };

  useEffect(() => {
    if (reloadData) {
      setDataTable(null)
      setDataTimeDate(null);
      setDataTimeDateId(null);
      petition_get("timeGroups", { id: id })
        .then(({ data: result }) => {
          setDataTable({
            title: [

              { title: "name", key: "name" },
            ],
            content: [...result.result],
            actions: {
              title: "Actions",
              content: [
                {
                  icon: <DeleteIcon />,
                  handleClick: modalDeleteButton,
                },
                {
                  icon: <EditIcon />,
                  handleClick: modalEditButton,
                },
                {
                  icon: <DateRange />,
                  handleClick: viewTimeDate,
                },
              ],

            },
            search: {
              label: "Search TimeGroup"
            },
            addButton: {
              label: "Add TimeGroup",
              handleClick: () => { setModalAdd(true) }
            }
          });
          setReloadData(false);
        })
        .catch((error) => { });
    }
  }, [reloadData]);
  return (
    <>
      <ModalUtilityEdit
        modal={modalEdit}
        loading={loading}
        setModal={setModalEdit}
        setReloadData={setReloadData}
        dataTimeDate={dataTimeDate}
        dataTimeDateId={dataTimeDateId}
      />
      <ModalUtilityDelete
        modal={modalDelete}
        loading={loading}
        setModal={setModalDelete}
        dataTimeDate={dataTimeDate}
        dataTimeDateId={dataTimeDateId}
        setReloadData={setReloadData}

      />
      <ModalUtilityAdd
        dataTimeDate={dataTimeDate}
        dataTimeDateId={dataTimeDateId}
        modal={modalAdd}
        setModal={setModalAdd}
        loading={loading}
        setReloadData={setReloadData}
      />
      <div className="page-content">
        <CustomBreadCrum title="Your Phone System" subtitle={dataTimeDate ? "Time Date" : "Time Group"} />
        {dataTimeDate &&
          <Button
            onClick={() => { setReloadData(true); }}
            color="danger"
            className="mb-3"
          >
            Back
          </Button>
        }
        {!dataTimeDate ? <CustomTable data={dataTable} /> : <CustomTable data={dataTimeDate} />}
      </div>
    </>
  );
}
