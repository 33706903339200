import React, { useEffect, useState } from "react";
import { get, set } from "lodash";
import { CustomInput, Button } from "reactstrap";
import InfoIcon from '@material-ui/icons/Info';
import ReactTooltip from 'react-tooltip';
import { Multiselect } from "multiselect-react-dropdown";
import petition_get from "../../../../../pages/petitions/petition_get";
import petition_patch from "../../../../../pages/petitions/petition_patch";

export default function EditNodo({ listOfUpdateDevices, setListOfUpdateDevices, listUsers, activeEdit, setActiveEdit, dataCallFlow, timeZone, setDataCallFlow, refChart, deleteNode }) {
  const [form, setForm] = useState({});
  const [finalDataSave, setFinalDataSave] = useState(null);
  const [usersOptions, setUsersOptions] = useState(listUsers.map(element => ({ name: `${element.first_name} ${element.last_name}`, id: element.id })))
  const [firstOptions, setFirstOptions] = useState([])
  const filterTypeNodo = activeEdit.type.split("_");
  const [saveDevice, setSaveDevice] = useState(null)

  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  console.log(form)

  useEffect(() => {

    let finalData;
    if (activeEdit.childrenUrl === "") finalData = dataCallFlow.flow
    else finalData = get(dataCallFlow.flow, activeEdit.childrenUrl)
    setFinalDataSave(finalData);



    switch (filterTypeNodo[0]) {
      case "Time":
        setForm({ timeZone: filterTypeNodo });
        break;
      case "UserCallflow":
        setForm({
          ...finalData.data
        });

        break;
      case "ring":


        let listUsersCopy = []
        listUsers.map(element => {
          const findOne = finalData.data.endpoints.find(elementTwo => elementTwo.id === element.id)
          if (findOne) listUsersCopy.push({ name: `${element.first_name} ${element.last_name}`, id: element.id })
        })

        console.log(finalData)
        setFirstOptions(listUsersCopy)
        setForm({
          name: finalData.data.name,
          strategy: finalData.data.strategy,
          timeout: finalData.data.timeout,
          users: listUsersCopy,
          repeats: finalData.data.repeats,
          ignore_forward: finalData.data.ignore_forward
        });

        break;

      case "externalNumber":


        if (finalData.data.id.split("_").length > 1) {
          let number = finalData.data.id.split("_")[1]

          const { require_keypress, keep_caller_id, timeout } = finalData.data
          setForm({
            number,
            require_keypress,
            keep_caller_id,
            timeout
          })
        }
        else {
          /*  petition_get("getDevicesDetails", { id, deviceId: finalData.data.id })
             .then(({ data: result }) => {
 
 
               setSaveDevice(result.result)
               const { require_keypress, keep_caller_id, timeout } = finalData.data
               setForm({
                 number: result.result.call_forward.number,
                 require_keypress,
                 keep_caller_id,
                 timeout
               })
 
             })
             .catch((error) => {
               console.log(error);
             }); */

          petition_get("getDevicesDetails", { id, deviceId: finalData.data.id })
            .then(({ data: result }) => {


              setSaveDevice(result.result)
              const { require_keypress, keep_caller_id, timeout, number } = finalData.data
              setForm({
                number: number || result.result.call_forward.number,
                require_keypress,
                keep_caller_id,
                timeout
              })

            })
            .catch((error) => {
              console.log(error);
            });
        }


        break;
      default:
        break;
    }
  }, [activeEdit]);

  const onChangeForm = (e) => {
    switch (filterTypeNodo[0]) {
      case "Time":
        setForm({ timeZone: e.target.value });
      case "UserCallflow":
        setForm({ ...form, [e.target.name]: e.target.value });
      case "ring":
        setForm({ ...form, [e.target.name]: e.target.value });

        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    let data;
    switch (filterTypeNodo[0]) {
      case "Time":
        data = { ...finalDataSave.data, timeZone: form.timeZone };
        break;
      case "UserCallflow":
        data = {
          ...finalDataSave.data,
          id: form.flow === "voicemail" ? finalDataSave.data.idCallflow : finalDataSave.data.idUser,
          flow: form.flow,
          timeout: form.timeout,
          can_call_self: form.can_call_self,
        };
        finalDataSave.module = form.flow === "voicemail" ? "callflow" : "user";
        break;
      case "ring":

        let endpoints = []
        form.users.map(element => endpoints.push({ delay: 0, endpoint_type: "user", id: element.id, timeout: 20 }))


        data = {
          ...finalDataSave.data,
          endpoints,
          name: form.name,
          strategy: form.strategy,
          timeout: form.timeout,
          ignore_forward: form.ignore_forward,
          repeats: form.repeats
        };

        console.log(data)

        break;
      case "externalNumber":


        if (finalDataSave.data.id.split("_").length > 1) {


          data = {
            ...finalDataSave.data,
            id: `Create_${form.number}`,
            number: form.number,
            require_keypress: form.require_keypress,
            keep_caller_id: form.keep_caller_id,
            timeout: form.timeout,

          }
        }
        else {

          //Actualizar la data de nodo 
          data = {
            ...finalDataSave.data,
            require_keypress: form.require_keypress,
            keep_caller_id: form.keep_caller_id,
            timeout: form.timeout,
            number: form.number,

          }

          //Guardando la lista de devices que se deben actualizar al guardar el callflow, ya que si se actualiza en el momento puede que el usuario no guarde el callflow
          let copyListOfUpdateDevices = []

          let findFlag = false

          listOfUpdateDevices.map(element => {

            if (element.id === finalDataSave.data.id) {
              findFlag = true
              copyListOfUpdateDevices.push({
                id: element.id,
                call_forward: {
                  ...saveDevice.call_forward,
                  require_keypress: form.require_keypress,
                  keep_caller_id: form.keep_caller_id,
                  enabled: true,
                  number: form.number
                },
              })
            }
            else {
              copyListOfUpdateDevices.push({
                id: element.id,
                call_forward: element.call_forward
              })
            }

          })

          if (!findFlag) copyListOfUpdateDevices.push({
            id: finalDataSave.data.id,
            call_forward: {
              ...saveDevice.call_forward,
              require_keypress: form.require_keypress,
              keep_caller_id: form.keep_caller_id,
              enabled: true,
              number: form.number
            },
          })


          setListOfUpdateDevices(copyListOfUpdateDevices)
        }


        //Actualizando el nodo
        const copyNodes = refChart.current.state;
        copyNodes.nodes[finalDataSave.data.nodeId].type = `externalNumber_${form.number}`

        refChart.current.setState(copyNodes);
        break;
      default:
        break;
    }
    const dataCallFlowCopy = dataCallFlow;




    if (activeEdit.childrenUrl === "") {
      dataCallFlowCopy.flow = {
        children: finalDataSave.children,
        data,
        module: finalDataSave.module,
      };
    } else {
      set(dataCallFlowCopy.flow, activeEdit.childrenUrl, {
        children: finalDataSave.children,
        data,
        module: finalDataSave.module,
      });
    }


    setDataCallFlow(dataCallFlowCopy)
    setActiveEdit(false)





    if (form.flow && finalDataSave) {
      const copyNodes = refChart.current.state;
      copyNodes.nodes[finalDataSave.data.nodeId].flowSelected = data.flow
      refChart.current.setState(copyNodes);

      let nodeSelected = copyNodes.nodes[finalDataSave.data.nodeId]
      if (form.flow === "voicemail" && finalDataSave.data.flow === "custom") {
        const newArrayLinks = Object.keys(copyNodes.links);
        newArrayLinks.find((element) => {
          if (copyNodes.links[element].from.nodeId === nodeSelected.id) {
            deleteNode(copyNodes.nodes[copyNodes.links[element].to.nodeId])
          }
        });
      }
    }



  };

  const handleChangeCheck = (e) => {
    setForm({ ...form, users: e })
  }

  return (
    <>
      <p style={{ fontSize: "1rem", textAlign: "center" }}>Edit Node</p>

      {filterTypeNodo[0] === "Time" && (
        <>
          <select
            onChange={onChangeForm}
            className="form-control"
            list="datalistOptions"
            id="exampleDataList"
            value={form.timeZone}
            placeholder="Type to search..."
          >
            <option value="default">
              Default
            </option>
            {timeZone.map((element, i) => (
              <option key={i} value={element}>
                {element}
              </option>
            ))}
          </select>
        </>
      )}
      {filterTypeNodo[0] === "UserCallflow" && (
        <>
          <div>
            <div className=" pt-3">
              <label
                className="m-0 w-100 text-left"
                htmlFor="flow"
              >
                If the number is not available:
              </label>

              <select onChange={onChangeForm}
                name="flow"
                value={form.flow}
                id="flow"
                type="number"
                className="form-control" >

                <option value="voicemail">Go to User`s Voicemail</option>
                <option value="custom">Custom option</option>
              </select>

            </div>
            <div className=" pt-3" >
              <label
                className="m-0 w-100 text-left"

                htmlFor="timeout"
              >
                Ring Time:
              </label>
              <input
                onChange={onChangeForm}
                name="timeout"
                value={form.timeout}
                id="timeout"
                type="number"
                className="form-control"
              />
            </div>
            <div className="d-flex pt-3" style={{ alignItems: "center" }}>
              <label className="m-0 pr-2" htmlFor="exampleCustomCheck">
                Allow call themselves:
              </label>
              <CustomInput
                onChange={(e) => {
                  setForm({ ...form, can_call_self: e.target.checked });
                }}
                type="checkbox"
                checked={form.can_call_self}
                className="input-check"
                name=""
                id="exampleCheck1"
              />
            </div>
          </div>
        </>
      )}

      {filterTypeNodo[0] === "externalNumber" &&
        <div >
          <label style={{ textAlign: "left", width: "100%" }} htmlFor="externalNumber">External Number:</label>
          <input
            onChange={(e) => { setForm({ ...form, number: e.target.value }) }}
            className="form-control"
            value={form.number}
            id="externalNumber"
            type="number"
          />
          <div className="pt-4">
            <label style={{ textAlign: "left", width: "100%" }} htmlFor="timeout">Timeout:</label>
            <input
              onChange={(e) => { setForm({ ...form, timeout: e.target.value }) }}
              className="form-control"
              value={form.timeout}
              id="timeout"
              type="number"
            />
          </div>
          <div className="d-flex mt-3">

            <CustomInput
              onChange={(e) => {
                setForm({ ...form, require_keypress: e.target.checked });
              }}
              checked={form.require_keypress}
              type="checkbox"
              className="input-check"
              name=""
              id="check1"
            />
            <label className="m-0 pr-2 d-flex" htmlFor="check1">
              Confirm Call?
              <InfoIcon data-tip data-for='text1' style={{ paddingLeft: "0.5rem", fontSize: "21px" }} />
            </label>
            <ReactTooltip id="text1" >
              <div className="text-left" style={{ fontSize: "10px" }}>
                When receiving a forwarded call, user will
                <br />
                be required to press 1 to answer the call.
                <br />
                This is useful when you are forwarding calls
                <br />
                to a cellphone and want to avoid connecting
                <br />
                to the Cell Phone's voicemail.
              </div>
            </ReactTooltip>

          </div>
          <div className="d-flex mt-3">
            <CustomInput
              onChange={(e) => {
                setForm({ ...form, keep_caller_id: e.target.checked });
              }}
              checked={form.keep_caller_id}
              type="checkbox"
              className="input-check"
              name=""
              id="check2"
            />
            <label className="m-0 d-flex text-left" htmlFor="check2">
              Kepp Caller's Caller ID?
              <InfoIcon data-tip data-for='text2' style={{ paddingLeft: "0.5rem", fontSize: "21px" }} />
            </label>
            <ReactTooltip id="text2" >
              <div className="text-left" style={{ fontSize: "10px" }}>
                - If enabled, this will attempt to show the
                <br />
                Caller's Caller ID on the forwarded phone.
                <br />
                - If disabled, the caller ID displayed
                <br />
                will be the system default
              </div>
            </ReactTooltip>

          </div>
        </div>

      }

      {filterTypeNodo[0] === "ring" && (

        <div>
          <div className=" pt-3" >
            <label className="m-0 pr-2 text-left" htmlFor="name">
              Name:
            </label>
            <input
              onChange={onChangeForm}
              name="name"
              value={form.name}
              id="name"
              type="text"
              className="form-control"
            />
          </div>
          <div className=" pt-3">
            <label
              className="m-0 pr-2 text-left"

              htmlFor="strategy"
            >
              Strategy:
            </label>
            <select
              value={form.strategy}
              onChange={onChangeForm}
              className="form-control"
              name="strategy"
              id="strategy"
            >
              <option value="">---Select One---</option>
              <option value="single">Single</option>
              <option value="simultaneous">Simultaneous</option>
            </select>
          </div>{" "}
          <div className=" pt-3" >
            <label
              className=" text-left m-0 pr-2"

              htmlFor="timeout"
            >
              Number of Seconds To Ring:
            </label>
            <input
              onChange={onChangeForm}
              name="timeout"
              value={form.timeout}
              id="timeout"
              type="number"
              className="form-control"

            />
          </div>
          <div className=" pt-3">
            <label
              className="m-0 pr-2 text-left"

              htmlFor="strategy"
            >
              Ignore Call Forwarding:
            </label>
            <select
              value={form.ignore_forward}
              onChange={onChangeForm}
              className="form-control"
              name="ignore_forward"
              id="ignore_forward"
            >
              <option value={true}>True</option>
              <option value={false}>False</option>

            </select>
          </div>
          <div className=" pt-3">
            <label
              className="m-0 pr-2 text-left"

              htmlFor="repeats"
            >
              Repeats:
            </label>
            <input
              onChange={onChangeForm}
              name="repeats"
              value={form.repeats}
              id="repeats"
              type="number"
              className="form-control"

            />
          </div>
          <div className=" pt-3" >
            <label
              className=" text-left m-0 pr-2"

              htmlFor="repeats"
            >
              Members
            </label>
            <Multiselect
              options={[...usersOptions]}
              onSelect={handleChangeCheck}
              onRemove={handleChangeCheck}
              selectedValues={firstOptions || []}
              displayValue="name"
              placeholder="Start Typing..."
            />
          </div>
        </div>

      )}

      <Button
        onClick={async () => {
          handleSubmit();
        }}
        className=" mt-3 w-100"
        color="primary"
      >
        Save
      </Button>
    </>
  );
}
