import React, {useState} from "react";
import {Spinner, Row, Col, Button, CustomInput} from "reactstrap";
import states from "../Filters/State/config";
import {scroller, Element} from "react-scroll";
export default function ListNumbers({
  listOfNumbers,
  loading,
  setSelectedNumbers,
  cityState,
  npa,
  npaNxx,
  filterNumbers,
  selectedNumbers,
  setActiveSelected,
  pricePerDid
}) {
  const [pagination, setPagination] = useState(10);

  if (!listOfNumbers) return null;

  const onClickCheck = (e) => {
    if (e.target.checked) {
      switch (filterNumbers.type) {
        case "NPA":
          setSelectedNumbers([
            {
              type: filterNumbers.type,
              code: npa.code,
              number: e.target.value,
            },
          ]);

          break;
        case "NPANXX":
          setSelectedNumbers([
            {
              type: filterNumbers.type,
              code: `${npaNxx.npa}${npaNxx.nxx}`,
              number: e.target.value,
            },
          ]);

          break;
        case "City/State":
          const stateInfo = states.find(
            (state) => state.shortcode === cityState.state
          );
          const newLetter = stateInfo.name[0].toUpperCase();
          setSelectedNumbers([
            {
              type: filterNumbers.type,
              code: `${cityState.city} - US/${newLetter}${stateInfo.name.slice(
                1
              )}`,
              number: e.target.value,
            },
          ]);
          break;
        default:
          break;
      }
    }
  };


  const addNumber = () => {
     setActiveSelected([...selectedNumbers]);
            scroller.scrollTo("order", {
              duration: 500,
              smooth: true,
            });
  }


   const color = localStorage.getItem("theme");

  return (
    <div style={{padding: "0 2rem", paddingBottom: "1rem"}}>
      <Row style={{margin: "0", paddingTop: "2rem"}}>
        <Col
          style={{
            padding: "1.5rem",
            borderBottom: "none",
            borderBottomLeftRadius: "0",
            display: "flex",
            alignItems: "center",
            height: "auto",
          }}
          className="col-6 col-lg-3 form-control"
        >
          {" "}
          Numbers Available from ITPSCORP{" "}
        </Col>
      </Row>

      <Row
        className="form-control"
        style={{
          display: "flex",
          height: "auto",
          paddingTop: "2rem ",
          borderTop: "none",
          borderTopLeftRadius: "0",
          margin: "0",
        }}
      >
        {listOfNumbers.map((number, index) => {
          if (pagination === 1000)
            return (
              <Col
                key={index}
                className="col-12 col-sm-6 col-md-4 col-lg-4 xl-3 d-flex"
                style={{justifyContent: "center"}}
              >
                <CustomInput
                  type="checkbox"
                  className="input-check"
                  name=""
                  id={"exampleCustomCheckbox" + index}
                  onChange={onClickCheck}
                  value={number || ""}
                  checked={selectedNumbers.find(
                    (numbers) => numbers.number === number
                  )}
                />
                <p>{number}</p>{" "}
              </Col>
            );
          else if (index <= pagination && index > pagination - 10) {
            return (
              <Col
                key={index}
                className="col-12 col-sm-6 col-md-4 col-lg-4 xl-3 d-flex"
                style={{justifyContent: "center"}}
              >
                <CustomInput
                  type="checkbox"
                  className="input-check"
                  name="number"
                  id={"exampleCustomCheckbox" + index}
                  onChange={onClickCheck}
                  value={number || ""}
                  checked={selectedNumbers.find(
                    (numbers) => numbers.number === number
                  )}
                />

                <p> <label htmlFor={"exampleCustomCheckbox" + index}>
                    {number}
                  </label></p>
              </Col>
            );
          }
        })}
        <Col className="col-12  text-center text-md-left pt-2">
          {pagination > 10 && (
            <Button
              outline
              color="secondary"
              style={{margin: "0 0.5rem", width: "7rem"}}
              onClick={() => {
                setPagination(pagination - 10);
              }}
            >
              Back
            </Button>
          )}{" "}
          <Button
            className="mt-2 mt-md-0"
            outline
            color="secondary"
            onClick={() => {
              if (pagination < 100) return setPagination(pagination + 10);
            }}
            style={{margin: "0 0.5rem", width: "7rem"}}
          >
            Show next 10{" "}
          </Button>{" "}
          <Button
            className="mt-2 mt-md-0"
            outline
            color="secondary"
            onClick={() => {
              if (pagination <= 100) return setPagination(1000);
              else setPagination(10);
            }}
            style={{margin: "0 0.5rem", width: "7rem"}}
          >
            {" "}
            {pagination === 1000 ? "Show 10" : "Show all"}{" "}
          </Button>
        </Col>
      </Row>
      <Element name="order" className="d-flex pt-4 ">
        <Button
          color="primary"
          onClick={() => {
            addNumber();
          }}
          disabled={loading === "add" ? true : false}
        >
          {loading === "add" && listOfNumbers !== null && (
            <Spinner
              className="spinner-submit"
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Add Number
        </Button>
        <p
          style={{
            margin: "0",
            display: "flex",
            alignItems: "center",
            paddingLeft: "10px",
            fontSize: "11px",
          }}
        >
          {" "}
          Selected quantity of 1 exceeds the avilable numbers by 1. A charge of
          ${pricePerDid} each per month will be incurred.{" "}
          <strong>Subtotal: ${pricePerDid}</strong>
        </p>
      </Element>
    </div>
  );
}
