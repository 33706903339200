import React, { useState, useEffect } from 'react'
import ButtonHover from "../../../../../../components/utils/ButtonHover";
import SettingsIcon from "@material-ui/icons/Settings";
import petition_patch from '../../../../../petitions/petition_patch';
import petition_get from '../../../../../petitions/petition_get';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge, Spinner } from "reactstrap";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import toastr from "toastr";
import CheckIcon from '@material-ui/icons/Check';
export default function ProfileCall({ queues, reloadQueues, setModalQueues, deviceId, deviceData, userStatusProfile, audioSettings, reloadDataDevice, setReloadDataDevice }) {

    toastr.options = {
        showDuration: 300,
    };

    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    const [dropDownActive, setDropDownActive] = useState(false)
    const [reloadData, setReloadData] = useState(false)
    const [statusAgent, setStatusAgent] = useState("")
    const [loading, setLoading] = useState(false)
    const [disturb, setDisturb] = useState(false)


    const getColor = (type) => {

        switch (type) {

            case "READY": return "success";
            case "OFFLINE": return "danger";
            case "PAUSE": return "warning";
            case "CALL": return "primary";
            default: break;
        }
    }

    useEffect(() => {

        petition_get("agent-status", { id: id })
            .then(({ data: result }) => {
                setStatusAgent("")
                let value = ""
                switch (result.result) {

                    case "logged_in": value = "READY"; break;
                    case "ready": value = "READY"; break;
                    case "unknown": value = "OFFLINE"; break;
                    case "logged_out": value = "OFFLINE"; break;
                    case "paused": value = "PAUSE"; break;
                    default: break;
                }
                setStatusAgent(value)
                setLoading(false)

            })
            .catch((error) => {
                console.log(error);

            });

        petition_get("callHandling", { id })
            .then(({ data: result }) => { console.log(result); setDisturb(result.result.flow.module === "user" ? true : false) }).catch((error) => { console.log(error) });



    }, [reloadData, reloadQueues])


    const handleClickStatusAgent = async (type) => {
        try {
            let data = { status: type }


            if (type !== "login" && statusAgent !== "READY") return toastr.warning("You cannot switch between inactive states, please first activate the state with 'Ready'", "Warning");
            if (type === "disturb") data.status = "pause"
            else if (type === "login" && statusAgent === "PAUSE") data.status = "resume"

            setLoading(true)
            let save_callFlow;
            await petition_get("callHandling", { id })
                .then(({ data: result }) => save_callFlow = result.result).catch((error) => { throw error });
            if (!deviceData.itpvoice_metadata.save_callFlow) {
                await petition_patch("deviceEdit", { id, device_id: deviceId, data: { itpvoice_metadata: { save_callFlow } } })
                    .then(({ data: result }) => { setReloadDataDevice(!reloadDataDevice) })
                    .catch((error) => { throw error });
            }
            if (type === "disturb" && save_callFlow.flow.module === "user") {
                console.log("prueba2");
                const newCallflow = { ...save_callFlow, flow: save_callFlow.flow.children._ }

                await petition_patch("updateCallflowDisturb", { id, data: newCallflow })
                    .then(({ data: result }) => { })
                    .catch((error) => { throw error });


            } else if (statusAgent === "PAUSE" && save_callFlow.flow.module === "voicemail") {
                console.log("prueba");

                await petition_patch("updateCallflowDisturb", { id, data: deviceData.itpvoice_metadata.save_callFlow })
                    .then(({ data: result }) => { })
                    .catch((error) => { throw error });
            }


            await petition_patch("updateAgentStatus", { id: id, data, user_id: userStatusProfile.id })
                .then(({ data: result }) => {
                    setReloadData(!reloadData)
                })
                .catch((error) => { throw error });


            return toastr.success("Action completed", "Success");

        } catch (error) {
            console.log(error)

            setLoading(false)
            if (error.response && error.response.status === 400) {
                return toastr.error("You need one queue to active this status", "Error");
            }
            return toastr.error("Action failed", "Error");
        }
    }

    return (
        <div className="phone-call_info">
            {userStatusProfile && (
                <>

                    <div className="pl-1 d-flex " style={{ alignItems: "center" }} >
                        <p className="title pt-1 ">
                            {userStatusProfile.first_name} {userStatusProfile.last_name}
                        </p>

                        {/* <Dropdown
                            isOpen={dropDownActive}
                            toggle={() => { setDropDownActive(!dropDownActive) }}
                        >
                            <DropdownToggle

                                style={{ background: "transparent", border: "none", padding: "0 11px 0 0" }}

                                tag="button"
                            >

                                <div style={{ cursor: "pointer", marginLeft: "7px", background: disturb ? "#34c38f" : "#f1b44c", width: "17px", height: "17px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}> <ExpandMoreIcon style={{ fontSize: "13px", color: "white" }} /> </div>

                            </DropdownToggle>
                            <DropdownMenu right style={{ background: "red !important" }}>
                                <DropdownItem onClick={() => { handleClickStatusAgent("login") }} >
                                    Ready
                                </DropdownItem>
                                <div className="dropdown-divider"></div>
                                <DropdownItem onClick={() => { handleClickStatusAgent("disturb") }} >

                                    Do Not Disturb
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown> */}

                        <div style={{ position: "absolute", top: "14px", right: "17px" }}>
                            <ButtonHover onClick={audioSettings}>

                                <SettingsIcon />
                            </ButtonHover>
                        </div>
                    </div>
                    <p className="subtitle pl-1">
                        {userStatusProfile.presence_id}
                    </p>
                    <div className="d-flex alerts" >
                        {
                            loading || !deviceData ? <div>
                                <Spinner
                                    animation="grow"
                                    style={{
                                        margin: "10px auto",
                                        height: "10px",
                                        width: "10px",
                                    }}
                                />
                            </div> : <> {queues.length >= 1 && <Badge onClick={() => { setModalQueues(statusAgent) }}
                                style={{ cursor: "pointer", margin: "0 0 0.1rem 0.3rem", fontSize: "15p", padding: "3px 4px" }}

                                color={getColor(statusAgent)}
                            >
                                {/* {statusAgent} */} queues
                            </Badge>} </>
                        }

                    </div>{" "}
                </>
            )}
        </div>
    )
}
