import React, { useState, useEffect } from "react";
import profileImg from "../../../../../assets/images/profile-img.png";
import { Button, Modal, ModalBody, Row, Col, Spinner } from "reactstrap";

export default function ModalUtility({
  modal,
  setModal,
  deleteUser,
  message,
  spinner,
}) {
  return (
    <Modal
      style={{ marginTop: "10rem" }}
      isOpen={!modal ? false : true}
      className="info "
    >
      <ModalBody>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {!spinner && <h3>{message}</h3>}
          {spinner && (
            <Spinner
              animation="grow"
              style={{
                marginTop: "10vh",
                marginBottom: "10vh",
                height: "10vh",
                width: "10vh",
              }}
            />
          )}
        </div>
        {!spinner && (
          <div style={{ textAlign: "center" }}>
            <Button
              style={{ margin: "1rem" }}
              outline
              color="secondary"
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </Button>
            {message !== "error" && (
              <Button   
                style={{ margin: "1rem" }}
                color="danger"
                onClick={() => {
                  deleteUser();
                }}
              >
                Delete
              </Button>
            )}
          </div>
        )}
      </ModalBody>
    </Modal>
  );
}
