import React, { useState, useEffect } from "react";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import Steps from "./Steps/Steps";
import StepOne from "./Steps/StepOne";
import StepTwo from "./Steps/StepTwo";
import StepThree from "./Steps/StepThree";
import StepRepeat from "./Steps/StepRepeat";
import StepViewCarriers from "./Steps/StepViewCarriers";

import toastr from "toastr";
import {
  Container,
  Table,
  CustomInput,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
} from "reactstrap";

import { connect } from "react-redux";

import petition_post from "../../../petitions/petition_post";

import IgnoreNumbersModal from "./Modals/IgnoreNumbersModal";
import { invalid } from "moment";
import { clearFields } from "redux-form";

function NumberInventory({ pricePerDid }) {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  toastr.options = {
    showDuration: 300,
  };
  const [step, setStep] = useState(1);
  const [stepTwo, setStepTwo] = useState(0);
  const [form, setForm] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingIgnoreNumbers, setLoadingIgnoreNumbers] = useState(false);
  const [invalidModal, setInvalidModal] = useState(false);
  const [invalidNumbers, setInvalidNumbers] = useState(false);
  const [supportedLosingCarriers, setSupportedLosingCarriers] = useState(null);
  const [allData, setAllData] = useState([]);

  const [formMetadata, setFormMetadata] = useState({
    porting_number: false,
    allow_none: false,
    porting_business_type: "",
    porting_first_name: "",
    porting_last_name: "",
    porting_business_name: "",
    porting_house_number: "",
    porting_street_name: "",
    porting_city_name: "",
    porting_state_code: "",
    porting_zip_code: "",
    porting_btn: "",
    porting_pin: "",
    porting_account_number: "",
    porting_carrier: "",
    pin: ""
  });

  const filterValidNumbers = () => {
    const newForm = form.split("\n").filter((element) => {
      if (
        element.trim() !== "" &&
        parseInt(element.trim()).toString().length === element.trim().length
      ) {
        return element.trim();
      }
    });
    const formTrim = newForm.map((element) => {
      return element.trim();
    });

    const filterNumbers = formTrim.filter((element) => {
      let findNumber = false;
      if (typeof invalidNumbers === "string") {
        if (invalidNumbers === element) findNumber = true;
      } else {
        console.log(invalidNumbers);
        findNumber = invalidNumbers.find(
          (elementTwo) => elementTwo === element
        );
      }

      if (!findNumber) return element;
    });

    console.log(filterNumbers);

    setLoadingIgnoreNumbers(true);
    if (filterNumbers.length !== 0) {
      petition_post("verifyTransferNumbersLegacy", {
        id,
        data: { numbers: filterNumbers },
      })
        .then(({ data: result }) => {
          setSupportedLosingCarriers(
            result.result.NumberPortabilityResponse.SupportedTollFreeNumbers ?
              [...result.result.NumberPortabilityResponse.SupportedLosingCarriers
                .LosingCarrierTnList, { LosingCarrierName: "Toll Free Number", TnList: result.result.NumberPortabilityResponse.SupportedTollFreeNumbers.TollFreeNumberGroup.TnList }]
              : result.result.NumberPortabilityResponse.SupportedLosingCarriers
                .LosingCarrierTnList
          );
          setInvalidModal(false);
          setLoadingIgnoreNumbers(false);
          setForm(filterNumbers);
          setStep(step + 1);
          setInvalidNumbers(false);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          console.log("prueba");
        });
    } else {
      setInvalidModal(false);
      setLoadingIgnoreNumbers(false);
      toastr.error("No valid number has been entered", "Error");
    }
  };

  const reloadForm = () => {
    setFormMetadata({
      porting_number: false,
      allow_none: false,
      porting_business_type: "",
      porting_first_name: "",
      porting_last_name: "",
      porting_business_name: "",
      porting_house_number: "",
      porting_street_name: "",
      porting_city_name: "",
      porting_state_code: "",
      porting_zip_code: "",
      porting_btn: "",
      porting_pin: "",
      porting_account_number: "",
      porting_carrier: "",
      pin: ""
    });
  };

  const handleNext = () => {
    switch (step) {
      case 1:
        if (form.length === 0) {
          toastr.error("Complete Form", "Error");
          return;
        }
        const newForm = form.split("\n").filter((element) => {
          if (
            element.trim() !== "" &&
            parseInt(element.trim()).toString().length === element.trim().length
          ) {
            return element.trim();
          }
        });
        const formTrim = newForm.map((element) => {
          return element.trim();
        });
        /*  setForm(formTrim); */
        setLoading(true);
        petition_post("verifyTransferNumbersLegacy", { id, data: { numbers: formTrim } })
          .then(({ data: result }) => {
            console.log(result);
            setSupportedLosingCarriers(
              result.result.NumberPortabilityResponse.SupportedTollFreeNumbers ?
                [...result.result.NumberPortabilityResponse.SupportedLosingCarriers
                  .LosingCarrierTnList, { LosingCarrierName: "Toll Free Number", TnList: result.result.NumberPortabilityResponse.SupportedTollFreeNumbers.TollFreeNumberGroup.TnList }]
                : result.result.NumberPortabilityResponse.SupportedLosingCarriers
                  .LosingCarrierTnList
            );
            setInvalidModal(false);
            setLoadingIgnoreNumbers(false);
            setForm(formTrim);
            setStep(step + 1);
            setInvalidNumbers(false);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            if (error.response.data.result.NumberPortabilityResponse) {
              if (
                error.response.data.result.NumberPortabilityResponse.Errors
                  .Description === "Telephone number is invalid"
              ) {
                setInvalidNumbers(
                  error.response.data.result.NumberPortabilityResponse.Errors
                    .TelephoneNumbers.Tn
                );
                setInvalidModal(true);
                setLoading(false);
              }
            } else {
              toastr.error("Action failed", "Error");
            }
          });
        break;
      case 2:
        setStep(step + 1)
        break;
      case 3:
        if (stepTwo < supportedLosingCarriers.length) {
          if (
            formMetadata.porting_business_type.trim() === "" ||
            formMetadata.porting_first_name.trim() === "" ||
            formMetadata.porting_last_name.trim() === "" ||
            formMetadata.porting_house_number.trim() === "" ||
            formMetadata.porting_street_name.trim() === "" ||
            formMetadata.porting_city_name.trim() === "" ||
            formMetadata.porting_state_code.trim() === "" ||
            formMetadata.porting_zip_code.trim() === "" ||
            formMetadata.porting_btn.trim() === "" ||
            formMetadata.porting_account_number.trim() === "" ||
            formMetadata.porting_carrier.trim() === ""
          ) {
            toastr.error("Complete Form", "Error");
            return;
          }
          const newAllData = allData;
          if (typeof supportedLosingCarriers[stepTwo].TnList.Tn === "string") {
            newAllData.push({
              ...formMetadata,
              did: supportedLosingCarriers[stepTwo].TnList.Tn,
              type: supportedLosingCarriers[stepTwo].LosingCarrierName,
            });
          } else {
            supportedLosingCarriers[stepTwo].TnList.Tn.map((element) => {
              newAllData.push({
                ...formMetadata,
                did: element,
                type: supportedLosingCarriers[stepTwo].LosingCarrierName,
              });
            });
          }

          setAllData(allData);
          reloadForm();
          if (stepTwo === supportedLosingCarriers.length - 1)
            return setStep(step + 1);
          return setStepTwo(stepTwo + 1);
        }

        setStep(step + 1);

        break;
      case 4:
        if (form.length > 0 && form.length) setStep(step + 1);

        break;
      case 5:
        //Petition
        setLoading(true);

        let types = {};

        supportedLosingCarriers.map((element) => {
          types[element.LosingCarrierName] = [];
        });

        allData.map((element) => {
          types[element.type] = [
            ...types[element.type],
            {
              did: element.did.trim(),
              business_type: element.porting_business_type,
              first_name: element.porting_first_name,
              last_name: element.porting_last_name,
              business_name: element.porting_business_name,
              house_number: element.porting_house_number,
              street_name: element.porting_street_name,
              city_name: element.porting_city_name,
              state_code: element.porting_state_code,
              zip_code: element.porting_zip_code,
              btn: element.porting_btn,
              account_number: element.porting_account_number,
              porting_carrier: element.porting_carrier,
              pin: element.pin
            },
          ];
        });

        const allPetitions = Object.keys(types);
        const promiseAll = [];
        allPetitions.map((element) => {
          console.log("pruebas")
          promiseAll.push(
            petition_post("transferNumbersLegacy", {
              id,
              data: {
                numbers: [...types[element]],
                internal_port: element === "ITP" ? true : false,
              },
            })
          );
        });

        Promise.all(promiseAll)
          .then((values) => {
            toastr.success("Action completed", "Success");
            setStep(1);
            setStepTwo(0);
            setForm("");
            setLoading(false);
            setAllData([]);
          })
          .catch((reason) => {
            toastr.error("Action failed", "Error");

            console.log(reason);
          });

        break;

      default:
        break;
    }
  };

  return (
    <>
      <IgnoreNumbersModal
        modal={invalidModal}
        setModal={setInvalidModal}
        invalidNumbers={invalidNumbers}
        loading={loadingIgnoreNumbers}
        filterValidNumbers={filterValidNumbers}
      />
      <div className="page-content">
        <Container fluid>
          <div
            className="d-flex"
            style={{ alignItems: "center", paddingBottom: "1rem" }}
          >
            <h3 style={{ margin: "0" }}>Phone Numbers</h3>{" "}
            <span>
              <DoubleArrowIcon className="icon-arrow" />
            </span>{" "}
            <h4
              style={{
                color: "#a6b0cf",
                fontWeight: "400",
                margin: "0",
                fontSize: "15px",
                paddingTop: "7px",
              }}
            >
              Transfer Your Numbers
            </h4>
          </div>
        </Container>

        <Card body className="mx-2">
          <Steps step={step} />

          {step === 1 && (
            <StepOne
              formMetadata={formMetadata}
              setFormMetadata={setFormMetadata}
              setForm={setForm}
              form={form}
            />
          )}
          {step === 2 && (
            <StepViewCarriers
              supportedLosingCarriers={supportedLosingCarriers}
            />
          )}
          {step === 3 && (
            <StepRepeat
              step={stepTwo}
              formMetadata={formMetadata}
              setFormMetadata={setFormMetadata}
              setForm={setForm}
              form={form}
              supportedLosingCarriers={supportedLosingCarriers}
            />
          )}
          {step === 4 && (
            <StepTwo
              setAllData={setAllData}
              allData={allData}
              setForm={setForm}
              form={form}
            />
          )}
          {step === 5 && (
            <StepThree
              pricePerDid={pricePerDid}
              setForm={setForm}
              form={form}
            />
          )}

          <div className="text-right  mt-3">
            {step > 1 && (
              <Button
                disabled={loading}
                color="outline-secondary"
                style={{ padding: "0.60rem 2rem", marginRight: "0.5rem" }}
                onClick={() => {
                  setStep(step - 1);
                  if (step === 2) {
                    setForm("");
                    setFormMetadata("");
                  }
                }}
              >
                Back
              </Button>
            )}

            <Button
              disabled={loading}
              color="primary"
              style={{ padding: "0.60rem 2.5rem" }}
              onClick={handleNext}
            >
              {loading && (
                <Spinner
                  className="spinner-submit mr-2"
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Next
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    pricePerDid: state.ITPVoice.didPrice,
  };
};

export default connect(mapStateToProps)(NumberInventory);
