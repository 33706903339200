import React, { useEffect, useState } from "react";
import {
    Container,
    Table,
    CustomInput,
    Button,
    Row,
    Col,
    Card,
    Spinner,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import petition_get from "../../../../petitions/petition_get";
import petition_delete from "../../../../petitions/petition_delete";
import petition_patch from "../../../../petitions/petition_patch";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";

import DeleteIcon from "@material-ui/icons/Delete";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AddIcon from "@material-ui/icons/Add";
import styled from "styled-components";
import ButtonHover from "../../../../../components/utils/ButtonHover";
import EditIcon from "@material-ui/icons/Edit";
import ModalUtility from "../ModalUtility/ModalUtility";
import ModalUtilityDelete from "../ModalUtility/ModalUtilityDelete";
import ModalUtilityDisconnect from "../ModalUtility/ModalUtilityDisconnect";

import { v4 as uuidv4 } from 'uuid';
import toastr from "toastr";
const ContainerPagination = styled.div`
  padding: 20px;
  margin: 0;
  .btn-page {
    border: none;
    background: rgba(255, 255, 255, 0.1);
    color: #a6b0cf;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    margin: 0 5px;
    svg {
      color: #a6b0cf;
    }
    &:focus {
      outline: none;
    }
  }
  .active {
    background: #536be1;
  }
`;

const Pages = ({ keyNumbers, setPagination, pagination, cantPagination }) => {
    let page = [];

    for (let i = 0; i < Math.trunc(keyNumbers); i++) {
        page.push(i);
    }

    const onClickPage = (index) => {
        index = (index + 1) * cantPagination;
        setPagination(index);
    };

    return (
        <>
            {page.map((pag, index) => {
                if (
                    (index + 1 >= pagination / cantPagination - 2 &&
                        index + 1 <= pagination / cantPagination) ||
                    (index + 1 <= pagination / cantPagination + 2 &&
                        index + 1 >= pagination / cantPagination)
                ) {
                    return (
                        <button
                            className={`btn-page ${pagination / cantPagination === index + 1 && "active"
                                } `}
                            onClick={() => {
                                onClickPage(index);
                            }}
                            key={index}
                        >
                            {" "}
                            {index + 1}{" "}
                        </button>
                    );
                }
            })}
        </>
    );
};

export default function TabAllNumbers() {
    toastr.options = {
        showDuration: 300,
    };

    const history = useHistory();
    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    const cantPagination = 10;
    const [numbers, setNumbers] = useState(null);
    const [keyNumbers, setKeyNumbers] = useState(null);
    const [AllData, setAllData] = useState(null);
    const [pagination, setPagination] = useState(cantPagination);
    const [reloadDataEdit, setReloadDataEdit] = useState(true);
    const [loadingData, setLoadingData] = useState(false);
    const [loadingTwo, setLoadingTwo] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [loadingDisconnect, setLoadingDisconnect] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [modalDisconnect, setModalDisconnect] = useState(false);
    const [elementNumber, setElementNumber] = useState(null);
    const [defaultNumber, setDefaultNumber] = useState(null)
    const [allUsers, setAllUsers] = useState(null);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectCheckbox, setSelectCheckbox] = useState([]);
    const [inputSearch, setInputSearch] = useState("");
    const [allColflows, setAllColflows] = useState(null);
    const [viewCallSettingsUsersAll, setViewCallSettingsUsersAll] =
        useState(null);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    useEffect(() => {
        if (reloadDataEdit) {
            setLoadingTwo(false);
            setLoadingData(false);
            setReloadDataEdit(false);
            setAllColflows(null);
            setViewCallSettingsUsersAll(null);

            const petition = async () => {

                let saveUsers, saveCallflows;

                await petition_get("itpvoiceUsers", { id: id })
                    .then(({ data: result }) => {
                        setAllUsers(result.result);
                        saveUsers = result.result
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                await petition_get("allCallflows", { id: id })
                    .then(({ data: result }) => {
                        saveCallflows = result.result
                        setAllColflows(result.result);
                    })
                    .catch((error) => {
                        console.log(error);
                    });


                await petition_get("view", { id: id })
                    .then(({ data: result }) => {

                        let saveNumbers = [] //Save key numbers 
                        let petitions = [] //Promise all
                        let detailsNumbers = [] //Save object complete numbers 

                        setKeyNumbers(Object.keys(result.result.numbers));
                        Object.keys(result.result.numbers).map(element => saveNumbers.push({ checked: false, num: element, ...result.result.numbers[element] }))

                        saveNumbers.map(element => petitions.push(petition_get("viewDetails", { id, phoneNumber: element.num })
                            .then(res => {
                                let findUser = null
                                if (res.data.result.owner_id) findUser = saveUsers.find(elementTwo => elementTwo.id === res.data.result.owner_id)

                                let assigned_user = findUser ? `${findUser.first_name} ${findUser.last_name} ${findUser.presence_id}` : `${element.used_by === "callflow" ? "Callflow" : "Not Assigned"}`

                                let findCallflow = null;
                                saveCallflows.map(elementTwo => {

                                    let findNumber = elementTwo.numbers.find(elementThree => elementThree === element.num)

                                    if (findNumber) findCallflow = elementTwo.id

                                })

                                detailsNumbers.push({ ...element, callflowId: findCallflow, assigned_user, statusReal: res.data.result.itpvoice_metadata ? res.data.result.itpvoice_metadata.state.replace("_", " ") : "null", owner_id: res.data.result.owner_id || null })
                            })
                            .catch(err => console.log(err))
                        ))


                        Promise.all(petitions)
                            .then(res => {
                                setLoadingData(true);
                                setNumbers(detailsNumbers)
                                setAllData(detailsNumbers);
                                setLoadingTwo(true)
                                console.log("number", detailsNumbers)
                            })
                            .catch(err => console.log(err))

                    })
                    .catch((error) => console.log(error))

            }

            petition()


            petition_get("viewCallSettingsUsersAll", { id: id })
                .then(({ data: result }) => {
                    setViewCallSettingsUsersAll(result.result);
                })
                .catch((error) => {
                    console.log(error);
                });
            petition_get("itpvoiceAll", { id: id })
                .then(({ data: result }) => {
                    // console.log( );
                    setDefaultNumber(
                        result.result.data.caller_id
                            ? result.result.data.caller_id.external.number
                            : true
                    );
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [reloadDataEdit]);

    function disconnectAll() {
        let data = [];
        if (selectCheckbox.length) {
            setLoadingDisconnect(true);
            selectCheckbox.forEach((element) => {
                data.push(
                    petition_delete("deleteNumber", {
                        id: id,
                        phone: element.num.substr(2),
                    })
                );
            });
            Promise.all(data)
                .then((values) => {
                    setTimeout(() => {
                        setLoadingDisconnect(false);
                        setModalDisconnect(false);
                        toastr.success("Action completed", "Success");
                        setReloadDataEdit(true);
                        setSelectCheckbox([]);
                    }, 20000);
                })
                .catch((reason) => {
                    setReloadDataEdit(true);
                    setLoadingDisconnect(false);
                    setModalDisconnect(false);
                    console.log(reason);
                    setSelectCheckbox([]);
                    toastr.error("Action failed", "Error");
                });
        }
    }

    function activeModalEdit(val) {
        setElementNumber(val);
    }

    function activeModalDelete(val) {
        setModalDelete(val);
    }

    async function deleteNumber() {

        try {
            setLoadingDelete(true);

            if (modalDelete.callflowId) {
                await petition_get("callflowsDetails", { id, callflow: modalDelete.callflowId })
                    .then(async ({ data: result }) => {

                        let filterNumbers = result.result.numbers.filter(element => element !== modalDelete.num)

                        if (filterNumbers.length === 0) filterNumbers.push(`nonum_${uuidv4().split("-")[0]}`)

                        result.result.numbers = filterNumbers

                        await petition_patch("callflowEdit", { id, callflow_id: modalDelete.callflowId, data: { ...result.result }, })
                            .then((result) => console.log(result))
                            .catch((error) => { throw error });



                        petition_delete("deleteNumber", { id: id, phone: modalDelete.num.substr(2) })
                            .then(({ data: result }) => {
                                const newNumbers = numbers.filter(element => element.num !== modalDelete.num);
                                setNumbers(newNumbers);
                                setLoadingDelete(false);
                                setModalDelete(false);
                                toastr.success("Action completed", "Success");
                            })
                            .catch((error) => { throw error });

                    })
                    .catch((error) => { throw error });
            } else {
                petition_delete("deleteNumber", { id: id, phone: modalDelete.num.substr(2) })
                    .then(({ data: result }) => {
                        const newNumbers = numbers.filter(element => element.num !== modalDelete.num);
                        setNumbers(newNumbers);
                        setLoadingDelete(false);
                        setModalDelete(false);
                        toastr.success("Action completed", "Success");
                    })
                    .catch((error) => { throw error });
            }



        } catch (error) {
            console.log(error);
            setLoadingDelete(false);
            setModalDelete(false);
            toastr.error("Action failed", "Error");
        }


    }

    useEffect(() => {
        if (AllData) {
            const keyNumbersCopy = [];
            const newData = AllData.filter((element) => {
                if (element.num.includes(inputSearch)) {
                    keyNumbersCopy.push(element.num);
                    return element;
                }
            });
            setKeyNumbers(keyNumbersCopy);
            setNumbers(newData);
            setPagination(10);
        }
    }, [inputSearch]);

    const onClickNext = () => {
        if (pagination < keyNumbers.length) {
            setPagination(pagination + cantPagination);
        }
    };

    const onClickBack = () => {
        if (pagination > cantPagination) {
            setPagination(pagination - cantPagination);
        }
    };

    const handleCheck = (value) => {
        //If it is a single number
        if (value.number) {
            let allNumbers = AllData.map((element, i) => {
                if (element.num === value.index) {
                    element.checked = !element.checked;
                }
                return element;
            });
            setAllData(allNumbers);
            let allSelectedCheckBox;
            if (value.evento) {
                //Add Numbers
                allSelectedCheckBox = selectCheckbox;
                allSelectedCheckBox.push(value.number);
            } else {
                allSelectedCheckBox = selectCheckbox.filter(
                    (element) => element.num !== value.number.num
                );
            }

            setSelectCheckbox(allSelectedCheckBox);
        } else {
            let allNumbers = AllData.map((element, i) => {
                element.checked = value.checked;
                return element;
            });
            setAllData(allNumbers);
            if (value.checked) {
                return setSelectCheckbox(allNumbers);
            }
            setSelectCheckbox([]);
        }
    };


    return (
        <>
            {modal && allUsers && elementNumber != null ? (
                <ModalUtility
                    modal={modal}
                    setModal={setModal}
                    elementNumber={elementNumber}
                    allUsers={allUsers}
                    setReloadDataEdit={setReloadDataEdit}
                    viewCallSettingsUsersAll={viewCallSettingsUsersAll}
                />
            ) : null}
            <ModalUtilityDelete
                modal={modalDelete}
                setModal={setModalDelete}
                deleteNumber={deleteNumber}
                loading={loadingDelete}
            />
            <ModalUtilityDisconnect
                modal={modalDisconnect}
                setModal={setModalDisconnect}
                disconnectNumbers={disconnectAll}
                loading={loadingDisconnect}
            />
            <div >

                {!loadingTwo || !defaultNumber ? (
                    <div style={{ textAlign: "center" }}>
                        <Spinner
                            animation="grow"
                            style={{
                                margin: "10vh auto",
                                height: "10vh",
                                width: "10vh",
                            }}
                        />
                    </div>
                ) : (
                    <Card body>
                        {numbers.length !== 0 || inputSearch.trim() !== "" ? (
                            <>
                                <Row>

                                    {/*   <Col className="col-12 col-md-3 mb-3 mb-md-0">
                                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                            <DropdownToggle caret>Bulk Actions</DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => setModalDisconnect(true)}>
                                                    Disconnect
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>{" "}
                                    </Col> */}

                                    <Col style={{ positio: "relative" }} className="col-12   col-md-5">
                                        <input
                                            id="search"
                                            type="text"
                                            className="form-control"
                                            style={{ width: "100%" }}
                                            placeholder={"Search Numbers"}
                                            onChange={(e) => {
                                                setInputSearch(e.target.value);
                                            }}
                                        />
                                        <label

                                            className="icon-search"
                                            htmlFor="search"
                                        >

                                            <SearchIcon />
                                        </label>
                                    </Col>

                                    <Col
                                        className="text-right mt-3 mt-md-0 col-12 col-md-4"
                                    >
                                        {/*   <Button
                                            color="primary"
                                            onClick={() => {
                                                history.push(`/new-numbers?id=${id}`)
                                            }}
                                        >
                                            <AddIcon className="icon-arrow" /> Add Number
                                        </Button> */}
                                    </Col>
                                </Row>

                                <Table hover responsive className=" table mb-0 mt-4">
                                    <thead className="thead-light">
                                        <tr style={{ backgroun: "#2a3042" }}>
                                            <th className="d-flex">
                                                <CustomInput
                                                    onClick={(e) => {
                                                        handleCheck({ checked: e.target.checked });
                                                    }}
                                                    type="checkbox"
                                                    className="input-check"
                                                    name=""
                                                    id="exampleCustomCheckbox"
                                                />
                                                <span>Position </span>
                                            </th>
                                            <th>Number</th>
                                            <th>Status</th>
                                            <th>Destination</th>
                                            <th style={{ textAlign: "center" }}>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {numbers
                                            ? numbers.map((number, index) => {
                                                if (
                                                    index < pagination &&
                                                    index >= pagination - cantPagination
                                                ) {
                                                    return (
                                                        <tr key={index}>
                                                            <th className="d-flex">

                                                                <CustomInput
                                                                    onChange={(e) => {
                                                                        handleCheck({
                                                                            index: number.num,
                                                                            number,
                                                                            evento: e.target.checked,
                                                                        });
                                                                    }}
                                                                    checked={number.checked}
                                                                    type="checkbox"
                                                                    className="input-check"
                                                                    name=""
                                                                    id={"exampleCustomCheckbox" + index}
                                                                />
                                                                <span> {index + 1}</span>

                                                            </th>
                                                            <th>
                                                                {number.num}
                                                            </th>
                                                            <th>
                                                                {number.statusReal}
                                                            </th>
                                                            <th>
                                                                {number.assigned_user}
                                                            </th>
                                                            <th
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                {/* <ButtonHover
                                                                    style={{ margin: "0" }}
                                                                    onClick={() => { activeModalDelete(number) }}

                                                                >
                                                                    <DeleteIcon />
                                                                </ButtonHover> */}
                                                                <ButtonHover
                                                                    style={{ margin: "0" }}
                                                                    onClick={() => {
                                                                        setModal(true);
                                                                        activeModalEdit(number);
                                                                    }}
                                                                >
                                                                    <EditIcon />
                                                                </ButtonHover>
                                                            </th>
                                                        </tr>
                                                    );
                                                }
                                            })
                                            : null}
                                    </tbody>
                                </Table>{" "}
                            </>
                        ) : (
                            <p
                                style={{
                                    textAlign: "center",
                                    fontSize: "2rem",
                                    fontWeight: "200",
                                    margin: "0",
                                }}
                            >
                                {" "}
                                You currently do not have any phone numbers
                            </p>
                        )}

                        <ContainerPagination>
                            {keyNumbers && keyNumbers.length > cantPagination && (
                                <div className="col-12">
                                    <button className="btn-page" onClick={onClickBack}>
                                        {" "}
                                        <ArrowBackIosIcon />{" "}
                                    </button>
                                    <Pages
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        cantPagination={cantPagination}
                                        keyNumbers={
                                            (keyNumbers.length / cantPagination) % 1 === 0
                                                ? keyNumbers.length / cantPagination
                                                : keyNumbers.length / cantPagination + 1
                                        }
                                    />
                                    <button className="btn-page" onClick={onClickNext}>
                                        {" "}
                                        <ArrowForwardIosIcon />
                                    </button>
                                </div>
                            )}
                        </ContainerPagination>
                    </Card>
                )}

            </div>
        </>
    );
}
